import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Container,
  Paper,
  Grid,
  Card,
  CardHeader,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { myAxiosCommonHost } from "../../Service/CorsHelperBaseUrl";
import { Autocomplete } from "@mui/material";

const RegisterExpenseHeader = () => {
  const navigate = useNavigate();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [expenseDetails, setExpenseDetails] = useState({
    expenseFor: "",
    selectCompany: "",
  });

  const [loadingOverlay, setLoadingOverlay] = useState(false);
  const [company, setCompany] = useState([]);

  useEffect(() => {
    async function fetchCompany() {
      try {
        const response = await myAxiosCommonHost.get(
          "gst/profile/getCompanyCodeList"
        );
        const companies = response.data;
        setCompany(companies);
      } catch (error) {
        console.error("Error fetching company data:", error);
      }
    }
    fetchCompany();
  }, []);

  const handleExpenseDetailsChange = (event, name, value) => {
    if (event) {
      const { name, value } = event.target;
      setExpenseDetails((prevDetails) => ({
        ...prevDetails,
        [name]: value,
      }));
    } else {
      setExpenseDetails((prevDetails) => ({
        ...prevDetails,
        [name]: value,
      }));
    }
  };

  const handleCancel = () => {
    const userConfirmed = window.confirm(
      "Are you sure you want to cancel? Any unsaved changes will be lost."
    );
    if (userConfirmed) {
      navigate("/updateExpenseHeader");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoadingOverlay(true);
    try {
      const postData = { ...expenseDetails };

      await myAxiosCommonHost.post("gst/expense/createExpenseHeader", postData);

      alert("Expense Header added successfully!");
      setExpenseDetails({
        expenseFor: "",
        selectCompany: "",
      });

      navigate("/updateExpenseHeader");
    } catch (error) {
      console.error("Error posting data:", error);
      alert("Error adding expense header.");
    } finally {
      setLoadingOverlay(false);
    }
  };

  return (
    <Container maxWidth="lg">
      <form onSubmit={handleSubmit}>
        <Card elevation={3} style={{ marginBottom: "20px", marginTop: "20px" }}>
          <CardHeader
            title="Expense Registration"
            style={{
              backgroundColor: "#2196f3",
              color: "white",
              padding: "10px",
              textAlign: "center",
            }}
          />
          <Paper
            elevation={3}
            style={{ padding: "20px", marginBottom: "20px" }}
          >
            <Paper
              elevation={3}
              style={{ padding: "20px", marginBottom: "20px" }}
            >
              <Paper
                elevation={3}
                style={{
                  padding: "10px",
                  marginBottom: "30px",
                  backgroundColor: "darkgray",
                }}
              >
                <Typography
                  variant="h5"
                  gutterBottom
                  style={{
                    marginBottom: "15px",
                    color: "darkblue",
                    textAlign: "center",
                  }}
                >
                  Expense Header Details
                </Typography>
              </Paper>
              {loadingOverlay && (
                <div className="loading-overlay">
                  <CircularProgress size={60} />
                  <div className="loading-message">Expense is Saving...</div>
                </div>
              )}

              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="EXPENSE FOR"
                    name="expenseFor"
                    value={expenseDetails.expenseFor}
                    onChange={handleExpenseDetailsChange}
                    fullWidth
                    margin="dense"
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    id="company"
                    options={company}
                    value={expenseDetails.selectCompany}
                    onChange={(e, newValue) =>
                      handleExpenseDetailsChange(
                        null,
                        "selectCompany",
                        newValue
                      )
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Company"
                        variant="standard"
                        margin="dense"
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} style={{ marginTop: "20px" }}>
                <Grid item xs={12} md={6}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                  >
                    Submit
                  </Button>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Button
                    type="button"
                    variant="contained"
                    color="secondary"
                    fullWidth
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Paper>
        </Card>
      </form>
    </Container>
  );
};

export default RegisterExpenseHeader;
