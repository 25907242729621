import Headers from "../../../Pages/Header";
import OutStandingPaidHistory from "../PaidInvoiceHistory";

const OutStandingPaidHistoryPage= () => {
  return (
    <Headers>
      <OutStandingPaidHistory/>
    </Headers>
  );
};
export default OutStandingPaidHistoryPage;