import Headers from "../../Pages/Header";
import ProjectPOListVal from "../ValidationsProjectPo";
const ProjectPOListPage = () => {
  return (
    <Headers>
      <ProjectPOListVal />
    </Headers>
  );
};
export default ProjectPOListPage;
