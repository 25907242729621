import Headers from "../../../Pages/Header";
import NotificationGSTUI from "../Notification(GST)";

const NotificationGSTPage = () => {
  return (
    <Headers >
      <NotificationGSTUI  />
    </Headers>
  );
};
export default NotificationGSTPage;