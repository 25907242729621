import Headers from "../../../Pages/Header";
import GenerateOtherChargeInvoice from "../GenerateOtherChargesInvoice";

const GenerateOtherChargesInvoicePage = () => {
  return (
    <Headers>
      <GenerateOtherChargeInvoice />
    </Headers>
  );
};
export default GenerateOtherChargesInvoicePage;
