import Headers from "../../../Pages/Header";
import EmployeeSummary from "../EmployeSummary";

const EmployeeSummaryPage = () => {
  return (
    <Headers>
      <EmployeeSummary />
    </Headers>
  );
};
export default EmployeeSummaryPage;
