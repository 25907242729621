import React, { useEffect, useState } from "react";
import { myAxiosCommonHost } from "../../Service/CorsHelperBaseUrl";
import { CardContent } from "@material-ui/core";
import {
  Card,
  Paper,
  Grid,
  TextField,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  CircularProgress,
  Typography,
  TablePagination,
  Button,
} from "@material-ui/core";
import { Autocomplete } from "@mui/material";

const EmployeePOSummary = () => {
  const [clientNameAndCode, setClientNameAndCode] = useState("");
  const [clientNameOptions, setClientNameOptions] = useState([]);
  const [employeePOSummary, setEmployeePoSummary] = useState([]);
  const [getDataTrigger, setGetDataTrigger] = useState(false); // State to trigger data fetching
  const [employeeNameOptions, setEmployeeNameOptions] = useState([]); // State for project name options
  const [selectedEmployeeName, setSelectedEmployeeName] = useState(""); // State for selected project name
  const [employeeActivePoSummaryList, setEmployeeActivePoSummaryList] =
    useState([]);
  const [employeePoInvoiceWiseDataList, setEmployeePoInvoiceWiseDataList] =
    useState([]); // State for project name summary data
  const [
    employeePoTotalInvoiceAmountList,
    setEmployeePoTotalInvoiceAmountList,
  ] = useState([]); // State for project name summary data
  const [getProjectNameDataTrigger, setGetProjectNameDataTrigger] =
    useState(false); // State for project name summary data
  const [page, setPage] = useState(0); // Add page state
  const [rowsPerPage, setRowsPerPage] = useState(10); // Add rowsPerPage state
  const [totalCount, setTotalCount] = useState(0); // Initialize total count state
  const [pageChanged, setPageChanged] = useState(false);
  const [isClientWisePOSummaryGet, setIsClientWisePOSummaryGet] =
    useState(false);
  const [isEmployeeWisePOSummaryGet, setIsEmployeeWisePOSummaryGet] =
    useState(false);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [company, setCompany] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchEmployeePOSummary = () => {
    const encodedClientNameAndCode = encodeURIComponent(clientNameAndCode);
    myAxiosCommonHost
      .get(
        `gst/employeePo/getEmployeePoSummaryClientCount?clientNameAndCode=${encodedClientNameAndCode}&selectCompany=${selectedCompany}`
      )
      .then((response) => {
        const count = parseInt(response.data); // Extract count from API response
        setTotalCount(count); // Set total count state
      })
      .catch((error) => {
        console.error("Error fetching employee count:", error);
      });
  };

  useEffect(() => {
    myAxiosCommonHost
      .get(
        `gst/employeePo/getClientListEmpPurchaseOrder?selectCompany=${selectedCompany}`
      )
      .then((response) => {
        setClientNameOptions(response.data);
      })
      .catch((error) => {
        console.error("Error Fetching EmployeeName:", error);
      });
  }, [selectedCompany]);

  useEffect(() => {
    async function fetchCompany() {
      try {
        const response = await myAxiosCommonHost.get(
          "gst/profile/getCompanyCodeList"
        );
        const companies = response.data;
        setCompany(companies);
        if (companies.length > 0) {
          setSelectedCompany(companies[0]); // Set the default company to the first one
        }
      } catch (error) {
        console.error("Error fetching company data:", error);
      }
    }
    fetchCompany();
  }, []);

  useEffect(() => {
    if (getDataTrigger || pageChanged) {
      setIsClientWisePOSummaryGet(true); // Set loading state
      const encodedClientNameAndCode = encodeURIComponent(clientNameAndCode);
      const limit = rowsPerPage;
      const offset = page * rowsPerPage;
      try {
        myAxiosCommonHost
          .get(
            `gst/employeePo/getClientEmployeePoSummary?clientNameAndCode=${encodedClientNameAndCode}&selectCompany=${selectedCompany}&limit=${limit}&offset=${offset}`
          )
          .then((response) => {
            setEmployeePoSummary(response.data.clientEmployeePoSummaryList);
            setEmployeeNameOptions(response.data.employeeNameAndCodeList);
            fetchEmployeePOSummary();
          })
          .catch((error) => {
            console.error("Error fetching collection history:", error);
          })
          .finally(() => {
            setIsClientWisePOSummaryGet(false); // Reset loading state
          });
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsClientWisePOSummaryGet(false); // Reset loading state on error
      }
      setGetDataTrigger(false); // Reset the trigger after fetching data
      setPageChanged(false); // Reset the page change trigger
    } else {
      setEmployeePoSummary([]);
    }
  }, [
    clientNameAndCode,
    selectedCompany,
    getDataTrigger,
    rowsPerPage,
    page,
    pageChanged,
  ]);

  useEffect(() => {
    if (
      clientNameAndCode &&
      getProjectNameDataTrigger &&
      selectedEmployeeName &&
      selectedCompany
    ) {
      setIsEmployeeWisePOSummaryGet(true); // Set loading state
      const encodedClientNameAndCode = encodeURIComponent(clientNameAndCode);
      const encodedEmployeeNameAndCode =
        encodeURIComponent(selectedEmployeeName);
      myAxiosCommonHost
        .get(
          `gst/employeePo/getEmployeeNamePerPoSummary?clientNameAndCode=${encodedClientNameAndCode}&employeeNameAndCode=${encodedEmployeeNameAndCode}&selectCompany=${selectedCompany}`
        )
        .then((response) => {
          setEmployeeActivePoSummaryList(
            response.data.employeeActivePoSummaryList
          );
          setEmployeePoInvoiceWiseDataList(
            response.data.employeePoInvoiceWiseDataList
          );
          setEmployeePoTotalInvoiceAmountList(
            response.data.employeePoTotalInvoiceAmountList
          );
        })
        .catch((error) => {
          console.error("Error fetching project summary:", error);
        })
        .finally(() => {
          setIsEmployeeWisePOSummaryGet(false); // Reset loading state
        });
      setGetProjectNameDataTrigger(false); // Reset the trigger after fetching data
    } else {
      setEmployeePoSummary([]);
      setEmployeeActivePoSummaryList([]);
      setEmployeePoInvoiceWiseDataList([]);
      setEmployeePoTotalInvoiceAmountList([]);
    }
  }, [
    clientNameAndCode,
    selectedCompany,
    getProjectNameDataTrigger,
    selectedEmployeeName,
  ]);

  const handleGetData = () => {
    // Set getDataTrigger to true when the button is clicked
    setGetDataTrigger(true);
  };
  const handleCompanySelect = (company) => {
    setSelectedCompany(company);
    console.log("Selected Company :", company);
  };
  const handleGetEmployeePOData = () => {
    // Set getDataTrigger to true when the button is clicked
    setGetProjectNameDataTrigger(true);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setPageChanged(true); // Set page change trigger to true
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div>
      {isClientWisePOSummaryGet && (
        <div className="blur-effect">
          <CircularProgress color="primary" />
          <div className="loading-message">
            Getting Client Wise Employee PO Summary...
          </div>
        </div>
      )}
      {isEmployeeWisePOSummaryGet && (
        <div className="blur-effect">
          <CircularProgress color="primary" />
          <div className="loading-message">
            Getting Employee And Client Wise Employee PO Summary...
          </div>
        </div>
      )}
      <Grid>
        <Grid>
          <Card>
            <CardContent>
              <Paper
                elevation={3}
                style={{ padding: "20px", marginBottom: "20px" }}
              >
                <Paper
                  elevation={3}
                  style={{
                    padding: "10px",
                    marginBottom: "10px",
                    backgroundColor: "#2196f3",
                  }}
                >
                  <Typography
                    variant="h5"
                    gutterBottom
                    style={{
                      marginBottom: "15px",
                      color: "white",
                      textAlign: "center",
                    }}
                  >
                    Employee PO Summary
                  </Typography>
                </Paper>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Autocomplete
                      id="company"
                      options={company}
                      loading={loading}
                      value={selectedCompany}
                      onChange={(event, newValue) =>
                        handleCompanySelect(newValue)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Company"
                          variant="standard"
                          margin="normal"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Autocomplete
                      options={clientNameOptions}
                      getOptionLabel={(option) => option}
                      value={clientNameAndCode}
                      onChange={(event, newValue) =>
                        setClientNameAndCode(newValue)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Client Name"
                          variant="standard"
                          margin="normal"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Autocomplete
                      options={employeeNameOptions}
                      getOptionLabel={(option) => option}
                      value={selectedEmployeeName}
                      onChange={(event, newValue) =>
                        setSelectedEmployeeName(newValue)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Employee Name"
                          variant="standard"
                          margin="normal"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleGetData}
                      disabled={!clientNameAndCode}
                    >
                      View Client History
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      style={{ margin: "10px" }}
                    >
                      PO Count {totalCount}
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleGetEmployeePOData}
                      disabled={!selectedEmployeeName}
                    >
                      View Employee PO History
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {employeePOSummary.length > 0 && (
        <Grid>
          <Paper
            elevation={3}
            style={{ padding: "20px", marginBottom: "20px" }}
          >
            <Paper
              elevation={3}
              style={{ padding: "10px", marginBottom: "10px" }}
            >
              <Typography
                variant="h5"
                gutterBottom
                style={{ marginBottom: "15px", color: "#2196f3" }}
              >
                Employee PO Summary
              </Typography>
            </Paper>
            <TableContainer component={Paper} style={{ marginTop: "20px" }}>
              <Table>
                <TableHead>
                  <TableRow
                    style={{
                      backgroundColor: "darkcyan",
                      fontWeight: "bold",
                      color: "darkblue",
                    }}
                  >
                    <TableCell>SR.No</TableCell>
                    <TableCell>Employee Name</TableCell>
                    <TableCell>Employee Code</TableCell>
                    <TableCell>Is Active</TableCell>
                    <TableCell>Flag Is Active Value</TableCell>
                    <TableCell>Employee Po No</TableCell>
                    <TableCell>Employee Po Amount</TableCell>
                    <TableCell>Employee Po Date</TableCell>
                    <TableCell>Employee Po StartDate</TableCell>
                    <TableCell>Employee Po EndDate</TableCell>
                    <TableCell>ClientCode</TableCell>
                    <TableCell>ClientName</TableCell>
                    <TableCell>Employee Unique Code</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {employeePOSummary.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={5} align="center">
                        Employee PO Summary found
                      </TableCell>
                    </TableRow>
                  ) : (
                    employeePOSummary.map((row, index) => (
                      <TableRow key={row.id}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{row.employeeName}</TableCell>
                        <TableCell>{row.employeeCode}</TableCell>
                        <TableCell>
                          {row.isActive == "true" ? "true" : "false"}
                        </TableCell>
                        <TableCell>{row.flagIsActiveValue}</TableCell>
                        <TableCell>{row.employeePoNo}</TableCell>
                        <TableCell>{row.employeePoAmount}</TableCell>
                        <TableCell>
                          {row.employeePoDate
                            ? row.employeePoDate.split("-").reverse().join("-")
                            : "N/A"}
                        </TableCell>
                        <TableCell>
                          {row.employeePoStartDate
                            ? row.employeePoStartDate
                                .split("-")
                                .reverse()
                                .join("-")
                            : "N/A"}
                        </TableCell>
                        <TableCell>
                          {row.employeePoEndDate
                            ? row.employeePoEndDate
                                .split("-")
                                .reverse()
                                .join("-")
                            : "N/A"}
                        </TableCell>
                        <TableCell>{row.clientCode}</TableCell>
                        <TableCell>{row.clientName}</TableCell>
                        <TableCell>{row.gsEmployeeUniqueCode}</TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 15, 20]}
              component="div"
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
      )}
      {employeeActivePoSummaryList.length > 0 && (
        <Grid>
          <Paper
            elevation={3}
            style={{ padding: "20px", marginBottom: "20px" }}
          >
            <Paper
              elevation={3}
              style={{ padding: "10px", marginBottom: "10px" }}
            >
              <Typography
                variant="h5"
                gutterBottom
                style={{ marginBottom: "15px", color: "#2196f3" }}
              >
                Active Employee PO Summary
              </Typography>
            </Paper>
            <TableContainer component={Paper} style={{ marginTop: "20px" }}>
              <Table>
                <TableHead>
                  <TableRow
                    style={{
                      backgroundColor: "darkcyan",
                      fontWeight: "bold",
                      color: "darkblue",
                    }}
                  >
                    <TableCell>SR.No</TableCell>
                    <TableCell>IsActive</TableCell>
                    <TableCell>Employee Po No</TableCell>
                    <TableCell>Employee Po Amount</TableCell>
                    <TableCell>Employee Po Date</TableCell>
                    <TableCell>Employee Po Start Date</TableCell>
                    <TableCell>Employee Po End Date</TableCell>
                    <TableCell>Flag IsActive Value</TableCell>
                    <TableCell>GS Employee PO Unique Code</TableCell>
                    <TableCell>Client Code</TableCell>
                    <TableCell>Employee Code</TableCell>
                    <TableCell>Employee Release Date</TableCell>
                    <TableCell>PO Rate</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {employeeActivePoSummaryList.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={5} align="center">
                        No Active Po Summary found
                      </TableCell>
                    </TableRow>
                  ) : (
                    employeeActivePoSummaryList.map((row, index) => (
                      <TableRow key={row.gsProjectUniqueCode}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>
                          {row.isActive === "true" ? "true" : "false"}
                        </TableCell>
                        <TableCell>{row.employeePoNo}</TableCell>
                        <TableCell>{row.employeePoAmount}</TableCell>
                        <TableCell>{row.employeePoDate}</TableCell>
                        <TableCell>{row.employeePoStartDate}</TableCell>
                        <TableCell>{row.employeePoEndDate}</TableCell>
                        <TableCell>{row.flagIsActiveValue}</TableCell>
                        <TableCell>{row.gsProjectUniqueCode}</TableCell>
                        <TableCell>{row.clientCode}</TableCell>
                        <TableCell>{row.employeeCode}</TableCell>
                        <TableCell>{row.employeeReleaseDate}</TableCell>
                        <TableCell>{row.empRateUnit}</TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      )}
      {employeePoInvoiceWiseDataList.length > 0 && (
        <Grid>
          <Paper
            elevation={3}
            style={{ padding: "20px", marginBottom: "20px" }}
          >
            <Paper
              elevation={3}
              style={{ padding: "10px", marginBottom: "10px" }}
            >
              <Typography
                variant="h5"
                gutterBottom
                style={{ marginBottom: "15px", color: "#2196f3" }}
              >
                Employee PO Invoice Data
              </Typography>
            </Paper>
            <TableContainer component={Paper} style={{ marginTop: "20px" }}>
              <Table>
                <TableHead>
                  <TableRow
                    style={{
                      backgroundColor: "darkcyan",
                      fontWeight: "bold",
                      color: "darkblue",
                    }}
                  >
                    <TableCell>Sr.No</TableCell>
                    <TableCell>Finacial Year Name</TableCell>
                    <TableCell>Invoice Year</TableCell>
                    <TableCell>Invoice Month</TableCell>
                    <TableCell>Employee Po No</TableCell>
                    <TableCell>Invoice Ref No</TableCell>
                    <TableCell>GS Employee Unique Code</TableCell>
                    <TableCell>Client Code</TableCell>
                    <TableCell>Presenty Amount </TableCell>
                    <TableCell>Employee Rate</TableCell>
                    <TableCell>Presenty</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {employeePoInvoiceWiseDataList.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={5} align="center">
                        No Employee Po Invoice Wise Summary found
                      </TableCell>
                    </TableRow>
                  ) : (
                    employeePoInvoiceWiseDataList.map((row, index) => (
                      <TableRow key={row.gsProjectUniqueCode}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{row.finacialYearName}</TableCell>
                        <TableCell>{row.invoiceYear}</TableCell>
                        <TableCell>{row.invoiceMonth}</TableCell>
                        <TableCell>{row.employeePoNo}</TableCell>
                        <TableCell>{row.invoiceRefNo}</TableCell>
                        <TableCell>{row.gsProjectUniqueCode}</TableCell>
                        <TableCell>{row.clientCode}</TableCell>
                        <TableCell>{row.employeePresentyAmt}</TableCell>
                        <TableCell>{row.employeeRate}</TableCell>
                        <TableCell>{row.presenty}</TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      )}

      {employeePoTotalInvoiceAmountList.length > 0 && (
        <Grid>
          <Paper
            elevation={3}
            style={{ padding: "20px", marginBottom: "20px" }}
          >
            <Paper
              elevation={3}
              style={{ padding: "10px", marginBottom: "10px" }}
            >
              <Typography
                variant="h5"
                gutterBottom
                style={{ marginBottom: "15px", color: "#2196f3" }}
              >
                Employee PO Total Invoice Amount
              </Typography>
            </Paper>
            <TableContainer component={Paper} style={{ marginTop: "20px" }}>
              <Table>
                <TableHead>
                  <TableRow
                    style={{
                      backgroundColor: "darkcyan",
                      fontWeight: "bold",
                      color: "darkblue",
                    }}
                  >
                    <TableCell>Sr.No</TableCell>
                    <TableCell>Company Unique Code</TableCell>
                    <TableCell>PO Number</TableCell>
                    <TableCell>PO Amount</TableCell>
                    <TableCell>Total Invoice Amount</TableCell>
                    <TableCell>Remaining Invoice Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {employeePoTotalInvoiceAmountList.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={5} align="center">
                        No Employee Po Total Invoice Amount Summary found
                      </TableCell>
                    </TableRow>
                  ) : (
                    employeePoTotalInvoiceAmountList.map((row, index) => (
                      <TableRow key={row.gsProjectUniqueCode}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{row.gsProjectUniqueCode}</TableCell>
                        <TableCell>{row.projectPoNo}</TableCell>
                        <TableCell>{row.poAmount}</TableCell>
                        <TableCell>{row.totalInvoiceAmount}</TableCell>
                        <TableCell>{row.remainingAmount}</TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      )}
    </div>
  );
};

export default EmployeePOSummary;
