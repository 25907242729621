import Headers from "../../../Pages/Header";
import UpdateQuotation from "../UpdateQuotation";

const UpdateQuotationPage = () => {
  return (
    <Headers>
      <UpdateQuotation />
    </Headers>
  );
};
export default UpdateQuotationPage;
