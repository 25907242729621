import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Tab,
  Tabs,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Collapse,
  Grid,
  TablePagination,
  CircularProgress,
  Backdrop,
} from "@material-ui/core";
import { Autocomplete } from "@mui/material";
import { Link } from "react-router-dom";
import DeleteIcon from "@material-ui/icons/Delete";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Box, IconButton } from "@mui/material";
import { myAxiosCommonHost } from "../../Service/CorsHelperBaseUrl";
import * as XLSX from "xlsx";
import { CompanyContext } from "../StateProvider/CompanyProvider";
import { useParams } from "react-router-dom"; // Import useParams

const UpdateProjectPO = () => {
  const [data, setData] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [editedData, setEditedData] = useState(null);
  const [clientDialogOpen, setClientDialogOpen] = useState(false);
  const [editedClientData, setEditedClientData] = useState(null);
  const [currentTab, setCurrentTab] = useState(0);
  const [releaseDialogOpen, setReleaseDialogOpen] = useState(false);
  const [expandedRow, setExpandedRow] = useState(null);
  const [addClientDialogOpen, setAddClientDialogOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false); // State to control update overlay
  const [isProjectAdd, setIsProjectAdd] = useState(false); // State to control update overlay
  const [isProjectDelete, setIsProjectDelete] = useState(false); // State to control update overlay
  const [isProjectRelease, setIsProjectRelease] = useState(false); // State to control update overlay
  const [isProjectUpdated, setIsProjectUpdated] = useState(false); // State to control update overlay
  const [isReleaseDataChanged, setReleaseIsDataChanged] = useState(false);
  const [projectPoData, setProjectPoData] = useState([]);
  const [newClientData, setNewClientData] = useState({
    selectCompany: "",
    projectPoNo: "",
    gsProjectUniqueCode: "",
    clientNameAndCode: "",
    projectName: "",
    kindAttention: "",
    projectPoDetailsUIList: [
      {
        projectPoNo: "",
        poDate: "",
        poStartDate: "",
        poEndDate: "",
        poAmount: "",
        description: "",
        ratePerDay: "",
        ratePerhour: "",
        projectHourOrDay: "",
        isActive: "",
        projectPOReleaseUI: {
          projectPoNo: "",
          clientNameAndCode: "",
          projectName: "",
          gsProjectUniqueCode: "",
          description: "",
          releaseNotes: "",
        },
      },
    ],
  });
  const [clientOptions, setClientOptions] = useState([]);
  const [addedClient, setAddedClient] = useState([]);
  const [isDataChanged, setIsDataChanged] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [password, setPassword] = useState("");
  const [selectedProjectUniqueCode, setSelectedProjectUniqueCode] =
    useState("");
  const [page, setPage] = useState(0); // Add page state
  const [rowsPerPage, setRowsPerPage] = useState(10); // Add rowsPerPage state
  const [totalCount, setTotalCount] = useState(0); // Initialize total count state
  const [searchGsProjectUniqueCode, setSearchGsProjectUniqueCode] =
    useState("");
  const [searchClientName, setSearchClientName] = useState("");
  // const [selectedCompany, setSelectedCompany] = useState("");
  const getCurrentFinancialYear = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const fiscalStartYear = currentYear - (currentDate.getMonth() >= 3 ? 0 : 1);
    const fiscalEndYear = fiscalStartYear + 1;
    return `${(fiscalStartYear % 100).toString().padStart(2, "0")}-${(
      fiscalEndYear % 100
    )
      .toString()
      .padStart(2, "0")}`;
  };
  const defaultFinancialYear = getCurrentFinancialYear();
  // const [selectedFinancialYear, setSelectedFinancialYear] =
  //   useState(defaultFinancialYear);
  const [selectedOption, setSelectedOption] = useState(null);
  const [financialYear, setFinancialYear] = useState([]);
  const { selectedCompany } = useContext(CompanyContext);
  const { selectedFinancialYear } = useContext(CompanyContext);
  const options = [
    { value: "ALL", label: " Download Excel(For All Data)" },
    { value: "FinancialYear", label: " Download Excel FinancialYear Wise" },
    { value: "CurrentPage", label: "Download Excel For Current Page" },
  ];
  const { gsProjectUniqueCode } = useParams(); // Extract the parameter from the route
  const handleChange = (event, newValue) => {
    setSelectedOption(newValue);
  };

  const handleViewPO = async () => {
    try {
      setLoading(true);
      const response = await myAxiosCommonHost.get(
        `gst/projectpo/viewNotification?gsProjectUniqueCode=${gsProjectUniqueCode}`
      );
      setData(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error searching employeePo:", error);
      setError(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (gsProjectUniqueCode) {
      handleViewPO();
    }
  }, [gsProjectUniqueCode]);
  // const handleFinancialYearSelect = (financialYear) => {
  //   setSelectedFinancialYear(financialYear);
  //   console.log("Selected Financial Year:", financialYear);
  // };
  // const handleCompanySelect = (company) => {
  //   setSelectedCompany(company);
  //   console.log("Selected Company Year:", company);
  // };
  const handleDownloadExcel = async () => {
    const baseUrl = "gst/projectpo/getProjectPOListForExcel";
    let url = "";
    const limit = rowsPerPage;
    const offset = page * rowsPerPage;

    if (selectedOption?.value === "ALL") {
      url = `${baseUrl}?excelFilterType=ALL&selectCompany=${selectedCompany}`;
    } else if (selectedOption?.value === "FinancialYear") {
      url = `${baseUrl}?excelFilterType&selectCompany=${selectedCompany}&financialYear=${selectedFinancialYear}`;
    } else if (selectedOption?.value === "CurrentPage") {
      url = `${baseUrl}?excelFilterType&selectCompany=${selectedCompany}&financialYear=${selectedFinancialYear}&limit=${limit}&offset=${offset}`;
    }
    try {
      const response = await myAxiosCommonHost.get(url);
      setProjectPoData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    if (projectPoData) {
      downloadExcel();
    }
  }, [projectPoData]);
  useEffect(() => {
    async function fetchFinancialYear() {
      try {
        const response = await myAxiosCommonHost.get(
          "gst/invoice/getFiscalYear"
        );
        setFinancialYear(response.data);
      } catch (error) {
        console.error("Error fetching financial year data:", error);
      }
    }
    fetchFinancialYear();
  }, []);
  // useEffect(() => {
  //   async function fetchCompany() {
  //     try {
  //       const response = await myAxiosCommonHost.get(
  //         "gst/profile/getCompanyCodeList"
  //       );
  //       const companies = response.data;
  //       setCompany(companies);
  //       if (companies.length > 0) {
  //         setSelectedCompany(companies[0]); // Set the default company to the first one
  //       }
  //     } catch (error) {
  //       console.error("Error fetching company data:", error);
  //     }
  //   }
  //   fetchCompany();
  // }, []);
  const fetchProjectCount = () => {
    myAxiosCommonHost
      .get("gst/projectpo/getProjectPOCount")
      .then((response) => {
        const count = parseInt(response.data); // Extract count from API response
        setTotalCount(count); // Set total count state
      })
      .catch((error) => {
        console.error("Error fetching employee count:", error);
      });
  };
  useEffect(() => {
    myAxiosCommonHost
      .get("gst/client/getClientNameList")
      .then((response) => {
        setClientOptions(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.error("Error fetching client data:", error);
      });
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Set loading to true when fetching data
      try {
        const limit = rowsPerPage;
        const offset = page * rowsPerPage;
        const response = await myAxiosCommonHost.get(
          `gst/projectpo/getProjectPoListCompanyFinacialYearWise?selectCompany=${selectedCompany}&financialYear=${selectedFinancialYear}&limit=${limit}&offset=${offset}`
        );
        setData(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(error);
        setLoading(false);
      }
    };
    if (selectedCompany && selectedFinancialYear && !gsProjectUniqueCode) {
      fetchProjectCount();
      fetchData();
    }
  }, [
    selectedCompany,
    selectedFinancialYear,
    gsProjectUniqueCode,
    rowsPerPage,
    page,
  ]);

  const handleAddClientClick = (po) => {
    setNewClientData({
      ...newClientData,
      projectPoNo: po.projectPoNo,
      gsProjectUniqueCode: po.gsProjectUniqueCode,
      clientNameAndCode: po.clientNameAndCode,
      projectName: po.projectName,
      selectCompany: po.selectCompany,
    });
    setAddClientDialogOpen(true);
  };
  const handleAddClientDialogClose = () => {
    setAddClientDialogOpen(false);
  };
  const handleReleaseButtonClick = (editedData, clientData) => {
    setEditedClientData(clientData);
    setEditedData(editedData);
    setReleaseDialogOpen(true);
  };
  const handleReleaseDialogClose = () => {
    setReleaseDialogOpen(false);
  };

  const handleEditClick = (editedData) => {
    setEditedData(editedData);
    setOpenDialog(true);
  };

  const handleClientEditClick = (editedData, clientData) => {
    setEditedData(editedData);
    setEditedClientData(clientData);
    setClientDialogOpen(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleAddToTable = () => {
    // Check for duplicate entry based on description
    const isDuplicateDescription = addedClient.some(
      (client) =>
        client.projectPoDetailsUIList[0].description.trim() ===
        newClientData.projectPoDetailsUIList[0].description.trim()
    );

    if (isDuplicateDescription) {
      alert("Duplicate entry! Cannot add the same description.");
      return;
    }

    // Check if required fields are filled for newClientData
    const newClientDataFilled =
      // newClientData.projectPoDetailsUIList[0].poDate.trim() !== "" &&
      // newClientData.projectPoDetailsUIList[0].poStartDate.trim() !== "" &&
      // newClientData.projectPoDetailsUIList[0].poEndDate.trim() !== "" &&
      // newClientData.projectPoDetailsUIList[0].poAmount.trim() !== "" &&
      newClientData.projectPoDetailsUIList[0].description.trim() !== "";

    if (!newClientDataFilled) {
      alert("Please fill all required fields before adding to the table.");
      return;
    }

    setAddedClient([...addedClient, newClientData]);
    setNewClientData({
      projectPoNo: newClientData.projectPoNo,
      gsProjectUniqueCode: newClientData.gsProjectUniqueCode,
      clientNameAndCode: newClientData.clientNameAndCode,
      projectName: newClientData.projectName,
      kindAttention: newClientData.kindAttention,
      selectCompany: newClientData.selectCompany,
      projectPoDetailsUIList: [
        {
          projectPoNo: "",
          poDate: "",
          poStartDate: "",
          poEndDate: "",
          poAmount: "",
          description: "",
          ratePerDay: "",
          ratePerhour: "",
          projectHourOrDay: "",
          isActive: "",
        },
      ],
    });
  };

  const handleAddProject = async () => {
    if (addedClient.length === 0) {
      alert("Cannot submit without adding an Project to the table");
      return;
    }
    const requestData = {
      projectPoNo: addedClient[0].projectPoNo,
      gsProjectUniqueCode: addedClient[0].gsProjectUniqueCode,
      clientNameAndCode: addedClient[0].clientNameAndCode,
      projectName: addedClient[0].projectName,

      projectPoDetailsUIList: addedClient.flatMap((client) =>
        client.projectPoDetailsUIList.map((projectPo) => ({
          projectPoNo: projectPo.projectPoNo,
          poDate: projectPo.poDate,
          poStartDate: projectPo.poStartDate,
          poEndDate: projectPo.poEndDate,
          poAmount: projectPo.poAmount,
          ratePerDay: projectPo.ratePerDay,
          ratePerhour: projectPo.ratePerhour,
          projectHourOrDay: projectPo.projectHourOrDay,
          description: projectPo.description,
          isActive: projectPo.isActive,
        }))
      ),
    };

    console.log("Request Data:", JSON.stringify(requestData, null, 2));
    setIsProjectAdd(true);
    try {
      const updateResponse = await myAxiosCommonHost.put(
        "gst/projectpo/updateProjectPO",
        requestData
      );

      if (updateResponse.status === 200) {
        console.log("Update Response:", updateResponse.data);
        const limit = rowsPerPage;
        const offset = page * rowsPerPage;
        // Fetch updated data after successful addition
        const fetchDataResponse = await myAxiosCommonHost.get(
          `gst/projectpo/getProjectPoListCompanyFinacialYearWise?selectCompany=${selectedCompany}&financialYear=${selectedFinancialYear}&limit=${limit}&offset=${offset}`
        );

        if (fetchDataResponse.status === 200) {
          const updatedResult = await fetchDataResponse.data;
          setData(updatedResult); // Update the state with the new data
          setEditedClientData(updatedResult);
          setEditedData(updatedResult);
        } else {
          console.error("Failed to fetch updated data.");
        }
        setAddClientDialogOpen(false);
      } else {
        console.error("Failed to update data.");
        alert("Error adding Project. Please try again.");
      }
      // const getResponse = await myAxiosCommonHost.get(
      //   "gst/projectpo/getProjectPoList"
      // );
      // console.log("Get Response:", getResponse.data);
    } catch (error) {
      console.error("Error sending or fetching data from the backend:", error);
    }
    setIsProjectAdd(false);
  };
  const handleClientDialogClose = () => {
    setClientDialogOpen(false);
  };
  const handleUpdateData = async () => {
    setIsUpdating(true);
    try {
      const updatedData = {
        ...editedData,
        projectPoDetailsUIList: [editedClientData],
      };
      const updateResponse = await myAxiosCommonHost.put(
        "gst/projectpo/updateProjectPO",
        updatedData
      );
      if (updateResponse.status === 200) {
        console.log("Successfully updated data:", updatedData);
        const limit = rowsPerPage;
        const offset = page * rowsPerPage;
        const fetchDataResponse = await myAxiosCommonHost.get(
          `gst/projectpo/getProjectPoListCompanyFinacialYearWise?selectCompany=${selectedCompany}&financialYear=${selectedFinancialYear}&limit=${limit}&offset=${offset}`
        );
        if (fetchDataResponse.status === 200) {
          const updatedResult = fetchDataResponse.data;
          setData(updatedResult);
        } else {
          console.error("Failed to fetch updated data.");
        }
        setOpenDialog(false);
        setClientDialogOpen(false);
      } else {
        console.error("Failed to update data.");
        alert("Error updating data. Please try again.");
      }
    } catch (error) {
      console.error("Error updating data:", error);
      alert("Error updating data. Please try again.");
    }
    setIsUpdating(false);
  };
  const handleUpdateProjectData = async () => {
    setIsProjectUpdated(true);
    try {
      const updatedData = {
        ...editedData,
        projectPoDetailsUIList: [editedClientData],
      };

      const updateResponse = await myAxiosCommonHost.put(
        "gst/projectpo/updateProjectPO",
        updatedData
      );
      if (updateResponse.status === 200) {
        console.log("Successfully updated data:", updatedData);
        const limit = rowsPerPage;
        const offset = page * rowsPerPage;
        const fetchDataResponse = await myAxiosCommonHost.get(
          `gst/projectpo/getProjectPoListCompanyFinacialYearWise?selectCompany=${selectedCompany}&financialYear=${selectedFinancialYear}&limit=${limit}&offset=${offset}`
        );

        if (fetchDataResponse.status === 200) {
          const updatedResult = fetchDataResponse.data;
          setData(updatedResult);
        } else {
          console.error("Failed to fetch updated data.");
        }

        setOpenDialog(false);
        setClientDialogOpen(false);
      } else {
        console.error("Failed to update data.");
        alert("Error updating data. Please try again.");
      }
    } catch (error) {
      console.error("Error updating data:", error);
      alert("Error updating data. Please try again.");
    }
    setIsProjectUpdated(false);
  };

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };
  const handleExpandClick = (index) => {
    setExpandedRow(expandedRow === index ? null : index);
  };
  const handleDeleteRow = (index) => {
    const updatedClient = [...addedClient];
    updatedClient.splice(index, 1);
    setAddedClient(updatedClient);
  };
  const handleReleaseProject = async () => {
    setIsProjectRelease(true);
    try {
      const releaseData = {
        projectPoNo: editedClientData.projectPoNo || "",
        clientNameAndCode: editedData.clientNameAndCode || "",
        projectName: editedData.projectName || "",
        gsProjectUniqueCode: editedData.gsProjectUniqueCode || "",
        description: editedClientData.description || "",
        releaseNotes: editedData.releaseNotes || "",
        releaseDate: editedData.releaseDate || "",
      };
      console.log("Release Data:", JSON.stringify(releaseData, null, 2));
      const releaseResponse = await myAxiosCommonHost.post(
        "/gst/projectpo/projectReleaseAsPerDescription",
        releaseData
      );

      if (releaseResponse.status === 200) {
        console.log("Successfully Released Project:", releaseData);
        const limit = rowsPerPage;
        const offset = page * rowsPerPage;
        const fetchDataResponse = await myAxiosCommonHost.get(
          `gst/projectpo/getProjectPoListCompanyFinacialYearWise?selectCompany=${selectedCompany}&financialYear=${selectedFinancialYear}&limit=${limit}&offset=${offset}`
        );
        if (fetchDataResponse.status === 200) {
          const updatedResult = await fetchDataResponse.data;
          setData(updatedResult);
          setEditedData(updatedResult);
        } else {
          console.error("Failed to fetch updated data.");
        }
        setReleaseDialogOpen(false);
      } else {
        console.error(
          "Failed to release Project. Status:",
          releaseResponse.status
        );
        alert("Error releasing Project. Please try again.");
      }
    } catch (error) {
      console.error("Error releasing Project:", error);
      alert("Error releasing Project. Please try again.");
    }
    setIsProjectRelease(false);
  };
  const handleDeleteClick = async (gsProjectUniqueCode) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this record?"
    );
    if (confirmDelete) {
      setIsDeleting(true);
      await handleDeleteButtonClick(gsProjectUniqueCode);
      setIsDeleting(false);
    }
  };
  const resetNewClientData = () => {
    setNewClientData({
      projectPoNo: newClientData.projectPoNo,
      gsProjectUniqueCode: newClientData.gsProjectUniqueCode,
      clientNameAndCode: newClientData.clientNameAndCode,
      projectName: newClientData.projectName,
      kindAttention: newClientData.kindAttention,
      selectCompany: newClientData.selectCompany,
      projectPoDetailsUIList: [
        {
          projectPoNo: "",
          poDate: "",
          poStartDate: "",
          poEndDate: "",
          poAmount: "",
          description: "",
          ratePerDay: "",
          ratePerhour: "",
          projectHourOrDay: "",
          isActive: "",
          projectPOReleaseUI: {
            projectPoNo: "",
            clientNameAndCode: "",
            projectName: "",
            gsProjectUniqueCode: "",
            description: "",
            releaseNotes: "",
          },
        },
      ],
    });
  };
  const handleFieldChange = (fieldName, value) => {
    setEditedData({
      ...editedData,
      [fieldName]: value,
    });
    setIsDataChanged(true); // Set to true whenever a field is changed
  };
  const handleFieldEditPO = (fieldName, value) => {
    setEditedClientData({
      ...editedClientData,
      [fieldName]: value,
    });
    setIsDataChanged(true); // Set to true whenever a field is changed
  };
  const handleReleasePO = (fieldName, value) => {
    setEditedData({
      ...editedData,
      [fieldName]: value,
    });
    setEditedClientData({
      ...editedClientData,
      [fieldName]: value,
    });
    setReleaseIsDataChanged(true);
    setIsDataChanged(true); // Set to true whenever a field is changed
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleDeleteProject = async (projectData) => {
    setIsProjectDelete(true);
    try {
      if (projectData && projectData.projectPOReleaseUI) {
        const projectPOReleaseUI = projectData.projectPOReleaseUI;
        const deleteData = {
          projectPoNo: projectPOReleaseUI.projectPoNo,
          projectName: projectPOReleaseUI.projectName,
          description: projectPOReleaseUI.description,
          releaseNotes: projectPOReleaseUI.releaseNotes,
          gsProjectUniqueCode: projectPOReleaseUI.gsProjectUniqueCode,
          clientNameAndCode: projectPOReleaseUI.clientNameAndCode,
          releaseDate: projectPOReleaseUI.releaseDate,
        };
        const confirmDelete = window.confirm(
          "Are you sure you want to delete this project record?"
        );
        if (confirmDelete) {
          // Perform the POST request to delete the Project data using Axios
          const response = await myAxiosCommonHost.post(
            "gst/projectpo/projectDeleteAsPerDescription",
            deleteData
          );
          console.log(JSON.stringify(deleteData, null, 2));

          if (response.status === 200) {
            const limit = rowsPerPage;
            const offset = page * rowsPerPage;
            const updatedDataResponse = await myAxiosCommonHost.get(
              `gst/projectpo/getProjectPoListCompanyFinacialYearWise?selectCompany=${selectedCompany}&financialYear=${selectedFinancialYear}&limit=${limit}&offset=${offset}`
            );
            setData(updatedDataResponse.data);
            setEditedData(updatedDataResponse.data);
            console.log("Project data deleted successfully:", deleteData);
          } else {
            console.error("Failed to delete Project data.");
            alert("Error deleting data. Please try again.");
          }
        }
      } else {
        console.error(
          "Project data or projectPoDetailsUIList is missing:",
          projectData
        );
        alert("Error deleting data. Please try again.");
      }
    } catch (error) {
      console.error("Error deleting Project data:", error);
      alert("Error deleting data. Please try again.");
    }
    setIsProjectDelete(false);
  };
  const handleDeleteButtonClick = async (gsProjectUniqueCode) => {
    setSelectedProjectUniqueCode(gsProjectUniqueCode);
    setDeleteDialogOpen(true);
  };
  const handleDeleteConfirm = async () => {
    setIsDeleting(true);
    try {
      const response = await myAxiosCommonHost.get(
        `gst/login/verificationForDeletionRecord?userPassword=${password}`
      );
      if (response.status === 200 && response.data) {
        const confirmDelete = window.confirm(
          "Password verified. Are You Want to Delete This Record..."
        );
        console.log("Password verified. Deleting record...");
        if (confirmDelete) {
          // Perform deletion logic here
          const deleteResponse = await myAxiosCommonHost.delete(
            `gst/projectpo/deleteProjectPO/uniqueProjectAndPoNo?uniqueProjectAndPoNo=${selectedProjectUniqueCode}`
          );

          if (deleteResponse.status === 200) {
            console.log("PO deleted successfully!");
            const limit = rowsPerPage;
            const offset = page * rowsPerPage;
            const updatedDataResponse = await myAxiosCommonHost.get(
              `gst/projectpo/getProjectPoListCompanyFinacialYearWise?selectCompany=${selectedCompany}&financialYear=${selectedFinancialYear}&limit=${limit}&offset=${offset}`
            );
            if (updatedDataResponse.status === 200) {
              const updatedResult = await updatedDataResponse.data;
              setData(updatedResult);
              setEditedData(updatedResult);
            } else {
              console.error("Failed to fetch updated data.");
            }
            setDeleteDialogOpen(false);
          } else {
            console.error("Failed to delete PO.");
            alert("Failed to delete PO. Please try again.");
          }
        } else {
          console.error("Failed to verify password.");
          alert("Failed to verify password. Deletion canceled.");
        }
      }
    } catch (error) {
      console.error("Error deleting PO:", error);
      alert("An error occurred while deleting the PO.");
    }
    setIsDeleting(false);
    setDeleteDialogOpen(false);
  };
  const handleSearch = async () => {
    try {
      setLoading(true);
      const limit = rowsPerPage;
      const offset = page * rowsPerPage;
      const response = await myAxiosCommonHost.get(
        `gst/projectpo/searchProjectPO?clientName=${searchClientName}&gsProjectUniqueCode=${searchGsProjectUniqueCode}&limit=${limit}&offset=${offset}`
      );
      setData(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error searching employeePo:", error);
      setError(error);
      setLoading(false);
    }
  };
  const downloadExcel = async () => {
    // Assuming selectedOption, selectedCompany, selectedFinancialYear, limit, and offset are available in the current scope
    let filename;
    if (selectedOption?.value === "ALL") {
      filename = `ProjectPO_ALL_${selectedCompany}.xlsx`;
    } else if (selectedOption?.value === "FinancialYear") {
      filename = `ProjectPO_FinancialYear_Wise_${selectedCompany}_${selectedFinancialYear}.xlsx`;
    } else if (selectedOption?.value === "CurrentPage") {
      filename = `ProjectPO_CurrentPage_${selectedCompany}_${selectedFinancialYear}.xlsx`;
    } else {
      filename = `ProjectPO_${selectedCompany}.xlsx`; // Default filename if no option matches
    }
    // Ensure that projectPoData and its properties are defined
    if (
      !projectPoData ||
      !projectPoData.projectDetailsExcelList ||
      !projectPoData.projectPoDetailsExcelList
    ) {
      console.error(
        "ProjectPoData is undefined or missing required properties"
      );
      return;
    }
    const projectDetailsExcelList = projectPoData.projectDetailsExcelList.map(
      (item) => ({
        selectCompany: item.selectCompany,
        financialYear: item.financialYear,
        GSProjectUniqueCode: item.gsProjectUniqueCode,
        ProjectName: item.projectName,
        clientCode: item.clientCode,
        clientName: item.clientName,
        clientNameAndCode: item.clientNameAndCode,
        kindAttention: item.kindAttention,
        projectPoNo: item.projectPoNo,
      })
    );
    // Prepare the projectPoDetails data
    const projectPoDetailsExcelList =
      projectPoData.projectPoDetailsExcelList.map((item) => ({
        projectName: item.projectName,
        projectPoNo: item.projectPoNo,
        poDate: item.poDate,
        poStartDate: item.poStartDate,
        poEndDate: item.poEndDate,
        poAmount: item.poAmount,
        description: item.description,
        isActive: item.isActive,
        gsProjectUniqueCode: item.gsProjectUniqueCode,
        ratePerDay: item.ratePerDay,
        ratePerhour: item.ratePerhour,
        projectWorkUnit: item.projectWorkUnit,
        clientNameAndCode: item.clientNameAndCode,
        releaseNotes: item.releaseNotes,
        releaseDate: item.releaseDate,
        projectHourOrDay: item.projectHourOrDay,
        financialYear: item.financialYear,
        selectCompany: item.selectCompany,
        clientName: item.clientName,
        clientCode: item.clientCode,
      }));

    // Create a new workbook
    const workbook = XLSX.utils.book_new();

    // Add the main quotation data worksheet
    const projectDetailsWorksheet = XLSX.utils.json_to_sheet(
      projectDetailsExcelList
    );
    XLSX.utils.book_append_sheet(
      workbook,
      projectDetailsWorksheet,
      "ProjectDetailsExcelList"
    );

    // Add the accommodation data worksheet
    const projectPoDetailsWorksheet = XLSX.utils.json_to_sheet(
      projectPoDetailsExcelList
    );
    XLSX.utils.book_append_sheet(
      workbook,
      projectPoDetailsWorksheet,
      "ProjectPoDetailsExcelList"
    );

    // Generate a Blob from the workbook
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Create a download link and click it programmatically
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };
  return (
    <div>
      {isDeleting && (
        <div className="blur-effect">
          <CircularProgress color="primary" />
          <div className="loading-message">PO is Deleting...</div>
        </div>
      )}
      {isUpdating && (
        <div className="blur-effect">
          <CircularProgress color="primary" />
          <div className="loading-message">PO is Updating...</div>
        </div>
      )}
      {isProjectAdd && (
        <div className="blur-effect">
          <CircularProgress color="primary" />
          <div className="loading-message">Project is Adding...</div>
        </div>
      )}
      {isProjectDelete && (
        <div className="blur-effect">
          <CircularProgress color="primary" />
          <div className="loading-message">Project is Deleting...</div>
        </div>
      )}
      {isProjectRelease && (
        <div className="blur-effect">
          <CircularProgress color="primary" />
          <div className="loading-message">Project is Releasing...</div>
        </div>
      )}
      {isProjectUpdated && (
        <div className="blur-effect">
          <CircularProgress color="primary" />
          <div className="loading-message">Project is Updating...</div>
        </div>
      )}
      <Paper
        elevation={3}
        style={{ padding: 20, marginBottom: 20, marginTop: 20 }}
      >
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <Link to="/generateProjectPO" style={{ textDecoration: "none" }}>
              <Button
                variant="contained"
                color="secondary"
                style={{ marginTop: 20, marginRight: 10, marginBottom: "20px" }}
              >
                Generate Project PO
              </Button>
            </Link>
            <Button
              variant="contained"
              color="secondary"
              style={{ margin: "10px" }}
            >
              Project Count {totalCount}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                label="Client Name"
                value={searchClientName}
                onChange={(e) => setSearchClientName(e.target.value)}
                variant="standard"
                style={{ marginRight: "10px" }}
              />
              <TextField
                label="Project Po UniqueNo"
                value={searchGsProjectUniqueCode}
                onChange={(e) => setSearchGsProjectUniqueCode(e.target.value)}
                variant="standard"
                style={{ marginRight: "10px" }}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleSearch}
              >
                Search
              </Button>
            </Box>
            <Box my={4}>
              <Autocomplete
                options={options}
                getOptionLabel={(option) => option.label}
                onChange={handleChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Option"
                    variant="standard"
                  />
                )}
              />
              <Box mt={2}>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "green", color: "white" }}
                  onClick={handleDownloadExcel}
                >
                  Download Excel
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Paper>
      <Paper
        elevation={3}
        style={{ padding: 20, marginBottom: 20, marginTop: 20 }}
      >
        <Grid container spacing={2}>
          <Grid item xs={2} md={4}>
            {/* <Autocomplete
              id="financial-year"
              sx={{ width: 200 }}
              options={financialYear}
              loading={loading}
              value={selectedFinancialYear}
              onChange={(event, newValue) =>
                handleFinancialYearSelect(newValue)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Financial Year"
                  variant="standard"
                  style={{ backgroundColor: "white", marginTop: "20px" }}
                />
              )}
            /> */}
            <TextField
              label="Financial Year"
              value={selectedFinancialYear}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              disabled
              variant="standard"
              style={{ backgroundColor: "white", marginTop: "20px" }}
            />
          </Grid>
          <Grid item xs={2} md={4}>
            {/* <Autocomplete
              id="company"
              sx={{ width: 200 }}
              options={company}
              loading={loading}
              value={selectedCompany}
              onChange={(event, newValue) => handleCompanySelect(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Company"
                  variant="standard"
                  style={{ backgroundColor: "white", marginTop: "20px" }}
                />
              )}
            /> */}
            <TextField
              label="Select Company"
              value={selectedCompany}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              disabled
              variant="standard"
              style={{ backgroundColor: "white", marginTop: "20px" }}
            />
          </Grid>
        </Grid>
      </Paper>
      <Paper
        elevation={3}
        style={{
          padding: 20,
          marginBottom: 20,
          backgroundColor: "#2196f3",
          color: "white",
          padding: "10px",
          textAlign: "center",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography
            variant="h5"
            gutterBottom
            style={{ marginBottom: "15px", color: "darkblue" }}
          >
            Project Purchase Order
          </Typography>
        </div>
      </Paper>
      {error ? (
        <p>No Record Found</p>
      ) : (
        <TableContainer component={Paper} style={{ height: "20%" }}>
          {loading ? (
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={loading}
            >
              <CircularProgress color="inherit" />
              <Typography
                variant="h6"
                color="inherit"
                style={{ marginLeft: "10px" }}
              >
                Getting Data...
              </Typography>
            </Backdrop>
          ) : (
            <>
              <Grid item xs={12}>
                <Paper>
                  <Table>
                    <TableHead>
                      <TableRow
                        style={{
                          backgroundColor: "darkcyan",
                          fontWeight: "bold",
                          color: "darkblue",
                        }}
                      >
                        <TableCell />
                        <TableCell>Project Po No</TableCell>
                        <TableCell>Client Name</TableCell>
                        <TableCell>Project Name</TableCell>
                        <TableCell>Project Unique Code</TableCell>
                        <TableCell>Kind Attention</TableCell>
                        <TableCell></TableCell>
                        <TableCell>Action</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.length === 0 ? (
                        <TableRow>
                          <TableCell colSpan={9} align="center">
                            No Project PO found
                          </TableCell>
                        </TableRow>
                      ) : (
                        data.map((po, index) => (
                          <React.Fragment key={index}>
                            <TableRow>
                              <TableCell>
                                <IconButton
                                  onClick={() => handleExpandClick(index)}
                                  aria-expanded={expandedRow === index}
                                  aria-label="show more"
                                >
                                  <ExpandMoreIcon />
                                </IconButton>
                              </TableCell>
                              <TableCell>{po.projectPoNo}</TableCell>
                              <TableCell>{po.clientName}</TableCell>
                              <TableCell>{po.projectName}</TableCell>
                              <TableCell>{po.gsProjectUniqueCode}</TableCell>
                              <TableCell>{po.kindAttention}</TableCell>
                              <TableCell>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={() => handleEditClick(po)}
                                >
                                  Update PO
                                </Button>
                              </TableCell>
                              <TableCell>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={() => handleAddClientClick(po)}
                                >
                                  Add Project
                                </Button>
                              </TableCell>
                              <TableCell>
                                <Button
                                  variant="contained"
                                  color="secondary"
                                  onClick={() =>
                                    handleDeleteClick(po.gsProjectUniqueCode)
                                  }
                                >
                                  Delete PO
                                </Button>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell colSpan={5}>
                                <Collapse
                                  in={expandedRow === index}
                                  timeout="auto"
                                  unmountOnExit
                                >
                                  {(po.projectPoDetailsUIList || []).map(
                                    (client, clientIndex) => (
                                      <Accordion key={clientIndex}>
                                        <AccordionSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls={`panel${index}-content`}
                                          id={`panel${index}-header`}
                                          IconButtonProps={{ edge: "start" }}
                                        >
                                          <Typography>
                                            {"Description -:"}
                                            {
                                              po.projectPoDetailsUIList[
                                                clientIndex
                                              ].description
                                            }
                                          </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                          <Table style={{ width: "100%" }}>
                                            <TableBody>
                                              <TableRow
                                                style={{
                                                  backgroundColor: "goldenrod",
                                                  fontWeight: "bold",
                                                  color: "darkblue",
                                                }}
                                              >
                                                {/* <TableCell>Project PoNo</TableCell> */}
                                                <TableCell>
                                                  Description
                                                </TableCell>
                                                <TableCell>Po Amount</TableCell>
                                                <TableCell>
                                                  Rate Per Day
                                                </TableCell>
                                                <TableCell>
                                                  Rate Per Hour
                                                </TableCell>
                                                <TableCell>Po Date</TableCell>
                                                <TableCell>
                                                  Po StartDate
                                                </TableCell>
                                                <TableCell>
                                                  Po EndDate
                                                </TableCell>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell>Action</TableCell>
                                                <TableCell></TableCell>
                                              </TableRow>
                                              {client ? (
                                                <TableRow >
                                                  {/* <TableCell>{client.projectPoNo}</TableCell> */}
                                                  <TableCell>
                                                    {client?.description ||
                                                      "N/A"}
                                                  </TableCell>
                                                  <TableCell>
                                                    {client?.poAmount || "N/A"}
                                                  </TableCell>
                                                  <TableCell>
                                                    {client?.ratePerDay ||
                                                      "N/A"}
                                                  </TableCell>
                                                  <TableCell>
                                                    {client?.ratePerhour ||
                                                      "N/A"}
                                                  </TableCell>
                                                  <TableCell>
                                                    {client?.projectHourOrDay ||
                                                      "N/A"}
                                                  </TableCell>
                                                  <TableCell>
                                                    {client.poDate
                                                      ? client?.poDate
                                                          .split("-")
                                                          .reverse()
                                                          .join("-")
                                                      : "N/A"}
                                                  </TableCell>
                                                  <TableCell>
                                                    {client.poStartDate
                                                      ? client?.poStartDate
                                                          .split("-")
                                                          .reverse()
                                                          .join("-")
                                                      : "N/A"}
                                                  </TableCell>
                                                  <TableCell>
                                                    {client.poEndDate
                                                      ? client?.poEndDate
                                                          .split("-")
                                                          .reverse()
                                                          .join("-")
                                                      : "N/A"}
                                                  </TableCell>
                                                  <TableCell>
                                                    <Button
                                                      variant="contained"
                                                      color="primary"
                                                      onClick={() =>
                                                        handleClientEditClick(
                                                          po,
                                                          client
                                                        )
                                                      }
                                                    >
                                                      Update
                                                    </Button>
                                                  </TableCell>
                                                  <TableCell>
                                                    <Button
                                                      variant="contained"
                                                      color="primary"
                                                      onClick={() =>
                                                        handleReleaseButtonClick(
                                                          po,
                                                          client
                                                        )
                                                      }
                                                    >
                                                      Release
                                                    </Button>
                                                  </TableCell>
                                                  <TableCell>
                                                    <Button
                                                      variant="contained"
                                                      color="secondary"
                                                      onClick={() =>
                                                        handleDeleteProject(
                                                          client
                                                        )
                                                      }
                                                    >
                                                      Delete Project
                                                    </Button>
                                                  </TableCell>
                                                </TableRow>
                                              ) : (
                                                <TableRow>
                                                  <TableCell
                                                    colSpan={4}
                                                    align="center"
                                                  >
                                                    No Project Details Found
                                                  </TableCell>
                                                </TableRow>
                                              )}
                                              <TableRow></TableRow>
                                            </TableBody>
                                          </Table>
                                        </AccordionDetails>
                                      </Accordion>
                                    )
                                  )}
                                </Collapse>
                              </TableCell>
                            </TableRow>
                          </React.Fragment>
                        ))
                      )}
                    </TableBody>
                  </Table>
                  <TablePagination
                    rowsPerPageOptions={[10, 15, 20]}
                    component="div"
                    count={totalCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </Paper>
              </Grid>
            </>
          )}
        </TableContainer>
      )}
      <Dialog
        open={addClientDialogOpen}
        onClose={handleAddClientDialogClose}
        maxWidth="md"
        maxHeight="md"
        fullWidth
      >
        <DialogTitle>Add Project</DialogTitle>
        <DialogContent style={{ marginBottom: 20, marginTop: 20 }}>
          <Paper elevation={3} style={{ padding: 20, marginBottom: 20 }}>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <TextField
                  label="Select Company"
                  value={newClientData.selectCompany || "N/A"}
                  InputProps={{ readOnly: true }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Project Po No"
                  value={newClientData.projectPoNo || "N/A"}
                  InputProps={{ readOnly: true }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Client Name And Code"
                  value={newClientData.clientNameAndCode || "N/A"}
                  InputProps={{ readOnly: true }}
                  fullWidth
                  disabled
                  required
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Project Unique Code"
                  value={newClientData.gsProjectUniqueCode || "N/A"}
                  InputProps={{ readOnly: true }}
                  fullWidth
                  disabled
                  required
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Project Name"
                  value={newClientData.projectName || "N/A"}
                  InputProps={{ readOnly: true }}
                  fullWidth
                  disabled
                  required
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Kind Attention"
                  value={newClientData.kindAttention || "N/A"}
                  InputProps={{ readOnly: true }}
                  fullWidth
                  required
                />
              </Grid>
            </Grid>
          </Paper>

          <Paper elevation={3} style={{ padding: 20, marginBottom: 20 }}>
            <Typography variant="subtitle1">Project Details</Typography>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <TextField
                  label="Description"
                  value={newClientData.projectPoDetailsUIList[0].description}
                  onChange={(e) =>
                    setNewClientData({
                      ...newClientData,
                      projectPoDetailsUIList: [
                        {
                          ...newClientData.projectPoDetailsUIList[0],
                          description: e.target.value,
                        },
                      ],
                    })
                  }
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="PO Amount"
                  value={newClientData.projectPoDetailsUIList[0].poAmount}
                  onChange={(e) =>
                    setNewClientData({
                      ...newClientData,
                      projectPoDetailsUIList: [
                        {
                          ...newClientData.projectPoDetailsUIList[0],
                          poAmount: e.target.value,
                        },
                      ],
                    })
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Rate Per Day"
                  value={newClientData.projectPoDetailsUIList[0].ratePerDay}
                  onChange={(e) =>
                    setNewClientData({
                      ...newClientData,
                      projectPoDetailsUIList: [
                        {
                          ...newClientData.projectPoDetailsUIList[0],
                          ratePerDay: e.target.value,
                        },
                      ],
                    })
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Rate Per Hour"
                  value={newClientData.projectPoDetailsUIList[0].ratePerhour}
                  onChange={(e) =>
                    setNewClientData({
                      ...newClientData,
                      projectPoDetailsUIList: [
                        {
                          ...newClientData.projectPoDetailsUIList[0],
                          ratePerhour: e.target.value,
                        },
                      ],
                    })
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Po Date"
                  type="date"
                  value={newClientData.projectPoDetailsUIList[0].poDate}
                  onChange={(e) =>
                    setNewClientData({
                      ...newClientData,
                      projectPoDetailsUIList: [
                        {
                          ...newClientData.projectPoDetailsUIList[0],
                          poDate: e.target.value,
                        },
                      ],
                    })
                  }
                  margin="dense"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="PO Start Date"
                  type="date"
                  value={newClientData.projectPoDetailsUIList[0].poStartDate}
                  onChange={(e) =>
                    setNewClientData({
                      ...newClientData,
                      projectPoDetailsUIList: [
                        {
                          ...newClientData.projectPoDetailsUIList[0],
                          poStartDate: e.target.value,
                        },
                      ],
                    })
                  }
                  margin="dense"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="PO End Date"
                  type="date"
                  value={newClientData.projectPoDetailsUIList[0].poEndDate}
                  onChange={(e) =>
                    setNewClientData({
                      ...newClientData,
                      projectPoDetailsUIList: [
                        {
                          ...newClientData.projectPoDetailsUIList[0],
                          poEndDate: e.target.value,
                        },
                      ],
                    })
                  }
                  margin="dense"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Project Hour Or Day"
                  value={
                    newClientData.projectPoDetailsUIList[0].projectHourOrDay
                  }
                  onChange={(e) =>
                    setNewClientData({
                      ...newClientData,
                      projectPoDetailsUIList: [
                        {
                          ...newClientData.projectPoDetailsUIList[0],
                          projectHourOrDay: e.target.value,
                        },
                      ],
                    })
                  }
                  fullWidth
                />
              </Grid>
            </Grid>
          </Paper>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow
                  style={{
                    backgroundColor: "darkcyan",
                    fontWeight: "bold",
                    color: "darkblue",
                  }}
                >
                  {/* <TableCell>Project PoNo</TableCell> */}
                  <TableCell>Description</TableCell>
                  <TableCell>Po Amount</TableCell>
                  <TableCell>Rate Per Day</TableCell>
                  <TableCell>Rate Per Hour</TableCell>
                  <TableCell>Po Date</TableCell>
                  <TableCell>Po StartDate</TableCell>
                  <TableCell>Po EndDate</TableCell>

                  {/* <TableCell>Is Active</TableCell> */}
                  <TableCell>Action</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {addedClient.map((client, index) => (
                  <TableRow key={index}>
                    {/* <TableCell>
                      {client.projectPoDetailsUIList[0].projectPoNo}
                    </TableCell> */}
                    <TableCell>
                      {client?.projectPoDetailsUIList[0].description || "N/A"}
                    </TableCell>
                    <TableCell>
                      {client?.projectPoDetailsUIList[0].poAmount || "N/A"}
                    </TableCell>
                    <TableCell>
                      {client?.projectPoDetailsUIList[0].ratePerDay || "N/A"}
                    </TableCell>
                    <TableCell>
                      {client?.projectPoDetailsUIList[0]?.ratePerhour || "N/A"}
                    </TableCell>
                    <TableCell>
                      {client?.projectPoDetailsUIList[0]?.projectHourOrDay ||
                        "N/A"}
                    </TableCell>
                    <TableCell>
                      {client?.projectPoDetailsUIList[0].poDate
                        .split("-")
                        .reverse()
                        .join("-") || "N/A"}
                    </TableCell>
                    <TableCell>
                      {client?.projectPoDetailsUIList[0].poStartDate
                        .split("-")
                        .reverse()
                        .join("-") || "N/A"}
                    </TableCell>
                    <TableCell>
                      {client?.projectPoDetailsUIList[0].poEndDate
                        .split("-")
                        .reverse()
                        .join("-") || "N/A"}
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleDeleteRow(index)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Button onClick={handleAddToTable} color="primary">
            Add to Table
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddClientDialogClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleAddProject}
            color="primary"
            disabled={addedClient.length === 0}
          >
            Add Project
          </Button>
          <Button onClick={resetNewClientData} color="secondary">
            Reset
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        maxHeight="md"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Edit PO Data</DialogTitle>
        <DialogContent style={{ marginBottom: 20, marginTop: 20 }}>
          <Paper elevation={3} style={{ padding: 20, marginBottom: 20 }}>
            <Grid container spacing={6}>
              <Grid item xs={4}>
                <TextField
                  label="Select Company"
                  name="selectCompany"
                  value={editedData ? editedData.selectCompany : ""}
                  onChange={handleFieldChange}
                  variant="standard"
                  disabled
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Project Po No"
                  value={editedData ? editedData.projectPoNo : ""}
                  onChange={(e) =>
                    handleFieldChange("projectPoNo", e.target.value)
                  }
                  disabled
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Client Name And Code"
                  value={editedData ? editedData.clientNameAndCode : ""}
                  onChange={(e) =>
                    handleFieldChange("clientNameAndCode", e.target.value)
                  }
                  disabled
                  required
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Project Name"
                  value={editedData ? editedData.projectName : ""}
                  onChange={(e) =>
                    handleFieldChange("projectName", e.target.value)
                  }
                  required
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Project Unique Code"
                  value={editedData ? editedData.gsProjectUniqueCode : ""}
                  onChange={(e) =>
                    handleFieldChange("gsProjectUniqueCode", e.target.value)
                  }
                  disabled
                  required
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Kind Attention"
                  value={editedData ? editedData.kindAttention : ""}
                  onChange={(e) =>
                    handleFieldChange("kindAttention", e.target.value)
                  }
                />
              </Grid>
            </Grid>
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleUpdateData}
            color="primary"
            disabled={!isDataChanged} // Disable the button if no changes
          >
            Update PO
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={clientDialogOpen}
        onClose={handleClientDialogClose}
        maxWidth="md"
        maxHeight="md"
        fullWidth
      >
        <DialogTitle>Edit Project Data</DialogTitle>
        <DialogContent>
          <Tabs
            value={currentTab}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="Project Details" />
          </Tabs>
          {currentTab === 0 && (
            <div>
              <Paper
                elevation={3}
                style={{ padding: 20, marginBottom: 20, marginTop: 20 }}
              >
                <Grid container spacing={6} style={{ marginTop: 10 }}>
                  <Grid item xs={4}>
                    <TextField
                      label="Description"
                      value={
                        editedClientData ? editedClientData.description : ""
                      }
                      // onChange={(e) =>
                      //   setEditedClientData({
                      //     ...editedClientData,
                      //     description: e.target.value,
                      //   })
                      // }
                      onChange={(e) =>
                        handleFieldEditPO("description", e.target.value)
                      }
                      disabled
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="PO Amount"
                      value={editedClientData ? editedClientData.poAmount : ""}
                      // onChange={(e) =>
                      //   setEditedClientData({
                      //     ...editedClientData,
                      //     poAmount: e.target.value,
                      //   })
                      // }
                      onChange={(e) =>
                        handleFieldEditPO("poAmount", e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="Rate Per Day"
                      value={
                        editedClientData ? editedClientData.ratePerDay : ""
                      }
                      // onChange={(e) =>
                      //   setEditedClientData({
                      //     ...editedClientData,
                      //     ratePerDay: e.target.value,
                      //   })
                      // }
                      onChange={(e) =>
                        handleFieldEditPO("ratePerDay", e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="Rate Per Hour"
                      value={
                        editedClientData ? editedClientData.ratePerhour : ""
                      }
                      // onChange={(e) =>
                      //   setEditedClientData({
                      //     ...editedClientData,
                      //     ratePerhour: e.target.value,
                      //   })
                      // }
                      onChange={(e) =>
                        handleFieldEditPO("ratePerhour", e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="PO Date"
                      value={editedClientData ? editedClientData.poDate : ""}
                      // onChange={(e) =>
                      //   setEditedClientData({
                      //     ...editedClientData,
                      //     poDate: e.target.value,
                      //   })
                      // }
                      onChange={(e) =>
                        handleFieldEditPO("poDate", e.target.value)
                      }
                      type="date"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="Po Start Date"
                      value={
                        editedClientData ? editedClientData.poStartDate : ""
                      }
                      // onChange={(e) =>
                      //   setEditedClientData({
                      //     ...editedClientData,
                      //     poStartDate: e.target.value,
                      //   })
                      // }
                      onChange={(e) =>
                        handleFieldEditPO("poStartDate", e.target.value)
                      }
                      type="date"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="Po End Date"
                      value={editedClientData ? editedClientData.poEndDate : ""}
                      // onChange={(e) =>
                      //   setEditedClientData({
                      //     ...editedClientData,
                      //     poEndDate: e.target.value,
                      //   })
                      // }
                      onChange={(e) =>
                        handleFieldEditPO("poEndDate", e.target.value)
                      }
                      type="date"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="Project Hour Or Day"
                      value={
                        editedClientData
                          ? editedClientData.projectHourOrDay
                          : ""
                      }
                      // onChange={(e) =>
                      //   setEditedClientData({
                      //     ...editedClientData,
                      //     projectHourOrDay: e.target.value,
                      //   })
                      // }
                      onChange={(e) =>
                        handleFieldEditPO("projectHourOrDay", e.target.value)
                      }
                    />
                  </Grid>
                </Grid>
              </Paper>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClientDialogClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleUpdateData}
            disabled={!isDataChanged}
            color="primary"
          >
            Update Project
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={releaseDialogOpen}
        onClose={handleReleaseDialogClose}
        maxWidth="md"
        maxHeight="md"
        fullWidth
      >
        <DialogTitle>Project Release</DialogTitle>
        <DialogContent style={{ marginBottom: 20, marginTop: 20 }}>
          <Paper elevation={2} style={{ padding: 20, marginBottom: 20 }}>
            <Grid container spacing={6}>
              <Grid item md={4}>
                <TextField
                  label="Project Po No"
                  value={editedClientData ? editedClientData.projectPoNo : ""}
                  onChange={(e) =>
                    handleReleasePO("projectPoNo", e.target.value)
                  }
                  required
                  disabled
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item md={4}>
                <TextField
                  label="Client Name And Code"
                  value={editedData ? editedData.clientNameAndCode : ""}
                  onChange={(e) =>
                    handleReleasePO("clientNameAndCode", e.target.value)
                  }
                  required
                  disabled
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item md={4}>
                <TextField
                  label="Description"
                  value={editedClientData ? editedClientData.description : ""}
                  onChange={(e) =>
                    handleReleasePO("description", e.target.value)
                  }
                  required
                  disabled
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item md={4}>
                <TextField
                  label="Project Unique Code"
                  value={editedData ? editedData.gsProjectUniqueCode : ""}
                  onChange={(e) =>
                    handleReleasePO("gsProjectUniqueCode", e.target.value)
                  }
                  required
                  disabled
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item md={4}>
                <TextField
                  label="Project Name"
                  value={editedData ? editedData.projectName : ""}
                  onChange={(e) =>
                    handleReleasePO("projectName", e.target.value)
                  }
                  required
                  disabled
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item md={4}>
                <TextField
                  label="Release Note"
                  value={editedData ? editedData.releaseNotes : ""}
                  onChange={(e) =>
                    handleReleasePO("releaseNotes", e.target.value)
                  }
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item md={4}>
                <TextField
                  label="Release Date"
                  value={editedData ? editedData.releaseDate : ""}
                  onChange={(e) =>
                    handleReleasePO("releaseDate", e.target.value)
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  type="date"
                  fullWidth
                  margin="normal"
                />
              </Grid>
            </Grid>
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleReleaseDialogClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleReleaseProject}
            color="primary"
            disabled={!isReleaseDataChanged}
          >
            Project Release
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={deleteDialogOpen}
        onClose={() => {
          setDeleteDialogOpen(false);
          setPassword(""); // Reset password field
        }}
        maxHeight="md"
        fullWidth
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent style={{ marginBottom: 20, marginTop: 20 }}>
          <Paper elevation={2} style={{ padding: 20, marginBottom: 20 }}>
            <Grid container spacing={6}>
              <Grid item md={4}>
                <TextField
                  label="Enter Password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  fullWidth
                  autoFocus
                />
              </Grid>
            </Grid>
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleDeleteConfirm}
            color="primary"
            disabled={isDeleting}
          >
            {isDeleting ? <CircularProgress size={24} /> : "Delete"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UpdateProjectPO;
