import Headers from "../../../Pages/Header";
import RegisterEmployee from "../RegisterEmployee";

const RegisterEmployeePage = () => {
  return (
    <Headers>
      <RegisterEmployee />
    </Headers>
  );
};
export default RegisterEmployeePage;
