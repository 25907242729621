import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Headers from "./Pages/Header";
import { createTheme, ThemeProvider } from "@material-ui/core";
import { useState } from "react";
import ProjectPOListPage from "./Validation/Page/ProjectPOList";
import EmpPOListPage from "./Validation/Page/EmployeePOList";
import OtherChargesInvoiceListValPage from "./Validation/Page/OtherChargesInvoice";
import EmployeeInvoiceList12 from "./Validation/EmployeeInvoiceList";
import ERPLogin from "./LoginUser/ERPLogin";
import OtherChargesInvoiceListVal from "./Validation/ValidationOtherChargesList";
import UpdateEmpPOSearchPage from "./Pages/EmpPOSearchPagination";
import GenerateEmployeeInvoice2 from "./Validation/GenereteEmployeeInvoiceTest";
import RegisterClientVal from "./Validation/RegisterClientVal";
import UpdateClient2 from "./Validation/UpdateClientN";
import GenerateEmployeeInvoiceDate from "./Validation/InvoiceDate";
import RegisterBankPage from "./Component/Bank/Page/RegisterBankPage";
import UpdateEmpPONew3 from "./Validation/EmpPOListNew";
import GenerateEmployeeInvoiceTest from "./Validation/GenereteInvoiceEmployee";
import UpdateEmployee2 from "./Validation/EmployeeupdateNew";
import GenerateProjectInvoiceNewVal from "./Validation/GeneteteProjectInvoice";
import TableDataComponent from "./Component/Summary/TableDataComponent";
import UpdateBank2 from "./Validation/UpdateBankDelete";
import UpdateEmployeePageTest from "./Validation/EmployeePage";
import UpdateEmpPOTest from "./Validation/EmpLoyeePOPageTest";
import UpdateEmpPOtesting from "./Validation/UpdateEmployeePO";
import GenerateEmployeeInvoiceDemo from "./Validation/EmployeeInvoiceGenerate";
import ProjectInvoiceListDemo from "./Validation/ProjectInvoiceList";
import OutStandingExcel from "./SLL/OutStandingExcel";
import GenerateEmployeeInvoiceDemoNew from "./Component/Invoice/EmployeeInvoice";
import DownloadQuatationExcel from "./SLL/DownloadQuatationExcel";
import DownloadQuotationPDF from "./SLL/DownloadQuatationPDF";
import MyComponent from "./SLL/PDFQuotation";
import DownloadPDFNew from "./SLL/DownloadPDFNew";
import DownloadPDFNew2 from "./SLL/DownloadPDFNew2";
import QuotationExcel from "./Component/QuotationPDF/QuitationWord";
import QuotationWord2 from "./Component/QuotationPDF/QuotationWord2";
import Test3 from "./Component/QuotationPDF/test3";
import QuotationGenerator from "./Component/QuotationPDF/UploadQuotation";
import QuotationGenerator2 from "./Component/QuotationPDF/UploadQuotation2";
import QuotationGenerator3 from "./Component/QuotationPDF/UploadOuotation3";
import QuotationUpload5 from "./Component/Quotation/QuotationUpload5";
import AutocompleteComponent from "./Component/ProjectDetails/ProjectPOExcel";
import RegisterClientAgreement from "./Component/Agreement/RegisterClientAgreement";
import RegisterVendorInformation from "./Component/Expense/RegisterVendorInformation";
import RegisterExpenseHeader from "./Component/Expense/RegisterExpenseHeader";
import UpdateClientAgreement from "./Component/Agreement/UpdateClientAgreement";
import UpdateExpenseHeader from "./Component/Expense/UpdateExpenseHeader";
import UpdateVendorInformation from "./Component/Expense/UpdateVendorInformation";
import { CompanyProvider } from "./Component/StateProvider/CompanyProvider";
import UpdateEmployee from "./Component/Employee/UpdateEmployee";
import UpdateEmpPO from "./Component/EmpPO/UpdateEmpPO";
import UpdateClient from "./Component/Client/UpdateClient";
import UpdateProjectPO from "./Component/ProjectDetails/UpdateProjectPO";
import GenerateEmployeeInvoice from "./Component/Invoice/GenerateEmployeeInvoice";
import GenerateProjectInvoice from "./Component/Invoice/GenerateProjectInvoice";
import OtherChargesInvoiceList from "./Component/InvoiceList/OtherChargesInvoiceList";
import EmployeeInvoiceList from "./Component/InvoiceList/EmployeeInvoiceList";
import ProjectInvoiceList from "./Component/InvoiceList/ProjectInvoiceList";
import UpdateBank from "./Component/Bank/UpdateBank";
import UpdateCompanyProfile from "./Component/CompanyProfile/UpdateCompanyProfile";
import EmployeeSummary from "./Component/Summary/EmployeSummary";
import EmployeePOSummary from "./Component/Summary/EmployeePOSummary";
import ProjectPOSummary from "./Component/Summary/ProjectPOSummary";
import UpdateQuotation from "./Component/Quotation/UpdateQuotation";
import NotificationGSTUI from "./Component/Notifications/Notification(GST)";
import NotificationBGDUI from "./Component/Notifications/Notifications(BGD)";
import UpdateVendor from "./Component/Vendor/UpdateVendor";
import RegisterEmployee from "./Component/Employee/RegisterEmployee";
import RegisterClient from "./Component/Client/RegisterClient";
import GenerateEmpPO from "./Component/EmpPO/GenerateEmpPO";
import GenerateProjectPO from "./Component/ProjectDetails/GenerateProjectPO";
import GenerateOtherChargeInvoice from "./Component/Invoice/GenerateOtherChargesInvoice";
import OutStandingUnPaidHistory from "./Component/OutStandingCollection/UnpaidInvoiceHistory";
import OutStandingPaidHistory from "./Component/OutStandingCollection/PaidInvoiceHistory";
import InvoiceReport from "./Component/Report/Report";
import RegisterCompanyProfile from "./Component/CompanyProfile/RegisterCompanyProfile";
import GenerateQuotation from "./Component/Quotation/GenereateQuotation";
import GenerateVendor from "./Component/Vendor/GenerateVendor";
import NavigationDrawer from "./Component/NavigationBar/NaviigationNew";
import { AuthProvider } from "./Component/Auth/Authentication";
import NavigationBarRSuite from "./Component/NavigationBar/NavigationRSuite";
import PrivateRoute from "./Component/StateProvider/PrivateRoute";
import LogInRoute from "./Component/Auth/LoginRoute";
import DemoLogin from "./LoginUser/DemoLogin";
import GenerateEmpPOPage from "./Component/EmpPO/Pages/GenerateEmpPOPage";
import UpdateClientPage from "./Component/Client/Pages/ClientListPage";
import RegisterClientPage from "./Component/Client/Pages/RegisterClientPage";
import RegisterEmployeePage from "./Component/Employee/Pages/RegisterEmployeePage";
import UpdateEmployeePage from "./Component/Employee/Pages/UpdateEmployeePage";
import UpdateEmpPOPage from "./Component/EmpPO/Pages/UpdateEmpPOPage";
import UpdateProjectPOPage from "./Component/ProjectDetails/Page/UpdateProjectPOPage";
import GenerateProjectPOPage from "./Component/ProjectDetails/Page/GenerateProjectPOPage";
import GenerateEmployeeInvoicePage from "./Component/Invoice/Pages/Employee_Invoice_Page";
import GenerateProjectInvoicePage from "./Component/Invoice/Pages/ProjectInvoicePage";
import GenerateOtherChargesInvoicePage from "./Component/Invoice/Pages/OtherChargesInvoicePage";
import EmployeeInvoiceListPage from "./Component/InvoiceList/Pages/EmployeeInvoicePage";
import OtherChargesInvoiceListPage from "./Component/InvoiceList/Pages/OtherChargesInvoiceListt";
import ProjectInvoiceListPage from "./Component/InvoiceList/Pages/ProjectInvoiceList";
import OutStandingPaidHistoryPage from "./Component/OutStandingCollection/Pages/PaidInvoiceHistory";
import OutStandingUnPaidHistoryPage from "./Component/OutStandingCollection/Pages/UnPaidInvoiceHistoryPage";
import InvoiceReportPage from "./Component/Report/Page/InvoiceReportPage";
import UpdateBankPage from "./Component/Bank/Page/UpdateBank";
import GenerateVendorPage from "./Component/Vendor/Pages/GenerateVendorPage";
import UpdateVendorPage from "./Component/Vendor/Pages/UpdateVendorPage";
import NotificationBGDPage from "./Component/Notifications/Pages/NotificationBGDUIPage";
import NotificationGSTPage from "./Component/Notifications/Pages/NotificationGSTUIPage";
import GenerateQuotationPage from "./Component/Quotation/Pages/GenerateQuotationPage";
import UpdateQuotationPage from "./Component/Quotation/Pages/UpdateQuotationPage";
import EmployeePOSummaryPage from "./Component/Summary/Page/EmployeePOSummaryPage";
import ProjectPOSummaryPage from "./Component/Summary/Page/ProjectPOSummaryPage";
import EmployeeSummaryPage from "./Component/Summary/Page/EmployeeSummaryPage";
import RegisterCompanyProfilePage from "./Component/CompanyProfile/Page/RegisterCompanyProfileLPage";
import UpdateCompanyProfilePage from "./Component/CompanyProfile/Page/UpdateCompanyProfilePage";
import ClientAgreeMentNotiPage from "./Component/Notifications/Pages/ClientAgreeMentNotiPage";
import UpdateClientAgreementPage from "./Component/Agreement/Pages/UpdateClientAgreementPage";
import RegisterClientAgreementPage from "./Component/Agreement/Pages/RegisterClientPage";
import UpdateVendorInformationPage from "./Component/Expense/Pages/UpdateVendorInformationPage";
import UpdateExpenseHeaderPage from "./Component/Expense/Pages/UpdateExpenseHeaderPage";
import RegisterVendorInformationPage from "./Component/Expense/Pages/RegisterVendorInformationPage";
import RegisterExpenseHeaderPage from "./Component/Expense/Pages/RegisterExpenseHeaderPage";
import FileUpload from "./SLL/FileUpload";
import FileDownload from "./SLL/DownloadFile";
import FileViewer from "./SLL/FileDownload";
import MultipleFileUpload from "./SLL/UploadMultipleFile";
import ClientAgreementForm from "./SLL/AgreementFile";
import CreateClientAgreement from "./SLL/AgreementFile";
function App() {
  //const [isAuthenticated, setIsAuthenticated] = useState(false);
  const theme = createTheme({
    typography: {
      fontFamily: ["Roboto Condensed", "sans-serif"].join(","),
      fontWeightBold: 700,
    },
  });
  // const handleLogin = () => {
  //   setIsAuthenticated(true);
  // };
  const isAuthenticated = localStorage.getItem("isAuthenticated") === "true";
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        {/* <AuthProvider> */}
        <CompanyProvider>
          <Routes>
            <Route
              path="/"
              // element={<DemoLogin onLogin={handleLogin} />}
              element={<DemoLogin onLogin={() => {}} />}
            />
            {/* {isAuthenticated && (
              <> */}

            {isAuthenticated ? (
              <>
                {/* <Route
                  element={
                    <>
                      <NavigationBarRSuite />
                      <Routes> */}
                <Route path="/login" element={<ERPLogin />}></Route>
                <Route path="/headers" element={<Headers />}></Route>
                <Route
                  path="/navigationBarRSuite"
                  element={<NavigationBarRSuite />}
                ></Route>
                <Route
                  path="/empList"
                  element={<EmployeeInvoiceList12 />}
                ></Route>
                <Route path="/navBar" element={<Headers />}></Route>
                {/* Client Add */}
                <Route
                  path="/registerClient"
                  element={<RegisterClientPage />}
                ></Route>
                <Route
                  path="/updateClient"
                  element={<UpdateClientPage />}
                ></Route>

                {/* Employee Add */}
                <Route
                  path="/registerEmployee"
                  element={<RegisterEmployeePage />}
                ></Route>
                <Route
                  path="/updateEmployee"
                  element={<UpdateEmployeePage />}
                ></Route>
                {/* Generate Employee PO */}
                <Route
                  path="/generateEmployeePO"
                  element={<GenerateEmpPOPage />}
                ></Route>
                <Route
                  path="/updateEmployeePO"
                  element={<UpdateEmpPOPage />}
                ></Route>
                <Route
                  path="/updateEmployeePO/:empPoUniqueNoAndGSCode"
                  element={<UpdateEmpPOPage />}
                />
                {/* Generete Project PO */}
                <Route
                  path="/generateProjectPO"
                  element={<GenerateProjectPOPage />}
                ></Route>
                <Route
                  path="/updateProjectPO"
                  element={<UpdateProjectPOPage />}
                ></Route>
                <Route
                  path="/updateProjectPO/:gsProjectUniqueCode"
                  element={<UpdateProjectPOPage />}
                ></Route>

                {/* Generete Invoices */}
                <Route
                  path="/generateEmployeeInvoice"
                  element={<GenerateEmployeeInvoicePage />}
                ></Route>
                <Route
                  path="/generateProjectInvoice"
                  element={<GenerateProjectInvoicePage />}
                ></Route>
                <Route
                  path="/generateOtherChargesInvoice"
                  element={<GenerateOtherChargesInvoicePage />}
                ></Route>

                {/* Routing For Invoice List Page */}
                <Route
                  path="/otherChargesInvoiceList"
                  element={<OtherChargesInvoiceListPage />}
                ></Route>

                <Route
                  path="/employeeInvoiceList"
                  element={<EmployeeInvoiceListPage />}
                />
                <Route
                  path="/projectInvoiceList"
                  element={<ProjectInvoiceListPage />}
                ></Route>

                {/* Routing For OutStanding Invoice */}
                <Route
                  path="/outStandingUnpaidHistory"
                  element={<OutStandingUnPaidHistoryPage />}
                ></Route>
                <Route
                  path="/outStandingpaidHistory"
                  element={<OutStandingPaidHistoryPage />}
                ></Route>
                {/* Invoice Report */}
                <Route
                  path="/invoiceReportPage"
                  element={<InvoiceReportPage />}
                ></Route>

                {/* Validate Purchase Order List */}
                <Route
                  path="/projectPOListPage"
                  element={<ProjectPOListPage />}
                ></Route>
                <Route
                  path="/empPOListPage"
                  element={<EmpPOListPage />}
                ></Route>

                <Route
                  path="/otherChargesInvoiceListValPage"
                  element={<OtherChargesInvoiceListValPage />}
                ></Route>

                <Route
                  path="/otherChargesInvoiceList"
                  element={<OtherChargesInvoiceListVal />}
                ></Route>
                <Route
                  path="/updateEmpPOSearchPage"
                  element={<UpdateEmpPOSearchPage />}
                ></Route>

                <Route
                  path="/generateEmployeeInvoice2"
                  element={<GenerateEmployeeInvoice2 />}
                ></Route>
                <Route
                  path="/registerClientVal"
                  element={<RegisterClientVal />}
                ></Route>
                <Route
                  path="/updateClient2"
                  element={<UpdateClient2 />}
                ></Route>
                <Route
                  path="/generateEmployeeInvoiceDate"
                  element={<GenerateEmployeeInvoiceDate />}
                ></Route>

                <Route
                  path="/generateEmployeeInvoiceDate"
                  element={<GenerateEmployeeInvoiceDate />}
                ></Route>

                <Route
                  path="/registerBankPage"
                  element={<RegisterBankPage />}
                ></Route>

                <Route
                  path="/updateBankPage"
                  element={<UpdateBankPage />}
                ></Route>
                <Route
                  path="/updateEmpPONew3"
                  element={<UpdateEmpPONew3 />}
                ></Route>
                <Route
                  path="/registerCompanyProfile"
                  element={<RegisterCompanyProfilePage />}
                ></Route>
                <Route
                  path="/updateCompanyProfile"
                  element={<UpdateCompanyProfilePage />}
                ></Route>
                <Route
                  path="/employeeSummary"
                  element={<EmployeeSummaryPage />}
                ></Route>
                <Route
                  path="/employeePOSummaryPage"
                  element={<EmployeePOSummaryPage />}
                ></Route>
                <Route
                  path="/projectPOSummary"
                  element={<ProjectPOSummaryPage />}
                ></Route>
                <Route
                  path="/tableDataComponent"
                  element={<TableDataComponent />}
                ></Route>
                <Route
                  path="/generateQuotationPage"
                  element={<GenerateQuotationPage />}
                ></Route>
                <Route
                  path="/updateQuotationPage"
                  element={<UpdateQuotationPage />}
                ></Route>
                <Route
                  path="/generateEmployeeInvoiceTest"
                  element={<GenerateEmployeeInvoiceTest />}
                ></Route>
                <Route
                  path="/updateEmployee2"
                  element={<UpdateEmployee2 />}
                ></Route>
                <Route
                  path="/generateProjectInvoiceNewVal"
                  element={<GenerateProjectInvoiceNewVal />}
                ></Route>
                <Route path="/updateBank2" element={<UpdateBank2 />}></Route>
                <Route
                  path="/updateEmployeePageTest"
                  element={<UpdateEmployeePageTest />}
                ></Route>
                <Route
                  path="/UpdateEmpPOTest"
                  element={<UpdateEmpPOTest />}
                ></Route>
                <Route
                  path="/UpdateEmpPOtesting"
                  element={<UpdateEmpPOtesting />}
                ></Route>
                <Route
                  path="/generateEmployeeInvoiceDemo"
                  element={<GenerateEmployeeInvoiceDemo />}
                ></Route>
                <Route
                  path="/projectInvoiceListDemo"
                  element={<ProjectInvoiceListDemo />}
                ></Route>
                <Route
                  path="/outStandingExcel"
                  element={<OutStandingExcel />}
                ></Route>
                <Route
                  path="/generateEmployeeInvoiceDemo"
                  element={<GenerateEmployeeInvoiceDemoNew />}
                ></Route>
                <Route
                  path="/downloadQuatationExcel"
                  element={<DownloadQuatationExcel />}
                ></Route>
                <Route
                  path="/downloadQuotationPDF"
                  element={<DownloadQuotationPDF />}
                ></Route>
                <Route path="/myComponent" element={<MyComponent />}></Route>
                <Route
                  path="/downloadPDFNew"
                  element={<DownloadPDFNew />}
                ></Route>
                <Route
                  path="/downloadPDFNew2"
                  element={<DownloadPDFNew2 />}
                ></Route>

                <Route
                  path="/quotationExcel"
                  element={<QuotationExcel />}
                ></Route>
                <Route
                  path="/quotationWord2"
                  element={<QuotationWord2 />}
                ></Route>
                <Route path="/test3" element={<Test3 />}></Route>
                <Route
                  path="/quotationGenerator"
                  element={<QuotationGenerator />}
                ></Route>
                <Route
                  path="/quotationGenerator2"
                  element={<QuotationGenerator2 />}
                ></Route>
                <Route
                  path="/quotationGenerator3"
                  element={<QuotationGenerator3 />}
                ></Route>
                <Route
                  path="/quotationUpload5"
                  element={<QuotationUpload5 />}
                ></Route>
                <Route
                  path="/autocompleteComponent"
                  element={<AutocompleteComponent />}
                ></Route>
                <Route
                  path="/generateVendor"
                  element={<GenerateVendorPage />}
                ></Route>
                <Route
                  path="/updateVendor"
                  element={<UpdateVendorPage />}
                ></Route>
                <Route
                  path="/notificationGSTPage"
                  element={<NotificationGSTPage />}
                ></Route>
                <Route
                  path="/notificationBGDPage"
                  element={<NotificationBGDPage />}
                ></Route>
                <Route
                  path="/registerExpenseHeader"
                  element={<RegisterExpenseHeaderPage />}
                ></Route>
                <Route
                  path="registerVendorInformation"
                  element={<RegisterVendorInformationPage />}
                ></Route>
                <Route
                  path="/registerClientAgreement"
                  element={<RegisterClientAgreementPage />}
                ></Route>
                <Route
                  path="/updateClientAgreement"
                  element={<UpdateClientAgreementPage />}
                ></Route>
                <Route
                  path="/updateVendorInformation"
                  element={<UpdateVendorInformationPage />}
                ></Route>
                <Route
                  path="/updateExpenseHeader"
                  element={<UpdateExpenseHeaderPage />}
                ></Route>
                <Route
                  path="/navigationDrawer"
                  element={<NavigationDrawer />}
                ></Route>
                <Route
                  path="/clientAgreeMentNotiPage"
                  element={<ClientAgreeMentNotiPage />}
                ></Route>
                <Route
                  path="/updateClientAgreement/:uniqueAgreementCode"
                  element={<UpdateClientAgreementPage />}
                />
                <Route path="/fileUpload" element={<FileUpload />} />
                <Route path="/fileDownload" element={<FileDownload />} />
                <Route
                  path="/fileDownload2"
                  element={<FileViewer fileId={1} />}
                />
                <Route
                  path="/multipleFileUpload"
                  element={ <MultipleFileUpload />}
                />
                 <Route
                  path="/clientAgreementForm"
                  element={ <ClientAgreementForm/>}
                />
                {/* </Routes>
                    </> */}
                {/* }
                /> */}
              </>
            ) : (
              // )}
              // <Route
              //   path="*"
              //   element={
              //     <Navigate
              //       to={isAuthenticated ? "/navigationBarRSuite" : "/erpLogin"}
              //     />
              //   }
              // />
              <Route path="*" element={<Navigate to="/" />} />
            )}
          </Routes>
        </CompanyProvider>
        {/* </AuthProvider> */}
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
