import React, { useState } from 'react';
import axios from 'axios';
import * as XLSX from 'xlsx';
import { myAxiosCommonHost } from '../Service/CorsHelperBaseUrl';

const DownloadQuatationExcel = () => {
  const [loading, setLoading] = useState(false);

  const downloadExcel = async () => {
    setLoading(true);
    try {
      const response = await myAxiosCommonHost.get('gst/quotation/getQuotationList');
      const data = response.data;

      // Prepare the main quotation data
      const quotationData = data.map(item => ({
        "Select Company": item.selectCompany,
        "Quotation Ref No": item.quotationRefNo,
        "Quotation No": item.quotationNo,
        "Quotation Date": item.quotationDate,
        "Client Name And Code": item.clientNameAndCode,
        "Kind Attention": item.kindAttention,
        "Quotation Subject": item.quotationSubject,
        "Quotation Content": item.quotationContent,
        "Total Amount": item.totalAmount,
        "Total In Word": item.totalInWord,
        "Financial Year": item.financialYear,
        "Financial Month": item.financialMonth
      }));

      // Prepare the accommodation data
      const accommodationData = data.flatMap((item, index) =>
        item.accomodationUIList.map(acc => ({
          "Quotation Ref No": item.quotationRefNo,
          "Description": acc.description,
          "Cost Per Resource": acc.costPerResources,
          "No Of Resources": acc.noOfResources,
          "No Of Months": acc.noOfMonth,
          "Total Cost": acc.totalCost
        }))
      );

      // Create a new workbook
      const workbook = XLSX.utils.book_new();

      // Add the main quotation data worksheet
      const quotationWorksheet = XLSX.utils.json_to_sheet(quotationData);
      XLSX.utils.book_append_sheet(workbook, quotationWorksheet, 'Quotations');

      // Add the accommodation data worksheet
      const accommodationWorksheet = XLSX.utils.json_to_sheet(accommodationData);
      XLSX.utils.book_append_sheet(workbook, accommodationWorksheet, 'Accommodations');

      // Generate a Blob from the workbook
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      // Create a download link and click it programmatically
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'quotation_list.xlsx');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading the Excel file:", error);
      alert('Error downloading the Excel file. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {loading ? (
        <div className="loading-animation">
          {/* Replace with your actual loading animation component */}
          Loading...
        </div>
      ) : (
        <button onClick={downloadExcel}>Download Excel</button>
      )}
    </div>
  );
};

export default DownloadQuatationExcel;
