import Headers from "../../../Pages/Header";
import OtherChargesInvoiceList from "../OtherChargesInvoiceList";

const OtherChargesInvoiceListPage = () => {
  return (
    <Headers>
      <OtherChargesInvoiceList />
    </Headers>
  );
};
export default OtherChargesInvoiceListPage;