import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Collapse,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  TablePagination,
  Grid,
  Tab,
  Tabs,
  Box,
} from "@mui/material";
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  Edit as EditIcon,
  Search,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import { myAxiosCommonHost } from "../Service/CorsHelperBaseUrl";

const Row = ({ row, onDelete, onUpdate }) => {
  const [open, setOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [editedClient, setEditedClient] = useState({ ...row });
  const [editedGstDetails, setEditedGstDetails] = useState({
    ...row.gstdetailsUI,
  });
  const [editedContractDate, setEditedContractDate] = useState({
    ...row.contractdateUI,
  });
  const [editedContactPersons, setEditedContactPersons] = useState(
    Array.isArray(row.contactpersonUI) ? [...row.contactpersonUI] : []
  );
  const [tabValue, setTabValue] = useState(0);

  const handleEdit = () => {
    setDialogOpen(true);
  };

  const handleDelete = () => {
    onDelete(row.clientCode);
  };

  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    setEditedClient((prevClient) => ({ ...prevClient, [name]: value }));
  };

  const handleContactPersonChange = (e, index) => {
    const { name, value } = e.target;
    const updatedContactPersons = [...editedContactPersons];
    updatedContactPersons[index] = {
      ...updatedContactPersons[index],
      [name]: value,
    };
    setEditedContactPersons(updatedContactPersons);
  };

  const handleGstDetailsChange = (e) => {
    const { name, value } = e.target;
    setEditedGstDetails((prevGstDetails) => ({
      ...prevGstDetails,
      [name]: value,
    }));
  };

  const handleContractDateChange = (e) => {
    const { name, value } = e.target;
    setEditedContractDate((prevContractDate) => ({
      ...prevContractDate,
      [name]: value,
    }));
  };

  const handleSave = () => {
    const updatedClient = {
      ...editedClient,
      gstdetailsUI: editedGstDetails,
      contactpersonUI: editedContactPersons,
      contractdateUI: editedContractDate,
    };
    onUpdate(updatedClient);
    setDialogOpen(false);
  };

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell>{row.clientCode}</TableCell>
        <TableCell>{row.clientName}</TableCell>
        <TableCell>{row.clientType}</TableCell>
        <TableCell>
          <Button onClick={handleEdit} variant="contained" color="primary">
            Update
          </Button>
          <Button
            onClick={handleDelete}
            variant="contained"
            color="error"
            style={{ marginLeft: "8px" }}
          >
            Delete
          </Button>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 10, paddingTop: 10 }} colSpan={5}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <div>
              <Box style={{ padding: 20 }}>
                <Typography variant="h6">Basic Information</Typography>
                <TableRow
                  style={{
                    backgroundColor: "darkcyan",
                    fontWeight: "bold",
                    color: "darkblue",
                  }}
                >
                  <TableCell>Client Code:</TableCell>
                  <TableCell>Client Name:</TableCell>
                  <TableCell>Client Type:</TableCell>
                  <TableCell>Client Address:</TableCell>
                  <TableCell>Client RegNumber:</TableCell>
                  <TableCell>Client Website:</TableCell>
                  <TableCell>Client ContactNumber:</TableCell>
                  <TableCell>Billing Period:</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{row.clientCode || "N/A"}</TableCell>
                  <TableCell>{row.clientName || "N/A"}</TableCell>
                  <TableCell>{row.clientType || "N/A"}</TableCell>
                  <TableCell>{row.clientAddress || "N/A"}</TableCell>
                  <TableCell>{row.clientRegNumber || "N/A"}</TableCell>
                  <TableCell>{row.clientWebsite || "N/A"}</TableCell>
                  <TableCell> {row.clientContactNumber || "N/A"}</TableCell>
                  <TableCell>{row.billingPeriod || "N/A"}</TableCell>
                </TableRow>

                <Typography variant="h6">GST Details</Typography>
                <TableRow
                  style={{
                    backgroundColor: "darkcyan",
                    fontWeight: "bold",
                    color: "darkblue",
                  }}
                >
                  <TableCell>State</TableCell>
                  <TableCell>State Code</TableCell>
                  <TableCell>PAN Number</TableCell>
                  <TableCell>GSTIN</TableCell>
                  <TableCell>HSN/SAC</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{row.gstdetailsUI.state || "N/A"}</TableCell>
                  <TableCell>{row.gstdetailsUI.stateCode || "N/A"}</TableCell>
                  <TableCell>{row.gstdetailsUI.panNumber || "N/A"}</TableCell>
                  <TableCell>{row.gstdetailsUI.gstin || "N/A"}</TableCell>
                  <TableCell>{row.gstdetailsUI.hsnsac || "N/A"}</TableCell>
                </TableRow>
                <Typography variant="h6">Contract Date</Typography>
                <TableRow
                  style={{
                    backgroundColor: "darkcyan",
                    fontWeight: "bold",
                    color: "darkblue",
                  }}
                >
                  <TableCell>Start Date</TableCell>
                  <TableCell>End Date</TableCell>
                  <TableCell>MSA</TableCell>
                  <TableCell>NDA</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{row.contractdateUI.startDate || "N/A"}</TableCell>
                  <TableCell>{row.contractdateUI.endDate || "N/A"}</TableCell>
                  <TableCell>{row.contractdateUI.msa || "N/A"}</TableCell>
                  <TableCell>{row.contractdateUI.nda || "N/A"}</TableCell>
                </TableRow>
                {/* <Typography variant="h6">Contact Persons</Typography>
                {row.contactpersonUI.map((contactPerson, index) => (
                  <div key={index}>
                    <TableRow
                      style={{
                        backgroundColor: "darkcyan",
                        fontWeight: "bold",
                        color: "darkblue",
                      }}
                    >
                      <TableCell> Contact Person {index + 1}: </TableCell>
                      <TableCell> Designation{index + 1}:</TableCell>
                      <TableCell>Email ID{index + 1}</TableCell>
                      <TableCell> Contact Number{index + 1}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        {contactPerson.contactPersonName || "N/A"}
                      </TableCell>
                      <TableCell>
                        {contactPerson.designation || "N/A"}
                      </TableCell>
                      <TableCell>{contactPerson.emailID || "N/A"}</TableCell>
                      <TableCell>
                        {contactPerson.contactNumber || "N/A"}
                      </TableCell>
                    </TableRow>
                  </div>
                ))} */}
                <Typography variant="h6">Contact Persons</Typography>
                {row.contactpersonUI ? (
                  row.contactpersonUI.map((contactPerson, index) => (
                    <div key={index}>
                      <TableRow
                        style={{
                          backgroundColor: "darkcyan",
                          fontWeight: "bold",
                          color: "darkblue",
                        }}
                      >
                        <TableCell> Contact Person {index + 1}: </TableCell>
                        <TableCell> Designation{index + 1}:</TableCell>
                        <TableCell>Email ID{index + 1}</TableCell>
                        <TableCell> Contact Number{index + 1}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          {contactPerson?.contactPersonName || "N/A"}
                        </TableCell>
                        <TableCell>
                          {contactPerson?.designation || "N/A"}
                        </TableCell>
                        <TableCell>{contactPerson?.emailID || "N/A"}</TableCell>
                        <TableCell>
                          {contactPerson?.contactNumber || "N/A"}
                        </TableCell>
                      </TableRow>
                    </div>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      No contact persons found
                    </TableCell>
                  </TableRow>
                )}
              </Box>
            </div>
          </Collapse>
        </TableCell>
      </TableRow>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        maxWidth="md"
        maxHeight="md"
        fullWidth
      >
        <DialogTitle>Edit Client Information</DialogTitle>
        <DialogContent>
          <Tabs
            value={tabValue}
            onChange={(event, newValue) => setTabValue(newValue)}
            variant="fullWidth"
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="Basic Information" />
            <Tab label="GST Details" />
            <Tab label="Contract Date" />
            <Tab label="Contact Persons" />
          </Tabs>
          {tabValue === 0 && (
            <>
              <Paper
                elevation={3}
                style={{ padding: 10, marginBottom: 10, marginTop: 10 }}
              >
                <Grid container spacing={6} style={{ marginTop: 10 }}>
                  <Grid item xl={4}>
                    <TextField
                      label="Client Code"
                      name="clientCode"
                      value={editedClient.clientCode || "N/A"}
                      onChange={handleFieldChange}
                      fullWidth
                      disabled
                      variant="standard"
                      //sx={{ marginBottom: 2, marginTop: 2 }}
                    />
                  </Grid>
                  <Grid item xl={4}>
                    <TextField
                      label="Client Name"
                      name="clientName"
                      value={editedClient.clientName || "N/A"}
                      onChange={handleFieldChange}
                      fullWidth
                      variant="standard"
                      //sx={{ marginBottom: 2, marginTop: 2 }}
                    />
                  </Grid>
                  <Grid item xl={4}>
                    <TextField
                      label="Client Type"
                      name="clientType"
                      value={editedClient.clientType || "N/A"}
                      onChange={handleFieldChange}
                      fullWidth
                      variant="standard"
                      //sx={{ marginBottom: 2, marginTop: 2 }}
                    />
                  </Grid>
                  <Grid item xl={4}>
                    <TextField
                      label="Client Address"
                      name="clientAddress"
                      value={editedClient.clientAddress || "N/A"}
                      onChange={handleFieldChange}
                      fullWidth
                      variant="standard"
                      //sx={{ marginBottom: 2, marginTop: 2 }}
                    />
                  </Grid>
                  <Grid item xl={4}>
                    <TextField
                      label="Client Registration Number"
                      name="clientRegNumber"
                      value={editedClient.clientRegNumber || "N/A"}
                      onChange={handleFieldChange}
                      fullWidth
                      variant="standard"
                      //sx={{ marginBottom: 2, marginTop: 2 }}
                    />
                  </Grid>
                  <Grid item xl={4}>
                    <TextField
                      label="Client Website"
                      name="clientWebsite"
                      value={editedClient.clientWebsite || "N/A"}
                      onChange={handleFieldChange}
                      fullWidth
                      variant="standard"
                      //sx={{ marginBottom: 2, marginTop: 2 }}
                    />
                  </Grid>
                  <Grid item xl={4}>
                    <TextField
                      label="Client Contact Number"
                      name="clientContactNumber"
                      value={editedClient.clientContactNumber || "N/A"}
                      onChange={handleFieldChange}
                      fullWidth
                      variant="standard"
                      // sx={{ marginBottom: 2, marginTop: 2 }}
                    />
                  </Grid>
                  <Grid item xl={4}>
                    <TextField
                      label="Billing Period"
                      name="billingPeriod"
                      value={editedClient.billingPeriod || "N/A"}
                      onChange={handleFieldChange}
                      fullWidth
                      variant="standard"
                      // sx={{ marginBottom: 2, marginTop: 2 }}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </>
          )}
          {tabValue === 1 && (
            <>
              <Typography variant="h6">GST Details</Typography>
              <Paper
                elevation={3}
                style={{ padding: 10, marginBottom: 10, marginTop: 10 }}
              >
                <Grid container spacing={6} style={{ marginTop: 10 }}>
                  <Grid item xl={4}>
                    <TextField
                      label="State"
                      name="state"
                      value={editedGstDetails.state || "N/A"}
                      onChange={handleGstDetailsChange}
                      fullWidth
                      variant="standard"
                      // sx={{ marginBottom: 2, marginTop: 2 }}
                    />
                  </Grid>
                  <Grid item xl={4}>
                    <TextField
                      label="State Code"
                      name="stateCode"
                      value={editedGstDetails.stateCode || "N/A"}
                      onChange={handleGstDetailsChange}
                      fullWidth
                      variant="standard"
                      // sx={{ marginBottom: 2, marginTop: 2 }}
                    />
                  </Grid>
                  <Grid item xl={4}>
                    <TextField
                      label="PAN Number"
                      name={"panNumber"}
                      value={editedGstDetails.panNumber || "N/A"}
                      onChange={handleGstDetailsChange}
                      fullWidth
                      variant="standard"
                      // sx={{ marginBottom: 2, marginTop: 2 }}
                    />
                  </Grid>
                  <Grid item xl={4}>
                    <TextField
                      label="GSTIN"
                      name={"gstin"}
                      value={editedGstDetails.gstin || "N/A"}
                      onChange={handleGstDetailsChange}
                      fullWidth
                      variant="standard"
                      // sx={{ marginBottom: 2, marginTop: 2 }}
                    />
                  </Grid>
                  <Grid item xl={4}>
                    <TextField
                      label="HSN/SAC"
                      name={"hsnsac"}
                      value={editedGstDetails.hsnsac || "N/A"}
                      onChange={handleGstDetailsChange}
                      fullWidth
                      variant="standard"
                      // sx={{ marginBottom: 2, marginTop: 2 }}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </>
          )}
          {tabValue === 2 && (
            <>
              <Typography variant="h6">Contract Date</Typography>
              <Paper
                elevation={3}
                style={{ padding: 10, marginBottom: 10, marginTop: 10 }}
              >
                <Grid container spacing={6} style={{ marginTop: 10 }}>
                  <Grid item xl={4}>
                    <TextField
                      label="Start Date"
                      name="startDate"
                      value={editedContractDate.startDate || "N/A"}
                      onChange={handleContractDateChange}
                      fullWidth
                      variant="standard"
                      //sx={{ marginBottom: 2, marginTop: 2 }}
                    />
                  </Grid>

                  <Grid item xl={4}>
                    <TextField
                      label="End Date"
                      name="endDate"
                      value={editedContractDate.endDate || "N/A"}
                      onChange={handleContractDateChange}
                      fullWidth
                      variant="standard"
                      //sx={{ marginBottom: 2, marginTop: 2 }}
                    />
                  </Grid>
                  <Grid item xl={4}>
                    <TextField
                      label="MSA"
                      name="msa"
                      value={editedContractDate.msa || "N/A"}
                      onChange={handleContractDateChange}
                      fullWidth
                      variant="standard"
                      //sx={{ marginBottom: 2, marginTop: 2 }}
                    />
                  </Grid>
                  <Grid item xl={4}>
                    <TextField
                      label="NDA"
                      name="nda"
                      value={editedContractDate.nda || "N/A"}
                      onChange={handleContractDateChange}
                      fullWidth
                      variant="standard"
                      //sx={{ marginBottom: 2, marginTop: 2 }}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </>
          )}
          {tabValue === 3 && (
            <>
              <Typography variant="h6">Contact Persons</Typography>
              {editedContactPersons.map((contactPerson, index) => (
                <Paper
                  key={index}
                  elevation={3}
                  style={{ padding: 10, marginBottom: 10, marginTop: 10 }}
                >
                  <Grid container spacing={4} style={{ marginTop: 10 }}>
                    <Grid item xl={4}>
                      <TextField
                        label={`Contact Person Name ${index + 1}`}
                        name={"contactPersonName"}
                        value={contactPerson.contactPersonName || "N/A"}
                        onChange={(e) => handleContactPersonChange(e, index)}
                        fullWidth
                        //sx={{ marginBottom: 2, marginTop: 2 }}
                        variant="standard"
                      />
                    </Grid>
                    <Grid item xl={4}>
                      <TextField
                        label={`Designation ${index + 1}`}
                        name="designation"
                        value={contactPerson.designation || "N/A"}
                        onChange={(e) => handleContactPersonChange(e, index)}
                        fullWidth
                        variant="standard"
                        //sx={{ marginBottom: 2, marginTop: 2 }}
                      />
                    </Grid>

                    <Grid item xl={4}>
                      <TextField
                        label={`Email ID ${index + 1}`}
                        name="emailID"
                        value={contactPerson.emailID || "N/A"}
                        onChange={(e) => handleContactPersonChange(e, index)}
                        fullWidth
                        variant="standard"
                        //sx={{ marginBottom: 2, marginTop: 2 }}
                      />
                    </Grid>
                    <Grid item xl={4}>
                      <TextField
                        label={`Contact Number ${index + 1}`}
                        name={"contactNumber"}
                        value={contactPerson.contactNumber || "N/A"}
                        onChange={(e) => handleContactPersonChange(e, index)}
                        fullWidth
                        variant="standard"
                        //sx={{ marginBottom: 2, marginTop: 2 }}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              ))}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`}>
      {value === index && <div>{children}</div>}
    </div>
  );
}

const UpdateClient2 = () => {
  const [clientData, setClientData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchClientData = () => {
      myAxiosCommonHost
        .get("gst/client/getClientList")
        .then((response) => {
          setClientData(response.data);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching client data:", error);
          setError(error); // Set the error state
          setLoading(false);
        });
    };

    fetchClientData();
  }, []);

  const handleUpdateClient = (updatedClient) => {
    myAxiosCommonHost
      .put("gst/client/updateClient", updatedClient)
      .then((response) => {
        console.log("Client data updated successfully.");
        alert("Client data updated successfully.");
        // Update the client data state with the new data
        setClientData((prevClientData) =>
          prevClientData.map((client) => {
            if (client.clientCode === updatedClient.clientCode) {
              return updatedClient;
            }
            return client;
          })
        );
      })
      .catch((error) => {
        console.error("Error updating client data: ", error);
        alert("Client data not updated successfully.");
      });
  };

  const handleDeleteClient = (clientCode) => {
    // Delete the client with the given clientCode
    if (window.confirm("Are you sure you want to delete this Client?")) {
      myAxiosCommonHost
        .delete(`gst/client/deleteClient/clientCode/${clientCode}`)
        .then((response) => {
          console.log("Client deleted successfully.");
          setClientData();
          alert("Client deleted successfully.");
        })
        .catch((error) => {
          console.error("Error deleting client: ", error);
          alert("Error deleting Client.");
        });
    }
  };

  return (
    <div>
      <TextField
        label="Search by Client Name"
        variant="standard"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        style={{ float: "right", margin: "10px" }}
      />
      <Link to="/registerClient">
        <Button variant="contained" color="primary" style={{ margin: "10px" }}>
          Register Client
        </Button>
      </Link>
      <Paper
        elevation={3}
        style={{
          padding: 20,
          marginBottom: 20,
          marginTop: 20,
          backgroundColor: "#2196f3",
          color: "white",
          padding: "10px",
          textAlign: "center",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography
            variant="h5"
            gutterBottom
            style={{ marginBottom: "15px", color: "darkblue" }}
          >
            Client List
          </Typography>
        </div>
      </Paper>

      {error ? (
        <p>Error fetching data. Please try again.</p>
      ) : (
        <TableContainer component={Paper} style={{ height: "20%" }}>
          {loading ? (
            <p>Loading...</p>
          ) : (
            <>
              <Table>
                <TableHead>
                  <TableRow
                    style={{
                      backgroundColor: "darkcyan",
                      fontWeight: "bold",
                      color: "darkblue",
                    }}
                  >
                    <TableCell />
                    <TableCell>Client Code</TableCell>
                    <TableCell>Client Name</TableCell>
                    <TableCell>Client Type</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {clientData.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={5} align="center">
                        No clients found
                      </TableCell>
                    </TableRow>
                  ) : (
                    clientData
                      .filter((client) =>
                        client.clientName
                          .toLowerCase()
                          .includes(searchTerm.toLowerCase())
                      )
                      .map((client) => (
                        <Row
                          key={client.clientCode}
                          row={client}
                          onDelete={handleDeleteClient}
                          onUpdate={handleUpdateClient}
                        />
                      ))
                  )}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[]}
                component="div"
                count={clientData.length}
                rowsPerPage={2}
                page={0}
                onPageChange={(event, newPage) => {}}
              />
            </>
          )}
        </TableContainer>
      )}
    </div>
  );
};

export default UpdateClient2;
