import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Collapse,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  TablePagination,
  Grid,
  Tab,
  Tabs,
  Box,
  CircularProgress,
  Backdrop,
  MenuItem,
} from "@material-ui/core";
import { Autocomplete } from "@mui/material";
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  Edit as EditIcon,
  Search,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import * as XLSX from "xlsx";
import { myAxiosCommonHost } from "../../Service/CorsHelperBaseUrl";
import { saveAs } from "file-saver";
import { CompanyContext } from "../StateProvider/CompanyProvider";

const Row = ({ row, onDelete, onUpdate }) => {
  const [open, setOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [editedClient, setEditedClient] = useState({ ...row });
  const [editedGstDetails, setEditedGstDetails] = useState({
    ...row.gstdetailsUI,
  });
  const [editedContractDate, setEditedContractDate] = useState({
    ...row.contractdateUI,
  });
  const [editedContactPersons, setEditedContactPersons] = useState(
    Array.isArray(row.contactpersonUI) ? [...row.contactpersonUI] : []
  );
  const [tabValue, setTabValue] = useState(0);
  const [isDataChangedEdit, setIsDataChangedEdit] = useState(false);

  const handleEdit = () => {
    setDialogOpen(true);
  };

  const handleDelete = () => {
    onDelete(row.clientCode);
  };

  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    setEditedClient((prevClient) => ({ ...prevClient, [name]: value }));
    setIsDataChangedEdit(true);
  };

  const handleContactPersonChange = (e, index) => {
    const { name, value } = e.target;
    const updatedContactPersons = [...editedContactPersons];
    updatedContactPersons[index] = {
      ...updatedContactPersons[index],
      [name]: value,
    };
    setEditedContactPersons(updatedContactPersons);
    setIsDataChangedEdit(true);
  };
  const handleAddContactpersons = () => {
    setEditedContactPersons([
      ...editedContactPersons,
      {
        contactPersonName: "",
        designation: "",
        emailID: "",
        contactNumber: "",
      },
    ]);
  };
  const handleDeleteContactpersons = (index) => {
    const updatedContactPersons = [...editedContactPersons];
    updatedContactPersons.splice(index, 1);
    setEditedContactPersons(updatedContactPersons);
    setIsDataChangedEdit(true);
  };

  const handleGstDetailsChange = (e) => {
    const { name, value } = e.target;
    setEditedGstDetails((prevGstDetails) => ({
      ...prevGstDetails,
      [name]: value,
    }));
    setIsDataChangedEdit(true);
  };

  const handleContractDateChange = (e) => {
    const { name, value } = e.target;
    setEditedContractDate((prevContractDate) => ({
      ...prevContractDate,
      [name]: value,
    }));
    setIsDataChangedEdit(true);
  };

  const handleSave = () => {
    const updatedClient = {
      ...editedClient,
      gstdetailsUI: editedGstDetails,
      contactpersonUI: editedContactPersons,
      contractdateUI: editedContractDate,
    };
    onUpdate(updatedClient);
    setDialogOpen(false);
  };
  if (!editedClient) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell>{row.clientCode}</TableCell>
        <TableCell>{row.clientName}</TableCell>
        <TableCell>{row.clientType}</TableCell>
        <TableCell>
          <Button onClick={handleEdit} variant="contained" color="primary">
            Update
          </Button>
          <Button
            onClick={handleDelete}
            color="error"
            variant="contained"
            style={{
              marginLeft: "8px",
              backgroundColor: "red",
              color: "white",
            }}
          >
            Delete
          </Button>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 10, paddingTop: 10 }} colSpan={5}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <div>
              <Box style={{ padding: 20 }}>
                <Typography variant="h6">Basic Information</Typography>
                <TableRow
                  style={{
                    backgroundColor: "darkcyan",
                    fontWeight: "bold",
                    color: "darkblue",
                  }}
                >
                  <TableCell>Select Company:</TableCell>
                  <TableCell>Client Code:</TableCell>
                  <TableCell>Client Name:</TableCell>
                  <TableCell>Client Type:</TableCell>
                  <TableCell>Client Address:</TableCell>
                  <TableCell>Client RegNumber:</TableCell>
                  <TableCell>Client Website:</TableCell>
                  <TableCell>Client ContactNumber:</TableCell>
                  <TableCell>Billing Period:</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{row.selectCompany || "N/A"}</TableCell>
                  <TableCell>{row.clientCode || "N/A"}</TableCell>
                  <TableCell>{row.clientName || "N/A"}</TableCell>
                  <TableCell>{row.clientType || "N/A"}</TableCell>
                  <TableCell>{row.clientAddress || "N/A"}</TableCell>
                  <TableCell>{row.clientRegNumber || "N/A"}</TableCell>
                  <TableCell>{row.clientWebsite || "N/A"}</TableCell>
                  <TableCell> {row.clientContactNumber || "N/A"}</TableCell>
                  <TableCell>{row.billingPeriod || "N/A"}</TableCell>
                </TableRow>
                <Typography variant="h6">GST Details</Typography>
                {row.gstdetailsUI ? (
                  <div>
                    <TableRow
                      style={{
                        backgroundColor: "darkcyan",
                        fontWeight: "bold",
                        color: "darkblue",
                      }}
                    >
                      <TableCell>State</TableCell>
                      <TableCell>State Code</TableCell>
                      <TableCell>PAN Number</TableCell>
                      <TableCell>GSTIN</TableCell>
                      <TableCell>HSN/SAC</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{row.gstdetailsUI.state || "N/A"}</TableCell>
                      <TableCell>
                        {row.gstdetailsUI.stateCode || "N/A"}
                      </TableCell>
                      <TableCell>
                        {row.gstdetailsUI.panNumber || "N/A"}
                      </TableCell>
                      <TableCell>{row.gstdetailsUI.gstin || "N/A"}</TableCell>
                      <TableCell>{row.gstdetailsUI.hsnsac || "N/A"}</TableCell>
                    </TableRow>
                  </div>
                ) : (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      No GST Details Found
                    </TableCell>
                  </TableRow>
                )}
                <Typography variant="h6">Contract Date</Typography>
                <TableRow
                  style={{
                    backgroundColor: "darkcyan",
                    fontWeight: "bold",
                    color: "darkblue",
                  }}
                >
                  <TableCell>Start Date</TableCell>
                  <TableCell>End Date</TableCell>
                  <TableCell>MSA</TableCell>
                  <TableCell>NDA</TableCell>
                </TableRow>
                {row.contractdateUI ? (
                  <TableRow>
                    <TableCell>
                      {row.contractdateUI.startDate
                        ? row.contractdateUI.startDate
                            .split("-")
                            .reverse()
                            .join("-")
                        : "N/A"}
                    </TableCell>
                    <TableCell>
                      {row.contractdateUI.endDate
                        ? row.contractdateUI.endDate
                            .split("-")
                            .reverse()
                            .join("-")
                        : "N/A"}
                    </TableCell>
                    <TableCell>{row.contractdateUI.msa || "N/A"}</TableCell>
                    <TableCell>{row.contractdateUI.nda || "N/A"}</TableCell>
                  </TableRow>
                ) : (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      No Contract Date Found
                    </TableCell>
                  </TableRow>
                )}
                {/* <Typography variant="h6">Contact Persons</Typography>
                {row.contactpersonUI.map((contactPerson, index) => (
                  <div key={index}>
                    <TableRow
                      style={{
                        backgroundColor: "darkcyan",
                        fontWeight: "bold",
                        color: "darkblue",
                      }}
                    >
                      <TableCell> Contact Person {index + 1}: </TableCell>
                      <TableCell> Designation{index + 1}:</TableCell>
                      <TableCell>Email ID{index + 1}</TableCell>
                      <TableCell> Contact Number{index + 1}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        {contactPerson.contactPersonName || "N/A"}
                      </TableCell>
                      <TableCell>
                        {contactPerson.designation || "N/A"}
                      </TableCell>
                      <TableCell>{contactPerson.emailID || "N/A"}</TableCell>
                      <TableCell>
                        {contactPerson.contactNumber || "N/A"}
                      </TableCell>
                    </TableRow>
                  </div>
                ))} */}
                <Typography variant="h6">Contact Persons</Typography>
                {row.contactpersonUI ? (
                  row.contactpersonUI.map((contactPerson, index) => (
                    <div key={index}>
                      <TableRow
                        style={{
                          backgroundColor: "darkcyan",
                          fontWeight: "bold",
                          color: "darkblue",
                        }}
                      >
                        <TableCell> Contact Person {index + 1}: </TableCell>
                        <TableCell> Designation{index + 1}:</TableCell>
                        <TableCell>Email ID{index + 1}</TableCell>
                        <TableCell> Contact Number{index + 1}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          {contactPerson?.contactPersonName || "N/A"}
                        </TableCell>
                        <TableCell>
                          {contactPerson?.designation || "N/A"}
                        </TableCell>
                        <TableCell>{contactPerson?.emailID || "N/A"}</TableCell>
                        <TableCell>
                          {contactPerson?.contactNumber || "N/A"}
                        </TableCell>
                      </TableRow>
                    </div>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      No contact persons found
                    </TableCell>
                  </TableRow>
                )}
              </Box>
            </div>
          </Collapse>
        </TableCell>
      </TableRow>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        maxWidth="lg"
        maxHeight="lg"
        fullWidth
      >
        <DialogTitle>Edit Client Information</DialogTitle>
        <DialogContent>
          <Tabs
            value={tabValue}
            onChange={(event, newValue) => setTabValue(newValue)}
            variant="fullWidth"
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="Basic Information" />
            <Tab label="GST Details" />
            <Tab label="Contract Date" />
            <Tab label="Contact Persons" />
          </Tabs>
          {tabValue === 0 && (
            <>
              <Paper
                elevation={3}
                style={{ padding: 10, marginBottom: 10, marginTop: 10 }}
              >
                <Grid container spacing={6} style={{ marginTop: 10 }}>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="Select Company"
                      name="selectCompany"
                      value={editedClient.selectCompany || ""}
                      onChange={handleFieldChange}
                      fullWidth
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="Client Code"
                      name="clientCode"
                      value={editedClient.clientCode || ""}
                      onChange={handleFieldChange}
                      fullWidth
                      disabled
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="Client Name"
                      name="clientName"
                      value={editedClient.clientName || ""}
                      onChange={handleFieldChange}
                      fullWidth
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={2} md={4}>
                    <Autocomplete
                      options={[
                        "Automobile",
                        "IT",
                        "Manufacturing",
                        "Marketing",
                      ]}
                      value={editedClient.clientType || null}
                      onChange={(event, newValue) =>
                        handleFieldChange({
                          target: { name: "clientType", value: newValue },
                        })
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Client Type"
                          fullWidth
                          variant="standard"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="Client Address"
                      name="clientAddress"
                      value={editedClient.clientAddress || ""}
                      onChange={handleFieldChange}
                      fullWidth
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="Client Registration Number"
                      name="clientRegNumber"
                      value={editedClient.clientRegNumber || ""}
                      onChange={handleFieldChange}
                      fullWidth
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="Client Website"
                      name="clientWebsite"
                      value={editedClient.clientWebsite || ""}
                      onChange={handleFieldChange}
                      fullWidth
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="Client Contact Number"
                      name="clientContactNumber"
                      value={editedClient.clientContactNumber || ""}
                      onChange={handleFieldChange}
                      fullWidth
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="Billing Period"
                      name="billingPeriod"
                      value={editedClient.billingPeriod || ""}
                      onChange={handleFieldChange}
                      fullWidth
                      variant="standard"
                    />
                  </Grid>
                </Grid>
              </Paper>
            </>
          )}
          {tabValue === 1 && (
            <>
              <Typography variant="h6">GST Details</Typography>
              <Paper
                elevation={3}
                style={{ padding: 10, marginBottom: 10, marginTop: 10 }}
              >
                <Grid container spacing={6} style={{ marginTop: 10 }}>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="State"
                      name="state"
                      value={editedGstDetails.state || "N/A"}
                      onChange={handleGstDetailsChange}
                      fullWidth
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="State Code"
                      name="stateCode"
                      value={editedGstDetails.stateCode || "N/A"}
                      onChange={handleGstDetailsChange}
                      fullWidth
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="PAN Number"
                      name={"panNumber"}
                      value={editedGstDetails.panNumber || "N/A"}
                      onChange={handleGstDetailsChange}
                      fullWidth
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="GSTIN"
                      name={"gstin"}
                      value={editedGstDetails.gstin || "N/A"}
                      onChange={handleGstDetailsChange}
                      fullWidth
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="HSN/SAC"
                      name={"hsnsac"}
                      value={editedGstDetails.hsnsac || "N/A"}
                      onChange={handleGstDetailsChange}
                      fullWidth
                      variant="standard"
                    />
                  </Grid>
                </Grid>
              </Paper>
            </>
          )}
          {tabValue === 2 && (
            <>
              <Typography variant="h6">Contract Date</Typography>
              <Paper
                elevation={3}
                style={{ padding: 10, marginBottom: 10, marginTop: 10 }}
              >
                <Grid container spacing={6} style={{ marginTop: 10 }}>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="Start Date"
                      name="startDate"
                      type="date"
                      value={editedContractDate.startDate}
                      onChange={handleContractDateChange}
                      fullWidth
                      variant="standard"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={2} md={4}>
                    <TextField
                      label="End Date"
                      name="endDate"
                      type="date"
                      value={editedContractDate.endDate}
                      onChange={handleContractDateChange}
                      fullWidth
                      variant="standard"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="MSA"
                      name="msa"
                      value={editedContractDate.msa || "N/A"}
                      onChange={handleContractDateChange}
                      fullWidth
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xl={4}>
                    <TextField
                      label="NDA"
                      name="nda"
                      value={editedContractDate.nda || "N/A"}
                      onChange={handleContractDateChange}
                      fullWidth
                      variant="standard"
                    />
                  </Grid>
                </Grid>
              </Paper>
            </>
          )}
          {tabValue === 3 && (
            <>
              <Typography variant="h6">Contact Persons</Typography>
              {editedContactPersons.map((contactPerson, index) => (
                <Paper
                  key={index}
                  elevation={3}
                  style={{ padding: 10, marginBottom: 10, marginTop: 10 }}
                >
                  <Grid container spacing={4} style={{ marginTop: 10 }}>
                    <Grid item xs={2} md={4}>
                      <TextField
                        label={`Contact Person Name ${index + 1}`}
                        name={"contactPersonName"}
                        value={contactPerson.contactPersonName}
                        onChange={(e) => handleContactPersonChange(e, index)}
                        fullWidth
                        variant="standard"
                      />
                    </Grid>
                    <Grid item xs={2} md={4}>
                      <TextField
                        label={`Designation ${index + 1}`}
                        name="designation"
                        value={contactPerson.designation}
                        onChange={(e) => handleContactPersonChange(e, index)}
                        fullWidth
                        variant="standard"
                      />
                    </Grid>

                    <Grid item xs={2} md={4}>
                      <TextField
                        label={`Email ID ${index + 1}`}
                        name="emailID"
                        value={contactPerson.emailID}
                        onChange={(e) => handleContactPersonChange(e, index)}
                        fullWidth
                        variant="standard"
                      />
                    </Grid>
                    <Grid item xs={2} md={4}>
                      <TextField
                        label={`Contact Number ${index + 1}`}
                        name={"contactNumber"}
                        value={contactPerson.contactNumber}
                        onChange={(e) => handleContactPersonChange(e, index)}
                        fullWidth
                        variant="standard"
                      />
                      <Paper
                        elevation={1}
                        style={{ padding: 5, marginBottom: 5, marginTop: 5 }}
                      >
                        <Button
                          variant="contained"
                          onClick={() => handleDeleteContactpersons(index)}
                          style={{ backgroundColor: "red", color: "white" }}
                        >
                          Delete
                        </Button>
                      </Paper>
                    </Grid>
                  </Grid>
                </Paper>
              ))}
              <Paper
                elevation={2}
                style={{ padding: 5, marginBottom: 5, marginTop: 5 }}
              >
                <Button
                  variant="contained"
                  onClick={handleAddContactpersons}
                  style={{ backgroundColor: "blue", color: "white" }}
                >
                  Add Contact Persons
                </Button>
              </Paper>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleSave} disabled={!isDataChangedEdit}>
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`}>
      {value === index && <div>{children}</div>}
    </div>
  );
}

const UpdateClient = () => {
  const [clientData, setClientData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false); // State to control deletion overlay
  const [isUpdating, setIsUpdating] = useState(false); // State to control update overlay
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0); // Initialize total count state
  const [searchClientsCode, setSearchClientsCode] = useState("");
  const [searchClientName, setSearchClientName] = useState("");
  const [company, setCompany] = useState([]);
  // const [selectedCompany, setSelectedCompany] = useState("");
  const [selectedOption, setSelectedOption] = useState("1");
  const { selectedCompany } = useContext(CompanyContext);

  // useEffect(() => {
  //   async function fetchCompany() {
  //     try {
  //       const response = await myAxiosCommonHost.get(
  //         "gst/profile/getCompanyCodeList"
  //       );
  //       const companies = response.data;
  //       setCompany(companies);
  //       if (companies.length > 0) {
  //         setSelectedCompany(companies[0]); // Set the default company to the first one
  //       }
  //     } catch (error) {
  //       console.error("Error fetching company data:", error);
  //     }
  //   }
  //   fetchCompany();
  // }, []);

  const fetchClientCount = () => {
    myAxiosCommonHost
      .get("gst/client/getClientCount")
      .then((response) => {
        const count = parseInt(response.data); // Extract count from API response
        setTotalCount(count); // Set total count state
      })
      .catch((error) => {
        console.error("Error fetching employee count:", error);
      });
  };
  useEffect(() => {
    if (selectedCompany) {
      fetchClientData();
    }
  }, [selectedCompany]);

  const fetchClientData = () => {
    if (selectedCompany) {
      const limit = rowsPerPage;
      const offset = page * rowsPerPage; // Correct offset calculation
      setLoading(true);
      myAxiosCommonHost
        .get(
          `gst/client/getClientList?selectCompany=${selectedCompany}&limit=${limit}&offset=${offset}`
        )
        .then((response) => {
          setClientData(response.data);
          fetchClientCount();
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching Employee data:", error);
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    fetchClientCount();
    fetchClientData();
  }, [rowsPerPage, page]);

  // const handleCompanySelect = (company) => {
  //   setSelectedCompany(company);
  //   console.log("Selected Company:", company);
  // };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleUpdateClient = (updatedClient) => {
    setIsUpdating(true); // Show update overlay
    myAxiosCommonHost
      .put("gst/client/updateClient", updatedClient)
      .then((response) => {
        console.log("Client data updated successfully.");
        fetchClientData();
        setIsUpdating(false); // Hide update overlay after update
      })
      .catch((error) => {
        console.error("Error updating Client data: ", error);
        setIsUpdating(false); // Hide update overlay on error
      });
  };
  const handleDeleteClient = (clientCode) => {
    if (window.confirm("Are you sure you want to delete this Client?")) {
      setIsDeleting(true);
      const encodedclientCode = encodeURIComponent(clientCode);
      myAxiosCommonHost
        .delete(
          `gst/client/deleteClient/clientCode?clientCode=${encodedclientCode}`
        )
        .then((response) => {
          console.log("Client deleted successfully.");
          fetchClientData();
          setIsDeleting(false); // Hide deletion overlay after deletion
        })
        .catch((error) => {
          console.error("Error deleting client: ", error);
          setIsDeleting(false); // Hide deletion overlay on error
        });
    }
  };
  const handleSearch = async () => {
    try {
      setLoading(true);
      const limit = rowsPerPage;
      const offset = page * rowsPerPage;
      const encodedclientCode = encodeURIComponent(searchClientsCode);
      const response = await myAxiosCommonHost.get(
        `gst/client/searchClients?selectCompany=${selectedCompany}&clientName=${searchClientName}&clientCode=${encodedclientCode}&limit=${limit}&offset=${offset}`
      );
      setClientData(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error searching employeePo:", error);
      setError(error);
      setLoading(false);
    }
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const downloadExcelFile = async () => {
    try {
      let apiUrl = `/gst/client/getClientListForExcel`;
      if (selectedOption === "1") {
        apiUrl += `?selectCompany=${selectedCompany}`;
      }

      const response = await myAxiosCommonHost.get(apiUrl);

      const data = response.data;

      if (!data) {
        console.error("Data is not available or empty.");
        return;
      }

      const workbook = XLSX.utils.book_new();

      const clientDetailsExcelList = data.clientDetailsExcelList || [];
      const contactPersonExcelList = data.contactPersonExcelList || [];

      if (clientDetailsExcelList.length > 0) {
        const worksheet1 = XLSX.utils.json_to_sheet(clientDetailsExcelList);
        XLSX.utils.book_append_sheet(workbook, worksheet1, "Client Details");
      }

      if (contactPersonExcelList.length > 0) {
        const worksheet2 = XLSX.utils.json_to_sheet(contactPersonExcelList);
        XLSX.utils.book_append_sheet(
          workbook,
          worksheet2,
          "Contact Person Details"
        );
      }

      const excelBuffer = XLSX.write(workbook, {
        type: "array",
        bookType: "xlsx",
      });

      const blob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const url = URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.target = "_blank";
      anchor.download = `${selectedCompany}_Client_Details.xlsx`; // Updated file name
      anchor.click();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <div>
      {isDeleting && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 9999,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ textAlign: "center", color: "white" }}>
            <CircularProgress style={{ marginBottom: "10px" }} />
            <Typography variant="h6">Client is Deleting...</Typography>
          </div>
        </div>
      )}
      {isUpdating && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 9999,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ textAlign: "center", color: "white" }}>
            <CircularProgress style={{ marginBottom: "10px" }} />
            <Typography variant="h6">Client is Updating...</Typography>
          </div>
        </div>
      )}
      <Paper
        elevation={3}
        style={{ padding: 20, marginBottom: 20, marginTop: 20 }}
      >
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <Link to="/registerClient">
              <Button
                variant="contained"
                color="primary"
                style={{ margin: "10px" }}
              >
                Register Client
              </Button>
            </Link>
            <Button
              variant="contained"
              color="secondary"
              style={{ margin: "10px" }}
            >
              Client Count {totalCount}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                label="Client Name"
                value={searchClientName}
                onChange={(e) => setSearchClientName(e.target.value)}
                variant="standard"
                style={{ marginRight: "10px" }}
              />
              <TextField
                label="Client Code"
                value={searchClientsCode}
                onChange={(e) => setSearchClientsCode(e.target.value)}
                variant="standard"
                style={{ marginRight: "10px" }}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleSearch}
              >
                Search
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Paper>
      <Paper
        elevation={3}
        style={{ padding: 20, marginBottom: 20, marginTop: 20 }}
      >
        <Grid container spacing={2}>
          <Grid item xs={2} md={4}>
            {/* <Autocomplete
              id="company"
              sx={{ width: 200, marginLeft: "10px" }}
              options={company}
              loading={loading}
              value={selectedCompany}
              onChange={(event, newValue) => handleCompanySelect(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Company"
                  variant="standard"
                  style={{ backgroundColor: "white", marginTop: "20px" }}
                />
              )}
            /> */}
            <TextField
              label="Select Company"
              value={selectedCompany}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              disabled
              variant="standard"
              style={{ backgroundColor: "white", marginTop: "20px" }}
            />
          </Grid>
          <Grid item xs={2} md={4}>
            <TextField
              select
              label="Download Options"
              value={selectedOption}
              onChange={handleOptionChange}
              variant="standard"
              style={{ minWidth: 200, marginRight: 16, marginTop: 20 }}
            >
              <MenuItem value="1">Download All Client CompanyWise</MenuItem>
            </TextField>

            <Button
              variant="contained"
              style={{
                backgroundColor: "green",
                color: "white",
                marginTop: 25,
              }}
              onClick={downloadExcelFile}
            >
              DOWNLOAD EXCEL
            </Button>
          </Grid>
        </Grid>
      </Paper>
      <Paper
        elevation={3}
        style={{
          padding: 20,
          marginBottom: 20,
          marginTop: 20,
          backgroundColor: "#2196f3",
          color: "white",
          padding: "10px",
          textAlign: "center",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography
            variant="h5"
            gutterBottom
            style={{ marginBottom: "15px", color: "darkblue" }}
          >
            Client List
          </Typography>
        </div>
      </Paper>
      {error ? (
        <p>Error fetching data. Please try again.</p>
      ) : (
        <TableContainer component={Paper} style={{ height: "20%" }}>
          {loading ? (
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={loading}
            >
              <CircularProgress color="inherit" />
              <Typography
                variant="h6"
                color="inherit"
                style={{ marginLeft: "10px" }}
              >
                Getting Data...
              </Typography>
            </Backdrop>
          ) : (
            <>
              <Table>
                <TableHead>
                  <TableRow
                    style={{
                      backgroundColor: "darkcyan",
                      fontWeight: "bold",
                      color: "darkblue",
                    }}
                  >
                    <TableCell />
                    <TableCell>Client Code</TableCell>
                    <TableCell>Client Name</TableCell>
                    <TableCell>Client Type</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {clientData.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={5} align="center">
                        No clients found
                      </TableCell>
                    </TableRow>
                  ) : (
                    clientData.map((client) => (
                      <Row
                        key={client.clientCode}
                        row={client}
                        onDelete={handleDeleteClient}
                        onUpdate={handleUpdateClient}
                      />
                    ))
                  )}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[10, 15, 20]}
                component="div"
                count={totalCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage} // Make sure this line is correct
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </>
          )}
        </TableContainer>
      )}
    </div>
  );
};

export default UpdateClient;
