import React, { useState } from 'react';
import axios from 'axios';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { myAxiosCommonHost } from '../Service/CorsHelperBaseUrl';

const DownloadQuotationPDF = () => {
  const [loading, setLoading] = useState(false);

  const downloadPDF = async () => {
    setLoading(true);
    try {
      const response = await myAxiosCommonHost.get('gst/quotation/getQuotationList');
      const data = response.data;

      data.forEach(item => {
        const { quotationRefNo, selectCompany, quotationNo, quotationDate, clientNameAndCode, kindAttention, quotationSubject, quotationContent, totalAmount, totalInWord, financialYear, financialMonth, accomodationUIList } = item;

        // Create a new PDF document
        const doc = new jsPDF();

        // Add Quotation Details table
        doc.setFontSize(18);
        doc.text('Quotation Details', 20, 20);

        doc.autoTable({
          startY: 30,
          head: [['Field', 'Value']],
          body: [
            ['Select Company', selectCompany],
            ['Quotation Ref No', quotationRefNo],
            ['Quotation No', quotationNo],
            ['Quotation Date', quotationDate],
            ['Client Name And Code', clientNameAndCode],
            ['Kind Attention', kindAttention],
            ['Quotation Subject', quotationSubject],
            ['Quotation Content', quotationContent],
            ['Total Amount', totalAmount],
            ['Total In Word', totalInWord],
            ['Financial Year', financialYear],
            ['Financial Month', financialMonth],
          ],
        });

        // Add Accommodation Details table
        doc.text('Accommodation Details', 20, doc.autoTable.previous.finalY + 10);

        doc.autoTable({
          startY: doc.autoTable.previous.finalY + 20,
          head: [['Description', 'Cost Per Resource', 'No Of Resources', 'No Of Months', 'Total Cost']],
          body: accomodationUIList.map(acc => [acc.description, acc.costPerResources, acc.noOfResources, acc.noOfMonth, acc.totalCost]),
        });

        // Save the PDF with a unique filename
        doc.save(`quotation_list(${quotationRefNo}).pdf`);
      });

    } catch (error) {
      console.error("Error downloading the PDF file:", error);
      alert('Error downloading the PDF file. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {loading ? (
        <div className="loading-animation">
          {/* Replace with your actual loading animation component */}
          Loading...
        </div>
      ) : (
        <button onClick={downloadPDF} disabled={loading}>
          {loading ? 'Downloading...' : 'Download PDF'}
        </button>
      )}
    </div>
  );
};

export default DownloadQuotationPDF;

// import React, { useState } from 'react';
// import axios from 'axios';
// import jsPDF from 'jspdf';
// import 'jspdf-autotable';
// import { myAxiosCommonHost } from '../Service/CorsHelperBaseUrl';

// const DownloadQuotationPDF = () => {
//   const [loading, setLoading] = useState(false);

//   const downloadPDF = async () => {
//     setLoading(true);
//     try {
//       const response = await myAxiosCommonHost.get('gst/quotation/getQuotationList');
//       const data = response.data;

//       data.forEach(item => {
//         const { quotationRefNo, selectCompany, quotationNo, quotationDate, clientNameAndCode, kindAttention, quotationSubject, quotationContent, totalAmount, totalInWord, financialYear, financialMonth, accomodationUIList } = item;

//         // Create a new PDF document
//         const doc = new jsPDF();

//         // Add title and basic information
//         doc.setFontSize(18);
//         doc.text('Quotation Details', 20, 20);

//         doc.setFontSize(12);
//         doc.text(`Select Company: ${selectCompany}`, 20, 30);
//         doc.text(`Quotation Ref No: ${quotationRefNo}`, 20, 40);
//         doc.text(`Quotation No: ${quotationNo}`, 20, 50);
//         doc.text(`Quotation Date: ${quotationDate}`, 20, 60);
//         doc.text(`Client Name And Code: ${clientNameAndCode}`, 20, 70);
//         doc.text(`Kind Attention: ${kindAttention}`, 20, 80);
//         doc.text(`Quotation Subject: ${quotationSubject}`, 20, 90);
//         doc.text(`Quotation Content: ${quotationContent}`, 20, 100);
//         doc.text(`Total Amount: ${totalAmount}`, 20, 110);
//         doc.text(`Total In Word: ${totalInWord}`, 20, 120);
//         doc.text(`Financial Year: ${financialYear}`, 20, 130);
//         doc.text(`Financial Month: ${financialMonth}`, 20, 140);

//         // Add accommodation details table
//         doc.autoTable({
//           startY: 150,
//           head: [['Description', 'Cost Per Resource', 'No Of Resources', 'No Of Months', 'Total Cost']],
//           body: accomodationUIList.map(acc => [acc.description, acc.costPerResources, acc.noOfResources, acc.noOfMonth, acc.totalCost]),
//         });

//         // Save the PDF with a unique filename
//         doc.save(`quotation_list(${quotationRefNo}).pdf`);
//       });

//     } catch (error) {
//       console.error("Error downloading the PDF file:", error);
//       alert('Error downloading the PDF file. Please try again.');
//     } finally {
//       setLoading(false);
//     }
//   };

//   return (
//     <div>
//       {loading ? (
//         <div className="loading-animation">
//           {/* Replace with your actual loading animation component */}
//           Loading...
//         </div>
//       ) : (
//         <button onClick={downloadPDF} disabled={loading}>
//           {loading ? 'Downloading...' : 'Download PDF'}
//         </button>
//       )}
//     </div>
//   );
// };

// export default DownloadQuotationPDF;
