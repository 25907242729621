import React, { useState } from "react";
import {
  TextField,
  Button,
  Container,
  Grid,
  Paper,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { myAxiosCommonHost } from "../../Service/CorsHelperBaseUrl";

const RegisterCompanyProfile = () => {
  const navigate = useNavigate();
  const [profileDetails, setProfileDetails] = useState({
    companyName: "",
    companyCode: "",
    companyAddress: "",
    website: "",
    eMail: "",
    companyWork: "",
    contactNo: "",
    gstin: "",
    pan: "",
    cin: "",
    iec: "",
  });
  const [loadingOverlay, setLoadingOverlay] = useState(false);
  const handleProfileDetailsChange = (e) => {
    const { name, value } = e.target;
    setProfileDetails((prevProfileDetails) => ({
      ...prevProfileDetails,
      [name]: value,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoadingOverlay(true);
    try {
      const postData = {
        ...profileDetails,
      };

      await myAxiosCommonHost.post(
        // "gst/bank/createBankDetails",
        "gst/profile/createCompanyProfile",
        postData
      );

      // Show alert on successful post
      alert("Company Profile registered successfully!");
      setProfileDetails({}); // Reset bank details
      navigate("/updateCompanyProfile"); // Navigate to bank list page after successful registration
    } catch (error) {
      console.error("Error posting data:", error);
      alert("Error registering company profile.");
    } finally {
      setLoadingOverlay(false);
    }
  };

  const handleCancel = () => {
    const userConfirmed = window.confirm(
      "Are you sure you want to cancel? Any unsaved changes will be lost."
    );
    if (userConfirmed) {
      navigate("/updateCompanyProfile");
    }
  };
  return (
    <div>
      <Container maxWidth="lg">
        <form onSubmit={handleSubmit}>
          <Paper
            elevation={3}
            style={{ marginBottom: "20px", marginTop: "20px" }}
          >
            <Paper
              elevation={3}
              style={{ padding: "20px", marginBottom: "20px" }}
            >
              <Typography
                variant="h5"
                gutterBottom
                style={{
                  marginBottom: "15px",
                  color: "darkblue",
                  textAlign: "center",
                }}
              >
                Profile Details
              </Typography>
              {loadingOverlay && (
                <div className="loading-overlay">
                  <CircularProgress size={60} />
                  <div className="loading-message">
                    Submting Company Profile....
                  </div>
                </div>
              )}
              <Grid container spacing={2}>
                <Grid item xs={6} md={4}>
                  <TextField
                    label="Company Name"
                    name="companyName"
                    value={profileDetails.companyName}
                    onChange={handleProfileDetailsChange}
                    fullWidth
                    margin="dense"
                    variant="standard"
                    required
                  />
                </Grid>
                <Grid item xs={6} md={4}>
                  <TextField
                    label="Company Code"
                    name="companyCode"
                    value={profileDetails.companyCode}
                    onChange={handleProfileDetailsChange}
                    fullWidth
                    margin="dense"
                    variant="standard"
                    required
                  />
                </Grid>
                <Grid item xs={6} md={4}>
                  <TextField
                    label="Company Address"
                    name="companyAddress"
                    value={profileDetails.companyAddress}
                    onChange={handleProfileDetailsChange}
                    fullWidth
                    margin="dense"
                    variant="standard"
                    required
                  />
                </Grid>
                <Grid item xs={6} md={4}>
                  <TextField
                    label="Website"
                    name="website"
                    value={profileDetails.website}
                    onChange={handleProfileDetailsChange}
                    fullWidth
                    margin="dense"
                    variant="standard"
                    required
                  />
                </Grid>
                <Grid item xs={6} md={4}>
                  <TextField
                    label="Email"
                    name="eMail"
                    value={profileDetails.eMail}
                    onChange={handleProfileDetailsChange}
                    fullWidth
                    margin="dense"
                    variant="standard"
                    required
                  />
                </Grid>
                <Grid item xs={6} md={4}>
                  <TextField
                    label="Company Work"
                    name="companyWork"
                    value={profileDetails.companyWork}
                    onChange={handleProfileDetailsChange}
                    fullWidth
                    margin="dense"
                    variant="standard"
                    required
                  />
                </Grid>
                <Grid item xs={6} md={4}>
                  <TextField
                    label="Contact Number"
                    name="contactNo"
                    value={profileDetails.contactNo}
                    onChange={handleProfileDetailsChange}
                    fullWidth
                    margin="dense"
                    variant="standard"
                    required
                    type="number"
                  />
                </Grid>
                <Grid item xs={6} md={4}>
                  <TextField
                    label="GSTIN"
                    name="gstin"
                    value={profileDetails.gstin}
                    onChange={handleProfileDetailsChange}
                    fullWidth
                    margin="dense"
                    variant="standard"
                    required
                  />
                </Grid>
                <Grid item xs={6} md={4}>
                  <TextField
                    label="PAN"
                    name="pan"
                    value={profileDetails.pan}
                    onChange={handleProfileDetailsChange}
                    fullWidth
                    margin="dense"
                    variant="standard"
                    required
                  />
                </Grid>
                <Grid item xs={6} md={4}>
                  <TextField
                    label="CIN"
                    name="cin"
                    value={profileDetails.cin}
                    onChange={handleProfileDetailsChange}
                    fullWidth
                    margin="dense"
                    variant="standard"
                    required
                  />
                </Grid>
                <Grid item xs={6} md={4}>
                  <TextField
                    label="IEC"
                    name="iec"
                    value={profileDetails.iec}
                    onChange={handleProfileDetailsChange}
                    fullWidth
                    margin="dense"
                    variant="standard"
                    required
                  />
                </Grid>
              </Grid>
            </Paper>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                padding: "10px",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                type="submit"
                style={{ marginBottom: "20px", marginRight: "10px" }}
              >
                Submit
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleCancel}
                style={{ marginBottom: "20px" }}
              >
                Cancel
              </Button>
            </div>
          </Paper>
        </form>
      </Container>
    </div>
  );
};
export default RegisterCompanyProfile;
