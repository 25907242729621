import Headers from "../../../Pages/Header";
import RegisterCompanyProfile from "../RegisterCompanyProfile";

const RegisterCompanyProfilePage = () => {
  return (
    <Headers>
      <RegisterCompanyProfile />
    </Headers>
  );
};
export default RegisterCompanyProfilePage;
