import React, { useEffect, useState } from "react";
import axios from "axios";
import { Document, Page } from "react-pdf";
import { myAxiosCommonHost } from "../Service/CorsHelperBaseUrl";

const FileViewer = ({ fileId }) => {
  const [fileData, setFileData] = useState(null);
  const [numPages, setNumPages] = useState(null);

  useEffect(() => {
    const fetchFile = async () => {
      try {
        const response = await myAxiosCommonHost.get(
          `gst/clientFile/${fileId}`,
          {
            responseType: "arraybuffer",
          }
        );

        const file = new Blob([response.data], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        setFileData(fileURL);
      } catch (error) {
        console.error("Error fetching the file:", error);
      }
    };

    fetchFile();
  }, [fileId]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <div>
      {fileData ? (
        <Document file={fileData} onLoadSuccess={onDocumentLoadSuccess}>
          {Array.from(new Array(numPages), (el, index) => (
            <Page key={`page_${index + 1}`} pageNumber={index + 1} />
          ))}
        </Document>
      ) : (
        <p>Loading file...</p>
      )}
    </div>
  );
};

export default FileViewer;
