import Headers from "../../../Pages/Header";
import ProjectInvoiceList from "../ProjectInvoiceList";

const ProjectInvoiceListPage = () => {
  return (
    <Headers>
      <ProjectInvoiceList />
    </Headers>
  );
};
export default ProjectInvoiceListPage;