import React, { useState, useEffect } from "react";
import numberToWords from "number-to-words";
import axios from "axios";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Grid,
  Button,
  Card,
  CardHeader,
  Container,
  Typography,
  CircularProgress,
  FormControl,
  Radio,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Checkbox,
} from "@material-ui/core";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import GsquareLOGO from "../../Component/Invoice/GsquareLOGO.jpg";
import Bgd_LOGO from "../../Component/Invoice/Bgd_LOGO.jpeg";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { myAxiosCommonHost } from "../../Service/CorsHelperBaseUrl";
import { Autocomplete } from "@mui/material";
import "./Invoice.css";

const GenerateEmployeeInvoice = () => {
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };
  const getCurrentMonth = () => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() - 1); // Subtract 1 to get the previous month
    const previousMonthIndex = currentDate.getMonth();
    return months[previousMonthIndex];
  };
  const getCurrentFinancialYear = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const fiscalStartYear = currentYear - (currentDate.getMonth() >= 3 ? 0 : 1);
    const fiscalEndYear = fiscalStartYear + 1;
    return `${(fiscalStartYear % 100).toString().padStart(2, "0")}-${(
      fiscalEndYear % 100
    )
      .toString()
      .padStart(2, "0")}`;
  };
  const navigate = useNavigate();
  const [invoiceHeaderDetails, setInvoiceHeaderDetails] = useState({
    selectCompany: "",
    financialYear: getCurrentFinancialYear(),
    invoiceNo: "",
    invoiceRefNo: "",
    invoiceDate: format(new Date(), "yyyy-MM") + "-01", // Default to the first day of the current month
    issueDate: format(new Date(), "yyyy-MM-dd"), // Default to today's date
    invoiceType: "",
    clientType: "AutoMobile",
    paymentYear: getCurrentYear(),
    paymentMonth: getCurrentMonth(),
    clientNameAndCode: "",
    sacHsn: "",
    uniqueNoPoNo: "",
    buyersPoNumber: "",
    poNumber: "",
    workingDays: "",
    invoiceTitle: "",
    invoiceRemarks: "",
    empRateUnit: "",
    department: "",
    subDepartment: "",
  });

  const [invoiceCalculation, setInvoiceCalculation] = useState({
    totalPresentyAmount: 0,
    cgst: 0,
    igst: 0,
    sgst: 0,
    grandTotalAmount: 0,
    totalTaxAmt: 0,
    deductionAmount: 0,
    deductionParticular: "",
    totalInWord: "",
    igstAmount: "",
    cgstAmount: "",
    sgstAmount: "",
  });

  const [paymentMode, setPaymentMode] = useState({
    ModeTermsOfPayment: "15 Days Bank Transfer",
    KindAttention: "",
    SelectBankAccNo: "",
  });
  const [isCalculateClicked, setIsCalculateClicked] = useState(false);
  const [financialYears, setFinancialYears] = useState([]);
  const [selectCompanys, setSelectCompanys] = useState([]);
  const [paymentYears, setPaymentYears] = useState([]);
  const [paymentMonths, setPaymentMonths] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [poNumbers, setPoNumbers] = useState([]);
  const [projectDetails, setProjectDetails] = useState(null);
  const [bankAccNoAndIfscCodes, setBankAccNoAndIfscCodes] = useState([]);
  const [bankUIData, setBankUIData] = useState(null);
  const [loadingOverlay, setLoadingOverlay] = useState(false);
  const [workingDays, setWorkingDays] = useState(0);
  const [isPdfButtonDisabled, setIsPdfButtonDisabled] = useState(true);
  const [invoiceRemarksOptions, setInvoiceRemarksOptions] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [company, setCompany] = useState([]);
  const [isSubmitButtonDisabledForPdf, setIsSubmitButtonDisabledForPdf] =
    useState(false);
  const [invoiceEmployeePoDetailsUIList, setInvoiceEmployeePoDetailsUIList] =
    useState({
      invoiceEmployeePoDetailsUIList: [
        {
          empRateUnit: "",
          presenty: "",
          presentyAmt: "",
          canteenAmt: "",
          transportationAmt: "",
          fromDate: "",
          toDate: "",
          employeeCode: "",
          emplyoyeeName: "",
          currentPoRate: "",
          fixmonthlyCanteen: "",
          isCanteenAssign: "",
          isTransportationAssign: "",
          activeStatus: "",
          releaseDate: "",
          currentEmplyoyee: "",
          currentPoNo: "",
          currentPoDate: "",
          annenuxFlag: false,
          uniqueNoPoNo: "",
        },
      ],
    });
  const [isFetchingData, setIsFetchingData] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true); // State to track submit button disable/enable status
  const [isEmployeePOGet, setIsEmployeePOGet] = useState(false); // State to control update overlay
  const [companyProfile, setCompanyProfile] = useState({});
  const [clientDetails, setClientDetails] = useState({});
  const [gstDetails, setGstDetails] = useState({});
  useEffect(() => {
    async function fetchCompany() {
      try {
        const response = await myAxiosCommonHost.get(
          "gst/profile/getCompanyCodeList"
        );
        const companies = response.data;
        setCompany(companies);
        if (companies.length > 0) {
          setSelectedCompany(companies[0]); // Set the default company to the first one
        }
      } catch (error) {
        console.error("Error fetching company data:", error);
      }
    }
    fetchCompany();
  }, []);
  useEffect(() => {
    const fetchClientList = async () => {
      try {
        const response = await myAxiosCommonHost.get(
          `gst/employeePo/getClientListEmpPurchaseOrder?selectCompany=${invoiceHeaderDetails.selectCompany}`
        );
        setClientList(response.data);
      } catch (error) {
        console.error("Error fetching client list:", error);
      }
    };

    fetchClientList();
  }, [invoiceHeaderDetails.selectCompany]);
  useEffect(() => {
    const fetchFinancialYears = async () => {
      try {
        const response = await myAxiosCommonHost.get(
          "gst/invoice/getFiscalYear"
        );
        setFinancialYears(response.data);
      } catch (error) {
        console.error("Error fetching financialYears:", error);
      }
    };

    fetchFinancialYears();
  }, []);
  useEffect(() => {
    const fetchSelectCompanys = async () => {
      try {
        const response = await myAxiosCommonHost.get(
          "gst/profile/getCompanyProfileName"
        );
        setSelectCompanys(response.data);
      } catch (error) {
        console.error("Error fetching Select Company:", error);
      }
    };

    fetchSelectCompanys();
  }, []);

  useEffect(() => {
    const fetchPaymentYears = async () => {
      try {
        const response = await myAxiosCommonHost.get(
          "gst/invoice/getPaymentYear"
        );
        setPaymentYears(response.data);
      } catch (error) {
        console.error("Error fetching paymentYears:", error);
      }
    };

    fetchPaymentYears();
  }, []);

  useEffect(() => {
    const fetchPaymentMonths = async () => {
      try {
        const response = await myAxiosCommonHost.get(
          "gst/invoice/getShortMonth"
        );
        setPaymentMonths(response.data);
      } catch (error) {
        console.error("Error fetching paymentMonths:", error);
      }
    };

    fetchPaymentMonths();
  }, []);

  const fetchPoNumbers = async (clientNameAndCode) => {
    const encodedclientNameAndCode = encodeURIComponent(clientNameAndCode);
    try {
      const response = await myAxiosCommonHost.get(
        `gst/employeePo/getPoNumberAndUniqueNumberEmpPurchaseOrderList?clientNameAndCode=${encodedclientNameAndCode}&selectCompany=${invoiceHeaderDetails.selectCompany}`
      );
      setPoNumbers(response.data);
    } catch (error) {
      console.error("Error fetching poNumbers:", error);
    }
  };

  const fetchBankAccNoAndIfscCodes = async (
    uniqueNoPoNo,
    paymentYear,
    paymentMonth
  ) => {
    try {
      const response = await myAxiosCommonHost.get(
        `gst/employeePo/getEmployeePoInvoiceUIList?empPoNoAndUniqueCode=${uniqueNoPoNo}&selectCompany=${invoiceHeaderDetails.selectCompany}&paymentYear=${paymentYear}&paymentMonth=${paymentMonth}`
      );
      const data = response.data.bankAccNoAndIfscCode || [];
      setBankAccNoAndIfscCodes(data);
      return data;
    } catch (error) {
      console.error("Error fetching bankAccNoAndIfscCodes:", error);
      return [];
    }
  };
  const handleCompanySelect = (company) => {
    setSelectedCompany(company);
    console.log("Selected Company Year:", company);
  };
  const generateInvoiceRefNo = () => {
    const { selectCompany, financialYear, invoiceNo } = invoiceHeaderDetails;
    return `${selectCompany}${financialYear}${invoiceNo}`;
  };
  const invoiceRefNo = generateInvoiceRefNo();
  useEffect(() => {
    if (
      invoiceHeaderDetails.selectCompany &&
      invoiceHeaderDetails.financialYear &&
      invoiceHeaderDetails.invoiceNo
    ) {
      const newInvoiceRefNo = generateInvoiceRefNo();
      setInvoiceHeaderDetails((prevDetails) => ({
        ...prevDetails,
        invoiceRefNo: newInvoiceRefNo,
      }));
    }
  }, [
    invoiceHeaderDetails.selectCompany,
    invoiceHeaderDetails.financialYear,
    invoiceHeaderDetails.invoiceNo,
  ]);

  const handleWorkingDaysChange = (event) => {
    const value = event.target.value;
    const updatedWorkingDays = parseFloat(value);
    setWorkingDays(updatedWorkingDays);
  };

  const handleInvoiceEmployeeDetailsChange = (index, field) => (event) => {
    const value = event.target.value;
    setInvoiceEmployeePoDetailsUIList((prevDetails) => {
      const updatedList = [...prevDetails.invoiceEmployeePoDetailsUIList];
      const currentItem = { ...updatedList[index], [field]: value };
      // Check if both presenty and currentPoRate are valid numbers before calculating presentyAmt
      if (!isNaN(currentItem.presenty) && !isNaN(currentItem.currentPoRate)) {
        if (
          currentItem.empRateUnit === "Per Day" ||
          currentItem.empRateUnit === "Per Hour"
        ) {
          currentItem.presentyAmt = (
            parseFloat(currentItem.presenty) *
            parseFloat(currentItem.currentPoRate)
          ).toFixed(2);
        } else if (currentItem.empRateUnit === "Per Month") {
          currentItem.presentyAmt = (
            (parseFloat(currentItem.currentPoRate) / parseFloat(workingDays)) *
            parseFloat(currentItem.presenty)
          ).toFixed(2);
        } else {
          currentItem.presentyAmt = "";
        }
      } else {
        currentItem.presentyAmt = ""; // Handle the case where either presenty or currentPoRate is not a valid number
      }
      console.log("currentItem:", currentItem);
      updatedList[index] = currentItem;

      return {
        ...prevDetails,
        invoiceEmployeePoDetailsUIList: updatedList,
      };
    });
  };

  const fetchProjectPoData = async (
    uniqueNoPoNo,
    paymentYear,
    paymentMonth
  ) => {
    setIsEmployeePOGet(true);
    try {
      const response = await myAxiosCommonHost.get(
        `gst/employeePo/getEmployeePoInvoiceUIList?empPoNoAndUniqueCode=${uniqueNoPoNo}&selectCompany=${invoiceHeaderDetails.selectCompany}&paymentYear=${paymentYear}&paymentMonth=${paymentMonth}`
      );
      if (Array.isArray(response)) {
        setPaymentMode((prevPaymentMode) => ({
          ...prevPaymentMode,
          SelectBankAccNo: null, // Set to null initially
        }));
        setBankAccNoAndIfscCodes(response);
      } else {
        console.error("Invalid response from server:", response);
      }
      const kindAttention = response.data.kindAttention || "";
      setPaymentMode((prevPaymentMode) => ({
        ...prevPaymentMode,
        KindAttention: kindAttention,
      }));
      const Department = response.data.department || "";
      const SubDepartment = response.data.subDepartment || "";
      setInvoiceHeaderDetails((prevInvoiceHeaderDetails) => ({
        ...prevInvoiceHeaderDetails,
        department: Department,
        subDepartment: SubDepartment,
      }));
      const empRateUnit = response.data.empRateUnit || "";
      setInvoiceHeaderDetails((prevInvoiceHeaderDetails) => ({
        ...prevInvoiceHeaderDetails,
        empRateUnit: empRateUnit,
        poNumber: response.data.empPoRateInvoiceUIList[0]?.currentPoNo || "",
      }));
      const data = response.data;
      const { companyProfileUI, clientUI, gstdetailsUI } = data;
      setCompanyProfile(companyProfileUI);
      setClientDetails(clientUI);
      setGstDetails(gstdetailsUI);
      console.log("Company Profile:", companyProfileUI);
      console.log("Client Details:", clientUI);
      console.log("GST Details:", gstdetailsUI);
      setIsEmployeePOGet(false);
      return response.data;
    } catch (error) {
      console.error("Error fetching project data:", error);
      setIsEmployeePOGet(false);
      return null;
    }
  };
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const handleInvoiceHeaderDetailsChange = (field) => async (event) => {
    const value = event.target.value;
    let projectData;
    let paymentYear;
    let paymentMonth;

    const updateInvoiceHeaderDetails = (newDetails) => {
      setInvoiceHeaderDetails((prevDetails) => ({
        ...prevDetails,
        ...newDetails,
      }));
    };
    if (field === "invoiceTitle") {
      setInvoiceHeaderDetails((prevDetails) => ({
        ...prevDetails,
        invoiceRemarks: "", // Reset invoiceRemarks
      }));
      // Set options for invoiceRemarks based on invoiceTitle value
      if (value === "PROFORMA INVOICE") {
        setInvoiceRemarksOptions([
          "QUOTATION UNDER PROCESS",
          "PR UNDER PROCESS",
          "PO UNDER PROCESS",
        ]);
      } else if (value === "TAX INVOICE") {
        setInvoiceRemarksOptions([
          "Invoice in submission process",
          "Invoice with GSQUARE Representative",
          "Invoice with compliance",
          "Invoice with user",
          "Invoice with account/GRN",
        ]);
      } else {
        // Clear invoiceRemarksOptions if invoiceTitle is neither "PROFORMA INVOICE" nor "TAX INVOICE"
        setInvoiceRemarksOptions([]);
      }
    }
    // Update invoiceHeaderDetails with the selected value
    setInvoiceHeaderDetails((prevDetails) => ({
      ...prevDetails,
      [field]: value,
    }));
    if (field === "clientNameAndCode") {
      updateInvoiceHeaderDetails({
        clientNameAndCode: value,
        uniqueNoPoNo: "",
      });
      fetchPoNumbers(value);
    } else {
      updateInvoiceHeaderDetails({
        [field]: value,
      });
    }

    if (field === "uniqueNoPoNo") {
      setIsFetchingData(true);
      try {
        paymentYear = invoiceHeaderDetails.paymentYear;
        paymentMonth = invoiceHeaderDetails.paymentMonth;

        projectData = await fetchProjectPoData(
          value,
          paymentYear,
          paymentMonth
        );

        if (projectData && Array.isArray(projectData.empPoRateInvoiceUIList)) {
          const fromDate = new Date(
            paymentYear,
            months.indexOf(paymentMonth),
            1
          );
          const lastDayOfMonth = new Date(
            paymentYear,
            months.indexOf(paymentMonth) + 1,
            0
          );
          const toDate = format(lastDayOfMonth, "yyyy-MM-dd");

          console.log("Selected paymentYear:", paymentYear);
          console.log("Selected paymentMonth:", paymentMonth);
          console.log("Calculated fromDate:", format(fromDate, "yyyy-MM-dd"));
          console.log("Calculated toDate:", toDate);

          setInvoiceEmployeePoDetailsUIList((prevDetails) => ({
            ...prevDetails,
            invoiceEmployeePoDetailsUIList:
              projectData.empPoRateInvoiceUIList.map((project) => ({
                empRateUnit: project.empRateUnit,
                canteenAmt: project.canteenAmount,
                transportationAmt: project.transportAmount,
                fromDate: format(fromDate, "yyyy-MM-dd"), // Update fromDate with calculated value
                toDate: toDate, // Update toDate with calculated value
                employeeCode: project.employeeCode,
                emplyoyeeName: project.employeeName,
                currentPoRate: project.currentPoRate,
                fixmonthlyCanteen: project.fixmonthlyCanteen,
                isCanteenAssign: project.isCanteenAssigned,
                isTransportationAssign: project.isTransportationAssigned,
                activeStatus: project.activeStatus,
                releaseDate: project.employeeReleaseDate,
                currentEmplyoyee: project.employeeName,
                currentPoDate: project.currentPoDate,
                annenuxFlag: project.annenuxFlag,
                presenty: project.presenty,
                presentyAmt: project.presentyAmt,
                currentPoNo: project.currentPoNo,
              })),
          }));
          setProjectDetails(projectData);
          const { cgst, sgst, igst } = projectData.gstdetailsUI;
          setInvoiceCalculation((prevData) => ({
            ...prevData,
            cgst,
            sgst,
            igst,
          }));
          setIsEmployeePOGet(true);
          try {
            const gstdetailsResponse = await myAxiosCommonHost.get(
              `gst/employeePo/getEmployeePoInvoiceUIList?empPoNoAndUniqueCode=${value}&selectCompany=${invoiceHeaderDetails.selectCompany}&paymentYear=${paymentYear}&paymentMonth=${paymentMonth}`
            );
            const data = gstdetailsResponse.data.bankAccNoAndIfscCode || [];
            setBankAccNoAndIfscCodes(data);
            updateInvoiceHeaderDetails({
              sacHsn: gstdetailsResponse.data.gstdetailsUI.hsnsac || "",
            });
            setIsEmployeePOGet(false);
          } catch (error) {
            console.error("Error fetching SAC/HSN data:", error);
            setIsEmployeePOGet(false);
          }
        } else {
          console.error("Invalid response from server:", projectData);
        }
      } catch (error) {
        console.error("Error fetching project data:", error);
      }

      // try {
      //   const response = await fetchBankAccNoAndIfscCodes(
      //     value,
      //     paymentYear,
      //     paymentMonth
      //   );

      //   if (Array.isArray(response)) {
      //     setPaymentMode((prevPaymentMode) => ({
      //       ...prevPaymentMode,
      //       SelectBankAccNo: response,
      //     }));
      //   } else {
      //     console.error("Invalid response from server:", response);
      //   }
      // } catch (error) {
      //   console.error("Error fetching bankAccNoAndIfscCodes:", error);
      // }
      try {
        const response = await fetchBankAccNoAndIfscCodes(
          value,
          paymentYear,
          paymentMonth
        );

        if (Array.isArray(response)) {
          setPaymentMode((prevPaymentMode) => ({
            ...prevPaymentMode,
            SelectBankAccNo: null, // Set to null initially
          }));
          setBankAccNoAndIfscCodes(response);
        } else {
          console.error("Invalid response from server:", response);
        }
      } catch (error) {
        console.error("Error fetching bankAccNoAndIfscCodes:", error);
      } finally {
        setIsFetchingData(false); // Set flag to indicate data fetching is completed
      }

      const newInvoiceRefNo = generateInvoiceRefNo();
      updateInvoiceHeaderDetails({
        invoiceRefNo: newInvoiceRefNo,
        poNumber: projectData.empPoRateInvoiceUIList[0]?.currentPoNo || "",
      });
    }
  };

  const handleInvoiceCalculationChange = (field, value) => {
    setInvoiceCalculation((prevData) => ({
      ...prevData,
      [field]: parseFloat(value) || 0,
    }));
  };

  const handlePaymentModeChange = (field) => (event) => {
    setPaymentMode({
      ...paymentMode,
      [field]: event.target.value,
    });
  };

  useEffect(() => {
    const {
      uniqueNoPoNo,
      clientNameAndCode,
      invoiceRefNo,
      financialYear,
      // workingDays,
    } = invoiceHeaderDetails;

    const { grandTotalAmount, totalPresentyAmount, totalTaxAmt } =
      invoiceCalculation;

    const { SelectBankAccNo } = paymentMode;

    // Check if any required field is null or empty
    const isDisabled =
      !uniqueNoPoNo ||
      !clientNameAndCode ||
      // !workingDays ||
      !invoiceRefNo ||
      !financialYear ||
      !SelectBankAccNo ||
      grandTotalAmount === 0 ||
      totalPresentyAmount === 0 ;
     // totalTaxAmt === 0;

    // Update the state to enable/disable the submit button
    setIsSubmitDisabled(isDisabled);
  }, [
    invoiceHeaderDetails.uniqueNoPoNo,
    invoiceHeaderDetails.clientNameAndCode,
    // invoiceHeaderDetails.workingDays,
    invoiceHeaderDetails.invoiceRefNo,
    invoiceHeaderDetails.financialYear,
    invoiceCalculation.grandTotalAmount,
    invoiceCalculation.totalPresentyAmount,
   // invoiceCalculation.totalTaxAmt,
    paymentMode.SelectBankAccNo,
  ]);
  const isPaymentModeValid = () => {
    const { SelectBankAccNo } = paymentMode;
    console.log("Payment Mode:", JSON.stringify(paymentMode, null, 2)); // Log paymentMode in JSON format
    return SelectBankAccNo !== null;
  };

  const isSubmitButtonDisabled = () => {
    const {
      uniqueNoPoNo,
      clientNameAndCode,
      invoiceRefNo,
      financialYear, // Add invoiceRefNo to the destructuring assignment
    } = invoiceHeaderDetails;

    const { grandTotalAmount, totalPresentyAmount, totalTaxAmt } =
      invoiceCalculation;

    // Check conditions for disabling the Submit button
    if (
      !uniqueNoPoNo ||
      !clientNameAndCode ||
      !isPaymentModeValid() ||
      grandTotalAmount === undefined ||
      totalPresentyAmount === undefined ||
     // totalTaxAmt === undefined ||
      !invoiceRefNo ||
      !financialYear
    ) {
      return true; // Disable the button
    }
    return false; // Enable the button
  };

  const handleSubmit = async () => {
    setLoadingOverlay(true);
    const projectDetailsListData = {
      selectCompany: invoiceHeaderDetails.selectCompany,
      financialYear: invoiceHeaderDetails.financialYear,
      invoiceNo: invoiceHeaderDetails.invoiceNo,
      invoiceRefNo: invoiceHeaderDetails.invoiceRefNo,
      invoiceDate: invoiceHeaderDetails.invoiceDate,
      issueDate: invoiceHeaderDetails.issueDate,
      invoiceType: invoiceHeaderDetails.invoiceType,
      clientType: invoiceHeaderDetails.clientType,
      paymentYear: invoiceHeaderDetails.paymentYear,
      paymentMonth: invoiceHeaderDetails.paymentMonth,
      clientNameAndCode: invoiceHeaderDetails.clientNameAndCode,
      sacHsn: invoiceHeaderDetails.sacHsn,
      poNumber: invoiceHeaderDetails.poNumber,
      buyersPoNumber: invoiceHeaderDetails.buyersPoNumber,
      uniqueNoPoNo: invoiceHeaderDetails.uniqueNoPoNo,
      workingDays: invoiceHeaderDetails.workingDays,
      invoiceTitle: invoiceHeaderDetails.invoiceTitle,
      invoiceRemarks: invoiceHeaderDetails.invoiceRemarks,
      empRateUnit: invoiceHeaderDetails.empRateUnit,
      subDepartment: invoiceHeaderDetails.subDepartment,
      department: invoiceHeaderDetails.department,
      invoiceEmployeePoDetailsUIList:
        invoiceEmployeePoDetailsUIList.invoiceEmployeePoDetailsUIList.map(
          (item) => ({
            empRateUnit: item.empRateUnit,
            canteenAmt: item.canteenAmt,
            transportationAmt: item.transportationAmt,
            fromDate: item.fromDate,
            toDate: item.toDate,
            employeeCode: item.employeeCode,
            emplyoyeeName: item.emplyoyeeName,
            currentPoRate: item.currentPoRate,
            fixmonthlyCanteen: item.fixmonthlyCanteen,
            isCanteenAssign: item.isCanteenAssign,
            isTransportationAssign: item.isTransportationAssign,
            activeStatus: item.activeStatus,
            releaseDate: item.releaseDate,
            currentEmplyoyee: item.employeeNameAndCode,
            currentPoDate: item.currentPoDate,
            annenuxFlag: item.annenuxFlag,
            presenty: item.presenty,
            presentyAmt: item.presentyAmt,
          })
        ),
      invoiceGstDetailsUI: {
        totalPresentyAmount: invoiceCalculation.totalPresentyAmount,
        cGST: invoiceCalculation.cgst,
        sGST: invoiceCalculation.sgst,
        iGST: invoiceCalculation.igst,
        grandTotalAmount: invoiceCalculation.grandTotalAmount,
        totalTaxAmt: invoiceCalculation.totalTaxAmt,
        deductionAmount: invoiceCalculation.deductionAmount,
        deductionParticular: invoiceCalculation.deductionParticular,
        totalInWord: invoiceCalculation.totalInWord,
        igstAmount: invoiceCalculation.igstAmount,
        cgstAmount: invoiceCalculation.cgstAmount,
        sgstAmount: invoiceCalculation.sgstAmount,
      },
      invoicePaymentModeUI: {
        modeTermsOfPayment: paymentMode.ModeTermsOfPayment,
        kindAttention: paymentMode.KindAttention,
        selectBankAccNo: paymentMode.SelectBankAccNo,
      },
      bankUI: bankUIData,
    };
    console.log(JSON.stringify(projectDetailsListData, null, 2));
    try {
      const response = await myAxiosCommonHost.post(
        "gst/invoice/createInvoice?filterType=EMPLOYEEPOINVOICE",
        projectDetailsListData
      );

      console.log("Server response:", response.data);
      window.alert("Invoice Saved Successfully!");
      // Disable the submit button and enable the PDF button
      setIsSubmitButtonDisabledForPdf(true);
      // Enable the PDF button after successful data submission
      setIsPdfButtonDisabled(false);
      //navigate("/employeeInvoiceList");
    } catch (error) {
      console.error("Error submitting data:", error);
      window.alert("Error saving data on the server. Please try again.");
    } finally {
      setLoadingOverlay(false);
    }
  };
  const handleCancel = () => {
    const userConfirmed = window.confirm(
      "Are you sure you want to cancel? Any unsaved changes will be lost."
    );
    if (userConfirmed) {
      navigate("/employeeInvoiceList");
    }
  };

  useEffect(() => {
    fetchData();
  }, [invoiceHeaderDetails.financialYear, invoiceHeaderDetails.selectCompany]);

  const fetchData = async () => {
    try {
      if (
        invoiceHeaderDetails.paymentYear &&
        invoiceHeaderDetails.selectCompany
      ) {
        const response = await myAxiosCommonHost.get(
          `gst/invoice/getInvoiceUniqueNo?financialYear=${invoiceHeaderDetails.financialYear}&selectCompany=${invoiceHeaderDetails.selectCompany}`
        );

        if (response.status === 200) {
          let data = response.data;
          let invoiceResponseNo = "";
          if (typeof data === "string") {
            let msgArr = data.split(",");
            if (msgArr.length >= 2) {
              if (data.includes("Please")) {
                window.alert(data);
                invoiceResponseNo = parseInt(msgArr[1]);
              } else {
                invoiceResponseNo = parseInt(data);
              }
            } else {
              console.error("Unexpected data format for splitting:", data);
            }
          } else if (typeof data === "number") {
            invoiceResponseNo = data;
          } else {
            console.error("Unexpected data format:", data);
          }
          setInvoiceHeaderDetails((prevState) => ({
            ...prevState,
            invoiceNo: invoiceResponseNo,
            issueDate: format(new Date(), "yyyy-MM-dd"),
            invoiceDate: format(new Date(), "yyyy-MM") + "-01",
            clientType: "AutoMobile",
            sacHsn: "",
            poNumber: "",
            buyersPoNumber: "",
            workingDays: "",
            empRateUnit: "",
            uniqueNoPoNo: "",
            clientNameAndCode: "",
            department: "",
            subDepartment: "",
          }));
          setInvoiceCalculation({
            totalPresentyAmount: 0,
            cgst: 0,
            igst: 0,
            sgst: 0,
            grandTotalAmount: 0,
            totalTaxAmt: 0,
            deductionAmount: 0,
            deductionParticular: "",
            totalInWord: "",
            igstAmount: "",
            cgstAmount: "",
            sgstAmount: "",
          });
          setInvoiceEmployeePoDetailsUIList({
            invoiceEmployeePoDetailsUIList: [
              {
                empRateUnit: "",
                presenty: "",
                presentyAmt: "",
                canteenAmt: "",
                transportationAmt: "",
                fromDate: "",
                toDate: "",
                employeeCode: "",
                emplyoyeeName: "",
                currentPoRate: "",
                fixmonthlyCanteen: "",
                isCanteenAssign: "",
                isTransportationAssign: "",
                activeStatus: "",
                releaseDate: "",
                currentEmplyoyee: "",
                currentPoNo: "",
                currentPoDate: "",
                annenuxFlag: "",
                uniqueNoPoNo: "",
              },
            ],
          });
          setPaymentMode({
            ModeTermsOfPayment: "15 Days Bank Transfer",
            KindAttention: "",
            SelectBankAccNo: "",
          });
          setWorkingDays(0);
        } else {
          console.error("Error fetching data. Status:", response.status);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchInvoiceNoData = async () => {
    try {
      if (
        invoiceHeaderDetails.paymentYear &&
        invoiceHeaderDetails.selectCompany
      ) {
        const response = await myAxiosCommonHost.get(
          `gst/invoice/getInvoiceUniqueNo?financialYear=${invoiceHeaderDetails.financialYear}&selectCompany=${invoiceHeaderDetails.selectCompany}`
        );
        if (response.status === 200) {
          let data = response.data;
          let invoiceResponseNo = "";
          if (typeof data === "string") {
            let msgArr = data.split(",");
            if (msgArr.length >= 2) {
              if (data.includes("Please")) {
                console.log(data);
                invoiceResponseNo = parseInt(msgArr[1]);
              } else {
                invoiceResponseNo = parseInt(data);
              }
            } else {
              console.error("Unexpected data format for splitting:", data);
            }
          } else if (typeof data === "number") {
            invoiceResponseNo = data;
          } else {
            console.error("Unexpected data format:", data);
          }
          setInvoiceHeaderDetails((prevState) => ({
            ...prevState,
            invoiceNo: invoiceResponseNo,
            issueDate: format(new Date(), "yyyy-MM-dd"),
            invoiceDate: format(new Date(), "yyyy-MM") + "-01",
            clientType: "AutoMobile",
            sacHsn: "",
            poNumber: "",
            buyersPoNumber: "",
            workingDays: "",
            empRateUnit: "",
            department: "",
            subDepartment: "",
          }));
          setInvoiceCalculation({
            totalPresentyAmount: 0,
            cgst: 0,
            igst: 0,
            sgst: 0,
            grandTotalAmount: 0,
            totalTaxAmt: 0,
            deductionAmount: 0,
            deductionParticular: "",
            totalInWord: "",
            igstAmount: "",
            cgstAmount: "",
            sgstAmount: "",
          });
          setPaymentMode({
            ModeTermsOfPayment: "15 Days Bank Transfer",
            KindAttention: "",
            SelectBankAccNo: "",
          });
          setInvoiceEmployeePoDetailsUIList({
            invoiceEmployeePoDetailsUIList: [
              {
                empRateUnit: "",
                presenty: "",
                presentyAmt: "",
                canteenAmt: "",
                transportationAmt: "",
                fromDate: "",
                toDate: "",
                employeeCode: "",
                emplyoyeeName: "",
                currentPoRate: "",
                fixmonthlyCanteen: "",
                isCanteenAssign: "",
                isTransportationAssign: "",
                activeStatus: "",
                releaseDate: "",
                currentEmplyoyee: "",
                currentPoNo: "",
                currentPoDate: "",
                annenuxFlag: "",
                uniqueNoPoNo: "",
              },
            ],
          });
          setWorkingDays(0);
        } else {
          console.error("Error fetching data. Status:", response.status);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchInvoiceNoData();
  }, [
    invoiceHeaderDetails.paymentYear,
    invoiceHeaderDetails.paymentMonth,
    invoiceHeaderDetails.uniqueNoPoNo,
    invoiceHeaderDetails.selectCompany,
  ]);
  const handleReset = () => {
    // Reset all the form fields to their initial/default values
    setInvoiceHeaderDetails({
      selectCompany: null,
      financialYear: getCurrentFinancialYear(),
      invoiceNo: "",
      invoiceRefNo: "",
      invoiceDate: format(new Date(), "yyyy-MM") + "-01", // Default to the first day of the current month
      invoiceType: "",
      clientType: "AutoMobile",
      paymentYear: getCurrentYear(),
      paymentMonth: getCurrentMonth(),
      clientNameAndCode: "",
      sacHsn: "",
      uniqueNoPoNo: "",
      buyersPoNumber: "",
      poNumber: "",
      issueDate: format(new Date(), "yyyy-MM-dd"), // Default to today's date
      workingDays: "",
      invoiceTitle: "",
      invoiceRemarks: "",
      empRateUnit: "",
      department: "",
      subDepartment: "",
    });
    fetchInvoiceNoData();

    setInvoiceCalculation({
      totalPresentyAmount: 0,
      cgst: 0,
      igst: 0,
      sgst: 0,
      grandTotalAmount: 0,
      totalTaxAmt: 0,
      deductionAmount: 0,
      deductionParticular: "",
      totalInWord: "",
      igstAmount: "",
      cgstAmount: "",
      sgstAmount: "",
    });

    setPaymentMode({
      ModeTermsOfPayment: "15 Days Bank Transfer",
      KindAttention: "",
      SelectBankAccNo: "",
    });

    setInvoiceEmployeePoDetailsUIList({
      invoiceEmployeePoDetailsUIList: [
        {
          empRateUnit: "",
          presenty: "",
          presentyAmt: "",
          canteenAmt: "",
          transportationAmt: "",
          fromDate: "",
          toDate: "",
          employeeCode: "",
          emplyoyeeName: "",
          currentPoRate: "",
          fixmonthlyCanteen: "",
          isCanteenAssign: "",
          isTransportationAssign: "",
          activeStatus: "",
          releaseDate: "",
          currentEmplyoyee: "",
          currentPoNo: "",
          currentPoDate: "",
          annenuxFlag: "",
          uniqueNoPoNo: "",
        },
      ],
    });
    setWorkingDays(0);
    isSubmitButtonDisabled();
    setIsPdfButtonDisabled(true);
  };
  // Function to handle calculation
  const handleCalculate = async () => {
    try {
      const { cgst, igst, sgst } = invoiceCalculation;
      // Update the state with the received values
      setInvoiceCalculation((prevData) => ({
        ...prevData,
        cgst,
        sgst,
        igst,
      }));

      // Calculate totalPresentyAmount
      const updatedTotalPresentyAmount =
        invoiceEmployeePoDetailsUIList.invoiceEmployeePoDetailsUIList.reduce(
          (total, item) => total + parseFloat(item.presentyAmt) || 0,
          0
        );
      const roundedTotalPresentyAmount = parseFloat(
        updatedTotalPresentyAmount.toFixed(2)
      );
      // Calculate total tax amount
      const {
        cgst: updatedCgst,
        sgst: updatedSgst,
        igst: updatedIgst,
      } = invoiceCalculation;
      const cgstAmount = (updatedCgst / 100) * updatedTotalPresentyAmount;
      const sgstAmount = (updatedSgst / 100) * updatedTotalPresentyAmount;
      const igstAmount = (updatedIgst / 100) * updatedTotalPresentyAmount;
      const calculatedTotalTaxAmt = cgstAmount + sgstAmount + igstAmount;
      // Update the state with the recalculated totalTaxAmt
      setInvoiceCalculation((prevData) => ({
        ...prevData,
        totalTaxAmt: calculatedTotalTaxAmt,
        igstAmount: igstAmount,
        cgstAmount: cgstAmount,
        sgstAmount: sgstAmount,
      }));
      // Calculate deduction amount
      let deductionAmount = 0;
      let deductionParticular = "";
      const invoiceDetailsList =
        invoiceEmployeePoDetailsUIList.invoiceEmployeePoDetailsUIList;
      const annexureFlag = invoiceDetailsList.some(
        (employee) => employee.annenuxFlag === true
      );
      if (annexureFlag) {
        deductionAmount =
          invoiceEmployeePoDetailsUIList.invoiceEmployeePoDetailsUIList.reduce(
            (total, item) =>
              total +
              (parseFloat(item.canteenAmt) || 0) +
              (parseFloat(item.transportationAmt) || 0),
            0
          );
        deductionParticular =
          "Canteen & Transportation Ded. (ref. with Annexure attached)";
      }

      console.log("Deduction Amount:", deductionAmount);
      // Calculate grandTotal
      const grandTotalAmount = Math.ceil(
        updatedTotalPresentyAmount + calculatedTotalTaxAmt - deductionAmount
      );
      // Convert grandTotal to words
      const totalInWord = numberToWords.toWords(grandTotalAmount);
      // Update the state with the recalculated values
      setInvoiceCalculation((prevData) => ({
        ...prevData,
        totalPresentyAmount: roundedTotalPresentyAmount,
        deductionAmount,
        deductionParticular,
        grandTotalAmount,
        totalInWord,
      }));

      console.log("Grand Total Calculated:", grandTotalAmount);
      console.log("Total In Words:", totalInWord);
    } catch (error) {
      // Handle errors appropriately
      console.error("Error calculating grand total:", error.message);
    }
    setIsCalculateClicked(true);
  };

  const [bankName, setBankName] = useState("");
  const [bankAddress, setBankAddress] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [ifsc, setIfsc] = useState("");
  const [swiftCode, setSwiftCode] = useState("");
  const [isBankUIData, setIsBankUIData] = useState(false);

  useEffect(() => {
    const fetchBankUIData = async () => {
      const encodeSelectBankAccNo = encodeURIComponent(
        paymentMode.SelectBankAccNo
      );
      try {
        setIsBankUIData(true); // Start loading animation
        const response = await myAxiosCommonHost.get(
          `gst/bank/getInvoiceBankUI?bankAccNoAndIfscCode=${encodeSelectBankAccNo}`
        );
        setBankUIData(response.data);
        const { bankName, bankAddress, accountNumber, ifsc, swiftCode } =
          response.data;
        setBankName(bankName);
        setBankAddress(bankAddress);
        setAccountNumber(accountNumber);
        setIfsc(ifsc);
        setSwiftCode(swiftCode);
      } catch (error) {
        console.error("Error fetching bankUI data:", error);
      } finally {
        setIsBankUIData(false); // Stop loading animation
      }
    };

    if (paymentMode.SelectBankAccNo) {
      fetchBankUIData();
    }
  }, [paymentMode.SelectBankAccNo]);

  const handleDeleteRow = (index) => {
    // Create a copy of the array and remove the element at the specified index
    const updatedList = [
      ...invoiceEmployeePoDetailsUIList.invoiceEmployeePoDetailsUIList,
    ];
    updatedList.splice(index, 1);

    // Update the state with the new array
    setInvoiceEmployeePoDetailsUIList({
      invoiceEmployeePoDetailsUIList: updatedList,
    });
  };

  const [checkEmpRateUnit, setCheckEmpRateUnit] = useState();

  const handlegeneratePDF = () => {
    setCheckEmpRateUnit(invoiceHeaderDetails.empRateUnit);

    if (checkEmpRateUnit === "Per Day") {
      generatePDFPerDay(invoiceHeaderDetails, invoiceCalculation);
    } else if (checkEmpRateUnit === "Per Month") {
      generatePDFPerMonth(invoiceHeaderDetails, invoiceCalculation);
    } else if (checkEmpRateUnit === "Per Hour") {
      generatePDFPerHour(invoiceHeaderDetails, invoiceCalculation);
    }
  };

  const generatePDFPerMonth = async (
    invoiceHeaderDetails,
    invoiceCalculation
  ) => {
    const doc = new jsPDF();
    // Add a new rectangle to encompass all tables and containers
    const encompassingRectX = 3;
    const encompassingRectY = 4;
    const encompassingRectWidth = doc.internal.pageSize.width - 6;
    const encompassingRectHeight = doc.internal.pageSize.height - 8;

    doc.setDrawColor(0); // Set border color to black
    doc.setLineWidth(0.4); // Set border width
    doc.rect(
      encompassingRectX,
      encompassingRectY,
      encompassingRectWidth,
      encompassingRectHeight
    );
    let companyInfoText = "";

    try {
      // Information for GSQUARE TECHSYSTEMS PVT LTD
      companyInfoText =
        `${companyProfile.companyName}\n` +
        `${companyProfile.companyAddress}\n` +
        `${companyProfile.website}\n` +
        `${companyProfile.eMail}`;

      doc.setFontSize(10);
      doc.setTextColor(0, 0, 0); // Black color

      // Add the company information to the PDF
      doc.setFont("helvetica", "bold"); // Set font to bold
      doc.text(companyInfoText, 15, 30);
      doc.setFont("helvetica", "normal"); // Reset font to normal

      // Continue with the rest of your PDF generation logic...
    } catch (error) {
      console.error("Error fetching company data:", error);
    }

    const companyInfoContainerX = 14;
    const companyInfoContainerY = 10;
    const companyInfoContainerWidth =
      doc.internal.pageSize.width * 0.55 - companyInfoContainerX - 10;
    const companyInfoContainerHeight = 40;

    // Draw the container first
    doc.setDrawColor(0);
    doc.setLineWidth(0.4);
    doc.setFillColor(255, 255, 255);
    doc.rect(
      companyInfoContainerX,
      companyInfoContainerY,
      companyInfoContainerWidth,
      companyInfoContainerHeight,
      "FD"
    );
    if (invoiceHeaderDetails.selectCompany == "GST") {
      // Add the GsquareLOGO with additional margin from the left border
      doc.addImage(GsquareLOGO, "JPEG", 15, 11, 28, 28); // Change x coordinate (e.g., 25 or 30)
      doc.setFontSize(10);
      doc.setTextColor(0, 0, 0);
      doc.text(companyInfoText, 25 + 20 + 2, 18, {
        // Change margin after GsquareLOGO width (e.g., 2 or 3)
        maxWidth: doc.internal.pageSize.width * 0.55 - 25 - 20 - 15, // Calculate available width minus adjusted logo position and margins
      });
    }
    if (invoiceHeaderDetails.selectCompany == "BGD") {
      // Add the Bgd_LOGO with additional margin from the left border
      doc.addImage(Bgd_LOGO, "JPEG", 15, 11, 28, 28); // Change x coordinate (e.g., 25 or 30)
      doc.setFontSize(10);
      doc.setTextColor(0, 0, 0);
      doc.text(companyInfoText, 25 + 20 + 2, 18, {
        // Change margin after Bgd_LOGO width (e.g., 2 or 3)
        maxWidth: doc.internal.pageSize.width * 0.55 - 25 - 20 - 15, // Calculate available width minus adjusted logo position and margins
      });
    }

    const title = invoiceHeaderDetails.invoiceTitle;
    const titleHeight = 3;
    const titleY = encompassingRectY + 1;
    doc.setFontSize(10);
    doc.text(title, doc.internal.pageSize.width / 2, titleY + titleHeight, {
      align: "center",
    });

    const CompInvo = [
      ["Invoice No: " + invoiceHeaderDetails.invoiceRefNo],
      ["Company's GST NO.: " + companyProfile.gstin], // Include GSTIN directly
      ["Company's PAN No.: " + companyProfile.pan], // Include PAN directly
      ["Company's CIN: " + companyProfile.cin], // Include CIN directly
      [
        "Invoice Date: " +
          invoiceHeaderDetails.invoiceDate.split("-").reverse().join("-") ||
          "N/A",
      ],
    ];

    const tableWidth = doc.internal.pageSize.width * 0.45;
    const leftMargin = doc.internal.pageSize.width - tableWidth - 10;

    // Calculate the height of the CompInvo table
    const compInvoTableHeight = doc.autoTable({
      body: CompInvo,
      theme: "grid",
      margin: { top: 10, left: leftMargin, right: 14, bottom: 0 },
      styles: {
        fontSize: 10,
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        cellPadding: 2,
        marginBottom: 0,
        marginTop: 0,
        padding: 0,
      },
    }).lastAutoTable.finalY;
    const ClientInvo = [
      ["Service ACC Code: " + gstDetails.hsnsac],
      ["Client's GSTIN: " + gstDetails.gstin],
      ["Payment Term: 15 Days Bank Transfer"],
      ["Onsite Manpower Support for the month"],
      [
        `${invoiceHeaderDetails.paymentMonth} ${invoiceHeaderDetails.paymentYear}`,
      ],
    ];
    // Set the startY parameter for ClientInvo table to start immediately after CompInvo table
    doc.autoTable({
      body: ClientInvo,
      theme: "grid",
      columnStyles: { 0: { cellWidth: "auto" } },
      startY: compInvoTableHeight,
      margin: {
        top: compInvoTableHeight,
        left: leftMargin,
        right: 14,
        bottom: 0,
      },
      styles: {
        fontSize: 10,
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        marginBottom: 0,
        tableHeight: 40,
      },
    });
    // const billedToContainerHeight = 38.85;
    // const clientAddressLines = clientDetails.clientAddress.trim().split('\n'); // Split the address into multiple lines
    // const billedToTextLines = [
    //   `Billed To: ${clientDetails.clientName.trim()}`,
    //   // `Address: ${clientDetails.clientAddress.trim()}`,
    //   ...clientAddressLines, // Spread the address lines into the array
    //   `Place of supply: ${gstDetails.state} State Code: ${gstDetails.stateCode}`,
    //   `Ph: ${clientDetails.clientContactNumber}`,
    //   `Kind Attn: ${paymentMode.KindAttention.trim() || ""}`,
    // ];

    // const billedToContainerX = companyInfoContainerX;
    // const topMargin = 0;
    // const containerHeight = 40; // Set the common height for both the billedToContainer and the table
    // const billedToContainerY =
    //   companyInfoContainerY + companyInfoContainerHeight + topMargin;
    // const billedToContainerWidth = companyInfoContainerWidth;

    // doc.setDrawColor(0);
    // doc.setLineWidth(0.4);
    // doc.setFillColor(255, 255, 255);
    // doc.rect(
    //   billedToContainerX,
    //   billedToContainerY,
    //   billedToContainerWidth,
    //   containerHeight,
    //   "FD"
    // );

    // doc.setFontSize(10);
    // doc.setTextColor(0, 0, 0);

    // const lineHeight = 5;
    // let yPosition = billedToContainerY + 10;

    // billedToTextLines.forEach((line) => {
    //   doc.text(line.trim(), billedToContainerX + 5, yPosition, {
    //     maxWidth: billedToContainerWidth - 10,
    //   });
    //   yPosition += lineHeight;
    // });
    // doc.setFillColor(255, 255, 255);
    const billedToContainerHeight = 38.85;
    const clientAddressLines = clientDetails.clientAddress.trim().split("\n"); // Split the address into multiple lines
    const billedToTextLines = [
      `Billed To: ${clientDetails.clientName.trim()}`,
      ...clientAddressLines, // Spread the address lines into the array
      "",
      `Place of supply: ${gstDetails.state} State Code: ${gstDetails.stateCode}`,
      `Ph: ${clientDetails.clientContactNumber}`,
      `Kind Attn: ${paymentMode.KindAttention.trim() || ""}`,
    ];

    const billedToContainerX = companyInfoContainerX;
    const topMargin = 0;
    const containerHeight = 40; // Set the common height for both the billedToContainer and the table
    const billedToContainerY =
      companyInfoContainerY + companyInfoContainerHeight + topMargin;
    const billedToContainerWidth = companyInfoContainerWidth;

    doc.setDrawColor(0);
    doc.setLineWidth(0.4);
    doc.setFillColor(255, 255, 255);
    doc.rect(
      billedToContainerX,
      billedToContainerY,
      billedToContainerWidth,
      containerHeight,
      "FD"
    );

    doc.setFontSize(10);
    doc.setTextColor(0, 0, 0);

    const lineHeight = 6; // Adjusted line height
    let yPosition = billedToContainerY + 5;

    billedToTextLines.forEach((line) => {
      doc.text(line.trim(), billedToContainerX + 5, yPosition, {
        maxWidth: billedToContainerWidth - 10,
      });
      yPosition += lineHeight;
    });
    doc.setFillColor(255, 255, 255);

    const tableData =
      invoiceEmployeePoDetailsUIList.invoiceEmployeePoDetailsUIList.map(
        (row, index) => [
          index + 1,
          row.emplyoyeeName,
          row.currentPoNo,
          row.fromDate.split("-").reverse().join("-") || "N/A",
          row.toDate.split("-").reverse().join("-") || "N/A",
          invoiceHeaderDetails.workingDays,
          row.presenty,
          row.currentPoRate,
          row.presentyAmt,
        ]
      );

    doc.autoTable({
      head: [
        [
          "SR.NO",
          "Particulars",
          "PO NO.",
          "From Date",
          "To date",
          "Working Days",
          "Billable Days",
          "Current PO Rate",
          "Amount",
        ],
      ],
      body: tableData,
      startY: billedToContainerY + billedToContainerHeight + 0,
      theme: "grid",
      styles: {
        fontSize: 9,
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        marginBottom: 0,
      },
      columnStyles: {
        // Set fixed width for the last column
        8: { columnWidth: 35 },
      },
    });
    // Calculate the height of the first table (invoice data table)
    const firstTableHeight = doc.previousAutoTable
      ? doc.previousAutoTable.finalY
      : 0;

    const { totalPresentyAmount, grandTotalAmount, totalTaxAmt, totalInWord } =
      invoiceCalculation;
    const { cgst, sgst, igst, cgstAmount, sgstAmount, igstAmount } =
      invoiceCalculation;

    const InvoiceData = [
      ["A] Total", totalPresentyAmount],
      ["B] CGST (1)", ` ${cgstAmount}`],
      ["C] SGST (2)", ` ${sgstAmount}`],
      ["D] IGST (3)", ` ${igstAmount}`],
      ["E] Total (A+B+C+D)", grandTotalAmount],
      ["F] Deduction ", 0],
      ["G] Grand Total (F-G)", grandTotalAmount],
      [{ content: " Total In Words: " + totalInWord, colSpan: 2 }],
    ];

    // Set startY for the second table immediately after the first table
    doc.autoTable({
      body: InvoiceData,
      startY: firstTableHeight + 0, // You can adjust the spacing as needed
      theme: "grid",
      styles: {
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        marginBottom: 0,
      },
      columnStyles: {
        1: { columnWidth: 35, halign: "right" }, // Set value column width to 40
      },
    });
    // Calculate the height of the second table (invoice data table)
    const secondTableHeight = doc.previousAutoTable
      ? doc.previousAutoTable.finalY
      : 0;

    // Calculate remaining space on the page
    const remainingSpace =
      doc.internal.pageSize.getHeight() - secondTableHeight - 20;

    // Check if there is enough space for the bank table
    if (remainingSpace < 0) {
      doc.addPage();
    }
    let finalY = doc.internal.pageSize.height;

    if (doc.previousAutoTable && doc.previousAutoTable.finalY) {
      finalY = doc.previousAutoTable.finalY + 5; // Add some padding
    }

    const innerRectHeight = 60; // Set a fixed height for the inner rectangle
    const remainingSpace2 = doc.internal.pageSize.height - finalY;

    // Check if the inner rectangle can fit in the remaining space
    if (remainingSpace2 < innerRectHeight) {
      doc.addPage();
      finalY = 15; // Reset Y position for new page
    }

    // Draw the encompassing border at the end
    doc.setDrawColor(0); // Set border color to black
    doc.setLineWidth(0.4); // Set border width
    //draw Big border for the pdf page
    doc.rect(
      3,
      4,
      doc.internal.pageSize.width - 6,
      finalY > doc.internal.pageSize.height - 8
        ? finalY
        : doc.internal.pageSize.height - 8
    );

    // const innerRectX = 20;
    // const innerRectY = finalY + 0; // Start just after the previous table
    // const innerRectWidth = doc.internal.pageSize.width - 30;
    const innerRectX = 14;
    const innerRectY = finalY - 5; // Start just after the previous table
    const innerRectWidth = 182;
    // Draw the inner rectangle
    doc.rect(innerRectX, innerRectY, innerRectWidth, innerRectHeight);

    // Draw the vertical line to split the inner rectangle
    const splitLineX = innerRectX + innerRectWidth / 2;
    doc.line(splitLineX, innerRectY, splitLineX, innerRectY + innerRectHeight);

    // Add bank details to the left section
    const bankDetailsArray = [
      `Bank Name: ${bankName}`,
      `Bank Address: ${bankAddress}`,
      `A/C No.: ${accountNumber}`,
      `IFC Code: ${ifsc}`,
      `Swift Code: ${swiftCode}`,
    ];

    const maxTextWidth = innerRectWidth / 2 - 10; // Maximum width for the text in the left section
    let leftTextY = innerRectY + 10; // Initial Y position for the left text

    const lineHeight2 = 7; // Increase line height for more margin

    bankDetailsArray.forEach((line) => {
      const lines = doc.splitTextToSize(line, maxTextWidth);
      lines.forEach((wrappedLine) => {
        doc.text(wrappedLine, innerRectX + 5, leftTextY);
        leftTextY += lineHeight2; // Adjust the line height for more margin
      });
    });

    // Add text to the right section
    const rightText1 = "For Gsquare Techsystem PVT LTD";
    const rightText2 = "Authorized Signatory & Seal";
    const rightText1Width = doc.getTextWidth(rightText1);
    const rightText2Width = doc.getTextWidth(rightText2);

    const rightTextX1 = splitLineX + (innerRectWidth / 2 - rightText1Width) / 2;
    const rightTextX2 = splitLineX + (innerRectWidth / 2 - rightText2Width) / 2;

    const gapBetweenLines = 30; // Set the gap between the lines here

    doc.text(rightText1, rightTextX1, innerRectY + 10);
    doc.text(rightText2, rightTextX2, innerRectY + 10 + gapBetweenLines); // Adjust the Y position for the second line
    // Save the document
    // const invoiceDetailsList =
    //   invoiceEmployeePoDetailsUIList.invoiceEmployeePoDetailsUIList;
    // const annexureFlag = invoiceDetailsList.some(
    //   (employee) => employee.annenuxFlag === true
    // );
    // if (annexureFlag) {
    //   doc.addPage();
    //   // Add page border
    //   doc.setDrawColor(0);
    //   doc.setLineWidth(0.3);
    //   doc.rect(
    //     5,
    //     5,
    //     doc.internal.pageSize.getWidth() - 10,
    //     doc.internal.pageSize.getHeight() - 10
    //   );
    //   // Add title in the middle
    // Add title in the center
    // const pageTitle = "Annexure";
    // doc.setFontSize(18);
    // const pageWidth = doc.internal.pageSize.getWidth();
    // const titleWidth = (doc.getStringUnitWidth(pageTitle) * doc.internal.getFontSize()) / doc.internal.scaleFactor;
    // const titleX = (pageWidth - titleWidth) / 2;
    // doc.text(pageTitle, titleX, 20);

    //   // Border around additional fields and table
    //   const borderX = 10;
    //   const borderY = 40;
    //   const borderWidth = 190;
    //   const borderHeight = doc.internal.pageSize.getHeight() - 60; // Adjust height as needed (increased height)
    //   doc.rect(borderX, borderY, borderWidth, borderHeight);

    //   // Additional Fields
    //   const additionalFieldsTop = borderY + 10;
    //   const additionalFieldsMargin = 10; // Margin between additional fields
    //   doc.setFontSize(10);
    //   doc.text(
    //     15,
    //     additionalFieldsTop,
    //     `Client Name: ${invoiceHeaderDetails.clientNameAndCode}`
    //   );
    //   doc.text(
    //     15,
    //     additionalFieldsTop + additionalFieldsMargin,
    //     `Invoice No: ${invoiceHeaderDetails.invoiceRefNo}`
    //   );
    //   doc.text(
    //     15,
    //     additionalFieldsTop + additionalFieldsMargin * 2,
    //     `Invoice Date: ${invoiceHeaderDetails.invoiceDate}`
    //   );
    //   doc.text(
    //     15,
    //     additionalFieldsTop + additionalFieldsMargin * 3,
    //     `Company's Service Accounting Code (SAC): ${invoiceHeaderDetails.sacHsn}`
    //   );
    //   doc.text(
    //     15,
    //     additionalFieldsTop + additionalFieldsMargin * 4,
    //     "Subject: Facilities-Canteen & Transportation Deductions"
    //   );

    //   // Table
    //   const tableTop = additionalFieldsTop + additionalFieldsMargin * 5 + 10; // Adjust spacing between additional fields and table
    //   const tableHeaders = [
    //     "Sr.No",
    //     "Employee Name",
    //     "Presenty",
    //     "Canteen Amount",
    //     "Transportation Amount",
    //     "Total Amount",
    //   ];

    //   // Filter and map the employee data
    //   const tableData =
    //     invoiceEmployeePoDetailsUIList.invoiceEmployeePoDetailsUIList
    //       .filter((employee) => employee.annenuxFlag === true)
    //       .map((employee, index) => [
    //         index + 1,
    //         employee.emplyoyeeName,
    //         employee.presenty,
    //         employee.canteenAmt,
    //         employee.transportationAmt,
    //         employee.canteenAmt + employee.transportationAmt,
    //       ]);

    //   // Calculate the available height for the table
    //   const availableHeight = borderHeight - (tableTop - borderY);

    //   // Add table with dynamic page breaks
    //   doc.autoTable({
    //     head: [tableHeaders],
    //     body: tableData,
    //     startY: tableTop,
    //     theme: "grid",
    //     styles: {
    //       fontSize: 9,
    //       lineWidth: 0.3,
    //       lineColor: [0, 0, 0],
    //       fillColor: [255, 255, 255],
    //       textColor: [0, 0, 0],
    //       marginBottom: 0,
    //     },
    //     columnStyles: {
    //       0: { cellWidth: 20 }, // Adjust column width if needed
    //       // Add other column styles here
    //     },
    //     didDrawPage: function (data) {
    //       // Ensure the table doesn't go out of the border
    //       const tableBottomY = data.cursor.y;
    //       if (tableBottomY > borderY + borderHeight) {
    //         // Add border on the new page
    //         doc.setDrawColor(0);
    //         doc.setLineWidth(0.3);
    //         doc.rect(
    //           5,
    //           5,
    //           doc.internal.pageSize.getWidth() - 10,
    //           doc.internal.pageSize.getHeight() - 10
    //         );
    //         // Add border around the table on the new page
    //         doc.rect(borderX, borderY, borderWidth, borderHeight);
    //       }
    //     },
    //     willDrawCell: function (data) {
    //       if (data.row.index === data.table.body.length - 1) {
    //         // If the cell is in the last row, check if the table exceeds the border
    //         if (
    //           data.cursor.y + data.cell.styles.cellPadding >
    //           borderY + borderHeight
    //         ) {
    //           doc.addPage();
    //           data.cell.styles.cellPadding = 0; // Reset cell padding on new page
    //         }
    //       }
    //     },
    //   });
    // }
    // if (invoiceHeaderDetails.selectCompany == "GST") {
    //   doc.save(
    //     `Gsquare_Employee_Invoice_PerMonth_${clientDetails.clientName}(${invoiceHeaderDetails.invoiceRefNo}).pdf`
    //   );
    // }
    // if (invoiceHeaderDetails.selectCompany == "BGD") {
    //   doc.save(
    //     `Borgward_Employee_Invoice_PerMonth_${clientDetails.clientName}(${invoiceHeaderDetails.invoiceRefNo}).pdf`
    //   );
    // }

    // const invoiceDetailsList =
    //   invoiceEmployeePoDetailsUIList.invoiceEmployeePoDetailsUIList;
    // const annexureFlag = invoiceDetailsList.some(
    //   (employee) => employee.annenuxFlag === true
    // );
    // if (annexureFlag) {
    //   const addPageWithBorder = () => {
    //     doc.addPage();
    //     doc.setDrawColor(0);
    //     doc.setLineWidth(0.3);
    //     doc.rect(
    //       5,
    //       5,
    //       doc.internal.pageSize.getWidth() - 10,
    //       doc.internal.pageSize.getHeight() - 10
    //     );
    //     doc.rect(borderX, borderY, borderWidth, borderHeight);
    //   };

    //   doc.addPage();
    //   // Add page border
    //   doc.setDrawColor(0);
    //   doc.setLineWidth(0.3);
    //   doc.rect(
    //     5,
    //     5,
    //     doc.internal.pageSize.getWidth() - 10,
    //     doc.internal.pageSize.getHeight() - 10
    //   );

    //   // Add title in the center
    //   const pageTitle = "Annexure";
    //   doc.setFontSize(18);
    //   const pageWidth = doc.internal.pageSize.getWidth();
    //   const titleWidth =
    //     (doc.getStringUnitWidth(pageTitle) * doc.internal.getFontSize()) /
    //     doc.internal.scaleFactor;
    //   const titleX = (pageWidth - titleWidth) / 2;
    //   doc.text(pageTitle, titleX, 20);

    //   // Border around additional fields and table
    //   const borderX = 10;
    //   const borderY = 40;
    //   const borderWidth = 190;
    //   const borderHeight = doc.internal.pageSize.getHeight() - 60; // Adjust height as needed
    //   doc.rect(borderX, borderY, borderWidth, borderHeight);

    //   // Additional Fields
    //   const additionalFieldsTop = borderY + 10;
    //   const additionalFieldsMargin = 10; // Margin between additional fields
    //   doc.setFontSize(10);
    //   doc.text(
    //     15,
    //     additionalFieldsTop,
    //     `Client Name: ${invoiceHeaderDetails.clientNameAndCode}`
    //   );
    //   doc.text(
    //     15,
    //     additionalFieldsTop + additionalFieldsMargin,
    //     `Invoice No: ${invoiceHeaderDetails.invoiceRefNo}`
    //   );
    //   doc.text(
    //     15,
    //     additionalFieldsTop + additionalFieldsMargin * 2,
    //     `Invoice Date: ${invoiceHeaderDetails.invoiceDate}`
    //   );
    //   doc.text(
    //     15,
    //     additionalFieldsTop + additionalFieldsMargin * 3,
    //     `Company's Service Accounting Code (SAC): ${invoiceHeaderDetails.sacHsn}`
    //   );
    //   doc.text(
    //     15,
    //     additionalFieldsTop + additionalFieldsMargin * 4,
    //     "Subject: Facilities-Canteen & Transportation Deductions"
    //   );

    //   // Table
    //   const tableTop = additionalFieldsTop + additionalFieldsMargin * 5 + 10; // Adjust spacing between additional fields and table
    //   const tableHeaders = [
    //     "Sr.No",
    //     "Employee Name",
    //     "Presenty",
    //     "Canteen Amount",
    //     "Transportation Amount",
    //     "Total Amount",
    //   ];

    //   // Filter and map the employee data
    //   const tableData = invoiceDetailsList
    //     .filter((employee) => employee.annenuxFlag === true)
    //     .map((employee, index) => [
    //       index + 1,
    //       employee.emplyoyeeName,
    //       employee.presenty,
    //       employee.canteenAmt,
    //       employee.transportationAmt,
    //       employee.canteenAmt + employee.transportationAmt,
    //     ]);

    //   const startPage = doc.internal.getCurrentPageInfo().pageNumber;

    //   doc.autoTable({
    //     head: [tableHeaders],
    //     body: tableData,
    //     startY: tableTop,
    //     theme: "grid",
    //     styles: {
    //       fontSize: 9,
    //       lineWidth: 0.3,
    //       lineColor: [0, 0, 0],
    //       fillColor: [255, 255, 255],
    //       textColor: [0, 0, 0],
    //       marginBottom: 0,
    //     },
    //     columnStyles: {
    //       0: { cellWidth: 20 }, // Adjust column width if needed
    //       // Add other column styles here
    //     },
    //     didDrawPage: function (data) {
    //       if (data.pageNumber !== startPage) {
    //         addPageWithBorder();
    //       }
    //     },
    //     willDrawCell: function (data) {
    //       // Check if the table exceeds the border and add a new page if necessary
    //       if (
    //         data.row.index === data.table.body.length - 1 &&
    //         data.cursor.y + data.cell.height > borderY + borderHeight
    //       ) {
    //         const remainingTableData = tableData.slice(data.row.index);
    //         addPageWithBorder();

    //         doc.autoTable({
    //           head: [tableHeaders],
    //           body: remainingTableData,
    //           startY: borderY + 10,
    //           theme: "grid",
    //           styles: {
    //             fontSize: 9,
    //             lineWidth: 0.3,
    //             lineColor: [0, 0, 0],
    //             fillColor: [255, 255, 255],
    //             textColor: [0, 0, 0],
    //             marginBottom: 0,
    //           },
    //           columnStyles: {
    //             0: { cellWidth: 20 }, // Adjust column width if needed
    //             // Add other column styles here
    //           },
    //           didDrawPage: function () {
    //             addPageWithBorder();
    //           },
    //         });
    //         data.row.index = data.table.body.length; // Skip remaining rows
    //       }
    //     },
    //   });
    // }
    const invoiceDetailsList =
      invoiceEmployeePoDetailsUIList.invoiceEmployeePoDetailsUIList;
    const annexureFlag = invoiceDetailsList.some(
      (employee) => employee.annenuxFlag === true
    );
    if (annexureFlag) {
      doc.addPage();
      // Add page border
      doc.setDrawColor(0);
      doc.setLineWidth(0.3);
      doc.rect(
        5,
        5,
        doc.internal.pageSize.getWidth() - 10,
        doc.internal.pageSize.getHeight() - 10
      );
      // Add title in the center
      const pageTitle = "Annexure";
      doc.setFontSize(18);
      const pageWidth = doc.internal.pageSize.getWidth();
      const titleWidth =
        (doc.getStringUnitWidth(pageTitle) * doc.internal.getFontSize()) /
        doc.internal.scaleFactor;
      const titleX = (pageWidth - titleWidth) / 2;
      doc.text(pageTitle, titleX, 20);
      // Border around additional fields and table
      const borderX = 10;
      const borderY = 40;
      const borderWidth = doc.internal.pageSize.getWidth() - 20; // Adjusted width to fit within page
      const borderHeight = doc.internal.pageSize.getHeight() - 60; // Adjust height as needed
      doc.rect(borderX, borderY, borderWidth, borderHeight);
      // Additional Fields
      const additionalFieldsTop = borderY + 10;
      const additionalFieldsMargin = 10; // Margin between additional fields
      doc.setFontSize(10);
      doc.text(
        15,
        additionalFieldsTop,
        `Client Name: ${invoiceHeaderDetails.clientNameAndCode}`
      );
      doc.text(
        15,
        additionalFieldsTop + additionalFieldsMargin,
        `Invoice No.: ${invoiceHeaderDetails.invoiceRefNo}`
      );
      doc.text(
        15,
        additionalFieldsTop + additionalFieldsMargin * 2,
        `Invoice Date: ${
          invoiceHeaderDetails.invoiceDate.split("-").reverse().join("-") ||
          "N/A"
        }`
      );
      doc.text(
        15,
        additionalFieldsTop + additionalFieldsMargin * 3,
        `Company's Service Accounting Code (SAC):${invoiceHeaderDetails.sacHsn}`
      );
      doc.text(
        15,
        additionalFieldsTop + additionalFieldsMargin * 4,
        "Subject: Facilities-Canteen & Transportation Deductions"
      );

      // Table
      const tableTop = additionalFieldsTop + additionalFieldsMargin * 5 + 10; // Adjust spacing between additional fields and table
      const tableHeaders = [
        "Sr.No",
        "Employee Name",
        "Presenty",
        "Canteen Amount",
        "Transportation Amount",
        "Total Amount",
      ];
      const tableData = invoiceDetailsList
        .filter((employee) => employee.annenuxFlag === true)
        .map((employee, index) => [
          index + 1,
          employee.emplyoyeeName,
          employee.presenty,
          employee.canteenAmt,
          employee.transportationAmt,
          employee.canteenAmt + employee.transportationAmt,
        ]);

      // Calculate available height for the table
      const availableHeight = borderHeight - (tableTop - borderY) - 9; // Subtract spacing for padding
      const rowHeight = 8; // Estimate the height of a row
      const maxRows = Math.floor(availableHeight / rowHeight); // Calculate max rows that fit within the available height
      const newPageTableTop = 30; // Adjust the top margin for new pages

      // Split tableData into chunks that fit within the available height
      let currentY = tableTop;
      for (let i = 0; i < tableData.length; i += maxRows) {
        if (i > 0) {
          doc.addPage();
          doc.setDrawColor(0);
          doc.setLineWidth(0.3);
          // doc.rect(borderX, borderY, borderWidth, borderHeight);
          doc.rect(
            5,
            5,
            doc.internal.pageSize.getWidth() - 10,
            doc.internal.pageSize.getHeight() - 10
          );
          // currentY = tableTop; // Reset the currentY for the new page
          currentY = newPageTableTop; // Adjust the currentY for the new page
        }
        const tableChunk = tableData.slice(i, i + maxRows);
        doc.autoTable({
          head: [tableHeaders],
          body: tableChunk,
          startY: currentY,
          theme: "grid",
          styles: {
            fontSize: 9,
            lineWidth: 0.3,
            lineColor: [0, 0, 0],
            fillColor: [255, 255, 255],
            textColor: [0, 0, 0],
            marginBottom: 0,
          },
          margin: { left: borderX + 5, right: borderX + 5 }, // Adjust margins to fit within the border
          tableWidth: borderWidth - 10, // Adjust table width to fit within the border
          columnStyles: {
            0: { cellWidth: 20 }, // Adjust column width if needed
            1: { cellWidth: 40 }, // Example for adjusting column width
            2: { cellWidth: 30 },
            3: { cellWidth: 30 },
            4: { cellWidth: 30 },
            5: { cellWidth: 30 },
          },
        });
        currentY += tableChunk.length * rowHeight - 10; // Adjust currentY for the next chunk
      }
    }

    if (invoiceHeaderDetails.selectCompany == "GST") {
      doc.save(
        `Gsquare_Employee_Invoice_PerHour_${clientDetails.clientName}(${invoiceHeaderDetails.invoiceRefNo}).pdf`
      );
    }
    if (invoiceHeaderDetails.selectCompany == "BGD") {
      doc.save(
        `Borgward_Employee_Invoice_PerHour_${clientDetails.clientName}(${invoiceHeaderDetails.invoiceRefNo}).pdf`
      );
    }
  };
  const generatePDFPerHour = async (
    invoiceHeaderDetails,
    invoiceCalculation
  ) => {
    const doc = new jsPDF();

    // Add a new rectangle to encompass all tables and containers
    const encompassingRectX = 3;
    const encompassingRectY = 4;
    const encompassingRectWidth = doc.internal.pageSize.width - 6;
    const encompassingRectHeight = doc.internal.pageSize.height - 8;

    doc.setDrawColor(0); // Set border color to black
    doc.setLineWidth(0.4); // Set border width
    doc.rect(
      encompassingRectX,
      encompassingRectY,
      encompassingRectWidth,
      encompassingRectHeight
    );

    let companyInfoText = ""; // Declare companyInfoText outside the try block

    try {
      // Information for GSQUARE TECHSYSTEMS PVT LTD
      companyInfoText =
        `${companyProfile.companyName}\n` +
        `${companyProfile.companyAddress}\n` +
        `${companyProfile.website}\n` +
        `${companyProfile.eMail}`;

      doc.setFontSize(10);
      doc.setTextColor(0, 0, 0); // Black color

      // Add the company information to the PDF
      doc.setFont("helvetica", "bold"); // Set font to bold
      doc.text(companyInfoText, 15, 30);
      doc.setFont("helvetica", "normal"); // Reset font to normal

      // Continue with the rest of your PDF generation logic...
    } catch (error) {
      console.error("Error fetching company data:", error);
      // Handle errors if any
    }

    const companyInfoContainerX = 14;
    const companyInfoContainerY = 10;
    const companyInfoContainerWidth =
      doc.internal.pageSize.width * 0.55 - companyInfoContainerX - 10;
    const companyInfoContainerHeight = 40;

    // Draw the container first
    doc.setDrawColor(0);
    doc.setLineWidth(0.4);
    doc.setFillColor(255, 255, 255);
    doc.rect(
      companyInfoContainerX,
      companyInfoContainerY,
      companyInfoContainerWidth,
      companyInfoContainerHeight,
      "FD"
    );
    if (invoiceHeaderDetails.selectCompany == "GST") {
      // Add the logo with additional margin from the left border
      doc.addImage(GsquareLOGO, "JPEG", 15, 11, 28, 28); // Change x coordinate (e.g., 25 or 30)
      // Position the company information text with reduced margin after the logo
      doc.setFontSize(10);
      doc.setTextColor(0, 0, 0);
      doc.text(companyInfoText, 25 + 20 + 2, 18, {
        // Change margin after logo width (e.g., 2 or 3)
        maxWidth: doc.internal.pageSize.width * 0.55 - 25 - 20 - 15, // Calculate available width minus adjusted logo position and margins
      });
    }
    if (invoiceHeaderDetails.selectCompany == "BGD") {
      // Add the Bgd_LOGO with additional margin from the left border
      doc.addImage(Bgd_LOGO, "JPEG", 15, 11, 28, 28); // Change x coordinate (e.g., 25 or 30)
      doc.setFontSize(10);
      doc.setTextColor(0, 0, 0);
      doc.text(companyInfoText, 25 + 20 + 2, 18, {
        // Change margin after Bgd_LOGO width (e.g., 2 or 3)
        maxWidth: doc.internal.pageSize.width * 0.55 - 25 - 20 - 15, // Calculate available width minus adjusted logo position and margins
      });
    }

    const title = invoiceHeaderDetails.invoiceTitle;
    const titleHeight = 3;
    const titleY = encompassingRectY + 1;
    doc.setFontSize(10);
    doc.text(title, doc.internal.pageSize.width / 2, titleY + titleHeight, {
      align: "center",
    });

    const CompInvo = [
      ["Invoice No", invoiceHeaderDetails.invoiceRefNo],
      ["Company's GST NO.", companyProfile.gstin], // Include GSTIN directly
      ["Company's PAN No.", companyProfile.pan], // Include PAN directly
      ["Company's CIN", companyProfile.cin], // Include CIN directly
      ["Invoice Date", invoiceHeaderDetails.invoiceDate],
    ];

    const tableWidth = doc.internal.pageSize.width * 0.45;
    const leftMargin = doc.internal.pageSize.width - tableWidth - 10;

    // Calculate the height of the CompInvo table
    const compInvoTableHeight = doc.autoTable({
      body: CompInvo,
      theme: "grid",
      margin: { top: 10, left: leftMargin, right: 14, bottom: 0 },
      styles: {
        fontSize: 10,
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        cellPadding: 2,
        marginBottom: 0,
        marginTop: 0,
        padding: 0,
      },
    }).lastAutoTable.finalY;

    const ClientInvo = [
      ["Service ACC Code: " + gstDetails.hsnsac],
      ["Client's GSTIN: " + gstDetails.gstin],
      ["Payment Term: 15 Days Bank Transfer"],
      ["Onsite Manpower Support for the month"],
      [
        `${invoiceHeaderDetails.paymentMonth} ${invoiceHeaderDetails.paymentYear}`,
      ],
    ];
    // Set the startY parameter for ClientInvo table to start immediately after CompInvo table
    doc.autoTable({
      body: ClientInvo,
      theme: "grid",
      columnStyles: { 0: { cellWidth: "auto" } },
      startY: compInvoTableHeight, // Set startY explicitly
      margin: {
        top: compInvoTableHeight,
        left: leftMargin,
        right: 14,
        bottom: 0,
      },
      styles: {
        fontSize: 10,
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        marginBottom: 0,
        tableHeight: 40,
      },
    });

    const billedToContainerHeight = 38.85;
    const clientAddressLines = clientDetails.clientAddress.trim().split("\n"); // Split the address into multiple lines
    const billedToTextLines = [
      `Billed To: ${clientDetails.clientName.trim()}`,
      ...clientAddressLines, // Spread the address lines into the array
      "",
      `Place of supply: ${gstDetails.state} State Code: ${gstDetails.stateCode}`,
      `Ph: ${clientDetails.clientContactNumber}`,
      `Kind Attn: ${paymentMode.KindAttention.trim() || ""}`,
    ];

    const billedToContainerX = companyInfoContainerX;
    const topMargin = 0;
    const containerHeight = 40; // Set the common height for both the billedToContainer and the table
    const billedToContainerY =
      companyInfoContainerY + companyInfoContainerHeight + topMargin;
    const billedToContainerWidth = companyInfoContainerWidth;

    doc.setDrawColor(0);
    doc.setLineWidth(0.4);
    doc.setFillColor(255, 255, 255);
    doc.rect(
      billedToContainerX,
      billedToContainerY,
      billedToContainerWidth,
      containerHeight,
      "FD"
    );

    doc.setFontSize(10);
    doc.setTextColor(0, 0, 0);

    const lineHeight = 6; // Adjusted line height
    let yPosition = billedToContainerY + 5;

    billedToTextLines.forEach((line) => {
      doc.text(line.trim(), billedToContainerX + 5, yPosition, {
        maxWidth: billedToContainerWidth - 10,
      });
      yPosition += lineHeight;
    });
    doc.setFillColor(255, 255, 255);

    const tableData =
      invoiceEmployeePoDetailsUIList.invoiceEmployeePoDetailsUIList.map(
        (row, index) => [
          index + 1,
          row.emplyoyeeName,
          row.currentPoNo,
          row.fromDate,
          row.toDate,
          row.presenty,
          row.currentPoRate,
          row.presentyAmt,
        ]
      );

    doc.autoTable({
      head: [
        [
          "SR.NO",
          "Particulars",
          "PO NO.",
          "From Date",
          "To date",
          "Billable Hours",
          "Current PO Rate",
          "Amount",
        ],
      ],
      body: tableData,
      startY: billedToContainerY + billedToContainerHeight + 0,
      theme: "grid",
      styles: {
        fontSize: 9,
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        marginBottom: 0,
      },
      columnStyles: {
        7: { columnWidth: 35 },
      },
    });
    // Calculate the height of the first table (invoice data table)
    const firstTableHeight = doc.previousAutoTable
      ? doc.previousAutoTable.finalY
      : 0;

    const { totalPresentyAmount, grandTotalAmount, totalTaxAmt, totalInWord } =
      invoiceCalculation;
    const { cgst, sgst, igst, cgstAmount, sgstAmount, igstAmount } =
      invoiceCalculation;

    const InvoiceData = [
      ["A] Total", totalPresentyAmount],
      ["B] CGST (1)", ` ${cgstAmount}`],
      ["C] SGST (2)", ` ${sgstAmount}`],
      ["D] IGST (3)", ` ${igstAmount}`],
      ["E] Total (A+B+C+D)", grandTotalAmount],
      ["F] Deduction ", 0],
      ["G] Grand Total (F-G)", grandTotalAmount],
      [{ content: " Total In Words: " + totalInWord, colSpan: 2 }],
    ];

    // Set startY for the second table immediately after the first table
    doc.autoTable({
      body: InvoiceData,
      startY: firstTableHeight + 0, // You can adjust the spacing as needed
      theme: "grid",
      styles: {
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        marginBottom: 0,
      },
      columnStyles: {
        1: { columnWidth: 35, halign: "right" },
      },
    });
    // Calculate the height of the second table (invoice data table)
    const secondTableHeight = doc.previousAutoTable
      ? doc.previousAutoTable.finalY
      : 0;

    // Calculate remaining space on the page
    const remainingSpace =
      doc.internal.pageSize.getHeight() - secondTableHeight - 20;

    // Check if there is enough space for the bank table
    if (remainingSpace < 0) {
      doc.addPage();
    }
    let finalY = doc.internal.pageSize.height;

    if (doc.previousAutoTable && doc.previousAutoTable.finalY) {
      finalY = doc.previousAutoTable.finalY + 5; // Add some padding
    }

    const innerRectHeight = 60; // Set a fixed height for the inner rectangle
    const remainingSpace2 = doc.internal.pageSize.height - finalY;

    // Check if the inner rectangle can fit in the remaining space
    if (remainingSpace2 < innerRectHeight) {
      doc.addPage();
      finalY = 15; // Reset Y position for new page
    }

    // Draw the encompassing border at the end
    doc.setDrawColor(0); // Set border color to black
    doc.setLineWidth(0.4); // Set border width
    //draw Big border for the pdf page
    doc.rect(
      3,
      4,
      doc.internal.pageSize.width - 6,
      finalY > doc.internal.pageSize.height - 8
        ? finalY
        : doc.internal.pageSize.height - 8
    );

    const innerRectX = 14;
    const innerRectY = finalY - 5; // Start just after the previous table
    const innerRectWidth = 182;
    // Draw the inner rectangle
    doc.rect(innerRectX, innerRectY, innerRectWidth, innerRectHeight);

    // Draw the vertical line to split the inner rectangle
    const splitLineX = innerRectX + innerRectWidth / 2;
    doc.line(splitLineX, innerRectY, splitLineX, innerRectY + innerRectHeight);

    // Add bank details to the left section
    const bankDetailsArray = [
      `Bank Name: ${bankName}`,
      `Bank Address: ${bankAddress}`,
      `A/C No.: ${accountNumber}`,
      `IFC Code: ${ifsc}`,
      `Swift Code: ${swiftCode}`,
    ];

    const maxTextWidth = innerRectWidth / 2 - 10; // Maximum width for the text in the left section
    let leftTextY = innerRectY + 10; // Initial Y position for the left text

    const lineHeight2 = 7; // Increase line height for more margin

    bankDetailsArray.forEach((line) => {
      const lines = doc.splitTextToSize(line, maxTextWidth);
      lines.forEach((wrappedLine) => {
        doc.text(wrappedLine, innerRectX + 5, leftTextY);
        leftTextY += lineHeight2; // Adjust the line height for more margin
      });
    });

    // Add text to the right section
    const rightText1 = "For Gsquare Techsystem PVT LTD";
    const rightText2 = "Authorized Signatory & Seal";
    const rightText1Width = doc.getTextWidth(rightText1);
    const rightText2Width = doc.getTextWidth(rightText2);

    const rightTextX1 = splitLineX + (innerRectWidth / 2 - rightText1Width) / 2;
    const rightTextX2 = splitLineX + (innerRectWidth / 2 - rightText2Width) / 2;

    const gapBetweenLines = 30; // Set the gap between the lines here

    doc.text(rightText1, rightTextX1, innerRectY + 10);
    doc.text(rightText2, rightTextX2, innerRectY + 10 + gapBetweenLines); // Adjust the Y position for the second line

    const invoiceDetailsList =
      invoiceEmployeePoDetailsUIList.invoiceEmployeePoDetailsUIList;
    const annexureFlag = invoiceDetailsList.some(
      (employee) => employee.annenuxFlag === true
    );
    if (annexureFlag) {
      doc.addPage();
      // Add page border
      doc.setDrawColor(0);
      doc.setLineWidth(0.3);
      doc.rect(
        5,
        5,
        doc.internal.pageSize.getWidth() - 10,
        doc.internal.pageSize.getHeight() - 10
      );
      // Add title in the center
      const pageTitle = "Annexure";
      doc.setFontSize(18);
      const pageWidth = doc.internal.pageSize.getWidth();
      const titleWidth =
        (doc.getStringUnitWidth(pageTitle) * doc.internal.getFontSize()) /
        doc.internal.scaleFactor;
      const titleX = (pageWidth - titleWidth) / 2;
      doc.text(pageTitle, titleX, 20);
      // Border around additional fields and table
      const borderX = 10;
      const borderY = 40;
      const borderWidth = doc.internal.pageSize.getWidth() - 20; // Adjusted width to fit within page
      const borderHeight = doc.internal.pageSize.getHeight() - 60; // Adjust height as needed
      doc.rect(borderX, borderY, borderWidth, borderHeight);
      // Additional Fields
      const additionalFieldsTop = borderY + 10;
      const additionalFieldsMargin = 10; // Margin between additional fields
      doc.setFontSize(10);
      doc.text(
        15,
        additionalFieldsTop,
        `Client Name: ${invoiceHeaderDetails.clientNameAndCode}`
      );
      doc.text(
        15,
        additionalFieldsTop + additionalFieldsMargin,
        `Invoice No.: ${invoiceHeaderDetails.invoiceRefNo}`
      );
      doc.text(
        15,
        additionalFieldsTop + additionalFieldsMargin * 2,
        `Invoice Date: ${
          invoiceHeaderDetails.invoiceDate.split("-").reverse().join("-") ||
          "N/A"
        }`
      );
      doc.text(
        15,
        additionalFieldsTop + additionalFieldsMargin * 3,
        `Company's Service Accounting Code (SAC):${invoiceHeaderDetails.sacHsn}`
      );
      doc.text(
        15,
        additionalFieldsTop + additionalFieldsMargin * 4,
        "Subject: Facilities-Canteen & Transportation Deductions"
      );

      // Table
      const tableTop = additionalFieldsTop + additionalFieldsMargin * 5 + 10; // Adjust spacing between additional fields and table
      const tableHeaders = [
        "Sr.No",
        "Employee Name",
        "Presenty",
        "Canteen Amount",
        "Transportation Amount",
        "Total Amount",
      ];
      const tableData = invoiceDetailsList
        .filter((employee) => employee.annenuxFlag === true)
        .map((employee, index) => [
          index + 1,
          employee.emplyoyeeName,
          employee.presenty,
          employee.canteenAmt,
          employee.transportationAmt,
          employee.canteenAmt + employee.transportationAmt,
        ]);

      // Calculate available height for the table
      const availableHeight = borderHeight - (tableTop - borderY) - 9; // Subtract spacing for padding
      const rowHeight = 8; // Estimate the height of a row
      const maxRows = Math.floor(availableHeight / rowHeight); // Calculate max rows that fit within the available height
      const newPageTableTop = 30; // Adjust the top margin for new pages

      // Split tableData into chunks that fit within the available height
      let currentY = tableTop;
      for (let i = 0; i < tableData.length; i += maxRows) {
        if (i > 0) {
          doc.addPage();
          doc.setDrawColor(0);
          doc.setLineWidth(0.3);
          // doc.rect(borderX, borderY, borderWidth, borderHeight);
          doc.rect(
            5,
            5,
            doc.internal.pageSize.getWidth() - 10,
            doc.internal.pageSize.getHeight() - 10
          );
          // currentY = tableTop; // Reset the currentY for the new page
          currentY = newPageTableTop; // Adjust the currentY for the new page
        }
        const tableChunk = tableData.slice(i, i + maxRows);
        doc.autoTable({
          head: [tableHeaders],
          body: tableChunk,
          startY: currentY,
          theme: "grid",
          styles: {
            fontSize: 9,
            lineWidth: 0.3,
            lineColor: [0, 0, 0],
            fillColor: [255, 255, 255],
            textColor: [0, 0, 0],
            marginBottom: 0,
          },
          margin: { left: borderX + 5, right: borderX + 5 }, // Adjust margins to fit within the border
          tableWidth: borderWidth - 10, // Adjust table width to fit within the border
          columnStyles: {
            0: { cellWidth: 20 }, // Adjust column width if needed
            1: { cellWidth: 40 }, // Example for adjusting column width
            2: { cellWidth: 30 },
            3: { cellWidth: 30 },
            4: { cellWidth: 30 },
            5: { cellWidth: 30 },
          },
        });
        currentY += tableChunk.length * rowHeight - 10; // Adjust currentY for the next chunk
      }
    }
    if (invoiceHeaderDetails.selectCompany == "GST") {
      doc.save(
        `Gsquare_Employee_Invoice_PerHour_${clientDetails.clientName}(${invoiceHeaderDetails.invoiceRefNo}).pdf`
      );
    }
    if (invoiceHeaderDetails.selectCompany == "BGD") {
      doc.save(
        `Borgward_Employee_Invoice_PerHour_${clientDetails.clientName}(${invoiceHeaderDetails.invoiceRefNo}).pdf`
      );
    }
  };

  const generatePDFPerDay = async (
    invoiceHeaderDetails,
    invoiceCalculation
  ) => {
    const doc = new jsPDF();

    const encompassingRectX = 3;
    const encompassingRectY = 4;
    const encompassingRectWidth = doc.internal.pageSize.width - 6;
    const encompassingRectHeight = doc.internal.pageSize.height - 8;

    doc.setDrawColor(0); // Set border color to black
    doc.setLineWidth(0.4); // Set border width
    doc.rect(
      encompassingRectX,
      encompassingRectY,
      encompassingRectWidth,
      encompassingRectHeight
    );

    let companyInfoText = ""; // Declare companyInfoText outside the try block

    try {
      // Information for GSQUARE TECHSYSTEMS PVT LTD
      companyInfoText =
        `${companyProfile.companyName}\n` +
        `${companyProfile.companyAddress}\n` +
        `${companyProfile.website}\n` +
        `${companyProfile.eMail}`;

      doc.setFontSize(10);
      doc.setTextColor(0, 0, 0); // Black color

      // Add the company information to the PDF
      doc.setFont("helvetica", "bold"); // Set font to bold
      doc.text(companyInfoText, 15, 30);
      doc.setFont("helvetica", "normal"); // Reset font to normal

      // Continue with the rest of your PDF generation logic...
    } catch (error) {
      console.error("Error fetching company data:", error);
      // Handle errors if any
    }

    const companyInfoContainerX = 14;
    const companyInfoContainerY = 10;
    const companyInfoContainerWidth =
      doc.internal.pageSize.width * 0.55 - companyInfoContainerX - 10;
    const companyInfoContainerHeight = 40;

    // Draw the container first
    doc.setDrawColor(0);
    doc.setLineWidth(0.4);
    doc.setFillColor(255, 255, 255);
    doc.rect(
      companyInfoContainerX,
      companyInfoContainerY,
      companyInfoContainerWidth,
      companyInfoContainerHeight,
      "FD"
    );

    if (invoiceHeaderDetails.selectCompany == "GST") {
      // Add the logo with additional margin from the left border
      doc.addImage(GsquareLOGO, "JPEG", 15, 11, 28, 28); // Change x coordinate (e.g., 25 or 30)
      // Position the company information text with reduced margin after the logo
      doc.setFontSize(10);
      doc.setTextColor(0, 0, 0);
      doc.text(companyInfoText, 25 + 20 + 2, 18, {
        // Change margin after logo width (e.g., 2 or 3)
        maxWidth: doc.internal.pageSize.width * 0.55 - 25 - 20 - 15, // Calculate available width minus adjusted logo position and margins
      });
    }
    if (invoiceHeaderDetails.selectCompany == "BGD") {
      // Add the Bgd_LOGO with additional margin from the left border
      doc.addImage(Bgd_LOGO, "JPEG", 15, 11, 28, 28); // Change x coordinate (e.g., 25 or 30)
      doc.setFontSize(10);
      doc.setTextColor(0, 0, 0);
      doc.text(companyInfoText, 25 + 20 + 2, 18, {
        // Change margin after Bgd_LOGO width (e.g., 2 or 3)
        maxWidth: doc.internal.pageSize.width * 0.55 - 25 - 20 - 15, // Calculate available width minus adjusted logo position and margins
      });
    }

    const title = invoiceHeaderDetails.invoiceTitle;
    const titleHeight = 3;
    const titleY = encompassingRectY + 1;
    doc.setFontSize(10);
    doc.text(title, doc.internal.pageSize.width / 2, titleY + titleHeight, {
      align: "center",
    });

    const CompInvo = [
      ["Invoice No", invoiceHeaderDetails.invoiceRefNo],
      ["Company's GST NO.", companyProfile.gstin], // Include GSTIN directly
      ["Company's PAN No.", companyProfile.pan], // Include PAN directly
      ["Company's CIN", companyProfile.cin], // Include CIN directly
      ["Invoice Date", invoiceHeaderDetails.invoiceDate],
    ];

    const tableWidth = doc.internal.pageSize.width * 0.45;
    const leftMargin = doc.internal.pageSize.width - tableWidth - 10;

    // Calculate the height of the CompInvo table
    const compInvoTableHeight = doc.autoTable({
      body: CompInvo,
      theme: "grid",
      margin: { top: 10, left: leftMargin, right: 14, bottom: 0 },
      styles: {
        fontSize: 10,
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        cellPadding: 2,
        marginBottom: 0,
        marginTop: 0,
        padding: 0,
      },
    }).lastAutoTable.finalY;

    const ClientInvo = [
      ["Service ACC Code: " + gstDetails.hsnsac],
      ["Client's GSTIN: " + gstDetails.gstin],
      ["Payment Term: 15 Days Bank Transfer"],
      ["Onsite Manpower Support for the month"],
      [
        `${invoiceHeaderDetails.paymentMonth} ${invoiceHeaderDetails.paymentYear}`,
      ],
    ];

    // Set the startY parameter for ClientInvo table to start immediately after CompInvo table
    doc.autoTable({
      body: ClientInvo,
      theme: "grid",
      columnStyles: { 0: { cellWidth: "auto" } },
      startY: compInvoTableHeight, // Set startY explicitly
      margin: {
        top: compInvoTableHeight,
        left: leftMargin,
        right: 14,
        bottom: 0,
      },
      styles: {
        fontSize: 10,
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        marginBottom: 0,
        tableHeight: 40,
      },
    });

    const billedToContainerHeight = 38.85;
    const clientAddressLines = clientDetails.clientAddress.trim().split("\n"); // Split the address into multiple lines
    const billedToTextLines = [
      `Billed To: ${clientDetails.clientName.trim()}`,
      ...clientAddressLines, // Spread the address lines into the array
      "",
      `Place of supply: ${gstDetails.state} State Code: ${gstDetails.stateCode}`,
      `Ph: ${clientDetails.clientContactNumber}`,
      `Kind Attn: ${paymentMode.KindAttention.trim() || ""}`,
    ];

    const billedToContainerX = companyInfoContainerX;
    const topMargin = 0;
    const containerHeight = 40; // Set the common height for both the billedToContainer and the table
    const billedToContainerY =
      companyInfoContainerY + companyInfoContainerHeight + topMargin;
    const billedToContainerWidth = companyInfoContainerWidth;

    doc.setDrawColor(0);
    doc.setLineWidth(0.4);
    doc.setFillColor(255, 255, 255);
    doc.rect(
      billedToContainerX,
      billedToContainerY,
      billedToContainerWidth,
      containerHeight,
      "FD"
    );

    doc.setFontSize(10);
    doc.setTextColor(0, 0, 0);

    const lineHeight = 6; // Adjusted line height
    let yPosition = billedToContainerY + 5;

    billedToTextLines.forEach((line) => {
      doc.text(line.trim(), billedToContainerX + 5, yPosition, {
        maxWidth: billedToContainerWidth - 10,
      });
      yPosition += lineHeight;
    });
    doc.setFillColor(255, 255, 255);

    const tableData =
      invoiceEmployeePoDetailsUIList.invoiceEmployeePoDetailsUIList.map(
        (row, index) => [
          index + 1,
          row.emplyoyeeName,
          row.currentPoNo,
          row.fromDate,
          row.toDate,
          row.presenty,
          row.currentPoRate,
          row.presentyAmt,
        ]
      );
    doc.autoTable({
      head: [
        [
          "SR.NO",
          "Particulars",
          "PO NO.",
          "From Date",
          "To date",
          "Billable Days",
          "Current PO Rate",
          "Amount",
        ],
      ],
      body: tableData,
      startY: billedToContainerY + billedToContainerHeight + 0,
      theme: "grid",
      styles: {
        fontSize: 9,
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        marginBottom: 0,
      },
      columnStyles: {
        7: { columnWidth: 35 },
      },
    });
    // Calculate the height of the first table (invoice data table)
    const firstTableHeight = doc.previousAutoTable
      ? doc.previousAutoTable.finalY
      : 0;

    const { totalPresentyAmount, grandTotalAmount, totalTaxAmt, totalInWord } =
      invoiceCalculation;
    const { cgst, sgst, igst, cgstAmount, sgstAmount, igstAmount } =
      invoiceCalculation;

    const InvoiceData = [
      ["A] Total", totalPresentyAmount],
      ["B] CGST (1)", ` ${cgstAmount}`],
      ["C] SGST (2)", ` ${sgstAmount}`],
      ["D] IGST (3)", ` ${igstAmount}`],
      ["E] Total (A+B+C+D)", grandTotalAmount],
      ["F] Deduction ", 0],
      ["G] Grand Total (F-G)", grandTotalAmount],
      [{ content: " Total In Words: " + totalInWord, colSpan: 2 }],
    ];

    // Set startY for the second table immediately after the first table
    doc.autoTable({
      body: InvoiceData,
      startY: firstTableHeight + 0, // You can adjust the spacing as needed
      theme: "grid",
      styles: {
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        marginBottom: 0,
      },
      columnStyles: {
        1: { columnWidth: 35, halign: "right" }, // Set value column width to 40
      },
    });

    // Calculate the height of the second table (invoice data table)
    const secondTableHeight = doc.previousAutoTable
      ? doc.previousAutoTable.finalY
      : 0;

    // Calculate remaining space on the page
    const remainingSpace =
      doc.internal.pageSize.getHeight() - secondTableHeight - 20;

    // Check if there is enough space for the bank table
    if (remainingSpace < 0) {
      doc.addPage();
    }
    let finalY = doc.internal.pageSize.height;

    if (doc.previousAutoTable && doc.previousAutoTable.finalY) {
      finalY = doc.previousAutoTable.finalY + 5; // Add some padding
    }

    const innerRectHeight = 60; // Set a fixed height for the inner rectangle
    const remainingSpace2 = doc.internal.pageSize.height - finalY;

    // Check if the inner rectangle can fit in the remaining space
    if (remainingSpace2 < innerRectHeight) {
      doc.addPage();
      finalY = 15; // Reset Y position for new page
    }

    // Draw the encompassing border at the end
    doc.setDrawColor(0); // Set border color to black
    doc.setLineWidth(0.4); // Set border width
    //draw Big border for the pdf page
    doc.rect(
      3,
      4,
      doc.internal.pageSize.width - 6,
      finalY > doc.internal.pageSize.height - 8
        ? finalY
        : doc.internal.pageSize.height - 8
    );

    // const innerRectX = 20;
    // const innerRectY = finalY + 0; // Start just after the previous table
    // const innerRectWidth = doc.internal.pageSize.width - 30;
    const innerRectX = 14;
    const innerRectY = finalY - 5; // Start just after the previous table
    const innerRectWidth = 182;
    // Draw the inner rectangle
    doc.rect(innerRectX, innerRectY, innerRectWidth, innerRectHeight);

    // Draw the vertical line to split the inner rectangle
    const splitLineX = innerRectX + innerRectWidth / 2;
    doc.line(splitLineX, innerRectY, splitLineX, innerRectY + innerRectHeight);

    // Add bank details to the left section
    const bankDetailsArray = [
      `Bank Name: ${bankName}`,
      `Bank Address: ${bankAddress}`,
      `A/C No.: ${accountNumber}`,
      `IFC Code: ${ifsc}`,
      `Swift Code: ${swiftCode}`,
    ];

    const maxTextWidth = innerRectWidth / 2 - 10; // Maximum width for the text in the left section
    let leftTextY = innerRectY + 10; // Initial Y position for the left text

    const lineHeight2 = 7; // Increase line height for more margin

    bankDetailsArray.forEach((line) => {
      const lines = doc.splitTextToSize(line, maxTextWidth);
      lines.forEach((wrappedLine) => {
        doc.text(wrappedLine, innerRectX + 5, leftTextY);
        leftTextY += lineHeight2; // Adjust the line height for more margin
      });
    });

    // Add text to the right section
    const rightText1 = "For Gsquare Techsystem PVT LTD";
    const rightText2 = "Authorized Signatory & Seal";
    const rightText1Width = doc.getTextWidth(rightText1);
    const rightText2Width = doc.getTextWidth(rightText2);

    const rightTextX1 = splitLineX + (innerRectWidth / 2 - rightText1Width) / 2;
    const rightTextX2 = splitLineX + (innerRectWidth / 2 - rightText2Width) / 2;

    const gapBetweenLines = 30; // Set the gap between the lines here

    doc.text(rightText1, rightTextX1, innerRectY + 10);
    doc.text(rightText2, rightTextX2, innerRectY + 10 + gapBetweenLines); // Adjust the Y position for the second line

    // const invoiceDetailsList =
    //   invoiceEmployeePoDetailsUIList.invoiceEmployeePoDetailsUIList;
    // const annexureFlag = invoiceDetailsList.some(
    //   (employee) => employee.annenuxFlag === true
    // );
    // if (annexureFlag) {
    //   doc.addPage();
    //   // Add page border
    //   doc.setDrawColor(0);
    //   doc.setLineWidth(0.3);
    //   doc.rect(
    //     5,
    //     5,
    //     doc.internal.pageSize.getWidth() - 10,
    //     doc.internal.pageSize.getHeight() - 10
    //   );

    //   // Add title in the center
    //   const pageTitle = "Annexure";
    //   doc.setFontSize(18);
    //   const pageWidth = doc.internal.pageSize.getWidth();
    //   const titleWidth =
    //     (doc.getStringUnitWidth(pageTitle) * doc.internal.getFontSize()) /
    //     doc.internal.scaleFactor;
    //   const titleX = (pageWidth - titleWidth) / 2;
    //   doc.text(pageTitle, titleX, 20);

    //   // Border around additional fields and table
    //   const borderX = 10;
    //   const borderY = 40;
    //   const borderWidth = 190;
    //   const borderHeight = doc.internal.pageSize.getHeight() - 60; // Adjust height as needed (increased height)
    //   doc.rect(borderX, borderY, borderWidth, borderHeight);

    //   // Additional Fields
    //   const additionalFieldsTop = borderY + 10;
    //   const additionalFieldsMargin = 10; // Margin between additional fields
    //   doc.setFontSize(10);
    //   doc.text(
    //     15,
    //     additionalFieldsTop,
    //     `Client Name: ${invoiceHeaderDetails.clientNameAndCode}`
    //   );
    //   doc.text(
    //     15,
    //     additionalFieldsTop + additionalFieldsMargin,
    //     `Invoice No: ${invoiceHeaderDetails.invoiceRefNo}`
    //   );
    //   doc.text(
    //     15,
    //     additionalFieldsTop + additionalFieldsMargin * 2,
    //     `Invoice Date: ${invoiceHeaderDetails.invoiceDate}`
    //   );
    //   doc.text(
    //     15,
    //     additionalFieldsTop + additionalFieldsMargin * 3,
    //     `Company's Service Accounting Code (SAC): ${invoiceHeaderDetails.sacHsn}`
    //   );
    //   doc.text(
    //     15,
    //     additionalFieldsTop + additionalFieldsMargin * 4,
    //     "Subject: Facilities-Canteen & Transportation Deductions"
    //   );

    //   // Table
    //   const tableTop = additionalFieldsTop + additionalFieldsMargin * 5 + 10; // Adjust spacing between additional fields and table
    //   const tableHeaders = [
    //     "Sr.No",
    //     "Employee Name",
    //     "Presenty",
    //     "Canteen Amount",
    //     "Transportation Amount",
    //     "Total Amount",
    //   ];

    //   // Filter and map the employee data
    //   const tableData =
    //     invoiceEmployeePoDetailsUIList.invoiceEmployeePoDetailsUIList
    //       .filter((employee) => employee.annenuxFlag === true)
    //       .map((employee, index) => [
    //         index + 1,
    //         employee.emplyoyeeName,
    //         employee.presenty,
    //         employee.canteenAmt,
    //         employee.transportationAmt,
    //         employee.canteenAmt + employee.transportationAmt,
    //       ]);

    //   // Calculate the available height for the table
    //   const availableHeight = borderHeight - (tableTop - borderY);

    //   // Add table with dynamic page breaks
    //   doc.autoTable({
    //     head: [tableHeaders],
    //     body: tableData,
    //     startY: tableTop,
    //     theme: "grid",
    //     styles: {
    //       fontSize: 9,
    //       lineWidth: 0.3,
    //       lineColor: [0, 0, 0],
    //       fillColor: [255, 255, 255],
    //       textColor: [0, 0, 0],
    //       marginBottom: 0,
    //     },
    //     columnStyles: {
    //       0: { cellWidth: 20 }, // Adjust column width if needed
    //       // Add other column styles here
    //     },
    //     didDrawPage: function (data) {
    //       // Ensure the table doesn't go out of the border
    //       const tableBottomY = data.cursor.y;
    //       if (tableBottomY > borderY + borderHeight) {
    //         // Add border on the new page
    //         doc.setDrawColor(0);
    //         doc.setLineWidth(0.3);
    //         doc.rect(
    //           5,
    //           5,
    //           doc.internal.pageSize.getWidth() - 10,
    //           doc.internal.pageSize.getHeight() - 10
    //         );
    //         // Add border around the table on the new page
    //         doc.rect(borderX, borderY, borderWidth, borderHeight);
    //       }
    //     },
    //     willDrawCell: function (data) {
    //       if (data.row.index === data.table.body.length - 1) {
    //         // If the cell is in the last row, check if the table exceeds the border
    //         if (
    //           data.cursor.y + data.cell.styles.cellPadding >
    //           borderY + borderHeight
    //         ) {
    //           doc.addPage();
    //           data.cell.styles.cellPadding = 0; // Reset cell padding on new page
    //         }
    //       }
    //     },
    //   });
    // }
    const invoiceDetailsList =
      invoiceEmployeePoDetailsUIList.invoiceEmployeePoDetailsUIList;
    const annexureFlag = invoiceDetailsList.some(
      (employee) => employee.annenuxFlag === true
    );
    if (annexureFlag) {
      doc.addPage();
      // Add page border
      doc.setDrawColor(0);
      doc.setLineWidth(0.3);
      doc.rect(
        5,
        5,
        doc.internal.pageSize.getWidth() - 10,
        doc.internal.pageSize.getHeight() - 10
      );
      // Add title in the center
      const pageTitle = "Annexure";
      doc.setFontSize(18);
      const pageWidth = doc.internal.pageSize.getWidth();
      const titleWidth =
        (doc.getStringUnitWidth(pageTitle) * doc.internal.getFontSize()) /
        doc.internal.scaleFactor;
      const titleX = (pageWidth - titleWidth) / 2;
      doc.text(pageTitle, titleX, 20);
      // Border around additional fields and table
      const borderX = 10;
      const borderY = 40;
      const borderWidth = doc.internal.pageSize.getWidth() - 20; // Adjusted width to fit within page
      const borderHeight = doc.internal.pageSize.getHeight() - 60; // Adjust height as needed
      doc.rect(borderX, borderY, borderWidth, borderHeight);
      // Additional Fields
      const additionalFieldsTop = borderY + 10;
      const additionalFieldsMargin = 10; // Margin between additional fields
      doc.setFontSize(10);
      doc.text(
        15,
        additionalFieldsTop,
        `Client Name: ${invoiceHeaderDetails.clientNameAndCode}`
      );
      doc.text(
        15,
        additionalFieldsTop + additionalFieldsMargin,
        `Invoice No.: ${invoiceHeaderDetails.invoiceRefNo}`
      );
      doc.text(
        15,
        additionalFieldsTop + additionalFieldsMargin * 2,
        `Invoice Date: ${
          invoiceHeaderDetails.invoiceDate.split("-").reverse().join("-") ||
          "N/A"
        }`
      );
      doc.text(
        15,
        additionalFieldsTop + additionalFieldsMargin * 3,
        `Company's Service Accounting Code (SAC):${invoiceHeaderDetails.sacHsn}`
      );
      doc.text(
        15,
        additionalFieldsTop + additionalFieldsMargin * 4,
        "Subject: Facilities-Canteen & Transportation Deductions"
      );

      // Table
      const tableTop = additionalFieldsTop + additionalFieldsMargin * 5 + 10; // Adjust spacing between additional fields and table
      const tableHeaders = [
        "Sr.No",
        "Employee Name",
        "Presenty",
        "Canteen Amount",
        "Transportation Amount",
        "Total Amount",
      ];
      const tableData = invoiceDetailsList
        .filter((employee) => employee.annenuxFlag === true)
        .map((employee, index) => [
          index + 1,
          employee.emplyoyeeName,
          employee.presenty,
          employee.canteenAmt,
          employee.transportationAmt,
          employee.canteenAmt + employee.transportationAmt,
        ]);

      // Calculate available height for the table
      const availableHeight = borderHeight - (tableTop - borderY) - 9; // Subtract spacing for padding
      const rowHeight = 8; // Estimate the height of a row
      const maxRows = Math.floor(availableHeight / rowHeight); // Calculate max rows that fit within the available height
      const newPageTableTop = 30; // Adjust the top margin for new pages

      // Split tableData into chunks that fit within the available height
      let currentY = tableTop;
      for (let i = 0; i < tableData.length; i += maxRows) {
        if (i > 0) {
          doc.addPage();
          doc.setDrawColor(0);
          doc.setLineWidth(0.3);
          // doc.rect(borderX, borderY, borderWidth, borderHeight);
          doc.rect(
            5,
            5,
            doc.internal.pageSize.getWidth() - 10,
            doc.internal.pageSize.getHeight() - 10
          );
          // currentY = tableTop; // Reset the currentY for the new page
          currentY = newPageTableTop; // Adjust the currentY for the new page
        }
        const tableChunk = tableData.slice(i, i + maxRows);
        doc.autoTable({
          head: [tableHeaders],
          body: tableChunk,
          startY: currentY,
          theme: "grid",
          styles: {
            fontSize: 9,
            lineWidth: 0.3,
            lineColor: [0, 0, 0],
            fillColor: [255, 255, 255],
            textColor: [0, 0, 0],
            marginBottom: 0,
          },
          margin: { left: borderX + 5, right: borderX + 5 }, // Adjust margins to fit within the border
          tableWidth: borderWidth - 10, // Adjust table width to fit within the border
          columnStyles: {
            0: { cellWidth: 20 }, // Adjust column width if needed
            1: { cellWidth: 40 }, // Example for adjusting column width
            2: { cellWidth: 30 },
            3: { cellWidth: 30 },
            4: { cellWidth: 30 },
            5: { cellWidth: 30 },
          },
        });
        currentY += tableChunk.length * rowHeight - 10; // Adjust currentY for the next chunk
      }
    }
    if (invoiceHeaderDetails.selectCompany == "GST") {
      doc.save(
        `Gsquare_Employee_Invoice_PerMonth_${clientDetails.clientName}(${invoiceHeaderDetails.invoiceRefNo}).pdf`
      );
    }
    if (invoiceHeaderDetails.selectCompany == "BGD") {
      doc.save(
        `Borgward_Employee_Invoice_PerMonth_${clientDetails.clientName}(${invoiceHeaderDetails.invoiceRefNo}).pdf`
      );
    }
  };

  return (
    <Container maxWidth="xl" onSubmit={handleSubmit}>
      <Card elevation={3} style={{ margin: "40px auto" }}>
        <CardHeader
          title="Employee Invoice Header Details"
          style={{
            backgroundColor: "#2196f3",
            color: "white",
            padding: "10px",
            textAlign: "center",
          }}
        />
        <Paper elevation={3} style={{ padding: "10px", marginBottom: "10px" }}>
          <Paper
            elevation={3}
            style={{ padding: "20px", marginBottom: "20px" }}
          >
            <Paper
              elevation={3}
              style={{ padding: "10px", marginBottom: "10px" }}
            >
              <Typography
                variant="h5"
                gutterBottom
                style={{ marginBottom: "15px", color: "#2196f3" }}
              >
                Employee Invoice Header Details
              </Typography>
            </Paper>
            {loadingOverlay && (
              <div className="loading-overlay">
                <CircularProgress size={60} />
                <div className="loading-message">Creating Invoice...</div>
              </div>
            )}
            {isEmployeePOGet && (
              <div className="loading-overlay">
                <CircularProgress size={60} />
                <div className="loading-message">Getting Employee PO...</div>
              </div>
            )}
            {isBankUIData && (
              <div className="blur-effect">
                <CircularProgress color="primary" />
                <div className="loading-message">Getting Bank Data...</div>
              </div>
            )}
            <Grid container spacing={2}>
              <Grid item xs={3}>
                {/* <Autocomplete
                  // options={[{ label: "GSquare", value: "GST" }]}
                  options={selectCompanys}
                  getOptionLabel={(option) => option}
                  value={invoiceHeaderDetails.selectCompany}
                  onChange={(event, newValue) => {
                    handleInvoiceHeaderDetailsChange("selectCompany")({
                      target: { value: newValue },
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Company"
                      margin="normal"
                      variant="standard"
                      fullWidth
                      required
                    />
                  )}
                /> */}
                <Autocomplete
                  id="company"
                  options={company}
                  value={invoiceHeaderDetails.selectCompany}
                  onChange={(event, newValue) => {
                    handleInvoiceHeaderDetailsChange("selectCompany")({
                      target: { value: newValue },
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Company"
                      variant="standard"
                      margin="normal"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <Autocomplete
                  options={financialYears}
                  getOptionLabel={(option) => option}
                  value={invoiceHeaderDetails.financialYear}
                  onChange={(event, newValue) => {
                    handleInvoiceHeaderDetailsChange("financialYear")({
                      target: { value: newValue },
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Financial Year"
                      margin="normal"
                      variant="standard"
                      required
                    />
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Invoice No"
                  fullWidth
                  value={invoiceHeaderDetails.invoiceNo}
                  onChange={handleInvoiceHeaderDetailsChange("invoiceNo")}
                  variant="standard"
                  margin="normal"
                  // disabled
                  InputProps={{ readOnly: true }}
                  required
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Invoice RefNo"
                  name="invoiceRefNo"
                  value={invoiceHeaderDetails.invoiceRefNo}
                  onChange={handleInvoiceHeaderDetailsChange("invoiceRefNo")}
                  variant="standard"
                  fullWidth
                  margin="normal"
                  required
                  InputProps={{ readOnly: true }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Issue Date"
                  name="issueDate"
                  type="date"
                  value={invoiceHeaderDetails.issueDate}
                  onChange={handleInvoiceHeaderDetailsChange("issueDate")}
                  variant="standard"
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item xs={3}>
                <TextField
                  label="Invoice Date"
                  name="invoiceDate"
                  type="date"
                  value={invoiceHeaderDetails.invoiceDate}
                  onChange={handleInvoiceHeaderDetailsChange("invoiceDate")}
                  variant="standard"
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <Autocomplete
                  options={paymentYears}
                  getOptionLabel={(option) => option}
                  value={invoiceHeaderDetails.paymentYear}
                  onChange={(event, newValue) => {
                    handleInvoiceHeaderDetailsChange("paymentYear")({
                      target: { value: newValue },
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Service Of Year"
                      margin="normal"
                      variant="standard"
                      required
                    />
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <Autocomplete
                  options={paymentMonths}
                  getOptionLabel={(option) => option}
                  value={invoiceHeaderDetails.paymentMonth}
                  onChange={(event, newValue) => {
                    handleInvoiceHeaderDetailsChange("paymentMonth")({
                      target: { value: newValue },
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Service Of Month"
                      margin="normal"
                      variant="standard"
                      required
                    />
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <Autocomplete
                  options={["AutoMobile", "Mechanical"]}
                  getOptionLabel={(option) => option}
                  value={invoiceHeaderDetails.clientType}
                  onChange={(event, newValue) => {
                    handleInvoiceHeaderDetailsChange("clientType")({
                      target: { value: newValue },
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Client Type"
                      margin="normal"
                      variant="standard"
                      required
                    />
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <Autocomplete
                  options={clientList}
                  getOptionLabel={(option) => option}
                  value={invoiceHeaderDetails.clientNameAndCode}
                  onChange={(event, newValue) => {
                    handleInvoiceHeaderDetailsChange("clientNameAndCode")({
                      target: { value: newValue },
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Client Name And Code"
                      margin="normal"
                      variant="standard"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <Autocomplete
                  options={["Debit Note", "Credit Note", "Tax Invoice"]}
                  getOptionLabel={(option) => option}
                  value={invoiceHeaderDetails.invoiceType}
                  onChange={(event, newValue) => {
                    handleInvoiceHeaderDetailsChange("invoiceType")({
                      target: { value: newValue },
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Invoice Type"
                      margin="normal"
                      variant="standard"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="SAC/HSN"
                  fullWidth
                  value={invoiceHeaderDetails.sacHsn}
                  onChange={handleInvoiceHeaderDetailsChange("sacHsn")}
                  variant="standard"
                  //disabled
                  margin="normal"
                  type="number"
                />
              </Grid>

              <Grid item xs={3}>
                <Autocomplete
                  options={["PROFORMA INVOICE", "TAX INVOICE"]}
                  getOptionLabel={(option) => option}
                  value={invoiceHeaderDetails.invoiceTitle}
                  onChange={(event, newValue) => {
                    handleInvoiceHeaderDetailsChange("invoiceTitle")({
                      target: { value: newValue },
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Invoice Title"
                      margin="normal"
                      variant="standard"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <Autocomplete
                  value={invoiceHeaderDetails.invoiceRemarks}
                  onChange={(event, newValue) => {
                    setInvoiceHeaderDetails((prevDetails) => ({
                      ...prevDetails,
                      invoiceRemarks: newValue,
                    }));
                  }}
                  options={invoiceRemarksOptions}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Invoice Remarks"
                      margin="normal"
                      variant="standard"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Emp Rate Unit"
                  fullWidth
                  value={invoiceHeaderDetails.empRateUnit}
                  onChange={handleInvoiceHeaderDetailsChange("empRateUnit")}
                  variant="standard"
                  margin="normal"
                  required
                  disabled
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="DepartMent"
                  fullWidth
                  value={invoiceHeaderDetails.department}
                  onChange={handleInvoiceHeaderDetailsChange("department")}
                  variant="standard"
                  disabled
                  margin="normal"
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Sub DepartMent"
                  fullWidth
                  value={invoiceHeaderDetails.subDepartment}
                  onChange={handleInvoiceHeaderDetailsChange("subDepartment")}
                  variant="standard"
                  disabled
                  margin="normal"
                />
              </Grid>
              {/* <Grid item xs={3}>
                <Autocomplete
                  options={["Yes", "No"]}
                  getOptionLabel={(option) => option}
                  value={invoiceHeaderDetails.annexureFlag ? "Yes" : "No"} // Set the initial value based on the boolean value
                  onChange={(event, newValue) => {
                    const newValueBoolean = newValue === "Yes"; // Convert "Yes" to true and "No" to false
                    handleInvoiceHeaderDetailsChange("annexureFlag")({
                      target: { value: newValueBoolean }, // Set the boolean value in the target object
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Annexure Flag"
                      margin="normal"
                      variant="standard"
                      required
                    />
                  )}
                />
              </Grid> */}
            </Grid>
          </Paper>
        </Paper>

        <Paper elevation={3} style={{ padding: "20px", marginBottom: "20px" }}>
          <Paper
            elevation={3}
            style={{ padding: "20px", marginBottom: "20px" }}
          >
            <Paper
              elevation={3}
              style={{ padding: "10px", marginBottom: "10px" }}
            >
              <Typography
                variant="h5"
                gutterBottom
                style={{ marginBottom: "15px", color: "#2196f3" }}
              >
                Employee Details
              </Typography>
            </Paper>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Autocomplete
                  options={poNumbers}
                  getOptionLabel={(option) => option}
                  value={invoiceHeaderDetails.uniqueNoPoNo}
                  onChange={(event, newValue) => {
                    handleInvoiceHeaderDetailsChange("uniqueNoPoNo")({
                      target: { value: newValue },
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Unique Po Number"
                      margin="dense"
                      variant="standard"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="PO Number"
                  fullWidth
                  value={invoiceHeaderDetails.poNumber}
                  InputProps={{
                    readOnly: true,
                  }}
                  variant="standard"
                  margin="dense"
                  required
                />
              </Grid>
              {/* {invoiceEmployeePoDetailsUIList.invoiceEmployeePoDetailsUIList
                .length > 0 && (
                <Grid item xs={4}>
                  <TextField
                    label="Emp Rate Unit"
                    fullWidth
                    value={
                      invoiceEmployeePoDetailsUIList
                        .invoiceEmployeePoDetailsUIList[0].empRateUnit || ""
                    }
                    onChange={handleInvoiceHeaderDetailsChange(
                      0,
                      "empRateUnit"
                    )}
                    variant="standard"
                  />
                </Grid>
              )} */}
              <Grid item xs={4}>
                <TextField
                  label="Working Days"
                  fullWidth
                  value={invoiceHeaderDetails.workingDays}
                  onChange={(event) => {
                    handleWorkingDaysChange(event);
                    handleInvoiceHeaderDetailsChange("workingDays")(event);
                  }}
                  variant="standard"
                  margin="dense"
                  type="number"
                />
              </Grid>
            </Grid>
          </Paper>
        </Paper>
        <Paper elevation={3} style={{ padding: "20px", marginBottom: "20px" }}>
          <Paper
            elevation={3}
            style={{ padding: "20px", marginBottom: "20px" }}
          >
            <Paper
              elevation={3}
              style={{ padding: "10px", marginBottom: "10px" }}
            >
              <Typography
                variant="h5"
                gutterBottom
                style={{ marginBottom: "15px", color: "#2196f3" }}
              >
                Employee Calculation Details
              </Typography>
            </Paper>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow
                    style={{
                      backgroundColor: "darkcyan",
                      fontWeight: "bold",
                      color: "darkblue",
                    }}
                  >
                    <TableCell style={{ width: "30px" }}>Sr. No.</TableCell>
                    <TableCell style={{ width: "30px" }}>Action</TableCell>
                    <TableCell style={{ width: "150px" }}>Presenty</TableCell>
                    <TableCell style={{ width: "250px" }}>
                      Presenty Amount
                    </TableCell>

                    <TableCell style={{ width: "150px" }}>From Date</TableCell>
                    <TableCell style={{ width: "150px" }}>To Date</TableCell>
                    <TableCell style={{ width: "150px" }}>
                      Current Po No
                    </TableCell>
                    <TableCell style={{ width: "150px" }}>
                      Employee Name
                    </TableCell>
                    <TableCell style={{ width: "150px" }}>
                      Employee Code
                    </TableCell>

                    <TableCell style={{ width: "150px" }}>
                      Current PO Rate
                    </TableCell>
                    <TableCell style={{ width: "150px" }}>
                      Canteen Amount
                    </TableCell>
                    <TableCell style={{ width: "150px" }}>
                      Transportation Amount
                    </TableCell>
                    <TableCell style={{ width: "150px" }}>
                      Annexure Flag
                    </TableCell>
                    <TableCell style={{ width: "150px" }}>
                      Active Status
                    </TableCell>
                    <TableCell style={{ width: "150px" }}>
                      Release Date
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {invoiceEmployeePoDetailsUIList.invoiceEmployeePoDetailsUIList
                    .length === 0 ? (
                    <TableRow>
                      <TableCell
                        style={{ width: "150px" }}
                        colSpan={20}
                        align="center"
                      >
                        No Employee PO Record found
                      </TableCell>
                    </TableRow>
                  ) : (
                    invoiceEmployeePoDetailsUIList.invoiceEmployeePoDetailsUIList.map(
                      (project, index) => (
                        <TableRow key={index}>
                          <TableCell style={{ width: "30px" }}>
                            {index + 1}
                          </TableCell>
                          <TableCell style={{ width: "30px" }}>
                            <Button
                              variant="contained"
                              color="secondary"
                              onClick={() => handleDeleteRow(index)}
                            >
                              X
                            </Button>
                          </TableCell>
                          <TableCell style={{ width: "150px" }}>
                            <TextField
                              fullWidth
                              value={project.presenty}
                              onChange={handleInvoiceEmployeeDetailsChange(
                                index,
                                "presenty"
                              )}
                            />
                          </TableCell>
                          <TableCell style={{ width: "150px" }}>
                            <TextField
                              fullWidth
                              value={project.presentyAmt}
                              onChange={handleInvoiceEmployeeDetailsChange(
                                index,
                                "presentyAmt"
                              )}
                            />
                          </TableCell>

                          <TableCell style={{ width: "100px" }}>
                            <TextField
                              type="date"
                              fullWidth
                              value={project.fromDate}
                              onChange={handleInvoiceEmployeeDetailsChange(
                                index,
                                "fromDate"
                              )}
                            />
                          </TableCell>
                          <TableCell style={{ width: "100px" }}>
                            <TextField
                              type="date"
                              fullWidth
                              value={project.toDate}
                              onChange={handleInvoiceEmployeeDetailsChange(
                                index,
                                "toDate"
                              )}
                            />
                          </TableCell>
                          <TableCell style={{ width: "150px" }}>
                            {project.currentPoNo}
                          </TableCell>

                          <TableCell style={{ width: "150px" }}>
                            {project.emplyoyeeName}
                          </TableCell>
                          <TableCell style={{ width: "150px" }}>
                            {project.employeeCode}
                          </TableCell>

                          <TableCell style={{ width: "150px" }}>
                            {project.currentPoRate}
                          </TableCell>
                          <TableCell style={{ width: "150px" }}>
                            {project.canteenAmt}
                          </TableCell>
                          <TableCell style={{ width: "150px" }}>
                            {project.transportationAmt}
                          </TableCell>
                          {/* <TableCell style={{ width: "150px" }}>
                            <Autocomplete
                              options={["Yes", "No"]}
                              getOptionLabel={(option) => option}
                              value={project.annenuxFlag ? "Yes" : "No"}
                              onChange={(event, newValue) => {
                                const newValueBoolean = newValue === "Yes";
                                const updatedList =
                                  invoiceEmployeePoDetailsUIList.invoiceEmployeePoDetailsUIList.map(
                                    (item, itemIndex) => {
                                      if (itemIndex === index) {
                                        return {
                                          ...item,
                                          annenuxFlag: newValueBoolean,
                                        };
                                      }
                                      return item;
                                    }
                                  );
                                setInvoiceEmployeePoDetailsUIList(
                                  (prevState) => ({
                                    ...prevState,
                                    invoiceEmployeePoDetailsUIList: updatedList,
                                  })
                                );
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Annenux Flag"
                                  margin="normal"
                                  variant="standard"
                                  required
                                />
                              )}
                            />
                          </TableCell> */}
                          {/* <TableCell style={{ width: "150px" }}>
                            <FormControl component="fieldset">
                              <FormLabel component="legend">
                                Annexure Flag
                              </FormLabel>
                              <RadioGroup
                                row
                                value={project.annenuxFlag ? "Yes" : "No"}
                                onChange={(event) => {
                                  const newValueBoolean =
                                    event.target.value === "Yes";
                                  const updatedList =
                                    invoiceEmployeePoDetailsUIList.invoiceEmployeePoDetailsUIList.map(
                                      (item, itemIndex) => {
                                        if (itemIndex === index) {
                                          return {
                                            ...item,
                                            annenuxFlag: newValueBoolean,
                                          };
                                        }
                                        return item;
                                      }
                                    );
                                  setInvoiceEmployeePoDetailsUIList(
                                    (prevState) => ({
                                      ...prevState,
                                      invoiceEmployeePoDetailsUIList:
                                        updatedList,
                                    })
                                  );
                                }}
                              >
                                <FormControlLabel
                                  value="Yes"
                                  control={<Radio />}
                                  label="Yes"
                                />
                                <FormControlLabel
                                  value="No"
                                  control={<Radio />}
                                  label="No"
                                />
                              </RadioGroup>
                            </FormControl>
                          </TableCell> */}
                          <TableCell style={{ width: "150px" }}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={project.annenuxFlag}
                                  onChange={(event) => {
                                    const newValueBoolean =
                                      event.target.checked;
                                    const updatedList =
                                      invoiceEmployeePoDetailsUIList.invoiceEmployeePoDetailsUIList.map(
                                        (item, itemIndex) => {
                                          if (itemIndex === index) {
                                            return {
                                              ...item,
                                              annenuxFlag: newValueBoolean,
                                            };
                                          }
                                          return item;
                                        }
                                      );
                                    setInvoiceEmployeePoDetailsUIList(
                                      (prevState) => ({
                                        ...prevState,
                                        invoiceEmployeePoDetailsUIList:
                                          updatedList,
                                      })
                                    );
                                  }}
                                />
                              }
                              label="Yes"
                            />
                          </TableCell>
                          <TableCell style={{ width: "150px" }}>
                            {project.activeStatus}
                          </TableCell>
                          <TableCell style={{ width: "150px" }}>
                            {project.releaseDate}
                          </TableCell>
                        </TableRow>
                      )
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Paper>
        <Paper elevation={3} style={{ padding: "20px", marginBottom: "20px" }}>
          <Paper
            elevation={3}
            style={{ padding: "20px", marginBottom: "20px" }}
          >
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Paper
                  elevation={3}
                  style={{ padding: "20px", marginBottom: "20px" }}
                >
                  <TextField
                    label="Total Presenty Amount"
                    sx={{ marginBottom: 2, marginTop: 2 }}
                    variant="standard"
                    value={invoiceCalculation.totalPresentyAmount}
                    onChange={(e) =>
                      handleInvoiceCalculationChange(
                        "totalPresentyAmount",
                        e.target.value
                      )
                    }
                    fullWidth
                  />

                  <TextField
                    label="Total Tax Amount"
                    sx={{ marginBottom: 2, marginTop: 2 }}
                    variant="standard"
                    size="small"
                    value={invoiceCalculation.totalTaxAmt}
                    fullWidth
                  />
                  <TextField
                    label="Grand Total Amount"
                    sx={{ marginBottom: 2, marginTop: 2 }}
                    variant="standard"
                    size="small"
                    value={invoiceCalculation.grandTotalAmount}
                    fullWidth
                    style={{ color: "black" }}
                  />
                  <TextField
                    label=" Deduction Amount"
                    sx={{ marginBottom: 2, marginTop: 2 }}
                    variant="standard"
                    size="small"
                    value={invoiceCalculation.deductionAmount}
                    fullWidth
                  />
                </Paper>
              </Grid>
              <Grid item xs={3}>
                <Paper
                  elevation={3}
                  style={{ padding: "20px", marginBottom: "20px" }}
                >
                  <TextField
                    label="Deduction Particular"
                    sx={{ marginBottom: 2, marginTop: 2 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="standard"
                    size="small"
                    multiline
                    rows={4}
                    value={invoiceCalculation.deductionParticular}
                    onChange={(e) =>
                      handleInvoiceCalculationChange(
                        "deductionParticular",
                        e.target.value
                      )
                    }
                    fullWidth
                    style={{ fontSize: "16px" }}
                  />
                </Paper>
              </Grid>
              <Grid item xs={3}>
                <Paper
                  elevation={3}
                  style={{ padding: "20px", marginBottom: "20px" }}
                >
                  <TextField
                    label="CGST"
                    sx={{ marginBottom: 2, marginTop: 2 }}
                    variant="standard"
                    size="small"
                    value={invoiceCalculation.cgst}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    onChange={(e) =>
                      handleInvoiceCalculationChange("cgst", e.target.value)
                    }
                  />
                  <TextField
                    label="SGST"
                    sx={{ marginBottom: 2, marginTop: 2 }}
                    variant="standard"
                    size="small"
                    value={invoiceCalculation.sgst}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    onChange={(e) =>
                      handleInvoiceCalculationChange("sgst", e.target.value)
                    }
                  />
                  <TextField
                    label="IGST"
                    sx={{ marginBottom: 2, marginTop: 2 }}
                    variant="standard"
                    size="small"
                    value={invoiceCalculation.igst}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    onChange={(e) =>
                      handleInvoiceCalculationChange("igst", e.target.value)
                    }
                  />
                  <TextField
                    label="IGST Amount"
                    sx={{ marginBottom: 2, marginTop: 2 }}
                    variant="standard"
                    size="small"
                    value={invoiceCalculation.igstAmount}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    disabled
                    onChange={(e) =>
                      handleInvoiceCalculationChange(
                        "igstAmount",
                        e.target.value
                      )
                    }
                  />
                  <TextField
                    label="CGST Amount"
                    sx={{ marginBottom: 2, marginTop: 2 }}
                    variant="standard"
                    size="small"
                    value={invoiceCalculation.cgstAmount}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    disabled
                    onChange={(e) =>
                      handleInvoiceCalculationChange(
                        "cgstAmount",
                        e.target.value
                      )
                    }
                  />
                  <TextField
                    label="SGST Amount"
                    sx={{ marginBottom: 2, marginTop: 2 }}
                    variant="standard"
                    size="small"
                    value={invoiceCalculation.sgstAmount}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    disabled
                    onChange={(e) =>
                      handleInvoiceCalculationChange(
                        "sgstAmount",
                        e.target.value
                      )
                    }
                  />
                  <TextField
                    label="Total Tax Amount"
                    sx={{ marginBottom: 2, marginTop: 2 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="standard"
                    size="small"
                    value={invoiceCalculation.totalTaxAmt}
                    fullWidth
                  />
                  <TextField
                    label="Total in Words"
                    sx={{ marginBottom: 2, marginTop: 2 }}
                    multiline
                    rows={4}
                    variant="standard"
                    size="small"
                    value={invoiceCalculation.totalInWord}
                    fullWidth
                    disabled
                  />
                </Paper>
              </Grid>

              <Grid item xs={3}>
                <Paper
                  elevation={3}
                  style={{ padding: "20px", marginBottom: "20px" }}
                >
                  <Button variant="contained" onClick={handleCalculate}>
                    Calculate
                  </Button>
                </Paper>
              </Grid>
            </Grid>
          </Paper>
        </Paper>
        <Paper elevation={3} style={{ padding: "20px", marginBottom: "20px" }}>
          <Paper
            elevation={3}
            style={{ padding: "20px", marginBottom: "20px" }}
          >
            <Paper
              elevation={3}
              style={{ padding: "10px", marginBottom: "10px" }}
            >
              <Typography
                variant="h5"
                gutterBottom
                style={{ marginBottom: "15px", color: "#2196f3" }}
              >
                Payment Mode Details
              </Typography>
            </Paper>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Autocomplete
                  fullWidth
                  margin="normal"
                  options={["15 Days Bank Transfer"]} // Add other options as needed
                  value={paymentMode.ModeTermsOfPayment}
                  onChange={(event, newValue) =>
                    handlePaymentModeChange("ModeTermsOfPayment")({
                      target: { value: newValue },
                    })
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Mode Terms Of Payment"
                      variant="standard"
                      margin="normal"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Kind Attention"
                  fullWidth
                  value={paymentMode.KindAttention}
                  onChange={handlePaymentModeChange("KindAttention")}
                  variant="standard"
                  margin="normal"
                />
              </Grid>
              <Grid item xs={4}>
                <Autocomplete
                  options={bankAccNoAndIfscCodes}
                  getOptionLabel={(option) => option}
                  value={paymentMode.SelectBankAccNo}
                  onChange={(event, newValue) => {
                    handlePaymentModeChange("SelectBankAccNo")({
                      target: { value: newValue },
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Bank Acc No"
                      margin="normal"
                      variant="standard"
                      required
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Paper>
        </Paper>
        <Paper
          style={{ display: "flex", justifyContent: "center", padding: "10px" }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            style={{ marginLeft: "20px", marginBottom: "20px" }}
            disabled={isSubmitDisabled || !isPdfButtonDisabled} // Disable the submit button based on state
          >
            Submit
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handlegeneratePDF}
            style={{ marginLeft: "20px", marginBottom: "20px" }}
            disabled={isPdfButtonDisabled} // Conditionally disable based on state
          >
            PDF
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleCancel}
            style={{ marginLeft: "20px", marginBottom: "20px" }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={handleReset}
            style={{
              marginLeft: "20px",
              marginBottom: "20px",
              backgroundColor: "red",
              color: "white",
            }}
          >
            Reset
          </Button>
        </Paper>
      </Card>
    </Container>
  );
};

export default GenerateEmployeeInvoice;
