import Headers from "../../../Pages/Header";
import ClientAgreementNotification from "../ClientAgreementNotification";

const ClientAgreeMentNotiPage = () => {
  return (
    <Headers >
      <ClientAgreementNotification />
    </Headers>
  );
};
export default ClientAgreeMentNotiPage;