// import React, { useState, useEffect } from "react";
// import {
//   TextField,
//   Button,
//   Container,
//   Select,
//   MenuItem,
//   Paper,
//   Grid,
//   Card,
//   CardHeader,
//   FormControl,
//   InputLabel,
//   Typography,
//   CircularProgress,
// } from "@material-ui/core";
// import axios from "axios";
// import { useNavigate } from "react-router-dom";
// import { myAxiosCommonHost } from "../../Service/CorsHelperBaseUrl";
// import { Autocomplete } from "@mui/material";

// const RegisterClientAgreement = () => {
//   const navigate = useNavigate();
//   const [formSubmitted, setFormSubmitted] = useState(false);
//   const [loading, setLoading] = useState(true);
//   const [financialYear, setFinancialYear] = useState([]);
//   const [agreementDetails, setAgreementDetails] = useState({
//     selectCompany: "",
//     financialYear: "",
//     agreementCode: "",
//     agreementUniqueCode: "",
//     clientName: "",
//     clientCode: "",
//     department: "",
//     agreementType: "",
//     agreementDate: "",
//     duration: "",
//     startDate: "",
//     endDate: "",
//     agreementCopy: "",
//     agreementDraft: "",
//   });

//   const [loadingOverlay, setLoadingOverlay] = useState(false);
//   const [company, setCompany] = useState([]);
//   const [formData, setFormData] = useState({ ...agreementDetails });
//   const [agreementCopyFile, setAgreementCopyFile] = useState(null);
//   const [agreementDraftFile, setAgreementDraftFile] = useState(null);
//   const [agreementCopyView, setAgreementCopyView] = useState(false);
//   const [agreementDraftView, setAgreementDraftView] = useState(false);

//   useEffect(() => {
//     async function fetchCompany() {
//       try {
//         const response = await myAxiosCommonHost.get(
//           "gst/profile/getCompanyCodeList"
//         );
//         const companies = response.data;
//         setCompany(companies);
//         setFormData((prevState) => ({
//           ...prevState,
//         }));
//       } catch (error) {
//         console.error("Error fetching company data:", error);
//       }
//     }
//     fetchCompany();
//   }, []);

//   const getCurrentFinancialYear = () => {
//     const currentDate = new Date();
//     const currentYear = currentDate.getFullYear();
//     const fiscalStartYear = currentYear - (currentDate.getMonth() >= 3 ? 0 : 1);
//     const fiscalEndYear = fiscalStartYear + 1;
//     return `${(fiscalStartYear % 100).toString().padStart(2, "0")}-${(
//       fiscalEndYear % 100
//     )
//       .toString()
//       .padStart(2, "0")}`;
//   };

//   const defaultFinancialYear = getCurrentFinancialYear();
//   const [selectedFinancialYear, setSelectedFinancialYear] =
//     useState(defaultFinancialYear);

//   async function fetchFinancialYear() {
//     try {
//       const response = await myAxiosCommonHost.get("gst/invoice/getFiscalYear");
//       setFinancialYear(response.data);
//     } catch (error) {
//       console.error("Error fetching financial year data:", error);
//     }
//   }

//   useEffect(() => {
//     fetchFinancialYear();
//     handleFinancialYearSelect(null, defaultFinancialYear);
//   }, []);

//   const fetchUniqueAgreementCode = async (financialYear, selectCompany) => {
//     console.log(
//       "Fetching unique agreement code for:",
//       financialYear,
//       selectCompany
//     );
//     try {
//       const response = await myAxiosCommonHost.get(
//         `gst/clientAgreement/getUniqueAgreementCode?financialYear=${financialYear}&selectCompany=${selectCompany}`
//       );
//       let agreementResponseNo = "";
//       const msgArr = response.data.split(",");
//       console.log(msgArr);
//       if (response.data.includes("Please")) {
//         window.alert(response.data);
//         agreementResponseNo = msgArr[1];
//       } else {
//         agreementResponseNo = response.data;
//       }
//       setAgreementDetails((prevDetails) => ({
//         ...prevDetails,
//         agreementUniqueCode: agreementResponseNo,
//       }));
//     } catch (error) {
//       console.error("Error fetching unique agreement code:", error);
//     }
//   };

//   const handleFinancialYearSelect = (event, newValue) => {
//     setSelectedFinancialYear(newValue);
//     setAgreementDetails((prevDetails) => ({
//       ...prevDetails,
//       financialYear: newValue,
//     }));
//     if (agreementDetails.selectCompany) {
//       fetchUniqueAgreementCode(newValue, agreementDetails.selectCompany);
//     }
//   };

//   const handleAgreementDetailsChange = (e, name, value) => {
//     if (e && e.target) {
//       const { name, value, files } = e.target;
//       if (name === "agreementCopy") {
//         setAgreementCopyFile(files[0]);
//       } else if (name === "agreementDraft") {
//         setAgreementDraftFile(files[0]);
//       } else {
//         setAgreementDetails((prevDetails) => ({
//           ...prevDetails,
//           [name]: value,
//         }));
//         if (name === "selectCompany" && selectedFinancialYear) {
//           fetchUniqueAgreementCode(selectedFinancialYear, value);
//         }
//       }
//     } else {
//       setAgreementDetails((prevDetails) => ({
//         ...prevDetails,
//         [name]: value,
//       }));
//       if (name === "selectCompany" && selectedFinancialYear) {
//         fetchUniqueAgreementCode(selectedFinancialYear, value);
//       }
//     }
//   };

//   const handleCancel = () => {
//     const userConfirmed = window.confirm(
//       "Are you sure you want to cancel? Any unsaved changes will be lost."
//     );
//     if (userConfirmed) {
//       navigate("/updateClientAgreement");
//     }
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setLoadingOverlay(true);
//     setFormSubmitted(true);
//     try {
//       const postData = { ...agreementDetails };
//       console.log(JSON.stringify(postData, null, 2));

//       await myAxiosCommonHost.post(
//         `gst/clientAgreement/createClientAgreement?agreementCopy=${agreementDetails.agreementCopy}&agreementDraft=${agreementDetails.agreementDraft}`,
//         postData
//       );

//       console.log(
//         "Posted data in JSON format:",
//         JSON.stringify(postData, null, 2)
//       );

//       // Show alert on successful post
//       alert("Data posted successfully!");

//       // Reset all fields after user clicks "OK" on the alert
//       setAgreementDetails({
//         selectCompany: "",
//         financialYear: "",
//         agreementCode: "",
//         agreementUniqueCode: "",
//         clientName: "",
//         clientCode: "",
//         department: "",
//         agreementType: "",
//         agreementDate: "",
//         duration: "",
//         startDate: "",
//         endDate: "",
//         agreementCopy: "",
//         agreementDraft: "",
//       });

//       // Navigate to updateAgreement component
//       navigate("/updateClientAgreement");

//       console.log(
//         "Data posted successfully, fields reset, and navigated to AgreementList!"
//       );
//     } catch (error) {
//       console.error("Error posting data:", error);
//     } finally {
//       setLoadingOverlay(false);
//     }
//   };
//   const handleViewAgreementCopy = () => {
//     // Implement logic to view agreementCopyFile
//     console.log("View Agreement Copy:", agreementCopyFile);
//   };

//   const handleViewAgreementDraft = () => {
//     // Implement logic to view agreementDraftFile
//     console.log("View Agreement Draft:", agreementDraftFile);
//   };

//   const handleDeleteAgreementCopy = () => {
//     setAgreementCopyFile(null);
//     setAgreementCopyView(false);
//   };

//   const handleDeleteAgreementDraft = () => {
//     setAgreementDraftFile(null);
//     setAgreementDraftView(false);
//   };

//   return (
//     <Container maxWidth="lg">
//       <form onSubmit={handleSubmit}>
//         <Card elevation={3} style={{ marginBottom: "20px", marginTop: "20px" }}>
//           <CardHeader
//             title="Client Agreement Registration"
//             style={{
//               backgroundColor: "#2196f3",
//               color: "white",
//               padding: "10px",
//               textAlign: "center",
//             }}
//           />
//           <Paper
//             elevation={3}
//             style={{ padding: "20px", marginBottom: "20px" }}
//           >
//             <Paper
//               elevation={3}
//               style={{ padding: "20px", marginBottom: "20px" }}
//             >
//               <Paper
//                 elevation={3}
//                 style={{
//                   padding: "10px",
//                   marginBottom: "30px",
//                   backgroundColor: "darkgray",
//                 }}
//               >
//                 <Typography
//                   variant="h5"
//                   gutterBottom
//                   style={{
//                     marginBottom: "15px",
//                     color: "darkblue",
//                     textAlign: "center",
//                   }}
//                 >
//                   Agreement Details
//                 </Typography>
//               </Paper>
//               {loadingOverlay && (
//                 <div className="loading-overlay">
//                   <CircularProgress size={60} />
//                   <div className="loading-message">Saving Agreement...</div>
//                 </div>
//               )}

//               <Grid container spacing={2}>
//                 <Grid item xs={12} md={6}>
//                   <Autocomplete
//                     id="company"
//                     options={company}
//                     value={agreementDetails.selectCompany}
//                     onChange={(e, newValue) =>
//                       handleAgreementDetailsChange(
//                         null,
//                         "selectCompany",
//                         newValue
//                       )
//                     }
//                     renderInput={(params) => (
//                       <TextField
//                         {...params}
//                         label="Select Company"
//                         variant="standard"
//                         margin="dense"
//                       />
//                     )}
//                   />
//                 </Grid>
//                 <Grid item xs={12} md={6}>
//                   <Autocomplete
//                     id="financial-year"
//                     options={financialYear}
//                     loading={loading}
//                     value={selectedFinancialYear}
//                     onChange={handleFinancialYearSelect}
//                     renderInput={(params) => (
//                       <TextField
//                         {...params}
//                         label="Financial Year"
//                         variant="standard"
//                         margin="dense"
//                         required
//                       />
//                     )}
//                   />
//                 </Grid>
//                 <Grid item xs={12} md={6}>
//                   <TextField
//                     label="Unique Agreement Code"
//                     name="agreementUniqueCode"
//                     value={agreementDetails.agreementUniqueCode}
//                     disabled
//                     fullWidth
//                     margin="dense"
//                     variant="standard"
//                     required
//                   />
//                 </Grid>
//                 <Grid item xs={12} md={6}>
//                   <TextField
//                     label="Agreement Code"
//                     name="agreementCode"
//                     value={agreementDetails.agreementCode}
//                     onChange={handleAgreementDetailsChange}
//                     fullWidth
//                     margin="dense"
//                     variant="standard"
//                     required
//                   />
//                 </Grid>
//                 <Grid item xs={12} md={6}>
//                   <TextField
//                     label="Client Name"
//                     name="clientName"
//                     value={agreementDetails.clientName}
//                     onChange={handleAgreementDetailsChange}
//                     fullWidth
//                     margin="dense"
//                     variant="standard"
//                     required
//                   />
//                 </Grid>
//                 <Grid item xs={12} md={6}>
//                   <TextField
//                     label="Client Code"
//                     name="clientCode"
//                     value={agreementDetails.clientCode}
//                     onChange={handleAgreementDetailsChange}
//                     fullWidth
//                     margin="dense"
//                     variant="standard"
//                     required
//                   />
//                 </Grid>
//                 <Grid item xs={12} md={6}>
//                   <TextField
//                     label="Department"
//                     name="department"
//                     value={agreementDetails.department}
//                     onChange={handleAgreementDetailsChange}
//                     fullWidth
//                     margin="dense"
//                     variant="standard"
//                     required
//                   />
//                 </Grid>
//                 <Grid item xs={12} md={6}>
//                   <TextField
//                     label="Agreement Type"
//                     name="agreementType"
//                     value={agreementDetails.agreementType}
//                     onChange={handleAgreementDetailsChange}
//                     fullWidth
//                     margin="dense"
//                     variant="standard"
//                     required
//                   />
//                 </Grid>
//                 <Grid item xs={12} md={6}>
//                   <TextField
//                     label="Agreement Date"
//                     name="agreementDate"
//                     type="date"
//                     value={agreementDetails.agreementDate}
//                     onChange={handleAgreementDetailsChange}
//                     fullWidth
//                     margin="dense"
//                     variant="standard"
//                     InputLabelProps={{
//                       shrink: true,
//                     }}
//                     required
//                   />
//                 </Grid>
//                 <Grid item xs={12} md={6}>
//                   <TextField
//                     label="Duration"
//                     name="duration"
//                     value={agreementDetails.duration}
//                     onChange={handleAgreementDetailsChange}
//                     fullWidth
//                     margin="dense"
//                     variant="standard"
//                     required
//                   />
//                 </Grid>
//                 <Grid item xs={12} md={6}>
//                   <TextField
//                     label="Start Date"
//                     name="startDate"
//                     type="date"
//                     value={agreementDetails.startDate}
//                     onChange={handleAgreementDetailsChange}
//                     fullWidth
//                     margin="dense"
//                     variant="standard"
//                     InputLabelProps={{
//                       shrink: true,
//                     }}
//                     required
//                   />
//                 </Grid>
//                 <Grid item xs={12} md={6}>
//                   <TextField
//                     label="End Date"
//                     name="endDate"
//                     type="date"
//                     value={agreementDetails.endDate}
//                     onChange={handleAgreementDetailsChange}
//                     fullWidth
//                     margin="dense"
//                     variant="standard"
//                     InputLabelProps={{
//                       shrink: true,
//                     }}
//                     required
//                   />
//                 </Grid>
//                 <Grid item xs={12} md={6}>
//                   <TextField
//                     label="Agreement Copy"
//                     type="file"
//                     accept=".doc,.docx"
//                     name="agreementCopy"
//                     InputLabelProps={{
//                       shrink: true,
//                     }}
//                     onChange={handleAgreementDetailsChange}
//                   />
//                   {agreementCopyFile && (
//                     <Button onClick={() => setAgreementCopyView(true)}>
//                       View
//                     </Button>
//                   )}
//                   {agreementCopyFile && (
//                     <Button onClick={handleDeleteAgreementCopy}>
//                       Delete Agreement Copy
//                     </Button>
//                   )}
//                 </Grid>
//                 <Grid item xs={12} md={6}>
//                   <TextField
//                     label="Agreement Draft"
//                     type="file"
//                     accept=".pdf"
//                     name="agreementDraft"
//                     InputLabelProps={{
//                       shrink: true,
//                     }}
//                     onChange={handleAgreementDetailsChange}
//                   />
//                   {agreementDraftFile && (
//                     <Button onClick={() => setAgreementDraftView(true)}>
//                       View
//                     </Button>
//                   )}
//                   {agreementDraftFile && (
//                     <Button onClick={handleDeleteAgreementDraft}>
//                       Delete Agreement Draft
//                     </Button>
//                   )}
//                 </Grid>
//               </Grid>
//               <Grid container spacing={2} style={{ marginTop: "20px" }}>
//                 <Grid item xs={12} md={6}>
//                   <Button
//                     type="submit"
//                     variant="contained"
//                     color="primary"
//                     fullWidth
//                   >
//                     Submit
//                   </Button>
//                 </Grid>
//                 <Grid item xs={12} md={6}>
//                   <Button
//                     type="button"
//                     variant="contained"
//                     color="secondary"
//                     fullWidth
//                     onClick={handleCancel}
//                   >
//                     Cancel
//                   </Button>
//                 </Grid>
//               </Grid>
//             </Paper>
//           </Paper>
//         </Card>
//       </form>
//     </Container>
//   );
// };

// export default RegisterClientAgreement;
import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Container,
  Card,
  CardHeader,
  Paper,
  Typography,
  Grid,
  TextField,
  Button,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { Autocomplete } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { myAxiosCommonHost } from "../../Service/CorsHelperBaseUrl";

const RegisterClientAgreement = () => {
  const navigate = useNavigate();
  const [agreementUI, setAgreementUI] = useState({
    selectCompany: "",
    financialYear: "",
    agreementCode: "",
    agreementUniqueCode: "",
    clientName: "",
    clientCode: "",
    department: "",
    agreementType: "",
    agreementDate: "",
    duration: "",
    startDate: "",
    endDate: "",
  });

  const [agreementDraft, setAgreementDraft] = useState([]);
  const [agreementCopy, setAgreementCopy] = useState([]);
  const [company, setCompany] = useState([]);
  const [financialYear, setFinancialYear] = useState([]);
  const [loadingOverlay, setLoadingOverlay] = useState(false);
  const [loading, setLoading] = useState(true);
  const [formSubmitted, setFormSubmitted] = useState(false);

  useEffect(() => {
    async function fetchCompany() {
      try {
        const response = await myAxiosCommonHost.get(
          "gst/profile/getCompanyCodeList"
        );
        const companies = response.data;
        setCompany(companies);
        setAgreementUI((prevState) => ({
          ...prevState,
        }));
      } catch (error) {
        console.error("Error fetching company data:", error);
      }
    }
    fetchCompany();
  }, []);
  const getCurrentFinancialYear = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const fiscalStartYear = currentYear - (currentDate.getMonth() >= 3 ? 0 : 1);
    const fiscalEndYear = fiscalStartYear + 1;
    return `${(fiscalStartYear % 100).toString().padStart(2, "0")}-${(
      fiscalEndYear % 100
    )
      .toString()
      .padStart(2, "0")}`;
  };

  const defaultFinancialYear = getCurrentFinancialYear();
  const [selectedFinancialYear, setSelectedFinancialYear] =
    useState(defaultFinancialYear);

  async function fetchFinancialYear() {
    try {
      const response = await myAxiosCommonHost.get("gst/invoice/getFiscalYear");
      setFinancialYear(response.data);
    } catch (error) {
      console.error("Error fetching financial year data:", error);
    }
  }

  useEffect(() => {
    fetchFinancialYear();
    handleFinancialYearSelect(null, defaultFinancialYear);
  }, []);

  useEffect(() => {
    const fetchUniqueAgreementCode = async () => {
      try {
        const response = await myAxiosCommonHost.get(
          `gst/clientAgreement/getUniqueAgreementCode?financialYear=${agreementUI.financialYear}&selectCompany=${agreementUI.selectCompany}`
        );
        let agreementResponseNo = "";
        const msgArr = response.data.split(",");
        console.log(msgArr);
        if (response.data.includes("Please")) {
          window.alert(response.data);
          agreementResponseNo = msgArr[1];
        } else {
          agreementResponseNo = response.data;
        }
        setAgreementUI((prevDetails) => ({
          ...prevDetails,
          agreementCode: "",
          agreementUniqueCode: agreementResponseNo,
          clientName: "",
          clientCode: "",
          department: "",
          agreementType: "",
          agreementDate: "",
          duration: "",
          startDate: "",
          endDate: "",
        }));
        setAgreementDraft([]);
        setAgreementCopy([]);
      } catch (error) {
        console.error("Error fetching unique agreement code:", error);
      }
    };
    fetchUniqueAgreementCode();
  }, [agreementUI.selectCompany, agreementUI.financialYear]);

  const handleFinancialYearSelect = (event, newValue) => {
    setSelectedFinancialYear(newValue);
    setAgreementUI((prevDetails) => ({
      ...prevDetails,
      financialYear: newValue,
    }));
  };

  // const handleFileChange = (e, setFileState) => {
  //   setFileState(e.target.files);
  // };
  const handleFileChange = (e, setFileState) => {
    setFileState([...e.target.files]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoadingOverlay(true);
    setFormSubmitted(true);
    const formData = new FormData();
    formData.append(
      "agreementUI",
      new Blob([JSON.stringify(agreementUI)], {
        type: "application/json",
      })
    );
    Array.from(agreementDraft).forEach((file) =>
      formData.append("agreementDraft", file)
    );
    Array.from(agreementCopy).forEach((file) =>
      formData.append("agreementCopy", file)
    );

    try {
      const response = await myAxiosCommonHost.post(
        "gst/clientAgreement/createClientAgreement",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(
        "Posted data in JSON format:",
        JSON.stringify(response.data, null, 2)
      );
      alert("Data posted successfully!");
      setAgreementUI({
        selectCompany: "",
        financialYear: "",
        agreementCode: "",
        agreementUniqueCode: "",
        clientName: "",
        clientCode: "",
        department: "",
        agreementType: "",
        agreementDate: "",
        duration: "",
        startDate: "",
        endDate: "",
      });
      navigate("/updateClientAgreement");
      console.log(response.data);
    } catch (error) {
      console.error("Error uploading data", error);
    } finally {
      setLoadingOverlay(false);
    }
  };
  const handleCancel = () => {
    const userConfirmed = window.confirm(
      "Are you sure you want to cancel? Any unsaved changes will be lost."
    );
    if (userConfirmed) {
      navigate("/updateClientAgreement");
    }
  };
  const handleAutocompleteChange = (event, newValue, fieldName) => {
    setAgreementUI((prevDetails) => ({
      ...prevDetails,
      [fieldName]: newValue,
    }));
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setAgreementUI((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };
  const removeFile = (index, fileType) => {
    if (fileType === "draft") {
      setAgreementDraft((prevFiles) =>
        prevFiles.filter((_, fileIndex) => fileIndex !== index)
      );
    } else if (fileType === "copy") {
      setAgreementCopy((prevFiles) =>
        prevFiles.filter((_, fileIndex) => fileIndex !== index)
      );
    }
  };
  return (
    <Container maxWidth="lg">
      <form onSubmit={handleSubmit}>
        <Card elevation={3} style={{ marginBottom: "20px", marginTop: "20px" }}>
          <CardHeader
            title="Client Agreement Registration"
            style={{
              backgroundColor: "#2196f3",
              color: "white",
              padding: "10px",
              textAlign: "center",
            }}
          />
          <Paper
            elevation={3}
            style={{ padding: "20px", marginBottom: "20px" }}
          >
            <Paper
              elevation={3}
              style={{ padding: "20px", marginBottom: "20px" }}
            >
              <Paper
                elevation={3}
                style={{
                  padding: "10px",
                  marginBottom: "30px",
                  backgroundColor: "darkgray",
                }}
              >
                <Typography
                  variant="h5"
                  gutterBottom
                  style={{
                    marginBottom: "15px",
                    color: "darkblue",
                    textAlign: "center",
                  }}
                >
                  Agreement Details
                </Typography>
              </Paper>
              {loadingOverlay && (
                <div className="loading-overlay">
                  <CircularProgress size={60} />
                  <div className="loading-message">Saving Agreement...</div>
                </div>
              )}

              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    id="company"
                    options={company}
                    value={agreementUI.selectCompany}
                    onChange={(e, newValue) =>
                      handleAutocompleteChange(e, newValue, "selectCompany")
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Company"
                        variant="standard"
                        margin="dense"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    id="financial-year"
                    options={financialYear}
                    loading={loading}
                    value={agreementUI.financialYear}
                    onChange={(e, newValue) =>
                      handleAutocompleteChange(e, newValue, "financialYear")
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Financial Year"
                        variant="standard"
                        margin="dense"
                        required
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Unique Agreement Code"
                    name="agreementUniqueCode"
                    value={agreementUI.agreementUniqueCode}
                    disabled
                    fullWidth
                    margin="dense"
                    variant="standard"
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Agreement Code"
                    name="agreementCode"
                    value={agreementUI.agreementCode}
                    onChange={handleChange}
                    fullWidth
                    margin="dense"
                    variant="standard"
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Client Name"
                    name="clientName"
                    value={agreementUI.clientName}
                    onChange={handleChange}
                    fullWidth
                    margin="dense"
                    variant="standard"
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Client Code"
                    name="clientCode"
                    value={agreementUI.clientCode}
                    onChange={handleChange}
                    fullWidth
                    margin="dense"
                    variant="standard"
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Department"
                    name="department"
                    value={agreementUI.department}
                    onChange={handleChange}
                    fullWidth
                    margin="dense"
                    variant="standard"
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Agreement Type"
                    name="agreementType"
                    value={agreementUI.agreementType}
                    onChange={handleChange}
                    fullWidth
                    margin="dense"
                    variant="standard"
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Agreement Date"
                    name="agreementDate"
                    type="date"
                    value={agreementUI.agreementDate}
                    onChange={handleChange}
                    fullWidth
                    margin="dense"
                    variant="standard"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Duration"
                    name="duration"
                    value={agreementUI.duration}
                    onChange={handleChange}
                    fullWidth
                    margin="dense"
                    variant="standard"
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Start Date"
                    name="startDate"
                    type="date"
                    value={agreementUI.startDate}
                    onChange={handleChange}
                    fullWidth
                    margin="dense"
                    variant="standard"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="End Date"
                    name="endDate"
                    type="date"
                    value={agreementUI.endDate}
                    onChange={handleChange}
                    fullWidth
                    margin="dense"
                    variant="standard"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="body1">Agreement Draft</Typography>
                  <input
                    type="file"
                    accept="application/pdf"
                    style={{ display: "none" }}
                    id="upload-agreement-draft"
                    multiple
                    onChange={(e) => handleFileChange(e, setAgreementDraft)}
                  />
                  <label htmlFor="upload-agreement-draft">
                    <Button
                      variant="contained"
                      component="span"
                      color="secondary"
                    >
                      Upload Agreement Draft
                    </Button>
                  </label>
                  <List dense>
                    {agreementDraft.length > 0 &&
                      agreementDraft.map((file, index) => (
                        <ListItem key={index}>
                          <ListItemText primary={file.name} />
                          <ListItemSecondaryAction>
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              onClick={() => removeFile(index, "draft")}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      ))}
                  </List>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="body1">Agreement Copy</Typography>
                  <input
                    type="file"
                    style={{ display: "none" }}
                    id="upload-agreement-copy"
                    accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    multiple
                    onChange={(e) => handleFileChange(e, setAgreementCopy)}
                  />
                  <label htmlFor="upload-agreement-copy">
                    <Button
                      variant="contained"
                      component="span"
                      color="primary"
                    >
                      Upload Agreement Copy
                    </Button>
                  </label>
                  <List dense>
                    {agreementCopy.length > 0 &&
                      agreementCopy.map((file, index) => (
                        <ListItem key={index}>
                          <ListItemText primary={file.name} />
                          <ListItemSecondaryAction>
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              onClick={() => removeFile(index, "copy")}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      ))}
                  </List>
                </Grid>
                {/* <Grid item xs={12} md={6}>
                  <TextField
                    id="agreementDraft"
                    name="agreementDraft"
                    type="file"
                    label="Agreement Draft"
                    inputProps={{ multiple: true }}
                    onChange={(e) => handleFileChange(e, setAgreementDraft)}
                    fullWidth
                    variant="standard"
                    margin="dense"
                  />
                  <List dense>
                    {agreementDraft.length > 0 &&
                      agreementDraft.map((file, index) => (
                        <ListItem key={index}>
                          <ListItemText primary={file.name} />
                          <ListItemSecondaryAction>
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              onClick={() => removeFile(index, "draft")}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      ))}
                  </List>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="agreementCopy"
                    name="agreementCopy"
                    type="file"
                    label="Agreement Copy"
                    inputProps={{ multiple: true }}
                    onChange={(e) => handleFileChange(e, setAgreementCopy)}
                    fullWidth
                    variant="standard"
                    margin="dense"
                  />
                  <List dense>
                    {agreementCopy.length > 0 &&
                      agreementCopy.map((file, index) => (
                        <ListItem key={index}>
                          <ListItemText primary={file.name} />
                          <ListItemSecondaryAction>
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              onClick={() => removeFile(index, "copy")}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      ))}
                  </List>
                </Grid> */}
              </Grid>
              <Paper
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "10px",
                  marginTop: "40px",
                }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={{ marginBottom: "20px", marginLeft: "30px" }}
                >
                  Submit
                </Button>

                <Button
                  type="button"
                  variant="contained"
                  color="secondary"
                  onClick={handleCancel}
                  style={{
                    marginBottom: "20px",
                    marginLeft: "10px",
                    backgroundColor: "red",
                    color: "white",
                  }}
                >
                  Cancel
                </Button>
              </Paper>
            </Paper>
          </Paper>
        </Card>
      </form>
    </Container>
  );
};

export default RegisterClientAgreement;
