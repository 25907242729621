import Headers from "../../../Pages/Header";
import RegisterBank from "../RegisterBank";

const RegisterBankPage = () => {
  return (
    <Headers>
      <RegisterBank />
    </Headers>
  );
};
export default RegisterBankPage;
