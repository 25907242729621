import Headers from "../../Pages/Header";
import EmpPOListVal from "../Validations";
const EmpPOListPage = () => {
  return (
    <Headers>
      <EmpPOListVal />
    </Headers>
  );
};
export default EmpPOListPage;
