import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Container,
  CircularProgress,
} from "@material-ui/core";
import { myAxiosCommonHost } from "../../Service/CorsHelperBaseUrl";
import { Autocomplete } from "@mui/material";
import * as XLSX from "xlsx";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import Swal from "sweetalert2";
import Logo from "../Invoice/Logo1.jpg";

const OutStandingUnPaidHistory = () => {
  const [formData, setFormData] = useState({
    selectCompany: "",
    totalOutStandingAmount: "",
    invoiceRefNo: "",
    financialYear: "",
    clientNameAndCode: "",
    outStandingAmount: "",
    paidAmount: "",
    unPaidAmount: "",
    tdsAmount: "",
    payMode: "",
    payDate: "",
    collectedBy: "",
    collectedName: "",
    chqNumber: "",
    chqDate: "",
    bankBranch: "",
    deductionAmount: "",
    deductionRemark: "",
  });

  const [financialYears, setFinancialYears] = useState([]);
  const [clientNames, setClientNames] = useState([]);
  const [employeeDetails, setEmployeeDetails] = useState([]);
  const [projectDetails, setProjectDetails] = useState([]);
  const [otherChargesDetails, setOtherChargesDetails] = useState([]);
  const [invoiceRefNos, setInvoiceRefNos] = useState([]);
  const [collectionHistory, setCollectionHistory] = useState([]);
  const [loadingOverlay, setLoadingOverlay] = useState(false);
  const [loadingSubmittingOverlay, setLoadingSubmittingOverlay] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingInvoiceRefDetails, setLoadingInvoiceRefDetails] =
    useState(false);
  const [companyCodes, setCompanyCodes] = useState([]);
  const [companyProfile, setCompanyProfile] = useState({});
  const [clientDetails, setClientDetails] = useState({});
  const [gstDetails, setGstDetails] = useState({});

  // Fetch company codes
  useEffect(() => {
    myAxiosCommonHost
      .get("gst/profile/getCompanyCodeList")
      .then((response) => setCompanyCodes(response.data))
      .catch((error) => console.error("Error fetching company codes:", error));
  }, []);

  useEffect(() => {
    // Fetch financial years
    myAxiosCommonHost
      .get("gst/invoice/getFiscalYear")
      .then((response) => setFinancialYears(response.data))
      .catch((error) =>
        console.error("Error fetching financial years:", error)
      );
    // Fetch client names
    myAxiosCommonHost
      .get("gst/outstanding/getOutstandingClientList")
      .then((response) => setClientNames(response.data))
      .catch((error) => console.error("Error fetching client names:", error));
  }, []);
  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      //totalOutStandingAmount: "",
      invoiceRefNo: "",
      outStandingAmount: "",
      paidAmount: "",
      unPaidAmount: "",
      tdsAmount: "",
      payMode: "",
      payDate: "",
      collectedBy: "",
      collectedName: "",
      chqNumber: "",
      chqDate: "",
      bankBranch: "",
      deductionAmount: "",
      deductionRemark: "",
    }));
    setEmployeeDetails([]);
    setProjectDetails([]);
    setOtherChargesDetails([]);
    setCollectionHistory([]);
    if (
      formData.financialYear &&
      formData.clientNameAndCode &&
      formData.selectCompany
    ) {
      const encodedClientNameAndCode = encodeURIComponent(
        formData.clientNameAndCode
      );
      setLoading(true);
      const encodedFinancialYear = encodeURIComponent(formData.financialYear);

      myAxiosCommonHost
        .get(
          `gst/outstanding/getOutstandingClientWiseAmountAndInvoiceRef?clientNameAndCode=${encodedClientNameAndCode}&finacialYear=${encodedFinancialYear}&selectCompany=${formData.selectCompany}`
        )
        .then((response) => {
          const { clientOutStandingAmount, clientInvoiceRefList } =
            response.data;

          setInvoiceRefNos(
            clientInvoiceRefList.map((ref) => ({ label: ref, value: ref }))
          );
          setFormData((prevData) => ({
            ...prevData,
            totalOutStandingAmount: clientOutStandingAmount || "",
          }));
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching clientOutStandingAmount:", error);
          setLoading(false);
        });
    }
  }, [
    formData.financialYear,
    formData.clientNameAndCode,
    formData.selectCompany,
  ]);

  useEffect(() => {
    if (formData.invoiceRefNo) {
      setLoadingInvoiceRefDetails(true); // Start loading animation
      const encodedinvoiceRefNo = encodeURIComponent(formData.invoiceRefNo);
      myAxiosCommonHost
        .get(
          `gst/outstanding/getOutstandingInvoiceRefDetailsUI?invoiceRefNo=${encodedinvoiceRefNo}`
        )
        .then((response) => {
          const {
            grandTotalAmount,
            outstandingEmployeeWiseDetailsUIList,
            outstandingProjectWiseDetailsUIList,
            outstandingCollectionPaidHistoryList,
            outstandingOtherChargesPerDetailsUIList,
          } = response.data;

          setFormData((prevData) => ({
            ...prevData,
            grandTotalAmount: grandTotalAmount || 0.0,
            outStandingAmount: grandTotalAmount || 0.0,
          }));

          if (
            outstandingEmployeeWiseDetailsUIList &&
            outstandingEmployeeWiseDetailsUIList.length > 0
          ) {
            setEmployeeDetails(outstandingEmployeeWiseDetailsUIList);
            setProjectDetails([]); // Clear projectDetails if any
            setOtherChargesDetails([]); // Clear employeeDetails if any
          } else if (
            outstandingProjectWiseDetailsUIList &&
            outstandingProjectWiseDetailsUIList.length > 0
          ) {
            setProjectDetails(outstandingProjectWiseDetailsUIList);
            setEmployeeDetails([]); // Clear employeeDetails if any
            setOtherChargesDetails([]); // Clear employeeDetails if any
          } else if (
            outstandingOtherChargesPerDetailsUIList &&
            outstandingOtherChargesPerDetailsUIList.length > 0
          ) {
            setProjectDetails([]); // Clear employeeDetails if any
            setEmployeeDetails([]); // Clear employeeDetails if any
            setOtherChargesDetails(outstandingOtherChargesPerDetailsUIList);
          }

          setCollectionHistory(outstandingCollectionPaidHistoryList || []);
          setLoadingInvoiceRefDetails(false); // Stop loading animation
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setLoadingInvoiceRefDetails(false); // Stop loading animation even if there's an error
        });
    }
  }, [formData.invoiceRefNo]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => {
      let updatedData = {
        ...prevData,
        [name]: value,
      };
      if (
        name === "outStandingAmount" ||
        name === "paidAmount" ||
        name === "tdsAmount" ||
        name === "deductionAmount"
      ) {
        const outStandingAmount =
          parseFloat(updatedData.outStandingAmount) || 0.0;
        const paidAmount = parseFloat(updatedData.paidAmount) || 0.0;
        const tdsAmount = parseFloat(updatedData.tdsAmount) || 0.0;
        const deductionAmount = parseFloat(updatedData.deductionAmount) || 0.0;

        updatedData.unPaidAmount = (
          outStandingAmount -
          (paidAmount + tdsAmount + deductionAmount)
        ).toFixed(2);
      }
      return updatedData;
    });
  };
  const handleSubmit = () => {
    setLoadingSubmittingOverlay(true);
    myAxiosCommonHost
      .post("gst/outstanding/createOutStandingCollection", formData)
      .then((response) => {
        console.log("POST request successful:", response.data);
        console.log(JSON.stringify(formData, null, 2));
        console.log(JSON.stringify(response.data, null, 2));
        setCollectionHistory((prevCollection) => [...prevCollection, formData]);
        setLoadingSubmittingOverlay(false); // Stop loading animation
      })
      .catch((error) => {
        console.error("Error submitting data:", error);
        setLoadingSubmittingOverlay(false);
      });
    setFormData({
      //totalOutStandingAmount: "",
      invoiceRefNo: "",
      //financialYear: "",
      //clientNameAndCode: "",
      outStandingAmount: "",
      paidAmount: "",
      unPaidAmount: "",
      deductionAmount: "",
      deductionRemark: "",
      tdsAmount: "",
      payMode: "",
      payDate: "",
      collectedBy: "",
      collectedName: "",
      chqNumber: "",
      chqDate: "",
      bankBranch: "",
    });
  };

  const handleExportToExcel = () => {
    if (collectionHistory.length > 0) {
      const worksheet = XLSX.utils.json_to_sheet(collectionHistory);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(
        workbook,
        worksheet,
        "Outstanding Collection "
      );
      XLSX.writeFile(workbook, "outstandingCollection.xlsx");
    }
  };

  const handlegeneratePDF = async (invoiceRefNo, filterType) => {
    try {
      const response = await myAxiosCommonHost.get(
        `gst/invoice/viewInvoice?invoiceRefNo=${invoiceRefNo}`
      );
      const invoiceData = response.data[0]; // Assuming myAxiosCommonHost behaves similarly to axios
      const { companyProfileUI, clientUI, gstdetailsUI } = invoiceData;
      setCompanyProfile(companyProfileUI);
      setClientDetails(clientUI);
      setGstDetails(gstdetailsUI);
      console.log("Company Profile:", companyProfileUI);
      console.log("Client Details:", clientUI);
      console.log("GST Details:", gstdetailsUI);
      // Determine empRateUnit from invoiceData
      const empRateUnit = invoiceData?.empRateUnit;
      const filterType = invoiceData?.filterType;

      if (filterType === "EMPLOYEEPOINVOICE") {
        if (empRateUnit === "Per Day") {
          generatePDFPerDay(invoiceData);
        } else if (empRateUnit === "Per Month") {
          generatePDFPerMonth(invoiceData);
        } else if (empRateUnit === "Per Hour") {
          generatePDFPerHour(invoiceData);
        } else {
          console.log("Invalid empRateUnit value. Unable to generate PDF");
        }
      } else if (filterType === "PROJECTPOINVOICE") {
        // Additional check for Project PO Invoice
        if (empRateUnit === "Per Day") {
          generateProjectPDFPerDay(invoiceData);
        } else if (empRateUnit === "Per Hour") {
          generateProjectPDFPerHour(invoiceData);
        } else {
          console.log(
            "Invalid empRateUnit value for Project PO Invoice. Unable to generate PDF"
          );
        }
      } else if (filterType === "OTHERCHARGESINVOICE") {
        // Directly download Other Charges Invoice
        generateOtherChargesPDF(invoiceData);
      } else {
        console.log("Invalid filterType. Unable to determine PDF type.");
        return;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const generatePDFPerDay = async (invoiceData) => {
    const doc = new jsPDF();
    const includePO = await Swal.fire({
      title: "Do you want to include PO number in the PDF?",
      icon: "question",
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        return true;
      } else {
        return false;
      }
    });

    // Define includeEmployeeCategoryWithPO variable
    let includeEmployeeCategoryWithPO;

    if (includePO) {
      includeEmployeeCategoryWithPO = await Swal.fire({
        title:
          "Do you want to include Employee Category with PO Number in the PDF?",
        icon: "question",
        showDenyButton: true,
        confirmButtonText: "Yes",
        denyButtonText: `No`,
      }).then((result) => {
        if (result.isConfirmed) {
          return true;
        } else {
          return false;
        }
      });

      await generatePDF(includePO, includeEmployeeCategoryWithPO);
    } else {
      await generatePDF(includePO, false);
    }
    // Call generatePDF function with correct parameters
    async function generatePDF(includePO, includeEmployeeCategoryWithPO) {
      const encompassingRectX = 3;
      const encompassingRectY = 4;
      const encompassingRectWidth = doc.internal.pageSize.width - 6;
      const encompassingRectHeight = doc.internal.pageSize.height - 8;

      doc.setDrawColor(0); // Set border color to black
      doc.setLineWidth(0.4); // Set border width
      doc.rect(
        encompassingRectX,
        encompassingRectY,
        encompassingRectWidth,
        encompassingRectHeight
      );

      let companyInfoText =
        `${companyProfile.companyName}\n` +
        `${companyProfile.companyAddress}\n` +
        `${companyProfile.website}\n` +
        `${companyProfile.eMail}`;

      // Add the company information to the PDF for the current invoice
      doc.setFontSize(10);
      doc.setTextColor(0, 0, 0); // Black color
      doc.setFont("helvetica", "bold"); // Set font to bold
      doc.text(companyInfoText, 15, 30);
      doc.setFont("helvetica", "normal"); // Reset font to normal

      // Continue with the rest of your PDF generation logic...

      const companyInfoContainerX = 14;
      const companyInfoContainerY = 10;
      const companyInfoContainerWidth =
        doc.internal.pageSize.width * 0.55 - companyInfoContainerX - 10;
      const companyInfoContainerHeight = 40;

      // Draw the container first
      doc.setDrawColor(0);
      doc.setLineWidth(0.4);
      doc.setFillColor(255, 255, 255);
      doc.rect(
        companyInfoContainerX,
        companyInfoContainerY,
        companyInfoContainerWidth,
        companyInfoContainerHeight,
        "FD"
      );

      // Add the logo with additional margin from the left border
      doc.addImage(Logo, "JPEG", 15, 11, 28, 28); // Change x coordinate (e.g., 25 or 30)

      // Position the company information text with reduced margin after the logo
      doc.setFontSize(10);
      doc.setTextColor(0, 0, 0);
      doc.text(companyInfoText, 25 + 20 + 2, 18, {
        // Change margin after logo width (e.g., 2 or 3)
        maxWidth: doc.internal.pageSize.width * 0.55 - 25 - 20 - 15, // Calculate available width minus adjusted logo position and margins
      });

      const CompInvo = [
        ["Invoice No: " + invoiceData.invoiceRefNo],
        ["Company's GST NO.: " + companyProfile.gstin], // Include GSTIN directly
        ["Company's PAN No.: " + companyProfile.pan], // Include PAN directly
        ["Company's CIN: " + companyProfile.cin], // Include CIN directly
        ["Invoice Date: " + invoiceData.invoiceDate],
      ];

      const tableWidth = doc.internal.pageSize.width * 0.45;
      const leftMargin = doc.internal.pageSize.width - tableWidth - 10;

      // Calculate the height of the CompInvo table
      const compInvoTableHeight = doc.autoTable({
        body: CompInvo,
        theme: "grid",
        margin: { top: 10, left: leftMargin, right: 14, bottom: 0 },
        styles: {
          fontSize: 10,
          lineWidth: 0.5,
          lineColor: [0, 0, 0],
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          cellPadding: 2,
          marginBottom: 0,
          marginTop: 0,
          padding: 0,
        },
      }).lastAutoTable.finalY;

      const ClientInvo = [
        ["Service ACC Code: " + gstDetails.hsnsac],
        ["Client's GSTIN: " + gstDetails.gstin],
        ["Payment Term: 15Days Bank Transfer"], // No need to concatenate, as it's a single value
        ["Billing Period: " + clientDetails.billingPeriod],
        ["Onsite Manpower Support for the month"], // No need to concatenate, as it's a single value
      ];
      // Set the startY parameter for ClientInvo table to start immediately after CompInvo table
      doc.autoTable({
        body: ClientInvo,
        theme: "grid",
        columnStyles: { 0: { cellWidth: "auto" } },
        startY: compInvoTableHeight, // Set startY explicitly
        margin: {
          top: compInvoTableHeight,
          left: leftMargin,
          right: 14,
          bottom: 0,
        },
        styles: {
          fontSize: 10,
          lineWidth: 0.5,
          lineColor: [0, 0, 0],
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          marginBottom: 0,
          tableHeight: 40,
        },
      });

      const billedToContainerHeight = 38.85;
      const billedToTextLines = [
        `Billed To: ${clientDetails.clientName}`,
        clientDetails.clientAddress,
        "",
        `Place of supply: ${gstDetails.state} State Code: ${gstDetails.stateCode}`,
        `Ph: ${clientDetails.clientContactNumber}`,
        `Kind Attn: ${invoiceData.invoicePaymentModeUI.kindAttention || ""}`,
      ];
      const billedToContainerX = companyInfoContainerX;
      const topMargin = 0;
      const containerHeight = 40; // Set the common height for both the billedToContainer and the table
      const billedToContainerY =
        companyInfoContainerY + companyInfoContainerHeight + topMargin;
      const billedToContainerWidth = companyInfoContainerWidth;

      doc.setDrawColor(0);
      doc.setLineWidth(0.4);
      doc.setFillColor(255, 255, 255);
      doc.rect(
        billedToContainerX,
        billedToContainerY,
        billedToContainerWidth,
        containerHeight,
        "FD"
      );

      doc.setFontSize(10);
      doc.setTextColor(0, 0, 0);

      const lineHeight = 5;
      let yPosition = billedToContainerY + 10;

      billedToTextLines.forEach((line) => {
        doc.text(line.trim(), billedToContainerX + 5, yPosition, {
          maxWidth: billedToContainerWidth - 10,
        });
        yPosition += lineHeight;
      });

      doc.setFillColor(255, 255, 255);
      doc.setFillColor(255, 255, 255);

      let tableData;
      let amountColumnIndex;
      if (includePO) {
        // Include PO number column in the PDF
        if (includeEmployeeCategoryWithPO) {
          tableData = invoiceData.invoiceEmployeePoDetailsUIList.map((row) => [
            row.emplyoyeeName,
            invoiceData.poNumber,
            row.employeeCategory,
            row.fromDate,
            row.toDate,

            row.presenty,
            row.currentPoRate,
            row.presentyAmt, // Calculate amount
          ]);
          amountColumnIndex = 7;
        } else {
          tableData = invoiceData.invoiceEmployeePoDetailsUIList.map((row) => [
            row.emplyoyeeName,

            row.fromDate,
            row.toDate,

            row.presenty,
            row.currentPoRate,
            row.presentyAmt, // Calculate amount
          ]);
          amountColumnIndex = 6;
        }
      } else {
        // Exclude PO number column in the PDF
        tableData = invoiceData.invoiceEmployeePoDetailsUIList.map((row) => [
          row.emplyoyeeName,

          row.fromDate,
          row.toDate,

          row.presenty,
          row.currentPoRate,
          row.presentyAmt, // Calculate amount
        ]);
        amountColumnIndex = 5;
      }

      const headerLabels =
        includePO && includeEmployeeCategoryWithPO
          ? [
              "Particulars",
              "PO NO.",
              "Employee Category",
              "From Date",
              "To date",
              "Billable Days",
              "Current PO Rate",
              "Amount",
            ]
          : includePO && !includeEmployeeCategoryWithPO
          ? [
              "Particulars",
              "From Date",
              "To date",
              "Billable Days",
              "Current PO Rate",
              "Amount",
            ]
          : [
              "Particulars",
              "From Date",
              "To date",
              "Billable Days",
              "Current PO Rate",
              "Amount",
            ];

      // Generate the PDF table
      doc.autoTable({
        head: [headerLabels],

        body: tableData,
        startY: billedToContainerY + billedToContainerHeight + 0,
        theme: "grid",
        styles: {
          fontSize: 9,
          lineWidth: 0.5,
          lineColor: [0, 0, 0],
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          marginBottom: 0,
        },
        columnStyles: {
          [amountColumnIndex]: { columnWidth: 25, halign: "right" }, // Set width for the "Amount" column
        },
      });

      // Calculate the height of the first table (invoice data table)
      const firstTableHeight = doc.previousAutoTable
        ? doc.previousAutoTable.finalY
        : 0;

      const {
        totalPresentyAmount,
        grandTotalAmount,
        totalTaxAmt,
        totalInWordAmount,
      } = invoiceData.invoiceGstDetailsUI;
      const { cgstAmount, sgstAmount, igstAmount } =
        invoiceData.invoiceGstDetailsUI;

      const InvoiceData = [
        ["A] Total", totalPresentyAmount],
        ["B] CGST (1)", ` ${cgstAmount}`],
        ["C] SGST (2)", ` ${sgstAmount}`],
        ["D] IGST (3)", ` ${igstAmount}`],
        ["E] Total (A+B+C+D)", grandTotalAmount],
        ["F] Deduction ", 0],
        ["G] Grand Total (F-G)", grandTotalAmount],
        [{ content: " Total In Words: " + totalInWordAmount, colSpan: 2 }],
      ];

      // Set startY for the second table immediately after the first table
      doc.autoTable({
        body: InvoiceData,
        startY: firstTableHeight + 0,
        theme: "grid",
        styles: {
          lineWidth: 0.5,
          lineColor: [0, 0, 0],
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          marginBottom: 0,
        },
        columnStyles: {
          0: { columnWidth: 157 },
          1: { columnWidth: 25, halign: "right" },
        },
      });

      // Calculate the height of the second table (invoice data table)
      const secondTableHeight = doc.previousAutoTable
        ? doc.previousAutoTable.finalY
        : 0;

      // Calculate remaining space on the page
      const remainingSpace =
        doc.internal.pageSize.getHeight() - secondTableHeight - 20;

      // Check if there is enough space for the bank table
      if (remainingSpace < 0) {
        doc.addPage();
      }
      let finalY = doc.internal.pageSize.height;

      if (doc.previousAutoTable && doc.previousAutoTable.finalY) {
        finalY = doc.previousAutoTable.finalY + 5; // Add some padding
      }

      const innerRectHeight = 60; // Set a fixed height for the inner rectangle
      const remainingSpace2 = doc.internal.pageSize.height - finalY;

      // Check if the inner rectangle can fit in the remaining space
      if (remainingSpace2 < innerRectHeight) {
        doc.addPage();
        finalY = 15; // Reset Y position for new page
      }

      // Draw the encompassing border at the end
      doc.setDrawColor(0); // Set border color to black
      doc.setLineWidth(0.4); // Set border width
      //draw Big border for the pdf page
      doc.rect(
        3,
        4,
        doc.internal.pageSize.width - 6,
        finalY > doc.internal.pageSize.height - 8
          ? finalY
          : doc.internal.pageSize.height - 8
      );

      // const innerRectX = 20;
      // const innerRectY = finalY + 0; // Start just after the previous table
      // const innerRectWidth = doc.internal.pageSize.width - 30;
      const innerRectX = 14;
      const innerRectY = finalY - 5; // Start just after the previous table
      const innerRectWidth = 182;
      // Draw the inner rectangle
      doc.rect(innerRectX, innerRectY, innerRectWidth, innerRectHeight);

      // Draw the vertical line to split the inner rectangle
      const splitLineX = innerRectX + innerRectWidth / 2;
      doc.line(
        splitLineX,
        innerRectY,
        splitLineX,
        innerRectY + innerRectHeight
      );

      // Add bank details to the left section
      const bankDetailsArray = [
        `Bank Name: ${invoiceData.bankUI.bankName}`,
        `Bank Address: ${invoiceData.bankUI.bankAddress}`,
        `A/C No.: ${invoiceData.bankUI.accountNumber}`,
        `IFC Code: ${invoiceData.bankUI.ifsc}`,
        `Swift Code: ${invoiceData.bankUI.swiftCode}`,
      ];

      const maxTextWidth = innerRectWidth / 2 - 10; // Maximum width for the text in the left section
      let leftTextY = innerRectY + 10; // Initial Y position for the left text

      const lineHeight2 = 7; // Increase line height for more margin

      bankDetailsArray.forEach((line) => {
        const lines = doc.splitTextToSize(line, maxTextWidth);
        lines.forEach((wrappedLine) => {
          doc.text(wrappedLine, innerRectX + 5, leftTextY);
          leftTextY += lineHeight2; // Adjust the line height for more margin
        });
      });

      // Add text to the right section
      const rightText1 = "For Gsquare Techsystem PVT LTD";
      const rightText2 = "Authorized Signatory & Seal";
      const rightText1Width = doc.getTextWidth(rightText1);
      const rightText2Width = doc.getTextWidth(rightText2);

      const rightTextX1 =
        splitLineX + (innerRectWidth / 2 - rightText1Width) / 2;
      const rightTextX2 =
        splitLineX + (innerRectWidth / 2 - rightText2Width) / 2;

      const gapBetweenLines = 30; // Set the gap between the lines here

      doc.text(rightText1, rightTextX1, innerRectY + 10);
      doc.text(rightText2, rightTextX2, innerRectY + 10 + gapBetweenLines); // Adjust the Y position for the second line

      const pdfData = doc.output("blob"); // Assuming doc.output provides Blob object
      const pdfWindow = window.open("", "_blank");
      const pdfURL = URL.createObjectURL(pdfData);
      pdfWindow.location.href = pdfURL;
    }
  };

  const generatePDFPerMonth = async (invoiceData, annexureFlag) => {
    const doc = new jsPDF();

    const includePO = await Swal.fire({
      title: "Do you want to include PO number in the PDF?",
      icon: "question",
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        return true;
      } else {
        return false;
      }
    });

    // Define includeEmployeeCategoryWithPO variable
    let includeEmployeeCategoryWithPO;

    if (includePO) {
      includeEmployeeCategoryWithPO = await Swal.fire({
        title:
          "Do you want to include Employee Category with PO Number in the PDF?",
        icon: "question",
        showDenyButton: true,
        confirmButtonText: "Yes",
        denyButtonText: `No`,
      }).then((result) => {
        if (result.isConfirmed) {
          return true;
        } else {
          return false;
        }
      });

      await generatePDF(includePO, includeEmployeeCategoryWithPO);
    } else {
      await generatePDF(includePO, false);
    }
    // Call generatePDF function with correct parameters
    async function generatePDF(includePO, includeEmployeeCategoryWithPO) {
      const encompassingRectX = 3;
      const encompassingRectY = 4;
      const encompassingRectWidth = doc.internal.pageSize.width - 6;
      const encompassingRectHeight = doc.internal.pageSize.height - 8;

      doc.setDrawColor(0); // Set border color to black
      doc.setLineWidth(0.4); // Set border width
      doc.rect(
        encompassingRectX,
        encompassingRectY,
        encompassingRectWidth,
        encompassingRectHeight
      );

      let companyInfoText =
        `${companyProfile.companyName}\n` +
        `${companyProfile.companyAddress}\n` +
        `${companyProfile.website}\n` +
        `${companyProfile.eMail}`;

      // Add the company information to the PDF for the current invoice
      doc.setFontSize(10);
      doc.setTextColor(0, 0, 0); // Black color
      doc.setFont("helvetica", "bold"); // Set font to bold
      doc.text(companyInfoText, 15, 30);
      doc.setFont("helvetica", "normal"); // Reset font to normal

      // Continue with the rest of your PDF generation logic...

      const companyInfoContainerX = 14;
      const companyInfoContainerY = 10;
      const companyInfoContainerWidth =
        doc.internal.pageSize.width * 0.55 - companyInfoContainerX - 10;
      const companyInfoContainerHeight = 40;

      // Draw the container first
      doc.setDrawColor(0);
      doc.setLineWidth(0.4);
      doc.setFillColor(255, 255, 255);
      doc.rect(
        companyInfoContainerX,
        companyInfoContainerY,
        companyInfoContainerWidth,
        companyInfoContainerHeight,
        "FD"
      );

      // Add the logo with additional margin from the left border
      doc.addImage(Logo, "JPEG", 15, 11, 28, 28); // Change x coordinate (e.g., 25 or 30)

      // Position the company information text with reduced margin after the logo
      doc.setFontSize(10);
      doc.setTextColor(0, 0, 0);
      doc.text(companyInfoText, 25 + 20 + 2, 18, {
        // Change margin after logo width (e.g., 2 or 3)
        maxWidth: doc.internal.pageSize.width * 0.55 - 25 - 20 - 15, // Calculate available width minus adjusted logo position and margins
      });

      const CompInvo = [
        ["Invoice No: " + invoiceData.invoiceRefNo],
        ["Company's GST NO.: " + companyProfile.gstin], // Include GSTIN directly
        ["Company's PAN No.: " + companyProfile.pan], // Include PAN directly
        ["Company's CIN: " + companyProfile.cin], // Include CIN directly
        ["Invoice Date: " + invoiceData.invoiceDate],
      ];

      const tableWidth = doc.internal.pageSize.width * 0.45;
      const leftMargin = doc.internal.pageSize.width - tableWidth - 10;

      // Calculate the height of the CompInvo table
      const compInvoTableHeight = doc.autoTable({
        body: CompInvo,
        theme: "grid",
        margin: { top: 10, left: leftMargin, right: 14, bottom: 0 },
        styles: {
          fontSize: 10,
          lineWidth: 0.5,
          lineColor: [0, 0, 0],
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          cellPadding: 2,
          marginBottom: 0,
          marginTop: 0,
          padding: 0,
        },
      }).lastAutoTable.finalY;

      const ClientInvo = [
        ["Service ACC Code: " + gstDetails.hsnsac],
        ["Client's GSTIN: " + gstDetails.gstin],
        ["Payment Term: 15Days Bank Transfer"], // No need to concatenate, as it's a single value
        ["Billing Period: " + clientDetails.billingPeriod],
        ["Onsite Manpower Support for the month"], // No need to concatenate, as it's a single value
      ];

      // Set the startY parameter for ClientInvo table to start immediately after CompInvo table
      doc.autoTable({
        body: ClientInvo,
        theme: "grid",
        columnStyles: { 0: { cellWidth: "auto" } },
        startY: compInvoTableHeight, // Set startY explicitly
        margin: {
          top: compInvoTableHeight,
          left: leftMargin,
          right: 14,
          bottom: 0,
        },
        styles: {
          fontSize: 10,
          lineWidth: 0.5,
          lineColor: [0, 0, 0],
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          marginBottom: 0,
          tableHeight: 40,
        },
      });

      const billedToContainerHeight = 38.85;
      const billedToTextLines = [
        `Billed To: ${clientDetails.clientName}`,
        clientDetails.clientAddress,
        "",
        `Place of supply: ${gstDetails.state} State Code: ${gstDetails.stateCode}`,
        `Ph: ${clientDetails.clientContactNumber}`,
        `Kind Attn: ${invoiceData.invoicePaymentModeUI.kindAttention || ""}`,
      ];
      const billedToContainerX = companyInfoContainerX;
      const topMargin = 0;
      const containerHeight = 40; // Set the common height for both the billedToContainer and the table
      const billedToContainerY =
        companyInfoContainerY + companyInfoContainerHeight + topMargin;
      const billedToContainerWidth = companyInfoContainerWidth;

      doc.setDrawColor(0);
      doc.setLineWidth(0.4);
      doc.setFillColor(255, 255, 255);
      doc.rect(
        billedToContainerX,
        billedToContainerY,
        billedToContainerWidth,
        containerHeight,
        "FD"
      );

      doc.setFontSize(10);
      doc.setTextColor(0, 0, 0);

      const lineHeight = 5;
      let yPosition = billedToContainerY + 10;

      billedToTextLines.forEach((line) => {
        doc.text(line.trim(), billedToContainerX + 5, yPosition, {
          maxWidth: billedToContainerWidth - 10,
        });
        yPosition += lineHeight;
      });

      doc.setFillColor(255, 255, 255);

      let tableData;
      let amountColumnIndex;
      if (includePO) {
        // Include PO number column in the PDF
        if (includeEmployeeCategoryWithPO) {
          tableData = invoiceData.invoiceEmployeePoDetailsUIList.map((row) => [
            row.emplyoyeeName,
            invoiceData.poNumber,
            row.employeeCategory,
            row.fromDate,
            row.toDate,
            invoiceData.workingDays,
            row.presenty,
            row.currentPoRate,
            row.presentyAmt, // Calculate amount
          ]);
          amountColumnIndex = 8;
        } else {
          tableData = invoiceData.invoiceEmployeePoDetailsUIList.map((row) => [
            row.emplyoyeeName,
            invoiceData.poNumber,
            row.fromDate,
            row.toDate,
            invoiceData.workingDays,
            row.presenty,
            row.currentPoRate,
            row.presentyAmt, // Calculate amount
          ]);
          amountColumnIndex = 7;
        }
      } else {
        // Exclude PO number column in the PDF
        tableData = invoiceData.invoiceEmployeePoDetailsUIList.map((row) => [
          row.emplyoyeeName,

          row.fromDate,
          row.toDate,
          invoiceData.workingDays,
          row.presenty,
          row.currentPoRate,
          row.presentyAmt, // Calculate amount
        ]);
        amountColumnIndex = 7;
      }

      const headerLabels =
        includePO && includeEmployeeCategoryWithPO
          ? [
              "Particulars",
              "PO NO.",
              "Employee Category",
              "From Date",
              "To date",
              "Working Days",
              "Billable Days",
              "Current PO Rate",
              "Amount",
            ]
          : includePO && !includeEmployeeCategoryWithPO
          ? [
              "Particulars",
              "PO NO.",
              "From Date",
              "To date",
              "Working Days",
              "Billable Days",
              "Current PO Rate",
              "Amount",
            ]
          : [
              "Particulars",
              "From Date",
              "To date",
              "Working Days",
              "Billable Days",
              "Current PO Rate",
              "Amount",
            ];

      // Generate the PDF table
      doc.autoTable({
        head: [headerLabels],

        body: tableData,
        startY: billedToContainerY + billedToContainerHeight + 0,
        theme: "grid",
        styles: {
          fontSize: 9,
          lineWidth: 0.5,
          lineColor: [0, 0, 0],
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          marginBottom: 0,
        },
        columnStyles: {
          [amountColumnIndex]: { columnWidth: 25, halign: "right" }, // Set width for the "Amount" column
        },
      });

      // Calculate the height of the first table (invoice data table)
      const firstTableHeight = doc.previousAutoTable
        ? doc.previousAutoTable.finalY
        : 0;

      const {
        totalPresentyAmount,
        grandTotalAmount,
        totalTaxAmt,
        totalInWordAmount,
      } = invoiceData.invoiceGstDetailsUI;
      const { cgstAmount, sgstAmount, igstAmount } =
        invoiceData.invoiceGstDetailsUI;

      const InvoiceData = [
        ["A] Total", totalPresentyAmount],
        ["B] CGST (1)", ` ${cgstAmount}`],
        ["C] SGST (2)", ` ${sgstAmount}`],
        ["D] IGST (3)", ` ${igstAmount}`],
        ["E] Total (A+B+C+D)", grandTotalAmount],
        ["F] Deduction ", 0],
        ["G] Grand Total (F-G)", grandTotalAmount],
        [{ content: " Total In Words: " + totalInWordAmount, colSpan: 2 }],
      ];

      // Set startY for the second table immediately after the first table
      doc.autoTable({
        body: InvoiceData,
        startY: firstTableHeight + 0,
        theme: "grid",
        styles: {
          lineWidth: 0.5,
          lineColor: [0, 0, 0],
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          marginBottom: 0,
        },
        columnStyles: {
          0: { columnWidth: 157 },
          1: { columnWidth: 25, halign: "right" },
        },
      });

      // Calculate the height of the second table (invoice data table)
      const secondTableHeight = doc.previousAutoTable
        ? doc.previousAutoTable.finalY
        : 0;

      // Calculate remaining space on the page
      const remainingSpace =
        doc.internal.pageSize.getHeight() - secondTableHeight - 20;

      // Check if there is enough space for the bank table
      if (remainingSpace < 0) {
        doc.addPage();
      }
      let finalY = doc.internal.pageSize.height;

      if (doc.previousAutoTable && doc.previousAutoTable.finalY) {
        finalY = doc.previousAutoTable.finalY + 5; // Add some padding
      }

      const innerRectHeight = 60; // Set a fixed height for the inner rectangle
      const remainingSpace2 = doc.internal.pageSize.height - finalY;

      // Check if the inner rectangle can fit in the remaining space
      if (remainingSpace2 < innerRectHeight) {
        doc.addPage();
        finalY = 15; // Reset Y position for new page
      }

      // Draw the encompassing border at the end
      doc.setDrawColor(0); // Set border color to black
      doc.setLineWidth(0.4); // Set border width
      //draw Big border for the pdf page
      doc.rect(
        3,
        4,
        doc.internal.pageSize.width - 6,
        finalY > doc.internal.pageSize.height - 8
          ? finalY
          : doc.internal.pageSize.height - 8
      );

      // const innerRectX = 20;
      // const innerRectY = finalY + 0; // Start just after the previous table
      // const innerRectWidth = doc.internal.pageSize.width - 30;
      const innerRectX = 14;
      const innerRectY = finalY - 5; // Start just after the previous table
      const innerRectWidth = 182;
      // Draw the inner rectangle
      doc.rect(innerRectX, innerRectY, innerRectWidth, innerRectHeight);

      // Draw the vertical line to split the inner rectangle
      const splitLineX = innerRectX + innerRectWidth / 2;
      doc.line(
        splitLineX,
        innerRectY,
        splitLineX,
        innerRectY + innerRectHeight
      );

      // Add bank details to the left section
      const bankDetailsArray = [
        `Bank Name: ${invoiceData.bankUI.bankName}`,
        `Bank Address: ${invoiceData.bankUI.bankAddress}`,
        `A/C No.: ${invoiceData.bankUI.accountNumber}`,
        `IFC Code: ${invoiceData.bankUI.ifsc}`,
        `Swift Code: ${invoiceData.bankUI.swiftCode}`,
      ];

      const maxTextWidth = innerRectWidth / 2 - 10; // Maximum width for the text in the left section
      let leftTextY = innerRectY + 10; // Initial Y position for the left text

      const lineHeight2 = 7; // Increase line height for more margin

      bankDetailsArray.forEach((line) => {
        const lines = doc.splitTextToSize(line, maxTextWidth);
        lines.forEach((wrappedLine) => {
          doc.text(wrappedLine, innerRectX + 5, leftTextY);
          leftTextY += lineHeight2; // Adjust the line height for more margin
        });
      });

      // Add text to the right section
      const rightText1 = "For Gsquare Techsystem PVT LTD";
      const rightText2 = "Authorized Signatory & Seal";
      const rightText1Width = doc.getTextWidth(rightText1);
      const rightText2Width = doc.getTextWidth(rightText2);

      const rightTextX1 =
        splitLineX + (innerRectWidth / 2 - rightText1Width) / 2;
      const rightTextX2 =
        splitLineX + (innerRectWidth / 2 - rightText2Width) / 2;

      const gapBetweenLines = 30; // Set the gap between the lines here

      doc.text(rightText1, rightTextX1, innerRectY + 10);
      doc.text(rightText2, rightTextX2, innerRectY + 10 + gapBetweenLines); // Adjust the Y position for the second line
      const pdfData = doc.output("blob"); // Assuming doc.output provides Blob object
      const pdfWindow = window.open("", "_blank");
      const pdfURL = URL.createObjectURL(pdfData);
      pdfWindow.location.href = pdfURL;
    }
  };

  const generatePDFPerHour = async (invoiceData) => {
    const doc = new jsPDF();

    const includePO = await Swal.fire({
      title: "Do you want to include PO number in the PDF?",
      icon: "question",
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        return true;
      } else {
        return false;
      }
    });

    // Define includeEmployeeCategoryWithPO variable
    let includeEmployeeCategoryWithPO;

    if (includePO) {
      includeEmployeeCategoryWithPO = await Swal.fire({
        title:
          "Do you want to include Employee Category with PO Number in the PDF?",
        icon: "question",
        showDenyButton: true,
        confirmButtonText: "Yes",
        denyButtonText: `No`,
      }).then((result) => {
        if (result.isConfirmed) {
          return true;
        } else {
          return false;
        }
      });

      await generatePDF(includePO, includeEmployeeCategoryWithPO);
    } else {
      await generatePDF(includePO, false);
    }
    // Call generatePDF function with correct parameters
    async function generatePDF(includePO, includeEmployeeCategoryWithPO) {
      const encompassingRectX = 3;
      const encompassingRectY = 4;
      const encompassingRectWidth = doc.internal.pageSize.width - 6;
      const encompassingRectHeight = doc.internal.pageSize.height - 8;

      doc.setDrawColor(0); // Set border color to black
      doc.setLineWidth(0.4); // Set border width
      doc.rect(
        encompassingRectX,
        encompassingRectY,
        encompassingRectWidth,
        encompassingRectHeight
      );

      let companyInfoText =
        `${companyProfile.companyName}\n` +
        `${companyProfile.companyAddress}\n` +
        `${companyProfile.website}\n` +
        `${companyProfile.eMail}`;

      // Add the company information to the PDF for the current invoice
      doc.setFontSize(10);
      doc.setTextColor(0, 0, 0); // Black color
      doc.setFont("helvetica", "bold"); // Set font to bold
      doc.text(companyInfoText, 15, 30);
      doc.setFont("helvetica", "normal"); // Reset font to normal

      // Continue with the rest of your PDF generation logic...

      const companyInfoContainerX = 14;
      const companyInfoContainerY = 10;
      const companyInfoContainerWidth =
        doc.internal.pageSize.width * 0.55 - companyInfoContainerX - 10;
      const companyInfoContainerHeight = 40;

      // Draw the container first
      doc.setDrawColor(0);
      doc.setLineWidth(0.4);
      doc.setFillColor(255, 255, 255);
      doc.rect(
        companyInfoContainerX,
        companyInfoContainerY,
        companyInfoContainerWidth,
        companyInfoContainerHeight,
        "FD"
      );

      // Add the logo with additional margin from the left border
      doc.addImage(Logo, "JPEG", 15, 11, 28, 28); // Change x coordinate (e.g., 25 or 30)

      // Position the company information text with reduced margin after the logo
      doc.setFontSize(10);
      doc.setTextColor(0, 0, 0);
      doc.text(companyInfoText, 25 + 20 + 2, 18, {
        // Change margin after logo width (e.g., 2 or 3)
        maxWidth: doc.internal.pageSize.width * 0.55 - 25 - 20 - 15, // Calculate available width minus adjusted logo position and margins
      });
      const CompInvo = [
        ["Invoice No: " + invoiceData.invoiceRefNo],
        ["Company's GST NO.: " + companyProfile.gstin], // Include GSTIN directly
        ["Company's PAN No.: " + companyProfile.pan], // Include PAN directly
        ["Company's CIN: " + companyProfile.cin], // Include CIN directly
        ["Invoice Date: " + invoiceData.invoiceDate],
      ];

      const tableWidth = doc.internal.pageSize.width * 0.45;
      const leftMargin = doc.internal.pageSize.width - tableWidth - 10;

      // Calculate the height of the CompInvo table
      const compInvoTableHeight = doc.autoTable({
        body: CompInvo,
        theme: "grid",
        margin: { top: 10, left: leftMargin, right: 14, bottom: 0 },
        styles: {
          fontSize: 10,
          lineWidth: 0.5,
          lineColor: [0, 0, 0],
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          cellPadding: 2,
          marginBottom: 0,
          marginTop: 0,
          padding: 0,
        },
      }).lastAutoTable.finalY;

      const ClientInvo = [
        ["Service ACC Code: " + gstDetails.hsnsac],
        ["Client's GSTIN: " + gstDetails.gstin],
        ["Payment Term: 15Days Bank Transfer"], // No need to concatenate, as it's a single value
        ["Billing Period: " + clientDetails.billingPeriod],
        ["Onsite Manpower Support for the month"], // No need to concatenate, as it's a single value
      ];
      // Set the startY parameter for ClientInvo table to start immediately after CompInvo table
      doc.autoTable({
        body: ClientInvo,
        theme: "grid",
        columnStyles: { 0: { cellWidth: "auto" } },
        startY: compInvoTableHeight, // Set startY explicitly
        margin: {
          top: compInvoTableHeight,
          left: leftMargin,
          right: 14,
          bottom: 0,
        },
        styles: {
          fontSize: 10,
          lineWidth: 0.5,
          lineColor: [0, 0, 0],
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          marginBottom: 0,
          tableHeight: 40,
        },
      });

      const billedToContainerHeight = 38.85;
      const billedToTextLines = [
        `Billed To: ${clientDetails.clientName}`,
        clientDetails.clientAddress,
        "",
        `Place of supply: ${gstDetails.state} State Code: ${gstDetails.stateCode}`,
        `Ph: ${clientDetails.clientContactNumber}`,
        `Kind Attn: ${invoiceData.invoicePaymentModeUI.kindAttention || ""}`,
      ];
      const billedToContainerX = companyInfoContainerX;
      const topMargin = 0;
      const containerHeight = 40; // Set the common height for both the billedToContainer and the table
      const billedToContainerY =
        companyInfoContainerY + companyInfoContainerHeight + topMargin;
      const billedToContainerWidth = companyInfoContainerWidth;

      doc.setDrawColor(0);
      doc.setLineWidth(0.4);
      doc.setFillColor(255, 255, 255);
      doc.rect(
        billedToContainerX,
        billedToContainerY,
        billedToContainerWidth,
        containerHeight,
        "FD"
      );

      doc.setFontSize(10);
      doc.setTextColor(0, 0, 0);

      const lineHeight = 5;
      let yPosition = billedToContainerY + 10;

      billedToTextLines.forEach((line) => {
        doc.text(line.trim(), billedToContainerX + 5, yPosition, {
          maxWidth: billedToContainerWidth - 10,
        });
        yPosition += lineHeight;
      });

      doc.setFillColor(255, 255, 255);

      let tableData;
      let amountColumnIndex;
      if (includePO) {
        // Include PO number column in the PDF
        if (includeEmployeeCategoryWithPO) {
          tableData = invoiceData.invoiceEmployeePoDetailsUIList.map((row) => [
            row.emplyoyeeName,
            invoiceData.poNumber,
            row.employeeCategory,
            row.fromDate,
            row.toDate,

            row.presenty,
            row.currentPoRate,
            row.presentyAmt, // Calculate amount
          ]);
          amountColumnIndex = 7;
        } else {
          tableData = invoiceData.invoiceEmployeePoDetailsUIList.map((row) => [
            row.emplyoyeeName,

            row.fromDate,
            row.toDate,

            row.presenty,
            row.currentPoRate,
            row.presentyAmt, // Calculate amount
          ]);
          amountColumnIndex = 6;
        }
      } else {
        // Exclude PO number column in the PDF
        tableData = invoiceData.invoiceEmployeePoDetailsUIList.map((row) => [
          row.emplyoyeeName,

          row.fromDate,
          row.toDate,

          row.presenty,
          row.currentPoRate,
          row.presentyAmt, // Calculate amount
        ]);
        amountColumnIndex = 5;
      }

      const headerLabels =
        includePO && includeEmployeeCategoryWithPO
          ? [
              "Particulars",
              "PO NO.",
              "Employee Category",
              "From Date",
              "To date",
              "Billable Hours",
              "Current PO Rate",
              "Amount",
            ]
          : includePO && !includeEmployeeCategoryWithPO
          ? [
              "Particulars",
              "From Date",
              "To date",
              "Billable Hours",
              "Current PO Rate",
              "Amount",
            ]
          : [
              "Particulars",
              "From Date",
              "To date",
              "Billable Hours",
              "Current PO Rate",
              "Amount",
            ];

      // Generate the PDF table
      doc.autoTable({
        head: [headerLabels],

        body: tableData,
        startY: billedToContainerY + billedToContainerHeight + 0,
        theme: "grid",
        styles: {
          fontSize: 9,
          lineWidth: 0.5,
          lineColor: [0, 0, 0],
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          marginBottom: 0,
        },
        columnStyles: {
          [amountColumnIndex]: { columnWidth: 25, halign: "right" }, // Set width for the "Amount" column
        },
      });

      // Calculate the height of the first table (invoice data table)
      const firstTableHeight = doc.previousAutoTable
        ? doc.previousAutoTable.finalY
        : 0;

      const {
        totalPresentyAmount,
        grandTotalAmount,
        totalTaxAmt,
        totalInWordAmount,
      } = invoiceData.invoiceGstDetailsUI;
      const { cgstAmount, sgstAmount, igstAmount } =
        invoiceData.invoiceGstDetailsUI;

      const InvoiceData = [
        ["A] Total", totalPresentyAmount],
        ["B] CGST (1)", ` ${cgstAmount}`],
        ["C] SGST (2)", ` ${sgstAmount}`],
        ["D] IGST (3)", ` ${igstAmount}`],
        ["E] Total (A+B+C+D)", grandTotalAmount],
        ["F] Deduction ", 0],
        ["G] Grand Total (F-G)", grandTotalAmount],
        [{ content: " Total In Words: " + totalInWordAmount, colSpan: 2 }],
      ];

      // Set startY for the second table immediately after the first table
      doc.autoTable({
        body: InvoiceData,
        startY: firstTableHeight + 0,
        theme: "grid",
        styles: {
          lineWidth: 0.5,
          lineColor: [0, 0, 0],
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          marginBottom: 0,
        },
        columnStyles: {
          0: { columnWidth: 157 },
          1: { columnWidth: 25, halign: "right" },
        },
      });

      // Calculate the height of the second table (invoice data table)
      const secondTableHeight = doc.previousAutoTable
        ? doc.previousAutoTable.finalY
        : 0;

      // Calculate remaining space on the page
      const remainingSpace =
        doc.internal.pageSize.getHeight() - secondTableHeight - 20;

      // Check if there is enough space for the bank table
      if (remainingSpace < 0) {
        doc.addPage();
      }
      let finalY = doc.internal.pageSize.height;

      if (doc.previousAutoTable && doc.previousAutoTable.finalY) {
        finalY = doc.previousAutoTable.finalY + 5; // Add some padding
      }

      const innerRectHeight = 60; // Set a fixed height for the inner rectangle
      const remainingSpace2 = doc.internal.pageSize.height - finalY;

      // Check if the inner rectangle can fit in the remaining space
      if (remainingSpace2 < innerRectHeight) {
        doc.addPage();
        finalY = 15; // Reset Y position for new page
      }

      // Draw the encompassing border at the end
      doc.setDrawColor(0); // Set border color to black
      doc.setLineWidth(0.4); // Set border width
      //draw Big border for the pdf page
      doc.rect(
        3,
        4,
        doc.internal.pageSize.width - 6,
        finalY > doc.internal.pageSize.height - 8
          ? finalY
          : doc.internal.pageSize.height - 8
      );

      // const innerRectX = 20;
      // const innerRectY = finalY + 0; // Start just after the previous table
      // const innerRectWidth = doc.internal.pageSize.width - 30;
      const innerRectX = 14;
      const innerRectY = finalY - 5; // Start just after the previous table
      const innerRectWidth = 182;
      // Draw the inner rectangle
      doc.rect(innerRectX, innerRectY, innerRectWidth, innerRectHeight);

      // Draw the vertical line to split the inner rectangle
      const splitLineX = innerRectX + innerRectWidth / 2;
      doc.line(
        splitLineX,
        innerRectY,
        splitLineX,
        innerRectY + innerRectHeight
      );

      // Add bank details to the left section
      const bankDetailsArray = [
        `Bank Name: ${invoiceData.bankUI.bankName}`,
        `Bank Address: ${invoiceData.bankUI.bankAddress}`,
        `A/C No.: ${invoiceData.bankUI.accountNumber}`,
        `IFC Code: ${invoiceData.bankUI.ifsc}`,
        `Swift Code: ${invoiceData.bankUI.swiftCode}`,
      ];

      const maxTextWidth = innerRectWidth / 2 - 10; // Maximum width for the text in the left section
      let leftTextY = innerRectY + 10; // Initial Y position for the left text

      const lineHeight2 = 7; // Increase line height for more margin

      bankDetailsArray.forEach((line) => {
        const lines = doc.splitTextToSize(line, maxTextWidth);
        lines.forEach((wrappedLine) => {
          doc.text(wrappedLine, innerRectX + 5, leftTextY);
          leftTextY += lineHeight2; // Adjust the line height for more margin
        });
      });

      // Add text to the right section
      const rightText1 = "For Gsquare Techsystem PVT LTD";
      const rightText2 = "Authorized Signatory & Seal";
      const rightText1Width = doc.getTextWidth(rightText1);
      const rightText2Width = doc.getTextWidth(rightText2);

      const rightTextX1 =
        splitLineX + (innerRectWidth / 2 - rightText1Width) / 2;
      const rightTextX2 =
        splitLineX + (innerRectWidth / 2 - rightText2Width) / 2;

      const gapBetweenLines = 30; // Set the gap between the lines here

      doc.text(rightText1, rightTextX1, innerRectY + 10);
      doc.text(rightText2, rightTextX2, innerRectY + 10 + gapBetweenLines); // Adjust the Y position for the second line

      const pdfData = doc.output("blob"); // Assuming doc.output provides Blob object
      const pdfWindow = window.open("", "_blank");
      const pdfURL = URL.createObjectURL(pdfData);
      pdfWindow.location.href = pdfURL;
    }
  };

  const generateProjectPDFPerHour = async (invoiceData) => {
    const doc = new jsPDF();

    const includePO = await Swal.fire({
      title: "Do you want to include PO number in the PDF?",
      icon: "question",
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: "No",
    }).then((result) => {
      return result.isConfirmed;
    });

    const encompassingRectX = 3;
    const encompassingRectY = 4;
    const encompassingRectWidth = doc.internal.pageSize.width - 6;
    const encompassingRectHeight = doc.internal.pageSize.height - 8;

    doc.setDrawColor(0); // Set border color to black
    doc.setLineWidth(0.4); // Set border width
    doc.rect(
      encompassingRectX,
      encompassingRectY,
      encompassingRectWidth,
      encompassingRectHeight
    );

    let companyInfoText =
      `${companyProfile.companyName}\n` +
      `${companyProfile.companyAddress}\n` +
      `${companyProfile.website}\n` +
      `${companyProfile.eMail}`;

    // Add the company information to the PDF for the current invoice
    doc.setFontSize(10);
    doc.setTextColor(0, 0, 0); // Black color
    doc.setFont("helvetica", "bold"); // Set font to bold
    doc.text(companyInfoText, 15, 30);
    doc.setFont("helvetica", "normal"); // Reset font to normal

    // Continue with the rest of your PDF generation logic...

    const companyInfoContainerX = 14;
    const companyInfoContainerY = 10;
    const companyInfoContainerWidth =
      doc.internal.pageSize.width * 0.55 - companyInfoContainerX - 10;
    const companyInfoContainerHeight = 40;

    // Draw the container first
    doc.setDrawColor(0);
    doc.setLineWidth(0.4);
    doc.setFillColor(255, 255, 255);
    doc.rect(
      companyInfoContainerX,
      companyInfoContainerY,
      companyInfoContainerWidth,
      companyInfoContainerHeight,
      "FD"
    );

    // Add the logo with additional margin from the left border
    doc.addImage(Logo, "JPEG", 15, 11, 28, 28); // Change x coordinate (e.g., 25 or 30)

    // Position the company information text with reduced margin after the logo
    doc.setFontSize(10);
    doc.setTextColor(0, 0, 0);
    doc.text(companyInfoText, 25 + 20 + 2, 18, {
      // Change margin after logo width (e.g., 2 or 3)
      maxWidth: doc.internal.pageSize.width * 0.55 - 25 - 20 - 15, // Calculate available width minus adjusted logo position and margins
    });

    const CompInvo = [
      ["Invoice No: " + invoiceData.invoiceRefNo],
      ["Company's GST NO.: " + companyProfile.gstin], // Include GSTIN directly
      ["Company's PAN No.: " + companyProfile.pan], // Include PAN directly
      ["Company's CIN: " + companyProfile.cin], // Include CIN directly
      ["Invoice Date: " + invoiceData.invoiceDate],
    ];

    const tableWidth = doc.internal.pageSize.width * 0.45;
    const leftMargin = doc.internal.pageSize.width - tableWidth - 10;

    // Calculate the height of the CompInvo table
    const compInvoTableHeight = doc.autoTable({
      body: CompInvo,
      theme: "grid",
      margin: { top: 10, left: leftMargin, right: 14, bottom: 0 },
      styles: {
        fontSize: 10,
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        cellPadding: 2,
        marginBottom: 0,
        marginTop: 0,
        padding: 0,
      },
    }).lastAutoTable.finalY;

    const ClientInvo = [
      ["Service ACC Code: " + gstDetails.hsnsac],
      ["Client's GSTIN: " + gstDetails.gstin],
      ["Payment Term: 15Days Bank Transfer"], // No need to concatenate, as it's a single value
      ["Billing Period: " + clientDetails.billingPeriod],
      ["Onsite Manpower Support for the month"], // No need to concatenate, as it's a single value
    ];

    const clientInvoTableHeight = 40;

    // Set the startY parameter for ClientInvo table to start immediately after CompInvo table
    doc.autoTable({
      body: ClientInvo,
      theme: "grid",
      columnStyles: { 0: { cellWidth: "auto" } },
      startY: compInvoTableHeight, // Set startY explicitly
      margin: {
        top: compInvoTableHeight,
        left: leftMargin,
        right: 14,
        bottom: 0,
      },
      styles: {
        fontSize: 10,
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        marginBottom: 0,
        tableHeight: 40,
      },
    });

    const billedToContainerHeight = 38.85;
    const billedToTextLines = [
      `Billed To: ${clientDetails.clientName}`,
      clientDetails.clientAddress,
      "",
      `Place of supply: ${gstDetails.state} State Code: ${gstDetails.stateCode}`,
      `Ph: ${clientDetails.clientContactNumber}`,
      `Kind Attn: ${invoiceData.invoicePaymentModeUI.kindAttention || ""}`,
    ];
    const billedToContainerX = companyInfoContainerX;
    const topMargin = 0;
    const containerHeight = 40; // Set the common height for both the billedToContainer and the table
    const billedToContainerY =
      companyInfoContainerY + companyInfoContainerHeight + topMargin;
    const billedToContainerWidth = companyInfoContainerWidth;

    doc.setDrawColor(0);
    doc.setLineWidth(0.4);
    doc.setFillColor(255, 255, 255);
    doc.rect(
      billedToContainerX,
      billedToContainerY,
      billedToContainerWidth,
      containerHeight,
      "FD"
    );

    doc.setFontSize(10);
    doc.setTextColor(0, 0, 0);

    const lineHeight = 5;
    let yPosition = billedToContainerY + 10;

    billedToTextLines.forEach((line) => {
      doc.text(line.trim(), billedToContainerX + 5, yPosition, {
        maxWidth: billedToContainerWidth - 10,
      });
      yPosition += lineHeight;
    });

    doc.setFillColor(255, 255, 255);

    let tableData;
    let amountColumnIndex;

    if (includePO) {
      // Include PO number column in the PDF
      tableData = invoiceData.invoiceProjectPoDetailsUIList.map((row) => [
        row.projectName,
        row.projectPoNumber,
        row.projectFromDate.split("-").reverse().join("-"),
        row.projectToDate.split("-").reverse().join("-"),

        row.ratePerDays,
        row.monthWorkDays,
        row.projectWorkAmount,
      ]);
      amountColumnIndex = 6;
    }

    const headerLabels = includePO
      ? [
          "Particulars",
          "PO NO.",
          "From Date",
          "To date",
          "Billable Hours",
          "Unit Price",
          "Amount",
        ]
      : [
          "Particulars",
          "From Date",
          "To date",
          "Billable Hours",
          "Unit Price",
          "Amount",
        ];

    doc.autoTable({
      head: [headerLabels],
      body: tableData,
      startY: billedToContainerY + billedToContainerHeight + 0,
      theme: "grid",
      styles: {
        fontSize: 9,
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        marginBottom: 0,
      },

      columnStyles: {
        7: { columnWidth: 25, halign: "right" },
      },
    });
    // Calculate the height of the first table (invoice data table)
    const firstTableHeight = doc.previousAutoTable
      ? doc.previousAutoTable.finalY
      : 0;

    const {
      totalPresentyAmount,
      grandTotalAmount,
      totalTaxAmt,
      totalInWordAmount,
    } = invoiceData.invoiceGstDetailsUI;
    const { cgstAmount, sgstAmount, igstAmount } =
      invoiceData.invoiceGstDetailsUI;

    const InvoiceData = [
      ["A] Total", totalPresentyAmount],
      ["B] CGST (1)", ` ${cgstAmount}`],
      ["C] SGST (2)", ` ${sgstAmount}`],
      ["D] IGST (3)", ` ${igstAmount}`],
      ["E] Total (A+B+C+D)", grandTotalAmount],
      ["F] Deduction ", 0],
      ["G] Grand Total (F-G)", grandTotalAmount],
      [{ content: " Total In Words: " + totalInWordAmount, colSpan: 2 }],
    ];

    // Set startY for the second table immediately after the first table
    doc.autoTable({
      body: InvoiceData,
      startY: firstTableHeight + 0,
      theme: "grid",
      styles: {
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        marginBottom: 0,
      },
      columnStyles: {
        0: { columnWidth: 157 },
        1: { columnWidth: 25, halign: "right" },
      },
    });
    // Calculate the height of the second table (invoice data table)
    const secondTableHeight = doc.previousAutoTable
      ? doc.previousAutoTable.finalY
      : 0;

    // Calculate remaining space on the page
    const remainingSpace =
      doc.internal.pageSize.getHeight() - secondTableHeight - 20;

    // Check if there is enough space for the bank table
    if (remainingSpace < 0) {
      doc.addPage();
    }
    let finalY = doc.internal.pageSize.height;

    if (doc.previousAutoTable && doc.previousAutoTable.finalY) {
      finalY = doc.previousAutoTable.finalY + 5; // Add some padding
    }

    const innerRectHeight = 60; // Set a fixed height for the inner rectangle
    const remainingSpace2 = doc.internal.pageSize.height - finalY;

    // Check if the inner rectangle can fit in the remaining space
    if (remainingSpace2 < innerRectHeight) {
      doc.addPage();
      finalY = 15; // Reset Y position for new page
    }

    // Draw the encompassing border at the end
    doc.setDrawColor(0); // Set border color to black
    doc.setLineWidth(0.4); // Set border width
    //draw Big border for the pdf page
    doc.rect(
      3,
      4,
      doc.internal.pageSize.width - 6,
      finalY > doc.internal.pageSize.height - 8
        ? finalY
        : doc.internal.pageSize.height - 8
    );

    // const innerRectX = 20;
    // const innerRectY = finalY + 0; // Start just after the previous table
    // const innerRectWidth = doc.internal.pageSize.width - 30;
    const innerRectX = 14;
    const innerRectY = finalY - 5; // Start just after the previous table
    const innerRectWidth = 182;
    // Draw the inner rectangle
    doc.rect(innerRectX, innerRectY, innerRectWidth, innerRectHeight);

    // Draw the vertical line to split the inner rectangle
    const splitLineX = innerRectX + innerRectWidth / 2;
    doc.line(splitLineX, innerRectY, splitLineX, innerRectY + innerRectHeight);

    // Add bank details to the left section
    const bankDetailsArray = [
      `Bank Name: ${invoiceData.bankUI.bankName}`,
      `Bank Address: ${invoiceData.bankUI.bankAddress}`,
      `A/C No.: ${invoiceData.bankUI.accountNumber}`,
      `IFC Code: ${invoiceData.bankUI.ifsc}`,
      `Swift Code: ${invoiceData.bankUI.swiftCode}`,
    ];

    const maxTextWidth = innerRectWidth / 2 - 10; // Maximum width for the text in the left section
    let leftTextY = innerRectY + 10; // Initial Y position for the left text

    const lineHeight2 = 7; // Increase line height for more margin

    bankDetailsArray.forEach((line) => {
      const lines = doc.splitTextToSize(line, maxTextWidth);
      lines.forEach((wrappedLine) => {
        doc.text(wrappedLine, innerRectX + 5, leftTextY);
        leftTextY += lineHeight2; // Adjust the line height for more margin
      });
    });

    // Add text to the right section
    const rightText1 = "For Gsquare Techsystem PVT LTD";
    const rightText2 = "Authorized Signatory & Seal";
    const rightText1Width = doc.getTextWidth(rightText1);
    const rightText2Width = doc.getTextWidth(rightText2);

    const rightTextX1 = splitLineX + (innerRectWidth / 2 - rightText1Width) / 2;
    const rightTextX2 = splitLineX + (innerRectWidth / 2 - rightText2Width) / 2;

    const gapBetweenLines = 30; // Set the gap between the lines here

    doc.text(rightText1, rightTextX1, innerRectY + 10);
    doc.text(rightText2, rightTextX2, innerRectY + 10 + gapBetweenLines); // Adjust the Y position for the second line

    const pdfData = doc.output("blob"); // Assuming doc.output provides Blob object
    const pdfWindow = window.open("", "_blank");
    const pdfURL = URL.createObjectURL(pdfData);
    pdfWindow.location.href = pdfURL;
  };

  const generateProjectPDFPerDay = async (invoiceData) => {
    const doc = new jsPDF();

    const includePO = await Swal.fire({
      title: "Do you want to include PO number in the PDF?",
      icon: "question",
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: "No",
    }).then((result) => {
      return result.isConfirmed;
    });

    const encompassingRectX = 3;
    const encompassingRectY = 4;
    const encompassingRectWidth = doc.internal.pageSize.width - 6;
    const encompassingRectHeight = doc.internal.pageSize.height - 8;

    doc.setDrawColor(0); // Set border color to black
    doc.setLineWidth(0.4); // Set border width
    doc.rect(
      encompassingRectX,
      encompassingRectY,
      encompassingRectWidth,
      encompassingRectHeight
    );
    let companyInfoText =
      `${companyProfile.companyName}\n` +
      `${companyProfile.companyAddress}\n` +
      `${companyProfile.website}\n` +
      `${companyProfile.eMail}`;

    // Add the company information to the PDF for the current invoice
    doc.setFontSize(10);
    doc.setTextColor(0, 0, 0); // Black color
    doc.setFont("helvetica", "bold"); // Set font to bold
    doc.text(companyInfoText, 15, 30);
    doc.setFont("helvetica", "normal"); // Reset font to normal

    // Continue with the rest of your PDF generation logic...

    const companyInfoContainerX = 14;
    const companyInfoContainerY = 10;
    const companyInfoContainerWidth =
      doc.internal.pageSize.width * 0.55 - companyInfoContainerX - 10;
    const companyInfoContainerHeight = 40;

    // Draw the container first
    doc.setDrawColor(0);
    doc.setLineWidth(0.4);
    doc.setFillColor(255, 255, 255);
    doc.rect(
      companyInfoContainerX,
      companyInfoContainerY,
      companyInfoContainerWidth,
      companyInfoContainerHeight,
      "FD"
    );

    // Add the logo with additional margin from the left border
    doc.addImage(Logo, "JPEG", 15, 11, 28, 28); // Change x coordinate (e.g., 25 or 30)

    // Position the company information text with reduced margin after the logo
    doc.setFontSize(10);
    doc.setTextColor(0, 0, 0);
    doc.text(companyInfoText, 25 + 20 + 2, 18, {
      // Change margin after logo width (e.g., 2 or 3)
      maxWidth: doc.internal.pageSize.width * 0.55 - 25 - 20 - 15, // Calculate available width minus adjusted logo position and margins
    });

    const CompInvo = [
      ["Invoice No: " + invoiceData.invoiceRefNo],
      ["Company's GST NO.: " + companyProfile.gstin], // Include GSTIN directly
      ["Company's PAN No.: " + companyProfile.pan], // Include PAN directly
      ["Company's CIN: " + companyProfile.cin], // Include CIN directly
      ["Invoice Date: " + invoiceData.invoiceDate],
    ];

    const tableWidth = doc.internal.pageSize.width * 0.45;
    const leftMargin = doc.internal.pageSize.width - tableWidth - 10;

    // Calculate the height of the CompInvo table
    const compInvoTableHeight = doc.autoTable({
      body: CompInvo,
      theme: "grid",
      margin: { top: 10, left: leftMargin, right: 14, bottom: 0 },
      styles: {
        fontSize: 10,
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        cellPadding: 2,
        marginBottom: 0,
        marginTop: 0,
        padding: 0,
      },
    }).lastAutoTable.finalY;

    const ClientInvo = [
      ["Service ACC Code: " + gstDetails.hsnsac],
      ["Client's GSTIN: " + gstDetails.gstin],
      ["Payment Term: 15Days Bank Transfer"], // No need to concatenate, as it's a single value
      ["Billing Period: " + clientDetails.billingPeriod],
      ["Onsite Manpower Support for the month"], // No need to concatenate, as it's a single value
    ];
    // Set the startY parameter for ClientInvo table to start immediately after CompInvo table
    doc.autoTable({
      body: ClientInvo,
      theme: "grid",
      columnStyles: { 0: { cellWidth: "auto" } },
      startY: compInvoTableHeight, // Set startY explicitly
      margin: {
        top: compInvoTableHeight,
        left: leftMargin,
        right: 14,
        bottom: 0,
      },
      styles: {
        fontSize: 10,
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        marginBottom: 0,
        tableHeight: 40,
      },
    });

    const billedToContainerHeight = 38.85;
    const billedToTextLines = [
      `Billed To: ${clientDetails.clientName}`,
      clientDetails.clientAddress,
      "",
      `Place of supply: ${gstDetails.state} State Code: ${gstDetails.stateCode}`,
      `Ph: ${clientDetails.clientContactNumber}`,
      `Kind Attn: ${invoiceData.invoicePaymentModeUI.kindAttention || ""}`,
    ];
    const billedToContainerX = companyInfoContainerX;
    const topMargin = 0;
    const containerHeight = 40; // Set the common height for both the billedToContainer and the table
    const billedToContainerY =
      companyInfoContainerY + companyInfoContainerHeight + topMargin;
    const billedToContainerWidth = companyInfoContainerWidth;

    doc.setDrawColor(0);
    doc.setLineWidth(0.4);
    doc.setFillColor(255, 255, 255);
    doc.rect(
      billedToContainerX,
      billedToContainerY,
      billedToContainerWidth,
      containerHeight,
      "FD"
    );

    doc.setFontSize(10);
    doc.setTextColor(0, 0, 0);

    const lineHeight = 5;
    let yPosition = billedToContainerY + 10;

    billedToTextLines.forEach((line) => {
      doc.text(line.trim(), billedToContainerX + 5, yPosition, {
        maxWidth: billedToContainerWidth - 10,
      });
      yPosition += lineHeight;
    });

    doc.setFillColor(255, 255, 255);

    let tableData;
    let amountColumnIndex;

    if (includePO) {
      // Include PO number column in the PDF
      tableData = invoiceData.invoiceProjectPoDetailsUIList.map((row) => [
        row.projectName,
        row.projectPoNumber,
        row.projectFromDate.split("-").reverse().join("-"),
        row.projectToDate.split("-").reverse().join("-"),

        row.ratePerDays,
        row.monthWorkDays,
        row.projectWorkAmount,
      ]);
      amountColumnIndex = 6;
    }

    const headerLabels = includePO
      ? [
          "Particulars",
          "PO NO.",
          "From Date",
          "To date",
          "Billable Days",
          "Unit Price",
          "Amount",
        ]
      : [
          "Particulars",
          "From Date",
          "To date",
          "Billable Days",
          "Unit Price",
          "Amount",
        ];

    doc.autoTable({
      head: [headerLabels],
      body: tableData,
      startY: billedToContainerY + billedToContainerHeight + 0,
      theme: "grid",
      styles: {
        fontSize: 9,
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        marginBottom: 0,
      },

      columnStyles: {
        7: { columnWidth: 25, halign: "right" },
      },
    });
    // Calculate the height of the first table (invoice data table)
    const firstTableHeight = doc.previousAutoTable
      ? doc.previousAutoTable.finalY
      : 0;

    const {
      totalPresentyAmount,
      grandTotalAmount,
      totalTaxAmt,
      totalInWordAmount,
    } = invoiceData.invoiceGstDetailsUI;
    const { cgstAmount, sgstAmount, igstAmount } =
      invoiceData.invoiceGstDetailsUI;

    const InvoiceData = [
      ["A] Total", totalPresentyAmount],
      ["B] CGST (1)", ` ${cgstAmount}`],
      ["C] SGST (2)", ` ${sgstAmount}`],
      ["D] IGST (3)", ` ${igstAmount}`],
      ["E] Total (A+B+C+D)", grandTotalAmount],
      ["F] Deduction ", 0],
      ["G] Grand Total (F-G)", grandTotalAmount],
      [{ content: " Total In Words: " + totalInWordAmount, colSpan: 2 }],
    ];

    // Set startY for the second table immediately after the first table
    doc.autoTable({
      body: InvoiceData,
      startY: firstTableHeight + 0,
      theme: "grid",
      styles: {
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        marginBottom: 0,
      },
      columnStyles: {
        0: { columnWidth: 157 },
        1: { columnWidth: 25, halign: "right" },
      },
    });

    // Calculate the height of the second table (invoice data table)
    const secondTableHeight = doc.previousAutoTable
      ? doc.previousAutoTable.finalY
      : 0;

    // Calculate remaining space on the page
    const remainingSpace =
      doc.internal.pageSize.getHeight() - secondTableHeight - 20;

    // Check if there is enough space for the bank table
    if (remainingSpace < 0) {
      doc.addPage();
    }
    let finalY = doc.internal.pageSize.height;

    if (doc.previousAutoTable && doc.previousAutoTable.finalY) {
      finalY = doc.previousAutoTable.finalY + 5; // Add some padding
    }

    const innerRectHeight = 60; // Set a fixed height for the inner rectangle
    const remainingSpace2 = doc.internal.pageSize.height - finalY;

    // Check if the inner rectangle can fit in the remaining space
    if (remainingSpace2 < innerRectHeight) {
      doc.addPage();
      finalY = 15; // Reset Y position for new page
    }

    // Draw the encompassing border at the end
    doc.setDrawColor(0); // Set border color to black
    doc.setLineWidth(0.4); // Set border width
    //draw Big border for the pdf page
    doc.rect(
      3,
      4,
      doc.internal.pageSize.width - 6,
      finalY > doc.internal.pageSize.height - 8
        ? finalY
        : doc.internal.pageSize.height - 8
    );

    const innerRectX = 14;
    const innerRectY = finalY - 5; // Start just after the previous table
    const innerRectWidth = 182;
    // Draw the inner rectangle
    doc.rect(innerRectX, innerRectY, innerRectWidth, innerRectHeight);

    // Draw the vertical line to split the inner rectangle
    const splitLineX = innerRectX + innerRectWidth / 2;
    doc.line(splitLineX, innerRectY, splitLineX, innerRectY + innerRectHeight);

    // Add bank details to the left section
    const bankDetailsArray = [
      `Bank Name: ${invoiceData.bankUI.bankName}`,
      `Bank Address: ${invoiceData.bankUI.bankAddress}`,
      `A/C No.: ${invoiceData.bankUI.accountNumber}`,
      `IFC Code: ${invoiceData.bankUI.ifsc}`,
      `Swift Code: ${invoiceData.bankUI.swiftCode}`,
    ];

    const maxTextWidth = innerRectWidth / 2 - 10; // Maximum width for the text in the left section
    let leftTextY = innerRectY + 10; // Initial Y position for the left text

    const lineHeight2 = 7; // Increase line height for more margin

    bankDetailsArray.forEach((line) => {
      const lines = doc.splitTextToSize(line, maxTextWidth);
      lines.forEach((wrappedLine) => {
        doc.text(wrappedLine, innerRectX + 5, leftTextY);
        leftTextY += lineHeight2; // Adjust the line height for more margin
      });
    });

    // Add text to the right section
    const rightText1 = "For Gsquare Techsystem PVT LTD";
    const rightText2 = "Authorized Signatory & Seal";
    const rightText1Width = doc.getTextWidth(rightText1);
    const rightText2Width = doc.getTextWidth(rightText2);

    const rightTextX1 = splitLineX + (innerRectWidth / 2 - rightText1Width) / 2;
    const rightTextX2 = splitLineX + (innerRectWidth / 2 - rightText2Width) / 2;

    const gapBetweenLines = 30; // Set the gap between the lines here

    doc.text(rightText1, rightTextX1, innerRectY + 10);
    doc.text(rightText2, rightTextX2, innerRectY + 10 + gapBetweenLines); // Adjust the Y position for the second line

    const pdfData = doc.output("blob"); // Assuming doc.output provides Blob object
    const pdfWindow = window.open("", "_blank");
    const pdfURL = URL.createObjectURL(pdfData);
    pdfWindow.location.href = pdfURL;
  };

  const generateOtherChargesPDF = async (invoiceData) => {
    const doc = new jsPDF();
    const encompassingRectX = 3;
    const encompassingRectY = 4;
    const encompassingRectWidth = doc.internal.pageSize.width - 6;
    const encompassingRectHeight = doc.internal.pageSize.height - 8;

    doc.setDrawColor(0); // Set border color to black
    doc.setLineWidth(0.4); // Set border width
    doc.rect(
      encompassingRectX,
      encompassingRectY,
      encompassingRectWidth,
      encompassingRectHeight
    );
    let companyInfoText =
      `${companyProfile.companyName}\n` +
      `${companyProfile.companyAddress}\n` +
      `${companyProfile.website}\n` +
      `${companyProfile.eMail}`;

    // Add the company information to the PDF for the current invoice
    doc.setFontSize(10);
    doc.setTextColor(0, 0, 0); // Black color
    doc.setFont("helvetica", "bold"); // Set font to bold
    doc.text(companyInfoText, 15, 30);
    doc.setFont("helvetica", "normal"); // Reset font to normal

    // Continue with the rest of your PDF generation logic...

    const companyInfoContainerX = 14;
    const companyInfoContainerY = 10;
    const companyInfoContainerWidth =
      doc.internal.pageSize.width * 0.55 - companyInfoContainerX - 10;
    const companyInfoContainerHeight = 40;

    // Draw the container first
    doc.setDrawColor(0);
    doc.setLineWidth(0.4);
    doc.setFillColor(255, 255, 255);
    doc.rect(
      companyInfoContainerX,
      companyInfoContainerY,
      companyInfoContainerWidth,
      companyInfoContainerHeight,
      "FD"
    );

    // Add the logo with additional margin from the left border
    doc.addImage(Logo, "JPEG", 15, 11, 28, 28); // Change x coordinate (e.g., 25 or 30)

    // Position the company information text with reduced margin after the logo
    doc.setFontSize(10);
    doc.setTextColor(0, 0, 0);
    doc.text(companyInfoText, 25 + 20 + 2, 18, {
      // Change margin after logo width (e.g., 2 or 3)
      maxWidth: doc.internal.pageSize.width * 0.55 - 25 - 20 - 15, // Calculate available width minus adjusted logo position and margins
    });
    const CompInvo = [
      ["Invoice No: " + invoiceData.invoiceRefNo],
      ["Company's GST NO.: " + companyProfile.gstin], // Include GSTIN directly
      ["Company's PAN No.: " + companyProfile.pan], // Include PAN directly
      ["Company's CIN: " + companyProfile.cin], // Include CIN directly
      ["Invoice Date: " + invoiceData.invoiceDate],
    ];

    const tableWidth = doc.internal.pageSize.width * 0.45;
    const leftMargin = doc.internal.pageSize.width - tableWidth - 10;

    // Calculate the height of the CompInvo table
    const compInvoTableHeight = doc.autoTable({
      body: CompInvo,
      theme: "grid",
      margin: { top: 10, left: leftMargin, right: 14, bottom: 0 },
      styles: {
        fontSize: 10,
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        cellPadding: 2,
        marginBottom: 0,
        marginTop: 0,
        padding: 0,
      },
    }).lastAutoTable.finalY;

    const ClientInvo = [
      ["Service ACC Code: " + gstDetails.hsnsac],
      ["Client's GSTIN: " + gstDetails.gstin],
      ["Payment Term: 15Days Bank Transfer"], // No need to concatenate, as it's a single value
      ["Billing Period: " + clientDetails.billingPeriod],
      ["Onsite Manpower Support for the month"], // No need to concatenate, as it's a single value
    ];
    // Set the startY parameter for ClientInvo table to start immediately after CompInvo table
    doc.autoTable({
      body: ClientInvo,
      theme: "grid",
      columnStyles: { 0: { cellWidth: "auto" } },
      startY: compInvoTableHeight, // Set startY explicitly
      margin: {
        top: compInvoTableHeight,
        left: leftMargin,
        right: 14,
        bottom: 0,
      },
      styles: {
        fontSize: 10,
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        marginBottom: 0,
        tableHeight: 40,
      },
    });

    const billedToContainerHeight = 38.85;
    const billedToTextLines = [
      `Billed To: ${clientDetails.clientName}`,
      clientDetails.clientAddress,
      "",
      `Place of supply: ${gstDetails.state} State Code: ${gstDetails.stateCode}`,
      `Ph: ${clientDetails.clientContactNumber}`,
      `Kind Attn: ${invoiceData.invoicePaymentModeUI.kindAttention || ""}`,
    ];
    const billedToContainerX = companyInfoContainerX;
    const topMargin = 0;
    const containerHeight = 40; // Set the common height for both the billedToContainer and the table
    const billedToContainerY =
      companyInfoContainerY + companyInfoContainerHeight + topMargin;
    const billedToContainerWidth = companyInfoContainerWidth;

    doc.setDrawColor(0);
    doc.setLineWidth(0.4);
    doc.setFillColor(255, 255, 255);
    doc.rect(
      billedToContainerX,
      billedToContainerY,
      billedToContainerWidth,
      containerHeight,
      "FD"
    );

    doc.setFontSize(10);
    doc.setTextColor(0, 0, 0);

    const lineHeight = 5;
    let yPosition = billedToContainerY + 10;

    billedToTextLines.forEach((line) => {
      doc.text(line.trim(), billedToContainerX + 5, yPosition, {
        maxWidth: billedToContainerWidth - 10,
      });
      yPosition += lineHeight;
    });

    doc.setFillColor(255, 255, 255);
    const tableData = invoiceData.invoiceOtherChargesDetailsUIList.map(
      (row) => [
        row.employeeNameAndCode, // replace with the actual property names from otherChargesList
        row.employeeRole,
        row.description,
        row.ctcAmount,
      ]
    );

    doc.autoTable({
      head: [["Employee Name", "Employee Role", "Description", "Amount"]],
      body: tableData,
      startY: billedToContainerY + billedToContainerHeight + 0,
      theme: "grid",
      styles: {
        fontSize: 9,
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        marginBottom: 0,
      },
      columnStyles: {
        3: { columnWidth: 35, halign: "right" },
      },
    });
    // Calculate the height of the first table (invoice data table)
    const firstTableHeight = doc.previousAutoTable
      ? doc.previousAutoTable.finalY
      : 0;

    const {
      totalPresentyAmount,
      grandTotalAmount,
      totalTaxAmt,
      totalInWordAmount,
    } = invoiceData.invoiceGstDetailsUI;
    const { cgst, sgst, igst } = invoiceData.invoiceGstDetailsUI;

    const InvoiceData = [
      ["A] Total", totalPresentyAmount],
      ["B] CGST (1)", ` ${(cgst / 100) * totalPresentyAmount}`],
      ["C] SGST (2)", ` ${(sgst / 100) * totalPresentyAmount}`],
      ["D] IGST (3)", ` ${(igst / 100) * totalPresentyAmount}`],
      ["E] Total (A+B+C+D)", grandTotalAmount],
      ["F] Deduction ", 0],
      ["G] Grand Total (F-G)", grandTotalAmount],
      [{ content: " Total In Words: " + totalInWordAmount, colSpan: 2 }],
    ];

    // Set startY for the second table immediately after the first table
    doc.autoTable({
      body: InvoiceData,
      startY: firstTableHeight + 0,
      theme: "grid",
      styles: {
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        marginBottom: 0,
      },
      columnStyles: {
        0: { columnWidth: 147 },
        1: { columnWidth: 35, halign: "right" },
      },
    });

    // Calculate the height of the second table (invoice data table)
    const secondTableHeight = doc.previousAutoTable
      ? doc.previousAutoTable.finalY
      : 0;

    // Calculate remaining space on the page
    const remainingSpace =
      doc.internal.pageSize.getHeight() - secondTableHeight - 20;

    // Check if there is enough space for the bank table
    if (remainingSpace < 0) {
      doc.addPage();
    }
    let finalY = doc.internal.pageSize.height;

    if (doc.previousAutoTable && doc.previousAutoTable.finalY) {
      finalY = doc.previousAutoTable.finalY + 5; // Add some padding
    }

    const innerRectHeight = 60; // Set a fixed height for the inner rectangle
    const remainingSpace2 = doc.internal.pageSize.height - finalY;

    // Check if the inner rectangle can fit in the remaining space
    if (remainingSpace2 < innerRectHeight) {
      doc.addPage();
      finalY = 15; // Reset Y position for new page
    }

    // Draw the encompassing border at the end
    doc.setDrawColor(0); // Set border color to black
    doc.setLineWidth(0.4); // Set border width
    //draw Big border for the pdf page
    doc.rect(
      3,
      4,
      doc.internal.pageSize.width - 6,
      finalY > doc.internal.pageSize.height - 8
        ? finalY
        : doc.internal.pageSize.height - 8
    );

    const innerRectX = 14;
    const innerRectY = finalY - 5; // Start just after the previous table
    const innerRectWidth = 182;
    // Draw the inner rectangle
    doc.rect(innerRectX, innerRectY, innerRectWidth, innerRectHeight);

    // Draw the vertical line to split the inner rectangle
    const splitLineX = innerRectX + innerRectWidth / 2;
    doc.line(splitLineX, innerRectY, splitLineX, innerRectY + innerRectHeight);

    // Add bank details to the left section
    const bankDetailsArray = [
      `Bank Name: ${invoiceData.bankUI.bankName}`,
      `Bank Address: ${invoiceData.bankUI.bankAddress}`,
      `A/C No.: ${invoiceData.bankUI.accountNumber}`,
      `IFC Code: ${invoiceData.bankUI.ifsc}`,
      `Swift Code: ${invoiceData.bankUI.swiftCode}`,
    ];

    const maxTextWidth = innerRectWidth / 2 - 10; // Maximum width for the text in the left section
    let leftTextY = innerRectY + 10; // Initial Y position for the left text

    const lineHeight2 = 7; // Increase line height for more margin

    bankDetailsArray.forEach((line) => {
      const lines = doc.splitTextToSize(line, maxTextWidth);
      lines.forEach((wrappedLine) => {
        doc.text(wrappedLine, innerRectX + 5, leftTextY);
        leftTextY += lineHeight2; // Adjust the line height for more margin
      });
    });

    // Add text to the right section
    const rightText1 = "For Gsquare Techsystem PVT LTD";
    const rightText2 = "Authorized Signatory & Seal";
    const rightText1Width = doc.getTextWidth(rightText1);
    const rightText2Width = doc.getTextWidth(rightText2);

    const rightTextX1 = splitLineX + (innerRectWidth / 2 - rightText1Width) / 2;
    const rightTextX2 = splitLineX + (innerRectWidth / 2 - rightText2Width) / 2;

    const gapBetweenLines = 30; // Set the gap between the lines here

    doc.text(rightText1, rightTextX1, innerRectY + 10);
    doc.text(rightText2, rightTextX2, innerRectY + 10 + gapBetweenLines); // Adjust the Y position for the second line

    const pdfData = doc.output("blob"); // Assuming doc.output provides Blob object
    const pdfWindow = window.open("", "_blank");
    const pdfURL = URL.createObjectURL(pdfData);
    pdfWindow.location.href = pdfURL;
  };

  return (
    <Container maxWidth="lg">
      {loadingOverlay && (
        <div className="loading-overlay">
          <CircularProgress size={60} />
          <div className="loading-message">Getting Data...</div>
        </div>
      )}
      {loadingSubmittingOverlay && (
        <div className="loading-overlay">
          <CircularProgress size={60} />
          <div className="loading-message">Submitting...</div>
        </div>
      )}
      {loading && (
        <div className="loading-overlay">
          <CircularProgress size={60} />
          <div className="loading-message">
            Getting Amount And InvoiceRef...
          </div>
        </div>
      )}
      {loadingInvoiceRefDetails && (
        <div className="loading-overlay">
          <CircularProgress size={60} />
          <div className="loading-message">Getting Invoice Ref Details...</div>
        </div>
      )}

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Card style={{ marginTop: "20px" }}>
            <CardContent>
              <Paper
                elevation={3}
                style={{
                  padding: 20,
                  marginBottom: 20,
                  marginTop: 20,
                  backgroundColor: "#2196f3",
                  color: "white",
                  padding: "10px",
                  textAlign: "center",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    variant="h5"
                    gutterBottom
                    style={{ marginBottom: "15px", color: "darkblue" }}
                  >
                    Invoice Details
                  </Typography>
                </div>
              </Paper>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Autocomplete
                    options={companyCodes}
                    value={formData.selectCompany}
                    onChange={(event, newValue) => {
                      setFormData((prevData) => ({
                        ...prevData,
                        selectCompany: newValue,
                      }));
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Company"
                        variant="standard"
                        margin="normal"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Total Out Standing Amount"
                    fullWidth
                    variant="standard"
                    name="totalOutStandingAmount"
                    onChange={handleChange}
                    disabled
                    value={formData.totalOutStandingAmount}
                    required
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={6}>
                  <Autocomplete
                    options={financialYears}
                    onChange={(event, newValue) => {
                      handleChange({
                        target: {
                          name: "financialYear",
                          value: newValue || "",
                        },
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Financial Year"
                        variant="standard"
                        name="financialYear"
                        required
                        margin="normal"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Autocomplete
                    options={clientNames}
                    onChange={(event, newValue) => {
                      handleChange({
                        target: {
                          name: "clientNameAndCode",
                          value: newValue || "",
                        },
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Client Name And Code"
                        variant="standard"
                        name="clientNameAndCode"
                        required
                        margin="normal"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Autocomplete
                    options={invoiceRefNos}
                    getOptionLabel={(option) => option.label}
                    onChange={(event, newValue) => {
                      handleChange({
                        target: {
                          name: "invoiceRefNo",
                          value: newValue ? newValue.value : "",
                        },
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Invoice Ref No"
                        variant="standard"
                        name="invoiceRefNo"
                        required
                        margin="normal"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Outstanding Amount"
                    fullWidth
                    variant="standard"
                    name="outStandingAmount"
                    disabled
                    onChange={handleChange}
                    value={formData.outStandingAmount}
                    required
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Pay Date"
                    type="date"
                    fullWidth
                    variant="standard"
                    name="payDate"
                    onChange={handleChange}
                    value={formData.payDate}
                    required
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Paid Amount"
                    fullWidth
                    variant="standard"
                    name="paidAmount"
                    onChange={handleChange}
                    value={formData.paidAmount}
                    required
                    margin="normal"
                    type="number"
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    label="TDS Amount"
                    fullWidth
                    variant="standard"
                    name="tdsAmount"
                    onChange={handleChange}
                    value={formData.tdsAmount}
                    margin="normal"
                    type="number"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Deduction Amount"
                    fullWidth
                    variant="standard"
                    name="deductionAmount"
                    onChange={handleChange}
                    value={formData.deductionAmount}
                    margin="normal"
                    type="number"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Deduction Remark"
                    fullWidth
                    variant="standard"
                    name="deductionRemark"
                    onChange={handleChange}
                    value={formData.deductionRemark}
                    margin="normal"
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    label="UnPaid Amount"
                    fullWidth
                    variant="standard"
                    name="unPaidAmount"
                    //   onChange={handleChange}
                    disabled
                    value={formData.unPaidAmount}
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={6}>
                  <Autocomplete
                    options={["Cheque", "Bank Transfer"]}
                    onChange={(event, newValue) => {
                      handleChange({
                        target: { name: "payMode", value: newValue || "" },
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Pay Mode"
                        variant="standard"
                        name="payMode"
                        required
                        margin="normal"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Autocomplete
                    options={[
                      "HR",
                      "Marketing Head",
                      "IT",
                      "Design",
                      "Management",
                      "Product Assurance",
                      "Training",
                      "CAE",
                      "Welding",
                    ]}
                    onChange={(event, newValue) => {
                      handleChange({
                        target: { name: "collectedBy", value: newValue || "" },
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Collected By"
                        variant="standard"
                        name="collectedBy"
                        required
                        margin="normal"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Collected Name"
                    fullWidth
                    variant="standard"
                    name="collectedName"
                    onChange={handleChange}
                    value={formData.collectedName}
                    margin="normal"
                  />
                </Grid>

                <Grid item xs={6}>
                  <Paper
                    elevation={3}
                    style={{ padding: "20px", marginBottom: "20px" }}
                  >
                    {["Cheque", "Bank Transfer"].includes(formData.payMode) && (
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <TextField
                            label="Cheque Number/Bank UTRNo"
                            fullWidth
                            variant="standard"
                            name="chqNumber"
                            onChange={handleChange}
                            value={formData.chqNumber}
                            required
                            margin="normal"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            label="Cheque Date"
                            fullWidth
                            type="date"
                            variant="standard"
                            name="chqDate"
                            onChange={handleChange}
                            value={formData.chqDate}
                            margin="dense"
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            label="Bank Branch"
                            fullWidth
                            variant="standard"
                            name="bankBranch"
                            margin="normal"
                            onChange={handleChange}
                            value={formData.bankBranch}
                          />
                        </Grid>
                      </Grid>
                    )}
                  </Paper>
                </Grid>
                <Grid item xs={6}>
                  <Paper
                    elevation={3}
                    style={{ padding: "20px", marginBottom: "20px" }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSubmit}
                    >
                      Submit
                    </Button>
                  </Paper>
                </Grid>
                <Grid item xs={6}>
                  <Paper
                    elevation={3}
                    style={{ padding: "20px", marginBottom: "20px" }}
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={handleExportToExcel}
                    >
                      Excel
                    </Button>
                  </Paper>
                </Grid>
                <Grid item xs={6}>
                  <Paper
                    elevation={3}
                    style={{ padding: "20px", marginBottom: "20px" }}
                  >
                    <Button
                      variant="contained"
                      onClick={() => handlegeneratePDF(formData.invoiceRefNo)} // Pass a function reference here
                      color="secondary"
                    >
                      VIEW INVOICE
                    </Button>
                  </Paper>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Card style={{ marginTop: "20px" }}>
            <CardContent>
              <Paper
                elevation={3}
                style={{
                  padding: 20,
                  marginBottom: 20,
                  marginTop: 20,
                  backgroundColor: "#2196f3",
                  color: "white",
                  padding: "10px",
                  textAlign: "center",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    variant="h5"
                    component="div"
                    gutterBottom
                    style={{ marginBottom: "15px", color: "darkblue" }}
                  >
                    {employeeDetails.length > 0
                      ? "Outstanding Employee  Details"
                      : projectDetails.length > 0
                      ? "Outstanding Project  Details"
                      : otherChargesDetails.length > 0
                      ? "Outstanding OtherCharges Details"
                      : "Invoice Details"}
                  </Typography>
                </div>
              </Paper>
              {employeeDetails.length > 0 && (
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow
                        style={{
                          backgroundColor: "darkcyan",
                          fontWeight: "bold",
                          color: "darkblue",
                        }}
                      >
                        <TableCell>Month Name</TableCell>
                        <TableCell>Month Year</TableCell>
                        <TableCell>Employee Name</TableCell>
                        <TableCell>Present Amount</TableCell>
                        <TableCell>Catneen Amount</TableCell>
                        <TableCell>Transport Amount</TableCell>
                        <TableCell>Employee Rate</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {employeeDetails.map((employee, index) => (
                        <TableRow key={index}>
                          <TableCell>{employee?.monthName || "N/A"}</TableCell>
                          <TableCell>{employee?.monthYear || "N/A"}</TableCell>
                          <TableCell>
                            {employee?.employeeName || "N/A"}
                          </TableCell>
                          <TableCell>
                            {employee?.presentyAmount || "N/A"}
                          </TableCell>
                          <TableCell>
                            {employee?.catneenAmount || "N/A"}
                          </TableCell>
                          <TableCell>
                            {employee?.transportAmount || "N/A"}
                          </TableCell>
                          <TableCell>
                            {employee?.employeeRate || "N/A"}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
              {projectDetails.length > 0 && (
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow
                        style={{
                          backgroundColor: "darkcyan",
                          fontWeight: "bold",
                          color: "darkblue",
                        }}
                      >
                        <TableCell>Project Name</TableCell>
                        <TableCell>Particular</TableCell>
                        <TableCell>Project PO Number</TableCell>
                        <TableCell>Project From Date</TableCell>
                        <TableCell>Project To Date</TableCell>
                        <TableCell>Rate Per Days</TableCell>
                        <TableCell>Month Work Days</TableCell>
                        <TableCell>Project Work Amount</TableCell>
                        <TableCell>Month Name</TableCell>
                        <TableCell>Month Year</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {projectDetails.map((project, index) => (
                        <TableRow key={index}>
                          <TableCell>{project?.projectName || "N/A"}</TableCell>
                          <TableCell>{project?.particular || "N/A"}</TableCell>
                          <TableCell>
                            {project?.projectPoNumber || "N/A"}
                          </TableCell>
                          <TableCell>
                            {project.projectFromDate
                              ? project.projectFromDate
                                  .split("-")
                                  .reverse()
                                  .join("-")
                              : "N/A"}
                          </TableCell>
                          <TableCell>
                            {project.projectToDate
                              ? project.projectToDate
                                  .split("-")
                                  .reverse()
                                  .join("-")
                              : "N/A"}
                          </TableCell>
                          <TableCell>{project?.ratePerDays || "N/A"}</TableCell>
                          <TableCell>
                            {project?.monthWorkDays || "N/A"}
                          </TableCell>
                          <TableCell>
                            {project?.projectWorkAmount || "N/A"}
                          </TableCell>
                          <TableCell>{project?.monthName || "N/A"}</TableCell>
                          <TableCell>{project?.monthYear || "N/A"}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
              {otherChargesDetails.length > 0 && (
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow
                        style={{
                          backgroundColor: "darkcyan",
                          fontWeight: "bold",
                          color: "darkblue",
                        }}
                      >
                        <TableCell>Employee Name</TableCell>
                        <TableCell>Employee Role</TableCell>
                        <TableCell>Select Item</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>Charge Per Amount</TableCell>
                        <TableCell>CTC Amount</TableCell>
                        <TableCell>Percentage Amount</TableCell>
                        <TableCell>Month Name</TableCell>
                        <TableCell>Month Year</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {otherChargesDetails.map((charge, index) => (
                        <TableRow key={index}>
                          <TableCell>{charge?.employeeName || "N/A"}</TableCell>
                          <TableCell>{charge?.employeeRole || "N/A"}</TableCell>
                          <TableCell>{charge?.selectItem || "N/A"}</TableCell>
                          <TableCell>{charge?.description || "N/A"}</TableCell>
                          <TableCell>
                            {charge?.chargePerAmount || "N/A"}
                          </TableCell>
                          <TableCell>{charge?.ctcAmount || "N/A"}</TableCell>
                          <TableCell>
                            {charge?.percentageAmount || "N/A"}
                          </TableCell>
                          <TableCell>{charge?.monthName || "N/A"}</TableCell>
                          <TableCell>{charge?.monthYear || "N/A"}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card style={{ marginTop: "20px" }}>
            <CardContent>
              <Paper
                elevation={3}
                style={{
                  padding: 20,
                  marginBottom: 20,
                  backgroundColor: "#2196f3",
                  color: "white",
                  padding: "10px",
                  textAlign: "center",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    variant="h5"
                    gutterBottom
                    style={{ marginBottom: "15px", color: "darkblue" }}
                  >
                    Outstanding Collection UnPaid History
                  </Typography>
                </div>
              </Paper>
              {collectionHistory && collectionHistory.length > 0 ? (
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow
                        style={{
                          backgroundColor: "darkcyan",
                          fontWeight: "bold",
                          color: "darkblue",
                        }}
                      >
                        {/* <TableCell>ID</TableCell> */}
                        <TableCell>Invoice Ref No</TableCell>
                        {/* <TableCell>Total Standing Amount</TableCell> */}
                        {/* <TableCell>Financial Year</TableCell> */}
                        <TableCell>Client Name</TableCell>
                        <TableCell>Outstanding Amount</TableCell>
                        <TableCell>Paid Amount</TableCell>
                        <TableCell>UnPaid Amount</TableCell>
                        <TableCell>Deduction Amount</TableCell>
                        <TableCell>Deduction Particular</TableCell>
                        <TableCell>TDS Amount</TableCell>
                        <TableCell>Pay Mode</TableCell>
                        <TableCell>Pay Date</TableCell>
                        <TableCell>Collected By</TableCell>
                        <TableCell>Collected Name</TableCell>
                        <TableCell>Cheque Number</TableCell>
                        <TableCell>Cheque Date</TableCell>
                        <TableCell>Bank Branch</TableCell>
                        {/* <TableCell>Created Date</TableCell>
                      <TableCell>Updated Date</TableCell> */}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {collectionHistory.map((history, index) => (
                        <TableRow key={index}>
                          {/* <TableCell>{history.id}</TableCell> */}
                          <TableCell>{history.invoiceRefNo}</TableCell>
                          {/* <TableCell>{history.totalOutStandingAmount}</TableCell> */}
                          {/* <TableCell>{history.financialYear}</TableCell> */}
                          <TableCell>{history.clientNameAndCode}</TableCell>
                          <TableCell>{history.outStandingAmount}</TableCell>
                          <TableCell>{history.paidAmount}</TableCell>
                          <TableCell>{history.unPaidAmount}</TableCell>
                          <TableCell>{history.deductionAmount}</TableCell>
                          <TableCell>{history.deductionRemark}</TableCell>
                          <TableCell>{history.tdsAmount}</TableCell>
                          <TableCell>{history.payMode}</TableCell>
                          <TableCell>
                            {history.payDate
                              ? history.payDate.split("-").reverse().join("-")
                              : "N/A"}
                          </TableCell>
                          <TableCell>{history.collectedBy}</TableCell>
                          <TableCell>{history.collectedName}</TableCell>
                          <TableCell>{history.chqNumber}</TableCell>
                          <TableCell>
                            {history.chqDate
                              ? history.chqDate.split("-").reverse().join("-")
                              : "N/A"}
                          </TableCell>
                          <TableCell>{history.bankBranch}</TableCell>
                          {/* <TableCell>{history.createdDate}</TableCell>
                        <TableCell>{history.updatedDate}</TableCell> */}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Typography variant="body2" color="textSecondary" component="p">
                  No Outstanding Collection Unpaid History found.
                </Typography>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};
export default OutStandingUnPaidHistory;
