import React, { useEffect, useState } from "react";
import { myAxiosCommonHost } from "../../Service/CorsHelperBaseUrl";
import { CardContent } from "@material-ui/core";
import {
  Card,
  Paper,
  Grid,
  TextField,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  CircularProgress,
  Typography,
  TablePagination,
  Button,
} from "@material-ui/core";
import { Autocomplete } from "@mui/material";

const ProjectPOSummary = () => {
  const [clientNameAndCode, setClientNameAndCode] = useState("");
  const [clientNameOptions, setClientNameOptions] = useState([]);
  const [projectSummary, setProjectSummary] = useState([]);
  const [isSummaryGet, setIsSummaryGet] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [getDataTrigger, setGetDataTrigger] = useState(false); // State to trigger data fetching
  const [projectNameOptions, setProjectNameOptions] = useState([]); // State for project name options
  const [selectedProjectName, setSelectedProjectName] = useState(""); // State for selected project name
  const [projectNameActivePoSummaryList, setProjectNameActivePoSummaryList] =
    useState([]); // State for project name summary data
  const [projectTotalInvoiceAmount, setProjectTotalInvoiceAmount] = useState(
    []
  ); // State for project name summary data
  const [projectInvoiceWise, setProjectInvoiceWise] = useState([]); // State for project name summary data
  const [getProjectNameDataTrigger, setGetProjectNameDataTrigger] =
    useState(false); // State for project name summary data
  const [projectSummaryLoading, setProjectSummaryLoading] = useState(false);
  const [projectNameSummaryLoading, setProjectNameSummaryLoading] =
    useState(false);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [company, setCompany] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    async function fetchCompany() {
      try {
        const response = await myAxiosCommonHost.get(
          "gst/profile/getCompanyCodeList"
        );
        const companies = response.data;
        setCompany(companies);
        if (companies.length > 0) {
          setSelectedCompany(companies[0]); // Set the default company to the first one
        }
      } catch (error) {
        console.error("Error fetching company data:", error);
      }
    }
    fetchCompany();
  }, []);

  useEffect(() => {
    myAxiosCommonHost
      .get(
        `gst/projectpo/getProjectPoClientList?selectCompany=${selectedCompany}`
      )
      .then((response) => {
        setClientNameOptions(response.data);
      })
      .catch((error) => {
        console.error("Error Fetching EmployeeName:", error);
      });
  }, [selectedCompany]);
  // Fetch project name options
  // useEffect(() => {
  //   const encodedClientNameAndCode = encodeURIComponent(clientNameAndCode);
  //   myAxiosCommonHost
  //     .get(
  //       `/gst/projectpo/getProjectNameList?clientNameAndCode=${encodedClientNameAndCode}&selectCompany=${selectedCompany}`
  //     )
  //     .then((response) => {
  //       setProjectNameOptions(response.data);
  //     })
  //     .catch((error) => {
  //       console.error("Error Fetching Project Names:", error);
  //     });
  // }, [clientNameAndCode, selectedCompany]);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  useEffect(() => {
    if (clientNameAndCode && getDataTrigger) {
      setProjectSummaryLoading(true); // Set loading state
      const encodedClientNameAndCode = encodeURIComponent(clientNameAndCode);
      myAxiosCommonHost
        .get(
          `gst/projectpo/getClientProjectPoSummary?clientNameAndCode=${encodedClientNameAndCode}&selectCompany=${selectedCompany}`
        )
        .then((response) => {
          setProjectSummary(response.data.clientProjectPoSummaryList);
          setProjectNameOptions(response.data.projectNameList);
        })
        .catch((error) => {
          console.error("Error fetching project summary:", error);
        })
        .finally(() => {
          setProjectSummaryLoading(false); // Reset loading state
        });
      setGetDataTrigger(false); // Reset the trigger after fetching data
    } else {
      setProjectSummary([]); // Reset data if conditions not met
    }
  }, [clientNameAndCode, selectedCompany, getDataTrigger]);

  useEffect(() => {
    if (
      clientNameAndCode &&
      getProjectNameDataTrigger &&
      selectedProjectName &&
      selectedCompany
    ) {
      setProjectNameSummaryLoading(true); // Set loading state
      const encodedClientNameAndCode = encodeURIComponent(clientNameAndCode);
      const encodedProjectName = encodeURIComponent(selectedProjectName);
      myAxiosCommonHost
        .get(
          `gst/projectpo/getNameProjectPoSummary?clientNameAndCode=${encodedClientNameAndCode}&projectName=${encodedProjectName}&selectCompany=${selectedCompany}`
        )
        .then((response) => {
          setProjectNameActivePoSummaryList(
            response.data.projectNameActivePoSummaryList
          );
          setProjectTotalInvoiceAmount(
            response.data.projectPoTotalInvoiceAmountList
          );
          setProjectInvoiceWise(response.data.projectPoInvoiceWiseDataList);
        })
        .catch((error) => {
          console.error("Error fetching project summary:", error);
        })
        .finally(() => {
          setProjectNameSummaryLoading(false); // Reset loading state
        });
      setGetProjectNameDataTrigger(false); // Reset the trigger after fetching data
    } else {
      // Reset data if conditions not met
      setProjectSummary([]);
      setProjectNameActivePoSummaryList([]);
      setProjectTotalInvoiceAmount([]);
      setProjectInvoiceWise([]);
    }
  }, [
    clientNameAndCode,
    selectedCompany,
    getProjectNameDataTrigger,
    selectedProjectName,
  ]);

  const handleGetData = () => {
    // Set getDataTrigger to true when the button is clicked
    setGetDataTrigger(true);
  };
  const handleGetProjectNameData = () => {
    // Set getDataTrigger to true when the button is clicked
    setGetProjectNameDataTrigger(true);
  };
  const handleCompanySelect = (company) => {
    setSelectedCompany(company);
    console.log("Selected Company :", company);
  };

  return (
    <div>
      {projectSummaryLoading && (
        <div className="blur-effect">
          <CircularProgress color="primary" />
          <div className="loading-message">
            Getting Client Wise Project PO Summary...
          </div>
        </div>
      )}
      {projectNameSummaryLoading && (
        <div className="blur-effect">
          <CircularProgress color="primary" />
          <div className="loading-message">
            Getting Employee And Client Wise Employee PO Summary...
          </div>
        </div>
      )}
      <Grid>
        <Grid>
          <Card>
            <CardContent>
              <Paper
                elevation={3}
                style={{ padding: "20px", marginBottom: "20px" }}
              >
                <Paper
                  elevation={3}
                  style={{
                    padding: "10px",
                    marginBottom: "10px",
                    backgroundColor: "#2196f3",
                  }}
                >
                  <Typography
                    variant="h5"
                    gutterBottom
                    style={{
                      marginBottom: "15px",
                      color: "white",
                      textAlign: "center",
                    }}
                  >
                    Project Summary
                  </Typography>
                </Paper>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Autocomplete
                      id="company"
                      options={company}
                      loading={loading}
                      value={selectedCompany}
                      onChange={(event, newValue) =>
                        handleCompanySelect(newValue)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Company"
                          variant="standard"
                          margin="normal"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Autocomplete
                      options={clientNameOptions}
                      getOptionLabel={(option) => option}
                      value={clientNameAndCode}
                      onChange={(event, newValue) =>
                        setClientNameAndCode(newValue)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Client Name"
                          variant="standard"
                          margin="normal"
                          fullWidth
                          required
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Autocomplete
                      options={projectNameOptions}
                      getOptionLabel={(option) => option}
                      value={selectedProjectName}
                      onChange={(event, newValue) =>
                        setSelectedProjectName(newValue)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Project Name"
                          variant="standard"
                          margin="normal"
                          fullWidth
                          required
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={!clientNameAndCode}
                      onClick={handleGetData}
                    >
                      View Client History
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleGetProjectNameData}
                      disabled={!selectedProjectName}
                    >
                      View Project History
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {projectSummary.length > 0 && (
        <Grid>
          <Paper
            elevation={3}
            style={{ padding: "20px", marginBottom: "20px" }}
          >
            <Paper
              elevation={3}
              style={{ padding: "10px", marginBottom: "10px" }}
            >
              <Typography
                variant="h5"
                gutterBottom
                style={{ marginBottom: "15px", color: "#2196f3" }}
              >
                Project Summary
              </Typography>
            </Paper>
            <TableContainer component={Paper} style={{ marginTop: "20px" }}>
              <Table>
                <TableHead>
                  <TableRow
                    style={{
                      backgroundColor: "darkcyan",
                      fontWeight: "bold",
                      color: "darkblue",
                    }}
                  >
                    <TableCell>SR.No</TableCell>
                    <TableCell>Project Name</TableCell>
                    <TableCell>Project Description</TableCell>
                    <TableCell>Is Active</TableCell>
                    <TableCell>Flag Is Active Value</TableCell>
                    <TableCell>Project Po No</TableCell>
                    <TableCell>Project Po Amount</TableCell>
                    <TableCell>Project Po Date</TableCell>
                    <TableCell>Project Po StartDate</TableCell>
                    <TableCell>Project Po EndDate</TableCell>
                    <TableCell>ClientCode</TableCell>
                    <TableCell>ClientName</TableCell>
                    <TableCell>Project Unique Code</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {projectSummary
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => (
                      <TableRow key={row.id}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{row.projectName}</TableCell>
                        <TableCell>{row.projectDescription}</TableCell>
                        <TableCell>
                          {row.isActive === "true" ? "true" : "false"}
                        </TableCell>
                        <TableCell>{row.flagIsActiveValue}</TableCell>
                        <TableCell>{row.projectPoNo}</TableCell>
                        <TableCell>{row.projectPoAmount}</TableCell>
                        <TableCell>
                          {row.projectPoDate
                            ? row.projectPoDate.split("-").reverse().join("-")
                            : "N/A"}
                        </TableCell>
                        <TableCell>
                          {row.projectPoStartDate
                            ? row.projectPoStartDate
                                .split("-")
                                .reverse()
                                .join("-")
                            : "N/A"}
                        </TableCell>
                        <TableCell>
                          {row.projectPoEndDate
                            ? row.projectPoEndDate
                                .split("-")
                                .reverse()
                                .join("-")
                            : "N/A"}
                        </TableCell>
                        <TableCell>{row.clientCode}</TableCell>
                        <TableCell>{row.clientName}</TableCell>
                        <TableCell>{row.gsProjectUniqueCode}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[2, 4, 16]}
              component="div"
              count={projectSummary.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
      )}
      {projectNameActivePoSummaryList.length > 0 && (
        <Grid>
          <Paper
            elevation={3}
            style={{ padding: "20px", marginBottom: "20px" }}
          >
            <Paper
              elevation={3}
              style={{ padding: "10px", marginBottom: "10px" }}
            >
              <Typography
                variant="h5"
                gutterBottom
                style={{ marginBottom: "15px", color: "#2196f3" }}
              >
                Active Project Name
              </Typography>
            </Paper>
            <TableContainer component={Paper} style={{ marginTop: "20px" }}>
              <Table>
                <TableHead>
                  <TableRow
                    style={{
                      backgroundColor: "darkcyan",
                      fontWeight: "bold",
                      color: "darkblue",
                    }}
                  >
                    <TableCell>SR.No</TableCell>
                    <TableCell>IsActive</TableCell>
                    <TableCell>Project Po No</TableCell>
                    <TableCell>Project Po Amount</TableCell>
                    <TableCell>Project Po Date</TableCell>
                    <TableCell>Project Po Start Date</TableCell>
                    <TableCell>Project Po End Date</TableCell>
                    <TableCell>Flag IsActive Value</TableCell>
                    <TableCell>GS Project Unique Code</TableCell>
                    <TableCell>Client Code</TableCell>
                    <TableCell>Client Name</TableCell>
                    <TableCell>Project Name</TableCell>
                    <TableCell>Project Description</TableCell>
                    <TableCell>Project Release Date</TableCell>
                    <TableCell>Project Rate Unit</TableCell>
                    <TableCell>Project Work Rate</TableCell>
                    <TableCell>Project Hour or Day</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {projectNameActivePoSummaryList.map((row, index) => (
                    <TableRow key={row.gsProjectUniqueCode}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>
                        {row.isActive === "true" ? "true" : "false"}
                      </TableCell>
                      <TableCell>{row.projectPoNo}</TableCell>
                      <TableCell>{row.projectPoAmount}</TableCell>
                      <TableCell>{row.projectPoDate}</TableCell>
                      <TableCell>{row.projectPoStartDate}</TableCell>
                      <TableCell>{row.projectPoEndDate}</TableCell>
                      <TableCell>{row.flagIsActiveValue}</TableCell>
                      <TableCell>{row.gsProjectUniqueCode}</TableCell>
                      <TableCell>{row.clientCode}</TableCell>
                      <TableCell>{row.clientName}</TableCell>
                      <TableCell>{row.projectName}</TableCell>
                      <TableCell>{row.projectDescription}</TableCell>
                      <TableCell>{row.releaseDate}</TableCell>
                      <TableCell>{row.rateUnit}</TableCell>
                      <TableCell>{row.workRate}</TableCell>
                      <TableCell>{row.projectHourOrDay}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      )}
      {projectTotalInvoiceAmount.length > 0 && (
        <Grid>
          <Paper
            elevation={3}
            style={{ padding: "20px", marginBottom: "20px" }}
          >
            <Paper
              elevation={3}
              style={{ padding: "10px", marginBottom: "10px" }}
            >
              <Typography
                variant="h5"
                gutterBottom
                style={{ marginBottom: "15px", color: "#2196f3" }}
              >
                Project PO Invoice Wise
              </Typography>
            </Paper>
            <TableContainer component={Paper} style={{ marginTop: "20px" }}>
              <Table>
                <TableHead>
                  <TableRow
                    style={{
                      backgroundColor: "darkcyan",
                      fontWeight: "bold",
                      color: "darkblue",
                    }}
                  >
                    <TableCell>Sr.No</TableCell>
                    <TableCell>Finacial Year Name</TableCell>
                    <TableCell>Invoice Year</TableCell>
                    <TableCell>Invoice Month</TableCell>
                    <TableCell>Project Po No</TableCell>
                    <TableCell>Remaining Per Hour Or Day</TableCell>
                    <TableCell>Invoice Ref No</TableCell>
                    <TableCell>GS Project Unique Code</TableCell>
                    <TableCell>Client Code</TableCell>
                    <TableCell>Project Work Amount</TableCell>
                    <TableCell>Project Work Rate</TableCell>
                    <TableCell>Project Work Presenty</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {projectInvoiceWise.map((row, index) => (
                    <TableRow key={row.gsProjectUniqueCode}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{row.finacialYearName}</TableCell>
                      <TableCell>{row.invoiceYear}</TableCell>
                      <TableCell>{row.invoiceMonth}</TableCell>
                      <TableCell>{row.projectPoNo}</TableCell>
                      <TableCell>{row.remainingPerHourOrDay}</TableCell>
                      <TableCell>{row.invoiceRefNo}</TableCell>
                      <TableCell>{row.gsProjectUniqueCode}</TableCell>
                      <TableCell>{row.clientCode}</TableCell>
                      <TableCell>{row.workAmount}</TableCell>
                      <TableCell>{row.workRate}</TableCell>
                      <TableCell>{row.workPresenty}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      )}
      {projectTotalInvoiceAmount.length > 0 && (
        <Grid>
          <Paper
            elevation={3}
            style={{ padding: "20px", marginBottom: "20px" }}
          >
            <Paper
              elevation={3}
              style={{ padding: "10px", marginBottom: "10px" }}
            >
              <Typography
                variant="h5"
                gutterBottom
                style={{ marginBottom: "15px", color: "#2196f3" }}
              >
                Total Invoice Amount
              </Typography>
            </Paper>
            <TableContainer component={Paper} style={{ marginTop: "20px" }}>
              <Table>
                <TableHead>
                  <TableRow
                    style={{
                      backgroundColor: "darkcyan",
                      fontWeight: "bold",
                      color: "darkblue",
                    }}
                  >
                    <TableCell>Sr.No</TableCell>
                    <TableCell>Project Po No</TableCell>
                    <TableCell>GS Project Unique Code</TableCell>
                    <TableCell>Total Invoice Amount</TableCell>
                    <TableCell>Total Project Hour Or Day</TableCell>
                    <TableCell>Total Per Hour Or Day</TableCell>
                    <TableCell>Remaining Amount</TableCell>
                    <TableCell>PO Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {projectTotalInvoiceAmount.map((row, index) => (
                    <TableRow key={row.gsProjectUniqueCode}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{row.projectPoNo}</TableCell>
                      <TableCell>{row.gsProjectUniqueCode}</TableCell>
                      <TableCell>{row.totalInvoiceAmount}</TableCell>
                      <TableCell>{row.totalProjectHourOrDay}</TableCell>
                      <TableCell>{row.totalPerHourOrDay}</TableCell>
                      <TableCell>{row.remainingAmount}</TableCell>
                      <TableCell>{row.poAmount}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      )}
    </div>
  );
};

export default ProjectPOSummary;
