import Headers from "../../../Pages/Header";
import EmployeePOSummary from "../EmployeePOSummary";
const EmployeePOSummaryPage = () => {
  return (
    <Headers>
      <EmployeePOSummary />
    </Headers>
  );
};
export default EmployeePOSummaryPage;
