// import Headers from "../../../Pages/Header";
// import EmployeeInvoiceList from "../EmployeeInvoiceList";

// // const EmployeeInvoiceListPage = () => {
// function EmployeeInvoiceListPage({ selectedFinancialYear }) {
//   return (
//     <Headers>
//       {/* <EmployeeInvoiceList /> */}
//       <EmployeeInvoiceList selectedFinancialYear={selectedFinancialYear} />
//     </Headers>
//   );
// }
// export default EmployeeInvoiceListPage;

import Headers from "../../../Pages/Header";
import EmployeeInvoiceList from "../EmployeeInvoiceList";

const EmployeeInvoiceListPage = ({ selectedFinancialYear }) => {
  return (
    <Headers selectedFinancialYear={selectedFinancialYear}>
      <EmployeeInvoiceList selectedFinancialYear={selectedFinancialYear} />
    </Headers>
  );
};

export default EmployeeInvoiceListPage;
