
import Headers from "../../../Pages/Header";
import InvoiceReport from "../Report";

const InvoiceReportPage = () => {
  return (
    <Headers>
      <InvoiceReport />
    </Headers>
  );
};
export default InvoiceReportPage;