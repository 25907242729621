import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
  Image,
} from "@react-pdf/renderer";
import { myAxiosCommonHost } from "../Service/CorsHelperBaseUrl";

// Create styles
const styles = StyleSheet.create({
  page: {
    position: "relative",
    padding: 20,
  },
  backgroundImage: {
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: -1,
  },
  header: {
    fontSize: 10,
    textAlign: "center",
    marginBottom: 10,
  },
  section: {
    marginBottom: 5,
    fontSize: 9,
  },
  subjectSection: {
    marginBottom: 5,
    fontSize: 9,
    textAlign: "center",
  },
  sectionQuotationRefAndDate: {
    marginBottom: 5,
    fontSize: 9,
    alignItems: "flex-end", // Align content to the right
  },
  subjectSection2: {
    marginBottom: 5,
    fontSize: 9,
    textAlign: "center",
    alignItems: "flex-end", // Align content to the right
  },
  //   table: {
  //     display: "table",
  //     width: "auto",
  //     borderStyle: "solid",
  //     borderWidth: 1,
  //     borderRightWidth: 0,
  //     borderBottomWidth: 0,
  //     marginBottom: 5,
  //   },
  //   tableRow: {
  //     flexDirection: "row",
  //   },
  //   tableColHeader: {
  //     width: "14.28%",
  //     borderStyle: "solid",
  //     borderWidth: 1,
  //     borderLeftWidth: 0,
  //     borderTopWidth: 0,
  //     backgroundColor: "#f2f2f2",
  //   },
  //   tableCol: {
  //     width: "14.28%",
  //     borderStyle: "solid",
  //     borderWidth: 1,
  //     borderLeftWidth: 0,
  //     borderTopWidth: 0,
  //   },
  //   tableCellHeader: {
  //     margin: 2,
  //     fontSize: 8,
  //     fontWeight: "bold",
  //   },
  //   tableCell: {
  //     margin: 2,
  //     fontSize: 8,
  //   },
  table: {
    display: "table",
    width: "80%", // Set a width to control table size
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginBottom: 5,
    alignSelf: "center", // Center the table
  },
  tableRow: {
    flexDirection: "row",
  },
  tableColHeader: {
    width: "16.66%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: "#f2f2f2",
  },
  tableCol: {
    width: "16.66%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCellHeader: {
    margin: 2,
    fontSize: 8,
    fontWeight: "bold",
  },
  tableCell: {
    margin: 2,
    fontSize: 8,
  },
  footer: {
    marginTop: 10,
    textAlign: "right",
    fontSize: 9,
  },
  boldDarkText: {
    fontWeight: "bold",
    color: "#000", // Dark color
  },
  sectionFooter: {
    marginBottom: 20,
    fontSize: 9,
  },
  logo: {
    width: 100,
    height: 100,
    marginBottom: 10,
  },
  letterhead: {
    width: "100%",
    height: 150,
    marginBottom: 10,
  },
});

// Create Document Component
const MyDocument = ({ data }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <Image style={styles.backgroundImage} src="letterHeader.jpg" />
      <View style={[styles.sectionQuotationRefAndDate, styles.boldDarkText]}>
        <Text>Quote No: {data.quotationRefNo}</Text>
        <Text>Date: {data.quotationDate.split("-").reverse().join("-")}</Text>
      </View>

      <View style={styles.section}>
        <Text>TO,</Text>
        <Text>{data.clientNameAndCode}</Text>
        <Text>Kind Attn: {data.kindAttention}</Text>
      </View>

      <View style={styles.subjectSection}>
        <Text>Subject: - {data.quotationSubject}</Text>
      </View>
      <View style={styles.subjectSection2}>
        <Text>
          With reference to your mail and further to our discussion about cost
          onsite {data.quotationSubject}, we are happy to submit the following
          quote.
        </Text>
      </View>

      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={styles.tableColHeader}>
            <Text style={styles.tableCellHeader}>Sr No</Text>
          </View>
          <View style={styles.tableColHeader}>
            <Text style={styles.tableCellHeader}>Description</Text>
          </View>
          <View style={styles.tableColHeader}>
            <Text style={styles.tableCellHeader}>Cost Per Resource</Text>
          </View>
          <View style={styles.tableColHeader}>
            <Text style={styles.tableCellHeader}>No. of Resources</Text>
          </View>
          <View style={styles.tableColHeader}>
            <Text style={styles.tableCellHeader}>No. of Months</Text>
          </View>
          <View style={styles.tableColHeader}>
            <Text style={styles.tableCellHeader}>Total Cost</Text>
          </View>
        </View>
        {data.accomodationUIList.map((item, index) => (
          <View style={styles.tableRow} key={index}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{index + 1}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{item.description}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{item.costPerResources}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{item.noOfResources}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{item.noOfMonth}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{item.totalCost}</Text>
            </View>
          </View>
        ))}
      </View>

      <View style={styles.section}>
        <Text>
          Total Amount: - {data.totalAmount} ({data.totalInWord})
        </Text>
        <Text>Terms and Conditions:</Text>
        <Text>1. GST @18%</Text>
        <Text>2. TDS as applicable</Text>
        <Text>3. Payment terms 15 days from the date of invoice</Text>
      </View>

      <View style={styles.footer}>
        <Text>Thanking you,</Text>
        <Text>Mr. M Krishnan</Text>
        <Text>Director</Text>
      </View>
      <View style={styles.sectionFooter}>
        <Image
          style={styles.logo}
          src="H:\ERP_UI\CheckOutRepository\feature-develop\erpui\src\logo.svg"
        />
        <Text style={styles.header}>
          Office No. 404, Pentagon tower-2, Magarpatta city, Pune, Maharashtra
          411013
        </Text>
        <Text style={styles.header}>
          India, North America & Europe | www.gsquaretech.com |
          hr@gsquaretech.com
        </Text>
      </View>
    </Page>
  </Document>
);

// Main component
const DownloadPDFNew2 = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    myAxiosCommonHost
      .get("gst/quotation/getQuotationList")
      .then((response) => {
        setData(response.data[0]);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  return (
    <div>
      {data ? (
        <PDFDownloadLink
          document={<MyDocument data={data} />}
          fileName="quotation.pdf"
        >
          {({ loading }) => (loading ? "Loading document..." : "Download PDF")}
        </PDFDownloadLink>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default DownloadPDFNew2;
