import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PizZip from 'pizzip';
import Docxtemplater from 'docxtemplater';
import { saveAs } from 'file-saver';
import NewQuotationWord2 from './NewQuotationWord2.docx'

const fetchData = async () => {
  const response = await axios.get('http://localhost:8082/gst/quotation/viewQuotationPdf?quotationNo=1');
  return response.data[0];
};

const generateDocx = async (data) => {
  const response = await axios.get(NewQuotationWord2, { responseType: 'arraybuffer' });
  const content = new PizZip(response.data);
  const doc = new Docxtemplater(content);

  doc.setData({
    clientName: data.clientUI.clientName,
    quotationNo: data.quotationNo,
    quotationRefNo: data.quotationRefNo,
    quotationDate: data.quotationDate,
    clientNameAndCode: data.clientNameAndCode,
    kindAttention: data.kindAttention,
    quotationSubject: data.quotationSubject,
    quotationContent: data.quotationContent,
    totalAmount: data.totalAmount,
    totalInWord: data.totalInWord,
    financialYear: data.financialYear,
    financialMonth: data.financialMonth,
    serviceAccountingCode: data.serviceAccountingCode,
    accommodations: data.accomodationUIList,
    clientAddress: data.clientUI.clientAddress,
    clientContactNumber: data.clientUI.clientContactNumber,
    clientWebsite: data.clientUI.clientWebsite,
    gstDetails: data.gstDetailsUI,
    termsAndConditions: data.quotationTermAndConditionsUI,
  });

  try {
    doc.render();
  } catch (error) {
    console.error(error);
    throw error;
  }

  const out = doc.getZip().generate({
    type: 'blob',
    mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  });

  return out;
};

const convertDocxToPdf = async (docxBlob) => {
  const formData = new FormData();
  formData.append('doc', docxBlob);
  formData.append('test', 'true');
  formData.append('name', 'document.pdf');
  formData.append('document_type', 'pdf');

  const response = await axios.post('https://api.pdf.co/v1/file/upload/get-presigned-url', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': `Basic ${btoa('sagarpise0202@gmail.com_72kwTd0K3Jyj70X2Tqf9ajVZ3o2tIN0Q0Z6QSg90Tc4gLs2cMIkho2c4Hzh34MR2')}`,
    },
    responseType: 'blob',
  });

  return response.data;
};

const QuotationUpload5 = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    fetchData().then((data) => setData(data));
  }, []);

  const generatePdf = async (data) => {
    if (!data) return;

    try {
      const docxBlob = await generateDocx(data);
      const pdfBlob = await convertDocxToPdf(docxBlob);
      saveAs(pdfBlob, 'document.pdf');
    } catch (error) {
      console.error('Error generating PDF:', error);
    }
  };

  return (
    <div>
      {data ? <div>Data loaded</div> : <div>Loading...</div>}
      <button onClick={() => generatePdf(data)}>Generate PDF</button>
    </div>
  );
};

export default QuotationUpload5;
