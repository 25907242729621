import React, { useState, useEffect } from "react";
import numberToWords from "number-to-words";
import axios from "axios";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Grid,
  Button,
  Card,
  CardHeader,
  Container,
  Typography,
} from "@material-ui/core";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
// import Logo from "../Invoice/Logo1.jpg";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { myAxiosCommonHost } from "../Service/CorsHelperBaseUrl";

const GenerateEmployeeInvoice2 = () => {
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };
  const getCurrentMonth = () => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const currentDate = new Date();
    const currentMonthIndex = currentDate.getMonth();
    return months[currentMonthIndex];
  };
  //   const getCurrentMonth = () => {
  //     const months = [
  //       "Jan",
  //       "Feb",
  //       "Mar",
  //       "Apr",
  //       "May",
  //       "Jun",
  //       "Jul",
  //       "Aug",
  //       "Sep",
  //       "Oct",
  //       "Nov",
  //       "Dec",
  //     ];
  //     const currentDate = new Date();
  //     currentDate.setMonth(currentDate.getMonth() - 1); // Subtract 1 to get the previous month
  //     const previousMonthIndex = currentDate.getMonth();
  //     return months[previousMonthIndex];
  //   };
  const getCurrentFinancialYear = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const fiscalStartYear = currentYear - (currentDate.getMonth() >= 3 ? 0 : 1);
    const fiscalEndYear = fiscalStartYear + 1;
    return `${(fiscalStartYear % 100).toString().padStart(2, "0")}-${(
      fiscalEndYear % 100
    )
      .toString()
      .padStart(2, "0")}`;
  };
  const navigate = useNavigate();
  const [invoiceHeaderDetails, setInvoiceHeaderDetails] = useState({
    selectCompany: "GST",
    financialYear: getCurrentFinancialYear(),
    invoiceNo: "",
    invoiceRefNo: "",
    invoiceDate: format(new Date(), "yyyy-MM") + "-01", // Default to the first day of the current month
    invoiceType: "",
    clientType: "AutoMobile",
    paymentYear: getCurrentYear(),
    paymentMonth: getCurrentMonth(),
    // paymentMonth: getCurrentMonth(),
    clientName: "",
    sacHsn: "",
    uniqueNoPoNo: "",
    buyersPoNumber: "",
    poNumber: "",
    issueDate: format(new Date(), "yyyy-MM-dd"), // Default to today's date
    workingDays: "",
    invoiceTitle: "",
  });

  const [invoiceCalculation, setInvoiceCalculation] = useState({
    totalPresentyAmount: 0,
    cgst: 0,
    igst: 0,
    sgst: 0,
    grandTotalAmount: 0,
    totalTaxAmt: 0,
    deductionAmount: 0,
    deductionParticular: "",
    totalInWord: "",
  });

  const [paymentMode, setPaymentMode] = useState({
    ModeTermsOfPayment: "",
    KindAttention: "",
    SelectBankAccNo: "",
  });
  const [isCalculateClicked, setIsCalculateClicked] = useState(false);
  const [financialYears, setFinancialYears] = useState([]);
  const [paymentYears, setPaymentYears] = useState([]);
  const [paymentMonths, setPaymentMonths] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [poNumbers, setPoNumbers] = useState([]);
  const [projectDetails, setProjectDetails] = useState(null);
  const [bankAccNoAndIfscCodes, setBankAccNoAndIfscCodes] = useState([]);
  const [bankUIData, setBankUIData] = useState(null);
  const [invoiceEmployeePoDetailsUIList, setInvoiceEmployeePoDetailsUIList] =
    useState({
      invoiceEmployeePoDetailsUIList: [
        {
          empRateUnit: "",
          presenty: "",
          presentyAmt: "",
          canteenAmt: "",
          transportationAmt: "",
          fromDate: "",
          toDate: "",
          emplyoyeeCode: "",
          emplyoyeeName: "",
          currentPoRate: "",
          fixmonthlyCanteen: "",
          isCanteenAssign: "",
          isTransportationAssign: "",
          activeStatus: "",
          releaseDate: "",
          currentEmplyoyee: "",
          currentPoNo: "",
          currentPoDate: "",
          annenuxFlag: "",
          uniqueNoPoNo: "",
        },
      ],
    });

  useEffect(() => {
    const fetchClientList = async () => {
      try {
        const response = await myAxiosCommonHost.get(
          "gst/employeePo/getClientListEmpPurchaseOrder"
        );
        setClientList(response.data);
      } catch (error) {
        console.error("Error fetching client list:", error);
      }
    };

    fetchClientList();
  }, []);
  useEffect(() => {
    const fetchFinancialYears = async () => {
      try {
        const response = await myAxiosCommonHost.get(
          "gst/invoice/getFiscalYear"
        );
        setFinancialYears(response.data);
      } catch (error) {
        console.error("Error fetching financialYears:", error);
      }
    };

    fetchFinancialYears();
  }, []);

  useEffect(() => {
    const fetchPaymentYears = async () => {
      try {
        const response = await myAxiosCommonHost.get(
          "gst/invoice/getPaymentYear"
        );
        setPaymentYears(response.data);
      } catch (error) {
        console.error("Error fetching paymentYears:", error);
      }
    };

    fetchPaymentYears();
  }, []);

  useEffect(() => {
    const fetchPaymentMonths = async () => {
      try {
        const response = await myAxiosCommonHost.get(
          "gst/invoice/getShortMonth"
        );
        setPaymentMonths(response.data);
      } catch (error) {
        console.error("Error fetching paymentMonths:", error);
      }
    };

    fetchPaymentMonths();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          //   "http://gsquareerp.gsquaretech.com:8082/gst/invoice/getInvoiceUniqueNo"
          "http://localhost:8082/gst/invoice/getInvoiceUniqueNo"
        );
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.text();
        setInvoiceHeaderDetails((prevState) => ({
          ...prevState,
          selectCompany: "GST",
          financialYear: getCurrentFinancialYear(),
          invoiceNo: data,
          invoiceRefNo: "",
          issueDate: format(new Date(), "yyyy-MM-dd"), // Update the default value for issueDate
          invoiceType: "",
          clientType: "AutoMobile",
          paymentYear: getCurrentYear(),
          paymentMonth: getCurrentMonth(),
          clientName: "",
          sacHsn: "",
          poNumber: "",
          uniqueNoPoNo: "",
          invoiceDate: format(new Date(), "yyyy-MM") + "-01", // Update the default value for invoiceDate
          buyersPoNumber: "",
          workingDays: "",
          invoiceTitle: "",
        }));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const fetchPoNumbers = async (clientName) => {
    try {
      const response = await myAxiosCommonHost.get(
        `gst/employeePo/getPoNumberAndUniqueNumberEmpPurchaseOrderList?ClientName=${clientName}`
      );
      setPoNumbers(response.data);
    } catch (error) {
      console.error("Error fetching poNumbers:", error);
    }
  };

  const fetchBankAccNoAndIfscCodes = async (
    uniqueNoPoNo,
    paymentYear,
    paymentMonth
  ) => {
    try {
      const response = await myAxiosCommonHost.get(
        `gst/employeePo/getEmployeePoInvoiceUIList?empPoNoAndUniqueCode=${uniqueNoPoNo}&paymentYear=${paymentYear}&paymentMonth=${paymentMonth}`
      );
      const data = response.data.bankAccNoAndIfscCode || [];
      setBankAccNoAndIfscCodes(data);
      return data;
    } catch (error) {
      console.error("Error fetching bankAccNoAndIfscCodes:", error);
      return [];
    }
  };

  const generateInvoiceRefNo = () => {
    const { selectCompany, financialYear, invoiceNo } = invoiceHeaderDetails;
    return `${selectCompany}${financialYear}${invoiceNo}`;
  };

  const invoiceRefNo = generateInvoiceRefNo();
  useEffect(() => {
    if (
      invoiceHeaderDetails.selectCompany &&
      invoiceHeaderDetails.financialYear &&
      invoiceHeaderDetails.invoiceNo
    ) {
      const newInvoiceRefNo = generateInvoiceRefNo();
      setInvoiceHeaderDetails((prevDetails) => ({
        ...prevDetails,
        invoiceRefNo: newInvoiceRefNo,
      }));
    }
  }, [
    invoiceHeaderDetails.selectCompany,
    invoiceHeaderDetails.financialYear,
    invoiceHeaderDetails.invoiceNo,
  ]);

  const [workingDays, setWorkingDays] = useState(0);

  const handleWorkingDaysChange = (event) => {
    const value = event.target.value;
    const updatedWorkingDays = parseFloat(value);
    setWorkingDays(updatedWorkingDays);
  };

  const handleInvoiceEmployeeDetailsChange = (index, field) => (event) => {
    console.log("handleInvoiceEmployeeDetailsChange called");

    const value = event.target.value;

    setInvoiceEmployeePoDetailsUIList((prevDetails) => {
      const updatedList = [...prevDetails.invoiceEmployeePoDetailsUIList];
      const currentItem = { ...updatedList[index], [field]: value };

      // Check if both presenty and currentPoRate are valid numbers before calculating presentyAmt
      if (!isNaN(currentItem.presenty) && !isNaN(currentItem.currentPoRate)) {
        if (
          currentItem.empRateUnit === "Per Day" ||
          currentItem.empRateUnit === "Per Hour"
        ) {
          currentItem.presentyAmt = (
            parseFloat(currentItem.presenty) *
            parseFloat(currentItem.currentPoRate)
          ).toFixed(2);
        } else if (currentItem.empRateUnit === "Per Month") {
          currentItem.presentyAmt = (
            (parseFloat(currentItem.currentPoRate) / parseFloat(workingDays)) *
            parseFloat(currentItem.presenty)
          ).toFixed(2);
        } else {
          currentItem.presentyAmt = "";
        }
      } else {
        currentItem.presentyAmt = ""; // Handle the case where either presenty or currentPoRate is not a valid number
      }

      console.log("currentItem:", currentItem);

      updatedList[index] = currentItem;

      return {
        ...prevDetails,
        invoiceEmployeePoDetailsUIList: updatedList,
      };
    });
  };
  // const fetchProjectPoData = async (
  //   uniqueNoPoNo,
  //   paymentYear,
  //   paymentMonth
  // ) => {
  //   try {
  //     const response = await myAxiosCommonHost.get(
  //       `gst/employeePo/getEmployeePoInvoiceUIList?empPoNoAndUniqueCode=${uniqueNoPoNo}&paymentYear=${paymentYear}&paymentMonth=${paymentMonth}`
  //     );
  //     return response.data;
  //   } catch (error) {
  //     console.error("Error fetching project data:", error);
  //     return null;
  //   }
  // };
  const fetchProjectPoData = async (
    uniqueNoPoNo,
    paymentYear,
    paymentMonth
  ) => {
    try {
      const response = await myAxiosCommonHost.get(
        `gst/employeePo/getEmployeePoInvoiceUIList?empPoNoAndUniqueCode=${uniqueNoPoNo}&paymentYear=${paymentYear}&paymentMonth=${paymentMonth}`
      );

      const kindAttention = response.data.kindAttention || "";
      setPaymentMode((prevPaymentMode) => ({
        ...prevPaymentMode,
        KindAttention: kindAttention,
      }));

      return response.data;
    } catch (error) {
      console.error("Error fetching project data:", error);
      return null;
    }
  };

  const handleInvoiceHeaderDetailsChange = (field) => async (event) => {
    const value = event.target.value;
    let projectData;
    let paymentYear;
    let paymentMonth;

    const updateInvoiceHeaderDetails = (newDetails) => {
      setInvoiceHeaderDetails((prevDetails) => ({
        ...prevDetails,
        ...newDetails,
      }));
    };

    if (field === "clientName") {
      updateInvoiceHeaderDetails({
        clientName: value,
        uniqueNoPoNo: "",
      });
      fetchPoNumbers(value);
    } else {
      updateInvoiceHeaderDetails({
        [field]: value,
      });
    }

    if (field === "uniqueNoPoNo") {
      try {
        paymentYear = invoiceHeaderDetails.paymentYear;
        paymentMonth = invoiceHeaderDetails.paymentMonth;

        projectData = await fetchProjectPoData(
          value,
          paymentYear,
          paymentMonth
        );

        if (projectData && Array.isArray(projectData.empPoRateInvoiceUIList)) {
          setInvoiceEmployeePoDetailsUIList((prevDetails) => ({
            ...prevDetails,
            invoiceEmployeePoDetailsUIList:
              projectData.empPoRateInvoiceUIList.map((project) => ({
                empRateUnit: project.empRateUnit,
                canteenAmt: project.canteenAmount,
                transportationAmt: project.transportAmount,
                fromDate: project.fromDate,
                toDate: project.toDate,
                emplyoyeeCode: project.employeeCode,
                emplyoyeeName: project.employeeName,
                currentPoRate: project.currentPoRate,
                fixmonthlyCanteen: project.fixmonthlyCanteen,
                isCanteenAssign: project.isCanteenAssigned,
                isTransportationAssign: project.isTransportationAssigned,
                activeStatus: project.activeStatus,
                releaseDate: project.employeeReleaseDate,
                currentEmplyoyee: project.employeeName,
                currentPoDate: project.currentPoDate,
                annenuxFlag: project.annenuxFlag,
                presenty: project.presenty,
                presentyAmt: project.presentyAmt,
                currentPoNo: project.currentPoNo,
              })),
          }));
          setProjectDetails(projectData);
          const { cgst, sgst, igst } = projectData.gstdetailsUI;
          setInvoiceCalculation((prevData) => ({
            ...prevData,
            cgst,
            sgst,
            igst,
          }));
          try {
            const gstdetailsResponse = await myAxiosCommonHost.get(
              `gst/employeePo/getEmployeePoInvoiceUIList?empPoNoAndUniqueCode=${value}&paymentYear=${paymentYear}&paymentMonth=${paymentMonth}`
            );
            updateInvoiceHeaderDetails({
              sacHsn: gstdetailsResponse.data.gstdetailsUI.hsnsac || "",
            });
          } catch (error) {
            console.error("Error fetching SAC/HSN data:", error);
          }
        } else {
          console.error("Invalid response from server:", projectData);
        }
      } catch (error) {
        console.error("Error fetching project data:", error);
      }

      try {
        const response = await fetchBankAccNoAndIfscCodes(
          value,
          paymentYear,
          paymentMonth
        );

        if (Array.isArray(response)) {
          setPaymentMode((prevPaymentMode) => ({
            ...prevPaymentMode,
            SelectBankAccNo: response,
          }));
        } else {
          console.error("Invalid response from server:", response);
        }
      } catch (error) {
        console.error("Error fetching bankAccNoAndIfscCodes:", error);
      }

      const newInvoiceRefNo = generateInvoiceRefNo();
      updateInvoiceHeaderDetails({
        invoiceRefNo: newInvoiceRefNo,
        poNumber: projectData.empPoRateInvoiceUIList[0]?.currentPoNo || "",
      });
    }
  };

  const handleInvoiceCalculationChange = (field, value) => {
    setInvoiceCalculation((prevData) => ({
      ...prevData,
      [field]: parseFloat(value) || 0,
    }));
  };

  const handlePaymentModeChange = (field) => (event) => {
    setPaymentMode({
      ...paymentMode,
      [field]: event.target.value,
    });
  };

  const handleSubmit = async () => {
    const projectDetailsListData = {
      selectCompany: invoiceHeaderDetails.selectCompany,
      financialYear: invoiceHeaderDetails.financialYear,
      invoiceNo: invoiceHeaderDetails.invoiceNo,
      invoiceRefNo: invoiceHeaderDetails.invoiceRefNo,
      invoiceDate: invoiceHeaderDetails.invoiceDate,
      invoiceType: invoiceHeaderDetails.invoiceType,
      clientType: invoiceHeaderDetails.clientType,
      paymentYear: invoiceHeaderDetails.paymentYear,
      paymentMonth: invoiceHeaderDetails.paymentMonth,
      clientName: invoiceHeaderDetails.clientName,
      sacHsn: invoiceHeaderDetails.sacHsn,
      poNumber: invoiceHeaderDetails.poNumber,
      buyersPoNumber: invoiceHeaderDetails.buyersPoNumber,
      uniqueNoPoNo: invoiceHeaderDetails.uniqueNoPoNo,
      workingDays: invoiceHeaderDetails.workingDays,
      invoiceTitle: invoiceHeaderDetails.invoiceTitle,
      invoiceEmployeePoDetailsUIList:
        invoiceEmployeePoDetailsUIList.invoiceEmployeePoDetailsUIList.map(
          (item) => ({
            empRateUnit: item.empRateUnit,
            canteenAmt: item.canteenAmt,
            transportationAmt: item.transportationAmt,
            fromDate: item.fromDate,
            toDate: item.toDate,
            emplyoyeeCode: item.emplyoyeeCode,
            emplyoyeeName: item.emplyoyeeName,
            curreentPoRate: item.curreentPoRate,
            fixmonthlyCanteen: item.fixmonthlyCanteen,
            isCanteenAssign: item.isCanteenAssign,
            isTransportationAssign: item.isTransportationAssign,
            activeStatus: item.activeStatus,
            releaseDate: item.releaseDate,
            currentEmplyoyee: item.employeeName,
            currentPoDate: item.currentPoDate,
            annenuxFlag: item.annenuxFlag,
            presenty: item.presenty,
            presentyAmt: item.presentyAmt,
          })
        ),
      invoiceGstDetailsUI: {
        totalPresentyAmount: invoiceCalculation.totalPresentyAmount,
        cGST: invoiceCalculation.cgst,
        sGST: invoiceCalculation.sgst,
        iGST: invoiceCalculation.igst,
        grandTotalAmount: invoiceCalculation.grandTotalAmount,
        totalTaxAmt: invoiceCalculation.totalTaxAmt,
        deductionAmount: invoiceCalculation.deductionAmount,
        deductionParticular: invoiceCalculation.deductionParticular,
        totalInWord: invoiceCalculation.totalInWord,
      },
      invoicePaymentModeUI: {
        modeTermsOfPayment: paymentMode.ModeTermsOfPayment,
        kindAttention: paymentMode.KindAttention,
        selectBankAccNo: paymentMode.SelectBankAccNo,
      },
      bankUI: bankUIData,
    };
    console.log(JSON.stringify(projectDetailsListData, null, 2));
    try {
      const response = await myAxiosCommonHost.post(
        "gst/invoice/createInvoice?filterType=EMPLOYEEPOINVOICE",
        projectDetailsListData
      );

      console.log("Server response:", response.data);
      window.alert("Data successfully saved on the server!");
      navigate("/employeeInvoiceList");
    } catch (error) {
      console.error("Error submitting data:", error);
      window.alert("Error saving data on the server. Please try again.");
    }
  };
  const handleCancel = () => {
    const userConfirmed = window.confirm(
      "Are you sure you want to cancel? Any unsaved changes will be lost."
    );
    if (userConfirmed) {
      navigate("/employeeInvoiceList");
    }
  };
  async function fetchCompanyData(uniqueNoPoNo, paymentYear, paymentMonth) {
    const url = `http://gsquareerp.gsquaretech.com:8082/gst/employeePo/getEmployeePoInvoiceUIList?empPoNoAndUniqueCode=${uniqueNoPoNo}&paymentYear=${paymentYear}&paymentMonth=${paymentMonth}`;
    // const url =
    //   "http://localhost:8082/gst/employeePo/getEmployeePoInvoiceUIList?empPoNoAndUniqueCode=${uniqueNoPoNo}&paymentYear=${paymentYear}&paymentMonth=${paymentMonth}";

    try {
      const response = await fetch(url);

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();

      // Extract the required fields from companyProfileUI
      const { companyProfileUI, clientUI, gstdetailsUI } = data;

      // Extract the required fields from companyProfileUI
      const {
        companyName,
        companyAddress,
        website,
        eMail,
        companyWork,
        contactNo,
        gstin,
        pan,
        cin,
        iec,
      } = companyProfileUI;

      // Extract the required fields from clientUI
      const {
        clientType,
        clientCode,
        clientName,
        clientAddress,
        clientRegNumber,
        clientWebsite,
        clientContactNumber,
        billingPeriod,
      } = clientUI;

      // Extract the required fields from gstdetailsUI
      const {
        state,
        stateCode,
        panNumber,
        gstin: gstDetailsGstin,
        hsnsac,
        igst,
        sgst,
        cgst,
      } = gstdetailsUI;

      // You can use the extracted data as needed
      console.log("Company Name:", companyName);
      console.log("Company Address:", companyAddress);
      console.log("Website:", website);
      console.log("Email:", eMail);
      console.log("Company Work:", companyWork);
      console.log("Contact No:", contactNo);
      console.log("GSTIN:", gstin);
      console.log("PAN:", pan);
      console.log("CIN:", cin);
      console.log("IEC:", iec);
      console.log("State:", state);
      console.log("State Code:", stateCode);
      console.log("PAN Number:", panNumber);
      console.log("GSTIN (from gstdetailsUI):", gstDetailsGstin);
      console.log("HSN/SAC:", hsnsac);
      console.log("IGST:", igst);
      console.log("SGST:", sgst);
      console.log("CGST:", cgst);

      // Return the extracted data
      return {
        companyName,
        companyAddress,
        website,
        eMail,
        companyWork,
        contactNo,
        gstin,
        pan,
        cin,
        iec,
        state,
        stateCode,
        panNumber,
        gstDetailsGstin,
        hsnsac,
        igst,
        sgst,
        cgst,
        clientType,
        clientCode,
        clientName,
        clientAddress,
        clientRegNumber,
        clientWebsite,
        clientContactNumber,
        billingPeriod,
      };
    } catch (error) {
      console.error("Error fetching data:", error.message);
      throw error;
    }
  }

  // Function to handle calculation
  const handleCalculate = async () => {
    try {
      // Destructure the relevant data from invoiceHeaderDetails
      const uniqueNoPoNo = invoiceHeaderDetails.uniqueNoPoNo;
      const paymentYear = invoiceHeaderDetails.paymentYear;
      const paymentMonth = invoiceHeaderDetails.paymentMonth;
      const companyData = await fetchCompanyData(
        uniqueNoPoNo,
        paymentYear,
        paymentMonth
      );

      // Destructure the relevant data from companyData
      const { cgst, sgst, igst } = companyData;

      // Update the state with the received values
      setInvoiceCalculation((prevData) => ({
        ...prevData,
        cgst,
        sgst,
        igst,
      }));

      // Calculate totalPresentyAmount
      const updatedTotalPresentyAmount =
        invoiceEmployeePoDetailsUIList.invoiceEmployeePoDetailsUIList.reduce(
          (total, item) => total + parseFloat(item.presentyAmt) || 0,
          0
        );
      const roundedTotalPresentyAmount = parseFloat(
        updatedTotalPresentyAmount.toFixed(2)
      );

      // Update the state with the recalculated totalPresentyAmount
      setInvoiceCalculation((prevData) => ({
        ...prevData,
        totalPresentyAmount: roundedTotalPresentyAmount,
      }));

      // Calculate total tax amount
      const {
        cgst: updatedCgst,
        sgst: updatedSgst,
        igst: updatedIgst,
      } = invoiceCalculation;
      // Round calculated amounts to two decimal places
      const cgstAmount = parseFloat(
        (updatedCgst / 100) * updatedTotalPresentyAmount
      ).toFixed(2);
      const sgstAmount = parseFloat(
        (updatedSgst / 100) * updatedTotalPresentyAmount
      ).toFixed(2);
      const igstAmount = parseFloat(
        (updatedIgst / 100) * updatedTotalPresentyAmount
      ).toFixed(2);
      const calculatedTotalTaxAmt =
        parseFloat(cgstAmount) +
        parseFloat(sgstAmount) +
        parseFloat(igstAmount);

      // Update the state with the recalculated totalTaxAmt
      setInvoiceCalculation((prevData) => ({
        ...prevData,
        totalTaxAmt: parseFloat(calculatedTotalTaxAmt.toFixed(2)),
      }));

      // Calculate grandTotal
      const grandTotalAmount = parseFloat(
        roundedTotalPresentyAmount + calculatedTotalTaxAmt
      ).toFixed(2);

      // Update the state with the recalculated grandTotal
      setInvoiceCalculation((prevData) => ({
        ...prevData,
        grandTotalAmount,
      }));

      // Convert grandTotal to words
      const totalInWord = numberToWords.toWords(grandTotalAmount);

      // Update the state with the total in words
      setInvoiceCalculation((prevData) => ({
        ...prevData,
        totalInWord,
      }));

      console.log("Grand Total Calculated:", grandTotalAmount);
      console.log("Total In Words:", totalInWord);
      // You can also do other calculations or updates here as needed

      //console.log("Grand Total Calculated:", grandTotal);
    } catch (error) {
      // Handle errors appropriately
      console.error("Error calculating grand total:", error.message);
    }
    setIsCalculateClicked(true);
  };

  const [bankName, setBankName] = useState("");
  const [bankAddress, setBankAddress] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [ifsc, setIfsc] = useState("");
  const [swiftCode, setSwiftCode] = useState("");

  useEffect(() => {
    const fetchBankUIData = async () => {
      try {
        const response = await myAxiosCommonHost.get(
          `gst/bank/getInvoiceBankUI?bankAccNoAndIfscCode=${paymentMode.SelectBankAccNo}`
        );
        setBankUIData(response.data);
        // Now you can set these values in your state or do whatever you need with them
        const { bankName, bankAddress, accountNumber, ifsc, swiftCode } =
          response.data;
        setBankName(bankName);
        setBankAddress(bankAddress);
        setAccountNumber(accountNumber);
        setIfsc(ifsc);
        setSwiftCode(swiftCode);
      } catch (error) {
        console.error("Error fetching bankUI data:", error);
      }
    };

    if (paymentMode.SelectBankAccNo) {
      fetchBankUIData();
    }
  }, [paymentMode.SelectBankAccNo]);

  const handleDeleteRow = (index) => {
    // Create a copy of the array and remove the element at the specified index
    const updatedList = [
      ...invoiceEmployeePoDetailsUIList.invoiceEmployeePoDetailsUIList,
    ];
    updatedList.splice(index, 1);

    // Update the state with the new array
    setInvoiceEmployeePoDetailsUIList({
      invoiceEmployeePoDetailsUIList: updatedList,
    });
  };

  const [checkEmpRateUnit, setCheckEmpRateUnit] = useState();

  const handlegeneratePDF = () => {
    invoiceEmployeePoDetailsUIList.invoiceEmployeePoDetailsUIList.forEach(
      (invoiceEmployeePoDetailsUI) => {
        setCheckEmpRateUnit(invoiceEmployeePoDetailsUI.empRateUnit);
      }
    );

    if (checkEmpRateUnit === "Per Day") {
      generatePDFPerDay(
        invoiceHeaderDetails,
        invoiceCalculation
        //empPoRateInvoiceUIList,
        // other parameters
      );
    } else if (checkEmpRateUnit === "Per Month") {
      generatePDFPerMonth(
        invoiceHeaderDetails,
        invoiceCalculation
        //empPoRateInvoiceUIList,
        // other parameters
      );
    } else if (checkEmpRateUnit === "Per Hour") {
      generatePDFPerHour(
        invoiceHeaderDetails,
        invoiceCalculation
        // empPoRateInvoiceUIList,
        // other parameters
      );
    } else {
      console.log("Invalid empRateUnit value. Unable to generate PDF");
    }
  };

  const generatePDFPerMonth = async (
    invoiceHeaderDetails,
    invoiceCalculation,
    empPoRateInvoiceUIList
    // other parameters
  ) => {
    const doc = new jsPDF();

    // Add a new rectangle to encompass all tables and containers
    const encompassingRectX = 3;
    const encompassingRectY = 4;
    const encompassingRectWidth = doc.internal.pageSize.width - 6;
    const encompassingRectHeight = doc.internal.pageSize.height - 8;

    doc.setDrawColor(0); // Set border color to black
    doc.setLineWidth(0.4); // Set border width
    doc.rect(
      encompassingRectX,
      encompassingRectY,
      encompassingRectWidth,
      encompassingRectHeight
    );

    let companyInfoText = ""; // Declare companyInfoText outside the try block

    try {
      const uniqueNoPoNo = invoiceHeaderDetails.uniqueNoPoNo;
      const paymentYear = invoiceHeaderDetails.paymentYear;
      const paymentMonth = invoiceHeaderDetails.paymentMonth;
      const companyData = await fetchCompanyData(
        uniqueNoPoNo,
        paymentYear,
        paymentMonth
      );

      // Information for GSQUARE TECHSYSTEMS PVT LTD
      companyInfoText =
        `${companyData.companyName}\n` +
        `${companyData.companyAddress}\n` +
        `${companyData.website}\n` +
        `${companyData.eMail}`;

      doc.setFontSize(10);
      doc.setTextColor(0, 0, 0); // Black color

      // Add the company information to the PDF
      doc.setFont("helvetica", "bold"); // Set font to bold
      doc.text(companyInfoText, 15, 30);
      doc.setFont("helvetica", "normal"); // Reset font to normal

      // Continue with the rest of your PDF generation logic...
    } catch (error) {
      console.error("Error fetching company data:", error);
      // Handle errors if any
    }

    const companyInfoContainerX = 14;
    const companyInfoContainerY = 10;
    const companyInfoContainerWidth =
      doc.internal.pageSize.width * 0.55 - companyInfoContainerX - 10;
    const companyInfoContainerHeight = 40;

    // Draw the container first
    doc.setDrawColor(0);
    doc.setLineWidth(0.4);
    doc.setFillColor(255, 255, 255);
    doc.rect(
      companyInfoContainerX,
      companyInfoContainerY,
      companyInfoContainerWidth,
      companyInfoContainerHeight,
      "FD"
    );

    // Add the logo with additional margin from the left border
    // doc.addImage(Logo, "JPEG", 15, 11, 28, 28); // Change x coordinate (e.g., 25 or 30)

    // Position the company information text with reduced margin after the logo
    doc.setFontSize(10);
    doc.setTextColor(0, 0, 0);
    doc.text(companyInfoText, 25 + 20 + 2, 18, {
      // Change margin after logo width (e.g., 2 or 3)
      maxWidth: doc.internal.pageSize.width * 0.55 - 25 - 20 - 15, // Calculate available width minus adjusted logo position and margins
    });

    const uniqueNoPoNo = invoiceHeaderDetails.uniqueNoPoNo;
    const paymentYear = invoiceHeaderDetails.paymentYear;
    const paymentMonth = invoiceHeaderDetails.paymentMonth;
    const companyData = await fetchCompanyData(
      uniqueNoPoNo,
      paymentYear,
      paymentMonth
    );

    const CompInvo = [
      ["Invoice No", invoiceHeaderDetails.invoiceNo],
      ["Company's GST NO.", companyData.gstin], // Include GSTIN directly
      ["Company's PAN No.", companyData.pan], // Include PAN directly
      ["Company's CIN", companyData.cin], // Include CIN directly
      ["Invoice Date", invoiceHeaderDetails.invoiceDate],
    ];

    const tableWidth = doc.internal.pageSize.width * 0.45;
    const leftMargin = doc.internal.pageSize.width - tableWidth - 10;

    // Calculate the height of the CompInvo table
    const compInvoTableHeight = doc.autoTable({
      body: CompInvo,
      theme: "grid",
      margin: { top: 10, left: leftMargin, right: 14, bottom: 0 },
      styles: {
        fontSize: 10,
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        cellPadding: 2,
        marginBottom: 0,
        marginTop: 0,
        padding: 0,
      },
    }).lastAutoTable.finalY;

    const ClientInvo = [
      ["Service ACC Code", companyData.hsnsac],
      ["Client's GSTIN", companyData.gstDetailsGstin],
      ["Payment Term", "15Days Bank Transfer"],

      ["Billing Period", companyData.billingPeriod],
      [{ content: "Onsite Manpower Support for the month", colSpan: 2 }],
    ];

    const clientInvoTableHeight = 40;

    // Set the startY parameter for ClientInvo table to start immediately after CompInvo table
    doc.autoTable({
      body: ClientInvo,
      theme: "grid",
      columnStyles: { 0: { cellWidth: "auto" } },
      startY: compInvoTableHeight, // Set startY explicitly
      margin: {
        top: compInvoTableHeight,
        left: leftMargin,
        right: 14,
        bottom: 0,
      },
      styles: {
        fontSize: 10,
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        marginBottom: 0,
        tableHeight: 40,
      },
    });

    // Assuming you have already fetched the data using fetchCompanyData function
    //const companyData = await fetchCompanyData(uniqueNoPoNo);

    const firstTableHeight1 = doc.previousAutoTable.finalY;
    const billedToContainerHeight = 38.85; // or the appropriate value you intend to use

    // Extract clientAddress from the fetched data
    const clientAddress = companyData.clientAddress;

    // Billed To container immediately after companyInfo container
    const billedToText = `Billed To: ${clientAddress}`;

    const billedToContainerX = companyInfoContainerX;
    const topMargin = 0;
    const containerHeight = 40; // Set the common height for both the billedToContainer and the table
    const billedToContainerY =
      companyInfoContainerY + companyInfoContainerHeight + topMargin;
    const billedToContainerWidth = companyInfoContainerWidth;

    doc.setDrawColor(0);
    doc.setLineWidth(0.4);
    doc.setFillColor(255, 255, 255);
    doc.rect(
      billedToContainerX,
      billedToContainerY,
      billedToContainerWidth,
      containerHeight,
      "FD"
    );

    doc.setFontSize(10);
    doc.setTextColor(0, 0, 0);
    doc.text(billedToText, billedToContainerX + 5, billedToContainerY + 10, {
      maxWidth: billedToContainerWidth - 10,
    });
    doc.setFillColor(255, 255, 255);

    const tableData =
      invoiceEmployeePoDetailsUIList.invoiceEmployeePoDetailsUIList.map(
        (row) => [
          row.emplyoyeeName,
          row.currentPoNo,
          row.fromDate,
          row.toDate,
          invoiceHeaderDetails.workingDays,
          row.presenty,
          row.currentPoRate,
          row.presentyAmt,
        ]
      );

    doc.autoTable({
      head: [
        [
          "Particulars",
          "PO NO.",
          "From Date",
          "To date",
          "Working Days",
          "Billable Days",
          "Current PO Rate",
          "Amount",
        ],
      ],
      body: tableData,
      startY: billedToContainerY + billedToContainerHeight + 0,
      theme: "grid",
      styles: {
        fontSize: 9,
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        marginBottom: 0,
      },
    });
    // Calculate the height of the first table (invoice data table)
    const firstTableHeight = doc.previousAutoTable
      ? doc.previousAutoTable.finalY
      : 0;

    const { totalPresentyAmount, grandTotalAmount, totalTaxAmt, totalInWord } =
      invoiceCalculation;
    const { cgst, sgst, igst } = companyData;
    const InvoiceData = [
      ["A] Total", totalPresentyAmount],
      ["B] CGST (1)", ` ${(cgst / 100) * totalPresentyAmount}`],
      ["C] SGST (2)", ` ${(sgst / 100) * totalPresentyAmount}`],
      ["D] IGST (3)", ` ${(igst / 100) * totalPresentyAmount}`],
      ["E] Total (A+B+C+D)", grandTotalAmount],
      ["F] Deduction ", 0],
      ["G] Grand Total (F-G)", grandTotalAmount],
      ["H] Total In Words", totalInWord],
    ];

    // Set startY for the second table immediately after the first table
    doc.autoTable({
      body: InvoiceData,
      startY: firstTableHeight + 0, // You can adjust the spacing as needed
      theme: "grid",
      styles: {
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        marginBottom: 0,
      },
      columnStyles: {
        1: { cellWidth: "auto", halign: "right" }, // Adjust the width of the 2nd column in InvoiceData
      },
    });

    // Calculate the height of the second table (invoice data table)
    const secondTableHeight = doc.previousAutoTable
      ? doc.previousAutoTable.finalY
      : 0;

    // Calculate remaining space on the page
    const remainingSpace =
      doc.internal.pageSize.getHeight() - secondTableHeight - 20;

    // Check if there is enough space for the bank table
    if (remainingSpace < 0) {
      doc.addPage();
    }

    const bankData = [
      [`Bank Name: ${bankName}`],
      [`Bank Address: ${bankAddress}`],
      [`A/C No.: ${accountNumber}`],
      [`IFC Code: ${ifsc}`],
      [`Swift Code: ${swiftCode}`],
    ];

    const tableWidth2 = doc.internal.pageSize.width * 0.4;

    // Set startY for the bank table immediately after the invoice data table
    doc.autoTable({
      body: bankData,
      startY: secondTableHeight + 0, // Start immediately after the previous table with some padding
      theme: "grid",
      styles: {
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        marginBottom: 0,
      },
      columnStyles: { 0: { cellWidth: tableWidth2, cellHeight: 10 } },
    });

    // Draw the rectangle and text for "For Gsquare Techsystem PVT LTD"
    const rectWidth = 97;
    const rectHeight = 38;

    // Set rectX1 to start from the right edge of the bank table
    const rectX1 = doc.internal.pageSize.width * 0.47;

    const rectY1 = secondTableHeight + 0; // Adjust the spacing as needed

    doc.setDrawColor(0); // Set border color to black
    doc.rect(rectX1, rectY1, rectWidth, rectHeight).setLineWidth(0.5);

    const line1Text = "For Gsquare Techsystem PVT LTD";
    const line2Text = "Authorized Signatory & Seal";
    const textX = rectX1 + 25;
    const textY1 = rectY1 + 8;
    const textY2 = rectY1 + 30;
    doc.text(line1Text, textX - 5, textY1);
    doc.text(line2Text, textX, textY2);

    doc.save("Employee_Invoice_data_perMonth.pdf");
  };

  const generatePDFPerHour = async (
    invoiceHeaderDetails,
    invoiceCalculation,
    empPoRateInvoiceUIList,
    invoicePaymentModeUI,
    paymentMode
    // workingDayCalculationUI
  ) => {
    const doc = new jsPDF();

    // Add a new rectangle to encompass all tables and containers
    const encompassingRectX = 3;
    const encompassingRectY = 4;
    const encompassingRectWidth = doc.internal.pageSize.width - 6;
    const encompassingRectHeight = doc.internal.pageSize.height - 8;

    doc.setDrawColor(0); // Set border color to black
    doc.setLineWidth(0.4); // Set border width
    doc.rect(
      encompassingRectX,
      encompassingRectY,
      encompassingRectWidth,
      encompassingRectHeight
    );

    let companyInfoText = ""; // Declare companyInfoText outside the try block

    try {
      const uniqueNoPoNo = invoiceHeaderDetails.uniqueNoPoNo;
      const paymentYear = invoiceHeaderDetails.paymentYear;
      const paymentMonth = invoiceHeaderDetails.paymentMonth;
      const companyData = await fetchCompanyData(
        uniqueNoPoNo,
        paymentYear,
        paymentMonth
      );

      // Information for GSQUARE TECHSYSTEMS PVT LTD
      companyInfoText =
        `${companyData.companyName}\n` +
        `${companyData.companyAddress}\n` +
        `${companyData.website}\n` +
        `${companyData.eMail}`;

      doc.setFontSize(10);
      doc.setTextColor(0, 0, 0); // Black color

      // Add the company information to the PDF
      doc.setFont("helvetica", "bold"); // Set font to bold
      doc.text(companyInfoText, 15, 30);
      doc.setFont("helvetica", "normal"); // Reset font to normal

      // Continue with the rest of your PDF generation logic...
    } catch (error) {
      console.error("Error fetching company data:", error);
      // Handle errors if any
    }

    const companyInfoContainerX = 14;
    const companyInfoContainerY = 10;
    const companyInfoContainerWidth =
      doc.internal.pageSize.width * 0.55 - companyInfoContainerX - 10;
    const companyInfoContainerHeight = 40;

    // Draw the container first
    doc.setDrawColor(0);
    doc.setLineWidth(0.4);
    doc.setFillColor(255, 255, 255);
    doc.rect(
      companyInfoContainerX,
      companyInfoContainerY,
      companyInfoContainerWidth,
      companyInfoContainerHeight,
      "FD"
    );

    // Add the logo with additional margin from the left border
    // doc.addImage(Logo, "JPEG", 15, 11, 28, 28); // Change x coordinate (e.g., 25 or 30)

    // Position the company information text with reduced margin after the logo
    doc.setFontSize(10);
    doc.setTextColor(0, 0, 0);
    doc.text(companyInfoText, 25 + 20 + 2, 18, {
      // Change margin after logo width (e.g., 2 or 3)
      maxWidth: doc.internal.pageSize.width * 0.55 - 25 - 20 - 15, // Calculate available width minus adjusted logo position and margins
    });

    const uniqueNoPoNo = invoiceHeaderDetails.uniqueNoPoNo;
    const paymentYear = invoiceHeaderDetails.paymentYear;
    const paymentMonth = invoiceHeaderDetails.paymentMonth;
    const companyData = await fetchCompanyData(
      uniqueNoPoNo,
      paymentYear,
      paymentMonth
    );

    const CompInvo = [
      ["Invoice No", invoiceHeaderDetails.invoiceNo],
      ["Company's GST NO.", companyData.gstin], // Include GSTIN directly
      ["Company's PAN No.", companyData.pan], // Include PAN directly
      ["Company's CIN", companyData.cin], // Include CIN directly
      ["Invoice Date", invoiceHeaderDetails.invoiceDate],
    ];

    const tableWidth = doc.internal.pageSize.width * 0.45;
    const leftMargin = doc.internal.pageSize.width - tableWidth - 10;

    // Calculate the height of the CompInvo table
    const compInvoTableHeight = doc.autoTable({
      body: CompInvo,
      theme: "grid",
      margin: { top: 10, left: leftMargin, right: 14, bottom: 0 },
      styles: {
        fontSize: 10,
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        cellPadding: 2,
        marginBottom: 0,
        marginTop: 0,
        padding: 0,
      },
    }).lastAutoTable.finalY;

    const ClientInvo = [
      ["Service ACC Code", companyData.hsnsac],
      ["Client's GSTIN", companyData.gstDetailsGstin],
      ["Payment Term", "15Days Bank Transfer"],

      ["Billing Period", companyData.billingPeriod],
      [{ content: "Onsite Manpower Support for the month", colSpan: 2 }],
    ];

    const clientInvoTableHeight = 40;

    // Set the startY parameter for ClientInvo table to start immediately after CompInvo table
    doc.autoTable({
      body: ClientInvo,
      theme: "grid",
      columnStyles: { 0: { cellWidth: "auto" } },
      startY: compInvoTableHeight, // Set startY explicitly
      margin: {
        top: compInvoTableHeight,
        left: leftMargin,
        right: 14,
        bottom: 0,
      },
      styles: {
        fontSize: 10,
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        marginBottom: 0,
        tableHeight: 40,
      },
    });

    // Assuming you have already fetched the data using fetchCompanyData function
    //const companyData = await fetchCompanyData(uniqueNoPoNo);

    const firstTableHeight1 = doc.previousAutoTable.finalY;
    const billedToContainerHeight = 38.85; // or the appropriate value you intend to use

    // Extract clientAddress from the fetched data
    const clientAddress = companyData.clientAddress;

    // Billed To container immediately after companyInfo container
    const billedToText = `Billed To: ${clientAddress}`;

    const billedToContainerX = companyInfoContainerX;
    const topMargin = 0;
    const containerHeight = 40; // Set the common height for both the billedToContainer and the table
    const billedToContainerY =
      companyInfoContainerY + companyInfoContainerHeight + topMargin;
    const billedToContainerWidth = companyInfoContainerWidth;

    doc.setDrawColor(0);
    doc.setLineWidth(0.4);
    doc.setFillColor(255, 255, 255);
    doc.rect(
      billedToContainerX,
      billedToContainerY,
      billedToContainerWidth,
      containerHeight,
      "FD"
    );

    doc.setFontSize(10);
    doc.setTextColor(0, 0, 0);
    doc.text(billedToText, billedToContainerX + 5, billedToContainerY + 10, {
      maxWidth: billedToContainerWidth - 10,
    });
    doc.setFillColor(255, 255, 255);

    const tableData =
      invoiceEmployeePoDetailsUIList.invoiceEmployeePoDetailsUIList.map(
        (row) => [
          row.emplyoyeeName,
          row.currentPoNo,
          row.fromDate,
          row.toDate,
          // Add more fields based on your data structure

          row.presenty,
          row.currentPoRate,
          row.presentyAmt,
        ]
      );

    doc.autoTable({
      head: [
        [
          "Particulars",
          "PO NO.",
          "From Date",
          "To date",

          "Billable Hours",
          "Current PO Rate",
          "Amount",
        ],
      ],
      body: tableData,
      startY: billedToContainerY + billedToContainerHeight + 0,
      theme: "grid",
      styles: {
        fontSize: 9,
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        marginBottom: 0,
      },
    });
    // Calculate the height of the first table (invoice data table)
    const firstTableHeight = doc.previousAutoTable
      ? doc.previousAutoTable.finalY
      : 0;

    const { totalPresentyAmount, grandTotalAmount, totalTaxAmt, totalInWord } =
      invoiceCalculation;
    const { cgst, sgst, igst } = companyData;
    const InvoiceData = [
      ["A] Total", totalPresentyAmount],
      ["B] CGST (1)", ` ${(cgst / 100) * totalPresentyAmount}`],
      ["C] SGST (2)", ` ${(sgst / 100) * totalPresentyAmount}`],
      ["D] IGST (3)", ` ${(igst / 100) * totalPresentyAmount}`],
      ["E] Total (A+B+C+D)", grandTotalAmount],
      ["F] Deduction ", 0],
      ["G] Grand Total (F-G)", grandTotalAmount],
      ["H] Total In Words", totalInWord],
    ];

    // Set startY for the second table immediately after the first table
    doc.autoTable({
      body: InvoiceData,
      startY: firstTableHeight + 0, // You can adjust the spacing as needed
      theme: "grid",
      styles: {
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        marginBottom: 0,
      },
      columnStyles: {
        1: { cellWidth: "auto", halign: "right" }, // Adjust the width of the 2nd column in InvoiceData
      },
    });

    // Calculate the height of the second table (invoice data table)
    const secondTableHeight = doc.previousAutoTable
      ? doc.previousAutoTable.finalY
      : 0;

    // Calculate remaining space on the page
    const remainingSpace =
      doc.internal.pageSize.getHeight() - secondTableHeight - 20;

    // Check if there is enough space for the bank table
    if (remainingSpace < 0) {
      doc.addPage();
    }

    const bankData = [
      [`Bank Name: ${bankName}`],
      [`Bank Address: ${bankAddress}`],
      [`A/C No.: ${accountNumber}`],
      [`IFC Code: ${ifsc}`],
      [`Swift Code: ${swiftCode}`],
    ];

    const tableWidth2 = doc.internal.pageSize.width * 0.4;

    // Set startY for the bank table immediately after the invoice data table
    doc.autoTable({
      body: bankData,
      startY: secondTableHeight + 0, // Start immediately after the previous table with some padding
      theme: "grid",
      styles: {
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        marginBottom: 0,
      },
      columnStyles: { 0: { cellWidth: tableWidth2, cellHeight: 10 } },
    });

    // Draw the rectangle and text for "For Gsquare Techsystem PVT LTD"
    const rectWidth = 97;
    const rectHeight = 38;

    // Set rectX1 to start from the right edge of the bank table
    const rectX1 = doc.internal.pageSize.width * 0.47;

    const rectY1 = secondTableHeight + 0; // Adjust the spacing as needed

    doc.setDrawColor(0); // Set border color to black
    doc.rect(rectX1, rectY1, rectWidth, rectHeight).setLineWidth(0.5);

    const line1Text = "For Gsquare Techsystem PVT LTD";
    const line2Text = "Authorized Signatory & Seal";
    const textX = rectX1 + 25;
    const textY1 = rectY1 + 8;
    const textY2 = rectY1 + 30;
    doc.text(line1Text, textX - 5, textY1);
    doc.text(line2Text, textX, textY2);

    doc.save("Employee_Invoice_data_perHour.pdf");
  };

  const generatePDFPerDay = async (
    invoiceHeaderDetails,
    invoiceCalculation,
    empPoRateInvoiceUIList,
    invoicePaymentModeUI,
    paymentMode
    // workingDayCalculationUI
  ) => {
    const doc = new jsPDF();

    // Add a new rectangle to encompass all tables and containers
    const encompassingRectX = 3;
    const encompassingRectY = 4;
    const encompassingRectWidth = doc.internal.pageSize.width - 6;
    const encompassingRectHeight = doc.internal.pageSize.height - 8;

    doc.setDrawColor(0); // Set border color to black
    doc.setLineWidth(0.4); // Set border width
    doc.rect(
      encompassingRectX,
      encompassingRectY,
      encompassingRectWidth,
      encompassingRectHeight
    );

    let companyInfoText = ""; // Declare companyInfoText outside the try block

    try {
      const uniqueNoPoNo = invoiceHeaderDetails.uniqueNoPoNo;
      const paymentYear = invoiceHeaderDetails.paymentYear;
      const paymentMonth = invoiceHeaderDetails.paymentMonth;
      const companyData = await fetchCompanyData(
        uniqueNoPoNo,
        paymentYear,
        paymentMonth
      );

      // Information for GSQUARE TECHSYSTEMS PVT LTD
      companyInfoText =
        `${companyData.companyName}\n` +
        `${companyData.companyAddress}\n` +
        `${companyData.website}\n` +
        `${companyData.eMail}`;

      doc.setFontSize(10);
      doc.setTextColor(0, 0, 0); // Black color

      // Add the company information to the PDF
      doc.setFont("helvetica", "bold"); // Set font to bold
      doc.text(companyInfoText, 15, 30);
      doc.setFont("helvetica", "normal"); // Reset font to normal

      // Continue with the rest of your PDF generation logic...
    } catch (error) {
      console.error("Error fetching company data:", error);
      // Handle errors if any
    }

    const companyInfoContainerX = 14;
    const companyInfoContainerY = 10;
    const companyInfoContainerWidth =
      doc.internal.pageSize.width * 0.55 - companyInfoContainerX - 10;
    const companyInfoContainerHeight = 40;

    // Draw the container first
    doc.setDrawColor(0);
    doc.setLineWidth(0.4);
    doc.setFillColor(255, 255, 255);
    doc.rect(
      companyInfoContainerX,
      companyInfoContainerY,
      companyInfoContainerWidth,
      companyInfoContainerHeight,
      "FD"
    );

    // Add the logo with additional margin from the left border
    // doc.addImage(Logo, "JPEG", 15, 11, 28, 28); // Change x coordinate (e.g., 25 or 30)

    // Position the company information text with reduced margin after the logo
    doc.setFontSize(10);
    doc.setTextColor(0, 0, 0);
    doc.text(companyInfoText, 25 + 20 + 2, 18, {
      // Change margin after logo width (e.g., 2 or 3)
      maxWidth: doc.internal.pageSize.width * 0.55 - 25 - 20 - 15, // Calculate available width minus adjusted logo position and margins
    });

    const uniqueNoPoNo = invoiceHeaderDetails.uniqueNoPoNo;
    const paymentYear = invoiceHeaderDetails.paymentYear;
    const paymentMonth = invoiceHeaderDetails.paymentMonth;
    const companyData = await fetchCompanyData(
      uniqueNoPoNo,
      paymentYear,
      paymentMonth
    );

    const CompInvo = [
      ["Invoice No", invoiceHeaderDetails.invoiceNo],
      ["Company's GST NO.", companyData.gstin], // Include GSTIN directly
      ["Company's PAN No.", companyData.pan], // Include PAN directly
      ["Company's CIN", companyData.cin], // Include CIN directly
      ["Invoice Date", invoiceHeaderDetails.invoiceDate],
    ];

    const tableWidth = doc.internal.pageSize.width * 0.45;
    const leftMargin = doc.internal.pageSize.width - tableWidth - 10;

    // Calculate the height of the CompInvo table
    const compInvoTableHeight = doc.autoTable({
      body: CompInvo,
      theme: "grid",
      margin: { top: 10, left: leftMargin, right: 14, bottom: 0 },
      styles: {
        fontSize: 10,
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        cellPadding: 2,
        marginBottom: 0,
        marginTop: 0,
        padding: 0,
      },
    }).lastAutoTable.finalY;

    const ClientInvo = [
      ["Service ACC Code", companyData.hsnsac],
      ["Client's GSTIN", companyData.gstDetailsGstin],
      ["Payment Term", "15Days Bank Transfer"],

      ["Billing Period", companyData.billingPeriod],
      [{ content: "Onsite Manpower Support for the month", colSpan: 2 }],
    ];

    const clientInvoTableHeight = 40;

    // Set the startY parameter for ClientInvo table to start immediately after CompInvo table
    doc.autoTable({
      body: ClientInvo,
      theme: "grid",
      columnStyles: { 0: { cellWidth: "auto" } },
      startY: compInvoTableHeight, // Set startY explicitly
      margin: {
        top: compInvoTableHeight,
        left: leftMargin,
        right: 14,
        bottom: 0,
      },
      styles: {
        fontSize: 10,
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        marginBottom: 0,
        tableHeight: 40,
      },
    });

    // Assuming you have already fetched the data using fetchCompanyData function
    //const companyData = await fetchCompanyData(uniqueNoPoNo);

    const firstTableHeight1 = doc.previousAutoTable.finalY;
    const billedToContainerHeight = 38.85; // or the appropriate value you intend to use

    // Extract clientAddress from the fetched data
    const clientAddress = companyData.clientAddress;

    // Billed To container immediately after companyInfo container
    const billedToText = `Billed To: ${clientAddress}`;

    const billedToContainerX = companyInfoContainerX;
    const topMargin = 0;
    const containerHeight = 40; // Set the common height for both the billedToContainer and the table
    const billedToContainerY =
      companyInfoContainerY + companyInfoContainerHeight + topMargin;
    const billedToContainerWidth = companyInfoContainerWidth;

    doc.setDrawColor(0);
    doc.setLineWidth(0.4);
    doc.setFillColor(255, 255, 255);
    doc.rect(
      billedToContainerX,
      billedToContainerY,
      billedToContainerWidth,
      containerHeight,
      "FD"
    );

    doc.setFontSize(10);
    doc.setTextColor(0, 0, 0);
    doc.text(billedToText, billedToContainerX + 5, billedToContainerY + 10, {
      maxWidth: billedToContainerWidth - 10,
    });
    doc.setFillColor(255, 255, 255);

    const tableData =
      invoiceEmployeePoDetailsUIList.invoiceEmployeePoDetailsUIList.map(
        (row) => [
          row.emplyoyeeName,
          row.currentPoNo,
          row.fromDate,
          row.toDate,
          // Add more fields based on your data structure

          row.presenty,
          row.currentPoRate,
          row.presentyAmt,
        ]
      );

    doc.autoTable({
      head: [
        [
          "Particulars",
          "PO NO.",
          "From Date",
          "To date",

          "Billable Days",
          "Current PO Rate",
          "Amount",
        ],
      ],
      body: tableData,
      startY: billedToContainerY + billedToContainerHeight + 0,
      theme: "grid",
      styles: {
        fontSize: 9,
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        marginBottom: 0,
      },
    });
    // Calculate the height of the first table (invoice data table)
    const firstTableHeight = doc.previousAutoTable
      ? doc.previousAutoTable.finalY
      : 0;

    const { totalPresentyAmount, grandTotalAmount, totalTaxAmt, totalInWord } =
      invoiceCalculation;
    const { cgst, sgst, igst } = companyData;
    const InvoiceData = [
      ["A] Total", totalPresentyAmount],
      ["B] CGST (1)", ` ${(cgst / 100) * totalPresentyAmount}`],
      ["C] SGST (2)", ` ${(sgst / 100) * totalPresentyAmount}`],
      ["D] IGST (3)", ` ${(igst / 100) * totalPresentyAmount}`],
      ["E] Total (A+B+C+D)", grandTotalAmount],
      ["F] Deduction ", 0],
      ["G] Grand Total (F-G)", grandTotalAmount],
      ["H] Total In Words", totalInWord],
    ];

    // Set startY for the second table immediately after the first table
    doc.autoTable({
      body: InvoiceData,
      startY: firstTableHeight + 0, // You can adjust the spacing as needed
      theme: "grid",
      styles: {
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        marginBottom: 0,
      },

      columnStyles: {
        1: { halign: "right" }, // Adjust the width of the 2nd column in InvoiceData
      },
    });

    // Calculate the height of the second table (invoice data table)
    const secondTableHeight = doc.previousAutoTable
      ? doc.previousAutoTable.finalY
      : 0;

    // Calculate remaining space on the page
    const remainingSpace =
      doc.internal.pageSize.getHeight() - secondTableHeight - 20;

    // Check if there is enough space for the bank table
    if (remainingSpace < 0) {
      doc.addPage();
    }

    const bankData = [
      [`Bank Name: ${bankName}`],
      [`Bank Address: ${bankAddress}`],
      [`A/C No.: ${accountNumber}`],
      [`IFC Code: ${ifsc}`],
      [`Swift Code: ${swiftCode}`],
    ];

    const tableWidth2 = doc.internal.pageSize.width * 0.4;

    // Set startY for the bank table immediately after the invoice data table
    doc.autoTable({
      body: bankData,
      startY: secondTableHeight + 0, // Start immediately after the previous table with some padding
      theme: "grid",
      styles: {
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        marginBottom: 0,
      },
      columnStyles: { 0: { cellWidth: tableWidth2, cellHeight: 10 } },
    });

    // Draw the rectangle and text for "For Gsquare Techsystem PVT LTD"
    const rectWidth = 97;
    const rectHeight = 38;

    // Set rectX1 to start from the right edge of the bank table
    const rectX1 = doc.internal.pageSize.width * 0.47;

    const rectY1 = secondTableHeight + 0; // Adjust the spacing as needed

    doc.setDrawColor(0); // Set border color to black
    doc.rect(rectX1, rectY1, rectWidth, rectHeight).setLineWidth(0.5);

    const line1Text = "For Gsquare Techsystem PVT LTD";
    const line2Text = "Authorized Signatory & Seal";
    const textX = rectX1 + 25;
    const textY1 = rectY1 + 8;
    const textY2 = rectY1 + 30;
    doc.text(line1Text, textX - 5, textY1);
    doc.text(line2Text, textX, textY2);

    doc.save("Employee_Invoice_data_perDay.pdf");
  };

  return (
    <Container maxWidth="xl" onSubmit={handleSubmit}>
      <Card elevation={3} style={{ margin: "40px auto" }}>
        <CardHeader
          title="Employee Invoice Header Details"
          style={{
            backgroundColor: "#2196f3",
            color: "white",
            padding: "10px",
            textAlign: "center",
          }}
        />
        <Paper elevation={3} style={{ padding: "10px", marginBottom: "10px" }}>
          <Paper
            elevation={3}
            style={{ padding: "20px", marginBottom: "20px" }}
          >
            <Paper
              elevation={3}
              style={{ padding: "10px", marginBottom: "10px" }}
            >
              <Typography
                variant="h5"
                gutterBottom
                style={{ marginBottom: "15px", color: "#2196f3" }}
              >
                Employee Invoice Header Details
              </Typography>
            </Paper>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <FormControl
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  style={{ marginBottom: 20 }}
                >
                  <InputLabel shrink htmlFor="selectCompany">
                    Select Company
                  </InputLabel>
                  <Select
                    label="Select Company"
                    name="selectCompany"
                    value={invoiceHeaderDetails.selectCompany}
                    onChange={handleInvoiceHeaderDetailsChange("selectCompany")}
                    variant="standard"
                    fullWidth
                    margin="dense"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    <MenuItem value="" disabled>
                      <em>Select Company</em>
                    </MenuItem>
                    <MenuItem value="GST">GSquare</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  style={{ marginBottom: 20 }}
                >
                  <InputLabel shrink htmlFor="financialYear">
                    Financial Year
                  </InputLabel>
                  <Select
                    label="Financial Year"
                    fullWidth
                    value={invoiceHeaderDetails.financialYear}
                    onChange={handleInvoiceHeaderDetailsChange("financialYear")}
                    variant="standard"
                    margin="dense"
                    displayEmpty
                  >
                    {financialYears.map((year) => (
                      <MenuItem key={year} value={year}>
                        {year}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Invoice No"
                  fullWidth
                  value={invoiceHeaderDetails.invoiceNo}
                  onChange={handleInvoiceHeaderDetailsChange("invoiceNo")}
                  variant="standard"
                  margin="dense"
                  disabled
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Invoice RefNo"
                  name="invoiceRefNo"
                  value={invoiceHeaderDetails.invoiceRefNo}
                  onChange={handleInvoiceHeaderDetailsChange("invoiceRefNo")}
                  variant="standard"
                  fullWidth
                  margin="dense"
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Issue Date"
                  name="issueDate"
                  type="date"
                  value={invoiceHeaderDetails.issueDate}
                  onChange={handleInvoiceHeaderDetailsChange("issueDate")}
                  variant="standard"
                  fullWidth
                  margin="dense"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item xs={3}>
                <TextField
                  label="Invoice Date"
                  name="invoiceDate"
                  type="date"
                  value={invoiceHeaderDetails.invoiceDate}
                  onChange={handleInvoiceHeaderDetailsChange("invoiceDate")}
                  variant="standard"
                  fullWidth
                  margin="dense"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <FormControl
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  style={{ marginBottom: 20 }}
                >
                  <InputLabel shrink htmlFor="paymentYear">
                    Payment Year
                  </InputLabel>
                  <Select
                    label="Payment Year"
                    fullWidth
                    value={invoiceHeaderDetails.paymentYear}
                    onChange={handleInvoiceHeaderDetailsChange("paymentYear")}
                    variant="standard"
                    margin="dense"
                  >
                    {paymentYears.map((year) => (
                      <MenuItem key={year} value={year}>
                        {year}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  style={{ marginBottom: 20 }}
                >
                  <InputLabel shrink htmlFor="paymentMonth">
                    Payment Month
                  </InputLabel>
                  <Select
                    label="Payment Month"
                    fullWidth
                    value={invoiceHeaderDetails.paymentMonth}
                    onChange={handleInvoiceHeaderDetailsChange("paymentMonth")}
                    variant="standard"
                  >
                    {paymentMonths.map((month) => (
                      <MenuItem key={month} value={month}>
                        {month}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  style={{ marginBottom: 20 }}
                >
                  <InputLabel shrink htmlFor="clientType">
                    ClientType
                  </InputLabel>
                  <Select
                    label="ClientType"
                    name="clientType"
                    value={invoiceHeaderDetails.clientType}
                    onChange={handleInvoiceHeaderDetailsChange("clientType")}
                    variant="standard"
                    fullWidth
                    margin="dense"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    <MenuItem value="" disabled>
                      <em>Client Type</em>
                    </MenuItem>
                    <MenuItem value="AutoMobile">AutoMobile</MenuItem>
                    <MenuItem value="Mechanical">Mechanical</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  style={{ marginBottom: 20 }}
                >
                  <InputLabel>Client Name</InputLabel>
                  <Select
                    select
                    label="Client Name"
                    fullWidth
                    value={invoiceHeaderDetails.clientName}
                    onChange={handleInvoiceHeaderDetailsChange("clientName")}
                    variant="standard"
                  >
                    <MenuItem value="" disabled>
                      Select Client Name
                    </MenuItem>
                    {clientList.map((client) => (
                      <MenuItem key={client} value={client}>
                        {client}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  style={{ marginBottom: 20 }}
                >
                  <InputLabel>Select Invoice Type</InputLabel>
                  <Select
                    label="Invoice Type"
                    name="invoiceType"
                    value={invoiceHeaderDetails.invoiceType}
                    onChange={handleInvoiceHeaderDetailsChange("invoiceType")}
                    variant="standard"
                    fullWidth
                    margin="dense"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    <MenuItem value="" disabled>
                      <em>Select Invoice Type</em>
                    </MenuItem>
                    <MenuItem value="EmployeeInvoice">
                      Employee Invoice
                    </MenuItem>
                    <MenuItem value="OtherChargesInvoice">
                      Other Charges Invoice
                    </MenuItem>
                    <MenuItem value="ProjectInvoice">Project Invoice</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="SAC/HSN"
                  fullWidth
                  value={invoiceHeaderDetails.sacHsn}
                  onChange={handleInvoiceHeaderDetailsChange("sacHsn")}
                  variant="standard"
                />
              </Grid>
              {/* <Grid item xs={3}>
                <TextField
                  label="PO Number"
                  fullWidth
                  value={invoiceHeaderDetails.poNumber}
                  InputProps={{
                    readOnly: true,
                  }}
                  variant="standard"
                />
              </Grid> */}

              <Grid item xs={3}>
                <TextField
                  label="Buyer's PO Number"
                  fullWidth
                  value={invoiceHeaderDetails.buyersPoNumber}
                  onChange={handleInvoiceHeaderDetailsChange("buyersPoNumber")}
                  variant="standard"
                />
              </Grid>
              <Grid item xs={3}>
                <FormControl
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  style={{ marginBottom: 20 }}
                >
                  <InputLabel>Invoice Title</InputLabel>
                  <Select
                    label="Invoice Title"
                    name="invoiceTitle"
                    value={invoiceHeaderDetails.invoiceTitle}
                    onChange={handleInvoiceHeaderDetailsChange("invoiceTitle")}
                    variant="standard"
                    fullWidth
                    margin="dense"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    <MenuItem value="" disabled>
                      <em>Invoice Title</em>
                    </MenuItem>
                    <MenuItem value=" PROFORMA INVOICE">
                      PROFORMA INVOICE
                    </MenuItem>
                    <MenuItem value="TAX INVOICE">TAX INVOICE</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Paper>
        </Paper>

        <Paper elevation={3} style={{ padding: "20px", marginBottom: "20px" }}>
          <Paper
            elevation={3}
            style={{ padding: "20px", marginBottom: "20px" }}
          >
            <Paper
              elevation={3}
              style={{ padding: "10px", marginBottom: "10px" }}
            >
              <Typography
                variant="h5"
                gutterBottom
                style={{ marginBottom: "15px", color: "#2196f3" }}
              >
                Employee Details
              </Typography>
            </Paper>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextField
                  select
                  label="Unique PO Number"
                  fullWidth
                  value={invoiceHeaderDetails.uniqueNoPoNo}
                  onChange={handleInvoiceHeaderDetailsChange("uniqueNoPoNo")}
                  variant="standard"
                >
                  {poNumbers.map((poNumber) => (
                    <MenuItem key={poNumber} value={poNumber}>
                      {poNumber}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="PO Number"
                  fullWidth
                  value={invoiceHeaderDetails.poNumber}
                  InputProps={{
                    readOnly: true,
                  }}
                  variant="standard"
                />
              </Grid>
              {invoiceEmployeePoDetailsUIList.invoiceEmployeePoDetailsUIList
                .length > 0 && (
                <Grid item xs={4}>
                  <TextField
                    label="Emp Rate Unit"
                    fullWidth
                    value={
                      invoiceEmployeePoDetailsUIList
                        .invoiceEmployeePoDetailsUIList[0].empRateUnit || ""
                    }
                    onChange={handleInvoiceHeaderDetailsChange(
                      0,
                      "empRateUnit"
                    )}
                    variant="standard"
                  />
                </Grid>
              )}
              {invoiceEmployeePoDetailsUIList.invoiceEmployeePoDetailsUIList
                .length > 0 && (
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel>Annenux Flag</InputLabel>
                    <Select
                      value={
                        invoiceEmployeePoDetailsUIList
                          .invoiceEmployeePoDetailsUIList[0].annenuxFlag || ""
                      }
                      onChange={(event) =>
                        handleInvoiceEmployeeDetailsChange(
                          0,
                          "annenuxFlag"
                        )(event.target.value)
                      }
                    >
                      <MenuItem value={true}>True</MenuItem>
                      <MenuItem value={false}>False</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              )}
              <Grid item xs={4}>
                <TextField
                  label="Working Days"
                  fullWidth
                  value={invoiceHeaderDetails.workingDays}
                  onChange={(event) => {
                    handleWorkingDaysChange(event);
                    handleInvoiceHeaderDetailsChange("workingDays")(event);
                  }}
                  variant="standard"
                  margin="dense"
                />
              </Grid>
            </Grid>
          </Paper>
        </Paper>
        <Paper elevation={3} style={{ padding: "20px", marginBottom: "20px" }}>
          <Paper
            elevation={3}
            style={{ padding: "20px", marginBottom: "20px" }}
          >
            <Paper
              elevation={3}
              style={{ padding: "10px", marginBottom: "10px" }}
            >
              <Typography
                variant="h5"
                gutterBottom
                style={{ marginBottom: "15px", color: "#2196f3" }}
              >
                Employee Calculation Details
              </Typography>
            </Paper>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow
                    style={{
                      backgroundColor: "darkcyan",
                      fontWeight: "bold",
                      color: "darkblue",
                    }}
                  >
                    <TableCell style={{ width: "30px" }}>Sr. No.</TableCell>
                    <TableCell style={{ width: "30px" }}>Action</TableCell>
                    <TableCell style={{ width: "150px" }}>Presenty</TableCell>
                    <TableCell style={{ width: "250px" }}>
                      Presenty Amount
                    </TableCell>
                    <TableCell style={{ width: "150px" }}>
                      Canteen Amount
                    </TableCell>
                    <TableCell style={{ width: "150px" }}>
                      Transportation Amount
                    </TableCell>
                    <TableCell style={{ width: "150px" }}>From Date</TableCell>
                    <TableCell style={{ width: "150px" }}>To Date</TableCell>
                    <TableCell style={{ width: "150px" }}>
                      Current Po No
                    </TableCell>

                    <TableCell style={{ width: "150px" }}>
                      Employee Name
                    </TableCell>
                    <TableCell style={{ width: "150px" }}>
                      Current PO Rate
                    </TableCell>
                    <TableCell style={{ width: "150px" }}>
                      Active Status
                    </TableCell>
                    <TableCell style={{ width: "150px" }}>
                      Release Date
                    </TableCell>
                    <TableCell style={{ width: "150px" }}>
                      Annenux Flag
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {invoiceEmployeePoDetailsUIList.invoiceEmployeePoDetailsUIList.map(
                    (project, index) => (
                      <TableRow key={index}>
                        <TableCell style={{ width: "30px" }}>
                          {index + 1}
                        </TableCell>
                        <TableCell style={{ width: "30px" }}>
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => handleDeleteRow(index)}
                          >
                            X
                          </Button>
                        </TableCell>
                        <TableCell style={{ width: "150px" }}>
                          <TextField
                            fullWidth
                            value={project.presenty}
                            onChange={handleInvoiceEmployeeDetailsChange(
                              index,
                              "presenty"
                            )}
                          />
                        </TableCell>
                        <TableCell style={{ width: "150px" }}>
                          <TextField
                            fullWidth
                            value={project.presentyAmt}
                            onChange={handleInvoiceEmployeeDetailsChange(
                              index,
                              "presentyAmt"
                            )}
                          />
                        </TableCell>
                        <TableCell style={{ width: "150px" }}>
                          {project.canteenAmt}
                        </TableCell>
                        <TableCell style={{ width: "150px" }}>
                          {project.transportationAmt}
                        </TableCell>
                        <TableCell style={{ width: "100px" }}>
                          <TextField
                            type="date"
                            fullWidth
                            value={project.fromDate}
                            onChange={handleInvoiceEmployeeDetailsChange(
                              index,
                              "fromDate"
                            )}
                          />
                        </TableCell>
                        <TableCell style={{ width: "100px" }}>
                          <TextField
                            type="date"
                            fullWidth
                            value={project.toDate}
                            onChange={handleInvoiceEmployeeDetailsChange(
                              index,
                              "toDate"
                            )}
                          />
                        </TableCell>
                        <TableCell style={{ width: "150px" }}>
                          {project.currentPoNo}
                        </TableCell>

                        <TableCell style={{ width: "150px" }}>
                          {project.emplyoyeeName}
                        </TableCell>
                        <TableCell style={{ width: "150px" }}>
                          {project.currentPoRate}
                        </TableCell>

                        <TableCell style={{ width: "150px" }}>
                          {String(project.activeStatus)}
                        </TableCell>
                        <TableCell style={{ width: "150px" }}>
                          {project.releaseDate}
                        </TableCell>

                        <TableCell style={{ width: "150px" }}>
                          <Select
                            fullWidth
                            value={project.annenuxFlag ? "true" : "false"}
                            onChange={(event) => {
                              const newValue = event.target.value === "true";
                              handleInvoiceEmployeeDetailsChange(
                                index,
                                "annenuxFlag"
                              )(newValue);
                            }}
                          >
                            <MenuItem value="true">True</MenuItem>
                            <MenuItem value="false">False</MenuItem>
                          </Select>
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Paper>

        <Paper elevation={3} style={{ padding: "20px", marginBottom: "20px" }}>
          <Paper
            elevation={3}
            style={{ padding: "20px", marginBottom: "20px" }}
          >
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Paper
                  elevation={3}
                  style={{ padding: "20px", marginBottom: "20px" }}
                >
                  <TextField
                    label="Total Presenty Amount"
                    sx={{ marginBottom: 2, marginTop: 2 }}
                    variant="standard"
                    value={invoiceCalculation.totalPresentyAmount}
                    onChange={(e) =>
                      handleInvoiceCalculationChange(
                        "totalPresentyAmount",
                        e.target.value
                      )
                    }
                    fullWidth
                  />

                  <TextField
                    label="Total Tax Amount"
                    sx={{ marginBottom: 2, marginTop: 2 }}
                    variant="standard"
                    size="small"
                    value={invoiceCalculation.totalTaxAmt}
                    fullWidth
                  />
                  <TextField
                    label="Grand Total Amount"
                    sx={{ marginBottom: 2, marginTop: 2 }}
                    variant="standard"
                    size="small"
                    value={invoiceCalculation.grandTotalAmount}
                    fullWidth
                    style={{ color: "black" }}
                  />
                  <TextField
                    label=" Deduction Amount"
                    sx={{ marginBottom: 2, marginTop: 2 }}
                    variant="standard"
                    size="small"
                    value={invoiceCalculation.deductionAmount}
                    fullWidth
                  />
                </Paper>
              </Grid>
              <Grid item xs={3}>
                <Paper
                  elevation={3}
                  style={{ padding: "20px", marginBottom: "20px" }}
                >
                  <TextField
                    label="Deduction Particular"
                    sx={{ marginBottom: 2, marginTop: 2 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="standard"
                    size="small"
                    multiline
                    rows={4}
                    value={invoiceCalculation.deductionParticular}
                    onChange={(e) =>
                      handleInvoiceCalculationChange(
                        "deductionParticular",
                        e.target.value
                      )
                    }
                    fullWidth
                    style={{ fontSize: "16px" }}
                  />
                </Paper>
              </Grid>
              <Grid item xs={3}>
                <Paper
                  elevation={3}
                  style={{ padding: "20px", marginBottom: "20px" }}
                >
                  <TextField
                    label="CGST"
                    sx={{ marginBottom: 2, marginTop: 2 }}
                    variant="standard"
                    size="small"
                    value={invoiceCalculation.cgst}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    onChange={(e) =>
                      handleInvoiceCalculationChange("cgst", e.target.value)
                    }
                  />
                  <TextField
                    label="SGST"
                    sx={{ marginBottom: 2, marginTop: 2 }}
                    variant="standard"
                    size="small"
                    value={invoiceCalculation.sgst}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    onChange={(e) =>
                      handleInvoiceCalculationChange("sgst", e.target.value)
                    }
                  />
                  <TextField
                    label="IGST"
                    sx={{ marginBottom: 2, marginTop: 2 }}
                    variant="standard"
                    size="small"
                    value={invoiceCalculation.igst}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    onChange={(e) =>
                      handleInvoiceCalculationChange("igst", e.target.value)
                    }
                  />
                  <TextField
                    label="Total Tax Amount"
                    sx={{ marginBottom: 2, marginTop: 2 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="standard"
                    size="small"
                    value={invoiceCalculation.totalTaxAmt}
                    fullWidth
                  />
                  <TextField
                    label="Total in Words"
                    sx={{ marginBottom: 2, marginTop: 2 }}
                    multiline
                    rows={4}
                    variant="standard"
                    size="small"
                    value={invoiceCalculation.totalInWord}
                    fullWidth
                    disabled
                  />
                </Paper>
              </Grid>
              <Grid item xs={3}>
                <Paper
                  elevation={3}
                  style={{ padding: "20px", marginBottom: "20px" }}
                >
                  <Button variant="contained" onClick={handleCalculate}>
                    Calculate
                  </Button>
                </Paper>
              </Grid>
            </Grid>
          </Paper>
        </Paper>
        <Paper elevation={3} style={{ padding: "20px", marginBottom: "20px" }}>
          <Paper
            elevation={3}
            style={{ padding: "20px", marginBottom: "20px" }}
          >
            <Paper
              elevation={3}
              style={{ padding: "10px", marginBottom: "10px" }}
            >
              <Typography
                variant="h5"
                gutterBottom
                style={{ marginBottom: "15px", color: "#2196f3" }}
              >
                Payment Mode Details
              </Typography>
            </Paper>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <FormControl fullWidth margin="dense">
                  <InputLabel shrink htmlFor="ModeTermsOfPayment">
                    Mode Terms Of Payment
                  </InputLabel>
                  <Select
                    label="Mode Terms Of Payment"
                    fullWidth
                    value={paymentMode.ModeTermsOfPayment}
                    onChange={handlePaymentModeChange("ModeTermsOfPayment")}
                    variant="standard"
                    margin="dense"
                    displayEmpty
                  >
                    <MenuItem value="" disabled>
                      ModeTerm of Payment
                    </MenuItem>
                    <MenuItem value="15 Days Bank Transfer">
                      15 Days Bank Transfer
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Kind Attention"
                  fullWidth
                  value={paymentMode.KindAttention}
                  onChange={handlePaymentModeChange("KindAttention")}
                  variant="standard"
                  margin="dense"
                  disabled
                />
              </Grid>
              <Grid item xs={4}>
                <FormControl
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  style={{ marginBottom: 20 }}
                >
                  <InputLabel shrink htmlFor="SelectBankAccNo">
                    Select Bank Acc No
                  </InputLabel>
                  <Select
                    select
                    label="Select Bank Acc No"
                    value={paymentMode.SelectBankAccNo || ""}
                    fullWidth
                    onChange={handlePaymentModeChange("SelectBankAccNo")}
                    variant="standard"
                    margin="dense"
                    displayEmpty
                  >
                    <MenuItem value="" disabled>
                      Select Bank Acc No
                    </MenuItem>
                    {bankAccNoAndIfscCodes.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Paper>
        </Paper>
        <Paper
          style={{ display: "flex", justifyContent: "center", padding: "10px" }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            style={{ marginLeft: "20px", marginBottom: "20px" }}
            disabled={!isCalculateClicked} // Disable if Calculate button is not clicked
          >
            Submit
          </Button>

          <Button
            variant="contained"
            color="primary"
            onClick={handlegeneratePDF}
            style={{ marginLeft: "20px", marginBottom: "20px" }}
          >
            PDF
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleCancel}
            style={{ marginLeft: "20px", marginBottom: "20px" }}
          >
            Cancel
          </Button>
        </Paper>
      </Card>
    </Container>
  );
};

export default GenerateEmployeeInvoice2;
