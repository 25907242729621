import Headers from "../../../Pages/Header";
import GenerateVendor from "../GenerateVendor";

const GenerateVendorPage = () => {
  return (
    <Headers>
      <GenerateVendor />
    </Headers>
  );
};
export default GenerateVendorPage;
