import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Collapse,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  TablePagination,
  Grid,
  Box,
  CircularProgress,
  Backdrop,
  MenuItem,
} from "@material-ui/core";
import { Autocomplete } from "@mui/material";
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import { myAxiosCommonHost } from "../../Service/CorsHelperBaseUrl";
import * as XLSX from "xlsx";
import { CompanyContext } from "../StateProvider/CompanyProvider";
import { useParams } from "react-router-dom";

const Row = ({ row, onDelete, onUpdate, refreshData }) => {
  const [open, setOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogOpenDraft, setDialogOpenDraft] = useState(false);
  const [dialogOpenCopy, setDialogOpenCopy] = useState(false);
  const [editedAgreement, setEditedAgreement] = useState({ ...row });
  const [isDataChangedEdit, setIsDataChangedEdit] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [downloadSuccess, setDownloadSuccess] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false); // State to control deletion overlay
  const [editedAgreementDraft, setEditedAgreementDraft] = useState(
    Array.isArray(row.agreementDraft) ? [...row.agreementDraft] : []
  );
  const [editedAgreementCopy, setEditedAgreementCopy] = useState(
    Array.isArray(row.agreementCopy) ? [...row.agreementCopy] : []
  );

  const handleEdit = () => {
    setDialogOpen(true);
  };

  const handleEditDraft = () => {
    setDialogOpenDraft(true);
  };

  const handleDelete = () => {
    onDelete(row.agreementUniqueCode, row.clientCode);
  };

  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    setEditedAgreement((prevAgreement) => ({
      ...prevAgreement,
      [name]: value,
    }));
    setIsDataChangedEdit(true);
  };

  const handleSave = () => {
    const agreement = {
      ...editedAgreement,
      agreementCopy: editedAgreementCopy,
      agreementDraft: editedAgreementDraft,
    };
    onUpdate(agreement);
    setDialogOpen(false);
    setDialogOpenDraft(false);
    setDialogOpenCopy(false);
  };
  const handleDeleteFileDraft = (agreementUniqueCode, fileName) => {
    if (window.confirm("Are you sure you want to delete this File?")) {
      myAxiosCommonHost
        .delete(
          `gst/clientAgreement/deleteClientAgreementDraft?agreementCode=${agreementUniqueCode}&fileName=${fileName}`
        )
        .then((response) => {
          console.log("ClientAgreementDraft Deleted successfully.");
          window.alert("ClientAgreementDraft Deleted successfully.");
          refreshData();
        })
        .catch((error) => {
          console.error("Error Deleting ClientAgreementDraft: ", error);
          //setIsDeleting(false); // Hide deletion overlay on error
        });
    }
  };

  const handleDeleteFileCopy = (agreementUniqueCode, fileName) => {
    if (window.confirm("Are you sure you want to delete this File?")) {
      myAxiosCommonHost
        .delete(
          `gst/clientAgreement/deleteClientAgreementCopy?agreementCode=${agreementUniqueCode}&fileName=${fileName}`
        )
        .then((response) => {
          console.log("ClientAgreementDraft Deleted successfully.");
          window.alert("ClientAgreementDraft Deleted successfully.");
          refreshData();
        })
        .catch((error) => {
          console.error("Error Deleting ClientAgreementDraft: ", error);
          //setIsDeleting(false); // Hide deletion overlay on error
        });
    }
  };

  const handleFileDownloadNew = (base64Data, fileName, mimeType) => {
    // Decode base64 string into binary data
    const binaryString = atob(base64Data);
    const binaryLength = binaryString.length;
    const bytes = new Uint8Array(binaryLength);

    // Convert binary string to Uint8Array
    for (let i = 0; i < binaryLength; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }

    // Create a Blob from the Uint8Array
    const blob = new Blob([bytes], { type: mimeType });

    // Create a URL for the Blob
    const url = URL.createObjectURL(blob);

    // Create a temporary anchor element to trigger the download
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);

    // Trigger the download
    link.click();

    // Clean up
    link.remove();
    URL.revokeObjectURL(url);
  };

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell>{row.agreementUniqueCode}</TableCell>
        <TableCell>{row.clientName}</TableCell>
        <TableCell>{row.agreementCode}</TableCell>
        <TableCell>{row.clientCode}</TableCell>
        <TableCell>
          <Button onClick={handleEdit} color="primary" variant="contained">
            Update
          </Button>
          <Button
            onClick={handleDelete}
            color="error"
            variant="contained"
            style={{
              marginLeft: "8px",
              backgroundColor: "red",
              color: "white",
            }}
          >
            Delete
          </Button>
        </TableCell>
        {/* <TableCell>
          <Button onClick={handleEditDraft} color="primary" variant="contained">
            Agreemt Draft
          </Button>
        </TableCell> */}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <div>
              <Box style={{ padding: 20 }}>
                <Typography variant="h6">Agreement Details</Typography>
                {row ? (
                  <div>
                    <TableRow
                      style={{
                        backgroundColor: "darkcyan",
                        fontWeight: "bold",
                        color: "darkblue",
                      }}
                    >
                      <TableCell>Agreement Code</TableCell>
                      <TableCell>Client Code</TableCell>
                      <TableCell>Client Name</TableCell>
                      <TableCell>Department</TableCell>
                      <TableCell>Agreement Type</TableCell>
                      <TableCell>Agreement Date</TableCell>
                      <TableCell>Duration</TableCell>
                      <TableCell>Start Date</TableCell>
                      <TableCell>End Date</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{row.agreementCode}</TableCell>
                      <TableCell>{row.clientCode}</TableCell>
                      <TableCell>{row.clientName}</TableCell>
                      <TableCell>{row.department}</TableCell>
                      <TableCell>{row.agreementType}</TableCell>
                      <TableCell>{row.agreementDate}</TableCell>
                      <TableCell>{row.duration}</TableCell>
                      <TableCell>{row.startDate}</TableCell>
                      <TableCell>{row.endDate}</TableCell>
                    </TableRow>
                  </div>
                ) : (
                  <TableRow>
                    <TableCell colSpan={3} align="center">
                      No Agreement Details found
                    </TableCell>
                  </TableRow>
                )}
                <Typography variant="h6">Agreement Draft File</Typography>
                {row.agreementDraft ? (
                  row.agreementDraft.map((agrmtDraft, index) => (
                    <div key={index}>
                      <TableRow
                        style={{
                          backgroundColor: "darkcyan",
                          fontWeight: "bold",
                          color: "darkblue",
                        }}
                      >
                        <TableCell>File Name</TableCell>
                        <TableCell>Agreement Unique Code</TableCell>
                        <TableCell>Download</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>{agrmtDraft?.fileName || "N/A"}</TableCell>
                        <TableCell>
                          {agrmtDraft?.agreementUniqueCode || "N/A"}
                        </TableCell>
                        <TableCell>
                          <Button
                            onClick={() =>
                              handleFileDownloadNew(
                                agrmtDraft.data,
                                agrmtDraft.fileName,
                                agrmtDraft.fileType
                              )
                            }
                            color="primary"
                            variant="contained"
                          >
                            Download File
                          </Button>
                          {/* {downloadProgress > 0 && (
                            <progress value={downloadProgress} max="100">
                              {downloadProgress}%
                            </progress>
                          )}
                          {downloadSuccess && (
                            <p>File downloaded successfully!</p>
                          )} */}

                          <Button
                            onClick={() =>
                              handleDeleteFileDraft(
                                agrmtDraft.agreementUniqueCode,
                                agrmtDraft.fileName
                              )
                            }
                            color="primary"
                            variant="contained"
                            style={{
                              marginLeft: "10px",
                              backgroundColor: "red",
                            }}
                          >
                            Delete
                          </Button>
                        </TableCell>
                      </TableRow>
                    </div>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      No AgreemtDraft Details Found
                    </TableCell>
                  </TableRow>
                )}
                <Typography variant="h6">Agreement Copy File</Typography>
                {row.agreementCopy ? (
                  row.agreementCopy.map((agrmtCopy, index) => (
                    <div key={index}>
                      <TableRow
                        style={{
                          backgroundColor: "darkcyan",
                          fontWeight: "bold",
                          color: "darkblue",
                        }}
                      >
                        <TableCell>File Name</TableCell>
                        <TableCell>Agreement Unique Code</TableCell>
                        <TableCell>Download</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>{agrmtCopy?.fileName || "N/A"}</TableCell>
                        <TableCell>
                          {agrmtCopy?.agreementUniqueCode || "N/A"}
                        </TableCell>
                        <TableCell>
                          <Button
                            onClick={() =>
                              handleFileDownloadNew(
                                agrmtCopy.data,
                                agrmtCopy.fileName,
                                agrmtCopy.fileType
                              )
                            }
                            color="primary"
                            variant="contained"
                          >
                            Download File
                          </Button>
                          {/* {downloadProgress > 0 && (
                            <progress value={downloadProgress} max="100">
                              {downloadProgress}%
                            </progress>
                          )}
                          {downloadSuccess && (
                            <p>File downloaded successfully!</p>
                          )} */}
                          <Button
                            onClick={() =>
                              handleDeleteFileCopy(
                                agrmtCopy.agreementUniqueCode,
                                agrmtCopy.fileName
                              )
                            }
                            color="primary"
                            variant="contained"
                            style={{
                              marginLeft: "10px",
                              backgroundColor: "red",
                            }}
                          >
                            Delete
                          </Button>
                        </TableCell>
                      </TableRow>
                    </div>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      No Agreemt Copy Details Found
                    </TableCell>
                  </TableRow>
                )}
              </Box>
            </div>
          </Collapse>
        </TableCell>
      </TableRow>

      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        maxWidth="md"
        maxHeight="md"
        fullWidth
      >
        <DialogTitle>Edit Agreement Information</DialogTitle>
        <DialogContent>
          <Typography variant="h6">Agreement Information</Typography>
          <Paper
            elevation={3}
            style={{ padding: 10, marginBottom: 10, marginTop: 10 }}
          >
            <Grid container spacing={3} style={{ marginTop: 10 }}>
              <Grid item xs={12} md={4}>
                <TextField
                  label="Select Company"
                  name="selectCompany"
                  value={editedAgreement.selectCompany}
                  onChange={handleFieldChange}
                  variant="standard"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  label="Financial Year"
                  name="financialYear"
                  value={editedAgreement.financialYear}
                  onChange={handleFieldChange}
                  variant="standard"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  label="Client Code"
                  name="clientCode"
                  value={editedAgreement.clientCode}
                  onChange={handleFieldChange}
                  variant="standard"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  label="Client Name"
                  name="clientName"
                  value={editedAgreement.clientName}
                  onChange={handleFieldChange}
                  variant="standard"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  label="Department"
                  name="department"
                  value={editedAgreement.department}
                  onChange={handleFieldChange}
                  variant="standard"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  label="Agreement Type"
                  name="agreementType"
                  value={editedAgreement.agreementType}
                  onChange={handleFieldChange}
                  variant="standard"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  label="Agreement Date"
                  name="agreementDate"
                  value={editedAgreement.agreementDate}
                  onChange={handleFieldChange}
                  variant="standard"
                  type="date"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  label="Duration"
                  name="duration"
                  value={editedAgreement.duration}
                  onChange={handleFieldChange}
                  variant="standard"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  label="Start Date"
                  name="startDate"
                  value={editedAgreement.startDate}
                  onChange={handleFieldChange}
                  variant="standard"
                  type="date"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  label="End Date"
                  name="endDate"
                  value={editedAgreement.endDate}
                  onChange={handleFieldChange}
                  variant="standard"
                  type="date"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            color="primary"
            disabled={!isDataChangedEdit}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={dialogOpenDraft}
        onClose={() => dialogOpenDraft(false)}
        maxWidth="md"
        maxHeight="md"
        fullWidth
      >
        <DialogTitle>Edit Agreement Information</DialogTitle>
        <DialogContent>
          <Typography variant="h6">Agreement Draft</Typography>
          {editedAgreementDraft.map((agreementDraft, index) => (
            <Paper
              elevation={3}
              style={{ padding: 10, marginBottom: 10, marginTop: 10 }}
            >
              <Grid container spacing={3} style={{ marginTop: 10 }}>
                <Grid item xs={12} md={4}>
                  <TextField
                    label="Select Company"
                    name="selectCompany"
                    value={agreementDraft.fileName}
                    onChange={handleFieldChange}
                    variant="standard"
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Paper>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpenDraft(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            color="primary"
            disabled={!isDataChangedEdit}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const UpdateClientAgreement = () => {
  const [agreementData, setAgreementData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  // const [selectedCompany, setSelectedCompany] = useState("");
  const [financialYear, setFinancialYear] = useState([]);
  const [data, setData] = useState(null);
  const [searchAgreementCode, setSearchAgreementCode] = useState("");
  const [error, setError] = useState(null);
  //const [selectedOption, setSelectedOption] = useState("1");
  const { selectedCompany } = useContext(CompanyContext);
  const { selectedFinancialYear } = useContext(CompanyContext);
  const [selectedOption, setSelectedOption] = useState(null);
  const { uniqueAgreementCode } = useParams();

  const handleOptionChange = (event, newValue) => {
    setSelectedOption(newValue);
  };
  const getCurrentFinancialYear = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const fiscalStartYear = currentYear - (currentDate.getMonth() >= 3 ? 0 : 1);
    const fiscalEndYear = fiscalStartYear + 1;
    return `${(fiscalStartYear % 100).toString().padStart(2, "0")}-${(
      fiscalEndYear % 100
    )
      .toString()
      .padStart(2, "0")}`;
  };
  const defaultFinancialYear = getCurrentFinancialYear();
  // const [selectedFinancialYear, setSelectedFinancialYear] =
  //   useState(defaultFinancialYear);
  const options = [
    { label: "Download All Clients Agreement", value: "1" },
    // Add more options as needed
  ];

  async function fetchFinancialYear() {
    try {
      const response = await myAxiosCommonHost.get("gst/invoice/getFiscalYear");
      setFinancialYear(response.data);
    } catch (error) {
      console.error("Error fetching financial year data:", error);
    }
  }
  useEffect(() => {
    fetchFinancialYear();
  }, []);

  // const handleFinancialYearSelect = (financialYear) => {
  //   setSelectedFinancialYear(financialYear);
  //   console.log("Selected Financial Year:", financialYear);
  // };

  // useEffect(() => {
  //   async function fetchCompany() {
  //     try {
  //       const response = await myAxiosCommonHost.get(
  //         "gst/profile/getCompanyCodeList"
  //       );
  //       const companies = response.data;
  //       setCompany(companies);
  //       if (companies.length > 0) {
  //         setSelectedCompany(companies[0]);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching company data:", error);
  //     }
  //   }
  //   fetchCompany();
  // }, []);
  const fetchAgreementCount = () => {
    myAxiosCommonHost
      .get(
        `gst/clientAgreement/getAgreementCount?selectCompany=${selectedCompany}&financialYear=${selectedFinancialYear}`
      )
      .then((response) => {
        const count = parseInt(response.data); // Extract count from API response
        setTotalCount(count); // Set total count state
      })
      .catch((error) => {
        console.error("Error fetching Agreement count:", error);
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const limit = rowsPerPage;
        const offset = page * rowsPerPage;
        const response = await myAxiosCommonHost.get(
          `gst/clientAgreement/getClientAgreementList?selectCompany=${selectedCompany}&limit=${limit}&offset=${offset}`
        );
        setData(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);

        setLoading(false);
      }
    };
    if (selectedCompany) {
      fetchData();
      fetchAgreementCount();
    }
  }, [selectedCompany, rowsPerPage, page]);

  const fetchAgreementData = () => {
    if (selectedCompany) {
      const limit = rowsPerPage;
      const offset = page * rowsPerPage; // Correct offset calculation
      setLoading(true);
      myAxiosCommonHost
        .get(
          `gst/clientAgreement/getClientAgreementList?selectCompany=${selectedCompany}&limit=${limit}&offset=${offset}`
        )
        .then((response) => {
          setData(response.data);
          fetchAgreementCount();
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching Employee data:", error);
          setLoading(false);
        });
    }
  };
  const handleViewAgreement = async () => {
    try {
      setLoading(true);
      const response = await myAxiosCommonHost.get(
        `gst/clientAgreement/viewNotification?uniqueAgreementCode=${uniqueAgreementCode}`
      );
      setData(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error searching employeePo:", error);
      setLoading(false);
    }
  };
  useEffect(() => {
    if (uniqueAgreementCode) {
      handleViewAgreement();
    }
  }, [uniqueAgreementCode]);

  const handleDelete = async (agreementCode, clientCode) => {
    try {
      await myAxiosCommonHost.delete(
        `gst/clientAgreement/deleteClientAgreement?agreementCode=${agreementCode}&clientCode=${clientCode}`
      );
      setAgreementData(
        agreementData.filter((vendor) => vendor.agreementCode !== agreementCode)
      );
    } catch (error) {
      console.error("Error deleting vendor data:", error);
    }
  };

  const handleUpdate = async (updatedAgreement) => {
    try {
      const response = await myAxiosCommonHost.put(
        `/gst/clientAgreement/updateCreateAgreement`,
        updatedAgreement
      );
      setAgreementData((prevVendors) =>
        prevVendors.map((vendor) =>
          vendor.agreementCode === updatedAgreement.agreementCode
            ? response.data
            : vendor
        )
      );
    } catch (error) {
      console.error("Error updating vendor data:", error);
    }
  };

  const handleSearch = async () => {
    try {
      setLoading(true);
      const limit = rowsPerPage;
      const offset = page * rowsPerPage;
      const response = await myAxiosCommonHost.get(
        `gst/clientAgreement/searchClientAgreement?agreementCode=${searchAgreementCode}&selectCompany=${selectedCompany}&limit=${limit}&offset=${offset}`
      );
      setData(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error searching employeePo:", error);
      setError(error);
      setLoading(false);
    }
  };

  // const handleCompanySelect = (company) => {
  //   setSelectedCompany(company);
  //   console.log("Selected Company Year:", company);
  // };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  // const handleOptionChange = (event) => {
  //   setSelectedOption(event.target.value);
  // };

  const downloadExcelFile = async () => {
    try {
      let apiUrl = `/gst/clientAgreement/getClientAgreementListForExcel?selectCompany=${selectedCompany}`;
      if (financialYear) {
        apiUrl += `&financialYear=${selectedFinancialYear}`;
      }

      const response = await myAxiosCommonHost.get(apiUrl);

      const data = response.data;

      if (!data) {
        console.error("Data is not available or empty.");
        return;
      }

      const workbook = XLSX.utils.book_new();

      const clientAgreementExcelUIList = data.clientAgreementExcelUIList || [];

      if (clientAgreementExcelUIList.length > 0) {
        const worksheet = XLSX.utils.json_to_sheet(clientAgreementExcelUIList);
        XLSX.utils.book_append_sheet(
          workbook,
          worksheet,
          "Client Agreement Details"
        );
      }

      const excelBuffer = XLSX.write(workbook, {
        type: "array",
        bookType: "xlsx",
      });

      const blob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const url = URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.target = "_blank";
      anchor.download = `${selectedCompany}_Client_Agreement_Details.xlsx`;
      anchor.click();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleFileDownload = async (agreementCode) => {
    try {
      // Create a URL for the blob
      const url = window.URL.createObjectURL(
        new Blob([data.agreementCopy.data])
      );
      const link = document.createElement("a");
      link.href = url;

      // Check if 'content-disposition' header is present
      const contentDisposition =
        data.agreementCopy.data.headers["content-disposition"];
      console.log(contentDisposition);
      let fileName = contentDisposition
        ? contentDisposition.split("filename=")[1].replace(/['"]/g, "") // Remove any surrounding quotes
        : "downloadedFile.docx"; // Default filename

      //   // Ensure the file extension is .pdf
      //   if (!fileName.endsWith(".pdf")) {
      //     fileName += ".pdf";
      //   }

      link.setAttribute("download", fileName);

      document.body.appendChild(link);
      link.click();
      link.remove(); // Remove the link after clicking
      window.URL.revokeObjectURL(url); // Clean up the URL
    } catch (error) {
      console.error("There was an error downloading the file!", error);
      alert("File download failed, please try again.");
    }
  };

  return (
    <div>
      <Paper
        elevation={3}
        style={{ padding: 20, marginBottom: 20, marginTop: 20 }}
      >
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <Link to="/registerClientAgreement">
              <Button
                variant="contained"
                color="primary"
                style={{ margin: "10px" }}
              >
                Register Agreement{" "}
              </Button>
            </Link>
          </Grid>

          <Grid item xs={6}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <TextField
                label="Agreement Code"
                value={searchAgreementCode}
                onChange={(e) => setSearchAgreementCode(e.target.value)}
                variant="standard"
                style={{ marginRight: "10px" }}
              />

              <Button
                variant="contained"
                color="primary"
                onClick={handleSearch}
                style={{ marginLeft: "10px", marginTop: 20 }}
              >
                Search
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Paper
          elevation={3}
          style={{ padding: 20, marginBottom: 20, marginTop: 20 }}
        >
          <Grid container spacing={2}>
            <Grid item xs={1} md={2}>
              {/* <Autocomplete
                  id="financial-year"
                  options={financialYear}
                  loading={loading}
                  value={selectedFinancialYear}
                  onChange={(event, newValue) =>
                    handleFinancialYearSelect(newValue)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Financial Year"
                      variant="standard"
                      style={{
                        backgroundColor: "white",
                      }}
                    />
                  )}
                /> */}
              <TextField
                label="Financial Year"
                value={selectedFinancialYear}
                InputLabelProps={{
                  shrink: true,
                }}
                disabled
                variant="standard"
                style={{ backgroundColor: "white", marginTop: "20px" }}
              />
            </Grid>
            <Grid item xs={4} md={2}>
              {/* <Autocomplete
                  id="company"
                  options={company}
                  loading={loading}
                  value={selectedCompany}
                  onChange={(event, newValue) => handleCompanySelect(newValue)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Company"
                      variant="standard"
                      style={{ backgroundColor: "white" }}
                    />
                  )}
                /> */}
              <TextField
                label="Select Company"
                value={selectedCompany}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                disabled
                variant="standard"
                style={{ backgroundColor: "white", marginTop: "20px" }}
              />
            </Grid>
            <Grid item xs={2} md={4}>
              <Autocomplete
                options={options}
                getOptionLabel={(option) => option.label}
                value={selectedOption}
                onChange={handleOptionChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Download Options"
                    variant="standard"
                    style={{ minWidth: 300, marginTop: 20, marginLeft: 20 }}
                  />
                )}
              />
              <Button
                variant="contained"
                style={{
                  backgroundColor: "green",
                  color: "white",
                  marginLeft: 25,
                  marginTop: 25,
                }}
                onClick={downloadExcelFile}
              >
                Download Excel
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Paper>
      <Paper
        elevation={3}
        style={{
          padding: 20,
          marginBottom: 20,
          marginTop: 20,
          backgroundColor: "#2196f3",
          color: "white",
          padding: "10px",
          textAlign: "center",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography
            variant="h5"
            gutterBottom
            style={{ marginBottom: "15px", color: "darkblue" }}
          >
            Client Agreement List
          </Typography>
        </div>
      </Paper>

      <TableContainer component={Paper} style={{ height: "20%" }}>
        {loading ? (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="inherit" />
            <Typography
              variant="h6"
              color="inherit"
              style={{ marginLeft: "10px" }}
            >
              Getting Data...
            </Typography>
          </Backdrop>
        ) : (
          <>
            <Table>
              <TableHead>
                <TableRow
                  style={{
                    backgroundColor: "darkcyan",
                    fontWeight: "bold",
                    color: "darkblue",
                  }}
                >
                  <TableCell />
                  <TableCell>Agreement Unique Code</TableCell>
                  <TableCell>Client Name</TableCell>
                  <TableCell>Agreement Code</TableCell>
                  <TableCell>Client Code</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data && data.length > 0 ? (
                  data.map((row) => (
                    <Row
                      key={row.agreementUniqueCode}
                      row={row}
                      onDelete={handleDelete}
                      onUpdate={handleUpdate}
                      onDownLoad={handleFileDownload}
                      refreshData={fetchAgreementData}
                    />
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      No Agreement data available
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        )}
      </TableContainer>
    </div>
  );
};

export default UpdateClientAgreement;
