import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Collapse,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  TablePagination,
  Grid,
  Tab,
  Tabs,
  Box,
  CircularProgress,
  Backdrop,
  MenuItem,
} from "@material-ui/core";
import { Autocomplete } from "@mui/material";
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  Edit as EditIcon,
  Search,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import * as XLSX from "xlsx";
import { myAxiosCommonHost } from "../../Service/CorsHelperBaseUrl";
import { CompanyContext } from "../StateProvider/CompanyProvider";
import { saveAs } from "file-saver";

const Row = ({ row, onDelete, onUpdate }) => {
  const [open, setOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [editedVendor, setEditedVendor] = useState({ ...row });
  const [editedVenoderExpenseDetails, setEditedVenoderExpenseDetails] =
    useState({
      ...row.venoderExpenseDetailsUI,
    });
  const [tabValue, setTabValue] = useState(0);
  const [isDataChangedEdit, setIsDataChangedEdit] = useState(false);

  const handleEdit = () => {
    setDialogOpen(true);
  };

  const handleDelete = () => {
    onDelete(row.voucherInvoiceNo);
  };

  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    setEditedVendor((prevVendor) => ({ ...prevVendor, [name]: value }));
    setIsDataChangedEdit(true);
  };

  const handleVenoderExpenseDetailsChange = (e) => {
    const { name, value } = e.target;
    setEditedVenoderExpenseDetails((prevGstDetails) => ({
      ...prevGstDetails,
      [name]: value,
    }));
    setIsDataChangedEdit(true);
  };

  const handleSave = () => {
    const updatedVendor = {
      ...editedVendor,
      venoderExpenseDetailsUI: editedVenoderExpenseDetails,
    };
    onUpdate(updatedVendor);
    setDialogOpen(false);
  };
  if (!editedVendor) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell>{row.voucherInvoiceNo}</TableCell>
        <TableCell>{row.selectCompany}</TableCell>
        <TableCell>{row.financialYear}</TableCell>
        <TableCell>
          <Button onClick={handleEdit} variant="contained" color="primary">
            Update
          </Button>
          <Button
            onClick={handleDelete}
            color="error"
            variant="contained"
            style={{
              marginLeft: "8px",
              backgroundColor: "red",
              color: "white",
            }}
          >
            Delete
          </Button>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 10, paddingTop: 10 }} colSpan={5}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <div>
              <Box style={{ padding: 20 }}>
                <Typography variant="h6">Basic Information</Typography>
                <TableRow
                  style={{
                    backgroundColor: "darkcyan",
                    fontWeight: "bold",
                    color: "darkblue",
                  }}
                >
                  <TableCell>Voucher Invoice No:</TableCell>
                  <TableCell>Select Company:</TableCell>
                  <TableCell>Financial Year:</TableCell>
                  <TableCell>VendorName And Code:</TableCell>
                  <TableCell>VendorName:</TableCell>
                  <TableCell>VendorCode:</TableCell>
                  <TableCell>Expense Month:</TableCell>
                  <TableCell>Expense Type:</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{row.voucherInvoiceNo || "N/A"}</TableCell>
                  <TableCell>{row.selectCompany || "N/A"}</TableCell>
                  <TableCell>{row.financialYear || "N/A"}</TableCell>
                  <TableCell>{row.vendorNameAndCode || "N/A"}</TableCell>
                  <TableCell>{row.vendorName || "N/A"}</TableCell>
                  <TableCell>{row.vendorCode || "N/A"}</TableCell>
                  <TableCell>{row.expFor || "N/A"}</TableCell>
                  <TableCell>{row.expType || "N/A"}</TableCell>
                </TableRow>
                <Typography variant="h6">Venoder Expense Details</Typography>
                {row.venoderExpenseDetailsUI ? (
                  <div>
                    <TableRow
                      style={{
                        backgroundColor: "darkcyan",
                        fontWeight: "bold",
                        color: "darkblue",
                      }}
                    >
                      <TableCell>Voucher Invoice No</TableCell>
                      <TableCell>Invoice Expense Month</TableCell>
                      <TableCell>Invoice Expense Date</TableCell>
                      <TableCell>Discription</TableCell>
                      <TableCell>Invoice Value</TableCell>
                      <TableCell>Gst Rate</TableCell>
                      <TableCell>Taxble Amount</TableCell>
                      <TableCell>GST Amount</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        {row.venoderExpenseDetailsUI.voucherInvoiceNo || "N/A"}
                      </TableCell>
                      <TableCell>
                        {row.venoderExpenseDetailsUI.expMonth || "N/A"}
                      </TableCell>
                      <TableCell>
                        {row.venoderExpenseDetailsUI.invoiceExpenseDate ||
                          "N/A"}
                      </TableCell>
                      <TableCell>
                        {row.venoderExpenseDetailsUI.discription || "N/A"}
                      </TableCell>
                      <TableCell>
                        {row.venoderExpenseDetailsUI.gstRate || "N/A"}
                      </TableCell>
                      <TableCell>
                        {row.venoderExpenseDetailsUI.invoiceValue || "N/A"}
                      </TableCell>
                      <TableCell>
                        {row.venoderExpenseDetailsUI.taxbleAmount || "N/A"}
                      </TableCell>
                      <TableCell>
                        {row.venoderExpenseDetailsUI.gstAmount || "N/A"}
                      </TableCell>
                    </TableRow>
                  </div>
                ) : (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      No Venoder Expense Details Found
                    </TableCell>
                  </TableRow>
                )}
              </Box>
            </div>
          </Collapse>
        </TableCell>
      </TableRow>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        maxWidth="lg"
        maxHeight="lg"
        fullWidth
      >
        <DialogTitle>Edit Vendor Information</DialogTitle>
        <DialogContent>
          <Tabs
            value={tabValue}
            onChange={(event, newValue) => setTabValue(newValue)}
            variant="fullWidth"
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="Basic Information" />
            <Tab label="Venoder Expense Details" />
          </Tabs>
          {tabValue === 0 && (
            <>
              <Paper
                elevation={3}
                style={{ padding: 10, marginBottom: 10, marginTop: 10 }}
              >
                <Grid container spacing={6} style={{ marginTop: 10 }}>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="Voucher Invoice No"
                      name="voucherInvoiceNo"
                      value={editedVendor.voucherInvoiceNo || ""}
                      onChange={handleFieldChange}
                      fullWidth
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="Select Company"
                      name="selectCompany"
                      value={editedVendor.selectCompany || ""}
                      onChange={handleFieldChange}
                      fullWidth
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="Financial Year"
                      name="financialYear"
                      value={editedVendor.financialYear || ""}
                      onChange={handleFieldChange}
                      fullWidth
                      disabled
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="Vendor Name And Code"
                      name="vendorNameAndCode"
                      value={editedVendor.vendorNameAndCode || ""}
                      onChange={handleFieldChange}
                      fullWidth
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="Vendor Name"
                      name="vendorName"
                      value={editedVendor.vendorName || ""}
                      onChange={handleFieldChange}
                      fullWidth
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="Vendor Code"
                      name="vendorCode"
                      value={editedVendor.vendorCode || ""}
                      onChange={handleFieldChange}
                      fullWidth
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="Expenese For"
                      name="expFor"
                      value={editedVendor.expFor || ""}
                      onChange={handleFieldChange}
                      fullWidth
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="Expense Type"
                      name="expType"
                      value={editedVendor.expType || ""}
                      onChange={handleFieldChange}
                      fullWidth
                      variant="standard"
                    />
                  </Grid>
                </Grid>
              </Paper>
            </>
          )}
          {tabValue === 1 && (
            <>
              <Typography variant="h6">Venoder Expense Details</Typography>
              <Paper
                elevation={3}
                style={{ padding: 10, marginBottom: 10, marginTop: 10 }}
              >
                <Grid container spacing={6} style={{ marginTop: 10 }}>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="Voucher Invoice No"
                      name="voucherInvoiceNo"
                      value={
                        editedVenoderExpenseDetails.voucherInvoiceNo || "N/A"
                      }
                      onChange={handleVenoderExpenseDetailsChange}
                      fullWidth
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="Invoice Expense Date"
                      name="invoiceExpenseDate"
                      value={
                        editedVenoderExpenseDetails.invoiceExpenseDate || "N/A"
                      }
                      type="date"
                      onChange={handleVenoderExpenseDetailsChange}
                      fullWidth
                      variant="standard"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="Expense Month"
                      name={"expMonth"}
                      value={editedVenoderExpenseDetails.expMonth || "N/A"}
                      onChange={handleVenoderExpenseDetailsChange}
                      fullWidth
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="Discription"
                      name={"discription"}
                      value={editedVenoderExpenseDetails.discription || "N/A"}
                      onChange={handleVenoderExpenseDetailsChange}
                      fullWidth
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="Gst Rate"
                      name={"gstRate"}
                      value={editedVenoderExpenseDetails.gstRate || "N/A"}
                      onChange={handleVenoderExpenseDetailsChange}
                      fullWidth
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="Invoice Value"
                      name={"invoiceValue"}
                      value={editedVenoderExpenseDetails.invoiceValue || "N/A"}
                      onChange={handleVenoderExpenseDetailsChange}
                      fullWidth
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="Taxble Amount"
                      name={"taxbleAmount"}
                      value={editedVenoderExpenseDetails.taxbleAmount || "N/A"}
                      onChange={handleVenoderExpenseDetailsChange}
                      fullWidth
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="GST Amount"
                      name={"gstAmount"}
                      value={editedVenoderExpenseDetails.gstAmount || "N/A"}
                      onChange={handleVenoderExpenseDetailsChange}
                      fullWidth
                      variant="standard"
                    />
                  </Grid>
                </Grid>
              </Paper>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleSave} disabled={!isDataChangedEdit}>
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const UpdateVendor = () => {
  const [vendorData, setVendorData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false); // State to control deletion overlay
  const [isUpdating, setIsUpdating] = useState(false); // State to control update overlay
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0); // Initialize total count state
  const [searchVoucherInvoiceNo, setSearchVoucherInvoiceNo] = useState("");
  const [company, setCompany] = useState([]);
 //const [selectedCompany, setSelectedCompany] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const { selectedCompany } = useContext(CompanyContext);
  const { selectedFinancialYear } = useContext(CompanyContext);

  // useEffect(() => {
  //   async function fetchCompany() {
  //     try {
  //       const response = await myAxiosCommonHost.get(
  //         "gst/profile/getCompanyCodeList"
  //       );
  //       const companies = response.data;
  //       setCompany(companies);
  //       if (companies.length > 0) {
  //         setSelectedCompany(companies[0]); // Set the default company to the first one
  //       }
  //     } catch (error) {
  //       console.error("Error fetching company data:", error);
  //     }
  //   }
  //   fetchCompany();
  // }, []);

  const fetchVendorCount = () => {
    if (selectedCompany) {
      myAxiosCommonHost
        .get(`gst/expense/getVendorCount?selectCompany=${selectedCompany}`)
        .then((response) => {
          const count = parseInt(response.data); // Extract count from API response
          setTotalCount(count); // Set total count state
        })
        .catch((error) => {
          console.error("Error fetching employee count:", error);
        });
    }
  };
  useEffect(() => {
    if (selectedCompany) {
      fetchVendorData();
    }
  }, [selectedCompany]);

  const fetchVendorData = () => {
    if (selectedCompany) {
      const limit = rowsPerPage;
      const offset = page * rowsPerPage; // Correct offset calculation
      setLoading(true);
      myAxiosCommonHost
        .get(
          `gst/expense/getVendorList?selectCompany=${selectedCompany}&limit=${limit}&offset=${offset}`
        )
        .then((response) => {
          setVendorData(response.data);
          fetchVendorCount();
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching Employee data:", error);
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    fetchVendorCount();
    fetchVendorData();
  }, [rowsPerPage, page]);

  // const handleCompanySelect = (company) => {
  //   setSelectedCompany(company);
  //   console.log("Selected Company:", company);
  // };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleUpdateVendor = (updatedVendor) => {
    setIsUpdating(true); // Show update overlay
    myAxiosCommonHost
      .put("gst/expense/updateVendor", updatedVendor)
      .then((response) => {
        console.log("Vendor data updated successfully.");
        fetchVendorData();
        setIsUpdating(false); // Hide update overlay after update
      })
      .catch((error) => {
        console.error("Error updating Vendor data: ", error);
        setIsUpdating(false); // Hide update overlay on error
      });
  };
  const handleDeleteVendor = (voucherInvoiceNo) => {
    if (window.confirm("Are you sure you want to delete this Vendor?")) {
      setIsDeleting(true);
      const encodedVoucherInvoiceNo = encodeURIComponent(voucherInvoiceNo);
      myAxiosCommonHost
        .delete(
          `gst/expense/deleteVendor?voucherInvoiceNo=${encodedVoucherInvoiceNo}`
        )
        .then((response) => {
          console.log("Vendor Deleted successfully.");
          fetchVendorData();
          setIsDeleting(false); // Hide deletion overlay after deletion
        })
        .catch((error) => {
          console.error("Error Deleting Vendor: ", error);
          setIsDeleting(false); // Hide deletion overlay on error
        });
    }
  };
  const handleSearch = async () => {
    try {
      setLoading(true);
      const limit = rowsPerPage;
      const offset = page * rowsPerPage;
      const encodedSearchVoucherInvoiceNo = encodeURIComponent(
        searchVoucherInvoiceNo
      );
      const response = await myAxiosCommonHost.get(
        `gst/expense/searchVendor?voucherInvoiceNo=${encodedSearchVoucherInvoiceNo}&limit=${limit}&offset=${offset}`
      );
      setVendorData(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error searching employeePo:", error);
      setError(error);
      setLoading(false);
    }
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const downloadExcelFile = async () => {
    try {
      let apiUrl = `/gst/expense/getVendorListForExcel`;
      if (selectedOption === "1") {
        apiUrl += `?selectCompany=${selectedCompany}`;
      }

      const response = await myAxiosCommonHost.get(apiUrl);

      const data = response.data;

      if (!data) {
        console.error("Data is not available or empty.");
        return;
      }

      const workbook = XLSX.utils.book_new();

      const vendorExcelList = data.vendorExcelList || [];

      if (vendorExcelList.length > 0) {
        const worksheet1 = XLSX.utils.json_to_sheet(vendorExcelList);
        XLSX.utils.book_append_sheet(workbook, worksheet1, "Vendors Details");
      }

      const excelBuffer = XLSX.write(workbook, {
        type: "array",
        bookType: "xlsx",
      });

      const blob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const url = URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.target = "_blank";
      anchor.download = `${selectedCompany}_Vendor_Details.xlsx`; // Updated file name
      anchor.click();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <div>
      {isDeleting && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 9999,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ textAlign: "center", color: "white" }}>
            <CircularProgress style={{ marginBottom: "10px" }} />
            <Typography variant="h6">Vendor is Deleting...</Typography>
          </div>
        </div>
      )}
      {isUpdating && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 9999,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ textAlign: "center", color: "white" }}>
            <CircularProgress style={{ marginBottom: "10px" }} />
            <Typography variant="h6">Vendor is Updating...</Typography>
          </div>
        </div>
      )}
      <Paper
        elevation={3}
        style={{ padding: 20, marginBottom: 20, marginTop: 20 }}
      >
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <Link to="/generateVendor">
              <Button
                variant="contained"
                color="primary"
                style={{ margin: "10px" }}
              >
                Register Vendor
              </Button>
            </Link>
            <Button
              variant="contained"
              color="secondary"
              style={{ margin: "10px" }}
            >
              Vendor Count {totalCount}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                label="Voucher Invoice No"
                value={searchVoucherInvoiceNo}
                onChange={(e) => setSearchVoucherInvoiceNo(e.target.value)}
                variant="standard"
                style={{ marginRight: "10px" }}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleSearch}
              >
                Search
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Paper>
      <Paper
        elevation={3}
        style={{ padding: 20, marginBottom: 20, marginTop: 20 }}
      >
        <Grid container spacing={2}>
          <Grid item xs={2} md={4}>
            {/* <Autocomplete
              id="company"
              sx={{ width: 200, marginLeft: "10px" }}
              options={company}
              loading={loading}
              value={selectedCompany}
              onChange={(event, newValue) => handleCompanySelect(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Company"
                  variant="standard"
                  style={{ backgroundColor: "white", marginTop: "20px" }}
                />
              )}
            /> */}
            <TextField
              label="Select Company"
              value={selectedCompany}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              disabled
              variant="standard"
              style={{ backgroundColor: "white", marginTop: "20px" }}
            />
          </Grid>
          <Grid item xs={2} md={4}>
            <TextField
              select
              label="Download Options"
              value={selectedOption}
              onChange={handleOptionChange}
              variant="standard"
              style={{ minWidth: 200, marginRight: 16, marginTop: 20 }}
            >
              <MenuItem value="1">Download All Vendor Company Wise</MenuItem>
            </TextField>
            <Button
              variant="contained"
              style={{
                backgroundColor: "green",
                color: "white",
                marginTop: 25,
              }}
              onClick={downloadExcelFile}
            >
              Download Excel
            </Button>
          </Grid>
        </Grid>
      </Paper>
      <Paper
        elevation={3}
        style={{
          padding: 20,
          marginBottom: 20,
          marginTop: 20,
          backgroundColor: "#2196f3",
          color: "white",
          padding: "10px",
          textAlign: "center",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography
            variant="h5"
            gutterBottom
            style={{ marginBottom: "15px", color: "darkblue" }}
          >
            Vendor List
          </Typography>
        </div>
      </Paper>
      {error ? (
        <p>Error fetching data. Please try again.</p>
      ) : (
        <TableContainer component={Paper} style={{ height: "20%" }}>
          {loading ? (
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={loading}
            >
              <CircularProgress color="inherit" />
              <Typography
                variant="h6"
                color="inherit"
                style={{ marginLeft: "10px" }}
              >
                Getting Data...
              </Typography>
            </Backdrop>
          ) : (
            <>
              <Table>
                <TableHead>
                  <TableRow
                    style={{
                      backgroundColor: "darkcyan",
                      fontWeight: "bold",
                      color: "darkblue",
                    }}
                  >
                    <TableCell />
                    <TableCell>Voucher Invoice No</TableCell>
                    <TableCell>Select Company</TableCell>
                    <TableCell>Financial Year</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {vendorData.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={5} align="center">
                        No Vendor found
                      </TableCell>
                    </TableRow>
                  ) : (
                    vendorData.map((vendor) => (
                      <Row
                        key={vendor.voucherInvoiceNo}
                        row={vendor}
                        onDelete={handleDeleteVendor}
                        onUpdate={handleUpdateVendor}
                      />
                    ))
                  )}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[10, 15, 20]}
                component="div"
                count={totalCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage} // Make sure this line is correct
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </>
          )}
        </TableContainer>
      )}
    </div>
  );
};

export default UpdateVendor;
