import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Tab,
  Tabs,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Collapse,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TablePagination,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import DeleteIcon from "@material-ui/icons/Delete";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Box, IconButton } from "@mui/material";
import { myAxiosCommonHost } from "../Service/CorsHelperBaseUrl";

const UpdateEmpPOSearchPage = () => {
  const [data, setData] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [editedData, setEditedData] = useState(null);
  const [employeeDialogOpen, setEmployeeDialogOpen] = useState(false);
  const [editedEmployeeData, setEditedEmployeeData] = useState(null);
  const [currentTab, setCurrentTab] = useState(0);
  const [releaseDialogOpen, setReleaseDialogOpen] = useState(false);
  const [expandedRow, setExpandedRow] = useState(null);
  const [addEmployeeDialogOpen, setAddEmployeeDialogOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [fetchError, setFetchError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(2);
  const [filteredData, setFilteredData] = useState(data);

  const [newEmployeeData, setNewEmployeeData] = useState({
    poNumber: "",
    clientName: "",
    purchaseOrderAmount: "",
    kindAttention: "",
    empPoUniqueNoAndGSCode: "",
    empPODetailsUI: [
      {
        employeeName: "",
        poNotes: "",
        clientName: "",
        poAssignDate: "",
        empPORateUI: {
          poPerMonth: "",
          poPerDay: "",
          poPerHour: "",
        },
        empPOAssignDetailsUI: {
          poStartDate: "",
          poEndDate: "",
          poAmount: "",
        },
        empPOClientAssesmentUI: {
          jobLocation: "",
          jobPost: "",
          poDate: "",
          employeeCategory: "",
        },
        empPOAssignFacilityUI: {
          canteenAmount: "",
          transportationAmount: "",
          poNumber: "",
        },
      },
    ],
  });
  const [employeeOptions, setEmployeeOptions] = useState([]);
  const [addedEmployees, setAddedEmployees] = useState([]);
  const [searchPoNumber, setSearchPoNumber] = useState("");
  const [searchClientName, setSearchClientName] = useState("");

  useEffect(() => {
    if (data) {
      setFilteredData(data);
    }
  }, [data]);

  useEffect(() => {
    myAxiosCommonHost
      .get("gst/employee/getEmployeeNameList")
      .then((response) => {
        setEmployeeOptions(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.error("Error fetching client data:", error);
      });
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "http://gsquareerp.gsquaretech.com:8082/gst/employeePo/getEmployeePO"
        );
        const result = await response.json();
        setData(result);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(error);
        setLoading(false);
        // Handle error, display an error message, or take appropriate action.
      }
    };

    fetchData();
  }, []);
  const handleAddEmployeeClick = (po) => {
    setNewEmployeeData({
      ...newEmployeeData,
      poNumber: po.poNumber,
      clientName: po.clientName,
      purchaseOrderAmount: po.purchaseOrderAmount,
      empPoUniqueNoAndGSCode: po.empPoUniqueNoAndGSCode,
      kindAttention: po.kindAttention,
    });
    setAddEmployeeDialogOpen(true);
  };
  const handleAddEmployeeDialogClose = () => {
    setAddEmployeeDialogOpen(false);
  };
  const handleReleaseButtonClick = (employeeData) => {
    setEditedEmployeeData(employeeData);
    setReleaseDialogOpen(true);
  };
  const handleReleaseDialogClose = () => {
    setReleaseDialogOpen(false);
  };

  const handleEditClick = (editedData) => {
    setEditedData(editedData);
    setOpenDialog(true);
  };

  const handleEmployeeEditClick = (employeeData) => {
    setEditedEmployeeData(employeeData);
    setEmployeeDialogOpen(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleAddToTable = () => {
    if (
      newEmployeeData.empPODetailsUI[0].employeeName.trim() === "" ||
      newEmployeeData.empPODetailsUI[0].poNotes.trim() === "" ||
      // newEmployeeData.empPODetailsUI[0].clientName.trim() === "" ||
      newEmployeeData.empPODetailsUI[0].poAssignDate.trim() === ""
    ) {
      alert("Cannot add null or empty data to the table");
      return;
    }
    setAddedEmployees([...addedEmployees, newEmployeeData]);
    setNewEmployeeData({
      empPODetailsUI: [
        {
          employeeName: "",
          poNotes: "",
          clientName: "",
          poAssignDate: "",
          empPORateUI: {
            poPerMonth: "",
            poPerDay: "",
            poPerHour: "",
          },
          empPOAssignDetailsUI: {
            poStartDate: "",
            poEndDate: "",
            poAmount: "",
          },
          empPOClientAssesmentUI: {
            jobLocation: "",
            jobPost: "",
            poDate: "",
            employeeCategory: "",
          },
          empPOAssignFacilityUI: {
            canteenAmount: "",
            transportationAmount: "",
            poNumber: "",
          },
        },
      ],
    });
  };

  const handleSendDataToBackend = async () => {
    if (addedEmployees.length === 0) {
      alert("Cannot submit without adding an employee to the table");
      return;
    }
    const requestData = {
      poNumber: addedEmployees[0].poNumber,
      clientName: addedEmployees[0].clientName,
      purchaseOrderAmount: addedEmployees[0].purchaseOrderAmount,
      empPoUniqueNoAndGSCode: addedEmployees[0].empPoUniqueNoAndGSCode,
      kindAttention: addedEmployees[0].kindAttention,
      empPODetailsUI: addedEmployees.flatMap((employee) =>
        employee.empPODetailsUI.map((empDetails) => ({
          employeeName: empDetails.employeeName,
          poNotes: empDetails.poNotes,
          clientName: empDetails.clientName,
          poAssignDate: empDetails.poAssignDate,
          empPORateUI: { ...empDetails.empPORateUI },
          empPOAssignDetailsUI: { ...empDetails.empPOAssignDetailsUI },
          empPOClientAssesmentUI: { ...empDetails.empPOClientAssesmentUI },
          empPOAssignFacilityUI: { ...empDetails.empPOAssignFacilityUI },
        }))
      ),
    };

    console.log("Request Data:", JSON.stringify(requestData, null, 2));
    try {
      const updateResponse = await myAxiosCommonHost.put(
        "gst/employeePo/updatePO",
        requestData
      );
      console.log("Update Response:", updateResponse.data);

      const getResponse = await myAxiosCommonHost.get(
        "gst/employeePo/getEmployeePO"
      );
      console.log("Get Response:", getResponse.data);
    } catch (error) {
      console.error("Error sending or fetching data from the backend:", error);
    }
  };

  const handleEmployeeDialogClose = () => {
    setEmployeeDialogOpen(false);
  };
  const handleUpdateData = async () => {
    try {
      const updatedData = {
        ...editedData,
        empPODetailsUI: [editedEmployeeData],
      };

      const updateResponse = await fetch(
        "http://gsquareerp.gsquaretech.com:8082/gst/employeePo/updatePO",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedData),
        }
      );

      if (updateResponse.ok) {
        console.log("Successfully updated data:", updatedData);
        console.alert("Successfully updated data");

        const fetchDataResponse = await fetch(
          "http://gsquareerp.gsquaretech.com:8082/gst/employeePo/getEmployeePO"
        );

        if (fetchDataResponse.ok) {
          const updatedResult = await fetchDataResponse.json();
          setData(updatedResult);
        } else {
          console.error("Failed to fetch updated data.");
        }

        setOpenDialog(false);
        setEmployeeDialogOpen(false);
      } else {
        console.error("Failed to update data.");
      }
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };
  const handleExpandClick = (index) => {
    setExpandedRow(expandedRow === index ? null : index);
  };
  const handleDeleteRow = (index) => {
    const updatedEmployees = [...addedEmployees];
    updatedEmployees.splice(index, 1);
    setAddedEmployees(updatedEmployees);
  };

  if (fetchError) {
    return <div>Error fetching data. Please try again later.</div>;
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  //   if (!data || data.length === 0) {
  //     return (
  //       <div>
  //         <p>No data found.</p>
  //         <Link to="/empPOADDPage" style={{ textDecoration: "none" }}>
  //           <Button
  //             variant="contained"
  //             color="secondary"
  //             style={{ marginTop: 20, marginRight: 10, marginBottom: "20px" }}
  //           >
  //             Generate Employee PO
  //           </Button>
  //         </Link>
  //       </div>
  //     );
  //   }
  // Add these functions to your component

  const handleSearch = () => {
    const searchTermLower = searchClientName.toLowerCase();
    const filtered = data.filter((po) => {
      return po.clientName.toLowerCase().includes(searchTermLower);
    });
    setFilteredData(filtered);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page when the number of rows per page changes
  };

  return (
    <div>
      <Link to="/generateEmployeePO" style={{ textDecoration: "none" }}>
        <Button
          variant="contained"
          color="secondary"
          style={{ marginTop: 20, marginRight: 10, marginBottom: "20px" }}
        >
          Generate Employee PO
        </Button>
      </Link>
      <Paper
        elevation={3}
        style={{
          padding: 20,
          marginBottom: 20,
          backgroundColor: "#2196f3",
          color: "white",
          padding: "10px",
          textAlign: "center",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography
            variant="h5"
            gutterBottom
            style={{ marginBottom: "15px", color: "darkblue" }}
          >
            Employee PO List
          </Typography>
        </div>
      </Paper>
      {/* <TextField
        label="Search by Po Number"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        style={{ float: "right", margin: "10px" }}
      /> */}
      <TextField
        label="Search by Client Name"
        value={searchClientName}
        onChange={(e) => setSearchClientName(e.target.value)}
        onBlur={handleSearch}
      />
      {error ? (
        <p>No data Found Add Employee PO.</p>
      ) : (
        <TableContainer component={Paper} style={{ height: "20%" }}>
          {loading ? (
            <p>Loading...</p>
          ) : (
            <>
              {data.length === 0 ? (
                <p>No data found.</p>
              ) : (
                <Table>
                  <TableHead>
                    <TableRow
                      style={{
                        backgroundColor: "darkcyan",
                        fontWeight: "bold",
                        color: "darkblue",
                      }}
                    >
                      <TableCell />
                      <TableCell>PO Number</TableCell>
                      <TableCell>Client Name</TableCell>
                      <TableCell>Purchase Order Amount</TableCell>
                      <TableCell>Po Unique Number</TableCell>
                      <TableCell>Kind Attention</TableCell>
                      <TableCell>Action</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredData.map((po, index) => (
                      <React.Fragment key={index}>
                        <TableRow>
                          <TableCell>
                            <IconButton
                              onClick={() => handleExpandClick(index)}
                              aria-expanded={expandedRow === index}
                              aria-label="show more"
                            >
                              <ExpandMoreIcon />
                            </IconButton>
                          </TableCell>
                          <TableCell>{po.poNumber}</TableCell>
                          <TableCell>{po.clientName}</TableCell>
                          <TableCell>{po.purchaseOrderAmount}</TableCell>
                          <TableCell>{po.empPoUniqueNoAndGSCode}</TableCell>
                          <TableCell>{po.kindAttention}</TableCell>

                          <TableCell>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => handleEditClick(po)}
                            >
                              Update PO
                            </Button>
                          </TableCell>
                          <TableCell>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => handleAddEmployeeClick(po)}
                            >
                              Add Employee
                            </Button>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={5}>
                            <Collapse
                              in={expandedRow === index}
                              timeout="auto"
                              unmountOnExit
                            >
                              {(po.empPODetailsUI || []).map(
                                (employee, empIndex) => (
                                  <Accordion key={empIndex}>
                                    <AccordionSummary
                                      expandIcon={<ExpandMoreIcon />}
                                      aria-controls={`panel${index}-content`}
                                      id={`panel${index}-header`}
                                      IconButtonProps={{ edge: "start" }}
                                    >
                                      <Typography>
                                        {"Employee Name -:"}
                                        {
                                          po.empPODetailsUI[empIndex]
                                            .employeeName
                                        }
                                      </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                      <Table style={{ width: "100%" }}>
                                        <TableBody>
                                          <TableRow
                                            style={{
                                              backgroundColor: "goldenrod",
                                              fontWeight: "bold",
                                              color: "darkblue",
                                            }}
                                          >
                                            <TableCell>Employee Name</TableCell>
                                            <TableCell>Client Name</TableCell>
                                            <TableCell>PO Notes</TableCell>
                                            <TableCell>
                                              PO Assign Date
                                            </TableCell>
                                            <TableCell>Action</TableCell>
                                            <TableCell></TableCell>
                                          </TableRow>
                                          <TableRow>
                                            <TableCell>
                                              {employee?.employeeName || "N/A"}
                                            </TableCell>
                                            <TableCell>
                                              {employee?.clientName || "N/A"}
                                            </TableCell>
                                            <TableCell>
                                              {employee?.poNotes || "N/A"}
                                            </TableCell>
                                            <TableCell>
                                              {employee?.poAssignDate || "N/A"}
                                            </TableCell>
                                            <TableCell>
                                              <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() =>
                                                  handleEmployeeEditClick(
                                                    employee
                                                  )
                                                }
                                              >
                                                Update
                                              </Button>
                                            </TableCell>
                                            <TableCell>
                                              <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() =>
                                                  handleReleaseButtonClick(
                                                    editedEmployeeData
                                                  )
                                                }
                                              >
                                                Release
                                              </Button>
                                            </TableCell>
                                          </TableRow>
                                          <TableRow>
                                            <TableCell colSpan={6}>
                                              <Collapse
                                                in={expandedRow === index}
                                                timeout="auto"
                                                unmountOnExit
                                              >
                                                <Box style={{ padding: 20 }}>
                                                  <Typography variant="subtitle1">
                                                    Employee Details
                                                  </Typography>
                                                  <TableRow
                                                    style={{
                                                      backgroundColor:
                                                        "darkcyan",
                                                      fontWeight: "bold",
                                                      color: "darkblue",
                                                    }}
                                                  >
                                                    <TableCell>
                                                      Employee Name
                                                    </TableCell>
                                                    <TableCell>
                                                      Client Name
                                                    </TableCell>
                                                    <TableCell>
                                                      PO Notes
                                                    </TableCell>
                                                    <TableCell>
                                                      PO Assign Date
                                                    </TableCell>
                                                  </TableRow>
                                                  <TableRow>
                                                    <TableCell>
                                                      {employee?.employeeName ||
                                                        "N/A"}
                                                    </TableCell>
                                                    <TableCell>
                                                      {employee?.clientName ||
                                                        "N/A"}
                                                    </TableCell>
                                                    <TableCell>
                                                      {employee?.poNotes ||
                                                        "N/A"}
                                                    </TableCell>
                                                    <TableCell>
                                                      {employee?.poAssignDate ||
                                                        "N/A"}
                                                    </TableCell>
                                                  </TableRow>

                                                  <Typography variant="subtitle1">
                                                    Rate Details
                                                  </Typography>
                                                  <TableRow
                                                    style={{
                                                      backgroundColor:
                                                        "darkcyan",
                                                      fontWeight: "bold",
                                                      color: "darkblue",
                                                    }}
                                                  >
                                                    <TableCell>
                                                      PO Per Month
                                                    </TableCell>
                                                    <TableCell>
                                                      PO Per Day
                                                    </TableCell>
                                                    <TableCell>
                                                      PO Per Hour
                                                    </TableCell>
                                                  </TableRow>
                                                  {employee.empPORateUI ? (
                                                    <TableRow>
                                                      <TableCell>
                                                        {employee.empPORateUI
                                                          .poPerMonth || "N/A"}
                                                      </TableCell>
                                                      <TableCell>
                                                        {employee.empPORateUI
                                                          .poPerDay || "N/A"}
                                                      </TableCell>
                                                      <TableCell>
                                                        {employee.empPORateUI
                                                          .poPerHour || "N/A"}
                                                      </TableCell>
                                                    </TableRow>
                                                  ) : (
                                                    <TableRow>
                                                      <TableCell colSpan={3}>
                                                        No Rate Details
                                                        Available
                                                      </TableCell>
                                                    </TableRow>
                                                  )}

                                                  <Typography variant="subtitle1">
                                                    Assignment Details
                                                  </Typography>
                                                  <TableRow
                                                    style={{
                                                      backgroundColor:
                                                        "darkcyan",
                                                      fontWeight: "bold",
                                                      color: "darkblue",
                                                    }}
                                                  >
                                                    <TableCell>
                                                      PO Start Date
                                                    </TableCell>
                                                    <TableCell>
                                                      PO End Date
                                                    </TableCell>
                                                    <TableCell>
                                                      PO Amount
                                                    </TableCell>
                                                  </TableRow>
                                                  {employee.empPORateUI ? (
                                                    <TableRow>
                                                      <TableCell>
                                                        {
                                                          employee
                                                            .empPOAssignDetailsUI
                                                            .poStartDate
                                                        }
                                                      </TableCell>
                                                      <TableCell>
                                                        {
                                                          employee
                                                            .empPOAssignDetailsUI
                                                            .poEndDate
                                                        }
                                                      </TableCell>
                                                      <TableCell>
                                                        {
                                                          employee
                                                            .empPOAssignDetailsUI
                                                            .poAmount
                                                        }
                                                      </TableCell>
                                                    </TableRow>
                                                  ) : (
                                                    <TableRow>
                                                      <TableCell colSpan={3}>
                                                        No Rate Details
                                                        Available
                                                      </TableCell>
                                                    </TableRow>
                                                  )}

                                                  <Typography variant="subtitle1">
                                                    Client Assessment
                                                  </Typography>
                                                  <TableRow
                                                    style={{
                                                      backgroundColor:
                                                        "darkcyan",
                                                      fontWeight: "bold",
                                                      color: "darkblue",
                                                    }}
                                                  >
                                                    <TableCell>
                                                      Job Location
                                                    </TableCell>
                                                    <TableCell>
                                                      Job Post
                                                    </TableCell>
                                                    <TableCell>
                                                      PO Date
                                                    </TableCell>
                                                    <TableCell>
                                                      Employee Category
                                                    </TableCell>
                                                  </TableRow>
                                                  <TableRow>
                                                    <TableCell>
                                                      {
                                                        employee
                                                          .empPOClientAssesmentUI
                                                          .jobLocation
                                                      }
                                                    </TableCell>
                                                    <TableCell>
                                                      {
                                                        employee
                                                          .empPOClientAssesmentUI
                                                          .jobPost
                                                      }
                                                    </TableCell>
                                                    <TableCell>
                                                      {
                                                        employee
                                                          .empPOClientAssesmentUI
                                                          .poDate
                                                      }
                                                    </TableCell>
                                                    <TableCell>
                                                      {
                                                        employee
                                                          .empPOClientAssesmentUI
                                                          .employeeCategory
                                                      }
                                                    </TableCell>
                                                  </TableRow>

                                                  <Typography variant="subtitle1">
                                                    Facility Assignment
                                                  </Typography>
                                                  <TableRow
                                                    style={{
                                                      backgroundColor:
                                                        "darkcyan",
                                                      fontWeight: "bold",
                                                      color: "darkblue",
                                                    }}
                                                  >
                                                    <TableCell>
                                                      Canteen Amount
                                                    </TableCell>
                                                    <TableCell>
                                                      Transportation Amount
                                                    </TableCell>
                                                    <TableCell>
                                                      Facility PO Number
                                                    </TableCell>
                                                  </TableRow>
                                                  <TableRow>
                                                    <TableCell>
                                                      {
                                                        employee
                                                          .empPOAssignFacilityUI
                                                          .canteenAmount
                                                      }
                                                    </TableCell>
                                                    <TableCell>
                                                      {
                                                        employee
                                                          .empPOAssignFacilityUI
                                                          .transportationAmount
                                                      }
                                                    </TableCell>
                                                    <TableCell>
                                                      {
                                                        employee
                                                          .empPOAssignFacilityUI
                                                          .poNumber
                                                      }
                                                    </TableCell>
                                                  </TableRow>

                                                  <Typography variant="subtitle1">
                                                    Release Details
                                                  </Typography>
                                                  <TableRow>
                                                    <TableCell>
                                                      Client Name
                                                    </TableCell>
                                                    <TableCell>
                                                      Employee Name
                                                    </TableCell>
                                                    <TableCell>
                                                      Employee Code
                                                    </TableCell>
                                                    <TableCell>
                                                      Employee Po UniqueNo
                                                    </TableCell>
                                                    <TableCell>
                                                      Release Date Notes
                                                    </TableCell>
                                                  </TableRow>
                                                </Box>
                                              </Collapse>
                                            </TableCell>
                                          </TableRow>
                                        </TableBody>
                                      </Table>
                                    </AccordionDetails>
                                  </Accordion>
                                )
                              )}
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    ))}
                  </TableBody>
                </Table>
              )}
            </>
          )}
          <TablePagination
            rowsPerPageOptions={[2, 5, 10]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </TableContainer>
      )}
      <Dialog
        open={addEmployeeDialogOpen}
        onClose={handleAddEmployeeDialogClose}
        maxWidth="md"
        maxHeight="md"
        fullWidth
      >
        <DialogTitle>Add Employee</DialogTitle>
        <DialogContent style={{ marginBottom: 20, marginTop: 20 }}>
          <Paper elevation={3} style={{ padding: 20, marginBottom: 20 }}>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <TextField
                  label="PO Number"
                  value={newEmployeeData.poNumber}
                  InputProps={{ readOnly: true }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Client Name"
                  value={newEmployeeData.clientName}
                  InputProps={{ readOnly: true }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Purchase Order Amount"
                  value={newEmployeeData.purchaseOrderAmount}
                  InputProps={{ readOnly: true }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Kind Attention"
                  value={newEmployeeData.kindAttention}
                  InputProps={{ readOnly: true }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Po Unique Number"
                  value={newEmployeeData.empPoUniqueNoAndGSCode}
                  InputProps={{ readOnly: true }}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Paper>
          <Paper elevation={3} style={{ padding: 20, marginBottom: 20 }}>
            <Typography variant="subtitle1">Employee Details</Typography>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="employeeName">Employee Name</InputLabel>
                  <Select
                    value={newEmployeeData.empPODetailsUI[0].employeeName || ""}
                    onChange={(e) =>
                      setNewEmployeeData({
                        ...newEmployeeData,
                        empPODetailsUI: [
                          {
                            ...newEmployeeData.empPODetailsUI[0],
                            employeeName: e.target.value,
                          },
                        ],
                      })
                    }
                    inputProps={{
                      name: "employeeName",
                      id: "employeeName",
                    }}
                  >
                    {employeeOptions.map((employee, index) => (
                      <MenuItem key={index} value={employee}>
                        {employee || ""}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="PO Notes"
                  value={newEmployeeData.empPODetailsUI[0].poNotes}
                  onChange={(e) =>
                    setNewEmployeeData({
                      ...newEmployeeData,
                      empPODetailsUI: [
                        {
                          ...newEmployeeData.empPODetailsUI[0],
                          poNotes: e.target.value,
                        },
                      ],
                    })
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Client Name"
                  value={newEmployeeData.empPODetailsUI[0].clientName}
                  onChange={(e) =>
                    setNewEmployeeData({
                      ...newEmployeeData,
                      empPODetailsUI: [
                        {
                          ...newEmployeeData.empPODetailsUI[0],
                          clientName: e.target.value,
                        },
                      ],
                    })
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="PO Assign Date"
                  type="date"
                  value={newEmployeeData.empPODetailsUI[0].poAssignDate}
                  onChange={(e) =>
                    setNewEmployeeData({
                      ...newEmployeeData,
                      empPODetailsUI: [
                        {
                          ...newEmployeeData.empPODetailsUI[0],
                          poAssignDate: e.target.value,
                        },
                      ],
                    })
                  }
                  margin="dense"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
          </Paper>
          <Paper elevation={2} style={{ padding: 20, marginBottom: 20 }}>
            <Typography variant="subtitle1">Emp Po Rate</Typography>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <TextField
                  label="Po Per Month"
                  value={
                    newEmployeeData.empPODetailsUI[0].empPORateUI.poPerMonth
                  }
                  onChange={(e) =>
                    setNewEmployeeData({
                      ...newEmployeeData,
                      empPODetailsUI: [
                        {
                          ...newEmployeeData.empPODetailsUI[0],
                          empPORateUI: {
                            ...newEmployeeData.empPODetailsUI[0].empPORateUI,
                            poPerMonth: e.target.value,
                          },
                        },
                      ],
                    })
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Po Per Day"
                  value={newEmployeeData.empPODetailsUI[0].empPORateUI.poPerDay}
                  onChange={(e) =>
                    setNewEmployeeData({
                      ...newEmployeeData,
                      empPODetailsUI: [
                        {
                          ...newEmployeeData.empPODetailsUI[0],
                          empPORateUI: {
                            ...newEmployeeData.empPODetailsUI[0].empPORateUI,
                            poPerDay: e.target.value,
                          },
                        },
                      ],
                    })
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Po Per Hour"
                  value={
                    newEmployeeData.empPODetailsUI[0].empPORateUI.poPerHour
                  }
                  onChange={(e) =>
                    setNewEmployeeData({
                      ...newEmployeeData,
                      empPODetailsUI: [
                        {
                          ...newEmployeeData.empPODetailsUI[0],
                          empPORateUI: {
                            ...newEmployeeData.empPODetailsUI[0].empPORateUI,
                            poPerHour: e.target.value,
                          },
                        },
                      ],
                    })
                  }
                />
              </Grid>
            </Grid>
          </Paper>
          <Paper elevation={3} style={{ padding: 20, marginBottom: 20 }}>
            <Typography variant="subtitle1">
              Employee PO Assign Details
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <TextField
                  label="PO Start Date"
                  type="date"
                  value={
                    newEmployeeData.empPODetailsUI[0].empPOAssignDetailsUI
                      .poStartDate
                  }
                  onChange={(e) =>
                    setNewEmployeeData({
                      ...newEmployeeData,
                      empPODetailsUI: [
                        {
                          ...newEmployeeData.empPODetailsUI[0],
                          empPOAssignDetailsUI: {
                            ...newEmployeeData.empPODetailsUI[0]
                              .empPOAssignDetailsUI,
                            poStartDate: e.target.value,
                          },
                        },
                      ],
                    })
                  }
                  margin="dense"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="PO End Date"
                  type="date"
                  value={
                    newEmployeeData.empPODetailsUI[0].empPOAssignDetailsUI
                      .poEndDate
                  }
                  onChange={(e) =>
                    setNewEmployeeData({
                      ...newEmployeeData,
                      empPODetailsUI: [
                        {
                          ...newEmployeeData.empPODetailsUI[0],
                          empPOAssignDetailsUI: {
                            ...newEmployeeData.empPODetailsUI[0]
                              .empPOAssignDetailsUI,
                            poEndDate: e.target.value,
                          },
                        },
                      ],
                    })
                  }
                  margin="dense"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="PO Amount"
                  value={
                    newEmployeeData.empPODetailsUI[0].empPOAssignDetailsUI
                      .poAmount
                  }
                  onChange={(e) =>
                    setNewEmployeeData({
                      ...newEmployeeData,
                      empPODetailsUI: [
                        {
                          ...newEmployeeData.empPODetailsUI[0],
                          empPOAssignDetailsUI: {
                            ...newEmployeeData.empPODetailsUI[0]
                              .empPOAssignDetailsUI,
                            poAmount: e.target.value,
                          },
                        },
                      ],
                    })
                  }
                />
              </Grid>
            </Grid>
          </Paper>
          <Paper elevation={3} style={{ padding: 20, marginBottom: 20 }}>
            <Typography variant="subtitle1">
              Employee PO Client Assesment
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <TextField
                  label="Job Location"
                  value={
                    newEmployeeData.empPODetailsUI[0].empPOClientAssesmentUI
                      .jobLocation
                  }
                  onChange={(e) =>
                    setNewEmployeeData({
                      ...newEmployeeData,
                      empPODetailsUI: [
                        {
                          ...newEmployeeData.empPODetailsUI[0],
                          empPOClientAssesmentUI: {
                            ...newEmployeeData.empPODetailsUI[0]
                              .empPOClientAssesmentUI,
                            jobLocation: e.target.value,
                          },
                        },
                      ],
                    })
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Job Post"
                  value={
                    newEmployeeData.empPODetailsUI[0].empPOClientAssesmentUI
                      .jobPost
                  }
                  onChange={(e) =>
                    setNewEmployeeData({
                      ...newEmployeeData,
                      empPODetailsUI: [
                        {
                          ...newEmployeeData.empPODetailsUI[0],
                          empPOClientAssesmentUI: {
                            ...newEmployeeData.empPODetailsUI[0]
                              .empPOClientAssesmentUI,
                            jobPost: e.target.value,
                          },
                        },
                      ],
                    })
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="PO Date"
                  type="date"
                  value={
                    newEmployeeData.empPODetailsUI[0].empPOClientAssesmentUI
                      .poDate
                  }
                  onChange={(e) =>
                    setNewEmployeeData({
                      ...newEmployeeData,
                      empPODetailsUI: [
                        {
                          ...newEmployeeData.empPODetailsUI[0],
                          empPOClientAssesmentUI: {
                            ...newEmployeeData.empPODetailsUI[0]
                              .empPOClientAssesmentUI,
                            poDate: e.target.value,
                          },
                        },
                      ],
                    })
                  }
                  margin="dense"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Employee Category"
                  value={
                    newEmployeeData.empPODetailsUI[0].empPOClientAssesmentUI
                      .employeeCategory
                  }
                  onChange={(e) =>
                    setNewEmployeeData({
                      ...newEmployeeData,
                      empPODetailsUI: [
                        {
                          ...newEmployeeData.empPODetailsUI[0],
                          empPOClientAssesmentUI: {
                            ...newEmployeeData.empPODetailsUI[0]
                              .empPOClientAssesmentUI,
                            employeeCategory: e.target.value,
                          },
                        },
                      ],
                    })
                  }
                />
              </Grid>
            </Grid>
          </Paper>
          <Paper elevation={3} style={{ padding: 20, marginBottom: 20 }}>
            <Typography variant="subtitle1">
              Employee PO Assign Facility{" "}
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <TextField
                  label="Canteen Amount"
                  value={
                    newEmployeeData.empPODetailsUI[0].empPOAssignFacilityUI
                      .canteenAmount
                  }
                  onChange={(e) =>
                    setNewEmployeeData({
                      ...newEmployeeData,
                      empPODetailsUI: [
                        {
                          ...newEmployeeData.empPODetailsUI[0],
                          empPOAssignFacilityUI: {
                            ...newEmployeeData.empPODetailsUI[0]
                              .empPOAssignFacilityUI,
                            canteenAmount: e.target.value,
                          },
                        },
                      ],
                    })
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Transportation Amount"
                  value={
                    newEmployeeData.empPODetailsUI[0].empPOAssignFacilityUI
                      .transportationAmount
                  }
                  onChange={(e) =>
                    setNewEmployeeData({
                      ...newEmployeeData,
                      empPODetailsUI: [
                        {
                          ...newEmployeeData.empPODetailsUI[0],
                          empPOAssignFacilityUI: {
                            ...newEmployeeData.empPODetailsUI[0]
                              .empPOAssignFacilityUI,
                            transportationAmount: e.target.value,
                          },
                        },
                      ],
                    })
                  }
                />
              </Grid>
              {/* <Grid item xs={6}>
                <TextField
                  label="PO Number"
                  value={
                    newEmployeeData.empPODetailsUI[0].empPOAssignFacilityUI
                      .poNumber
                  }
                  onChange={(e) =>
                    setNewEmployeeData({
                      ...newEmployeeData,
                      empPODetailsUI: [
                        {
                          ...newEmployeeData.empPODetailsUI[0],
                          empPOAssignFacilityUI: {
                            ...newEmployeeData.empPODetailsUI[0]
                              .empPOAssignFacilityUI,
                            poNumber: e.target.value,
                          },
                        },
                      ],
                    })
                  }
                />
              </Grid> */}
            </Grid>
          </Paper>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow
                  style={{
                    backgroundColor: "darkcyan",
                    fontWeight: "bold",
                    color: "darkblue",
                  }}
                >
                  <TableCell>Employee Name</TableCell>
                  <TableCell>Client Name</TableCell>
                  <TableCell>PO Notes</TableCell>
                  <TableCell>PO Assign Date</TableCell>
                  <TableCell>Action</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {addedEmployees.map((employee, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      {employee.empPODetailsUI[0].employeeName}
                    </TableCell>
                    <TableCell>
                      {employee.empPODetailsUI[0].clientName}
                    </TableCell>
                    <TableCell>{employee.empPODetailsUI[0].poNotes}</TableCell>
                    <TableCell>
                      {employee.empPODetailsUI[0].poAssignDate}
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleDeleteRow(index)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Button onClick={handleAddToTable} color="primary">
            Add to Table
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddEmployeeDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSendDataToBackend} color="primary">
            Add Employee
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        maxHeight="md"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Edit PO Data</DialogTitle>
        <DialogContent style={{ marginBottom: 20, marginTop: 20 }}>
          <Paper elevation={3} style={{ padding: 20, marginBottom: 20 }}>
            <Grid container spacing={6}>
              <Grid item xs={4}>
                <TextField
                  label="PO Number"
                  value={editedData ? editedData.poNumber : ""}
                  onChange={(e) =>
                    setEditedData({ ...editedData, poNumber: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Client Name"
                  value={editedData ? editedData.clientName : ""}
                  onChange={(e) =>
                    setEditedData({ ...editedData, clientName: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Purchase Order Amount"
                  value={editedData ? editedData.purchaseOrderAmount : ""}
                  onChange={(e) =>
                    setEditedData({
                      ...editedData,
                      purchaseOrderAmount: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Po Unique Number"
                  value={editedData ? editedData.empPoUniqueNoAndGSCode : ""}
                  onChange={(e) =>
                    setEditedData({
                      ...editedData,
                      empPoUniqueNoAndGSCode: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Kind Attention"
                  value={editedData ? editedData.kindAttention : ""}
                  onChange={(e) =>
                    setEditedData({
                      ...editedData,
                      kindAttention: e.target.value,
                    })
                  }
                />
              </Grid>
            </Grid>
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleUpdateData} color="primary">
            Update PO
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={employeeDialogOpen}
        onClose={handleEmployeeDialogClose}
        maxWidth="md"
        maxHeight="md"
        fullWidth
      >
        <DialogTitle>Edit Employee Data</DialogTitle>
        <DialogContent>
          <Tabs
            value={currentTab}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="Employee Details" />
            <Tab label="Rate Details" />
            <Tab label="Assign Details" />
            <Tab label="Client Assessment" />
            <Tab label="Assign Facility" />
            {/* <Tab label="Employee Release" /> */}
          </Tabs>
          {currentTab === 0 && (
            <div>
              <Paper
                elevation={3}
                style={{ padding: 20, marginBottom: 20, marginTop: 20 }}
              >
                <Grid container spacing={6} style={{ marginTop: 10 }}>
                  <Grid item xs={4}>
                    <TextField
                      label="Employee Name"
                      value={
                        editedEmployeeData
                          ? editedEmployeeData.employeeName
                          : ""
                      }
                      onChange={(e) =>
                        setEditedEmployeeData({
                          ...editedEmployeeData,
                          employeeName: e.target.value,
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="Client Name"
                      value={
                        editedEmployeeData ? editedEmployeeData.clientName : ""
                      }
                      onChange={(e) =>
                        setEditedEmployeeData({
                          ...editedEmployeeData,
                          clientName: e.target.value,
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="PO Notes"
                      value={
                        editedEmployeeData ? editedEmployeeData.poNotes : ""
                      }
                      onChange={(e) =>
                        setEditedEmployeeData({
                          ...editedEmployeeData,
                          poNotes: e.target.value,
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="Po Assign Date"
                      type="date"
                      value={
                        editedEmployeeData
                          ? editedEmployeeData.poAssignDate
                          : ""
                      }
                      onChange={(e) =>
                        setEditedEmployeeData({
                          ...editedEmployeeData,
                          poAssignDate: e.target.value,
                        })
                      }
                      margin="dense"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </div>
          )}
          {currentTab === 1 && (
            <div>
              <Paper
                elevation={3}
                style={{ padding: 20, marginBottom: 20, marginTop: 20 }}
              >
                <Grid container spacing={6} style={{ marginTop: 10 }}>
                  <Grid item xs={4}>
                    <TextField
                      label="Po Per Month"
                      value={
                        editedEmployeeData && editedEmployeeData.empPORateUI
                          ? editedEmployeeData.empPORateUI.poPerMonth
                          : ""
                      }
                      onChange={(e) =>
                        setEditedEmployeeData({
                          ...editedEmployeeData,
                          empPORateUI: {
                            ...editedEmployeeData.empPORateUI,
                            poPerMonth: e.target.value,
                          },
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="Po Per Day"
                      value={
                        editedEmployeeData && editedEmployeeData.empPORateUI
                          ? editedEmployeeData.empPORateUI.poPerDay
                          : ""
                      }
                      onChange={(e) =>
                        setEditedEmployeeData({
                          ...editedEmployeeData,
                          empPORateUI: {
                            ...editedEmployeeData.empPORateUI,
                            poPerDay: e.target.value,
                          },
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="Po Per Hour"
                      value={
                        editedEmployeeData && editedEmployeeData.empPORateUI
                          ? editedEmployeeData.empPORateUI.poPerHour
                          : ""
                      }
                      onChange={(e) =>
                        setEditedEmployeeData({
                          ...editedEmployeeData,
                          empPORateUI: {
                            ...editedEmployeeData.empPORateUI,
                            poPerHour: e.target.value,
                          },
                        })
                      }
                    />
                  </Grid>
                </Grid>
              </Paper>
            </div>
          )}
          {currentTab === 2 && (
            <div>
              <Paper
                elevation={3}
                style={{ padding: 20, marginBottom: 20, marginTop: 20 }}
              >
                <Grid container spacing={6} style={{ marginTop: 10 }}>
                  <Grid item xs={4}>
                    <TextField
                      label="Po Start Date"
                      type="date"
                      value={
                        editedEmployeeData &&
                        editedEmployeeData.empPOAssignDetailsUI
                          ? editedEmployeeData.empPOAssignDetailsUI.poStartDate
                          : ""
                      }
                      onChange={(e) =>
                        setEditedEmployeeData({
                          ...editedEmployeeData,
                          empPOAssignDetailsUI: {
                            ...editedEmployeeData.empPOAssignDetailsUI,
                            poStartDate: e.target.value,
                          },
                        })
                      }
                      margin="dense"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="Po End Date"
                      type="date"
                      value={
                        editedEmployeeData &&
                        editedEmployeeData.empPOAssignDetailsUI
                          ? editedEmployeeData.empPOAssignDetailsUI.poEndDate
                          : ""
                      }
                      onChange={(e) =>
                        setEditedEmployeeData({
                          ...editedEmployeeData,
                          empPOAssignDetailsUI: {
                            ...editedEmployeeData.empPOAssignDetailsUI,
                            poEndDate: e.target.value,
                          },
                        })
                      }
                      margin="dense"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="Po Amount"
                      value={
                        editedEmployeeData &&
                        editedEmployeeData.empPOAssignDetailsUI
                          ? editedEmployeeData.empPOAssignDetailsUI.poAmount
                          : ""
                      }
                      onChange={(e) =>
                        setEditedEmployeeData({
                          ...editedEmployeeData,
                          empPOAssignDetailsUI: {
                            ...editedEmployeeData.empPOAssignDetailsUI,
                            poAmount: e.target.value,
                          },
                        })
                      }
                    />
                  </Grid>
                </Grid>
              </Paper>
            </div>
          )}
          {currentTab === 3 && (
            <div>
              <Paper
                elevation={3}
                style={{ padding: 20, marginBottom: 20, marginTop: 20 }}
              >
                <Grid container spacing={6} style={{ marginTop: 10 }}>
                  <Grid item xs={4}>
                    <TextField
                      label="Job Location"
                      value={
                        editedEmployeeData &&
                        editedEmployeeData.empPOClientAssesmentUI
                          ? editedEmployeeData.empPOClientAssesmentUI
                              .jobLocation
                          : ""
                      }
                      onChange={(e) =>
                        setEditedEmployeeData({
                          ...editedEmployeeData,
                          empPOClientAssesmentUI: {
                            ...editedEmployeeData.empPOClientAssesmentUI,
                            jobLocation: e.target.value,
                          },
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="Job Post"
                      value={
                        editedEmployeeData &&
                        editedEmployeeData.empPOClientAssesmentUI
                          ? editedEmployeeData.empPOClientAssesmentUI.jobPost
                          : ""
                      }
                      onChange={(e) =>
                        setEditedEmployeeData({
                          ...editedEmployeeData,
                          empPOClientAssesmentUI: {
                            ...editedEmployeeData.empPOClientAssesmentUI,
                            jobPost: e.target.value,
                          },
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="Po Date"
                      type="date"
                      value={
                        editedEmployeeData &&
                        editedEmployeeData.empPOClientAssesmentUI
                          ? editedEmployeeData.empPOClientAssesmentUI.poDate
                          : ""
                      }
                      onChange={(e) =>
                        setEditedEmployeeData({
                          ...editedEmployeeData,
                          empPOClientAssesmentUI: {
                            ...editedEmployeeData.empPOClientAssesmentUI,
                            poDate: e.target.value,
                          },
                        })
                      }
                      margin="dense"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="Employee Category"
                      value={
                        editedEmployeeData &&
                        editedEmployeeData.empPOClientAssesmentUI
                          ? editedEmployeeData.empPOClientAssesmentUI
                              .employeeCategory
                          : ""
                      }
                      onChange={(e) =>
                        setEditedEmployeeData({
                          ...editedEmployeeData,
                          empPOClientAssesmentUI: {
                            ...editedEmployeeData.empPOClientAssesmentUI,
                            employeeCategory: e.target.value,
                          },
                        })
                      }
                    />
                  </Grid>
                </Grid>
              </Paper>
            </div>
          )}
          {currentTab === 4 && (
            <div>
              <Paper
                elevation={3}
                style={{ padding: 20, marginBottom: 20, marginTop: 20 }}
              >
                <Grid container spacing={6} style={{ marginTop: 10 }}>
                  <Grid item xs={4}>
                    <TextField
                      label="Canteen Amount"
                      value={
                        editedEmployeeData &&
                        editedEmployeeData.empPOAssignFacilityUI
                          ? editedEmployeeData.empPOAssignFacilityUI
                              .canteenAmount
                          : ""
                      }
                      onChange={(e) =>
                        setEditedEmployeeData({
                          ...editedEmployeeData,
                          empPOAssignFacilityUI: {
                            ...editedEmployeeData.empPOAssignFacilityUI,
                            canteenAmount: e.target.value,
                          },
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="Transportation Amount"
                      value={
                        editedEmployeeData &&
                        editedEmployeeData.empPOAssignFacilityUI
                          ? editedEmployeeData.empPOAssignFacilityUI
                              .transportationAmount
                          : ""
                      }
                      onChange={(e) =>
                        setEditedEmployeeData({
                          ...editedEmployeeData,
                          empPOAssignFacilityUI: {
                            ...editedEmployeeData.empPOAssignFacilityUI,
                            transportationAmount: e.target.value,
                          },
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="Po Number"
                      value={
                        editedEmployeeData &&
                        editedEmployeeData.empPOAssignFacilityUI
                          ? editedEmployeeData.empPOAssignFacilityUI.poNumber
                          : ""
                      }
                      onChange={(e) =>
                        setEditedEmployeeData({
                          ...editedEmployeeData,
                          empPOAssignFacilityUI: {
                            ...editedEmployeeData.empPOAssignFacilityUI,
                            poNumber: e.target.value,
                          },
                        })
                      }
                    />
                  </Grid>
                </Grid>
              </Paper>
            </div>
          )}
          {/* {currentTab === 5 && (
            <div>
              <Paper
                elevation={3}
                style={{ padding: 20, marginBottom: 20, marginTop: 20 }}
              >
                <Grid container spacing={6} style={{ marginTop: 10 }}>
                  <Grid item xs={4}>
                    <TextField
                      label="Client Name"
                      value={
                        editedEmployeeData && editedEmployeeData.empPOReleaseUI
                          ? editedEmployeeData.empPOReleaseUI.clientName || ""
                          : ""
                      }
                      onChange={(e) =>
                        setEditedEmployeeData({
                          ...editedEmployeeData,
                          empPOReleaseUI: {
                            ...editedEmployeeData.empPOReleaseUI,
                            clientName: e.target.value,
                          },
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="Employee Name"
                      value={
                        editedEmployeeData && editedEmployeeData.empPOReleaseUI
                          ? editedEmployeeData.empPOReleaseUI.employeeName || ""
                          : ""
                      }
                      onChange={(e) =>
                        setEditedEmployeeData({
                          ...editedEmployeeData,
                          empPOReleaseUI: {
                            ...editedEmployeeData.empPOReleaseUI,
                            employeeName: e.target.value,
                          },
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="Employee Code"
                      value={
                        editedEmployeeData && editedEmployeeData.empPOReleaseUI
                          ? editedEmployeeData.empPOReleaseUI.employeeCode || ""
                          : ""
                      }
                      onChange={(e) =>
                        setEditedEmployeeData({
                          ...editedEmployeeData,
                          empPOReleaseUI: {
                            ...editedEmployeeData.empPOReleaseUI,
                            employeeCode: e.target.value,
                          },
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="Release Date Notes"
                      value={
                        editedEmployeeData && editedEmployeeData.empPOReleaseUI
                          ? editedEmployeeData.empPOReleaseUI
                              .releaseDateNotes || ""
                          : ""
                      }
                      onChange={(e) =>
                        setEditedEmployeeData({
                          ...editedEmployeeData,
                          empPOReleaseUI: {
                            ...editedEmployeeData.empPOReleaseUI,
                            releaseDateNotes: e.target.value,
                          },
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="Employee Po UniqueNo"
                      value={
                        editedEmployeeData && editedEmployeeData.empPOReleaseUI
                          ? editedEmployeeData.empPOReleaseUI
                              .empPoUniqueNoAndGSCode || ""
                          : ""
                      }
                      onChange={(e) =>
                        setEditedEmployeeData({
                          ...editedEmployeeData,
                          empPOReleaseUI: {
                            ...editedEmployeeData.empPOReleaseUI,
                            empPoUniqueNoAndGSCode: e.target.value,
                          },
                        })
                      }
                    />
                  </Grid>
                </Grid>
              </Paper>
            </div>
          )} */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEmployeeDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleUpdateData} color="primary">
            Update Employee
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={releaseDialogOpen}
        onClose={handleReleaseDialogClose}
        maxWidth="md"
        maxHeight="md"
        fullWidth
      >
        <DialogTitle>Employee Release</DialogTitle>
        <DialogContent style={{ marginBottom: 20, marginTop: 20 }}>
          <Paper elevation={2} style={{ padding: 20, marginBottom: 20 }}>
            <Grid container spacing={6}>
              <Grid item md={4}>
                <TextField
                  label="Client Name"
                  value={
                    editedEmployeeData && editedEmployeeData.empPOReleaseUI
                      ? editedEmployeeData.empPOReleaseUI.clientName || ""
                      : ""
                  }
                  onChange={(e) =>
                    setEditedEmployeeData({
                      ...editedEmployeeData,
                      empPOReleaseUI: {
                        ...editedEmployeeData.empPOReleaseUI,
                        clientName: e.target.value,
                      },
                    })
                  }
                />
              </Grid>
              <Grid item md={4}>
                <TextField
                  label="Employee Name"
                  value={
                    editedEmployeeData && editedEmployeeData.empPOReleaseUI
                      ? editedEmployeeData.empPOReleaseUI.employeeName || ""
                      : ""
                  }
                  onChange={(e) =>
                    setEditedEmployeeData({
                      ...editedEmployeeData,
                      empPOReleaseUI: {
                        ...editedEmployeeData.empPOReleaseUI,
                        employeeName: e.target.value,
                      },
                    })
                  }
                />
              </Grid>
              <Grid item md={4}>
                <TextField
                  label="Employee Code"
                  value={
                    editedEmployeeData && editedEmployeeData.empPOReleaseUI
                      ? editedEmployeeData.empPOReleaseUI.employeeCode || ""
                      : ""
                  }
                  onChange={(e) =>
                    setEditedEmployeeData({
                      ...editedEmployeeData,
                      empPOReleaseUI: {
                        ...editedEmployeeData.empPOReleaseUI,
                        employeeCode: e.target.value,
                      },
                    })
                  }
                />
              </Grid>
              <Grid item md={4}>
                <TextField
                  label="Release Date Notes"
                  value={
                    editedEmployeeData && editedEmployeeData.empPOReleaseUI
                      ? editedEmployeeData.empPOReleaseUI.releaseDateNotes || ""
                      : ""
                  }
                  onChange={(e) =>
                    setEditedEmployeeData({
                      ...editedEmployeeData,
                      empPOReleaseUI: {
                        ...editedEmployeeData.empPOReleaseUI,
                        releaseDateNotes: e.target.value,
                      },
                    })
                  }
                />
              </Grid>
            </Grid>
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleReleaseDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleUpdateData} color="primary">
            Update Employee Release
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UpdateEmpPOSearchPage;
