import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Collapse,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  TablePagination,
  Grid,
  Box,
  CircularProgress,
  Backdrop,
} from "@material-ui/core";
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  Edit as EditIcon,
  Search,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import { myAxiosCommonHost } from "../../Service/CorsHelperBaseUrl";
import * as XLSX from "xlsx";

const Row = ({ row, onDelete, onUpdate }) => {
  const [open, setOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [editedBank, setEditedBank] = useState({ ...row });
  const [tabValue, setTabValue] = useState(0);
  const [isDataChangedEdit, setIsDataChangedEdit] = useState(false);

  const handleEdit = () => {
    setDialogOpen(true);
  };

  const handleDelete = () => {
    onDelete(row.accountNumber);
  };

  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    setEditedBank((prevBank) => ({ ...prevBank, [name]: value }));
    setIsDataChangedEdit(true);
  };

  const handleSave = () => {
    onUpdate(editedBank);
    setDialogOpen(false);
  };
  // if (!editedBank) {
  //   return <div>Loading...</div>;
  // }

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>

        <TableCell>{row.bankName}</TableCell>
        <TableCell>{row.branchName}</TableCell>
        <TableCell>{row.accountNumber}</TableCell>
        <TableCell>
          <Button onClick={handleEdit} color="primary" variant="contained">
            Update
          </Button>
          <Button
            onClick={handleDelete}
            color="error"
            variant="contained"
            style={{
              marginLeft: "8px",
              backgroundColor: "red",
              color: "white",
            }}
          >
            Delete
          </Button>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <div>
              <Box style={{ padding: 20 }}>
                <Typography variant="h6">Bank Details</Typography>
                {row ? (
                  <div>
                    <TableRow
                      style={{
                        backgroundColor: "darkcyan",
                        fontWeight: "bold",
                        color: "darkblue",
                      }}
                    >
                      <TableCell>Bank Name</TableCell>
                      <TableCell>Branch Name</TableCell>
                      <TableCell>Bank Address</TableCell>
                      <TableCell>Account Number</TableCell>
                      <TableCell>Account Type</TableCell>
                      <TableCell>IFSC</TableCell>
                      <TableCell>MICR</TableCell>
                      <TableCell>SWIFT CODE</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{row.bankName}</TableCell>
                      <TableCell>{row.branchName}</TableCell>
                      <TableCell>{row.bankAddress}</TableCell>
                      <TableCell>{row.accountNumber}</TableCell>
                      <TableCell>{row.accountType}</TableCell>
                      <TableCell>{row.ifsc}</TableCell>
                      <TableCell>{row.micr}</TableCell>
                      <TableCell>{row.swiftCode}</TableCell>
                    </TableRow>
                  </div>
                ) : (
                  <TableRow>
                    <TableCell colSpan={3} align="center">
                      No Bank Details found
                    </TableCell>
                  </TableRow>
                )}
              </Box>
            </div>
          </Collapse>
        </TableCell>
      </TableRow>

      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        maxWidth="md"
        maxHeight="md"
        fullWidth
      >
        <DialogTitle>Edit Bank Information</DialogTitle>
        <DialogContent>
          <Typography variant="h6">Bank Information</Typography>
          <Paper
            elevation={3}
            style={{ padding: 10, marginBottom: 10, marginTop: 10 }}
          >
            <Grid container spacing={3} style={{ marginTop: 10 }}>
              <Grid item xs={12} md={4}>
                <TextField
                  label="Bank Name"
                  name="bankName"
                  value={editedBank.bankName}
                  onChange={handleFieldChange}
                  variant="standard"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  label="Branch"
                  name="branch"
                  value={editedBank.branchName}
                  onChange={handleFieldChange}
                  variant="standard"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  label="Bank Address"
                  name="bankAddress"
                  value={editedBank.bankAddress}
                  onChange={handleFieldChange}
                  variant="standard"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  label="Account Type"
                  name="accountType"
                  value={editedBank.accountType}
                  onChange={handleFieldChange}
                  variant="standard"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  label="Account Number"
                  name="accountNumber"
                  value={editedBank.accountNumber}
                  onChange={handleFieldChange}
                  variant="standard"
                  fullWidth
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  label="IFSC"
                  name="ifsc"
                  value={editedBank.ifsc}
                  onChange={handleFieldChange}
                  variant="standard"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  label="MICR"
                  name="micr"
                  value={editedBank.micr}
                  onChange={handleFieldChange}
                  variant="standard"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  label="SWIFT CODE"
                  name="swiftCode"
                  value={editedBank.swiftCode}
                  onChange={handleFieldChange}
                  variant="standard"
                  fullWidth
                />
              </Grid>
            </Grid>
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => setDialogOpen(false)}
            style={{ backgroundColor: "red", color: "white" }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleSave}
            disabled={!isDataChangedEdit}
            style={{
              backgroundColor: isDataChangedEdit ? "green" : "lightgreen", // Change color based on isDataChangedEdit state
              color: "white",
            }}
          >
            UPDATE
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const UpdateBank = () => {
  const [bankData, setBankData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isDeleting, setIsDeleting] = useState(false); // State to control deletion overlay
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    const fetchBankData = () => {
      setLoading(true);
      myAxiosCommonHost
        .get("gst/bank/getBankListUI")
        .then((response) => {
          setBankData(response.data);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching bank data:", error);
          setError(error);
          setLoading(false);
        });
    };

    fetchBankData();
  }, []);

  const handleUpdateBank = (updatedBank) => {
    setIsUpdating(true); // Show update overlay
    myAxiosCommonHost
      .put("gst/bank/updateBankDetails", updatedBank)
      .then((response) => {
        console.log("Bank data updated successfully.");
        fetchBankData();
        setIsUpdating(false); // Hide update overlay after update
      })
      .catch((error) => {
        console.error("Error updating bank data: ", error);
        setIsUpdating(false); // Hide update overlay after update
      });
  };

  const handleDeleteAccountNumber = (accountNumber) => {
    if (window.confirm("Are you sure you want to delete this bank?")) {
      setIsDeleting(true); // Show deletion overlay
      myAxiosCommonHost
        .delete(`gst/bank/deleteBankDetails/accountNumber/${accountNumber}`)
        .then((response) => {
          console.log("Bank deleted successfully.");
          fetchBankData();
          // alert("Bank deleted successfully.");
          setIsDeleting(false); // Hide deletion overlay on error
        })
        .catch((error) => {
          console.error("Error deleting bank: ", error);
          alert("Error deleting bank.");
          setIsDeleting(false); // Hide deletion overlay on error
        });
    }
  };

  const fetchBankData = () => {
    myAxiosCommonHost
      .get("gst/bank/getBankListUI")
      .then((response) => {
        setBankData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching bank data:", error);
        setLoading(false);
      });
  };

  const exportToExcel = () => {
    // Map bankData to include only necessary fields
    const dataToExport = bankData.map(({ ...rest }) => ({
      ...rest,
    }));

    // Extract headers from the first element
    const headers = Object.keys(dataToExport[0]);

    // Convert data to worksheet
    const ws = XLSX.utils.json_to_sheet(dataToExport, { header: headers });

    // Set column width to 30
    const colWidth = headers.map(() => ({ width: 30 }));
    ws["!cols"] = colWidth;

    // Set alignment for all cells to left
    const range = XLSX.utils.decode_range(ws["!ref"]);
    for (let R = range.s.r; R <= range.e.r; ++R) {
      for (let C = range.s.c; C <= range.e.c; ++C) {
        const cellAddress = { r: R, c: C };
        const cellRef = XLSX.utils.encode_cell(cellAddress);
        if (!ws[cellRef]) continue;
        ws[cellRef].s = {
          alignment: { horizontal: "left", vertical: "bottom" },
        };
      }
    }

    // Create a new workbook and append the worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Bank Data");

    // Save the workbook as an Excel file
    XLSX.writeFile(wb, "bank_data.xlsx");
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const filterbankData = bankData.filter(
    (bankData) =>
      bankData.bankName.toLowerCase().includes(searchTerm.toLowerCase())
    //  &&
    // companyData.clientCode
    //     .toLowerCase()
    //     .includes(searchTermClientCode.toLowerCase())
  );

  return (
    <div>
      {isDeleting && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 9999,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ textAlign: "center", color: "white" }}>
            <CircularProgress style={{ marginBottom: "10px" }} />
            <Typography variant="h6">Bank is Deleting...</Typography>
          </div>
        </div>
      )}
      {isUpdating && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 9999,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ textAlign: "center", color: "white" }}>
            <CircularProgress style={{ marginBottom: "10px" }} />
            <Typography variant="h6">Bank is Updating...</Typography>
          </div>
        </div>
      )}
      <TextField
        label="Search by Bank Name"
        variant="standard"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        style={{ float: "right", margin: "10px" }}
      />
      <Link to="/registerBankPage">
        <Button variant="contained" color="primary" style={{ margin: "10px" }}>
          Add Bank
        </Button>
      </Link>
      <Button
        variant="contained"
        color="secondary"
        onClick={exportToExcel}
        style={{ margin: "10px" }}
      >
        Export to Excel
      </Button>
      <Paper
        elevation={3}
        style={{
          padding: 20,
          marginBottom: 20,
          marginTop: 20,
          backgroundColor: "#2196f3",
          color: "white",
          padding: "10px",
          textAlign: "center",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography
            variant="h5"
            gutterBottom
            style={{ marginBottom: "15px", color: "darkblue" }}
          >
            Bank List
          </Typography>
        </div>
      </Paper>
      {error ? (
        <p>Error fetching data. Please try again.</p>
      ) : (
        <TableContainer component={Paper} style={{ height: "20%" }}>
          {/* {loading ? (
            <p>Loading...</p>
          ) : ( */}
          {loading ? (
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={loading}
            >
              <CircularProgress color="inherit" />
              <Typography
                variant="h6"
                color="inherit"
                style={{ marginLeft: "10px" }}
              >
                Getting Data...
              </Typography>
            </Backdrop>
          ) : (
            <>
              <Table>
                <TableHead>
                  <TableRow
                    style={{
                      backgroundColor: "darkcyan",
                      fontWeight: "bold",
                      color: "darkblue",
                    }}
                  >
                    <TableCell />
                    <TableCell>Bank Name</TableCell>
                    <TableCell>Branch</TableCell>
                    <TableCell>Acount Number</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {bankData.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={5} align="center">
                        No Banks found
                      </TableCell>
                    </TableRow>
                  ) : (
                    filterbankData
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      // .filter((bank) =>
                      //   bank.bankName
                      //     .toLowerCase()
                      //     .includes(searchTerm.toLowerCase())
                      // )
                      .map((bank) => (
                        <Row
                          key={bank.accountNumber}
                          row={bank}
                          onDelete={handleDeleteAccountNumber}
                          onUpdate={handleUpdateBank}
                        />
                      ))
                  )}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[5, 10, 15]}
                component="div"
                count={filterbankData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </>
          )}
        </TableContainer>
      )}
    </div>
  );
};

export default UpdateBank;
