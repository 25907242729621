import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Container,
  Paper,
  Grid,
  Card,
  CardHeader,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { Autocomplete } from "@mui/material";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { myAxiosCommonHost } from "../../Service/CorsHelperBaseUrl";
import { format } from "date-fns";
const GenerateQuotation = () => {
  const navigate = useNavigate();
  const getCurrentFinancialYear = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const fiscalStartYear = currentYear - (currentDate.getMonth() >= 3 ? 0 : 1);
    const fiscalEndYear = fiscalStartYear + 1;
    return `${(fiscalStartYear % 100).toString().padStart(2, "0")}-${(
      fiscalEndYear % 100
    )
      .toString()
      .padStart(2, "0")}`;
  };
  const getCurrentMonth = () => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() - 1); // Subtract 1 to get the previous month
    const previousMonthIndex = currentDate.getMonth();
    return months[previousMonthIndex];
  };
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [quotationDetails, setQuotationDetails] = useState({
    selectCompany: null,
    serviceAccountingCode: "",
    quotationRefNo: "",
    quotationNo: "",
    quotationDate: format(new Date(), "yyyy-MM-dd"),
    clientNameAndCode: "",
    kindAttention: "",
    quotationSubject: "",
    quotationContent: "",
    totalAmount: "",
    totalInWord: "",
    financialYear: getCurrentFinancialYear(),
    financialMonth: getCurrentMonth(),
  });
  const [newTermCondition, setNewTermCondition] = useState({
    termSequenceId: "",
    termCondition: "",
  });
  const [accomodationUIList, setAccomodationUIList] = useState([]);
  const [quotationTermAndConditionsUI, setQuotationTermAndConditionsUI] =
    useState([]);
  const [clientOptions, setClientOptions] = useState([]);
  const [paymentMonths, setPaymentMonths] = useState([]);
  const [financialYears, setFinancialYears] = useState([]);
  const [loadingOverlay, setLoadingOverlay] = useState(false);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [newAccomodation, setNewAccomodation] = useState({
    accomodationSrNo: "",
    description: "",
    costPerResources: "",
    noOfResources: "",
    noOfMonth: "",
    totalCost: "",
  });
  useEffect(() => {
    myAxiosCommonHost
      .get("gst/client/getClientNameList")
      .then((response) => {
        setClientOptions(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.error("Error fetching client data:", error);
      });
  }, []);
  useEffect(() => {
    const fetchPaymentMonths = async () => {
      try {
        const response = await myAxiosCommonHost.get(
          "gst/invoice/getShortMonth"
        );
        setPaymentMonths(response.data);
      } catch (error) {
        console.error("Error fetching paymentMonths:", error);
      }
    };

    fetchPaymentMonths();
  }, []);
  useEffect(() => {
    const fetchFinancialYears = async () => {
      try {
        const response = await myAxiosCommonHost.get(
          "gst/invoice/getFiscalYear"
        );
        setFinancialYears(response.data);
      } catch (error) {
        console.error("Error fetching financialYears:", error);
      }
    };

    fetchFinancialYears();
  }, []);
  useEffect(() => {
    if ((quotationDetails.financialYear, quotationDetails.selectCompany)) {
      fetchQuotationNo();
    }
  }, [quotationDetails.financialYear, quotationDetails.selectCompany]);
  const fetchQuotationNo = async () => {
    try {
      const response = await myAxiosCommonHost.get(
        `gst/quotation/getQuotationNo?selectCompany=${quotationDetails.selectCompany}&financialYear=${quotationDetails.financialYear}`
      );
      if (response.status === 200) {
        let data = response.data;
        let quotationResponseNo = "";
        if (typeof data === "string") {
          let msgArr = data.split(",");
          if (msgArr.length >= 2) {
            if (data.includes("Please")) {
              window.alert(data);
              quotationResponseNo = parseInt(msgArr[1]);
            } else {
              quotationResponseNo = parseInt(data);
            }
          } else {
            console.error("Unexpected data format for splitting:", data);
          }
        } else if (typeof data === "number") {
          quotationResponseNo = data;
        } else {
          console.error("Unexpected data format:", data);
        }
        setQuotationDetails((prevState) => ({
          ...prevState,
          //selectCompany: null,
          serviceAccountingCode: "",
          // quotationRefNo: "",
          quotationNo: quotationResponseNo,
          quotationDate: format(new Date(), "yyyy-MM-dd"),
          clientNameAndCode: "",
          kindAttention: "",
          quotationSubject: "",
          quotationContent: "",
          totalAmount: "",
          totalInWord: "",
          //financialMonth: "",
          financialMonth: getCurrentMonth(),
        }));
        setNewAccomodation((prevState) => ({
          ...prevState,
          accomodationSrNo: "",
          description: "",
          costPerResources: "",
          noOfResources: "",
          noOfMonth: "",
          totalCost: "",
        }));
        setNewTermCondition((prevState) => ({
          ...prevState,
          termSequenceId: "",
          termCondition: "",
        }));
      } else {
        console.error("Error fetching data. Status:", response.status);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleAddEntry = () => {
    const isDuplicate = accomodationUIList.some(
      (accomodation) =>
        accomodation.description.trim() === newAccomodation.description.trim()
    );
    if (isDuplicate) {
      alert("Duplicate Entry! Cannot Add The Same Contact Person Name.");
      return;
    }
    if (
      newAccomodation.description.trim() === "" ||
      newAccomodation.costPerResources.trim() === "" ||
      newAccomodation.totalCost.trim() === "" ||
      newAccomodation.noOfMonth.trim() === "" ||
      newAccomodation.noOfResources.trim() === ""
    ) {
      alert("Cannot add null or empty data to the table");
      return;
    }
    setAccomodationUIList((prevAccomodationUIList) => [
      ...prevAccomodationUIList,
      {
        ...newAccomodation,
        accomodationSrNo: prevAccomodationUIList.length + 1,
      },
    ]);
    setNewAccomodation({
      accomodationSrNo: "",
      description: "",
      costPerResources: "",
      noOfResources: "",
      noOfMonth: "",
      totalCost: "",
    });
  };
  // const handleAccomodationUIChange = (e) => {
  //   const { name, value } = e.target;
  //   setNewAccomodation((prevNewAccomodation) => ({
  //     ...prevNewAccomodation,
  //     [name]: value,
  //   }));
  // };
  const handleAccomodationUIChange = (e) => {
    const { name, value } = e.target;
    setNewAccomodation((prevNewAccomodation) => {
      const updatedAccomodation = { ...prevNewAccomodation, [name]: value };
      if (
        updatedAccomodation.costPerResources &&
        updatedAccomodation.noOfResources &&
        updatedAccomodation.noOfMonth
      ) {
        const totalCost =
          parseFloat(updatedAccomodation.costPerResources) *
          parseInt(updatedAccomodation.noOfResources) *
          parseInt(updatedAccomodation.noOfMonth);
        updatedAccomodation.totalCost = totalCost.toFixed(0);
      }
      return updatedAccomodation;
    });
  };
  // Generating quotationRefNo
  useEffect(() => {
    const { selectCompany, financialYear, quotationNo } = quotationDetails;
    if (selectCompany && financialYear && quotationNo) {
      const quotationRefNo = `${selectCompany}Q/${financialYear}/${quotationNo}`;
      setQuotationDetails((prevState) => ({
        ...prevState,
        quotationRefNo,
      }));
    }
  }, [
    quotationDetails.selectCompany,
    quotationDetails.financialYear,
    quotationDetails.quotationNo,
  ]);
  const handleQuotationDetailsChange = (e) => {
    const { name, value } = e.target;
    setQuotationDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };
  const handleCancel = () => {
    const userConfirmed = window.confirm(
      "Are you sure you want to cancel? Any unsaved changes will be lost."
    );
    if (userConfirmed) {
      navigate("/updateQuotationPage");
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoadingOverlay(true);
    setFormSubmitted(true);
    try {
      const postData = {
        ...quotationDetails,
        accomodationUIList,
        quotationTermAndConditionsUI,
      };
      console.log(
        "Posted data in JSON format:",
        JSON.stringify(postData, null, 2)
      );
      await myAxiosCommonHost.post("gst/quotation/createQuotation", postData);
      console.log(
        "Posted data in JSON format:",
        JSON.stringify(postData, null, 2)
      );
      alert("Quotation Submited Successfully!");
      setQuotationDetails({
        selectCompany: "",
        serviceAccountingCode: "",
        quotationRefNo: "",
        quotationNo: "",
        quotationDate: "",
        clientNameAndCode: "",
        kindAttention: "",
        quotationSubject: "",
        quotationContent: "",
        totalAmount: "",
        totalInWord: "",
        financialYear: "",
        financialMonth: "",
      });

      setAccomodationUIList([]);
      setQuotationTermAndConditionsUI([]);

      setNewAccomodation({
        accomodationSrNo: "",
        description: "",
        costPerResources: "",
        noOfResources: "",
        noOfMonth: "",
        totalCost: "",
      });
      navigate("/updateQuotationPage");

      console.log(
        "Data posted successfully, fields reset, and navigated to ClientList!"
      );
    } catch (error) {
      console.error("Error posting data:", error);
    } finally {
      setLoadingOverlay(false);
    }
  };
  const handleDeleteRow = (index) => {
    const updatedQuotation = [...accomodationUIList];
    updatedQuotation.splice(index, 1);
    setAccomodationUIList(updatedQuotation);
  };
  useEffect(() => {
    const fetchCompanyOptions = async () => {
      try {
        const response = await myAxiosCommonHost.get(
          "gst/profile/getCompanyCodeList"
        );
        setCompanyOptions(response.data);
      } catch (error) {
        console.error("Error fetching CompanyOptions:", error);
      }
    };

    fetchCompanyOptions();
  }, []);

  // const handleAddTermCondition = () => {
  //   const isDuplicate = quotationTermAndConditionsUI.some(
  //     (quotationTerm) =>
  //       quotationTerm.termCondition.trim() ===
  //       newTermCondition.termCondition.trim()
  //   );
  //   if (isDuplicate) {
  //     alert("Duplicate Entry! Cannot Add The Same Contact Person Name.");
  //     return;
  //   }
  //   if (newTermCondition.termCondition.trim() === "") {
  //     alert("Cannot add null or empty data to the table");
  //     return;
  //   }
  //   setQuotationTermAndConditionsUI((prevQuotationTermAndConditionsUI) => [
  //     ...prevQuotationTermAndConditionsUI,
  //     newTermCondition,
  //   ]);
  //   setNewTermCondition({
  //     termSequenceId: "",
  //     termCondition: "",
  //   });
  // };
  const handleAddTermCondition = () => {
    const isDuplicate = quotationTermAndConditionsUI.some(
      (quotationTerm) =>
        quotationTerm.termCondition.trim() ===
        newTermCondition.termCondition.trim()
    );
    if (isDuplicate) {
      alert("Duplicate Entry! Cannot Add The Same Term Condition.");
      return;
    }
    if (newTermCondition.termCondition.trim() === "") {
      alert("Cannot add null or empty data to the table");
      return;
    }
    setQuotationTermAndConditionsUI((prevQuotationTermAndConditionsUI) => [
      ...prevQuotationTermAndConditionsUI,
      {
        ...newTermCondition,
        termSequenceId: prevQuotationTermAndConditionsUI.length + 1,
      },
    ]);
    setNewTermCondition({
      termSequenceId: "",
      termCondition: "",
    });
  };

  const handleDeleteTermAndConditionsRow = (index) => {
    const TermAndConditions = [...quotationTermAndConditionsUI];
    TermAndConditions.splice(index, 1);
    setQuotationTermAndConditionsUI(TermAndConditions);
  };
  const handleTermAndConditionsUIChange = (e) => {
    const { name, value } = e.target;
    setNewTermCondition((prevNewAccomodation) => ({
      ...prevNewAccomodation,
      [name]: value,
    }));
  };
  return (
    <Container maxWidth="lg">
      <form onSubmit={handleSubmit}>
        <Card elevation={3} style={{ marginBottom: "20px", marginTop: "20px" }}>
          <CardHeader
            title="Generate Quotation"
            style={{
              backgroundColor: "#2196f3",
              color: "white",
              padding: "10px",
              textAlign: "center",
            }}
          />
          <Paper
            elevation={3}
            style={{ padding: "20px", marginBottom: "20px" }}
          >
            <Paper
              elevation={3}
              style={{ padding: "20px", marginBottom: "20px" }}
            >
              <Paper
                elevation={3}
                style={{
                  padding: "10px",
                  marginBottom: "30px",
                  backgroundColor: "darkgray",
                }}
              >
                <Typography
                  variant="h5"
                  gutterBottom
                  style={{
                    marginBottom: "15px",
                    color: "darkblue",
                    textAlign: "center",
                  }}
                >
                  Client Details
                </Typography>
              </Paper>
              {loadingOverlay && (
                <div className="loading-overlay">
                  <CircularProgress size={60} />
                  <div className="loading-message">Quotation is Saving...</div>
                </div>
              )}
              <Grid container spacing={2}>
                <Grid item xs={2} md={4}>
                  <Autocomplete
                    options={companyOptions}
                    getOptionLabel={(option) => option}
                    value={quotationDetails.selectCompany}
                    onChange={(event, newValue) => {
                      setQuotationDetails({
                        ...quotationDetails,
                        selectCompany: newValue,
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Company"
                        margin="normal"
                        variant="standard"
                        fullWidth
                        required
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={2} md={4}>
                  <Autocomplete
                    options={financialYears}
                    getOptionLabel={(option) => option}
                    value={quotationDetails.financialYear}
                    onChange={(event, newValue) => {
                      setQuotationDetails({
                        ...quotationDetails,
                        financialYear: newValue,
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Financial Year"
                        margin="normal"
                        variant="standard"
                        required
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={2} md={4}>
                  <Autocomplete
                    options={paymentMonths}
                    getOptionLabel={(option) => option}
                    value={quotationDetails.financialMonth}
                    onChange={(event, newValue) => {
                      setQuotationDetails({
                        ...quotationDetails,
                        financialMonth: newValue,
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Financial Month"
                        margin="normal"
                        variant="standard"
                        required
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={2} md={4}>
                  <TextField
                    label="Quotation Ref Number"
                    name="quotationRefNo"
                    value={quotationDetails.quotationRefNo}
                    onChange={handleQuotationDetailsChange}
                    fullWidth
                    margin="normal"
                    variant="standard"
                    required
                    disabled
                  />
                </Grid>
                <Grid item xs={2} md={4}>
                  <TextField
                    label="Quotation No"
                    name="quotationNo"
                    value={quotationDetails.quotationNo}
                    onChange={handleQuotationDetailsChange}
                    fullWidth
                    margin="normal"
                    variant="standard"
                    required
                    disabled
                  />
                </Grid>
                <Grid item xs={2} md={4}>
                  <TextField
                    label="Quotation Date"
                    name="quotationDate"
                    value={quotationDetails.quotationDate}
                    onChange={handleQuotationDetailsChange}
                    type="date"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    margin="normal"
                    variant="standard"
                    required
                  />
                </Grid>
                <Grid item xs={2} md={4}>
                  <Autocomplete
                    options={clientOptions}
                    getOptionLabel={(option) => option}
                    value={quotationDetails.clientNameAndCode}
                    onChange={(event, newValue) => {
                      setQuotationDetails({
                        ...quotationDetails,
                        clientNameAndCode: newValue,
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Client Name And Code"
                        margin="normal"
                        variant="standard"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={2} md={4}>
                  <TextField
                    label="Kind Attention"
                    name="kindAttention"
                    value={quotationDetails.kindAttention}
                    onChange={handleQuotationDetailsChange}
                    fullWidth
                    margin="normal"
                    variant="standard"
                    required
                  />
                </Grid>
                {/* <Grid item xs={2} md={4}>
                  <TextField
                    label="Quotation Subject"
                    name="quotationSubject"
                    value={quotationDetails.quotationSubject}
                    onChange={handleQuotationDetailsChange}
                    fullWidth
                    margin="normal"
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={2} md={4}>
                  <TextField
                    label="Quotation Content"
                    name="quotationContent"
                    value={quotationDetails.quotationContent}
                    onChange={handleQuotationDetailsChange}
                    fullWidth
                    margin="normal"
                    variant="standard"
                  />
                </Grid> */}
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Quotation Subject"
                    name="quotationSubject"
                    value={quotationDetails.quotationSubject}
                    onChange={handleQuotationDetailsChange}
                    fullWidth
                    margin="normal"
                    variant="standard" // Change the variant to outlined for multiline TextField
                    multiline // Add the multiline prop for multiline functionality
                    rows={6} // Adjust the rows prop as needed
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    label="Quotation Content"
                    name="quotationContent"
                    value={quotationDetails.quotationContent}
                    onChange={handleQuotationDetailsChange}
                    fullWidth
                    margin="normal"
                    variant="standard" // Change the variant to outlined for multiline TextField
                    multiline // Add the multiline prop for multiline functionality
                    rows={6} // Adjust the rows prop as needed
                  />
                </Grid>
              </Grid>
            </Paper>
          </Paper>
          <Paper
            elevation={3}
            style={{ padding: "20px", marginBottom: "20px" }}
          >
            <Paper
              elevation={3}
              style={{ padding: "20px", marginBottom: "20px" }}
            >
              <Paper
                elevation={3}
                style={{
                  padding: "10px",
                  marginBottom: "30px",
                  backgroundColor: "darkgray",
                }}
              >
                <Typography
                  variant="h5"
                  gutterBottom
                  style={{
                    marginBottom: "15px",
                    color: "darkblue",
                    textAlign: "center",
                  }}
                >
                  Quotation Details
                </Typography>
              </Paper>

              <Grid container spacing={2}>
                {/* <Grid item xs={2} md={4}>
                  <TextField
                    label="Accomodation Sr No"
                    name="accomodationSrNo"
                    value={newAccomodation.accomodationSrNo}
                    onChange={handleAccomodationUIChange}
                    fullWidth
                    margin="dense"
                    variant="standard"
                  />
                </Grid> */}
                <Grid item xs={2} md={4}>
                  <TextField
                    label="Description"
                    name="description"
                    value={newAccomodation.description}
                    onChange={handleAccomodationUIChange}
                    fullWidth
                    margin="dense"
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={2} md={4}>
                  <TextField
                    label="Cost Per Resources"
                    name="costPerResources"
                    value={newAccomodation.costPerResources}
                    onChange={handleAccomodationUIChange}
                    fullWidth
                    margin="dense"
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={2} md={4}>
                  <TextField
                    label="Number Of Resources"
                    name="noOfResources"
                    value={newAccomodation.noOfResources}
                    onChange={handleAccomodationUIChange}
                    fullWidth
                    margin="dense"
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={2} md={4}>
                  <TextField
                    label="Number Of Month"
                    name="noOfMonth"
                    value={newAccomodation.noOfMonth}
                    onChange={handleAccomodationUIChange}
                    fullWidth
                    margin="dense"
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={2} md={4}>
                  <TextField
                    label="Total Cost"
                    name="totalCost"
                    disabled
                    value={newAccomodation.totalCost}
                    onChange={handleAccomodationUIChange}
                    fullWidth
                    margin="dense"
                    variant="standard"
                  />
                </Grid>
              </Grid>
            </Paper>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow
                    style={{
                      backgroundColor: "darkcyan",
                      fontWeight: "bold",
                      color: "darkblue",
                    }}
                  >
                    <TableCell>SR.No</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Cost Per Resources</TableCell>
                    <TableCell>Number Of Resources</TableCell>
                    <TableCell>Number Of Month</TableCell>
                    <TableCell>Total Cost</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {accomodationUIList.map((entry, index) => (
                    <TableRow key={index}>
                      <TableCell>{entry.accomodationSrNo}</TableCell>
                      <TableCell>{entry.description}</TableCell>
                      <TableCell>{entry.costPerResources}</TableCell>
                      <TableCell>{entry.noOfResources}</TableCell>
                      <TableCell>{entry.noOfMonth}</TableCell>
                      <TableCell>{entry.totalCost}</TableCell>
                      <TableCell>
                        <IconButton onClick={() => handleDeleteRow(index)}>
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddEntry}
              style={{ marginTop: "20px" }}
            >
              Add Quotation Details
            </Button>
          </Paper>
          <Paper
            elevation={3}
            style={{ padding: "20px", marginBottom: "20px" }}
          >
            <Grid container spacing={2}>
              <Grid item xs={2} md={4}>
                <TextField
                  label="Total Amount"
                  name="totalAmount"
                  value={quotationDetails.totalAmount}
                  onChange={handleQuotationDetailsChange}
                  fullWidth
                  margin="normal"
                  variant="standard"
                />
              </Grid>
              <Grid item xs={2} md={4}>
                <TextField
                  label="Total In Word"
                  name="totalInWord"
                  value={quotationDetails.totalInWord}
                  onChange={handleQuotationDetailsChange}
                  fullWidth
                  margin="normal"
                  variant="standard"
                />
              </Grid>
              <Grid item xs={2} md={4}>
                <TextField
                  label="Service Accounting Code(SAC)"
                  name="serviceAccountingCode"
                  value={quotationDetails.serviceAccountingCode}
                  onChange={handleQuotationDetailsChange}
                  fullWidth
                  margin="normal"
                  variant="standard"
                />
              </Grid>
            </Grid>
          </Paper>
          <Paper
            elevation={3}
            style={{ padding: "20px", marginBottom: "20px" }}
          >
            <Paper
              elevation={3}
              style={{ padding: "20px", marginBottom: "20px" }}
            >
              <Paper
                elevation={3}
                style={{
                  padding: "10px",
                  marginBottom: "30px",
                  backgroundColor: "darkgray",
                }}
              >
                <Typography
                  variant="h5"
                  gutterBottom
                  style={{
                    marginBottom: "15px",
                    color: "darkblue",
                    textAlign: "center",
                  }}
                >
                  Add Term/Condition
                </Typography>
              </Paper>
              <Grid container spacing={2} alignItems="center">
                {/* <Grid item xs={10}>
                  <TextField
                    label="SR.No"
                    name="termSequenceId"
                    value={newTermCondition.termSequenceId}
                    onChange={handleTermAndConditionsUIChange}
                    fullWidth
                    margin="dense"
                    variant="standard"
                  />
                </Grid> */}
                <Grid item xs={10}>
                  <TextField
                    label="Add Term And Condition"
                    name="termCondition"
                    value={newTermCondition.termCondition}
                    onChange={handleTermAndConditionsUIChange}
                    fullWidth
                    margin="dense"
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={2}>
                  <Button
                    variant="contained"
                    onClick={handleAddTermCondition}
                    color="primary"
                    style={{ marginTop: "20px" }}
                  >
                    Add
                  </Button>
                </Grid>
              </Grid>
              <TableContainer component={Paper} style={{ marginTop: "10px" }}>
                <Table>
                  <TableHead>
                    <TableRow
                      style={{
                        backgroundColor: "darkcyan",
                        fontWeight: "bold",
                        color: "darkblue",
                      }}
                    >
                      <TableCell>Term Sequence ID</TableCell>
                      <TableCell>Term Condition</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {quotationTermAndConditionsUI.map((term, index) => (
                      <TableRow key={index}>
                        <TableCell>{term.termSequenceId}</TableCell>
                        <TableCell>{term.termCondition}</TableCell>
                        <TableCell>
                          <IconButton
                            onClick={() =>
                              handleDeleteTermAndConditionsRow(index)
                            }
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Paper>
          <Paper
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "10px",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              type="submit"
              style={{ marginBottom: "20px", marginLeft: "30px" }}
            >
              Submit
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleCancel}
              style={{
                marginBottom: "20px",
                marginLeft: "10px",
                backgroundColor: "red",
                color: "white",
              }}
            >
              Cancel
            </Button>
          </Paper>
        </Card>
      </form>
    </Container>
  );
};

export default GenerateQuotation;
