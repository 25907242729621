import Headers from "../../../Pages/Header";
import RegisterExpenseHeader from "../RegisterExpenseHeader";

const RegisterExpenseHeaderPage = () => {
  return (
    <Headers>
      <RegisterExpenseHeader />
    </Headers>
  );
};
export default RegisterExpenseHeaderPage;
