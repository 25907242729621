import Headers from "../../../Pages/Header";
import UpdateProjectPO from "../UpdateProjectPO";

const UpdateProjectPOPage = () => {
  return (
    <Headers>
      <UpdateProjectPO />
    </Headers>
  );
};
export default UpdateProjectPOPage;
