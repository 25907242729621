import Headers from "../../../Pages/Header";
import GenerateProjectInvoice from "../GenerateProjectInvoice";

const GenerateProjectInvoicePage = () => {
  return (
    <Headers>
      <GenerateProjectInvoice />
    </Headers>
  );
};
export default GenerateProjectInvoicePage;
