import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Container,
  Select,
  MenuItem,
  Paper,
  Grid,
  Card,
  CardHeader,
  FormControl,
  InputLabel,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { myAxiosCommonHost } from "../../Service/CorsHelperBaseUrl";
import { Autocomplete } from "@mui/material";

const RegisterVendorInformation = () => {
  const navigate = useNavigate();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(true);
  const [financialYear, setFinancialYear] = useState([]);
  const [vendorDetails, setVendorDetails] = useState({
    selectCompany: "",
    financialYear: "",
    regDate: "",
    vendorCode: "",
    vendorName: "",
    contactNumber: "",
    email: "",
    vendorAddress: "",
    bankName: "",
    branchName: "",
    accountNumber: "",
    ifsc: "",
    accountType: "",
    state: "",
    stateCode: "",
    gstin: "",
    panNumber: "",
    vendorUniqueNoCompanyWise: "",
  });

  const accountTypes = ["Savings", "Current"];
  const [loadingOverlay, setLoadingOverlay] = useState(false);
  const [company, setCompany] = useState([]);
  const [formData, setFormData] = useState({ ...vendorDetails });

  useEffect(() => {
    async function fetchCompany() {
      try {
        const response = await myAxiosCommonHost.get(
          "gst/profile/getCompanyCodeList"
        );
        const companies = response.data;
        setCompany(companies);
        setFormData((prevState) => ({
          ...prevState,
        }));
      } catch (error) {
        console.error("Error fetching company data:", error);
      }
    }
    fetchCompany();
  }, []);

  const getCurrentFinancialYear = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const fiscalStartYear = currentYear - (currentDate.getMonth() >= 3 ? 0 : 1);
    const fiscalEndYear = fiscalStartYear + 1;
    return `${(fiscalStartYear % 100).toString().padStart(2, "0")}-${(
      fiscalEndYear % 100
    )
      .toString()
      .padStart(2, "0")}`;
  };

  const defaultFinancialYear = getCurrentFinancialYear();
  const [selectedFinancialYear, setSelectedFinancialYear] =
    useState(defaultFinancialYear);

  async function fetchFinancialYear() {
    try {
      const response = await myAxiosCommonHost.get("gst/invoice/getFiscalYear");
      setFinancialYear(response.data);
    } catch (error) {
      console.error("Error fetching financial year data:", error);
    }
  }

  useEffect(() => {
    fetchFinancialYear();
    handleFinancialYearSelect(null, defaultFinancialYear);
  }, []);

  const fetchUniqueVendorNoCompanyWise = async (
    financialYear,
    selectCompany
  ) => {
    console.log(
      "Fetching unique vendor code for:",
      financialYear,
      selectCompany
    );
    try {
      const response = await myAxiosCommonHost.get(
        `gst/expense/getVendorUniqueNoCompanyWise?financialYear=${financialYear}&selectCompany=${selectCompany}`
      );
      let vendorResponseNo = "";
      const msgArr = response.data.split(",");
      console.log(msgArr);
      if (response.data.includes("Please")) {
        window.alert(response.data);
        vendorResponseNo = msgArr[1].trim();
      } else {
        vendorResponseNo = response.data.trim();
      }
      setVendorDetails((prevDetails) => ({
        ...prevDetails,
        vendorUniqueNoCompanyWise: vendorResponseNo,
      }));
    } catch (error) {
      console.error("Error fetching unique vendor code:", error);
    }
  };
  const handleFinancialYearSelect = (event, newValue) => {
    setSelectedFinancialYear(newValue);
    setVendorDetails((prevDetails) => ({
      ...prevDetails,
      financialYear: newValue,
    }));
    if (vendorDetails.selectCompany) {
      fetchUniqueVendorNoCompanyWise(newValue, vendorDetails.selectCompany);
    }
  };

  const handleVendorDetailsChange = (e, name, value) => {
    if (e && e.target) {
      const { name, value } = e.target;
      setVendorDetails((prevDetails) => ({
        ...prevDetails,
        [name]: value,
      }));
      if (name === "selectCompany" && selectedFinancialYear) {
        fetchUniqueVendorNoCompanyWise(selectedFinancialYear, value);
      }
    } else {
      setVendorDetails((prevDetails) => ({
        ...prevDetails,
        [name]: value,
      }));
      if (name === "selectCompany" && selectedFinancialYear) {
        fetchUniqueVendorNoCompanyWise(selectedFinancialYear, value);
      }
    }
  };

  const handleCancel = () => {
    const userConfirmed = window.confirm(
      "Are you sure you want to cancel? Any unsaved changes will be lost."
    );
    if (userConfirmed) {
      navigate("/updateVendorInformation");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoadingOverlay(true);
    setFormSubmitted(true);
    try {
      const postData = { ...vendorDetails };

      await myAxiosCommonHost.post(
        "gst/expense/createVendorInformation",
        postData
      );

      console.log(
        "Posted data in JSON format:",
        JSON.stringify(postData, null, 2)
      );

      // Show alert on successful post
      alert("Data posted successfully!");

      // Reset all fields after user clicks "OK" on the alert
      setVendorDetails({
        selectCompany: "",
        financialYear: "",
        regDate: "",
        vendorCode: "",
        vendorName: "",
        contactNumber: "",
        email: "",
        vendorAddress: "",
        bankName: "",
        branchName: "",
        accountNumber: "",
        ifsc: "",
        accountType: "",
        state: "",
        stateCode: "",
        gstin: "",
        panNumber: "",
        vendorUniqueNoCompanyWise: "",
      });

      // Navigate to updateVendor component
      navigate("/updateVendorInformation");

      console.log(
        "Data posted successfully, fields reset, and navigated to VendorList!"
      );
    } catch (error) {
      console.error("Error posting data:", error);
    } finally {
      setLoadingOverlay(false);
    }
  };

  return (
    <Container maxWidth="lg">
      <form onSubmit={handleSubmit}>
        <Card elevation={3} style={{ marginBottom: "20px", marginTop: "20px" }}>
          <CardHeader
            title="Vendor Registration"
            style={{
              backgroundColor: "#2196f3",
              color: "white",
              padding: "10px",
              textAlign: "center",
            }}
          />
          <Paper
            elevation={3}
            style={{ padding: "20px", marginBottom: "20px" }}
          >
            <Paper
              elevation={3}
              style={{ padding: "20px", marginBottom: "20px" }}
            >
              <Paper
                elevation={3}
                style={{
                  padding: "10px",
                  marginBottom: "30px",
                  backgroundColor: "darkgray",
                }}
              >
                <Typography
                  variant="h5"
                  gutterBottom
                  style={{
                    marginBottom: "15px",
                    color: "darkblue",
                    textAlign: "center",
                  }}
                >
                  Vendor Details
                </Typography>
              </Paper>
              {loadingOverlay && (
                <div className="loading-overlay">
                  <CircularProgress size={60} />
                  <div className="loading-message">Vendor is Saving...</div>
                </div>
              )}

              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    id="company"
                    options={company}
                    value={vendorDetails.selectCompany}
                    onChange={(e, newValue) =>
                      handleVendorDetailsChange(null, "selectCompany", newValue)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Company"
                        variant="standard"
                        margin="dense"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    id="financial-year"
                    options={financialYear}
                    loading={loading}
                    value={selectedFinancialYear}
                    onChange={handleFinancialYearSelect}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Financial Year"
                        variant="standard"
                        margin="dense"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Unique Vendor No"
                    name="vendorUniqueNoCompanyWise"
                    value={vendorDetails.vendorUniqueNoCompanyWise}
                    disabled
                    fullWidth
                    margin="dense"
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Registration Date"
                    name="regDate"
                    type="date"
                    value={vendorDetails.regDate}
                    onChange={handleVendorDetailsChange}
                    fullWidth
                    margin="dense"
                    variant="standard"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Vendor Code"
                    name="vendorCode"
                    value={vendorDetails.vendorCode}
                    onChange={handleVendorDetailsChange}
                    fullWidth
                    margin="dense"
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Vendor Name"
                    name="vendorName"
                    value={vendorDetails.vendorName}
                    onChange={handleVendorDetailsChange}
                    fullWidth
                    margin="dense"
                    variant="standard"
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Contact Number"
                    name="contactNumber"
                    value={vendorDetails.contactNumber}
                    onChange={handleVendorDetailsChange}
                    fullWidth
                    margin="dense"
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Email"
                    name="email"
                    value={vendorDetails.email}
                    onChange={handleVendorDetailsChange}
                    fullWidth
                    margin="dense"
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Vendor Address"
                    name="vendorAddress"
                    value={vendorDetails.vendorAddress}
                    onChange={handleVendorDetailsChange}
                    fullWidth
                    margin="dense"
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Bank Name"
                    name="bankName"
                    value={vendorDetails.bankName}
                    onChange={handleVendorDetailsChange}
                    fullWidth
                    margin="dense"
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Branch Name"
                    name="branchName"
                    value={vendorDetails.branchName}
                    onChange={handleVendorDetailsChange}
                    fullWidth
                    margin="dense"
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Account Number"
                    name="accountNumber"
                    value={vendorDetails.accountNumber}
                    onChange={handleVendorDetailsChange}
                    fullWidth
                    margin="dense"
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="IFSC"
                    name="ifsc"
                    value={vendorDetails.ifsc}
                    onChange={handleVendorDetailsChange}
                    fullWidth
                    margin="dense"
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl variant="standard" fullWidth margin="dense">
                    <InputLabel>Account Type</InputLabel>
                    <Select
                      name="accountType"
                      value={vendorDetails.accountType}
                      onChange={handleVendorDetailsChange}
                    >
                      {accountTypes.map((type) => (
                        <MenuItem key={type} value={type}>
                          {type}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="State"
                    name="state"
                    value={vendorDetails.state}
                    onChange={handleVendorDetailsChange}
                    fullWidth
                    margin="dense"
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="State Code"
                    name="stateCode"
                    value={vendorDetails.stateCode}
                    onChange={handleVendorDetailsChange}
                    fullWidth
                    margin="dense"
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="GSTIN"
                    name="gstin"
                    value={vendorDetails.gstin}
                    onChange={handleVendorDetailsChange}
                    fullWidth
                    margin="dense"
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="PAN Number"
                    name="panNumber"
                    value={vendorDetails.panNumber}
                    onChange={handleVendorDetailsChange}
                    fullWidth
                    margin="dense"
                    variant="standard"
                  />
                </Grid>
              </Grid>
            </Paper>
            <Grid container spacing={2} justifyContent="center">
              <Grid item>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={formSubmitted}
                >
                  Submit
                </Button>
              </Grid>
              <Grid item>
                <Button
                  type="button"
                  variant="contained"
                  color="secondary"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Card>
      </form>
    </Container>
  );
};

export default RegisterVendorInformation;
