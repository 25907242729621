import React, { useContext, useEffect, useState } from "react";
import { myAxiosCommonHost } from "../../Service/CorsHelperBaseUrl";
import { CardContent } from "@material-ui/core";
import {
  Card,
  Paper,
  Grid,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  CircularProgress,
  Typography,
  TablePagination,
  Button,
} from "@material-ui/core";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import { CompanyContext } from "../StateProvider/CompanyProvider";

const NotificationGSTUI = () => {
  const [employeePONotificationUI, setEmployeePONotificationUI] = useState([]);
  const [projectPONotificationUI, setProjectPONotificationUI] = useState([]);
  const [isPONotificationUI, setIsPONotificationUI] = useState(false);
  const [page, setPage] = useState(0); // Add page state
  const [rowsPerPage, setRowsPerPage] = useState(10); // Add rowsPerPage state
  const [totalEmployeeCount, setTotalEmployeeCount] = useState(0);
  const [totalProjectCount, setTotalProjectCount] = useState(0);
  const { selectedCompany } = useContext(CompanyContext);
  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    setIsPONotificationUI(true);
    if (selectedCompany) {
      myAxiosCommonHost
        .get(
          `gst/notification/getNotificationGSTList?selectCompany=${selectedCompany}`
        )
        .then((response) => {
          setEmployeePONotificationUI(response.data.employeePONotificationUI);
          setProjectPONotificationUI(response.data.projectPONotificationUI);
          setTotalEmployeeCount(response.data.employeePONotificationCount);
          setTotalProjectCount(response.data.projectPONotificationCount);
        })
        .catch((error) => {
          console.error("Error fetching project summary:", error);
        })
        .finally(() => {
          setIsPONotificationUI(false);
        });
    }
  }, [selectedCompany]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleViewClick = (empPoUniqueNoAndGSCode) => {
    navigate(`/updateEmployeePO/${empPoUniqueNoAndGSCode}`);
  };

  const handleViewClickProject = (gsProjectUniqueCode) => {
    navigate(`/updateProjectPO/${gsProjectUniqueCode}`);
  };

  return (
    <div>
      {isPONotificationUI && (
        <div className="blur-effect">
          <CircularProgress color="primary" />
          <div className="loading-message">Getting Notifications...</div>
        </div>
      )}
      <Grid>
        <Grid>
          <Card>
            <CardContent>
              <Paper
                elevation={3}
                style={{ padding: "20px", marginBottom: "20px" }}
              >
                <Paper
                  elevation={3}
                  style={{
                    padding: "10px",
                    marginBottom: "10px",
                    backgroundColor: "#2196f3",
                  }}
                >
                  <Typography
                    variant="h5"
                    gutterBottom
                    style={{
                      marginBottom: "15px",
                      color: "white",
                      textAlign: "center",
                    }}
                  >
                    Notifications
                  </Typography>
                </Paper>
              </Paper>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {employeePONotificationUI.length > 0 && (
        <Grid>
          <Paper
            elevation={3}
            style={{ padding: "20px", marginBottom: "20px" }}
          >
            <Paper
              elevation={3}
              style={{ padding: "10px", marginBottom: "10px" }}
            >
              <Typography
                variant="h5"
                gutterBottom
                style={{ marginBottom: "15px", color: "#2196f3" }}
              >
                Employee PO Notifications
              </Typography>
            </Paper>
            <TableContainer component={Paper} style={{ marginTop: "20px" }}>
              <Table>
                <TableHead>
                  <TableRow
                    style={{
                      backgroundColor: "darkcyan",
                      fontWeight: "bold",
                      color: "darkblue",
                    }}
                  >
                    <TableCell>Action</TableCell>
                    <TableCell>SR.No</TableCell>
                    <TableCell>Employee Unique Code</TableCell>
                    <TableCell>Client Code</TableCell>
                    <TableCell>Client Name</TableCell>
                    <TableCell>Employee Name</TableCell>
                    <TableCell>Employee Code</TableCell>
                    <TableCell>PO Start Date</TableCell>
                    <TableCell>PO End Date</TableCell>
                    <TableCell>PO End In Day</TableCell>
                    <TableCell>PO End Hour</TableCell>
                    <TableCell>PO End Min</TableCell>
                    <TableCell>PO Validation</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {employeePONotificationUI.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={5} align="center">
                        Employee PO Notifications found
                      </TableCell>
                    </TableRow>
                  ) : (
                    employeePONotificationUI.map((row, index) => (
                      <TableRow key={row.empPoUniqueNoAndGSCode}>
                        <TableCell>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() =>
                              handleViewClick(row.empPoUniqueNoAndGSCode)
                            }
                          >
                            View
                          </Button>
                        </TableCell>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{row.empPoUniqueNoAndGSCode}</TableCell>
                        <TableCell>{row.clientCode}</TableCell>
                        <TableCell>{row.clientName}</TableCell>
                        <TableCell>{row.employeeName}</TableCell>
                        <TableCell>{row.employeeCode}</TableCell>
                        <TableCell>
                          {row.poStartDate
                            ? row.poStartDate.split("-").reverse().join("-")
                            : "N/A"}
                        </TableCell>
                        <TableCell>
                          {row.poEndDate
                            ? row.poEndDate.split("-").reverse().join("-")
                            : "N/A"}
                        </TableCell>
                        <TableCell>{row.poEndIn}</TableCell>
                        <TableCell>{row.poEndHour}</TableCell>
                        <TableCell>{row.poEndMin}</TableCell>
                        <TableCell>{row.poValidation}</TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 15, 20]}
              component="div"
              count={totalEmployeeCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
      )}
      {projectPONotificationUI.length > 0 && (
        <Grid>
          <Paper
            elevation={3}
            style={{ padding: "20px", marginBottom: "20px" }}
          >
            <Paper
              elevation={3}
              style={{ padding: "10px", marginBottom: "10px" }}
            >
              <Typography
                variant="h5"
                gutterBottom
                style={{ marginBottom: "15px", color: "#2196f3" }}
              >
                Project Po Notifications
              </Typography>
            </Paper>
            <TableContainer component={Paper} style={{ marginTop: "20px" }}>
              <Table>
                <TableHead>
                  <TableRow
                    style={{
                      backgroundColor: "darkcyan",
                      fontWeight: "bold",
                      color: "darkblue",
                    }}
                  >
                    <TableCell>Action</TableCell>
                    <TableCell>SR.No</TableCell>
                    <TableCell>Project Unique Code</TableCell>
                    <TableCell>Client Code</TableCell>
                    <TableCell>Client Name</TableCell>
                    <TableCell>Project Name</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>PO Start Date</TableCell>
                    <TableCell>PO End Date</TableCell>
                    <TableCell>PO End In Day</TableCell>
                    <TableCell>PO End Hour</TableCell>
                    <TableCell>PO End Min</TableCell>
                    <TableCell>PO Validation</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {projectPONotificationUI.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={5} align="center">
                        No Project Po Notifications found
                      </TableCell>
                    </TableRow>
                  ) : (
                    projectPONotificationUI.map((row, index) => (
                      <TableRow key={row.gsProjectUniqueCode}>
                        <TableCell>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() =>
                              handleViewClickProject(row.gsProjectUniqueCode)
                            }
                          >
                            View
                          </Button>
                        </TableCell>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{row.gsProjectUniqueCode}</TableCell>
                        <TableCell>{row.clientCode}</TableCell>
                        <TableCell>{row.clientName}</TableCell>
                        <TableCell>{row.projectName}</TableCell>
                        <TableCell>{row.description}</TableCell>
                        <TableCell>
                          {row.poStartDate
                            ? row.poStartDate.split("-").reverse().join("-")
                            : "N/A"}
                        </TableCell>
                        <TableCell>
                          {row.poEndDate
                            ? row.poEndDate.split("-").reverse().join("-")
                            : "N/A"}
                        </TableCell>
                        <TableCell>{row.poEndIn}</TableCell>
                        <TableCell>{row.poEndHour}</TableCell>
                        <TableCell>{row.poEndMin}</TableCell>
                        <TableCell>{row.poValidation}</TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 15, 20]}
              component="div"
              count={totalProjectCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
      )}
    </div>
  );
};

export default NotificationGSTUI;
