import React, { useEffect, useState } from 'react';
import axios from 'axios';
import PizZip from 'pizzip';
import Docxtemplater from 'docxtemplater';
import { saveAs } from 'file-saver';
import JSZipUtils from 'jszip-utils';
import { myAxiosCommonHost } from '../../Service/CorsHelperBaseUrl';

const Test3 = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    myAxiosCommonHost.get('/gst/quotation/getQuotationList')
      .then(response => {
        console.log('Data fetched:', response.data);
        setData(response.data[0]);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);

  const generateDocument = () => {
    const templatePath = 'QuoteNo2.docx';

    JSZipUtils.getBinaryContent(templatePath, (error, content) => {
      if (error) {
        console.error('Error fetching template:', error);
        return;
      }

      let zip;
      try {
        zip = new PizZip(content);
      } catch (e) {
        console.error('Error loading zip:', e);
        return;
      }

      let doc;
      try {
        doc = new Docxtemplater(zip, {
          paragraphLoop: true,
          linebreaks: true,
        });
      } catch (e) {
        console.error('Error creating Docxtemplater instance:', e);
        return;
      }

      doc.setData({
        quotationRefNo: data.quotationRefNo,
        quotationDate: new Date(data.quotationDate).toLocaleDateString(),
        clientUI: {
          clientName: data.clientUI.clientName,
          clientAddress: data.clientUI.clientAddress,
        },
        kindAttention: data.kindAttention,
        quotationSubject: data.quotationSubject,
        accomodationUIList: data.accomodationUIList.map((item, index) => ({
          index: index + 1,
          description: item.description,
          costPerResources: item.costPerResources,
          noOfResources: item.noOfResources,
          noOfMonth: item.noOfMonth,
          totalCost: item.totalCost,
        })),
        totalAmount: `${data.totalAmount} (${data.totalInWord})`,
        gstDetailsUI: {
          state: data.gstDetailsUI.state,
          stateCode: data.gstDetailsUI.stateCode,
        },
        serviceAccountingCode: data.gstDetailsUI.hsnsac,
      });

      try {
        doc.render();
      } catch (error) {
        console.error('Error rendering document:', error);
        return;
      }

      const out = doc.getZip().generate({
        type: 'blob',
        mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      });

      saveAs(out, 'quotation.docx');
    });
  };

  return (
    <div>
      {data ? (
        <button onClick={generateDocument}>Download Quotation</button>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default Test3;
