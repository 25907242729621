import React, { useContext, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  Collapse,
  Avatar,
  Box,
  TextField,
  ListItemIcon,
} from "@mui/material";
import {
  ExpandLess,
  ExpandMore,
  ExitToApp as ExitToAppIcon,
} from "@mui/icons-material";
import GsquareLOGO from "../../Component/Invoice/GsquareLOGO.jpg";
import Bgd_LOGO from "../../Component/Invoice/Bgd_LOGO.jpeg";
import { Autocomplete } from "@mui/material";
import { CompanyContext } from "../StateProvider/CompanyProvider";
import { myAxiosCommonHost } from "../../Service/CorsHelperBaseUrl";

const NavigationDrawer = () => {
  const getCurrentFinancialYear = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const fiscalStartYear = currentYear - (currentDate.getMonth() >= 3 ? 0 : 1);
    const fiscalEndYear = fiscalStartYear + 1;
    return `${(fiscalStartYear % 100).toString().padStart(2, "0")}-${(
      fiscalEndYear % 100
    )
      .toString()
      .padStart(2, "0")}`;
  };
  const defaultFinancialYear = getCurrentFinancialYear();
  const [selectedFinancialYear, setSelectedFinancialYear] =
    useState(defaultFinancialYear);
  const [openReg, setOpenReg] = useState(false);
  const [openPO, setOpenPO] = useState(false);
  const [openInvoice, setOpenInvoice] = useState(false);
  const [openSummary, setOpenSummary] = useState(false);
  const {selectedCompany, setSelectedCompany } = useContext(CompanyContext);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [financialYear, setFinancialYear] = useState([]);
  const [openOutstanding, setOpenOutstanding] = useState(false);

  useEffect(() => {
    const getCompanyData = async () => {
      try {
        const response = await myAxiosCommonHost.get(
          "gst/profile/getCompanyCodeList"
        );
        const initialCompany = response.data[0]; // Set initial company to the first item in the array
        setCompanyOptions(response.data);
        setSelectedCompany(initialCompany); // Set initial selected company
      } catch (error) {
        console.error("There was a problem with the fetch operation:", error);
      }
    };
    getCompanyData();
  }, []);
  useEffect(() => {
    const fetchFinancialYear = async () => {
      try {
        const response = await myAxiosCommonHost.get(
          "gst/invoice/getFiscalYear"
        );
        setFinancialYear(response.data);
      } catch (error) {
        console.error("Error fetching financial year data:", error);
      }
    };
    fetchFinancialYear();
  }, []);

  const handleRegClick = () => {
    setOpenReg(!openReg);
  };

  const handlePOClick = () => {
    setOpenPO(!openPO);
  };

  const handleInvoiceClick = () => {
    setOpenInvoice(!openInvoice);
  };

  const handleSummaryClick = () => {
    setOpenSummary(!openSummary);
  };

  const getLogo = () => {
    if (selectedCompany === "GST") {
      return GsquareLOGO;
    } else if (selectedCompany === "BGD") {
      return Bgd_LOGO;
    } else {
      return GsquareLOGO;
    }
  };
  const navigate = useNavigate();
  const handleLogout = () => {
    navigate("/login");
  };

  const handleOutstandingClick = () => {
    setOpenOutstanding(!openOutstanding);
  };
  return (
    <Drawer
      variant="permanent"
      anchor="left"
      sx={{
        width: 300,
        flexShrink: 10,
        [`& .MuiDrawer-paper`]: {
          width: 400,
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
        },
      }}
    >
    {/* // <Drawer
    //   variant="permanent"
    //   anchor="top" // Change anchor from "left" to "top"
    //   sx={{
    //     width: "100%", // Adjust width as needed
    //     flexShrink: 0,
    //     [`& .MuiDrawer-paper`]: {
    //       boxSizing: "border-box",
    //       display: "flex",
    //       flexDirection: "column",
    //       justifyContent: "space-between",
    //       height: 240, // Set height for the drawer if necessary
    //     },
    //   }}
    // > */}
      {/* <Box sx={{ display: "flex", justifyContent: "center", padding: "10px" }}>
        <Avatar
          alt="GsquareLOGO"
          src={GsquareLOGO}
          sx={{ width: 100, height: 100 }}
        />
      </Box> */}
      <Box sx={{ display: "flex", justifyContent: "center", padding: "10px" }}>
        {getLogo() && (
          <Avatar alt="Logo" src={getLogo()} sx={{ width: 100, height: 100 }} />
        )}
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center", padding: "10px", marginBottom:"10px"}}>
        <Autocomplete
          options={companyOptions}
          getOptionLabel={(option) => option}
          style={{ width: 300 }}
          value={selectedCompany}
          onChange={(event, newValue) => {
            setSelectedCompany(newValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select Company"
              variant="standard"
              style={{ backgroundColor: "white" }}
            />
          )}
        />
        <Autocomplete
          id="financial-year"
          style={{ width: 300 }}
          options={financialYear}
          value={selectedFinancialYear}
          onChange={(event, newValue) => {
            setSelectedFinancialYear(newValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Financial Year"
              variant="standard"
              style={{ backgroundColor: "white" }}
            />
          )}
        />
      </Box>

      <List>
        <ListItem
          button
          onClick={handleRegClick}
          sx={{ color: "blue" }}
        >
          <ListItemText primary="Registration" />
          {openReg ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openReg} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button component={Link}  to="/updateEmployee">
              <ListItemText primary="Employee" />
            </ListItem>
            <ListItem button component={Link} to="/updateClient">
              <ListItemText primary="Client" />
            </ListItem>
          </List>
        </Collapse>
        <ListItem
          button
          onClick={handleInvoiceClick}
          sx={{ color: "blue" }}
        >
          <ListItemText primary="Invoice" />
          {openInvoice ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openInvoice} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button component={Link} to="/employeeInvoiceList">
              <ListItemText primary="Employee Invoice" />
            </ListItem>
            <ListItem button component={Link} to="/projectInvoiceList">
              <ListItemText primary="Project Invoice" />
            </ListItem>
            <ListItem button component={Link} to="/otherChargesInvoiceList">
              <ListItemText primary="Other Charges Invoice" />
            </ListItem>
            <ListItem button component={Link} to="/invoiceReportPage">
              <ListItemText primary="Invoice Report" />
            </ListItem>
            <ListItem
              button
              onClick={handleOutstandingClick}
              sx={{ color: "blue" }}
            >
              <ListItemText primary="OutStanding" />
              {openOutstanding ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={openOutstanding} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem
                  button
                  component={Link}
                  to="/outStandingUnpaidHistory"
                >
                  <ListItemText primary="OutStanding UnPaid History" />
                </ListItem>
                <ListItem button component={Link} to="/outStandingpaidHistory">
                  <ListItemText primary="OutStanding PaidHistory" />
                </ListItem>
              </List>
            </Collapse>
          </List>
        </Collapse>
        <ListItem button onClick={handlePOClick} sx={{ color: "blue" }}>
          <ListItemText primary="Purchase Orders" />
          {openPO ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openPO} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button component={Link} to="/updateEmpPO">
              <ListItemText primary="Employee PO" />
            </ListItem>
            <ListItem button component={Link} to="/updateProject">
              <ListItemText primary="Project PO" />
            </ListItem>
            <ListItem
              button
              onClick={handleSummaryClick}
              sx={{ color: "blue" }}
            >
              <ListItemText primary="Summary" />
              {openSummary ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={openSummary} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem button component={Link} to="/employeePOSummaryPage">
                  <ListItemText primary="Employee PO Summary" />
                </ListItem>
                <ListItem button component={Link} to="/projectPOSummary">
                  <ListItemText primary="Project PO Summary" />
                </ListItem>
                <ListItem button component={Link} to="/employeeSummary">
                  <ListItemText primary="Employee Summary" />
                </ListItem>
              </List>
            </Collapse>
          </List>
        </Collapse>
      </List>
      <Box
        sx={{ borderTop: "1px solid #ccc", marginTop: "auto", padding: "16px" }}
      >
        <ListItem button onClick={handleLogout} sx={{ color: "inherit",backgroundColor:"blue" }}>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary="Logout" color="white"/>
        </ListItem>
      </Box>
    </Drawer>
  );
};

export default NavigationDrawer;
