import React, { useState, useEffect } from "react";
import { saveAs } from "file-saver";
import PizZip from "pizzip";
import Docxtemplater from "docxtemplater";
import { PDFDocument } from "pdf-lib";
import { myAxiosCommonHost } from "../../Service/CorsHelperBaseUrl";
import NewQuotationWord2 from "../QuotationPDF/NewQuotationWord2.docx";

const QuotationGenerator3 = () => {
  const [data, setData] = useState(null);
  const [docBlob, setDocBlob] = useState(null);

  useEffect(() => {
    myAxiosCommonHost
      //   .get("/gst/quotation/getQuotationList")
      .get(`gst/quotation/viewQuotationPdf?quotationNo=2`)
      .then((response) => {
        setData(response.data[0]);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const formatFileName = (subject, date) => {
    const formattedSubject = subject
      .replace(/[^a-zA-Z0-9]/g, " ")
      .trim()
      .replace(/\s+/g, "_");
    const formattedDate = date.toISOString().split("T")[0];
    return `GSQUARE_${formattedSubject}(${formattedDate}).docx`;
  };

  const generateDocument = async () => {
    if (!data) {
      alert("Data is not loaded yet.");
      return;
    }

    let content;
    try {
      content = await fetch(NewQuotationWord2).then((res) => res.arrayBuffer());
    } catch (error) {
      console.error("Error fetching template:", error);
      return;
    }

    let zip;
    try {
      zip = new PizZip(content);
    } catch (e) {
      console.error("Error loading zip:", e);
      return;
    }

    let doc;
    try {
      doc = new Docxtemplater(zip, {
        paragraphLoop: true,
        linebreaks: true,
      });
    } catch (e) {
      console.error("Error creating Docxtemplater instance:", e);
      return;
    }

    doc.setData({
      quotationRefNo: data.quotationRefNo,
      quotationContent: data.quotationContent,
      quotationDate: new Date(data.quotationDate).toLocaleDateString(),
      clientName: data.clientUI.clientName,
      clientAddress: data.clientUI.clientAddress,
      kindAttention: data.kindAttention,
      quotationSubject: data.quotationSubject,
      totalAmount: data.totalAmount,
      totalInWord: data.totalInWord,
      state: data.gstDetailsUI.state,
      stateCode: data.gstDetailsUI.stateCode,
      serviceAccountingCode: data.serviceAccountingCode,
      accomodationUIList: data.accomodationUIList.map((item, index) => ({
        index: index + 1,
        description: item.description,
        costPerResources: item.costPerResources,
        noOfResources: item.noOfResources,
        noOfMonth: item.noOfMonth,
        totalCost: item.totalCost,
      })),
      quotationTermAndConditionsUI: data.quotationTermAndConditionsUI.map(
        (item, index) => ({
          termSequenceId: item.termSequenceId,
          termCondition: item.termCondition,
        })
      ),
    });

    try {
      doc.render();
    } catch (error) {
      console.error("Error rendering document:", error);
      return;
    }

    const out = doc.getZip().generate({
      type: "blob",
      mimeType:
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    });
    const fileName = formatFileName(data.quotationSubject, new Date());

    saveAs(out, fileName);
    setDocBlob(out);
  };

  const generatePDF = async () => {
    if (!docBlob) {
      alert("Please generate the DOCX file first.");
      return;
    }

    const pdfDoc = await PDFDocument.create();
    const templateBytes = new Uint8Array(await docBlob.arrayBuffer());
    const templateDoc = await PDFDocument.load(templateBytes);

    const [templatePage] = await pdfDoc.copyPages(templateDoc, [0]);
    pdfDoc.addPage(templatePage);

    const pdfBytes = await pdfDoc.save();
    const pdfBlob = new Blob([pdfBytes], { type: "application/pdf" });

    const fileName = formatFileName(data.quotationSubject, new Date());

    saveAs(pdfBlob, `${fileName}.pdf`);
  };

  return (
    <div>
      <h1>Quotation Generator</h1>
      <button onClick={generateDocument}>Generate Quotation</button>
      <button onClick={generatePDF}>Download PDF</button>
    </div>
  );
};

export default QuotationGenerator3;
