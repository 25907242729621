import Headers from "../../../Pages/Header";
import GenerateEmpPO from "../GenerateEmpPO";

const GenerateEmpPOPage = () => {
  return (
    <Headers>
      <GenerateEmpPO />
    </Headers>
  );
};
export default GenerateEmpPOPage;
