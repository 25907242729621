import React, { useState } from "react";
import {
  TextField,
  Button,
  Container,
  Grid,
  Paper,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { myAxiosCommonHost } from "../../Service/CorsHelperBaseUrl";

const RegisterBank = () => {
  const navigate = useNavigate();
  const [bankDetails, setBankDetails] = useState({
    accountNumber: "",
    bankName: "",
    accountType: "",
    branchName: "",
    ifsc: "",
    micr: "",
    bankAddress: "",
    swiftCode: "",
  });
  const [loadingOverlay, setLoadingOverlay] = useState(false);

  const handleBankDetailsChange = (e) => {
    const { name, value } = e.target;
    setBankDetails((prevBankDetails) => ({
      ...prevBankDetails,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoadingOverlay(true);
    try {
      const postData = {
        ...bankDetails,
      };

      await myAxiosCommonHost.post("gst/bank/createBankDetails", postData);

      // Show alert on successful post
      alert("Bank registered successfully!");
      setBankDetails({}); // Reset bank details
      navigate("/updateBankPage"); // Navigate to bank list page after successful registration
    } catch (error) {
      console.error("Error posting data:", error);
      alert("Error registering bank.");
    } finally {
      setLoadingOverlay(false);
    }
  };

  const handleCancel = () => {
    const userConfirmed = window.confirm(
      "Are you sure you want to cancel? Any unsaved changes will be lost."
    );
    if (userConfirmed) {
      navigate("/updateBankPage");
    }
  };

  return (
    <div>
      <Container maxWidth="lg">
        <form onSubmit={handleSubmit}>
          <Paper
            elevation={3}
            style={{ marginBottom: "20px", marginTop: "20px" }}
          >
            <Paper
              elevation={3}
              style={{ padding: "20px", marginBottom: "20px" }}
            >
              <Typography
                variant="h5"
                gutterBottom
                style={{
                  marginBottom: "15px",
                  color: "darkblue",
                  textAlign: "center",
                }}
              >
                Bank Details
              </Typography>
              {loadingOverlay && (
                <div className="loading-overlay">
                  <CircularProgress size={60} />
                  <div className="loading-message">
                    Bank details are being processed...
                  </div>
                </div>
              )}
              <Grid container spacing={2}>
                <Grid item xs={6} md={4}>
                  <TextField
                    label="Account Number"
                    name="accountNumber"
                    value={bankDetails.accountNumber}
                    onChange={handleBankDetailsChange}
                    fullWidth
                    margin="dense"
                    variant="standard"
                    required
                    type="number"
                  />
                </Grid>
                <Grid item xs={6} md={4}>
                  <TextField
                    label="Bank Name"
                    name="bankName"
                    value={bankDetails.bankName}
                    onChange={handleBankDetailsChange}
                    fullWidth
                    margin="dense"
                    variant="standard"
                    required
                  />
                </Grid>
                <Grid item xs={6} md={4}>
                  <TextField
                    label="Account Type"
                    name="accountType"
                    value={bankDetails.accountType}
                    onChange={handleBankDetailsChange}
                    fullWidth
                    margin="dense"
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={6} md={4}>
                  <TextField
                    label="Branch Name"
                    name="branchName"
                    value={bankDetails.branchName}
                    onChange={handleBankDetailsChange}
                    fullWidth
                    margin="dense"
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={6} md={4}>
                  <TextField
                    label="IFSC"
                    name="ifsc"
                    value={bankDetails.ifsc}
                    onChange={handleBankDetailsChange}
                    fullWidth
                    margin="dense"
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={6} md={4}>
                  <TextField
                    label="MICR"
                    name="micr"
                    value={bankDetails.micr}
                    onChange={handleBankDetailsChange}
                    fullWidth
                    margin="dense"
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={6} md={4}>
                  <TextField
                    label="Bank Address"
                    name="bankAddress"
                    value={bankDetails.bankAddress}
                    onChange={handleBankDetailsChange}
                    fullWidth
                    margin="dense"
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={6} md={4}>
                  <TextField
                    label="Swift Code"
                    name="swiftCode"
                    value={bankDetails.swiftCode}
                    onChange={handleBankDetailsChange}
                    fullWidth
                    margin="dense"
                    variant="standard"
                  />
                </Grid>
              </Grid>
            </Paper>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                padding: "10px",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                type="submit"
                style={{ marginBottom: "20px", marginRight: "10px" }}
              >
                Submit
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleCancel}
                style={{ marginBottom: "20px" }}
              >
                Cancel
              </Button>
            </div>
          </Paper>
        </form>
      </Container>
    </div>
  );
};

export default RegisterBank;
