import React, { useState } from "react";
import {
  TextField,
  Button,
  Container,
  Select,
  MenuItem,
  Paper,
  Grid,
  Card,
  CardHeader,
  FormControl,
  InputLabel,
  Typography,
} from "@mui/material";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import DeleteIcon from "@material-ui/icons/Delete";
import { IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { myAxiosCommonHost } from "../Service/CorsHelperBaseUrl";
const RegisterClientVal = () => {
  const navigate = useNavigate();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [clientDetails, setClientDetails] = useState({
    clientType: "",
    clientCode: "",
    clientName: "",
    clientAddress: "",
    clientRegNumber: "",
    clientWebsite: "",
    clientContactNumber: "",
    billingPeriod: "",
  });

  const clientTypes = ["Automobile", "IT", "Manufacturing", "Marketing"];

  const [gstdetailsUI, setGstdetailsUI] = useState({
    state: "",
    stateCode: "",
    panNumber: "",
    gstin: "",
    hsnsac: "",
  });

  const [contractdateUI, setContractdateUI] = useState({
    startDate: "",
    endDate: "",
    msa: "",
    nda: "",
  });

  const [contactpersonUI, setContactpersonUI] = useState([]);
  const [newContactPerson, setNewContactPerson] = useState({
    contactPersonName: "",
    designation: "",
    emailID: "",
    contactNumber: "",
  });
  const handleAddEntry = () => {
    if (
      newContactPerson.contactPersonName.trim() === "" ||
      newContactPerson.designation.trim() === "" ||
      newContactPerson.emailID.trim() === "" ||
      newContactPerson.contactNumber.trim() === ""
    ) {
      alert("Cannot add null or empty data to the table");
      return;
    }
    setContactpersonUI((prevContactpersonUI) => [
      ...prevContactpersonUI,
      newContactPerson,
    ]);
    setNewContactPerson({
      contactPersonName: "",
      designation: "",
      emailID: "",
      contactNumber: "",
    });
  };
  const handleContactPersonUIChange = (e) => {
    const { name, value } = e.target;
    setNewContactPerson((prevNewContactPerson) => ({
      ...prevNewContactPerson,
      [name]: value,
    }));
  };
  const handleClientDetailsChange = (e) => {
    const { name, value } = e.target;
    setClientDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleGstdetailsUIChange = (e) => {
    const { name, value } = e.target;
    setGstdetailsUI((prevGstdetails) => ({
      ...prevGstdetails,
      [name]: value,
    }));
  };

  const handleContractdateUIChange = (e) => {
    const { name, value } = e.target;
    setContractdateUI((prevContractdate) => ({
      ...prevContractdate,
      [name]: value,
    }));
  };
  const handleCancel = () => {
    const userConfirmed = window.confirm(
      "Are you sure you want to cancel? Any unsaved changes will be lost."
    );
    if (userConfirmed) {
      navigate("/updateClient");
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormSubmitted(true);

    // Check if clientCode is empty
    if (clientDetails.clientCode === "") {
      // Display an alert and prevent further execution
      alert("Client Code is required!");
      return;
    }

    try {
      const postData = {
        ...clientDetails,
        gstdetailsUI,
        contractdateUI,
        contactpersonUI,
      };

      await myAxiosCommonHost.post("gst/client/createClient", postData);

      console.log(
        "Posted data in JSON format:",
        JSON.stringify(postData, null, 2)
      );

      // Show alert on successful post
      alert("Data posted successfully!");

      // Reset all fields after user clicks "OK" on the alert
      setClientDetails({
        clientType: "",
        clientCode: "",
        clientName: "",
        clientAddress: "",
        clientRegNumber: "",
        clientWebsite: "",
        clientContactNumber: "",
        billingPeriod: "",
      });

      setGstdetailsUI({
        state: "",
        stateCode: "",
        panNumber: "",
        gstin: "",
        hsnsac: "",
      });

      setContractdateUI({
        startDate: "",
        endDate: "",
        msa: "",
        nda: "",
      });

      setContactpersonUI([]);

      setNewContactPerson({
        contactPersonName: "",
        designation: "",
        emailID: "",
        contactNumber: "",
      });

      // Navigate to updateClient component
      navigate("/updateClient");

      console.log(
        "Data posted successfully, fields reset, and navigated to ClientList!"
      );
    } catch (error) {
      console.error("Error posting data:", error);
    }
  };

  const handleDeleteRow = (index) => {
    const updatedEmployees = [...contactpersonUI];
    updatedEmployees.splice(index, 1);
    setContactpersonUI(updatedEmployees);
  };

  return (
    <Container maxWidth="lg">
      <form onSubmit={handleSubmit}>
        <Card elevation={3} style={{ marginBottom: "20px", marginTop: "20px" }}>
          <CardHeader
            title="Client Registation"
            style={{
              backgroundColor: "#2196f3",
              color: "white",
              padding: "10px",
              textAlign: "center",
            }}
          />
          <Paper
            elevation={3}
            style={{ padding: "20px", marginBottom: "20px" }}
          >
            <Paper
              elevation={3}
              style={{ padding: "20px", marginBottom: "20px" }}
            >
              <Paper
                elevation={3}
                style={{
                  padding: "10px",
                  marginBottom: "30px",
                  backgroundColor: "darkgray",
                }}
              >
                <Typography
                  variant="h5"
                  gutterBottom
                  style={{
                    marginBottom: "15px",
                    color: "darkblue",
                    textAlign: "center",
                  }}
                >
                  Client Details
                </Typography>
              </Paper>
              <Grid container spacing={2}>
                <Grid item xs={2} md={4}>
                  <FormControl variant="standard" fullWidth margin="dense">
                    <InputLabel shrink htmlFor="clientType">
                      Client Type
                    </InputLabel>
                    <Select
                      label="Client Type"
                      name="clientType"
                      value={clientDetails.clientType}
                      onChange={handleClientDetailsChange}
                      fullWidth
                      margin="dense"
                      variant="standard"
                      displayEmpty
                      required
                    >
                      {clientTypes.map((type, index) => (
                        <MenuItem key={index} value={type}>
                          {type}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={2} md={4}>
                  <TextField
                    label="Client Code"
                    name="clientCode"
                    value={clientDetails.clientCode}
                    onChange={handleClientDetailsChange}
                    fullWidth
                    margin="dense"
                    variant="standard"
                    required
                    error
                  />
                </Grid>
                <Grid item xs={2} md={4}>
                  <TextField
                    label="Client Name"
                    name="clientName"
                    value={clientDetails.clientName}
                    onChange={handleClientDetailsChange}
                    fullWidth
                    margin="dense"
                    variant="standard"
                    required
                  />
                </Grid>
                <Grid item xs={2} md={4}>
                  <TextField
                    label="Client Address"
                    name="clientAddress"
                    value={clientDetails.clientAddress}
                    onChange={handleClientDetailsChange}
                    fullWidth
                    margin="dense"
                    variant="standard"
                    required
                  />
                </Grid>
                <Grid item xs={2} md={4}>
                  <TextField
                    label="Client Registration Number"
                    name="clientRegNumber"
                    value={clientDetails.clientRegNumber}
                    onChange={handleClientDetailsChange}
                    fullWidth
                    margin="dense"
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={2} md={4}>
                  <TextField
                    label="Client Website"
                    name="clientWebsite"
                    value={clientDetails.clientWebsite}
                    onChange={handleClientDetailsChange}
                    fullWidth
                    margin="dense"
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={2} md={4}>
                  <TextField
                    label="Client Contact Number"
                    name="clientContactNumber"
                    value={clientDetails.clientContactNumber}
                    onChange={handleClientDetailsChange}
                    fullWidth
                    margin="dense"
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={2} md={4}>
                  <TextField
                    label="Billing Period"
                    name="billingPeriod"
                    value={clientDetails.billingPeriod}
                    onChange={handleClientDetailsChange}
                    fullWidth
                    margin="dense"
                    variant="standard"
                  />
                </Grid>
              </Grid>
            </Paper>
          </Paper>
          <Paper
            elevation={3}
            style={{ padding: "20px", marginBottom: "20px" }}
          >
            <Paper
              elevation={3}
              style={{ padding: "20px", marginBottom: "20px" }}
            >
              <Paper
                elevation={3}
                style={{
                  padding: "10px",
                  marginBottom: "30px",
                  backgroundColor: "darkgray",
                }}
              >
                <Typography
                  variant="h5"
                  gutterBottom
                  style={{
                    marginBottom: "15px",
                    color: "darkblue",
                    textAlign: "center",
                  }}
                >
                  Client GST Details
                </Typography>
              </Paper>
              <Grid container spacing={2}>
                <Grid item xs={2} md={4}>
                  <TextField
                    label="State"
                    name="state"
                    value={gstdetailsUI.state}
                    onChange={(e) => handleGstdetailsUIChange(e, "state")}
                    fullWidth
                    margin="dense"
                    variant="standard"
                    required
                    // error={!gstdetailsUI.state} // Set error prop based on whether the field is filled or not
                    // helperText={
                    //   !gstdetailsUI.state ? "This field is required" : ""
                    // } // Optional helper text to display error message
                  />
                </Grid>
                <Grid item xs={2} md={4}>
                  <TextField
                    label="State Code"
                    name="stateCode"
                    value={gstdetailsUI.stateCode}
                    onChange={(e) => handleGstdetailsUIChange(e, "stateCode")}
                    fullWidth
                    margin="dense"
                    variant="standard"
                    required
                  />
                </Grid>
                <Grid item xs={2} md={4}>
                  <TextField
                    label="Pan Number"
                    name="panNumber"
                    value={gstdetailsUI.panNumber}
                    onChange={(e) => handleGstdetailsUIChange(e, "panNumber")}
                    fullWidth
                    margin="dense"
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={2} md={4}>
                  <TextField
                    label="GSTIN"
                    name="gstin"
                    value={gstdetailsUI.gstin}
                    onChange={(e) => handleGstdetailsUIChange(e, "gstin")}
                    fullWidth
                    margin="dense"
                    variant="standard"
                    required
                  />
                </Grid>
                <Grid item xs={2} md={4}>
                  <TextField
                    label="HSNSAC"
                    name="hsnsac"
                    value={gstdetailsUI.hsnsac}
                    onChange={(e) => handleGstdetailsUIChange(e, "hsnsac")}
                    fullWidth
                    margin="dense"
                    variant="standard"
                    required
                  />
                </Grid>
              </Grid>
            </Paper>
          </Paper>
          <Paper
            elevation={3}
            style={{ padding: "20px", marginBottom: "20px" }}
          >
            <Paper
              elevation={3}
              style={{ padding: "20px", marginBottom: "20px" }}
            >
              <Paper
                elevation={3}
                style={{
                  padding: "10px",
                  marginBottom: "30px",
                  backgroundColor: "darkgray",
                }}
              >
                <Typography
                  variant="h5"
                  gutterBottom
                  style={{
                    marginBottom: "15px",
                    color: "darkblue",
                    textAlign: "center",
                  }}
                >
                  Contract Date
                </Typography>
              </Paper>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    label="Start Date"
                    name="startDate"
                    type="date"
                    value={contractdateUI.startDate}
                    onChange={handleContractdateUIChange}
                    fullWidth
                    margin="dense"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="End Date"
                    name="endDate"
                    type="date"
                    value={contractdateUI.endDate}
                    onChange={handleContractdateUIChange}
                    fullWidth
                    margin="dense"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="MSA"
                    name="msa"
                    value={contractdateUI.msa}
                    onChange={(e) => handleContractdateUIChange(e, "msa")}
                    fullWidth
                    margin="dense"
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="NDA"
                    name="nda"
                    value={contractdateUI.nda}
                    onChange={(e) => handleContractdateUIChange(e, "nda")}
                    fullWidth
                    margin="dense"
                    variant="standard"
                  />
                </Grid>
              </Grid>
            </Paper>
            <Paper
              elevation={3}
              style={{ padding: "20px", marginBottom: "20px" }}
            >
              <Paper
                elevation={3}
                style={{
                  padding: "10px",
                  marginBottom: "30px",
                  backgroundColor: "darkgray",
                }}
              >
                <Typography
                  variant="h5"
                  gutterBottom
                  style={{
                    marginBottom: "15px",
                    color: "darkblue",
                    textAlign: "center",
                  }}
                >
                  Contact Person
                </Typography>
              </Paper>

              <Grid container spacing={2}>
                <Grid item xs={2} md={4}>
                  <TextField
                    label="Contact Person Name"
                    name="contactPersonName"
                    value={newContactPerson.contactPersonName}
                    onChange={handleContactPersonUIChange}
                    fullWidth
                    margin="dense"
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={2} md={4}>
                  <TextField
                    label="Designation"
                    name="designation"
                    value={newContactPerson.designation}
                    onChange={handleContactPersonUIChange}
                    fullWidth
                    margin="dense"
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={2} md={4}>
                  <TextField
                    label="Email ID"
                    name="emailID"
                    value={newContactPerson.emailID}
                    onChange={handleContactPersonUIChange}
                    fullWidth
                    margin="dense"
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={2} md={4}>
                  <TextField
                    label="Contact Number"
                    name="contactNumber"
                    value={newContactPerson.contactNumber}
                    onChange={handleContactPersonUIChange}
                    fullWidth
                    margin="dense"
                    variant="standard"
                  />
                </Grid>
              </Grid>
            </Paper>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow
                    style={{
                      backgroundColor: "darkcyan",
                      fontWeight: "bold",
                      color: "darkblue",
                    }}
                  >
                    <TableCell>Contact Person Name</TableCell>
                    <TableCell>Designation</TableCell>
                    <TableCell>Email ID</TableCell>
                    <TableCell>Contact Number</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {contactpersonUI.map((entry, index) => (
                    <TableRow key={index}>
                      <TableCell>{entry.contactPersonName}</TableCell>
                      <TableCell>{entry.designation}</TableCell>
                      <TableCell>{entry.emailID}</TableCell>
                      <TableCell>{entry.contactNumber}</TableCell>
                      <TableCell>
                        <IconButton onClick={() => handleDeleteRow(index)}>
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddEntry}
              style={{ marginTop: "20px" }}
            >
              Add Contact Person
            </Button>
          </Paper>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            style={{ marginBottom: "20px", marginLeft: "30px" }}
          >
            Submit
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleCancel}
            style={{ marginBottom: "20px", marginLeft: "10px" }}
          >
            Cancel
          </Button>
        </Card>
      </form>
    </Container>
  );
};

export default RegisterClientVal;
