import React, { useState } from "react";
import {
  TextField,
  Button,
  Container,
  Paper,
  Grid,
  Card,
  CardHeader,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { Autocomplete } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { myAxiosCommonHost } from "../../Service/CorsHelperBaseUrl";
import { useEffect } from "react";

const GenerateVendor = () => {
  const navigate = useNavigate();
  const getCurrentFinancialYear = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const fiscalStartYear = currentYear - (currentDate.getMonth() >= 3 ? 0 : 1);
    const fiscalEndYear = fiscalStartYear + 1;
    return `${(fiscalStartYear % 100).toString().padStart(2, "0")}-${(
      fiscalEndYear % 100
    )
      .toString()
      .padStart(2, "0")}`;
  };
  const [vendorCreation, setVendorCreation] = useState({
    voucherInvoiceNo: "",
    selectCompany: "",
    financialYear: "",
    vendorNameAndCode: "",
    expFor: "",
    expType: "",
  });
  const [venoderExpenseDetailsUI, setVenoderExpenseDetailsUI] = useState({
    voucherInvoiceNo: "",
    invoiceExpenseDate: "",
    expMonth: "",
    discription: "",
    gstRate: "",
    invoiceValue: "",
    taxbleAmount: "",
    gstAmount: "",
  });
  const [financialYear, setFinancialYear] = useState([]);
  const [loadingOverlay, setLoadingOverlay] = useState(false);
  const [company, setCompany] = useState([]);
  const [vendorName, setVendorName] = useState([]);
  const [expenseFor, setExpenseFor] = useState([]);

  useEffect(() => {
    async function fetchCompany() {
      try {
        const response = await myAxiosCommonHost.get(
          "gst/profile/getCompanyCodeList"
        );
        const companies = response.data;
        setCompany(companies);
        setVendorCreation((prevState) => ({
          ...prevState,
          voucherInvoiceNo: "",
          selectCompany: "",
          financialYear: getCurrentFinancialYear(),
          vendorNameAndCode: "",
          expFor: "",
          expType: "",
        }));
        setVenoderExpenseDetailsUI((prevState) => ({
          ...prevState,
          voucherInvoiceNo: "",
          invoiceExpenseDate: "",
          expMonth: "",
          description: "",
          gstRate: "",
          invoiceValue: "",
          taxbleAmount: "",
          gstAmount: "",
        }));
      } catch (error) {
        console.error("Error fetching company data:", error);
      }
    }
    fetchCompany();
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoadingOverlay(true);
    try {
      const postData = {
        ...vendorCreation,
        venoderExpenseDetailsUI,
      };

      await myAxiosCommonHost.post("gst/expense/createVendor", postData);
      console.log(
        "Posted data in JSON format:",
        JSON.stringify(postData, null, 2)
      );
      alert("Vendor added successfully!");
      setVendorCreation({});
      setVenoderExpenseDetailsUI({});
      navigate("/updateVendor");

      console.log(
        "Vendor added successfully, fields reset, and navigated to VendorList!"
      );
    } catch (error) {
      console.error("Error posting data:", error);
      alert("Error adding Vendor.");
    } finally {
      setLoadingOverlay(false);
    }
  };
  useEffect(() => {
    const fetchFinancialYear = async () => {
      try {
        const response = await myAxiosCommonHost.get(
          "gst/employeePo/getFiscalYear"
        );
        setFinancialYear(response.data);
      } catch (error) {
        console.error("Error fetching financialYear:", error);
      }
    };

    fetchFinancialYear();
  }, []);
  useEffect(() => {
    const fetchVendor = async () => {
      try {
        const response = await myAxiosCommonHost.get(
          `gst/expense/getVendorNameAndCodeList?selectCompany=${vendorCreation.selectCompany}`
        );
        setVendorName(response.data);
      } catch (error) {
        console.error("Error fetching financialYear:", error);
      }
    };
    if (vendorCreation.selectCompany) {
      fetchVendor();
      setVendorCreation({
        voucherInvoiceNo: "",
        selectCompany: vendorCreation.selectCompany,
        financialYear: getCurrentFinancialYear(),
        vendorNameAndCode: "",
        expFor: "",
        expType: "",
      });
      setVenoderExpenseDetailsUI({
        voucherInvoiceNo: "",
        invoiceExpenseDate: "",
        expMonth: "",
        description: "",
        gstRate: "",
        invoiceValue: "",
        taxbleAmount: "",
        gstAmount: "",
      });
    }
  }, [vendorCreation.selectCompany]);

  useEffect(() => {
    const fetchExpense = async () => {
      try {
        const response = await myAxiosCommonHost.get(
          `gst/expense/getExpenseHeaderForList?selectCompany=${vendorCreation.selectCompany}`
        );
        setExpenseFor(response.data);
      } catch (error) {
        console.error("Error fetching financialYear:", error);
      }
    };
    if (vendorCreation.selectCompany) {
      fetchExpense();

      setVendorCreation({
        voucherInvoiceNo: "",
        selectCompany: vendorCreation.selectCompany,
        financialYear: getCurrentFinancialYear(),
        vendorNameAndCode: "",
        expFor: "",
        expType: "",
      });

      setVenoderExpenseDetailsUI({
        voucherInvoiceNo: "",
        invoiceExpenseDate: "",
        expMonth: "",
        description: "",
        gstRate: "",
        invoiceValue: "",
        taxbleAmount: "",
        gstAmount: "",
      });
    }
  }, [vendorCreation.selectCompany]);

  //   const handleVendorCreationDetailsChange = (e) => {
  //     const { name, value } = e.target;
  //     setVendorCreation((prevDetails) => ({
  //       ...prevDetails,
  //       [name]: value,
  //     }));
  //   };
  const handleVendorCreationDetailsChange = (e) => {
    const { name, value } = e.target;

    setVendorCreation((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));

    if (name === "voucherInvoiceNo") {
      setVenoderExpenseDetailsUI((prevDetails) => ({
        ...prevDetails,
        voucherInvoiceNo: value,
      }));
    }
  };

  const handleAutocompleteChange = (event, newValue, fieldName) => {
    setVendorCreation((prevDetails) => ({
      ...prevDetails,
      [fieldName]: newValue,
    }));
  };

  const handleVenoderExpenseDetailsChange = (e) => {
    const { name, value } = e.target;
    setVenoderExpenseDetailsUI((prevVenoderExpenseDetails) => ({
      ...prevVenoderExpenseDetails,
      [name]: value,
    }));
  };

  const handleCancel = () => {
    const userConfirmed = window.confirm(
      "Are you sure you want to cancel? Any unsaved changes will be lost."
    );
    if (userConfirmed) {
      navigate("/updateVendor");
    }
  };

  return (
    <div>
      <Container maxWidth="lg">
        <form onSubmit={handleSubmit}>
          <Card
            elevation={3}
            style={{ marginBottom: "20px", marginTop: "20px" }}
          >
            <CardHeader
              title="Expense Creation"
              style={{
                backgroundColor: "#2196f3",
                color: "white",
                padding: "10px",
                textAlign: "center",
              }}
            />
            <Paper
              elevation={3}
              style={{ padding: "20px", marginBottom: "20px" }}
            >
              <Paper
                elevation={3}
                style={{ padding: "20px", marginBottom: "20px" }}
              >
                <Paper
                  elevation={3}
                  style={{
                    padding: "10px",
                    marginBottom: "30px",
                    backgroundColor: "darkgray",
                  }}
                >
                  <Typography
                    variant="h5"
                    gutterBottom
                    style={{
                      marginBottom: "15px",
                      color: "darkblue",
                      textAlign: "center",
                    }}
                  >
                    Expense Creation Details
                  </Typography>
                </Paper>
                {loadingOverlay && (
                  <div className="loading-overlay">
                    <CircularProgress size={60} />
                    <div className="loading-message">Expense is Saving...</div>
                  </div>
                )}

                <Grid container spacing={2}>
                  <Grid item xs={2} md={4}>
                    <Autocomplete
                      id="company"
                      options={company}
                      value={vendorCreation.selectCompany}
                      onChange={(e, newValue) =>
                        handleAutocompleteChange(e, newValue, "selectCompany")
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Company"
                          variant="standard"
                          margin="dense"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={2} md={4}>
                    <Autocomplete
                      options={financialYear}
                      getOptionLabel={(option) => option}
                      value={vendorCreation.financialYear}
                      onChange={(e, newValue) =>
                        handleAutocompleteChange(e, newValue, "financialYear")
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Financial Year"
                          margin="dense"
                          variant="standard"
                          required
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="Voucher Invoice No"
                      name="voucherInvoiceNo"
                      value={vendorCreation.voucherInvoiceNo}
                      onChange={handleVendorCreationDetailsChange}
                      fullWidth
                      margin="dense"
                      variant="standard"
                      required
                    />
                  </Grid>
                  <Grid item xs={2} md={4}>
                    <Autocomplete
                      id="vendorName"
                      options={vendorName}
                      value={vendorCreation.vendorNameAndCode}
                      onChange={(e, newValue) =>
                        handleAutocompleteChange(
                          e,
                          newValue,
                          "vendorNameAndCode"
                        )
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Vendor Name"
                          variant="standard"
                          margin="dense"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={2} md={4}>
                    <Autocomplete
                      id="expenseFor"
                      options={expenseFor}
                      value={vendorCreation.expFor}
                      onChange={(e, newValue) =>
                        handleAutocompleteChange(e, newValue, "expFor")
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Expense For"
                          variant="standard"
                          margin="dense"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  {/* <Grid item xs={2} md={4}>
                    <TextField
                      label="Vendor Name"
                      name="vendorNameAndCode"
                      value={vendorCreation.vendorNameAndCode}
                      onChange={handleVendorCreationDetailsChange}
                      fullWidth
                      margin="dense"
                      variant="standard"
                      required
                    />
                  </Grid> */}

                  {/* <Grid item xs={2} md={4}>
                    <TextField
                      label="Expense For"
                      name="expFor"
                      value={vendorCreation.expFor}
                      onChange={handleVendorCreationDetailsChange}
                      fullWidth
                      margin="dense"
                      variant="standard"
                    />
                  </Grid> */}
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="Expense Type"
                      name="expType"
                      value={vendorCreation.expType}
                      onChange={handleVendorCreationDetailsChange}
                      fullWidth
                      margin="dense"
                      variant="standard"
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Paper>
            <Paper
              elevation={3}
              style={{ padding: "20px", marginBottom: "20px" }}
            >
              <Paper
                elevation={3}
                style={{ padding: "20px", marginBottom: "20px" }}
              >
                <Paper
                  elevation={3}
                  style={{
                    padding: "10px",
                    marginBottom: "30px",
                    backgroundColor: "darkgray",
                  }}
                >
                  <Typography
                    variant="h5"
                    gutterBottom
                    style={{
                      marginBottom: "15px",
                      color: "darkblue",
                      textAlign: "center",
                    }}
                  >
                    Venoder Expense Details
                  </Typography>
                </Paper>
                <Grid container spacing={2}>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="Expense Date"
                      name="invoiceExpenseDate"
                      type="date"
                      value={venoderExpenseDetailsUI.invoiceExpenseDate}
                      onChange={handleVenoderExpenseDetailsChange}
                      fullWidth
                      margin="dense"
                      variant="standard"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="Expense Month"
                      name="expMonth"
                      value={venoderExpenseDetailsUI.expMonth}
                      onChange={handleVenoderExpenseDetailsChange}
                      fullWidth
                      margin="dense"
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="Discription"
                      name="discription"
                      value={venoderExpenseDetailsUI.discription}
                      onChange={handleVenoderExpenseDetailsChange}
                      fullWidth
                      margin="dense"
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="GST Rate"
                      name="gstRate"
                      value={venoderExpenseDetailsUI.gstRate}
                      onChange={handleVenoderExpenseDetailsChange}
                      fullWidth
                      margin="dense"
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="Invoice Value"
                      name="invoiceValue"
                      value={venoderExpenseDetailsUI.invoiceValue}
                      onChange={handleVenoderExpenseDetailsChange}
                      fullWidth
                      margin="dense"
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="Taxble Amount"
                      name="taxbleAmount"
                      value={venoderExpenseDetailsUI.taxbleAmount}
                      onChange={handleVenoderExpenseDetailsChange}
                      fullWidth
                      margin="dense"
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="GST Amount"
                      name="gstAmount"
                      value={venoderExpenseDetailsUI.gstAmount}
                      onChange={handleVenoderExpenseDetailsChange}
                      fullWidth
                      margin="dense"
                      variant="standard"
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Paper>
            <Paper
              style={{
                display: "flex",
                justifyContent: "center",
                padding: "10px",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                type="submit"
                style={{ marginBottom: "20px", marginLeft: "30px" }}
              >
                Submit
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleCancel}
                style={{
                  marginBottom: "20px",
                  marginLeft: "10px",
                  backgroundColor: "red",
                  color: "white",
                }}
              >
                Cancel
              </Button>
            </Paper>
          </Card>
        </form>
      </Container>
    </div>
  );
};

export default GenerateVendor;
