import Headers from "../../../Pages/Header";
import UpdateEmpPO from "../UpdateEmpPO";

const UpdateEmpPOPage = () => {
  return (
    <Headers>
      <UpdateEmpPO />
    </Headers>
  );
};
export default UpdateEmpPOPage;
