import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Container,
  Select,
  MenuItem,
  Paper,
  Grid,
  Card,
  CardHeader,
  TableRow,
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableContainer,
  FormControl,
  InputLabel,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { Autocomplete } from "@mui/material";

import axios from "axios";
import DeleteIcon from "@material-ui/icons/Delete";
import { IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { myAxiosCommonHost } from "../../Service/CorsHelperBaseUrl";
import "./Employee.css";

const RegisterEmployee = () => {
  const navigate = useNavigate();
  const [employeeDetails, setEmployeeDetails] = useState({
    selectCompany: "",
    employeeCode: "",
    employeeName: "",
    joiningDate: "",
    gsquareExperience: "",
    joiningExperience: "",
    totalExperience: "",
    gender: "",
    birthDate: "",
    localAddress: "",
    permantAddress: "",
    contactNo: "",
    emailId: "",
    panNo: "",
    addharNo: "",
    bloodGroup: "",
    seperationDate: "",
    positionChange: "",
  });
  const [salaryUI, setSalaryUI] = useState({
    ctc: "",
    monthly: "",
  });
  const [educationUIList, setEducationUIList] = useState([]);
  const [newEducation, setNewEducation] = useState({
    qualification: "",
    branch: "",
    university: "",
    yearOfPassing: "",
  });
  const [loadingOverlay, setLoadingOverlay] = useState(false);
  const [company, setCompany] = useState([]);
  const [formData, setFormData] = useState({ ...employeeDetails });

  useEffect(() => {
    async function fetchCompany() {
      try {
        const response = await myAxiosCommonHost.get(
          "gst/profile/getCompanyCodeList"
        );
        const companies = response.data;
        setCompany(companies);
        setFormData((prevState) => ({
          ...prevState,
        }));
      } catch (error) {
        console.error("Error fetching company data:", error);
      }
    }
    fetchCompany();
  }, []);

  const handleAddEducation = () => {
    const isDuplicate = educationUIList.some(
      (education) =>
        education.qualification.trim() === newEducation.qualification.trim()
    );
    const isDuplicateYear = educationUIList.some(
      (education) =>
        education.yearOfPassing.trim() === newEducation.yearOfPassing.trim()
    );
    if (isDuplicate) {
      alert("Duplicate entry! Cannot add the same Qualification.");
      return;
    }
    if (isDuplicateYear) {
      alert("Duplicate entry! Cannot add the same Year Of Passing.");
      return;
    }
    if (
      newEducation.qualification.trim() === "" ||
      newEducation.branch.trim() === "" ||
      // newEducation.university.trim() === "" ||
      newEducation.yearOfPassing.trim() === ""
    ) {
      alert("Cannot Add Null or Empty Data For Education");
      return;
    }
    // Add new education to the list
    setEducationUIList((prevEducationList) => [
      ...prevEducationList,
      newEducation,
    ]);
    // Reset the newEducation state
    setNewEducation({
      qualification: "",
      branch: "",
      university: "",
      yearOfPassing: "",
    });
  };

  useEffect(() => {
    async function fetchCompany() {
      try {
        const response = await myAxiosCommonHost.get(
          "gst/profile/getCompanyCodeList"
        );
        setCompany(response.data);
      } catch (error) {
        console.error("Error fetching company data:", error);
      }
    }
    fetchCompany();
  }, []);

  const handleEducationUIChange = (e) => {
    const { name, value } = e.target;
    setNewEducation((prevEducation) => ({
      ...prevEducation,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoadingOverlay(true);
    try {
      const postData = {
        ...employeeDetails,
        educationUIList,
        salaryUI,
      };
      console.log(JSON.stringify(postData, null, 2));
      await myAxiosCommonHost.post("gst/employee/createEmployee", postData);

      alert("Employee added successfully!");
      setEmployeeDetails({});
      setSalaryUI({});
      setEducationUIList([]);
      setNewEducation({});
      navigate("/updateEmployee");
    } catch (error) {
      console.error("Error posting data:", error);
      alert("Error adding employee.");
    } finally {
      setLoadingOverlay(false);
    }
  };

  const handleEmployeeDetailsChange = (e, name, newValue) => {
    const { name: targetName, value: targetValue } = e ? e.target : {};
    const currentDate = new Date();
    const selectedDate = new Date(targetValue || newValue);

    if (
      name === "birthDate" &&
      new Date().getFullYear() - selectedDate.getFullYear() < 21
    ) {
      alert("Employee must be at least 21 years old.");
      return;
    }
    // if (name === "joiningExperience" && targetValue) {
    //   const [years, months] = targetValue.split(".");
    //   if (months && parseInt(months) > 12) {
    //     alert("Months part of experience cannot exceed 12.");
    //     return;
    //   }
    // }

    // if (name === "joiningExperience" && targetValue) {
    //   const [years, months] = targetValue.split(".");
    //   if (months && parseInt(months) > 12) {
    //     alert("Months part of experience cannot exceed 12.");
    //     return;
    //   }
    //   if (!targetValue.includes(".")) {
    //     targetValue += ".0";
    //   }
    // }

    if (name === "joiningExperience" && targetValue) {
      let [years, months] = targetValue.split(".").map(Number);
      if (months >= 12) {
        years += Math.floor(months / 12);
        months = months % 12;
      }
      newValue = `${years}.${months}Y`;
    }

    setEmployeeDetails((prevDetails) => ({
      ...prevDetails,
      [name || targetName]: newValue || targetValue,
    }));
  };

  const handleSalaryUIChange = (e) => {
    const { name, value } = e.target;
    // Assuming the input is a valid number, you might want to add validation here
    const ctc = parseFloat(value);
    // Calculate monthly salary by dividing CTC by 12
    const monthlySalary = ctc / 12;
    setSalaryUI((prevSalaryUI) => ({
      ...prevSalaryUI,
      [name]: value,
      monthly: monthlySalary.toFixed(2), // Rounded to 2 decimal places
    }));
  };
  const handleDeleteRow = (index) => {
    const updatedEmployees = [...educationUIList];
    updatedEmployees.splice(index, 1);
    setEducationUIList(updatedEmployees);
  };
  const handleCancel = () => {
    const userConfirmed = window.confirm(
      "Are you sure you want to cancel? Any unsaved changes will be lost."
    );
    if (userConfirmed) {
      navigate("/updateEmployee");
    }
  };
  // const handleBlur = (event) => {
  //   if (event.target.name === "joiningExperience") {
  //     const { value } = event.target;
  //     if (!value.includes(".")) {
  //       setEmployeeDetails({
  //         ...employeeDetails,
  //         joiningExperience: value + ".0Y",
  //       });
  //     } else if (!value.endsWith("Y")) {
  //       setEmployeeDetails({
  //         ...employeeDetails,
  //         joiningExperience: value + "Y",
  //       });
  //     }
  //   }
  // };
  const handleBlur = (event) => {
    if (event.target.name === "joiningExperience") {
      let { value } = event.target;
      let [years, months] = value.split(".").map(Number);
      if (!months) {
        months = 0;
      }
      if (months >= 12) {
        years += Math.floor(months / 12);
        months = months % 12;
      }
      if (!value.includes(".")) {
        value += ".0";
      }
      setEmployeeDetails((prevDetails) => ({
        ...prevDetails,
        joiningExperience: `${years}.${months}Y`,
      }));
    }
  };

  return (
    <div>
      <Container maxWidth="lg">
        <form onSubmit={handleSubmit}>
          <Card
            elevation={3}
            style={{ marginBottom: "20px", marginTop: "20px" }}
          >
            <CardHeader
              title="Employee Registation"
              style={{
                backgroundColor: "#2196f3",
                color: "white",
                padding: "10px",
                textAlign: "center",
              }}
            />
            <Paper
              elevation={3}
              style={{ padding: "20px", marginBottom: "20px" }}
            >
              <Paper
                elevation={3}
                style={{ padding: "20px", marginBottom: "20px" }}
              >
                <Paper
                  elevation={3}
                  style={{
                    padding: "10px",
                    marginBottom: "30px",
                    backgroundColor: "darkgray",
                  }}
                >
                  <Typography
                    variant="h5"
                    gutterBottom
                    style={{
                      marginBottom: "15px",
                      color: "darkblue",
                      textAlign: "center",
                    }}
                  >
                    Employee Details
                  </Typography>
                </Paper>
                {loadingOverlay && (
                  <div className="loading-overlay">
                    <CircularProgress size={60} />
                    <div className="loading-message">Employee is Saving...</div>
                  </div>
                )}

                <Grid container spacing={2}>
                  <Grid item xs={2} md={4}>
                    <Autocomplete
                      id="company"
                      options={company}
                      value={employeeDetails.selectCompany}
                      onChange={(e, newValue) =>
                        handleEmployeeDetailsChange(
                          null,
                          "selectCompany",
                          newValue
                        )
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Company"
                          variant="standard"
                          margin="dense"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="Employee Code"
                      name="employeeCode"
                      value={employeeDetails.employeeCode}
                      onChange={handleEmployeeDetailsChange}
                      fullWidth
                      margin="dense"
                      variant="standard"
                      required // Add the 'required' attribute
                    />
                  </Grid>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="Employee Name"
                      name="employeeName"
                      value={employeeDetails.employeeName}
                      onChange={handleEmployeeDetailsChange}
                      fullWidth
                      margin="dense"
                      variant="standard"
                      required // Add the 'required' attribute
                    />
                  </Grid>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="Joining Date"
                      name="joiningDate"
                      type="date" // Set type to 'date'
                      value={employeeDetails.joiningDate}
                      onChange={handleEmployeeDetailsChange}
                      fullWidth
                      margin="dense"
                      variant="standard"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      required // Add the 'required' attribute
                    />
                  </Grid>

                  <Grid item xs={2} md={4}>
                    {/* <TextField
                      label="Joining Experience"
                      name="joiningExperience"
                      value={employeeDetails.joiningExperience}
                      onChange={handleEmployeeDetailsChange}
                      fullWidth
                      margin="dense"
                      variant="standard"
                    /> */}
                    <TextField
                      label="Joining Experience (Year)"
                      name="joiningExperience"
                      value={employeeDetails.joiningExperience}
                      onChange={handleEmployeeDetailsChange}
                      onBlur={handleBlur}
                      fullWidth
                      margin="dense"
                      variant="standard"
                      required
                    />
                  </Grid>

                  <Grid item xs={2} md={4}>
                    <Autocomplete
                      options={["Male", "Female", "Other"]}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Gender"
                          name="gender"
                          value={employeeDetails.gender}
                          onChange={handleEmployeeDetailsChange}
                          fullWidth
                          displayEmpty
                          margin="dense"
                          variant="standard"
                          required
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={2} md={6}>
                    <TextField
                      label="Permanent Address"
                      name="permantAddress"
                      multiline
                      rows={4}
                      value={employeeDetails.permantAddress}
                      onChange={handleEmployeeDetailsChange}
                      fullWidth
                      margin="dense"
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={2} md={6}>
                    <TextField
                      label="Local Address"
                      name="localAddress"
                      multiline
                      rows={4}
                      value={employeeDetails.localAddress}
                      onChange={handleEmployeeDetailsChange}
                      fullWidth
                      margin="dense"
                      variant="standard"
                      required
                    />
                  </Grid>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="Birth Date"
                      name="birthDate"
                      type="date" // Set type to 'date'
                      value={employeeDetails.birthDate}
                      onChange={handleEmployeeDetailsChange}
                      fullWidth
                      margin="dense"
                      variant="standard"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      required
                    />
                  </Grid>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="Contact Number"
                      name="contactNo"
                      type="number"
                      value={employeeDetails.contactNo}
                      onChange={handleEmployeeDetailsChange}
                      fullWidth
                      margin="dense"
                      variant="standard"
                      required
                    />
                  </Grid>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="Email ID"
                      name="emailId"
                      value={employeeDetails.emailId}
                      onChange={handleEmployeeDetailsChange}
                      fullWidth
                      margin="dense"
                      variant="standard"
                      type="email"
                      required
                    />
                  </Grid>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="PAN Number"
                      name="panNo"
                      value={employeeDetails.panNo}
                      onChange={handleEmployeeDetailsChange}
                      fullWidth
                      margin="dense"
                      variant="standard"
                      required
                    />
                  </Grid>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="Aadhar Number"
                      name="addharNo"
                      value={employeeDetails.addharNo}
                      onChange={handleEmployeeDetailsChange}
                      fullWidth
                      type="number"
                      margin="dense"
                      variant="standard"
                    />
                  </Grid>
                  {/* <Grid item xs={2} md={4}>
                    <FormControl variant="standard" fullWidth margin="dense">
                      <InputLabel shrink htmlFor="bloodGroup">
                        Blood Group
                      </InputLabel>
                      <Select
                        label="Blood Group"
                        name="bloodGroup"
                        value={employeeDetails.bloodGroup}
                        onChange={handleEmployeeDetailsChange}
                        fullWidth
                        margin="dense"
                        variant="standard"
                        displayEmpty
                      >
                        <MenuItem value="" disabled>
                          Blood Group
                        </MenuItem>
                        <MenuItem value="A+">A+</MenuItem>
                        <MenuItem value="A-">A-</MenuItem>
                        <MenuItem value="B+">B+</MenuItem>
                        <MenuItem value="B-">B-</MenuItem>
                        <MenuItem value="O+">O+</MenuItem>
                        <MenuItem value="O-">O-</MenuItem>
                        <MenuItem value="AB+">AB+</MenuItem>
                        <MenuItem value="AB-">AB-</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid> */}
                  <Grid item xs={2} md={4}>
                    <Autocomplete
                      options={[
                        "A+",
                        "A-",
                        "B+",
                        "B-",
                        "O+",
                        "O-",
                        "AB+",
                        "AB-",
                      ]}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Blood Group"
                          name="bloodGroup"
                          value={employeeDetails.bloodGroup}
                          onChange={handleEmployeeDetailsChange}
                          fullWidth
                          margin="dense"
                          variant="standard"
                          required
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={2} md={4}>
                    <TextField
                      label="Separation Date"
                      name="seperationDate"
                      type="date" // Set type to 'date'
                      value={employeeDetails.seperationDate}
                      onChange={handleEmployeeDetailsChange}
                      fullWidth
                      margin="dense"
                      variant="standard"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="Position Change"
                      name="positionChange"
                      value={employeeDetails.positionChange}
                      onChange={handleEmployeeDetailsChange}
                      fullWidth
                      margin="dense"
                      variant="standard"
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Paper>

            <Paper
              elevation={3}
              style={{ padding: "20px", marginBottom: "20px" }}
            >
              <Paper
                elevation={3}
                style={{ padding: "20px", marginBottom: "20px" }}
              >
                <Paper
                  elevation={3}
                  style={{
                    padding: "10px",
                    marginBottom: "30px",
                    backgroundColor: "darkgray",
                  }}
                >
                  <Typography
                    variant="h5"
                    gutterBottom
                    style={{
                      marginBottom: "15px",
                      color: "darkblue",
                      textAlign: "center",
                    }}
                  >
                    Employee Education Details
                  </Typography>
                </Paper>

                <Grid container spacing={2}>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="Qualification"
                      name="qualification"
                      value={newEducation.qualification}
                      onChange={handleEducationUIChange}
                      fullWidth
                      margin="dense"
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="Branch"
                      name="branch"
                      value={newEducation.branch}
                      onChange={handleEducationUIChange}
                      fullWidth
                      margin="dense"
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="University"
                      name="university"
                      value={newEducation.university}
                      onChange={handleEducationUIChange}
                      fullWidth
                      margin="dense"
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="Year Of Passing"
                      name="yearOfPassing"
                      value={newEducation.yearOfPassing}
                      onChange={handleEducationUIChange}
                      fullWidth
                      margin="dense"
                      type="number"
                      variant="standard"
                    />
                  </Grid>
                </Grid>
                <TableContainer component={Paper} style={{ marginTop: "20px" }}>
                  <Table>
                    <TableHead>
                      <TableRow
                        style={{
                          backgroundColor: "darkcyan",
                          fontWeight: "bold",
                          color: "darkblue",
                        }}
                      >
                        <TableCell>Qualification</TableCell>
                        <TableCell>Branch</TableCell>
                        <TableCell>University</TableCell>
                        <TableCell>Year Of Passing</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {educationUIList.map((education, index) => (
                        <TableRow key={index}>
                          <TableCell>{education.qualification}</TableCell>
                          <TableCell>{education.branch}</TableCell>
                          <TableCell>{education.university}</TableCell>
                          <TableCell>{education.yearOfPassing}</TableCell>
                          <TableCell>
                            <IconButton onClick={() => handleDeleteRow(index)}>
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleAddEducation}
                  style={{ marginTop: "10px" }}
                >
                  Add Education
                </Button>
              </Paper>
            </Paper>

            <Paper
              elevation={3}
              style={{ padding: "20px", marginBottom: "20px" }}
            >
              <Paper
                elevation={3}
                style={{ padding: "20px", marginBottom: "20px" }}
              >
                <Paper
                  elevation={3}
                  style={{
                    padding: "10px",
                    marginBottom: "30px",
                    backgroundColor: "darkgray",
                  }}
                >
                  <Typography
                    variant="h5"
                    gutterBottom
                    style={{
                      marginBottom: "15px",
                      color: "darkblue",
                      textAlign: "center",
                    }}
                  >
                    Employee Salary Details
                  </Typography>
                </Paper>
                <Grid container spacing={2}>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="CTC"
                      name="ctc"
                      value={salaryUI.ctc}
                      onChange={handleSalaryUIChange}
                      fullWidth
                      margin="dense"
                      variant="standard"
                      type="number"
                      required
                    />
                  </Grid>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="Monthly"
                      name="monthly"
                      value={salaryUI.monthly}
                      onChange={handleSalaryUIChange}
                      fullWidth
                      margin="dense"
                      variant="standard"
                      type="number"
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Paper>
            <Paper
              style={{
                display: "flex",
                justifyContent: "center",
                padding: "10px",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                type="submit"
                style={{ marginBottom: "20px", marginLeft: "30px" }}
              >
                Submit
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleCancel}
                style={{
                  marginBottom: "20px",
                  marginLeft: "10px",
                  backgroundColor: "red",
                  color: "white",
                }}
              >
                Cancel
              </Button>
            </Paper>
          </Card>
        </form>
      </Container>
    </div>
  );
};

export default RegisterEmployee;
