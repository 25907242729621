import Headers from "../../Pages/Header";
import OtherChargesInvoiceListVal from "../ValidationOtherChargesList";
const OtherChargesInvoiceListValPage = () => {
  return (
    <Headers>
      <OtherChargesInvoiceListVal />
    </Headers>
  );
};
export default OtherChargesInvoiceListValPage;
