import Headers from "../../../Pages/Header";
import UpdateBank from "../UpdateBank";

const UpdateBankPage = () => {
  return (
    <Headers>
      <UpdateBank />
    </Headers>
  );
};
export default UpdateBankPage;