import Headers from "../../../Pages/Header";
import UpdateVendorInformation from "../UpdateVendorInformation";

const UpdateVendorInformationPage = () => {
  return (
    <Headers>
      <UpdateVendorInformation />
    </Headers>
  );
};
export default UpdateVendorInformationPage;
