// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import PizZip from "pizzip";
// import Docxtemplater from "docxtemplater";
// import { saveAs } from "file-saver";
// import JSZipUtils from "jszip-utils";

// const QuotationWord = () => {
//   const [data, setData] = useState(null);

//   useEffect(() => {
//     axios
//       .get("gst/quotation/getQuotationList")
//       .then((response) => {
//         setData(response.data[0]);
//       })
//       .catch((error) => {
//         console.error("Error fetching data:", error);
//       });
//   }, []);

//   const generateDocument = () => {
//     JSZipUtils.getBinaryContent("template.docx", (error, content) => {
//       if (error) {
//         throw error;
//       }
//       const zip = new PizZip(content);
//       const doc = new Docxtemplater(zip, {
//         paragraphLoop: true,
//         linebreaks: true,
//       });
//       doc.setData({
//         quote_no: ${data.quotationNo},
//         date: new Date(data.quotationDate).toLocaleDateString(),
//         client_name: data.clientNameAndCode,
//         client_address:
//           "Mahindra Research Valley Mahindra World City Plot No 41/1 ANJUR POST, Chengalpattu Kanchipuram Dist. Tamil Nadu- 603004",
//         place_of_supply: "TAMIL NADU",
//         state_code: "33",
//         kind_attention: data.kindAttention,
//         quotation_subject: "Quotation Regarding CAD Engineers",
//         accomodationUIList: data.accomodationUIList.map((item, index) => ({
//           index: index + 1,
//           resourceId: item.description,
//           name: item.costPerResources,
//           sac: item.noOfResources,
//           rate: item.noOfMonth,
//           months: item.totalCost,
//           cost: item.totalCost,
//         })),
//         total_amount: `${data.totalAmount} (${data.totalInWord})`,
//       });

//       // Render the document with the data
//       try {
//         doc.render();
//       } catch (error) {
//         const e = {
//           message: error.message,
//           name: error.name,
//           stack: error.stack,
//           properties: error.properties,
//         };
//         console.log(JSON.stringify({ error: e }));
//         throw error;
//       }

//       // Generate the document and trigger the download
//       const out = doc.getZip().generate({
//         type: "blob",
//         mimeType:
//           "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
//       });

//       saveAs(out, "quotation.docx");
//     });
//   };

//   return (
//     <div>
//       {data ? (
//         <button onClick={generateDocument}>Download Quotation</button>
//       ) : (
//         <p>Loading...</p>
//       )}
//     </div>
//   );
// };

// export default QuotationWord;
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import PizZip from 'pizzip';
import Docxtemplater from 'docxtemplater';
import { saveAs } from 'file-saver';
import JSZipUtils from 'jszip-utils';
import { myAxiosCommonHost } from '../../Service/CorsHelperBaseUrl';

const QuotationExcel = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    myAxiosCommonHost.get('/gst/quotation/getQuotationList')
      .then(response => {
        setData(response.data[0]);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);

  const generateDocument = () => {
    JSZipUtils.getBinaryContent('/template.docx', (error, content) => {
      if (error) {
        throw error;
      }

      const zip = new PizZip(content);
      const doc = new Docxtemplater(zip, {
        paragraphLoop: true,
        linebreaks: true,
      });
      doc.setData({
        quotationRefNo: data.quotationRefNo,
        quotationDate: new Date(data.quotationDate).toLocaleDateString(),
        clientUI: {
          clientName: data.clientUI.clientName,
          clientAddress: data.clientUI.clientAddress,
        },
        kindAttention: data.kindAttention,
        quotationSubject: data.quotationSubject,
        accomodationUIList: data.accomodationUIList.map((item, index) => ({
          index: index + 1,
          description: item.description,
          costPerResources: item.costPerResources,
          noOfResources: item.noOfResources,
          noOfMonth: item.noOfMonth,
          totalCost: item.totalCost,
        })),
        totalAmount: `${data.totalAmount} (${data.totalInWord})`,
        gstDetailsUI: {
          state: data.gstDetailsUI.state,
          stateCode: data.gstDetailsUI.stateCode,
        },
      });
      try {
        doc.render();
      } catch (error) {
        const e = {
          message: error.message,
          name: error.name,
          stack: error.stack,
          properties: error.properties,
        };
        console.log(JSON.stringify({ error: e }));
        throw error;
      }
      const out = doc.getZip().generate({
        type: 'blob',
        mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      });

      saveAs(out, 'quotation.docx');
    });
  };

  return (
    <div>
      {data ? (
        <button onClick={generateDocument}>Download Quotation</button>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default QuotationExcel;