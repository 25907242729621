import Headers from "../../../Pages/Header";
import GenerateQuotation from "../GenereateQuotation";

const GenerateQuotationPage = () => {
  return (
    <Headers>
      <GenerateQuotation />
    </Headers>
  );
};
export default GenerateQuotationPage;
