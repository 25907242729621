import React, { useState } from 'react';
import { Button, Container, Typography } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import axios from 'axios';
import { myAxiosCommonHost } from '../Service/CorsHelperBaseUrl';

const OutStandingExcel = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState('');

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleFileUpload = async () => {
    try {
      const formData = new FormData();
      formData.append('file', selectedFile);

      const response = await myAxiosCommonHost.post('gst/file/uploadExcel', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      console.log('File uploaded successfully:', response.data);
      setUploadStatus('File uploaded successfully');
    } catch (error) {
      console.error('Error uploading file:', error);
      setUploadStatus('Error uploading file');
    }
  };

  return (
    <Container maxWidth="sm">
      <input
        accept=".xls,.xlsx,.csv"
        style={{ display: 'none' }}
        id="file-upload"
        type="file"
        onChange={handleFileChange}
      />
      <label htmlFor="file-upload">
        <Button
          variant="contained"
          component="span"
          startIcon={<CloudUploadIcon />}
        >
          Upload Excel File
        </Button>
      </label>
      <Typography variant="body1">{selectedFile ? selectedFile.name : 'No file selected'}</Typography>
      <Button variant="contained" onClick={handleFileUpload}>Upload</Button>
      <Typography variant="body1">{uploadStatus}</Typography>
    </Container>
  );
};

export default OutStandingExcel;
