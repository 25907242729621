import React, { useContext, useState, useEffect } from "react";
import { CompanyContext } from "../StateProvider/CompanyProvider";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Collapse,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  TablePagination,
  Grid,
  Tab,
  Tabs,
  MenuItem,
  Select,
  Box,
  FormControl,
  InputLabel,
  CircularProgress,
  Backdrop,
} from "@material-ui/core";
import { Autocomplete } from "@mui/material";
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  Edit as EditIcon,
  Search,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import { myAxiosCommonHost } from "../../Service/CorsHelperBaseUrl";
import * as XLSX from "xlsx";

const Row = ({ row, onDelete, onUpdate }) => {
  const [open, setOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [editedEmployee, setEditedEmployee] = useState({ ...row });

  const [editedEducation, setEditedEducation] = useState(
    Array.isArray(row.educationUIList) ? [...row.educationUIList] : []
  );
  const [editedSalary, setEditedSalary] = useState({ ...row.salaryUI });
  const [tabValue, setTabValue] = useState(0);
  const [isDataChangedEdit, setIsDataChangedEdit] = useState(false);

  const handleEdit = () => {
    setDialogOpen(true);
  };

  const handleDelete = () => {
    onDelete(row.employeeCode);
  };

  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    setEditedEmployee((prevEmployee) => ({ ...prevEmployee, [name]: value }));
    setIsDataChangedEdit(true);
  };
  const handleEducationChange = (e, index) => {
    const { name, value } = e.target;
    const updatedEducation = [...editedEducation];
    updatedEducation[index] = {
      ...updatedEducation[index],
      [name]: value,
    };
    setEditedEducation(updatedEducation);
    setIsDataChangedEdit(true);
  };
  const handleAddEducation = () => {
    setEditedEducation([
      ...editedEducation,
      { qualification: "", branch: "", university: "", yearOfPassing: "" },
    ]);
  };
  const handleDeleteEducation = (index) => {
    const updatedEducation = [...editedEducation];
    updatedEducation.splice(index, 1);
    setEditedEducation(updatedEducation);
  };
  const handleSalaryChange = (e) => {
    const { name, value } = e.target;
    const ctc = parseFloat(value);
    const monthlySalary = ctc / 12;
    setEditedSalary((prevSalary) => ({
      ...prevSalary,
      [name]: value,
      monthly: monthlySalary.toFixed(2),
    }));
    setIsDataChangedEdit(true);
  };
  const handleSave = () => {
    // Combine edited data for employee, education, and salary
    const updatedEmployee = {
      ...editedEmployee,
      educationUIList: editedEducation,
      salaryUI: editedSalary,
    };
    onUpdate(updatedEmployee);
    setDialogOpen(false);
  };
  if (!editedEmployee) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell>{row.employeeCode}</TableCell>
        <TableCell>{row.employeeName}</TableCell>
        <TableCell>{row.positionChange}</TableCell>
        <TableCell>
          <Button onClick={handleEdit} color="primary" variant="contained">
            Update
          </Button>
          <Button
            onClick={handleDelete}
            color="error"
            variant="contained"
            style={{
              marginLeft: "8px",
              backgroundColor: "red",
              color: "white",
            }}
          >
            Delete
          </Button>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <div>
              <Box style={{ padding: 20 }}>
                <Typography variant="h6">Basic Information</Typography>
                {row ? (
                  <div>
                    <TableRow
                      style={{
                        backgroundColor: "darkcyan",
                        fontWeight: "bold",
                        color: "darkblue",
                      }}
                    >
                      <TableCell>Select Company:</TableCell>
                      <TableCell>Employee Code:</TableCell>
                      <TableCell>Employee Name:</TableCell>
                      <TableCell>Blood Group:</TableCell>
                      <TableCell>Birth Date:</TableCell>
                      <TableCell>Joining Date:</TableCell>
                      <TableCell>Joining Experience:</TableCell>
                      <TableCell>Gsquare Experience:</TableCell>
                      <TableCell>Total Experience:</TableCell>
                      <TableCell>Gender:</TableCell>
                      <TableCell>Local Address</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{row.selectCompany}</TableCell>
                      <TableCell>{row.employeeCode}</TableCell>
                      <TableCell>{row.employeeName}</TableCell>
                      <TableCell>{row.bloodGroup}</TableCell>
                      <TableCell>
                        {row.birthDate
                          ? row.birthDate.split("-").reverse().join("-")
                          : "N/A"}
                      </TableCell>
                      <TableCell>
                        {row.joiningDate
                          ? row.joiningDate.split("-").reverse().join("-")
                          : "N/A"}
                      </TableCell>
                      <TableCell>{row.joiningExperience}</TableCell>
                      <TableCell>{row.gsquareExperience}</TableCell>
                      <TableCell>{row.totalExperience}</TableCell>
                      <TableCell>{row.gender}</TableCell>
                      <TableCell>{row.localAddress}</TableCell>
                    </TableRow>
                    <TableRow
                      style={{
                        backgroundColor: "darkcyan",
                        fontWeight: "bold",
                        color: "darkblue",
                      }}
                    >
                      <TableCell>Permanent Address:</TableCell>
                      <TableCell>Contact Number:</TableCell>
                      <TableCell>Email Id:</TableCell>
                      <TableCell>Pan Number:</TableCell>
                      <TableCell>Aadhar Number:</TableCell>
                      <TableCell>Blood Group:</TableCell>
                      <TableCell>Separation Date:</TableCell>
                      <TableCell>Position Change:</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{row.permantAddress}</TableCell>
                      <TableCell>{row.contactNo}</TableCell>
                      <TableCell>{row.emailId}</TableCell>
                      <TableCell>{row.panNo}</TableCell>
                      <TableCell>{row.addharNo}</TableCell>
                      <TableCell>{row.bloodGroup}</TableCell>
                      <TableCell>
                        {row.separationDate
                          ? row.separationDate.split("-").reverse().join("-")
                          : "N/A"}
                      </TableCell>
                      <TableCell>{row.positionChange}</TableCell>
                    </TableRow>
                  </div>
                ) : (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      No Personal Details found
                    </TableCell>
                  </TableRow>
                )}

                <Typography variant="h6">Education</Typography>
                {row.educationUIList ? (
                  row.educationUIList.map((education, index) => (
                    <div key={index}>
                      <TableRow
                        style={{
                          backgroundColor: "darkcyan",
                          fontWeight: "bold",
                          color: "darkblue",
                        }}
                      >
                        {/* <TableCell>Qualification {index + 1}:</TableCell> */}
                        <TableCell>Qualification</TableCell>
                        <TableCell>Branch</TableCell>
                        <TableCell>University</TableCell>
                        <TableCell>Year of Passing </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          {education?.qualification || "N/A"}
                        </TableCell>
                        <TableCell>{education?.branch || "N/A"}</TableCell>
                        <TableCell> {education?.university || "N/A"}</TableCell>
                        <TableCell>
                          {education?.yearOfPassing || "N/A"}
                        </TableCell>
                      </TableRow>
                    </div>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      No Education Details Found
                    </TableCell>
                  </TableRow>
                )}

                <Typography variant="h6">Salary</Typography>
                {row.salaryUI ? (
                  <div>
                    <TableRow
                      style={{
                        backgroundColor: "darkcyan",
                        fontWeight: "bold",
                        color: "darkblue",
                      }}
                    >
                      <TableCell>CTC:</TableCell>
                      <TableCell>Monthly:</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{row.salaryUI.ctc}</TableCell>
                      <TableCell>{row.salaryUI.monthly}</TableCell>
                    </TableRow>
                  </div>
                ) : (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      No Salary Details found
                    </TableCell>
                  </TableRow>
                )}
              </Box>
            </div>
          </Collapse>
        </TableCell>
      </TableRow>

      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        maxWidth="md"
        maxHeight="md"
        fullWidth
      >
        <DialogTitle>Edit Employee Information</DialogTitle>
        <DialogContent>
          <Tabs
            value={tabValue}
            onChange={(event, newValue) => setTabValue(newValue)}
            variant="fullWidth"
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="Basic Information" />
            <Tab label="Education" />
            <Tab label="Salary" />
          </Tabs>
          {tabValue === 0 && (
            <>
              <Typography variant="h6">Basic Employee Information</Typography>
              <Paper
                elevation={3}
                style={{ padding: 10, marginBottom: 10, marginTop: 10 }}
              >
                <Grid container spacing={3} style={{ marginTop: 10 }}>
                  <Grid item xs={12} md={4}>
                    <TextField
                      label="Select Company"
                      name="selectCompany"
                      value={editedEmployee.selectCompany}
                      onChange={handleFieldChange}
                      variant="standard"
                      fullWidth
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      label="Employee Code"
                      name="employeeCode"
                      value={editedEmployee.employeeCode}
                      onChange={handleFieldChange}
                      variant="standard"
                      fullWidth
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      label="Employee Name"
                      name="employeeName"
                      value={editedEmployee.employeeName}
                      onChange={handleFieldChange}
                      variant="standard"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      label="Position Change"
                      name="positionChange"
                      value={editedEmployee.positionChange}
                      variant="standard"
                      onChange={handleFieldChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth margin="dense">
                      <InputLabel shrink htmlFor="bloodGroup">
                        Blood Group
                      </InputLabel>
                      <Select
                        label="Blood Group"
                        name="bloodGroup"
                        value={editedEmployee.bloodGroup}
                        onChange={handleFieldChange}
                        variant="standard"
                        fullWidth
                      >
                        <MenuItem value="A+">A+</MenuItem>
                        <MenuItem value="A-">A-</MenuItem>
                        <MenuItem value="B+">B+</MenuItem>
                        <MenuItem value="B-">B-</MenuItem>
                        <MenuItem value="O+">O+</MenuItem>
                        <MenuItem value="O-">O-</MenuItem>
                        <MenuItem value="AB+">AB+</MenuItem>
                        <MenuItem value="AB-">AB-</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      label="Joining Date"
                      name="joiningDate"
                      type="date"
                      value={editedEmployee.joiningDate}
                      onChange={handleFieldChange}
                      variant="standard"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      label="Joining Experience"
                      name="joiningExperience"
                      value={editedEmployee.joiningExperience}
                      onChange={handleFieldChange}
                      variant="standard"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      label="Gsquare Experience"
                      name="gsquareExperience"
                      value={editedEmployee.gsquareExperience}
                      onChange={handleFieldChange}
                      variant="standard"
                      fullWidth
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      label="Total Experience"
                      name="totalExperience"
                      value={editedEmployee.totalExperience}
                      onChange={handleFieldChange}
                      variant="standard"
                      fullWidth
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth margin="dense">
                      <InputLabel shrink htmlFor="gender">
                        Gender
                      </InputLabel>
                      <Select
                        label="Gender"
                        name="gender"
                        value={editedEmployee.gender}
                        onChange={handleFieldChange}
                        variant="standard"
                        fullWidth
                      >
                        <MenuItem value="male">Male</MenuItem>
                        <MenuItem value="female">Female</MenuItem>
                        <MenuItem value="other">Other</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      label="Birth Date"
                      name="birthDate"
                      type="date"
                      value={editedEmployee.birthDate}
                      onChange={handleFieldChange}
                      variant="standard"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      label="Local Address"
                      name="localAddress"
                      value={editedEmployee.localAddress}
                      onChange={handleFieldChange}
                      variant="standard"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      label="Permanent Address"
                      name="permantAddress"
                      value={editedEmployee.permantAddress}
                      onChange={handleFieldChange}
                      variant="standard"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      label="Contact Number"
                      name="contactNo"
                      value={editedEmployee.contactNo}
                      onChange={handleFieldChange}
                      variant="standard"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      label="Email ID"
                      name="emailId"
                      value={editedEmployee.emailId}
                      onChange={handleFieldChange}
                      variant="standard"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      label="PAN Number"
                      name="panNo"
                      value={editedEmployee.panNo}
                      onChange={handleFieldChange}
                      variant="standard"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      label="Aadhar Number"
                      name="addharNo"
                      value={editedEmployee.addharNo}
                      onChange={handleFieldChange}
                      variant="standard"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      label="Seperation Date"
                      name="seperationDate"
                      type="date"
                      value={editedEmployee?.seperationDate}
                      onChange={handleFieldChange}
                      variant="standard"
                      fullWidth
                      margin="dense"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </>
          )}

          {tabValue === 1 && (
            <>
              <Typography variant="h6">Education</Typography>
              {editedEducation.map((education, index) => (
                <Paper
                  key={index}
                  elevation={3}
                  style={{ padding: 10, marginBottom: 10, marginTop: 10 }}
                >
                  <Grid container spacing={6} style={{ marginTop: 10 }}>
                    <Grid item xl={4}>
                      <TextField
                        label={`Qualification `}
                        name={`qualification`}
                        value={education.qualification}
                        onChange={(e) => handleEducationChange(e, index)}
                        fullWidth
                        variant="standard"
                      />
                    </Grid>
                    <Grid item xl={4}>
                      <TextField
                        label={`Branch `}
                        name={`branch`}
                        value={education.branch}
                        onChange={(e) => handleEducationChange(e, index)}
                        fullWidth
                        variant="standard"
                      />
                    </Grid>
                    <Grid item xl={4}>
                      <TextField
                        label={`University `}
                        name={`university`}
                        value={education.university}
                        onChange={(e) => handleEducationChange(e, index)}
                        fullWidth
                        variant="standard"
                      />
                    </Grid>
                    <Grid item xl={4}>
                      <TextField
                        label={`Year of Passing `}
                        name={`yearOfPassing`}
                        value={education.yearOfPassing}
                        onChange={(e) => handleEducationChange(e, index)}
                        fullWidth
                        variant="standard"
                      />
                      <Paper
                        elevation={1}
                        style={{ padding: 5, marginBottom: 5, marginTop: 5 }}
                      >
                        <Button
                          variant="contained"
                          onClick={() => handleDeleteEducation(index)}
                          style={{ backgroundColor: "red", color: "white" }}
                        >
                          Delete
                        </Button>
                      </Paper>
                    </Grid>
                  </Grid>
                </Paper>
              ))}
              <Paper
                elevation={1}
                style={{ padding: 5, marginBottom: 5, marginTop: 5 }}
              >
                <Button
                  variant="contained"
                  onClick={handleAddEducation}
                  style={{ backgroundColor: "blue", color: "white" }}
                >
                  Add Education
                </Button>
              </Paper>
            </>
          )}
          {tabValue === 2 && (
            <>
              <Typography variant="h6">Salary</Typography>
              <Paper
                elevation={3}
                style={{ padding: 10, marginBottom: 10, marginTop: 10 }}
              >
                <Grid container spacing={6} style={{ marginTop: 10 }}>
                  <Grid item xl={4}>
                    <TextField
                      label="CTC"
                      name="ctc"
                      value={editedSalary.ctc}
                      onChange={handleSalaryChange}
                      fullWidth
                      variant="standard"
                      sx={{ marginBottom: 2, marginTop: 2 }}
                    />
                  </Grid>

                  <Grid item xl={4}>
                    <TextField
                      label="Monthly"
                      name="monthly"
                      value={editedSalary.monthly}
                      onChange={handleSalaryChange}
                      fullWidth
                      variant="standard"
                      sx={{ marginBottom: 2, marginTop: 2 }}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleSave} disabled={!isDataChangedEdit}>
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`}>
      {value === index && <div>{children}</div>}
    </div>
  );
}

const UpdateEmployee = () => {
  const [employeeData, setEmployeeData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false); // State to control deletion overlay
  const [isUpdating, setIsUpdating] = useState(false);
  const [page, setPage] = useState(0); // Add page state
  const [rowsPerPage, setRowsPerPage] = useState(10); // Add rowsPerPage state
  const [totalCount, setTotalCount] = useState(0);
  const [searchEmployeeName, setSearchEmployeeName] = useState("");
  const [searchEmployeeCode, setSearchEmployeeCode] = useState("");
  // const [selectedCompany, setSelectedCompany] = useState("");
  const { selectedCompany } = useContext(CompanyContext);
  const [company, setCompany] = useState([]);
  const [selectedOption, setSelectedOption] = useState("1");

  // useEffect(() => {
  //   async function fetchCompany() {
  //     try {
  //       const response = await myAxiosCommonHost.get(
  //         "gst/profile/getCompanyCodeList"
  //       );
  //       const companies = response.data;
  //       setCompany(companies);
  //       if (companies.length > 0) {
  //         setSelectedCompany(companies[0]); // Set the default company to the first one
  //       }
  //     } catch (error) {
  //       console.error("Error fetching company data:", error);
  //     }
  //   }
  //   fetchCompany();
  // }, []);

  const fetchEmployeeCount = () => {
    myAxiosCommonHost
      .get("gst/employee/getEmployeeCount")
      .then((response) => {
        const count = parseInt(response.data); // Extract count from API response
        setTotalCount(count); // Set total count state
      })
      .catch((error) => {
        console.error("Error fetching employee count:", error);
      });
  };

  useEffect(() => {
    if (selectedCompany) {
      fetchEmployeeData();
    }
  }, [selectedCompany]);

  const fetchEmployeeData = () => {
    if (selectedCompany) {
      const limit = rowsPerPage;
      const offset = page * rowsPerPage; // Correct offset calculation
      setLoading(true);
      myAxiosCommonHost
        .get(
          `gst/employee/getEmployeeList?selectCompany=${selectedCompany}&limit=${limit}&offset=${offset}`
        )
        .then((response) => {
          setEmployeeData(response.data);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching Employee data:", error);
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    fetchEmployeeCount();
    fetchEmployeeData();
  }, [rowsPerPage, page, selectedCompany]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page to 0 when rows per page changes
  };

  const handleUpdateEmployee = (updatedEmployee) => {
    setIsUpdating(true); // Show update overlay
    myAxiosCommonHost
      .put("gst/employee/updateEmployee", updatedEmployee)
      .then((response) => {
        console.log("Employee data updated successfully.");
        fetchEmployeeData();
        setIsUpdating(false); // Hide update overlay after update
      })
      .catch((error) => {
        console.error("Error updating employee data: ", error);
        setIsUpdating(false); // Hide update overlay on error
      });
  };
  const handleDeleteEmployee = (employeeCode) => {
    if (window.confirm("Are you sure you want to delete this employee?")) {
      setIsDeleting(true); // Show deletion overlay
      const encodedEmployeeCode = encodeURIComponent(employeeCode);
      myAxiosCommonHost
        .delete(
          `/gst/employee/deleteEmployee/employeeCode?employeeCode=${encodedEmployeeCode}`
        )
        .then((response) => {
          console.log("Employee deleted successfully.");
          fetchEmployeeData();
          setIsDeleting(false); // Hide deletion overlay after deletion
        })
        .catch((error) => {
          console.error("Error deleting employee: ", error);
          setIsDeleting(false); // Hide deletion overlay on error
        });
    }
  };

  const handleSearch = async () => {
    try {
      setLoading(true);
      const limit = rowsPerPage;
      const offset = page * rowsPerPage;
      const encodedEmployeeCode = encodeURIComponent(searchEmployeeCode);
      const response = await myAxiosCommonHost.get(
        `gst/employee/searchEmployees?selectCompany=${selectedCompany}&employeeName=${searchEmployeeName}&employeecode=${encodedEmployeeCode}&limit=${limit}&offset=${offset}`
      );
      setEmployeeData(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error searching employeePo:", error);
      setError(error);
      setLoading(false);
    }
  };

  // const handleCompanySelect = (company) => {
  //   setSelectedCompany(company);
  //   console.log("Selected Company:", company);
  // };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const downloadExcelFile = async () => {
    try {
      let apiUrl = `/gst/employee/getEmployeeListForExcel`;
      if (selectedOption === "1") {
        apiUrl += `?selectCompany=${selectedCompany}`;
      }

      const response = await myAxiosCommonHost.get(apiUrl);

      const data = response.data;

      if (!data) {
        console.error("Data is not available or empty.");
        return;
      }

      const workbook = XLSX.utils.book_new();

      const employeePersonalExcelList = data.employeePersonalExcelList || [];
      const employeeEducationExcelList = data.employeeEducationExcelList || [];

      if (employeePersonalExcelList.length > 0) {
        const worksheet1 = XLSX.utils.json_to_sheet(employeePersonalExcelList);
        XLSX.utils.book_append_sheet(workbook, worksheet1, "Employee Details");
      }

      if (employeeEducationExcelList.length > 0) {
        const worksheet2 = XLSX.utils.json_to_sheet(employeeEducationExcelList);
        XLSX.utils.book_append_sheet(
          workbook,
          worksheet2,
          "Employee Education Details"
        );
      }

      const excelBuffer = XLSX.write(workbook, {
        type: "array",
        bookType: "xlsx",
      });

      const blob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const url = URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.target = "_blank";
      anchor.download = `${selectedCompany}_Employee_Details.xlsx`;
      anchor.click();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <div>
      {isDeleting && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 9999,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ textAlign: "center", color: "white" }}>
            <CircularProgress style={{ marginBottom: "10px" }} />
            <Typography variant="h6">Employee is Deleting...</Typography>
          </div>
        </div>
      )}
      {isUpdating && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 9999,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ textAlign: "center", color: "white" }}>
            <CircularProgress style={{ marginBottom: "10px" }} />
            <Typography variant="h6">Employee is Updating...</Typography>
          </div>
        </div>
      )}

      <Paper
        elevation={3}
        style={{ padding: 20, marginBottom: 20, marginTop: 20 }}
      >
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <Link to="/registerEmployee">
              <Button
                variant="contained"
                color="primary"
                style={{ margin: "10px" }}
              >
                Register Employee
              </Button>
            </Link>
            <Button
              variant="contained"
              color="secondary"
              style={{ margin: "10px" }}
            >
              Employee Count {totalCount}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                label="Employee Name"
                value={searchEmployeeName}
                onChange={(e) => setSearchEmployeeName(e.target.value)}
                variant="standard"
                style={{ marginRight: "10px" }}
              />
              <TextField
                label="Employee Code"
                value={searchEmployeeCode}
                onChange={(e) => setSearchEmployeeCode(e.target.value)}
                variant="standard"
                style={{ marginRight: "10px" }}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleSearch}
              >
                Search
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Paper>
      <Paper
        elevation={3}
        style={{ padding: 20, marginBottom: 20, marginTop: 20 }}
      >
        <Grid container spacing={2}>
          <Grid item xs={2} md={4}>
            {/* <Autocomplete
              id="company"
              sx={{ width: 200 }}
              options={company}
              loading={loading}
              value={selectedCompany}
              onChange={(event, newValue) => handleCompanySelect(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Company"
                  variant="standard"
                  style={{ backgroundColor: "white", marginTop: "20px" }}
                />
              )}
            /> */}
            <TextField
              label="Select Company"
              value={selectedCompany}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              disabled
              variant="standard"
              style={{ backgroundColor: "white", marginTop: "20px" }}
            />
          </Grid>
          <Grid item xs={2} md={4}>
            <TextField
              select
              label="Download Options"
              value={selectedOption}
              onChange={handleOptionChange}
              variant="standard"
              style={{ minWidth: 300, marginTop: 20 }}
            >
              <MenuItem value="1">Download All Employees Company Wise</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={2} md={4}>
            <Button
              variant="contained"
              style={{
                backgroundColor: "green",
                color: "white",
                marginTop: 25,
                marginLeft: 10,
              }}
              onClick={downloadExcelFile}
            >
              DOWNLOAD EXCEL
            </Button>
          </Grid>
        </Grid>
      </Paper>
      <Paper
        elevation={3}
        style={{
          padding: 20,
          marginBottom: 20,
          marginTop: 20,
          backgroundColor: "#2196f3",
          color: "white",
          padding: "10px",
          textAlign: "center",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography
            variant="h5"
            gutterBottom
            style={{ marginBottom: "15px", color: "darkblue" }}
          >
            Employee List
          </Typography>
        </div>
      </Paper>
      {error ? (
        <p>Error fetching data. Please try again.</p>
      ) : (
        <TableContainer component={Paper} style={{ height: "20%" }}>
          {loading ? (
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={loading}
            >
              <CircularProgress color="inherit" />
              <Typography
                variant="h6"
                color="inherit"
                style={{ marginLeft: "10px" }}
              >
                Getting Data...
              </Typography>
            </Backdrop>
          ) : (
            <>
              <Grid item xs={12}>
                <Paper>
                  <Table>
                    <TableHead>
                      <TableRow
                        style={{
                          backgroundColor: "darkcyan",
                          fontWeight: "bold",
                          color: "darkblue",
                        }}
                      >
                        <TableCell />
                        <TableCell>Employee Code</TableCell>
                        <TableCell>Employee Name</TableCell>
                        <TableCell>Position Change</TableCell>
                        <TableCell>Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {employeeData.length === 0 ? (
                        <TableRow>
                          <TableCell colSpan={5} align="center">
                            No Employees found
                          </TableCell>
                        </TableRow>
                      ) : (
                        employeeData.map((employee) => (
                          <Row
                            key={employee.employeeCode}
                            row={employee}
                            onDelete={handleDeleteEmployee}
                            onUpdate={handleUpdateEmployee}
                          />
                        ))
                      )}
                    </TableBody>
                  </Table>
                  <TablePagination
                    rowsPerPageOptions={[10, 15, 20]}
                    component="div"
                    count={totalCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Paper>
              </Grid>
            </>
          )}
        </TableContainer>
      )}
    </div>
  );
};

export default UpdateEmployee;
