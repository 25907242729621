import React, { useEffect, useState, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Navbar, Nav, Dropdown, SelectPicker, Container } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import GsquareLOGO from "../../Component/Invoice/GsquareLOGO.jpg";
import "./Navigation.css";
import { CompanyContext } from "../StateProvider/CompanyProvider";
import { myAxiosCommonHost } from "../../Service/CorsHelperBaseUrl";
import { Outlet } from "react-router-dom";
import Bgd_LOGO from "../../Component/Invoice/Bgd_LOGO.jpeg";

const NavigationBarRSuite = () => {
  const navigate = useNavigate();
  const getCurrentFinancialYear = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const fiscalStartYear = currentYear - (currentDate.getMonth() >= 3 ? 0 : 1);
    const fiscalEndYear = fiscalStartYear + 1;
    return `${(fiscalStartYear % 100).toString().padStart(2, "0")}-${(
      fiscalEndYear % 100
    )
      .toString()
      .padStart(2, "0")}`;
  };
  const defaultFinancialYear = getCurrentFinancialYear();
  const { selectedFinancialYear, setSelectedFinancialYear } =
    useContext(CompanyContext);
  const [financialYear, setFinancialYear] = useState([]);
  const { selectedCompany, setSelectedCompany } = useContext(CompanyContext);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const [logo, setLogo] = useState(GsquareLOGO);
  const isInitialFetch = useRef(true); // Ref to track initial fetch

  useEffect(() => {
    if (!selectedFinancialYear) {
      setSelectedFinancialYear(defaultFinancialYear);
    }
  }, [defaultFinancialYear, selectedFinancialYear, setSelectedFinancialYear]);

  useEffect(() => {
    if (!selectedCompany) {
      setSelectedCompany("GST");
    }
  }, [selectedCompany]);

  useEffect(() => {
    async function fetchFinancialYear() {
      try {
        const response = await myAxiosCommonHost.get(
          "gst/invoice/getFiscalYear"
        );
        setFinancialYear(response.data);
      } catch (error) {
        console.error("Error fetching financial year data:", error);
      }
    }
    fetchFinancialYear();
    if (isInitialFetch.current) {
      fetchFinancialYear();
      isInitialFetch.current = false;
    }
  }, []);

  useEffect(() => {
    const getCompanyData = async () => {
      try {
        const response = await myAxiosCommonHost.get(
          "gst/profile/getCompanyCodeList"
        );
        const initialCompany = response.data[0];
        setCompanyOptions(response.data);
        setSelectedCompany(initialCompany);
      } catch (error) {
        console.error("There was a problem with the fetch operation:", error);
      }
    };
    getCompanyData();
    if (isInitialFetch.current) {
      getCompanyData();
      isInitialFetch.current = false;
    }
    // Update the current date and time every second
    const timer = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000);

    // Cleanup the timer on component unmount
    return () => clearInterval(timer);
  }, []);
  useEffect(() => {
    // Update the logo based on selected company
    if (selectedCompany === "GST") {
      setLogo(GsquareLOGO);
    } else if (selectedCompany === "BGD") {
      setLogo(Bgd_LOGO);
    }
  }, [selectedCompany]);

  const handleSelect = (eventKey) => {
    navigate(eventKey);
  };
  // Timer to track user activity
  let activityTimer;
  useEffect(() => {
    function resetTimer() {
      clearTimeout(activityTimer);
      activityTimer = setTimeout(logoutUser, 30 * 60 * 1000); // Logout after 30 minutes of inactivity
    }
    resetTimer();
    function clearTimer() {
      clearTimeout(activityTimer);
    }
    function handleUserActivity() {
      resetTimer();
    }
    // Add event listeners to reset the timer on user activity
    document.addEventListener("mousemove", handleUserActivity);
    document.addEventListener("keydown", handleUserActivity);
    // Clear the activity timer and remove event listeners when the component unmounts or when user logs out
    return () => {
      clearTimer();
      document.removeEventListener("mousemove", handleUserActivity);
      document.removeEventListener("keydown", handleUserActivity);
    };
  }, [isLoggedIn]);

  // Function to handle logout
  const logoutUser = () => {
    setIsLoggedIn(false);
    window.location.href = "/";
  };
  // const formatDate = (date) => {
  //   return date.toLocaleDateString();
  // };

  const formatTime = (time) => {
    return time.toLocaleTimeString();
  };
  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  return (
    <Navbar className="custom-navbar">
      <Navbar.Brand href="/headers">
        {/* <img src={GsquareLOGO} alt="GsquareLOGO" style={{ height: "50px" }} /> */}
        <img
          src={logo}
          alt="GsquareLOGO"
          style={{
            height: "60px",
            width: "80px",
            display: "flex",
            // alignItems: "center",
            // alignContent: "center",
            marginRight: "10px",
            marginBottom: "50px",
          }}
        />
      </Navbar.Brand>
      <Nav onSelect={handleSelect}>
        {/* <Nav.Item eventKey="/headers" className="custom-item">
          Home
        </Nav.Item> */}
        <Dropdown title="Registration" className="custom-item">
          <Nav.Item eventKey="/updateEmployee">Employee</Nav.Item>
          <Nav.Item eventKey="/updateClient">Client</Nav.Item>
          <Nav.Item eventKey="/updateBankPage">Bank</Nav.Item>
          <Nav.Item eventKey="/updateCompanyProfile"> Company Profile</Nav.Item>
        </Dropdown>
        <Dropdown title="Purchase Orders" className="custom-item">
          <Nav.Item eventKey="/updateEmployeePO">
            Employee Purchase Order
          </Nav.Item>
          <Nav.Item eventKey="/updateProjectPO">
            {" "}
            Project Purchase Order
          </Nav.Item>
          <Nav.Item eventKey="/notificationGSTPage"> Notification</Nav.Item>
          <Nav.Menu title="Summary">
            <Nav.Item eventKey="/employeeSummary">Employee Summary</Nav.Item>
            <Nav.Item eventKey="/employeePOSummaryPage">
              Employee PO Summary
            </Nav.Item>
            <Nav.Item eventKey="/projectPOSummary">Project PO Summary</Nav.Item>
          </Nav.Menu>
        </Dropdown>
        <Dropdown title="Invoice" className="custom-item">
          <Nav.Item eventKey="/employeeInvoiceList">Employee Invoice</Nav.Item>
          <Nav.Item eventKey="/projectInvoiceList"> Project Invoice</Nav.Item>
          <Nav.Item eventKey="/otherChargesInvoiceList">
            {" "}
            OtherCharges Invoice
          </Nav.Item>
          <Nav.Menu title="OutStanding Collection">
            <Nav.Item eventKey="/outStandingUnpaidHistory">
              Collection UnPaid History
            </Nav.Item>
            <Nav.Item eventKey="/outStandingpaidHistory">
              Collection Paid History
            </Nav.Item>
            <Nav.Item eventKey="/invoiceReportPage">Invoice Report</Nav.Item>
          </Nav.Menu>
        </Dropdown>
        <Dropdown title="Contract" className="custom-item">
          <Nav.Item eventKey="/updateQuotationPage">Quotation</Nav.Item>
          <Nav.Item eventKey="/updateClientAgreement">
            {" "}
            Client Agreement
          </Nav.Item>
          <Nav.Item eventKey="/clientAgreeMentNotiPage">
            {" "}
            Client Agreement Notification
          </Nav.Item>
        </Dropdown>
        <Dropdown title="Expense Report" className="custom-item">
          <Nav.Item eventKey="/updateVendor">Expense Creation</Nav.Item>
          <Nav.Item eventKey="/updateVendorInformation">
            {" "}
            Vendor Information
          </Nav.Item>
          <Nav.Item eventKey="/updateExpenseHeader"> Expense Header</Nav.Item>
        </Dropdown>
        {/* <Dropdown title="Purchase Order Notification" className="custom-item">
          <Nav.Item eventKey="/notificationGSTPage">
            Purchase Order Notifications
          </Nav.Item>
          <Nav.Item eventKey="/notificationBGDPage">
            {" "}
            BGD Notifications
          </Nav.Item>
        </Dropdown> */}
      </Nav>
      <Nav pullRight>
        {/* <Nav.Item className="custom-item">
          {formatDate(currentDateTime)}
        </Nav.Item>
        <Nav.Item className="custom-item">
          {formatTime(currentDateTime)}
        </Nav.Item> */}
        <Dropdown title="Settings" className="custom-item">
          {/* <Nav.Item>Profile</Nav.Item> */}
          <Nav.Item onSelect={logoutUser}>Logout</Nav.Item>
        </Dropdown>
        <SelectPicker
          data={companyOptions.map((code) => ({ value: code, label: code }))}
          placeholder="Select Company"
          value={selectedCompany}
          onChange={(value) => setSelectedCompany(value)}
          cleanable={true}
          searchable={true}
          style={{ width: 150, marginRight: "20px", marginLeft: "10px" }}
        />
        <SelectPicker
          data={financialYear.map((code) => ({ value: code, label: code }))}
          placeholder="Financial Year"
          value={selectedFinancialYear}
          onChange={(value) => setSelectedFinancialYear(value)}
          cleanable={true}
          searchable={true}
          style={{ width: 150, marginRight: "20px", marginLeft: "10px" }}
        />
      </Nav>
      <Outlet />
    </Navbar>
  );
};

export default NavigationBarRSuite;
