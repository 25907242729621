import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  TablePagination,
  Grid,
  Box,
  CircularProgress,
  Backdrop,
} from "@material-ui/core";
import { Autocomplete } from "@mui/material";
import { Link } from "react-router-dom";
import { Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";
import { myAxiosCommonHost } from "../../Service/CorsHelperBaseUrl";
import * as XLSX from "xlsx";
import { CompanyContext } from "../StateProvider/CompanyProvider";

const Row = ({ row, onDelete, onUpdate }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [editedExpense, setEditedExpense] = useState({ ...row });
  const [isDataChangedEdit, setIsDataChangedEdit] = useState(false);

  const handleEdit = () => {
    setDialogOpen(true);
  };

  const handleDelete = () => {
    onDelete(row.expenseCode);
  };

  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    setEditedExpense((prevExpense) => ({ ...prevExpense, [name]: value }));
    setIsDataChangedEdit(true);
  };

  const handleSave = () => {
    onUpdate(editedExpense);
    setDialogOpen(false);
  };

  return (
    <>
      <TableRow>
        <TableCell>{row.expenseFor}</TableCell>
        <TableCell>{row.selectCompany}</TableCell>
        <TableCell>
          <Button onClick={handleEdit} color="primary" variant="contained">
            Update
          </Button>
          <Button
            onClick={handleDelete}
            color="error"
            variant="contained"
            style={{
              marginLeft: "8px",
              backgroundColor: "red",
              color: "white",
            }}
          >
            Delete
          </Button>
        </TableCell>
      </TableRow>

      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        maxWidth="md"
        maxHeight="md"
        fullWidth
      >
        <DialogTitle>Edit Expense Header</DialogTitle>
        <DialogContent>
          <Typography variant="h6">Expense Header Information</Typography>
          <Paper
            elevation={3}
            style={{ padding: 10, marginBottom: 10, marginTop: 10 }}
          >
            <Grid container spacing={3} style={{ marginTop: 10 }}>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Expense For"
                  name="expenseFor"
                  value={editedExpense.expenseFor}
                  onChange={handleFieldChange}
                  variant="standard"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Select Company"
                  name="selectCompany"
                  value={editedExpense.selectCompany}
                  onChange={handleFieldChange}
                  variant="standard"
                  fullWidth
                />
              </Grid>
            </Grid>
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            color="primary"
            disabled={!isDataChangedEdit}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const UpdateExpenseHeader = () => {
  const [expenseData, setExpenseData] = useState([]);
  const [filteredExpenses, setFilteredExpenses] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [error, setError] = useState(null);
  const [totalCount, setTotalCount] = useState(0); // Initialize total count state
  //const [selectedCompany, setSelectedCompany] = useState("");
  const [data, setData] = useState(null);
  const { selectedCompany } = useContext(CompanyContext);

  // useEffect(() => {
  //   async function fetchCompany() {
  //     try {
  //       const response = await myAxiosCommonHost.get(
  //         "gst/profile/getCompanyCodeList"
  //       );
  //       const companies = response.data;
  //       setCompany(companies);
  //       if (companies.length > 0) {
  //         setSelectedCompany(companies[0]);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching company data:", error);
  //     }
  //   }
  //   fetchCompany();
  // }, []);

  const fetchExpenseHeaderCount = () => {
    myAxiosCommonHost
      .get(`gst/expense/getExpenseHeaderCount?selectCompany=${selectedCompany}`)
      .then((response) => {
        const count = parseInt(response.data); // Extract count from API response
        setTotalCount(count); // Set total count state
      })
      .catch((error) => {
        console.error("Error fetching Agreement count:", error);
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const limit = rowsPerPage;
        const offset = page * rowsPerPage;
        const response = await myAxiosCommonHost.get(
          `gst/expense/getExpenseHeaderList?selectCompany=${selectedCompany}&limit=${limit}&offset=${offset}`
        );
        setData(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(error);
        setLoading(false);
      }
    };
    if (selectedCompany) {
      fetchExpenseHeaderCount();
      fetchData();
    }
  }, [selectedCompany, rowsPerPage, page]);

  const handleDelete = async (expenseCode) => {
    try {
      await myAxiosCommonHost.delete(
        `gst/expense/deleteExpenseHeader/expenseCode?expenseCode=${expenseCode}`
      );
      setExpenseData(
        expenseData.filter((expense) => expense.expenseCode !== expenseCode)
      );
    } catch (error) {
      console.error("Error deleting expense data:", error);
    }
  };

  const handleUpdate = async (updatedExpense) => {
    try {
      const response = await myAxiosCommonHost.put(
        `/gst/expense/updateExpenseHeader`,
        updatedExpense
      );
      setExpenseData((prevExpenses) =>
        prevExpenses.map((expense) =>
          expense.expenseCode === updatedExpense.expenseCode
            ? response.data
            : expense
        )
      );
    } catch (error) {
      console.error("Error updating expense data:", error);
    }
  };

  // const handleCompanySelect = (company) => {
  //   setSelectedCompany(company);
  //   console.log("Selected Company Year:", company);
  // };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  return (
    <div>
      <Paper
        elevation={3}
        style={{ padding: 20, marginBottom: 20, marginTop: 20 }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <Link to="/registerExpenseHeader">
                <Button
                  variant="contained"
                  color="primary"
                  style={{ margin: "10px" }}
                >
                  Register Expense Header
                </Button>
              </Link>
            </Grid>

            <Grid container spacing={2} style={{ padding: "20px" }}>
              <Grid item xs={6} md={3}>
                {/* <Autocomplete
                  id="company"
                  options={company}
                  loading={loading}
                  value={selectedCompany}
                  onChange={(event, newValue) => handleCompanySelect(newValue)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Company"
                      variant="standard"
                      style={{ backgroundColor: "white" }}
                    />
                  )}
                /> */}
                <TextField
                  label="Select Company"
                  value={selectedCompany}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  disabled
                  variant="standard"
                  style={{ backgroundColor: "white", marginTop: "20px" }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Paper>
      <Paper
        elevation={3}
        style={{
          padding: 20,
          marginBottom: 20,
          marginTop: 20,
          backgroundColor: "#2196f3",
          color: "white",
          padding: "10px",
          textAlign: "center",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography
            variant="h5"
            gutterBottom
            style={{ marginBottom: "15px", color: "darkblue" }}
          >
            Expense Header List
          </Typography>
        </div>
      </Paper>
      {error ? (
        <p>Error fetching data. Please try again.</p>
      ) : (
        <TableContainer component={Paper} style={{ height: "20%" }}>
          {loading ? (
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={loading}
            >
              <CircularProgress color="inherit" />
              <Typography
                variant="h6"
                color="inherit"
                style={{ marginLeft: "10px" }}
              >
                Getting Data...
              </Typography>
            </Backdrop>
          ) : (
            <>
              <Table>
                <TableHead>
                  <TableRow
                    style={{
                      backgroundColor: "darkcyan",
                      fontWeight: "bold",
                      color: "darkblue",
                    }}
                  >
                    <TableCell>Expense For</TableCell>
                    <TableCell>Select Company</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data && data.length > 0 ? (
                    data.map((row) => (
                      <Row
                        key={row.expenseCode}
                        row={row}
                        onDelete={handleDelete}
                        onUpdate={handleUpdate}
                      />
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={3} align="center">
                        No Expenese Data available
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={totalCount}
                rowsPerPage={rowsPerPage}
                page={currentPage}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          )}
        </TableContainer>
      )}
    </div>
  );
};

export default UpdateExpenseHeader;
