import Headers from "../../../Pages/Header";
import ProjectPOSummary from "../ProjectPOSummary";

const ProjectPOSummaryPage = () => {
  return (
    <Headers>
      <ProjectPOSummary />
    </Headers>
  );
};
export default ProjectPOSummaryPage;
