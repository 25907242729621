import React, { useState } from 'react';
import {
  TextField,
  Autocomplete,
  Button,
  Box,
  Container,
  Typography,
} from '@mui/material';
import axios from 'axios';

const options = [
  { value: 'ALL', label: 'ALL' },
  { value: 'FinancialYear', label: 'FinancialYear' },
  { value: 'CurrentPage', label: 'Current Page' },
];

const AutocompleteComponent = () => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectCompany, setSelectCompany] = useState('');
  const [financialYear, setFinancialYear] = useState('');
  const [limit, setLimit] = useState('');
  const [offset, setOffset] = useState('');

  const handleChange = (event, newValue) => {
    setSelectedOption(newValue);
  };

  const handleSubmit = async () => {
    const baseUrl = 'http://localhost:8082/gst/projectpo/getProjectPOListForExcel';
    let url = '';

    if (selectedOption?.value === 'ALL') {
      url = `${baseUrl}?excelFilterType=ALL&selectCompany=${selectCompany}`;
    } else if (selectedOption?.value === 'FinancialYear') {
      url = `${baseUrl}?excelFilterType&selectCompany=${selectCompany}&financialYear=${financialYear}`;
    } else if (selectedOption?.value === 'CurrentPage') {
      url = `${baseUrl}?excelFilterType&selectCompany=${selectCompany}&financialYear=${financialYear}&limit=${limit}&offset=${offset}`;
    }

    try {
      const response = await axios.get(url);
      console.log('Response:', response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  return (
    <Container maxWidth="sm">
      <Box my={4}>
        <Typography variant="h4" component="h1" gutterBottom>
          Excel Download Options
        </Typography>
        <Autocomplete
          options={options}
          getOptionLabel={(option) => option.label}
          onChange={handleChange}
          renderInput={(params) => <TextField {...params} label="Select Option" variant="outlined" />}
        />
        <TextField
          label="Select Company"
          variant="outlined"
          fullWidth
          margin="normal"
          value={selectCompany}
          onChange={(e) => setSelectCompany(e.target.value)}
        />
        {(selectedOption?.value === 'FinancialYear' || selectedOption?.value === 'CurrentPage') && (
          <TextField
            label="Financial Year"
            variant="outlined"
            fullWidth
            margin="normal"
            value={financialYear}
            onChange={(e) => setFinancialYear(e.target.value)}
          />
        )}
        {selectedOption?.value === 'CurrentPage' && (
          <>
            <TextField
              label="Limit"
              variant="outlined"
              fullWidth
              margin="normal"
              type="number"
              value={limit}
              onChange={(e) => setLimit(e.target.value)}
            />
            <TextField
              label="Offset"
              variant="outlined"
              fullWidth
              margin="normal"
              type="number"
              value={offset}
              onChange={(e) => setOffset(e.target.value)}
            />
          </>
        )}
        <Box mt={2}>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default AutocompleteComponent;
