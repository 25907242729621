import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Collapse,
  Grid,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Box, IconButton } from "@mui/material";
import { myAxiosCommonHost } from "../Service/CorsHelperBaseUrl";

const OtherChargesInvoiceListVal = () => {
  const [expandedRow, setExpandedRow] = useState(null);
  const [data, setData] = useState(null);
  const [editedInvoiceData, setEditedInvoiceData] = useState(null);
  const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
  const [fetchError, setFetchError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "http://localhost:8082/gst/invoice/getInvoiceList?filterType=OTHERCHARGESINVOICE"
        );
        const result = await response.json();
        setData(result);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleUpdateInvoiceClick = (invoiceData) => {
    setEditedInvoiceData({ ...invoiceData });
    setUpdateDialogOpen(true);
  };
  const handleDialogInputChange = (field, value) => {
    setEditedInvoiceData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };
  const handleUpdateDialogSave = () => {
    console.log("Updated Invoice Data:", editedInvoiceData);
    console.log(JSON.stringify(editedInvoiceData, null, 2));
    const response = axios.put(
      "http://localhost:8082/gst/invoice/updateInvoice?filterType=OTHERCHARGESINVOICE",
      editedInvoiceData
    );
    setUpdateDialogOpen(false);
  };

  const handleExpandClick = (index) => {
    setExpandedRow(expandedRow === index ? null : index);
  };

  const handleProjectInputChange = (index, field, value) => {
    const updatedData = { ...editedInvoiceData };
    updatedData.invoiceOtherChargesDetailsUIList[index][field] = value;
    setEditedInvoiceData(updatedData);
  };

  const handleGstDetailsChange = (field, value) => {
    setEditedInvoiceData((prevData) => ({
      ...prevData,
      invoiceGstDetailsUI: {
        ...prevData.invoiceGstDetailsUI,
        [field]: value,
      },
    }));
  };
  const handlePaymentModeChange = (field, value) => {
    setEditedInvoiceData((prevData) => ({
      ...prevData,
      invoicePaymentModeUI: {
        ...prevData.invoicePaymentModeUI,
        [field]: value,
      },
    }));
  };
  const handleBankDetailsChange = (field, value) => {
    setEditedInvoiceData((prevData) => ({
      ...prevData,
      bankUI: {
        ...prevData.bankUI,
        [field]: value,
      },
    }));
  };

  //   if (!data) {
  //     return <div>Loading...</div>;
  //   }
  if (fetchError) {
    return <div>Error fetching data. Please try again later.</div>;
  }

  if (loading) {
    return <div>Loading...</div>;
  }
  return (
    <>
      <Paper
        elevation={3}
        style={{ padding: 20, marginBottom: 20, marginTop: 20 }}
      >
        <Link to="/otherChargesInvoicePage">
          <Button
            variant="contained"
            color="secondary"
            style={{ marginTop: "20px" }}
          >
            Generate Invoice
          </Button>
        </Link>
      </Paper>
      <Paper
        elevation={3}
        style={{
          padding: 20,
          marginBottom: 20,
          backgroundColor: "#2196f3",
          color: "white",
          padding: "10px",
          textAlign: "center",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography
            variant="h5"
            gutterBottom
            style={{ marginBottom: "15px", color: "darkblue" }}
          >
            Other Charges Invoice List
          </Typography>
        </div>
      </Paper>
      {error ? (
        <p>No Invoice Data Found ,Generete A New Invoice.</p>
      ) : (
        <TableContainer
          component={Paper}
          style={{ height: "20%", marginTop: "20px" }}
        >
          {loading ? (
            <p>Loading...</p>
          ) : (
            <>
              {data.length === 0 ? (
                <p>No data found.</p>
              ) : (
                <Table>
                  <TableHead>
                    <TableRow
                      style={{
                        backgroundColor: "darkcyan",
                        fontWeight: "bold",
                        color: "darkblue",
                      }}
                    >
                      <TableCell />
                      <TableCell>Invoice Ref</TableCell>
                      <TableCell>Client Name</TableCell>
                      {/* <TableCell>Po Number</TableCell> */}
                      <TableCell>Invoice No</TableCell>
                      <TableCell>Action</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.map((invoice, index) => (
                      <React.Fragment key={index}>
                        <TableRow>
                          <TableCell>
                            <IconButton
                              onClick={() => handleExpandClick(index)}
                              aria-expanded={expandedRow === index}
                              aria-label="show more"
                            >
                              <ExpandMoreIcon />
                            </IconButton>
                          </TableCell>
                          <TableCell>{invoice.invoiceRefNo}</TableCell>
                          <TableCell>{invoice.clientName}</TableCell>
                          {/* <TableCell>{invoice.poNumber}</TableCell> */}
                          <TableCell>{invoice.invoiceNo}</TableCell>
                          <TableCell>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => handleUpdateInvoiceClick(invoice)}
                            >
                              Update Invoice
                            </Button>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={5}>
                            <Collapse
                              in={expandedRow === index}
                              timeout="auto"
                              unmountOnExit
                            >
                              {invoice.invoiceOtherChargesDetailsUIList.map(
                                (proInvoice, empIndex) => (
                                  <Accordion key={empIndex}>
                                    <AccordionSummary
                                      expandIcon={<ExpandMoreIcon />}
                                      aria-controls={`panel${index}-content`}
                                      id={`panel${index}-header`}
                                      IconButtonProps={{ edge: "start" }}
                                    >
                                      <Typography>
                                        {"Employee Name -:"}
                                        {
                                          invoice
                                            .invoiceOtherChargesDetailsUIList[
                                            empIndex
                                          ].employeeName
                                        }
                                      </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                      <Table style={{ width: "100%" }}>
                                        <TableBody>
                                          <TableRow
                                            style={{
                                              backgroundColor: "goldenrod",
                                              fontWeight: "bold",
                                              color: "darkblue",
                                            }}
                                          >
                                            <TableCell>
                                              Emplyoyee Name
                                            </TableCell>
                                            <TableCell>Employee Role</TableCell>
                                            <TableCell>Select Item</TableCell>
                                            <TableCell>Action</TableCell>
                                            <TableCell></TableCell>
                                          </TableRow>
                                          <TableRow>
                                            <TableCell>
                                              {proInvoice.employeeName}
                                            </TableCell>
                                            <TableCell>
                                              {proInvoice.employeeRole}
                                            </TableCell>
                                            <TableCell>
                                              {proInvoice.selectItem}
                                            </TableCell>
                                          </TableRow>
                                          <TableRow>
                                            <TableCell colSpan={6}>
                                              <Collapse
                                                in={expandedRow === index}
                                                timeout="auto"
                                                unmountOnExit
                                              >
                                                <Box style={{ padding: 20 }}>
                                                  <Typography variant="subtitle1">
                                                    Employee Details
                                                  </Typography>
                                                  <TableRow
                                                    style={{
                                                      backgroundColor:
                                                        "darkcyan",
                                                      fontWeight: "bold",
                                                      color: "darkblue",
                                                    }}
                                                  >
                                                    <TableCell>
                                                      Emplyoyee Name
                                                    </TableCell>
                                                    <TableCell>
                                                      Employee Role
                                                    </TableCell>
                                                    <TableCell>
                                                      Select Item
                                                    </TableCell>
                                                    <TableCell>
                                                      Description
                                                    </TableCell>
                                                    <TableCell>
                                                      Charge Per Amount
                                                    </TableCell>
                                                    <TableCell>
                                                      CTC Amount
                                                    </TableCell>
                                                    <TableCell>
                                                      Percentage Amount
                                                    </TableCell>
                                                  </TableRow>
                                                  <TableRow>
                                                    <TableCell>
                                                      {proInvoice.employeeName}
                                                    </TableCell>
                                                    <TableCell>
                                                      {proInvoice.employeeRole}
                                                    </TableCell>
                                                    <TableCell>
                                                      {proInvoice.selectItem}
                                                    </TableCell>
                                                    <TableCell>
                                                      {proInvoice.description}
                                                    </TableCell>
                                                    <TableCell>
                                                      {
                                                        proInvoice.chargePerAmount
                                                      }
                                                    </TableCell>
                                                    <TableCell>
                                                      {proInvoice.ctcAmount}
                                                    </TableCell>
                                                    <TableCell>
                                                      {
                                                        proInvoice.percentageAmount
                                                      }
                                                    </TableCell>
                                                  </TableRow>

                                                  <Typography variant="subtitle1">
                                                    Invoice Gst Details
                                                  </Typography>
                                                  <TableRow
                                                    style={{
                                                      backgroundColor:
                                                        "darkcyan",
                                                      fontWeight: "bold",
                                                      color: "darkblue",
                                                    }}
                                                  >
                                                    <TableCell>
                                                      Total Presenty Amount
                                                    </TableCell>
                                                    <TableCell>
                                                      Total Tax Amt
                                                    </TableCell>
                                                    <TableCell>
                                                      Grand Total
                                                    </TableCell>
                                                    <TableCell>
                                                      Deduction Amount
                                                    </TableCell>
                                                    <TableCell>
                                                      Deduction Particular
                                                    </TableCell>
                                                    <TableCell>CGST</TableCell>
                                                    <TableCell>SGST</TableCell>
                                                    <TableCell>IGST</TableCell>
                                                    <TableCell>
                                                      Grand Total Amount
                                                    </TableCell>
                                                    <TableCell>
                                                      Total In Word Amount
                                                    </TableCell>
                                                  </TableRow>
                                                  <TableRow>
                                                    <TableCell>
                                                      {
                                                        invoice
                                                          .invoiceGstDetailsUI
                                                          .totalPresentyAmount
                                                      }
                                                    </TableCell>
                                                    <TableCell>
                                                      {
                                                        invoice
                                                          .invoiceGstDetailsUI
                                                          .totalTaxAmt
                                                      }
                                                    </TableCell>
                                                    <TableCell>
                                                      {
                                                        invoice
                                                          .invoiceGstDetailsUI
                                                          .grandTotal
                                                      }
                                                    </TableCell>
                                                    <TableCell>
                                                      {
                                                        invoice
                                                          .invoiceGstDetailsUI
                                                          .deductionAmount
                                                      }
                                                    </TableCell>
                                                    <TableCell>
                                                      {
                                                        invoice
                                                          .invoiceGstDetailsUI
                                                          .deductionParticular
                                                      }
                                                    </TableCell>
                                                    <TableCell>
                                                      {
                                                        invoice
                                                          .invoiceGstDetailsUI
                                                          .cGST
                                                      }
                                                    </TableCell>
                                                    <TableCell>
                                                      {
                                                        invoice
                                                          .invoiceGstDetailsUI
                                                          .sGST
                                                      }
                                                    </TableCell>
                                                    <TableCell>
                                                      {
                                                        invoice
                                                          .invoiceGstDetailsUI
                                                          .iGST
                                                      }
                                                    </TableCell>
                                                    <TableCell>
                                                      {
                                                        invoice
                                                          .invoiceGstDetailsUI
                                                          .grandTotalAmount
                                                      }
                                                    </TableCell>
                                                    <TableCell>
                                                      {
                                                        invoice
                                                          .invoiceGstDetailsUI
                                                          .totalInWordAmount
                                                      }
                                                    </TableCell>
                                                  </TableRow>

                                                  <Typography variant="subtitle1">
                                                    Invoice Payment Mode
                                                  </Typography>
                                                  <TableRow
                                                    style={{
                                                      backgroundColor:
                                                        "darkcyan",
                                                      fontWeight: "bold",
                                                      color: "darkblue",
                                                    }}
                                                  >
                                                    <TableCell>
                                                      Mode Terms Of Payment
                                                    </TableCell>
                                                    <TableCell>
                                                      Kind Attention
                                                    </TableCell>
                                                    <TableCell>
                                                      Select Bank AccNo
                                                    </TableCell>
                                                  </TableRow>
                                                  <TableRow>
                                                    <TableCell>
                                                      {
                                                        invoice
                                                          .invoicePaymentModeUI
                                                          .modeTermsOfPayment
                                                      }
                                                    </TableCell>
                                                    <TableCell>
                                                      {
                                                        invoice
                                                          .invoicePaymentModeUI
                                                          .kindAttention
                                                      }
                                                    </TableCell>
                                                    <TableCell>
                                                      {
                                                        invoice
                                                          .invoicePaymentModeUI
                                                          .selectBankAccNo
                                                      }
                                                    </TableCell>
                                                  </TableRow>

                                                  <Typography variant="subtitle1">
                                                    Bank Details
                                                  </Typography>
                                                  <TableRow
                                                    style={{
                                                      backgroundColor:
                                                        "darkcyan",
                                                      fontWeight: "bold",
                                                      color: "darkblue",
                                                    }}
                                                  >
                                                    <TableCell>
                                                      Account Number
                                                    </TableCell>
                                                    <TableCell>
                                                      Bank Name
                                                    </TableCell>
                                                    <TableCell>
                                                      Account Type
                                                    </TableCell>
                                                    <TableCell>
                                                      Branch Name
                                                    </TableCell>
                                                    <TableCell>
                                                      Account Number
                                                    </TableCell>
                                                    <TableCell>IFSC</TableCell>
                                                    <TableCell>MICR</TableCell>
                                                    <TableCell>
                                                      Bank Address
                                                    </TableCell>
                                                    <TableCell>
                                                      Swift Code
                                                    </TableCell>
                                                  </TableRow>
                                                  <TableRow>
                                                    <TableCell>
                                                      {
                                                        invoice.bankUI
                                                          .accountNumber
                                                      }
                                                    </TableCell>
                                                    <TableCell>
                                                      {invoice.bankUI.bankName}
                                                    </TableCell>
                                                    <TableCell>
                                                      {
                                                        invoice.bankUI
                                                          .accountType
                                                      }
                                                    </TableCell>
                                                    <TableCell>
                                                      {
                                                        invoice.bankUI
                                                          .branchName
                                                      }
                                                    </TableCell>
                                                    <TableCell>
                                                      {invoice.bankUI.ifsc}
                                                    </TableCell>
                                                    <TableCell>
                                                      {invoice.bankUI.micr}
                                                    </TableCell>
                                                    <TableCell>
                                                      {
                                                        invoice.bankUI
                                                          .bankAddress
                                                      }
                                                    </TableCell>
                                                    <TableCell>
                                                      {invoice.bankUI.swiftCode}
                                                    </TableCell>
                                                  </TableRow>
                                                </Box>
                                              </Collapse>
                                            </TableCell>
                                          </TableRow>
                                        </TableBody>
                                      </Table>
                                    </AccordionDetails>
                                  </Accordion>
                                )
                              )}
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    ))}
                  </TableBody>
                </Table>
              )}
            </>
          )}
        </TableContainer>
      )}
      <Dialog
        open={updateDialogOpen}
        onClose={() => setUpdateDialogOpen(false)}
        maxWidth="lg"
        maxHeight="lg"
        fullWidth
      >
        <DialogTitle>Update Invoice Data</DialogTitle>
        <DialogContent>
          <Paper
            elevation={4}
            style={{ padding: 20, marginBottom: 20, marginTop: 20 }}
          >
            <Grid container spacing={6} style={{ marginTop: 10 }}>
              <Grid item xs={3}>
                <TextField
                  label="Select Company"
                  value={editedInvoiceData?.selectCompany || ""}
                  onChange={(e) =>
                    handleDialogInputChange("selectCompany", e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Financial Year"
                  value={editedInvoiceData?.financialYear || ""}
                  onChange={(e) =>
                    handleDialogInputChange("financialYear", e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Invoice No"
                  value={editedInvoiceData?.invoiceNo || ""}
                  onChange={(e) =>
                    handleDialogInputChange("invoiceNo", e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Invoice Ref No"
                  value={editedInvoiceData?.invoiceRefNo || ""}
                  onChange={(e) =>
                    handleDialogInputChange("invoiceRefNo", e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Invoice Date"
                  type="date"
                  value={editedInvoiceData?.invoiceDate || ""}
                  onChange={(e) =>
                    handleDialogInputChange("invoiceDate", e.target.value)
                  }
                  fullWidth
                  margin="dense"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Invoice Type"
                  value={editedInvoiceData?.invoiceType || ""}
                  onChange={(e) =>
                    handleDialogInputChange("invoiceType", e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Client Type"
                  value={editedInvoiceData?.clientType || ""}
                  onChange={(e) =>
                    handleDialogInputChange("clientType", e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Client Name"
                  value={editedInvoiceData?.clientName || ""}
                  onChange={(e) =>
                    handleDialogInputChange("clientName", e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Issue Date"
                  type="date"
                  value={editedInvoiceData?.issueDate || ""}
                  onChange={(e) =>
                    handleDialogInputChange("issueDate", e.target.value)
                  }
                  fullWidth
                  margin="dense"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="SacHsn"
                  value={editedInvoiceData?.sacHsn || ""}
                  onChange={(e) =>
                    handleDialogInputChange("sacHsn", e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="PO Number"
                  value={editedInvoiceData?.poNumber || ""}
                  onChange={(e) =>
                    handleDialogInputChange("poNumber", e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Buyers Po Number"
                  value={editedInvoiceData?.buyersPoNumber || ""}
                  onChange={(e) =>
                    handleDialogInputChange("buyersPoNumber", e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Payment Year"
                  value={editedInvoiceData?.paymentYear || ""}
                  onChange={(e) =>
                    handleDialogInputChange("paymentYear", e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Payment Month"
                  value={editedInvoiceData?.paymentMonth || ""}
                  onChange={(e) =>
                    handleDialogInputChange("paymentMonth", e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Unique Po No"
                  value={editedInvoiceData?.uniqueNoPoNo || ""}
                  onChange={(e) =>
                    handleDialogInputChange("uniqueNoPoNo", e.target.value)
                  }
                />
              </Grid>
            </Grid>
          </Paper>

          <Paper
            elevation={3}
            style={{ padding: 20, marginBottom: 20, marginTop: 20 }}
          >
            <TableContainer component={Paper} style={{ padding: "20px" }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Emplyoyee Name</TableCell>
                    <TableCell>Employee Role</TableCell>
                    <TableCell>Select Item</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Charge Per Amount</TableCell>
                    <TableCell>CTC Amount</TableCell>
                    <TableCell>Percentage Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {editedInvoiceData?.invoiceOtherChargesDetailsUIList?.map(
                    (project, projectIndex) => (
                      <TableRow key={projectIndex}>
                        <TableCell>
                          <TextField
                            value={project.employeeName || ""}
                            onChange={(e) =>
                              handleProjectInputChange(
                                projectIndex,
                                "employeeName",
                                e.target.value
                              )
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            value={project.employeeRole || ""}
                            onChange={(e) =>
                              handleProjectInputChange(
                                projectIndex,
                                "employeeRole",
                                e.target.value
                              )
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            value={project.selectItem || ""}
                            onChange={(e) =>
                              handleProjectInputChange(
                                projectIndex,
                                "selectItem",
                                e.target.value
                              )
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            value={project.description || ""}
                            onChange={(e) =>
                              handleProjectInputChange(
                                projectIndex,
                                "description",
                                e.target.value
                              )
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            value={project.chargePerAmount || ""}
                            onChange={(e) =>
                              handleProjectInputChange(
                                projectIndex,
                                "chargePerAmount",
                                e.target.value
                              )
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            value={project.ctcAmount || ""}
                            onChange={(e) =>
                              handleProjectInputChange(
                                projectIndex,
                                "ctcAmount",
                                e.target.value
                              )
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            value={project.percentageAmount || ""}
                            onChange={(e) =>
                              handleProjectInputChange(
                                projectIndex,
                                "percentageAmount",
                                e.target.value
                              )
                            }
                          />
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>

          <Paper
            elevation={3}
            style={{ padding: 20, marginBottom: 20, marginTop: 20 }}
          >
            <Grid container spacing={6} style={{ marginTop: 10 }}>
              <Grid item xs={4}>
                <Paper
                  elevation={3}
                  style={{ padding: "20px", marginBottom: "20px" }}
                >
                  <TextField
                    label="Total Presenty Amount"
                    sx={{ marginBottom: 2, marginTop: 2 }}
                    variant="standard"
                    size="small"
                    fullWidth
                    value={
                      editedInvoiceData?.invoiceGstDetailsUI
                        .totalPresentyAmount || ""
                    }
                    onChange={(e) =>
                      handleGstDetailsChange(
                        "totalPresentyAmount",
                        e.target.value
                      )
                    }
                  />

                  <TextField
                    label="Total Tax Amt"
                    sx={{ marginBottom: 2, marginTop: 2 }}
                    variant="standard"
                    size="small"
                    fullWidth
                    value={
                      editedInvoiceData?.invoiceGstDetailsUI.totalTaxAmt || ""
                    }
                    onChange={(e) =>
                      handleGstDetailsChange("totalTaxAmt", e.target.value)
                    }
                  />

                  <TextField
                    label="Grand Total Amount"
                    sx={{ marginBottom: 2, marginTop: 2 }}
                    variant="standard"
                    size="small"
                    fullWidth
                    value={
                      editedInvoiceData?.invoiceGstDetailsUI.grandTotalAmount ||
                      ""
                    }
                    onChange={(e) =>
                      handleGstDetailsChange("grandTotalAmount", e.target.value)
                    }
                  />

                  <TextField
                    label="Deduction Amount"
                    sx={{ marginBottom: 2, marginTop: 2 }}
                    variant="standard"
                    size="small"
                    fullWidth
                    value={
                      editedInvoiceData?.invoiceGstDetailsUI.deductionAmount ||
                      ""
                    }
                    onChange={(e) =>
                      handleGstDetailsChange("deductionAmount", e.target.value)
                    }
                  />
                </Paper>
              </Grid>
              <Grid item xs={3}>
                <Paper
                  elevation={3}
                  style={{ padding: "20px", marginBottom: "20px" }}
                >
                  <TextField
                    label="Deduction Particular"
                    sx={{ marginBottom: 2, marginTop: 2 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="standard"
                    size="small"
                    multiline
                    rows={4}
                    value={
                      editedInvoiceData?.invoiceGstDetailsUI
                        .deductionParticular || ""
                    }
                    onChange={(e) =>
                      handleGstDetailsChange(
                        "deductionParticular",
                        e.target.value
                      )
                    }
                  />
                </Paper>
              </Grid>
              <Grid item xs={3}>
                <Paper
                  elevation={3}
                  style={{ padding: "20px", marginBottom: "20px" }}
                >
                  <TextField
                    label="CGST"
                    variant="standard"
                    size="small"
                    value={editedInvoiceData?.invoiceGstDetailsUI.cGST || ""}
                    onChange={(e) =>
                      handleGstDetailsChange("cGST", e.target.value)
                    }
                    sx={{ marginBottom: 2, marginTop: 2 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />

                  <TextField
                    label="IGST"
                    variant="standard"
                    size="small"
                    value={editedInvoiceData?.invoiceGstDetailsUI.iGST || ""}
                    onChange={(e) =>
                      handleGstDetailsChange("iGST", e.target.value)
                    }
                    sx={{ marginBottom: 2, marginTop: 2 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />

                  <TextField
                    label="SGST"
                    variant="standard"
                    size="small"
                    value={editedInvoiceData?.invoiceGstDetailsUI.sGST || ""}
                    onChange={(e) =>
                      handleGstDetailsChange("sGST", e.target.value)
                    }
                    sx={{ marginBottom: 2, marginTop: 2 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <TextField
                    label="Total In Word Amount"
                    variant="standard"
                    size="small"
                    value={
                      editedInvoiceData?.invoiceGstDetailsUI
                        .totalInWordAmount || ""
                    }
                    onChange={(e) =>
                      handleGstDetailsChange(
                        "totalInWordAmount",
                        e.target.value
                      )
                    }
                    sx={{ marginBottom: 2, marginTop: 2 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Paper>
              </Grid>
              <Grid item xs={3}>
                <Paper
                  elevation={3}
                  style={{ padding: "20px", marginBottom: "20px" }}
                >
                  <Button variant="contained">Calculate</Button>
                </Paper>
              </Grid>
            </Grid>
          </Paper>

          <Paper
            elevation={3}
            style={{ padding: 20, marginBottom: 20, marginTop: 20 }}
          >
            <Grid container spacing={6} style={{ marginTop: 10 }}>
              <Grid item xs={4}>
                <TextField
                  label="Mode Terms Of Payment"
                  value={
                    editedInvoiceData?.invoicePaymentModeUI
                      .modeTermsOfPayment || ""
                  }
                  onChange={(e) =>
                    handlePaymentModeChange(
                      "modeTermsOfPayment",
                      e.target.value
                    )
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Kind Attention"
                  value={
                    editedInvoiceData?.invoicePaymentModeUI.kindAttention || ""
                  }
                  onChange={(e) =>
                    handlePaymentModeChange("kindAttention", e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Select Bank Acc No"
                  value={
                    editedInvoiceData?.invoicePaymentModeUI.selectBankAccNo ||
                    ""
                  }
                  onChange={(e) =>
                    handlePaymentModeChange("selectBankAccNo", e.target.value)
                  }
                />
              </Grid>
            </Grid>
          </Paper>
          <Paper
            elevation={3}
            style={{ padding: 20, marginBottom: 20, marginTop: 20 }}
          >
            <Grid container spacing={6} style={{ marginTop: 10 }}>
              <Grid item xs={4}>
                <TextField
                  label="Account Number"
                  value={editedInvoiceData?.bankUI.accountNumber || ""}
                  onChange={(e) =>
                    handleBankDetailsChange("accountNumber", e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Bank Name"
                  value={editedInvoiceData?.bankUI.bankName || ""}
                  onChange={(e) =>
                    handleBankDetailsChange("bankName", e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Account Type"
                  value={editedInvoiceData?.bankUI.accountType || ""}
                  onChange={(e) =>
                    handleBankDetailsChange("accountType", e.target.value)
                  }
                />
              </Grid>

              <Grid item xs={4}>
                <TextField
                  label="Branch Name"
                  value={editedInvoiceData?.bankUI.branchName || ""}
                  onChange={(e) =>
                    handleBankDetailsChange("branchName", e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="IFSC"
                  value={editedInvoiceData?.bankUI.ifsc || ""}
                  onChange={(e) =>
                    handleBankDetailsChange("ifsc", e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="MICR"
                  value={editedInvoiceData?.bankUI.micr || ""}
                  onChange={(e) =>
                    handleBankDetailsChange("micr", e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Bank Address"
                  value={editedInvoiceData?.bankUI.bankAddress || ""}
                  onChange={(e) =>
                    handleBankDetailsChange("bankAddress", e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Swift Code"
                  value={editedInvoiceData?.bankUI.swiftCode || ""}
                  onChange={(e) =>
                    handleBankDetailsChange("swiftCode", e.target.value)
                  }
                />
              </Grid>
            </Grid>
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setUpdateDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleUpdateDialogSave} color="primary">
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default OtherChargesInvoiceListVal;
