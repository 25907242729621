import Headers from "../../../Pages/Header";
import UpdateClientAgreement from "../UpdateClientAgreement";

const UpdateClientAgreementPage = () => {
  return (
    <Headers>
      <UpdateClientAgreement />
    </Headers>
  );
};
export default UpdateClientAgreementPage;
