// import React, { useState } from "react";
// import axios from "axios";
// import { myAxiosCommonHost } from "../Service/CorsHelperBaseUrl";

// const FileDownload = () => {
//   const [fileId, setFileId] = useState("");
//   const [downloadProgress, setDownloadProgress] = useState(0);
//   const [downloadSuccess, setDownloadSuccess] = useState(false);

//   const handleInputChange = (event) => {
//     setFileId(event.target.value);
//     setDownloadSuccess(false);
//     setDownloadProgress(0);
//   };

//   const handleFileDownload = async () => {
//     if (!fileId) {
//       alert("Please enter a file ID.");
//       return;
//     }

//     try {
//       const response = await myAxiosCommonHost.get(`gst/clientFile/${fileId}`, {
//         responseType: "blob",
//         onDownloadProgress: (progressEvent) => {
//           const { loaded, total } = progressEvent;
//           const percentCompleted = Math.round((loaded * 100) / total);
//           setDownloadProgress(percentCompleted);
//         },
//       });

//       const url = window.URL.createObjectURL(new Blob([response.data]));
//       const link = document.createElement("a");
//       link.href = url;

//       const contentDisposition = response.headers["content-disposition"];
//       const fileName = contentDisposition
//         ? contentDisposition.split("filename=")[1]
//         : "downloadedFile";
//       link.setAttribute("download", fileName);

//       document.body.appendChild(link);
//       link.click();
//       setDownloadSuccess(true);
//     } catch (error) {
//       console.error("There was an error downloading the file!", error);
//       alert("File download failed, please try again.");
//     }
//   };

//   return (
//     <div>
//       <h1>Download a File</h1>
//       <input
//         type="text"
//         placeholder="Enter file ID"
//         value={fileId}
//         onChange={handleInputChange}
//       />
//       <button onClick={handleFileDownload}>Download</button>
//       {downloadProgress > 0 && (
//         <progress value={downloadProgress} max="100">
//           {downloadProgress}%
//         </progress>
//       )}
//       {downloadSuccess && <p>File downloaded successfully!</p>}
//     </div>
//   );
// };

// export default FileDownload;
import React, { useState } from "react";
import axios from "axios";
import { myAxiosCommonHost } from "../Service/CorsHelperBaseUrl";

const FileDownload = () => {
  const [fileId, setFileId] = useState("");
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [downloadSuccess, setDownloadSuccess] = useState(false);

  const handleInputChange = (event) => {
    setFileId(event.target.value);
    setDownloadSuccess(false);
    setDownloadProgress(0);
  };

  const handleFileDownload = async () => {
    if (!fileId) {
      alert("Please enter a file ID.");
      return;
    }

    try {
      const response = await myAxiosCommonHost.get(
        `gst/clientFile/${fileId}`,
      
        {
          responseType: "blob",
          onDownloadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            const percentCompleted = Math.round((loaded * 100) / total);
            setDownloadProgress(percentCompleted);
          },
        }
      );

      // Create a URL for the blob
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;

      // Check if 'content-disposition' header is present
      const contentDisposition = response.headers["content-disposition"];
      console.log(contentDisposition);
      let fileName = contentDisposition
        ? contentDisposition.split("filename=")[1].replace(/['"]/g, "") // Remove any surrounding quotes
        : "downloadedFile.docx"; // Default filename

      //   // Ensure the file extension is .pdf
      //   if (!fileName.endsWith(".pdf")) {
      //     fileName += ".pdf";
      //   }

      link.setAttribute("download", fileName);

      document.body.appendChild(link);
      link.click();
      link.remove(); // Remove the link after clicking
      window.URL.revokeObjectURL(url); // Clean up the URL
      setDownloadSuccess(true);
    } catch (error) {
      console.error("There was an error downloading the file!", error);
      alert("File download failed, please try again.");
    }
  };

  return (
    <div>
      <h1>Download a File</h1>
      <input
        type="text"
        placeholder="Enter file ID"
        value={fileId}
        onChange={handleInputChange}
      />
      <button onClick={handleFileDownload}>Download</button>
      {downloadProgress > 0 && (
        <progress value={downloadProgress} max="100">
          {downloadProgress}%
        </progress>
      )}
      {downloadSuccess && <p>File downloaded successfully!</p>}
    </div>
  );
};

export default FileDownload;
