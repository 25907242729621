import Headers from "../../../Pages/Header";
import UpdateEmployee from "../UpdateEmployee";

const UpdateEmployeePage = () => {
  return (
    <Headers>
      <UpdateEmployee />
    </Headers>
  );
};
export default UpdateEmployeePage;
