import Headers from "../../../Pages/Header";
import RegisterClient from "../RegisterClient";

const RegisterClientPage = () => {
  return (
    <Headers>
      <RegisterClient />
    </Headers>
  );
};
export default RegisterClientPage;
