import React, { createContext, useState } from 'react';

export const CompanyContext = createContext();

export const CompanyProvider = ({ children }) => {
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedFinancialYear, setSelectedFinancialYear] = useState(null);

  return (
    <CompanyContext.Provider value={{ selectedCompany, setSelectedCompany, selectedFinancialYear, setSelectedFinancialYear }}>
      {children}
    </CompanyContext.Provider>
  );
};
