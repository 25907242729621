import Headers from "../../../Pages/Header";
import GenerateProjectPO from "../GenerateProjectPO";

const GenerateProjectPOPage = () => {
  return (
    <Headers>
      <GenerateProjectPO />
    </Headers>
  );
};
export default GenerateProjectPOPage;
