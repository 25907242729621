import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Tab,
  Tabs,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Collapse,
  Grid,
  TablePagination,
  CircularProgress,
  Backdrop,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import DeleteIcon from "@material-ui/icons/Delete";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Box, IconButton } from "@mui/material";
import { myAxiosCommonHost } from "../../Service/CorsHelperBaseUrl";
import { Autocomplete } from "@mui/material";
import * as XLSX from "xlsx";
import { CompanyContext } from "../StateProvider/CompanyProvider";
import { useParams } from "react-router-dom"; // Import useParams

const UpdateEmpPO = () => {
  const getCurrentFinancialYear = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const fiscalStartYear = currentYear - (currentDate.getMonth() >= 3 ? 0 : 1);
    const fiscalEndYear = fiscalStartYear + 1;
    return `${(fiscalStartYear % 100).toString().padStart(2, "0")}-${(
      fiscalEndYear % 100
    )
      .toString()
      .padStart(2, "0")}`;
  };
  const defaultFinancialYear = getCurrentFinancialYear();
  // const [selectedFinancialYear, setSelectedFinancialYear] =
  //   useState(defaultFinancialYear);
  const [data, setData] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [editedData, setEditedData] = useState(null);
  const [employeeDialogOpen, setEmployeeDialogOpen] = useState(false);
  const [editedEmployeeData, setEditedEmployeeData] = useState(null);
  const [currentTab, setCurrentTab] = useState(0);
  const [expandedRow, setExpandedRow] = useState(null);
  const [addEmployeeDialogOpen, setAddEmployeeDialogOpen] = useState(false);
  const [fetchError, setFetchError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false); // State to control update overlay
  const [isEmployeeAdd, setIsEmployeeAdd] = useState(false); // State to control update overlay
  const [isEmployeeDelete, setIsEmployeeDelete] = useState(false); // State to control update overlay
  const [isEmployeeRelease, setIsEmployeeRelease] = useState(false); // State to control update overlay
  const [isEmployeeUpdating, setIsEmployeeUpdating] = useState(false);
  const [employeePOData, setEmployeePOData] = useState([]);
  const [selectedOption, setSelectedOption] = useState("all");
  const { empPoUniqueNoAndGSCode } = useParams(); // Extract the parameter from the route

  const [newEmployeeData, setNewEmployeeData] = useState({
    poNumber: "",
    financialYear: getCurrentFinancialYear(),
    selectCompany: "",
    clientNameAndCode: "",
    purchaseOrderAmount: "",
    kindAttention: "",
    empPoUniqueNoAndGSCode: "",
    department: "",
    subDepartment: "",
    empPODetailsUI: [
      {
        employeeNameAndCode: "",
        poNotes: "",
        clientNameAndCode: "",
        poAssignDate: "",
        empPORateUI: {
          poPerMonth: "",
          poPerDay: "",
          poPerHour: "",
        },
        empPOAssignDetailsUI: {
          poStartDate: "",
          poEndDate: "",
          poAmount: "",
        },
        empPOClientAssesmentUI: {
          jobLocation: "",
          jobPost: "",
          poDate: "",
          employeeCategory: "",
        },
        empPOAssignFacilityUI: {
          canteenAmount: "",
          transportationAmount: "",
          poNumber: "",
        },
      },
    ],
  });
  const [employeeOptions, setEmployeeOptions] = useState([]);
  const [addedEmployees, setAddedEmployees] = useState([]);
  const [isDataChanged, setIsDataChanged] = useState(false);
  const [isDataChangedEditPO, setIsDataChangedEditPO] = useState(false);
  const [releaseDialogOpen, setReleaseDialogOpen] = useState(false);
  const [releasedEmployeeData, setReleasedEmployeeData] = useState(null);
  const [isReleaseDataChanged, setReleaseIsDataChanged] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [password, setPassword] = useState("");
  const [selectedEmployeeUniqueCode, setSelectedEmployeeUniqueCode] =
    useState("");
  const [page, setPage] = useState(0); // Add page state
  const [rowsPerPage, setRowsPerPage] = useState(10); // Add rowsPerPage state
  const [totalCount, setTotalCount] = useState(0); // Initialize total count state
  const [searchEmpPoUniqueNoAndGSCode, setSearchEmpPoUniqueNoAndGSCode] =
    useState("");
  const [searchClientName, setSearchClientName] = useState("");
  const [financialYear, setFinancialYear] = useState([]);
  // const [selectedCompany, setSelectedCompany] = useState("");
  const { selectedCompany } = useContext(CompanyContext);
  const { selectedFinancialYear } = useContext(CompanyContext);
  const options = [
    { value: "ALL", label: " Download Excel(For All Data)" },
    { value: "FinancialYear", label: " Download Excel FinancialYear Wise" },
    //{ value: "CurrentPage", label: "Download Excel For Current Page" },
  ];
  // useEffect(() => {
  //   async function fetchCompany() {
  //     try {
  //       const response = await myAxiosCommonHost.get(
  //         "gst/profile/getCompanyCodeList"
  //       );
  //       const companies = response.data;
  //       setCompany(companies);
  //       if (companies.length > 0) {
  //         setSelectedCompany(companies[0]); // Set the default company to the first one
  //       }
  //     } catch (error) {
  //       console.error("Error fetching company data:", error);
  //     }
  //   }
  //   fetchCompany();
  // }, []);
  const handleViewPO = async () => {
    try {
      setLoading(true);
      const response = await myAxiosCommonHost.get(
        `gst/employeePo/viewNotification?empPoUniqueNoAndGSCode=${empPoUniqueNoAndGSCode}`
      );
      setData(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error searching employeePo:", error);
      setError(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (empPoUniqueNoAndGSCode) {
      handleViewPO();
    }
  }, [empPoUniqueNoAndGSCode]);

  async function fetchFinancialYear() {
    try {
      const response = await myAxiosCommonHost.get("gst/invoice/getFiscalYear");
      setFinancialYear(response.data);
    } catch (error) {
      console.error("Error fetching financial year data:", error);
    }
  }
  useEffect(() => {
    fetchFinancialYear();
  }, []);
  // const handleFinancialYearSelect = (financialYear) => {
  //   setSelectedFinancialYear(financialYear);
  //   console.log("Selected Financial Year:", financialYear);
  // };
  const fetchEmployeeCount = () => {
    myAxiosCommonHost
      .get(
        `gst/employeePo/getEmployeePOCount?selectCompany=${selectedCompany}&financialYear=${selectedFinancialYear}`
      )
      .then((response) => {
        const count = parseInt(response.data);
        setTotalCount(count);
      })
      .catch((error) => {
        console.error("Error fetching employee count:", error);
      });
  };
  useEffect(() => {
    myAxiosCommonHost
      .get(`gst/employee/getEmployeePONameListCompanyWise?selectCompany=${selectedCompany}`)
      .then((response) => {
        setEmployeeOptions(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.error("Error fetching client data:", error);
      });
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const limit = rowsPerPage;
        const offset = page * rowsPerPage;
        const response = await myAxiosCommonHost.get(
          `gst/employeePo/getEmployeePO?selectCompany=${selectedCompany}&financialYear=${selectedFinancialYear}&limit=${limit}&offset=${offset}`
        );
        setData(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(error);
        setLoading(false);
      }
    };
    if (selectedCompany && selectedFinancialYear && !empPoUniqueNoAndGSCode) {
      fetchEmployeeCount();
      fetchData();
    }
  }, [
    selectedCompany,
    selectedFinancialYear,
    empPoUniqueNoAndGSCode,
    rowsPerPage,
    page,
  ]);
  const handleDownloadExcel = async () => {
    const baseUrl = "gst/employeePo/getEmployeePOListForExcel";
    let url = "";
    const limit = rowsPerPage;
    const offset = page * rowsPerPage;

    if (selectedOption?.value === "ALL") {
      url = `${baseUrl}?excelFilterType=ALL&selectCompany=${selectedCompany}`;
    } else if (selectedOption?.value === "FinancialYear") {
      url = `${baseUrl}?excelFilterType&selectCompany=${selectedCompany}&financialYear=${selectedFinancialYear}`;
    }
    // else if (selectedOption?.value === "CurrentPage") {
    //   url = `${baseUrl}?excelFilterType&selectCompany=${selectedCompany}&financialYear=${selectedFinancialYear}&limit=${limit}&offset=${offset}`;
    // }
    try {
      const response = await myAxiosCommonHost.get(url);
      setEmployeePOData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    if (employeePOData) {
      downloadExcel(employeePOData);
    }
  }, [employeePOData]);
  const handleAddEmployeeClick = (po) => {
    setNewEmployeeData({
      ...newEmployeeData,
      poNumber: po.poNumber,
      financialYear: po.financialYear,
      selectCompany: po.selectCompany,
      clientNameAndCode: po.clientNameAndCode,
      purchaseOrderAmount: po.purchaseOrderAmount,
      empPoUniqueNoAndGSCode: po.empPoUniqueNoAndGSCode,
      kindAttention: po.kindAttention,
      department: po.department,
      subDepartment: po.subDepartment,
    });
    setAddEmployeeDialogOpen(true);
  };
  const handleAddEmployeeDialogClose = () => {
    setAddEmployeeDialogOpen(false);
  };
  const handleEditClick = (editedData) => {
    setEditedData(editedData);
    setOpenDialog(true);
  };
  // const handleCompanySelect = (company) => {
  //   setSelectedCompany(company);
  //   console.log("Selected Company Year:", company);
  // };
  const handleEmployeeEditClick = (editedData, employeeData) => {
    setEditedData(editedData);
    setEditedEmployeeData(employeeData);
    setEmployeeDialogOpen(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleAddToTable = () => {
    const isDuplicateEmployee = addedEmployees.some(
      (employee) =>
        employee.empPODetailsUI[0].employeeNameAndCode.trim() ===
        newEmployeeData.empPODetailsUI[0].employeeNameAndCode.trim()
    );
    if (isDuplicateEmployee) {
      alert("Duplicate entry! Cannot Add The Same Employee.");
      return;
    }
    const isEmpPORateUIFilled =
      newEmployeeData.empPODetailsUI[0].empPORateUI.poPerMonth.trim() !== "" ||
      newEmployeeData.empPODetailsUI[0].empPORateUI.poPerDay.trim() !== "" ||
      newEmployeeData.empPODetailsUI[0].empPORateUI.poPerHour.trim() !== "";

    if (!isEmpPORateUIFilled) {
      alert("Please fill at least one field in PO Rate.");
      return;
    }

    // Check if required fields are filled for empPODetailsUI
    const empPODetailsUIFilled =
      newEmployeeData.empPODetailsUI[0].employeeNameAndCode.trim() !== "" &&
      // newEmployeeData.empPODetailsUI[0].poNotes.trim() !== "" &&
      newEmployeeData.empPODetailsUI[0].poAssignDate.trim() !== "";

    if (!empPODetailsUIFilled) {
      alert("Please fill Employee, Assign Date Before Adding.");
      return;
    }

    // Add data to the table
    setAddedEmployees([...addedEmployees, newEmployeeData]);

    // Reset newEmployeeData
    setNewEmployeeData({
      empPODetailsUI: [
        {
          employeeNameAndCode: "",
          poNotes: "",
          clientNameAndCode: "",
          poAssignDate: "",
          empPORateUI: {
            poPerMonth: "",
            poPerDay: "",
            poPerHour: "",
          },
          empPOAssignDetailsUI: {
            poStartDate: "",
            poEndDate: "",
            poAmount: "",
          },
          empPOClientAssesmentUI: {
            jobLocation: "",
            jobPost: "",
            poDate: "",
            employeeCategory: "",
          },
          empPOAssignFacilityUI: {
            canteenAmount: "",
            transportationAmount: "",
            poNumber: "",
          },
        },
      ],
    });
  };

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };
  const handleExpandClick = (index) => {
    setExpandedRow(expandedRow === index ? null : index);
  };
  const handleDeleteRow = (index) => {
    const updatedEmployees = [...addedEmployees];
    updatedEmployees.splice(index, 1);
    setAddedEmployees(updatedEmployees);
  };
  const handleEmployeeDialogClose = () => {
    setEmployeeDialogOpen(false);
  };
  const handleFieldChange = (fieldName, value) => {
    setEditedData({
      ...editedData,
      [fieldName]: value,
    });
    setIsDataChangedEditPO(true);
  };
  const handleAddNewEmployee = async () => {
    if (addedEmployees.length === 0) {
      alert("Cannot Submit Without Adding An Employee To The Table");
      return;
    }
    const requestData = {
      poNumber: addedEmployees[0].poNumber,
      financialYear: addedEmployees[0].financialYear,
      selectCompany: addedEmployees[0].selectCompany,
      clientNameAndCode: addedEmployees[0].clientNameAndCode,
      purchaseOrderAmount: addedEmployees[0].purchaseOrderAmount,
      empPoUniqueNoAndGSCode: addedEmployees[0].empPoUniqueNoAndGSCode,
      kindAttention: addedEmployees[0].kindAttention,
      department: addedEmployees[0].department,
      subDepartment: addedEmployees[0].subDepartment,
      empPODetailsUI: addedEmployees.flatMap((employee) =>
        employee.empPODetailsUI.map((empDetails) => ({
          employeeNameAndCode: empDetails.employeeNameAndCode,
          poNotes: empDetails.poNotes,
          clientNameAndCode: empDetails.clientNameAndCode,
          poAssignDate: empDetails.poAssignDate,
          empPORateUI: { ...empDetails.empPORateUI },
          empPOAssignDetailsUI: { ...empDetails.empPOAssignDetailsUI },
          empPOClientAssesmentUI: { ...empDetails.empPOClientAssesmentUI },
          empPOAssignFacilityUI: { ...empDetails.empPOAssignFacilityUI },
        }))
      ),
    };

    console.log("Request Data:", JSON.stringify(requestData, null, 2));
    setIsEmployeeAdd(true);
    try {
      const updateResponse = await myAxiosCommonHost.put(
        "gst/employeePo/updatePO",
        requestData
      );

      if (updateResponse.status === 200) {
        console.log("Update Response:", updateResponse.data);
        const limit = rowsPerPage;
        const offset = page * rowsPerPage;
        // Fetch updated data after successful addition
        const fetchDataResponse = await myAxiosCommonHost.get(
          `gst/employeePo/getEmployeePO?selectCompany=${selectedCompany}&financialYear=${selectedFinancialYear}&limit=${limit}&offset=${offset}`
        );

        if (fetchDataResponse.status === 200) {
          const updatedResult = await fetchDataResponse.data;
          setData(updatedResult);
          setEditedEmployeeData(updatedResult);
          setEditedData(updatedResult);
        } else {
          console.error("Failed to fetch updated data.");
        }

        setAddEmployeeDialogOpen(false);
      } else {
        console.error("Failed to update data.");
        alert("Error adding employee. Please try again.");
      }
    } catch (error) {
      console.error("Error sending or fetching data from the backend:", error);
      alert("Error adding employee. Please try again.");
    }
    setIsEmployeeAdd(false);
  };

  const handleUpdateData = async () => {
    setIsUpdating(true);
    try {
      const updatedData = {
        ...editedData,
        empPODetailsUI: [editedEmployeeData],
      };
      const updateResponse = await myAxiosCommonHost.put(
        "gst/employeePo/updatePO",
        updatedData
      );

      if (updateResponse.status === 200) {
        console.log("Successfully updated data:", updatedData);
        const limit = rowsPerPage;
        const offset = page * rowsPerPage;
        // Fetch updated data after successful addition
        const fetchDataResponse = await myAxiosCommonHost.get(
          `gst/employeePo/getEmployeePO?selectCompany=${selectedCompany}&financialYear=${selectedFinancialYear}&limit=${limit}&offset=${offset}`
        );
        if (fetchDataResponse.status === 200) {
          const updatedResult = fetchDataResponse.data;
          setData(updatedResult);
        } else {
          console.error("Failed to fetch updated data.");
        }
        setOpenDialog(false);
        setEmployeeDialogOpen(false);
      } else {
        console.error("Failed to update data.");
        alert("Error updating data. Please try again.");
      }
    } catch (error) {
      console.error("Error updating data:", error);
      alert("Error updating data. Please try again.");
    }
    setIsUpdating(false);
  };
  const handleEmployeeUpdateData = async () => {
    setIsEmployeeUpdating(true);
    try {
      const updatedData = {
        ...editedData,
        empPODetailsUI: [editedEmployeeData],
      };
      console.log("Successfully updated data:", updatedData);
      const updateResponse = await myAxiosCommonHost.put(
        "gst/employeePo/updatePO",
        updatedData
      );

      if (updateResponse.status === 200) {
        console.log("Successfully updated data:", updatedData);
        const limit = rowsPerPage;
        const offset = page * rowsPerPage; // Correct offset calculation
        // Fetch updated data after successful addition
        const fetchDataResponse = await myAxiosCommonHost.get(
          `gst/employeePo/getEmployeePO?selectCompany=${selectedCompany}&financialYear=${selectedFinancialYear}&limit=${limit}&offset=${offset}`
          // const fetchDataResponse = await myAxiosCommonHost.get(
          //   "gst/employeePo/getEmployeePO"
        );
        if (fetchDataResponse.status === 200) {
          const updatedResult = fetchDataResponse.data;
          setData(updatedResult);
        } else {
          console.error("Failed to fetch updated data.");
        }
        setOpenDialog(false);
        setEmployeeDialogOpen(false);
      } else {
        console.error("Failed to update data.");
        alert("Error updating data. Please try again.");
      }
    } catch (error) {
      console.error("Error updating data:", error);
      alert("Error updating data. Please try again.");
    }
    setIsEmployeeUpdating(false);
  };
  const handleDeleteClick = async (empPoUniqueNoAndGSCode) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this record?"
    );
    if (confirmDelete) {
      setIsDeleting(true);
      await handleDeleteButtonClick(empPoUniqueNoAndGSCode);
      setIsDeleting(false);
    }
  };
  const resetNewData = () => {
    setNewEmployeeData({
      poNumber: newEmployeeData.poNumber,
      clientNameAndCode: newEmployeeData.clientNameAndCode,
      purchaseOrderAmount: newEmployeeData.purchaseOrderAmount,
      kindAttention: newEmployeeData.kindAttention,
      empPoUniqueNoAndGSCode: newEmployeeData.empPoUniqueNoAndGSCode,
      department: newEmployeeData.department,
      subDepartment: newEmployeeData.subDepartment,
      empPODetailsUI: [
        {
          employeeNameAndCode: "",
          poNotes: "",
          clientNameAndCode: "",
          poAssignDate: "",
          empPORateUI: {
            poPerMonth: "",
            poPerDay: "",
            poPerHour: "",
          },
          empPOAssignDetailsUI: {
            poStartDate: "",
            poEndDate: "",
            poAmount: "",
          },
          empPOClientAssesmentUI: {
            jobLocation: "",
            jobPost: "",
            poDate: "",
            employeeCategory: "",
          },
          empPOAssignFacilityUI: {
            canteenAmount: "",
            transportationAmount: "",
            poNumber: "",
          },
        },
      ],
    });
  };

  const handleReleaseEmployeeClick = (po, employee) => {
    if (employee && employee.empPOReleaseUI) {
      const {
        clientName,
        // employeeName,
        // employeeCode,
        clientNameAndCode,
        employeeNameAndCode,
        releaseDateNotes,
        empPoUniqueNoAndGSCode,
        poNumber,
        releaseDate,
      } = employee.empPOReleaseUI;
      console.log(JSON.stringify(employee.empPOReleaseUI, null, 2)); // Log release data
      setReleasedEmployeeData({
        clientName: clientName || "",
        // employeeName: employeeName || "",
        // employeeCode: employeeCode || "",
        clientNameAndCode: clientNameAndCode || "",
        employeeNameAndCode: employeeNameAndCode || "",
        empPoUniqueNoAndGSCode: empPoUniqueNoAndGSCode || "",
        releaseDate: releaseDate || "",
        poNumber: poNumber || "",
        releaseDateNotes: releaseDateNotes || "",
      });
      setReleaseDialogOpen(true);
    } else {
      console.error("empPOReleaseUI data not found in employee:", employee);
    }
  };
  const handleUpdateReleasedEmployee = async () => {
    console.log(JSON.stringify(releasedEmployeeData, null, 2));
    setIsEmployeeRelease(true);
    try {
      const updateResponse = await myAxiosCommonHost.post(
        "gst/employeePo/getEmployeeReleaseUpdate",
        releasedEmployeeData
      );
      if (updateResponse.status === 200) {
        console.log(
          "Successfully updated released employee data:",
          updateResponse.data
        );
        const limit = rowsPerPage;
        const offset = page * rowsPerPage;
        const fetchDataResponse = await myAxiosCommonHost.get(
          `gst/employeePo/getEmployeePO?selectCompany=${selectedCompany}&financialYear=${selectedFinancialYear}&limit=${limit}&offset=${offset}`
        );
        if (fetchDataResponse.status === 200) {
          const updatedData = fetchDataResponse.data;
          setData(updatedData);
          setEditedData(updatedData);
        } else {
          console.error("Failed to fetch updated data.");
        }
      } else {
        console.error("Failed to update released employee data.");
        alert("Error updating data. Please try again.");
      }
    } catch (error) {
      console.error("Error updating released employee data:", error);
      alert("Error updating data. Please try again.");
    }
    setIsEmployeeRelease(false);
    setReleaseDialogOpen(false);
  };
  const handleDeleteEmployee = async (employeeData) => {
    setIsEmployeeDelete(true);
    try {
      if (employeeData && employeeData.empPOReleaseUI) {
        const empPOReleaseUI = employeeData.empPOReleaseUI;
        const deleteData = {
          clientNameAndCode: empPOReleaseUI.clientNameAndCode,
          employeeNameAndCode: empPOReleaseUI.employeeNameAndCode,
          employeeCode: empPOReleaseUI.employeeCode,
          poNumber: empPOReleaseUI.poNumber,
          empPoUniqueNoAndGSCode: empPOReleaseUI.empPoUniqueNoAndGSCode,
          clientName: empPOReleaseUI.clientName,
        };
        const confirmDelete = window.confirm(
          "Are you sure you want to delete this employee record?"
        );
        if (confirmDelete) {
          // Perform the POST request to delete the employee data using Axios
          const response = await myAxiosCommonHost.post(
            "gst/employeePo/deleteEmployeePoPerName",
            deleteData
          );
          console.log(JSON.stringify(deleteData, null, 2));

          if (response.status === 200) {
            const limit = rowsPerPage;
            const offset = page * rowsPerPage;
            const updatedDataResponse = await myAxiosCommonHost.get(
              `gst/employeePo/getEmployeePO?selectCompany=${selectedCompany}&financialYear=${selectedFinancialYear}&limit=${limit}&offset=${offset}`
            );
            setData(updatedDataResponse.data);
            setEditedData(updatedDataResponse.data);
            console.log("Employee data deleted successfully:", deleteData);
          } else {
            console.error("Failed to delete employee data.");
            alert("Error deleting data. Please try again.");
          }
        }
      } else {
        console.error(
          "Employee data or empPOReleaseUI is missing:",
          employeeData
        );
        alert("Error deleting data. Please try again.");
      }
    } catch (error) {
      console.error("Error deleting employee data:", error);
      alert("Error deleting data. Please try again.");
    }
    setIsEmployeeDelete(false);
  };
  if (fetchError) {
    return <div>Error fetching data. Please try again later.</div>;
  }
  const handleDeleteButtonClick = async (empPoUniqueNoAndGSCode) => {
    setSelectedEmployeeUniqueCode(empPoUniqueNoAndGSCode);
    setDeleteDialogOpen(true);
  };
  const handleDeleteConfirm = async () => {
    setIsDeleting(true);
    try {
      const response = await myAxiosCommonHost.get(
        `gst/login/verificationForDeletionRecord?userPassword=${password}`
      );
      if (response.status === 200 && response.data) {
        const confirmDelete = window.confirm(
          "Password verified. Are You Want to Delete This Record..."
        );
        console.log("Password verified. Deleting record...");
        if (confirmDelete) {
          const deleteResponse = await myAxiosCommonHost.delete(
            `gst/employeePo/deleteEmployeePo/uniqueEmployeeAndPoNo/${selectedEmployeeUniqueCode}`
          );

          if (deleteResponse.status === 200) {
            console.log("PO deleted successfully!");
            const limit = rowsPerPage;
            const offset = page * rowsPerPage;
            const updatedDataResponse = await myAxiosCommonHost.get(
              `gst/employeePo/getEmployeePO?selectCompany=${selectedCompany}&financialYear=${selectedFinancialYear}&limit=${limit}&offset=${offset}`
            );
            if (updatedDataResponse.status === 200) {
              const updatedResult = await updatedDataResponse.data;
              setData(updatedResult);
              setEditedData(updatedResult);
            } else {
              console.error("Failed to fetch updated data.");
            }
            setDeleteDialogOpen(false);
          } else {
            console.error("Failed to delete PO.");
            alert("Failed to delete PO. Please try again.");
          }
        } else {
          console.error("Failed to verify password.");
          alert("Failed to verify password. Deletion canceled.");
        }
      }
    } catch (error) {
      console.error("Error deleting PO:", error);
      alert("An error occurred while deleting the PO.");
    }
    setIsDeleting(false);
    setDeleteDialogOpen(false);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleSearch = async () => {
    try {
      setLoading(true);
      const limit = rowsPerPage;
      const offset = page * rowsPerPage;
      const response = await myAxiosCommonHost.get(
        `gst/employeePo/searchEmployeesPO?clientName=${searchClientName}&empPoUniqueNoAndGSCode=${searchEmpPoUniqueNoAndGSCode}&selectCompany=${selectedCompany}&limit=${limit}&offset=${offset}`
      );
      setData(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error searching employeePo:", error);
      setError(error);
      setLoading(false);
    }
  };

  const handleChange = (event, newValue) => {
    setSelectedOption(newValue);
  };
  const downloadExcel = async (employeePOData) => {
    // Assuming selectedOption, selectedCompany, selectedFinancialYear, limit, and offset are available in the current scope
    let filename;
    if (selectedOption?.value === "ALL") {
      filename = `EmployeePO_ALL_${selectedCompany}.xlsx`;
    } else if (selectedOption?.value === "FinancialYear") {
      filename = `EmployeePO_FinancialYear_Wise_${selectedCompany}_${selectedFinancialYear}.xlsx`;
    }
    // else if (selectedOption?.value === "CurrentPage") {
    //   filename = `EmployeePO_CurrentPage_${selectedCompany}_${selectedFinancialYear}.xlsx`;
    // }
    else {
      filename = `EmployeePO_${selectedCompany}.xlsx`; // Default filename if no option matches
    }
    // Ensure that employeePOData and its properties are defined
    if (
      !employeePOData ||
      !employeePOData.empPoHeaderExcelList ||
      !employeePOData.empPoDeatailExcelList
    ) {
      console.error(
        "employeePOData is undefined or missing required properties"
      );
      return;
    }
    const empPoHeaderExcelList = employeePOData.empPoHeaderExcelList.map(
      (item) => ({
        "PO Number": item.poNumber,
        "Client Name": item.clientName,
        "Client Name And Code": item.clientNameAndCode,
        "Client Code": item.clientCode,
        "Kind Attention": item.kindAttention,
        "Purchase Order Amount": item.purchaseOrderAmount,
        "Emp PoUniqueNo And GSCode": item.empPoUniqueNoAndGSCode,
        Department: item.department,
        SubDepartment: item.subDepartment,
        "Financial Year": item.financialYear,
        "Select Company": item.selectCompany,
      })
    );
    // Prepare the projectPoDetails data
    const empPoDeatailExcelList = employeePOData.empPoDeatailExcelList.map(
      (item) => ({
        "Employee Name": item.employeeName,
        "Employee Code": item.employeeCode,
        "Client Name": item.clientName,
        "Po Notes": item.poNotes,
        "Po Assign Date": item.poAssignDate,
        "Po Number": item.poNumber,
        "Purchase Order Amount": item.purchaseOrderAmount,
        "Emp PoUniqueNo And GS Code": item.empPoUniqueNoAndGSCode,
        "Po Start Date": item.poStartDate,
        "Po End Date": item.poEndDate,
        "Po Amount": item.poAmount,
        "Canteen Amount": item.canteenAmount,
        "Transportation Amount": item.transportationAmount,
        "Job Location": item.jobLocation,
        "Job Post": item.jobPost,
        "Po Date": item.poDate,
        "Employee Category": item.employeeCategory,
        "Release Date Notes": item.releaseDateNotes,
        "isRelease Employee": item.isReleaseEmployee,
        "Release Date": item.releaseDate,
        "Po Per Month": item.poPerMonth,
        "Po Per Hour": item.poPerHour,
        "Po Per Day": item.poPerDay,
        "Client Code": item.clientCode,
      })
    );

    // Create a new workbook
    const workbook = XLSX.utils.book_new();

    // Add the main quotation data worksheet
    const empPoHeaderExcelListsheet =
      XLSX.utils.json_to_sheet(empPoHeaderExcelList);
    XLSX.utils.book_append_sheet(
      workbook,
      empPoHeaderExcelListsheet,
      "empPoHeaderExcelList"
    );

    // Add the accommodation data worksheet
    const empPoDeatailExcelListWorksheet = XLSX.utils.json_to_sheet(
      empPoDeatailExcelList
    );
    XLSX.utils.book_append_sheet(
      workbook,
      empPoDeatailExcelListWorksheet,
      "empPoDeatailExcelList"
    );

    // Generate a Blob from the workbook
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Create a download link and click it programmatically
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  return (
    <div>
      {isDeleting && (
        <div className="blur-effect">
          <CircularProgress color="primary" />
          <div className="loading-message">PO is Deleting...</div>
        </div>
      )}
      {isUpdating && (
        <div className="blur-effect">
          <CircularProgress color="primary" />
          <div className="loading-message">PO is Updating...</div>
        </div>
      )}
      {isEmployeeAdd && (
        <div className="blur-effect">
          <CircularProgress color="primary" />
          <div className="loading-message">Employee is Adding...</div>
        </div>
      )}
      {isEmployeeDelete && (
        <div className="blur-effect">
          <CircularProgress color="primary" />
          <div className="loading-message">Employee is Deleting...</div>
        </div>
      )}
      {isEmployeeRelease && (
        <div className="blur-effect">
          <CircularProgress color="primary" />
          <div className="loading-message">Employee is Releasing...</div>
        </div>
      )}
      {isEmployeeUpdating && (
        <div className="blur-effect">
          <CircularProgress color="primary" />
          <div className="loading-message">Updating Employee...</div>
        </div>
      )}
      <Paper
        elevation={3}
        style={{ padding: 20, marginBottom: 20, marginTop: 20 }}
      >
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <Link to="/generateEmployeePO" style={{ textDecoration: "none" }}>
              <Button
                variant="contained"
                color="secondary"
                style={{ marginTop: 20, marginRight: 10, marginBottom: "20px" }}
              >
                Generate Employee PO
              </Button>
            </Link>
            <Button
              variant="contained"
              color="secondary"
              style={{ margin: "10px" }}
            >
              PO Count {totalCount}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                label="Client Name"
                value={searchClientName}
                onChange={(e) => setSearchClientName(e.target.value)}
                variant="standard"
                style={{ float: "right", margin: "10px" }}
              />
              <TextField
                label="Emp Po UniqueNo"
                value={searchEmpPoUniqueNoAndGSCode}
                onChange={(e) =>
                  setSearchEmpPoUniqueNoAndGSCode(e.target.value)
                }
                variant="standard"
                style={{ marginRight: "10px" }}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleSearch}
              >
                Search
              </Button>
            </Box>
            <Box my={4}>
              <Autocomplete
                options={options}
                getOptionLabel={(option) => option.label}
                onChange={handleChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Option"
                    variant="standard"
                  />
                )}
              />
              <Box mt={2}>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "green", color: "white" }}
                  onClick={handleDownloadExcel}
                >
                  Download Excel
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Paper
          elevation={3}
          style={{ padding: 20, marginBottom: 20, marginTop: 20 }}
        >
          <Grid container spacing={2}>
            <Grid item xs={2} md={4}>
              {/* <Autocomplete
                id="financial-year"
                sx={{ width: 200 }}
                options={financialYear}
                loading={loading}
                value={selectedFinancialYear}
                onChange={(event, newValue) =>
                  handleFinancialYearSelect(newValue)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Financial Year"
                    variant="standard"
                    style={{ backgroundColor: "white", marginTop: "20px" }}
                  />
                )}
              /> */}
              <TextField
                label="Financial Year"
                value={selectedFinancialYear}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                disabled
                variant="standard"
                style={{ backgroundColor: "white", marginTop: "20px" }}
              />
            </Grid>
            <Grid item xs={2} md={4}>
              {/* <Autocomplete
                id="company"
                sx={{ width: 200 }}
                options={company}
                loading={loading}
                value={selectedCompany}
                onChange={(event, newValue) => handleCompanySelect(newValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Company"
                    variant="standard"
                    style={{ backgroundColor: "white", marginTop: "20px" }}
                  />
                )}
              /> */}
              <TextField
                label="Select Company"
                value={selectedCompany}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                disabled
                variant="standard"
                style={{ backgroundColor: "white", marginTop: "20px" }}
              />
            </Grid>
          </Grid>
        </Paper>
      </Paper>
      <Paper
        elevation={3}
        style={{
          padding: 20,
          marginBottom: 20,
          backgroundColor: "#2196f3",
          color: "white",
          padding: "10px",
          textAlign: "center",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography
            variant="h5"
            gutterBottom
            style={{ marginBottom: "15px", color: "darkblue" }}
          >
            Employee Purchase Order
          </Typography>
        </div>
      </Paper>
      {error ? (
        <p>No Record Found</p>
      ) : (
        <TableContainer component={Paper} style={{ height: "20%" }}>
          {loading ? (
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={loading}
            >
              <CircularProgress color="inherit" />
              <Typography
                variant="h6"
                color="inherit"
                style={{ marginLeft: "10px" }}
              >
                Getting Data...
              </Typography>
            </Backdrop>
          ) : (
            <>
              <Grid item xs={12}>
                <Paper>
                  <Table>
                    <TableHead>
                      <TableRow
                        style={{
                          backgroundColor: "darkcyan",
                          fontWeight: "bold",
                          color: "darkblue",
                        }}
                      >
                        <TableCell />
                        <TableCell>PO Number</TableCell>
                        <TableCell>Client Name</TableCell>
                        <TableCell>Purchase Order Amount</TableCell>
                        <TableCell>Po Unique Number</TableCell>
                        <TableCell>Kind Attention</TableCell>
                        <TableCell>Department</TableCell>
                        <TableCell></TableCell>
                        <TableCell>Action</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.length === 0 ? (
                        <TableRow>
                          <TableCell colSpan={9} align="center">
                            No Employee PO found
                          </TableCell>
                        </TableRow>
                      ) : (
                        data.map((po, index) => (
                          <React.Fragment key={index}>
                            <TableRow>
                              <TableCell>
                                <IconButton
                                  onClick={() => handleExpandClick(index)}
                                  aria-expanded={expandedRow === index}
                                  aria-label="show more"
                                >
                                  <ExpandMoreIcon />
                                </IconButton>
                              </TableCell>
                              <TableCell>{po.poNumber}</TableCell>
                              <TableCell>{po.clientNameAndCode}</TableCell>
                              <TableCell>{po.purchaseOrderAmount}</TableCell>
                              <TableCell>{po.empPoUniqueNoAndGSCode}</TableCell>
                              <TableCell>{po.kindAttention}</TableCell>
                              <TableCell>{po.department}</TableCell>
                              <TableCell>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={() => handleEditClick(po)}
                                >
                                  Update PO
                                </Button>
                              </TableCell>
                              <TableCell>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={() => handleAddEmployeeClick(po)}
                                >
                                  Add Employee
                                </Button>
                              </TableCell>
                              <TableCell>
                                <Button
                                  variant="contained"
                                  color="secondary"
                                  onClick={() =>
                                    handleDeleteClick(po.empPoUniqueNoAndGSCode)
                                  }
                                  style={{
                                    backgroundColor: "red",
                                    color: "white",
                                  }}
                                >
                                  Delete PO
                                </Button>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell colSpan={5}>
                                <Collapse
                                  in={expandedRow === index}
                                  timeout="auto"
                                  unmountOnExit
                                >
                                  {(po.empPODetailsUI || []).map(
                                    (employee, empIndex) => (
                                      <Accordion key={empIndex}>
                                        <AccordionSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls={`panel${index}-content`}
                                          id={`panel${index}-header`}
                                          IconButtonProps={{ edge: "start" }}
                                        >
                                          <Typography>
                                            {"Employee Name And Code-:"}
                                            {
                                              po.empPODetailsUI[empIndex]
                                                .employeeName
                                            }
                                          </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                          <Table style={{ width: "100%" }}>
                                            <TableBody>
                                              <TableRow
                                                style={{
                                                  backgroundColor: "goldenrod",
                                                  fontWeight: "bold",
                                                  color: "darkblue",
                                                }}
                                              >
                                                <TableCell>
                                                  Employee Name
                                                </TableCell>
                                                <TableCell>
                                                  Client Name
                                                </TableCell>
                                                <TableCell>PO Notes</TableCell>
                                                <TableCell>
                                                  PO Assign Date
                                                </TableCell>
                                                <TableCell></TableCell>
                                                <TableCell>Action</TableCell>
                                                <TableCell></TableCell>
                                              </TableRow>
                                              {employee ? (
                                                <TableRow style={{
                                                  backgroundColor:
                                                    employee?.empPOReleaseUI.isReleaseEmployee === true
                                                      ? "lightblue"
                                                      : "inherit",
                                                }}>
                                                  <TableCell>
                                                    {employee?.employeeName ||
                                                      "N/A"}
                                                  </TableCell>
                                                  <TableCell>
                                                    {employee?.clientName ||
                                                      "N/A"}
                                                  </TableCell>
                                                  <TableCell>
                                                    {employee?.poNotes || "N/A"}
                                                  </TableCell>
                                                  <TableCell>
                                                    {employee?.poAssignDate
                                                      .split("-")
                                                      .reverse()
                                                      .join("-") || "N/A"}
                                                  </TableCell>
                                                  <TableCell>
                                                    <Button
                                                      variant="contained"
                                                      color="primary"
                                                      onClick={() =>
                                                        handleEmployeeEditClick(
                                                          po,
                                                          employee
                                                        )
                                                      }
                                                    >
                                                      Update
                                                    </Button>
                                                  </TableCell>
                                                  <TableCell>
                                                    <Button
                                                      variant="contained"
                                                      color="secondary"
                                                      onClick={() =>
                                                        handleDeleteEmployee(
                                                          employee
                                                        )
                                                      }
                                                    >
                                                      Delete
                                                    </Button>
                                                  </TableCell>
                                                  <TableCell>
                                                    <Button
                                                      variant="contained"
                                                      color="primary"
                                                      onClick={() =>
                                                        handleReleaseEmployeeClick(
                                                          po,
                                                          employee
                                                        )
                                                      }
                                                    >
                                                      Release
                                                    </Button>
                                                  </TableCell>
                                                </TableRow>
                                              ) : (
                                                <TableRow>
                                                  <TableCell
                                                    colSpan={4}
                                                    align="center"
                                                  >
                                                    No Record Found
                                                  </TableCell>
                                                </TableRow>
                                              )}
                                              <TableRow>
                                                <TableCell colSpan={6}>
                                                  <Collapse
                                                    in={expandedRow === index}
                                                    timeout="auto"
                                                    unmountOnExit
                                                  >
                                                    <Box
                                                      style={{ padding: 20 }}
                                                    >
                                                      <Typography variant="subtitle1">
                                                        Employee Details
                                                      </Typography>

                                                      <TableRow
                                                        style={{
                                                          backgroundColor:
                                                            "darkcyan",
                                                          fontWeight: "bold",
                                                          color: "darkblue",
                                                        }}
                                                      >
                                                        <TableCell>
                                                          Employee Name
                                                        </TableCell>
                                                        <TableCell>
                                                          Client Name
                                                        </TableCell>
                                                        <TableCell>
                                                          PO Notes
                                                        </TableCell>

                                                        <TableCell>
                                                          PO Assign Date
                                                        </TableCell>
                                                      </TableRow>
                                                      {employee ? (
                                                        <TableRow>
                                                          <TableCell>
                                                            {employee?.employeeNameAndCode ||
                                                              "N/A"}
                                                          </TableCell>
                                                          <TableCell>
                                                            {employee?.clientNameAndCode ||
                                                              "N/A"}
                                                          </TableCell>
                                                          <TableCell>
                                                            {employee?.poNotes ||
                                                              "N/A"}
                                                          </TableCell>

                                                          <TableCell>
                                                            {employee?.poAssignDate
                                                              .split("-")
                                                              .reverse()
                                                              .join("-") ||
                                                              "N/A"}
                                                          </TableCell>
                                                        </TableRow>
                                                      ) : (
                                                        <TableRow>
                                                          <TableCell
                                                            colSpan={4}
                                                            align="center"
                                                          >
                                                            No Record Found
                                                          </TableCell>
                                                        </TableRow>
                                                      )}

                                                      <Typography variant="subtitle1">
                                                        Rate Details
                                                      </Typography>
                                                      <TableRow
                                                        style={{
                                                          backgroundColor:
                                                            "darkcyan",
                                                          fontWeight: "bold",
                                                          color: "darkblue",
                                                        }}
                                                      >
                                                        <TableCell>
                                                          PO Per Month
                                                        </TableCell>
                                                        <TableCell>
                                                          PO Per Day
                                                        </TableCell>
                                                        <TableCell>
                                                          PO Per Hour
                                                        </TableCell>
                                                      </TableRow>
                                                      {employee.empPORateUI ? (
                                                        <TableRow>
                                                          <TableCell>
                                                            {employee
                                                              .empPORateUI
                                                              .poPerMonth ||
                                                              "N/A"}
                                                          </TableCell>
                                                          <TableCell>
                                                            {employee
                                                              .empPORateUI
                                                              .poPerDay ||
                                                              "N/A"}
                                                          </TableCell>
                                                          <TableCell>
                                                            {employee
                                                              .empPORateUI
                                                              .poPerHour ||
                                                              "N/A"}
                                                          </TableCell>
                                                        </TableRow>
                                                      ) : (
                                                        <TableRow>
                                                          <TableCell
                                                            colSpan={3}
                                                          >
                                                            No Rate Details
                                                            Available
                                                          </TableCell>
                                                        </TableRow>
                                                      )}

                                                      <Typography variant="subtitle1">
                                                        Assignment Details
                                                      </Typography>
                                                      <TableRow
                                                        style={{
                                                          backgroundColor:
                                                            "darkcyan",
                                                          fontWeight: "bold",
                                                          color: "darkblue",
                                                        }}
                                                      >
                                                        <TableCell>
                                                          PO Start Date
                                                        </TableCell>
                                                        <TableCell>
                                                          PO End Date
                                                        </TableCell>
                                                        <TableCell>
                                                          PO Amount
                                                        </TableCell>
                                                      </TableRow>
                                                      {employee.empPOAssignDetailsUI ? (
                                                        <TableRow>
                                                          <TableCell>
                                                            {employee
                                                              .empPOAssignDetailsUI
                                                              .poStartDate
                                                              ? employee.empPOAssignDetailsUI.poStartDate
                                                                  .split("-")
                                                                  .reverse()
                                                                  .join("-")
                                                              : "N/A"}
                                                          </TableCell>
                                                          <TableCell>
                                                            {employee
                                                              .empPOAssignDetailsUI
                                                              .poEndDate
                                                              ? employee.empPOAssignDetailsUI.poEndDate
                                                                  .split("-")
                                                                  .reverse()
                                                                  .join("-")
                                                              : "N/A"}
                                                          </TableCell>
                                                          <TableCell>
                                                            {
                                                              employee
                                                                .empPOAssignDetailsUI
                                                                .poAmount
                                                            }
                                                          </TableCell>
                                                        </TableRow>
                                                      ) : (
                                                        <TableRow>
                                                          <TableCell
                                                            colSpan={3}
                                                          >
                                                            No Rate Details
                                                            Available
                                                          </TableCell>
                                                        </TableRow>
                                                      )}

                                                      <Typography variant="subtitle1">
                                                        Client Assessment
                                                      </Typography>
                                                      <TableRow
                                                        style={{
                                                          backgroundColor:
                                                            "darkcyan",
                                                          fontWeight: "bold",
                                                          color: "darkblue",
                                                        }}
                                                      >
                                                        <TableCell>
                                                          Job Location
                                                        </TableCell>
                                                        <TableCell>
                                                          Job Post
                                                        </TableCell>
                                                        <TableCell>
                                                          PO Date
                                                        </TableCell>
                                                        <TableCell>
                                                          Employee Category
                                                        </TableCell>
                                                      </TableRow>
                                                      {employee.empPOClientAssesmentUI ? (
                                                        <TableRow>
                                                          <TableCell>
                                                            {employee
                                                              .empPOClientAssesmentUI
                                                              .jobLocation
                                                              ? employee
                                                                  .empPOClientAssesmentUI
                                                                  .jobLocation
                                                              : "N/A"}
                                                          </TableCell>
                                                          <TableCell>
                                                            {
                                                              employee
                                                                .empPOClientAssesmentUI
                                                                .jobPost
                                                            }
                                                          </TableCell>
                                                          <TableCell>
                                                            {employee
                                                              .empPOClientAssesmentUI
                                                              .poDate
                                                              ? employee.empPOClientAssesmentUI.poDate
                                                                  .split("-")
                                                                  .reverse()
                                                                  .join("-")
                                                              : "N/A"}
                                                          </TableCell>
                                                          <TableCell>
                                                            {
                                                              employee
                                                                .empPOClientAssesmentUI
                                                                .employeeCategory
                                                            }
                                                          </TableCell>
                                                        </TableRow>
                                                      ) : (
                                                        <TableRow>
                                                          <TableCell
                                                            colSpan={3}
                                                          >
                                                            No Client Assessment
                                                            Details Available
                                                          </TableCell>
                                                        </TableRow>
                                                      )}

                                                      <Typography variant="subtitle1">
                                                        Facility Assignment
                                                      </Typography>
                                                      <TableRow
                                                        style={{
                                                          backgroundColor:
                                                            "darkcyan",
                                                          fontWeight: "bold",
                                                          color: "darkblue",
                                                        }}
                                                      >
                                                        <TableCell>
                                                          Canteen Amount
                                                        </TableCell>
                                                        <TableCell>
                                                          Transportation Amount
                                                        </TableCell>
                                                        <TableCell>
                                                          Facility PO Number
                                                        </TableCell>
                                                      </TableRow>
                                                      {employee.empPOAssignFacilityUI ? (
                                                        <TableRow>
                                                          <TableCell>
                                                            {
                                                              employee
                                                                .empPOAssignFacilityUI
                                                                .canteenAmount
                                                            }
                                                          </TableCell>
                                                          <TableCell>
                                                            {
                                                              employee
                                                                .empPOAssignFacilityUI
                                                                .transportationAmount
                                                            }
                                                          </TableCell>
                                                          <TableCell>
                                                            {
                                                              employee
                                                                .empPOAssignFacilityUI
                                                                .poNumber
                                                            }
                                                          </TableCell>
                                                        </TableRow>
                                                      ) : (
                                                        <TableRow>
                                                          <TableCell
                                                            colSpan={3}
                                                          >
                                                            No Facility
                                                            Assignment Details
                                                            Available
                                                          </TableCell>
                                                        </TableRow>
                                                      )}

                                                      <Typography variant="subtitle1">
                                                        Release Details
                                                      </Typography>
                                                      <TableRow>
                                                        <TableCell>
                                                          Client Name
                                                        </TableCell>
                                                        <TableCell>
                                                          Employee Name
                                                        </TableCell>
                                                        <TableCell>
                                                          Employee Code
                                                        </TableCell>
                                                        <TableCell>
                                                          Employee Po UniqueNo
                                                        </TableCell>
                                                        <TableCell>
                                                          Release Date Notes
                                                        </TableCell>
                                                      </TableRow>
                                                    </Box>
                                                  </Collapse>
                                                </TableCell>
                                              </TableRow>
                                            </TableBody>
                                          </Table>
                                        </AccordionDetails>
                                      </Accordion>
                                    )
                                  )}
                                </Collapse>
                              </TableCell>
                            </TableRow>
                          </React.Fragment>
                        ))
                      )}
                    </TableBody>
                  </Table>
                  <TablePagination
                    rowsPerPageOptions={[10, 15, 20]}
                    component="div"
                    count={totalCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </Paper>
              </Grid>
              )
            </>
          )}

          <Dialog
            open={addEmployeeDialogOpen}
            onClose={handleAddEmployeeDialogClose}
            maxWidth="md"
            maxHeight="md"
            fullWidth
          >
            <DialogTitle>Add Employee</DialogTitle>
            <DialogContent style={{ marginBottom: 20, marginTop: 20 }}>
              <Paper elevation={3} style={{ padding: 20, marginBottom: 20 }}>
                <Grid container spacing={3}>
                  <Grid item xs={3}>
                    <TextField
                      label="Select Company"
                      name="selectCompany"
                      value={newEmployeeData.selectCompany}
                      onChange={handleFieldChange}
                      variant="standard"
                      fullWidth
                      disabled
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      label="PO Number"
                      value={newEmployeeData.poNumber}
                      InputProps={{ readOnly: true }}
                      fullWidth
                      disabled
                      required
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      label="Financial Year"
                      value={newEmployeeData.financialYear}
                      InputProps={{ readOnly: true }}
                      fullWidth
                      disabled
                      required
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <TextField
                      label="Client Name And Code"
                      value={newEmployeeData.clientNameAndCode}
                      InputProps={{ readOnly: true }}
                      fullWidth
                      disabled
                      required
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      label="Purchase Order Amount"
                      value={newEmployeeData.purchaseOrderAmount}
                      InputProps={{ readOnly: true }}
                      fullWidth
                      disabled
                      required
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      label="Kind Attention"
                      value={newEmployeeData.kindAttention}
                      fullWidth
                      disabled
                      required
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      label="Po Unique Number"
                      value={newEmployeeData.empPoUniqueNoAndGSCode}
                      InputProps={{ readOnly: true }}
                      fullWidth
                      disabled
                      required
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      label="Financial Year"
                      value={newEmployeeData.financialYear}
                      InputProps={{ readOnly: true }}
                      fullWidth
                      disabled
                      required
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <TextField
                      label="Department"
                      value={newEmployeeData.department}
                      InputProps={{ readOnly: true }}
                      fullWidth
                      disabled
                      required
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      label="Sub Department"
                      value={newEmployeeData.subDepartment}
                      InputProps={{ readOnly: true }}
                      fullWidth
                      disabled
                      required
                    />
                  </Grid>
                </Grid>
              </Paper>
              <Paper elevation={3} style={{ padding: 20, marginBottom: 20 }}>
                <Typography variant="subtitle1">Employee Details</Typography>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Autocomplete
                      options={employeeOptions}
                      value={
                        newEmployeeData.empPODetailsUI[0].employeeNameAndCode ||
                        ""
                      }
                      onChange={(event, newValue) => {
                        setNewEmployeeData({
                          ...newEmployeeData,
                          empPODetailsUI: [
                            {
                              ...newEmployeeData.empPODetailsUI[0],
                              employeeNameAndCode: newValue || "",
                            },
                          ],
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Employee Name And Code"
                          variant="standard"
                          name="employeeNameAndCode"
                          required
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="PO Notes"
                      value={newEmployeeData.empPODetailsUI[0].poNotes}
                      onChange={(e) =>
                        setNewEmployeeData({
                          ...newEmployeeData,
                          empPODetailsUI: [
                            {
                              ...newEmployeeData.empPODetailsUI[0],
                              poNotes: e.target.value,
                            },
                          ],
                        })
                      }
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="PO Assign Date"
                      type="date"
                      value={newEmployeeData.empPODetailsUI[0].poAssignDate}
                      required
                      onChange={(e) =>
                        setNewEmployeeData({
                          ...newEmployeeData,
                          empPODetailsUI: [
                            {
                              ...newEmployeeData.empPODetailsUI[0],
                              poAssignDate: e.target.value,
                            },
                          ],
                        })
                      }
                      margin="dense"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                </Grid>
              </Paper>
              <Paper elevation={2} style={{ padding: 20, marginBottom: 20 }}>
                <Typography variant="subtitle1">Emp Po Rate</Typography>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <TextField
                      label="Po Per Month"
                      value={
                        newEmployeeData.empPODetailsUI[0].empPORateUI.poPerMonth
                      }
                      onChange={(e) =>
                        setNewEmployeeData({
                          ...newEmployeeData,
                          empPODetailsUI: [
                            {
                              ...newEmployeeData.empPODetailsUI[0],
                              empPORateUI: {
                                ...newEmployeeData.empPODetailsUI[0]
                                  .empPORateUI,
                                poPerMonth: e.target.value,
                              },
                            },
                          ],
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Po Per Day"
                      value={
                        newEmployeeData.empPODetailsUI[0].empPORateUI.poPerDay
                      }
                      onChange={(e) =>
                        setNewEmployeeData({
                          ...newEmployeeData,
                          empPODetailsUI: [
                            {
                              ...newEmployeeData.empPODetailsUI[0],
                              empPORateUI: {
                                ...newEmployeeData.empPODetailsUI[0]
                                  .empPORateUI,
                                poPerDay: e.target.value,
                              },
                            },
                          ],
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Po Per Hour"
                      value={
                        newEmployeeData.empPODetailsUI[0].empPORateUI.poPerHour
                      }
                      onChange={(e) =>
                        setNewEmployeeData({
                          ...newEmployeeData,
                          empPODetailsUI: [
                            {
                              ...newEmployeeData.empPODetailsUI[0],
                              empPORateUI: {
                                ...newEmployeeData.empPODetailsUI[0]
                                  .empPORateUI,
                                poPerHour: e.target.value,
                              },
                            },
                          ],
                        })
                      }
                    />
                  </Grid>
                </Grid>
              </Paper>
              <Paper elevation={3} style={{ padding: 20, marginBottom: 20 }}>
                <Typography variant="subtitle1">
                  Employee PO Assign Details
                </Typography>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <TextField
                      label="PO Start Date"
                      type="date"
                      value={
                        newEmployeeData.empPODetailsUI[0].empPOAssignDetailsUI
                          .poStartDate
                      }
                      onChange={(e) =>
                        setNewEmployeeData({
                          ...newEmployeeData,
                          empPODetailsUI: [
                            {
                              ...newEmployeeData.empPODetailsUI[0],
                              empPOAssignDetailsUI: {
                                ...newEmployeeData.empPODetailsUI[0]
                                  .empPOAssignDetailsUI,
                                poStartDate: e.target.value,
                              },
                            },
                          ],
                        })
                      }
                      margin="dense"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="PO End Date"
                      type="date"
                      value={
                        newEmployeeData.empPODetailsUI[0].empPOAssignDetailsUI
                          .poEndDate
                      }
                      onChange={(e) =>
                        setNewEmployeeData({
                          ...newEmployeeData,
                          empPODetailsUI: [
                            {
                              ...newEmployeeData.empPODetailsUI[0],
                              empPOAssignDetailsUI: {
                                ...newEmployeeData.empPODetailsUI[0]
                                  .empPOAssignDetailsUI,
                                poEndDate: e.target.value,
                              },
                            },
                          ],
                        })
                      }
                      margin="dense"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="PO Amount"
                      value={
                        newEmployeeData.empPODetailsUI[0].empPOAssignDetailsUI
                          .poAmount
                      }
                      onChange={(e) =>
                        setNewEmployeeData({
                          ...newEmployeeData,
                          empPODetailsUI: [
                            {
                              ...newEmployeeData.empPODetailsUI[0],
                              empPOAssignDetailsUI: {
                                ...newEmployeeData.empPODetailsUI[0]
                                  .empPOAssignDetailsUI,
                                poAmount: e.target.value,
                              },
                            },
                          ],
                        })
                      }
                    />
                  </Grid>
                </Grid>
              </Paper>
              <Paper elevation={3} style={{ padding: 20, marginBottom: 20 }}>
                <Typography variant="subtitle1">
                  Employee PO Client Assesment
                </Typography>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <TextField
                      label="Job Location"
                      value={
                        newEmployeeData.empPODetailsUI[0].empPOClientAssesmentUI
                          .jobLocation
                      }
                      onChange={(e) =>
                        setNewEmployeeData({
                          ...newEmployeeData,
                          empPODetailsUI: [
                            {
                              ...newEmployeeData.empPODetailsUI[0],
                              empPOClientAssesmentUI: {
                                ...newEmployeeData.empPODetailsUI[0]
                                  .empPOClientAssesmentUI,
                                jobLocation: e.target.value,
                              },
                            },
                          ],
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Job Post"
                      value={
                        newEmployeeData.empPODetailsUI[0].empPOClientAssesmentUI
                          .jobPost
                      }
                      onChange={(e) =>
                        setNewEmployeeData({
                          ...newEmployeeData,
                          empPODetailsUI: [
                            {
                              ...newEmployeeData.empPODetailsUI[0],
                              empPOClientAssesmentUI: {
                                ...newEmployeeData.empPODetailsUI[0]
                                  .empPOClientAssesmentUI,
                                jobPost: e.target.value,
                              },
                            },
                          ],
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="PO Date"
                      type="date"
                      value={
                        newEmployeeData.empPODetailsUI[0].empPOClientAssesmentUI
                          .poDate
                      }
                      onChange={(e) =>
                        setNewEmployeeData({
                          ...newEmployeeData,
                          empPODetailsUI: [
                            {
                              ...newEmployeeData.empPODetailsUI[0],
                              empPOClientAssesmentUI: {
                                ...newEmployeeData.empPODetailsUI[0]
                                  .empPOClientAssesmentUI,
                                poDate: e.target.value,
                              },
                            },
                          ],
                        })
                      }
                      margin="dense"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Employee Category"
                      value={
                        newEmployeeData.empPODetailsUI[0].empPOClientAssesmentUI
                          .employeeCategory
                      }
                      onChange={(e) =>
                        setNewEmployeeData({
                          ...newEmployeeData,
                          empPODetailsUI: [
                            {
                              ...newEmployeeData.empPODetailsUI[0],
                              empPOClientAssesmentUI: {
                                ...newEmployeeData.empPODetailsUI[0]
                                  .empPOClientAssesmentUI,
                                employeeCategory: e.target.value,
                              },
                            },
                          ],
                        })
                      }
                    />
                  </Grid>
                  {/* <Grid item xs={6}>
                    <TextField
                      label="Department"
                      value={
                        newEmployeeData.empPODetailsUI[0].empPOClientAssesmentUI
                          .department
                      }
                      onChange={(e) =>
                        setNewEmployeeData({
                          ...newEmployeeData,
                          empPODetailsUI: [
                            {
                              ...newEmployeeData.empPODetailsUI[0],
                              empPOClientAssesmentUI: {
                                ...newEmployeeData.empPODetailsUI[0]
                                  .empPOClientAssesmentUI,
                                department: e.target.value,
                              },
                            },
                          ],
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Sub Department"
                      value={
                        newEmployeeData.empPODetailsUI[0].empPOClientAssesmentUI
                          .subDepartment
                      }
                      onChange={(e) =>
                        setNewEmployeeData({
                          ...newEmployeeData,
                          empPODetailsUI: [
                            {
                              ...newEmployeeData.empPODetailsUI[0],
                              empPOClientAssesmentUI: {
                                ...newEmployeeData.empPODetailsUI[0]
                                  .empPOClientAssesmentUI,
                                subDepartment: e.target.value,
                              },
                            },
                          ],
                        })
                      }
                    />
                  </Grid> */}
                </Grid>
              </Paper>
              <Paper elevation={3} style={{ padding: 20, marginBottom: 20 }}>
                <Typography variant="subtitle1">
                  Employee PO Assign Facility{" "}
                </Typography>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <TextField
                      label="Canteen Amount"
                      value={
                        newEmployeeData.empPODetailsUI[0].empPOAssignFacilityUI
                          .canteenAmount
                      }
                      onChange={(e) =>
                        setNewEmployeeData({
                          ...newEmployeeData,
                          empPODetailsUI: [
                            {
                              ...newEmployeeData.empPODetailsUI[0],
                              empPOAssignFacilityUI: {
                                ...newEmployeeData.empPODetailsUI[0]
                                  .empPOAssignFacilityUI,
                                canteenAmount: e.target.value,
                              },
                            },
                          ],
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Transportation Amount"
                      value={
                        newEmployeeData.empPODetailsUI[0].empPOAssignFacilityUI
                          .transportationAmount
                      }
                      onChange={(e) =>
                        setNewEmployeeData({
                          ...newEmployeeData,
                          empPODetailsUI: [
                            {
                              ...newEmployeeData.empPODetailsUI[0],
                              empPOAssignFacilityUI: {
                                ...newEmployeeData.empPODetailsUI[0]
                                  .empPOAssignFacilityUI,
                                transportationAmount: e.target.value,
                              },
                            },
                          ],
                        })
                      }
                    />
                  </Grid>
                </Grid>
              </Paper>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow
                      style={{
                        backgroundColor: "darkcyan",
                        fontWeight: "bold",
                        color: "darkblue",
                      }}
                    >
                      <TableCell>Employee Name</TableCell>
                      <TableCell>Client Name</TableCell>
                      <TableCell>PO Notes</TableCell>
                      <TableCell>PO Assign Date</TableCell>
                      <TableCell>Action</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {addedEmployees.map((employee, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          {employee.empPODetailsUI[0].employeeNameAndCode}
                        </TableCell>
                        <TableCell>
                          {employee.empPODetailsUI[0].clientNameAndCode}
                        </TableCell>
                        <TableCell>
                          {employee.empPODetailsUI[0].poNotes}
                        </TableCell>
                        <TableCell>
                          {employee.empPODetailsUI[0].poAssignDate}
                        </TableCell>
                        <TableCell>
                          <IconButton onClick={() => handleDeleteRow(index)}>
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Button onClick={handleAddToTable} color="primary">
                Add to Table
              </Button>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleAddEmployeeDialogClose} color="primary">
                Cancel
              </Button>
              <Button
                onClick={handleAddNewEmployee}
                color="primary"
                disabled={addedEmployees.length === 0}
              >
                Add Employee
              </Button>
              <Button onClick={resetNewData} color="secondary">
                Reset
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={openDialog}
            onClose={handleDialogClose}
            maxHeight="md"
            maxWidth="md"
            fullWidth
          >
            <DialogTitle>Edit PO Data</DialogTitle>
            <DialogContent style={{ marginBottom: 20, marginTop: 20 }}>
              <Paper elevation={3} style={{ padding: 20, marginBottom: 20 }}>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={4}>
                    <TextField
                      label="Select Company"
                      name="selectCompany"
                      value={editedData ? editedData.selectCompany : ""}
                      onChange={(e) =>
                        handleFieldChange("selectCompany", e.target.value)
                      }
                      variant="standard"
                      fullWidth
                      disabled
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="PO Number"
                      value={editedData ? editedData.poNumber : ""}
                      // onChange={(e) =>
                      //   setEditedData({ ...editedData, poNumber: e.target.value })
                      // }
                      onChange={(e) =>
                        handleFieldChange("poNumber", e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="Client Name And Code"
                      value={editedData ? editedData.clientNameAndCode : ""}
                      // onChange={(e) =>
                      //   setEditedData({
                      //     ...editedData,
                      //     clientNameAndCode: e.target.value,
                      //   })
                      // }
                      onChange={(e) =>
                        handleFieldChange("clientNameAndCode", e.target.value)
                      }
                      disabled
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="Purchase Order Amount"
                      value={editedData ? editedData.purchaseOrderAmount : ""}
                      // onChange={(e) =>
                      //   setEditedData({
                      //     ...editedData,
                      //     purchaseOrderAmount: e.target.value,
                      //   })
                      // }
                      onChange={(e) =>
                        handleFieldChange("purchaseOrderAmount", e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="Po Unique Number"
                      value={
                        editedData ? editedData.empPoUniqueNoAndGSCode : ""
                      }
                      // onChange={(e) =>
                      //   setEditedData({
                      //     ...editedData,
                      //     empPoUniqueNoAndGSCode: e.target.value,
                      //   })
                      // }
                      onChange={(e) =>
                        handleFieldChange(
                          "empPoUniqueNoAndGSCode",
                          e.target.value
                        )
                      }
                      disabled
                      required
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="Kind Attention"
                      value={editedData ? editedData.kindAttention : ""}
                      // onChange={(e) =>
                      //   setEditedData({
                      //     ...editedData,
                      //     kindAttention: e.target.value,
                      //   })
                      // }
                      onChange={(e) =>
                        handleFieldChange("kindAttention", e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="Department"
                      value={editedData ? editedData.department : ""}
                      // onChange={(e) =>
                      //   setEditedData({
                      //     ...editedData,
                      //     kindAttention: e.target.value,
                      //   })
                      // }
                      onChange={(e) =>
                        handleFieldChange("department", e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="Sub Department"
                      value={editedData ? editedData.subDepartment : ""}
                      // onChange={(e) =>
                      //   setEditedData({
                      //     ...editedData,
                      //     kindAttention: e.target.value,
                      //   })
                      // }
                      onChange={(e) =>
                        handleFieldChange("subDepartment", e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="Financial Year"
                      value={editedData ? editedData.financialYear : ""}
                      // onChange={(e) =>
                      //   setEditedData({
                      //     ...editedData,
                      //     kindAttention: e.target.value,
                      //   })
                      // }
                      onChange={(e) =>
                        handleFieldChange("financialYear", e.target.value)
                      }
                    />
                  </Grid>
                </Grid>
              </Paper>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogClose} color="primary">
                Cancel
              </Button>
              <Button
                onClick={handleUpdateData}
                color="primary"
                disabled={!isDataChangedEditPO}
              >
                Update PO
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={employeeDialogOpen}
            onClose={handleEmployeeDialogClose}
            maxWidth="md"
            maxHeight="md"
            fullWidth
          >
            <DialogTitle>Edit Employee Data</DialogTitle>
            <DialogContent>
              <Tabs
                value={currentTab}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="primary"
              >
                <Tab label="Employee Details" />
                <Tab label="Rate Details" />
                <Tab label="Assign Details" />
                <Tab label="Client Assessment" />
                <Tab label="Assign Facility" />
                {/* <Tab label="Employee Release" /> */}
              </Tabs>
              {currentTab === 0 && (
                <div>
                  <Paper
                    elevation={3}
                    style={{ padding: 20, marginBottom: 20, marginTop: 20 }}
                  >
                    <Grid container spacing={6} style={{ marginTop: 10 }}>
                      <Grid item xs={12} md={4}>
                        <TextField
                          label="Select Company"
                          name="selectCompany"
                          value={newEmployeeData.selectCompany}
                          onChange={handleFieldChange}
                          variant="standard"
                          fullWidth
                          disabled
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          label="Employee Name And Code"
                          value={
                            editedEmployeeData
                              ? editedEmployeeData.employeeNameAndCode
                              : ""
                          }
                          required
                          onChange={(e) => {
                            setEditedEmployeeData({
                              ...editedEmployeeData,
                              employeeNameAndCode: e.target.value,
                            });
                            setIsDataChanged(true); // Mark data as changed
                          }}
                          multiline
                          rows={2}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          label="Client Name And Code"
                          value={
                            editedEmployeeData
                              ? editedEmployeeData.clientNameAndCode
                              : ""
                          }
                          required
                          onChange={(e) => {
                            setEditedEmployeeData({
                              ...editedEmployeeData,
                              clientNameAndCode: e.target.value,
                            });
                            setIsDataChanged(true);
                          }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          label="PO Notes"
                          value={
                            editedEmployeeData ? editedEmployeeData.poNotes : ""
                          }
                          onChange={(e) => {
                            setEditedEmployeeData({
                              ...editedEmployeeData,
                              poNotes: e.target.value,
                            });
                            setIsDataChanged(true);
                          }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          label="Po Assign Date"
                          type="date"
                          value={
                            editedEmployeeData
                              ? editedEmployeeData.poAssignDate
                              : ""
                          }
                          required
                          onChange={(e) => {
                            setEditedEmployeeData({
                              ...editedEmployeeData,
                              poAssignDate: e.target.value,
                            });
                            setIsDataChanged(true);
                          }}
                          margin="dense"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                </div>
              )}
              {currentTab === 1 && (
                <div>
                  <Paper
                    elevation={3}
                    style={{ padding: 20, marginBottom: 20, marginTop: 20 }}
                  >
                    <Grid container spacing={6} style={{ marginTop: 10 }}>
                      <Grid item xs={4}>
                        <TextField
                          label="Po Per Month"
                          value={
                            editedEmployeeData && editedEmployeeData.empPORateUI
                              ? editedEmployeeData.empPORateUI.poPerMonth
                              : ""
                          }
                          onChange={(e) => {
                            setEditedEmployeeData({
                              ...editedEmployeeData,
                              empPORateUI: {
                                ...editedEmployeeData.empPORateUI,
                                poPerMonth: e.target.value,
                              },
                            });
                            setIsDataChanged(true);
                          }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          label="Po Per Day"
                          value={
                            editedEmployeeData && editedEmployeeData.empPORateUI
                              ? editedEmployeeData.empPORateUI.poPerDay
                              : ""
                          }
                          onChange={(e) => {
                            setEditedEmployeeData({
                              ...editedEmployeeData,
                              empPORateUI: {
                                ...editedEmployeeData.empPORateUI,
                                poPerDay: e.target.value,
                              },
                            });
                            setIsDataChanged(true);
                          }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          label="Po Per Hour"
                          value={
                            editedEmployeeData && editedEmployeeData.empPORateUI
                              ? editedEmployeeData.empPORateUI.poPerHour
                              : ""
                          }
                          onChange={(e) => {
                            setEditedEmployeeData({
                              ...editedEmployeeData,
                              empPORateUI: {
                                ...editedEmployeeData.empPORateUI,
                                poPerHour: e.target.value,
                              },
                            });
                            setIsDataChanged(true);
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                </div>
              )}
              {currentTab === 2 && (
                <div>
                  <Paper
                    elevation={3}
                    style={{ padding: 20, marginBottom: 20, marginTop: 20 }}
                  >
                    <Grid container spacing={6} style={{ marginTop: 10 }}>
                      <Grid item xs={4}>
                        <TextField
                          label="Po Start Date"
                          type="date"
                          value={
                            editedEmployeeData &&
                            editedEmployeeData.empPOAssignDetailsUI
                              ? editedEmployeeData.empPOAssignDetailsUI
                                  .poStartDate
                              : ""
                          }
                          onChange={(e) => {
                            setEditedEmployeeData({
                              ...editedEmployeeData,
                              empPOAssignDetailsUI: {
                                ...editedEmployeeData.empPOAssignDetailsUI,
                                poStartDate: e.target.value,
                              },
                            });
                            setIsDataChanged(true);
                          }}
                          margin="dense"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          label="Po End Date"
                          type="date"
                          value={
                            editedEmployeeData &&
                            editedEmployeeData.empPOAssignDetailsUI
                              ? editedEmployeeData.empPOAssignDetailsUI
                                  .poEndDate
                              : ""
                          }
                          onChange={(e) => {
                            setEditedEmployeeData({
                              ...editedEmployeeData,
                              empPOAssignDetailsUI: {
                                ...editedEmployeeData.empPOAssignDetailsUI,
                                poEndDate: e.target.value,
                              },
                            });
                            setIsDataChanged(true);
                          }}
                          margin="dense"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          label="Po Amount"
                          value={
                            editedEmployeeData &&
                            editedEmployeeData.empPOAssignDetailsUI
                              ? editedEmployeeData.empPOAssignDetailsUI.poAmount
                              : ""
                          }
                          onChange={(e) => {
                            setEditedEmployeeData({
                              ...editedEmployeeData,
                              empPOAssignDetailsUI: {
                                ...editedEmployeeData.empPOAssignDetailsUI,
                                poAmount: e.target.value,
                              },
                            });
                            setIsDataChanged(true);
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                </div>
              )}
              {currentTab === 3 && (
                <div>
                  <Paper
                    elevation={3}
                    style={{ padding: 20, marginBottom: 20, marginTop: 20 }}
                  >
                    <Grid container spacing={6} style={{ marginTop: 10 }}>
                      <Grid item xs={4}>
                        <TextField
                          label="Job Location"
                          value={
                            editedEmployeeData &&
                            editedEmployeeData.empPOClientAssesmentUI
                              ? editedEmployeeData.empPOClientAssesmentUI
                                  .jobLocation
                              : ""
                          }
                          onChange={(e) => {
                            setEditedEmployeeData({
                              ...editedEmployeeData,
                              empPOClientAssesmentUI: {
                                ...editedEmployeeData.empPOClientAssesmentUI,
                                jobLocation: e.target.value,
                              },
                            });
                            setIsDataChanged(true);
                          }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          label="Job Post"
                          value={
                            editedEmployeeData &&
                            editedEmployeeData.empPOClientAssesmentUI
                              ? editedEmployeeData.empPOClientAssesmentUI
                                  .jobPost
                              : ""
                          }
                          onChange={(e) => {
                            setEditedEmployeeData({
                              ...editedEmployeeData,
                              empPOClientAssesmentUI: {
                                ...editedEmployeeData.empPOClientAssesmentUI,
                                jobPost: e.target.value,
                              },
                            });
                            setIsDataChanged(true);
                          }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          label="Po Date"
                          type="date"
                          value={
                            editedEmployeeData &&
                            editedEmployeeData.empPOClientAssesmentUI
                              ? editedEmployeeData.empPOClientAssesmentUI.poDate
                              : ""
                          }
                          onChange={(e) => {
                            setEditedEmployeeData({
                              ...editedEmployeeData,
                              empPOClientAssesmentUI: {
                                ...editedEmployeeData.empPOClientAssesmentUI,
                                poDate: e.target.value,
                              },
                            });
                            setIsDataChanged(true);
                          }}
                          margin="dense"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          label="Employee Category"
                          value={
                            editedEmployeeData &&
                            editedEmployeeData.empPOClientAssesmentUI
                              ? editedEmployeeData.empPOClientAssesmentUI
                                  .employeeCategory
                              : ""
                          }
                          onChange={(e) => {
                            setEditedEmployeeData({
                              ...editedEmployeeData,
                              empPOClientAssesmentUI: {
                                ...editedEmployeeData.empPOClientAssesmentUI,
                                employeeCategory: e.target.value,
                              },
                            });
                            setIsDataChanged(true);
                          }}
                        />
                      </Grid>
                      {/* <Grid item xs={4}>
                        <TextField
                          label="Department"
                          value={
                            editedEmployeeData &&
                            editedEmployeeData.empPOClientAssesmentUI
                              ? editedEmployeeData.empPOClientAssesmentUI
                                  .department
                              : ""
                          }
                          onChange={(e) => {
                            setEditedEmployeeData({
                              ...editedEmployeeData,
                              empPOClientAssesmentUI: {
                                ...editedEmployeeData.empPOClientAssesmentUI,
                                department: e.target.value,
                              },
                            });
                            setIsDataChanged(true);
                          }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          label="Sub Department"
                          value={
                            editedEmployeeData &&
                            editedEmployeeData.empPOClientAssesmentUI
                              ? editedEmployeeData.empPOClientAssesmentUI
                                  .subDepartment
                              : ""
                          }
                          onChange={(e) => {
                            setEditedEmployeeData({
                              ...editedEmployeeData,
                              empPOClientAssesmentUI: {
                                ...editedEmployeeData.empPOClientAssesmentUI,
                                subDepartment: e.target.value,
                              },
                            });
                            setIsDataChanged(true);
                          }}
                        />
                      </Grid> */}
                    </Grid>
                  </Paper>
                </div>
              )}
              {currentTab === 4 && (
                <div>
                  <Paper
                    elevation={3}
                    style={{ padding: 20, marginBottom: 20, marginTop: 20 }}
                  >
                    <Grid container spacing={6} style={{ marginTop: 10 }}>
                      <Grid item xs={4}>
                        <TextField
                          label="Canteen Amount"
                          value={
                            editedEmployeeData &&
                            editedEmployeeData.empPOAssignFacilityUI
                              ? editedEmployeeData.empPOAssignFacilityUI
                                  .canteenAmount
                              : ""
                          }
                          onChange={(e) => {
                            setEditedEmployeeData({
                              ...editedEmployeeData,
                              empPOAssignFacilityUI: {
                                ...editedEmployeeData.empPOAssignFacilityUI,
                                canteenAmount: e.target.value,
                              },
                            });
                            setIsDataChanged(true);
                          }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          label="Transportation Amount"
                          value={
                            editedEmployeeData &&
                            editedEmployeeData.empPOAssignFacilityUI
                              ? editedEmployeeData.empPOAssignFacilityUI
                                  .transportationAmount
                              : ""
                          }
                          onChange={(e) => {
                            setEditedEmployeeData({
                              ...editedEmployeeData,
                              empPOAssignFacilityUI: {
                                ...editedEmployeeData.empPOAssignFacilityUI,
                                transportationAmount: e.target.value,
                              },
                            });
                            setIsDataChanged(true);
                          }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          label="Po Number"
                          value={
                            editedEmployeeData &&
                            editedEmployeeData.empPOAssignFacilityUI
                              ? editedEmployeeData.empPOAssignFacilityUI
                                  .poNumber
                              : ""
                          }
                          onChange={(e) => {
                            setEditedEmployeeData({
                              ...editedEmployeeData,
                              empPOAssignFacilityUI: {
                                ...editedEmployeeData.empPOAssignFacilityUI,
                                poNumber: e.target.value,
                              },
                            });
                            setIsDataChanged(true);
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                </div>
              )}
              {currentTab === 5 && (
                <div>
                  <Paper
                    elevation={3}
                    style={{ padding: 20, marginBottom: 20, marginTop: 20 }}
                  >
                    <Grid container spacing={6} style={{ marginTop: 10 }}>
                      <Grid item xs={4}>
                        <TextField
                          label="Client Name And Code"
                          value={
                            editedEmployeeData &&
                            editedEmployeeData.empPOReleaseUI
                              ? editedEmployeeData.empPOReleaseUI
                                  .clientNameAndCode || ""
                              : ""
                          }
                          disabled
                          required
                          onChange={(e) => {
                            setEditedEmployeeData({
                              ...editedEmployeeData,
                              empPOReleaseUI: {
                                ...editedEmployeeData.empPOReleaseUI,
                                clientNameAndCode: e.target.value,
                              },
                            });
                            setIsDataChanged(true);
                          }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          label="Employee Name And Code"
                          value={
                            editedEmployeeData &&
                            editedEmployeeData.empPOReleaseUI
                              ? editedEmployeeData.empPOReleaseUI
                                  .employeeName || ""
                              : ""
                          }
                          onChange={(e) => {
                            setEditedEmployeeData({
                              ...editedEmployeeData,
                              empPOReleaseUI: {
                                ...editedEmployeeData.empPOReleaseUI,
                                employeeName: e.target.value,
                              },
                            });
                            setIsDataChanged(true);
                          }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          label="Employee Code"
                          value={
                            editedEmployeeData &&
                            editedEmployeeData.empPOReleaseUI
                              ? editedEmployeeData.empPOReleaseUI
                                  .employeeCode || ""
                              : ""
                          }
                          onChange={(e) => {
                            setEditedEmployeeData({
                              ...editedEmployeeData,
                              empPOReleaseUI: {
                                ...editedEmployeeData.empPOReleaseUI,
                                employeeCode: e.target.value,
                              },
                            });
                            setIsDataChanged(true);
                          }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          label="Release Date Notes"
                          value={
                            editedEmployeeData &&
                            editedEmployeeData.empPOReleaseUI
                              ? editedEmployeeData.empPOReleaseUI
                                  .releaseDateNotes || ""
                              : ""
                          }
                          onChange={(e) => {
                            setEditedEmployeeData({
                              ...editedEmployeeData,
                              empPOReleaseUI: {
                                ...editedEmployeeData.empPOReleaseUI,
                                releaseDateNotes: e.target.value,
                              },
                            });
                            setIsDataChanged(true);
                          }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          label="Employee Po UniqueNo"
                          value={
                            editedEmployeeData &&
                            editedEmployeeData.empPOReleaseUI
                              ? editedEmployeeData.empPOReleaseUI
                                  .empPoUniqueNoAndGSCode || ""
                              : ""
                          }
                          onChange={(e) => {
                            setEditedEmployeeData({
                              ...editedEmployeeData,
                              empPOReleaseUI: {
                                ...editedEmployeeData.empPOReleaseUI,
                                empPoUniqueNoAndGSCode: e.target.value,
                              },
                            });
                            setIsDataChanged(true);
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                </div>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleEmployeeDialogClose} color="primary">
                Cancel
              </Button>
              <Button
                onClick={handleEmployeeUpdateData}
                disabled={!isDataChanged}
                color="primary"
              >
                Update Employee
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={releaseDialogOpen}
            onClose={() => setReleaseDialogOpen(false)}
            maxWidth="md"
            maxHeight="md"
            fullWidth
          >
            <DialogTitle>Released Employee Details</DialogTitle>
            <DialogContent style={{ marginBottom: 20, marginTop: 20 }}>
              <Paper elevation={2} style={{ padding: 20, marginBottom: 20 }}>
                <Grid container spacing={6}>
                  <Grid item md={4}>
                    <TextField
                      label="Client Name"
                      value={releasedEmployeeData?.clientName || ""}
                      onChange={(e) => {
                        setReleasedEmployeeData({
                          ...releasedEmployeeData,
                          clientName: e.target.value,
                        });
                        setReleaseIsDataChanged(true);
                      }}
                      fullWidth
                      margin="normal"
                      disabled
                    />
                  </Grid>
                  {/* <Grid item md={4}>
                    <TextField
                      label="Employee Name"
                      value={releasedEmployeeData?.employeeName || ""}
                      onChange={(e) => {
                        setReleasedEmployeeData({
                          ...releasedEmployeeData,
                          employeeName: e.target.value,
                        });
                        setReleaseIsDataChanged(true);
                      }}
                      fullWidth
                      margin="normal"
                      disabled
                    />
                  </Grid>
                  <Grid item md={4}>
                    <TextField
                      label="Employee Code"
                      // value={releasedEmployeeData?.employeeCode || ""}
                      value={
                        editedEmployeeData
                          ? editedEmployeeData.employeeCode
                          : ""
                      }
                      onChange={(e) => {
                        setReleasedEmployeeData({
                          ...releasedEmployeeData,
                          employeeCode: e.target.value,
                        });
                        setReleaseIsDataChanged(true);
                      }}
                      fullWidth
                      margin="normal"
                      disabled
                    />
                  </Grid> */}
                  <Grid item md={4}>
                    <TextField
                      label="Client Name And Code"
                      value={releasedEmployeeData?.clientNameAndCode || ""}
                      onChange={(e) => {
                        setReleasedEmployeeData({
                          ...releasedEmployeeData,
                          clientNameAndCode: e.target.value,
                        });
                        setReleaseIsDataChanged(true);
                      }}
                      fullWidth
                      margin="normal"
                      disabled
                    />
                  </Grid>
                  <Grid item md={4}>
                    <TextField
                      label="Employee Name And Code"
                      value={releasedEmployeeData?.employeeNameAndCode || ""}
                      onChange={(e) => {
                        setReleasedEmployeeData({
                          ...releasedEmployeeData,
                          employeeNameAndCode: e.target.value,
                        });
                        setReleaseIsDataChanged(true);
                      }}
                      fullWidth
                      margin="normal"
                      disabled
                    />
                  </Grid>
                  <Grid item md={4}>
                    <TextField
                      label="Release Date Notes"
                      value={releasedEmployeeData?.releaseDateNotes || ""}
                      onChange={(e) => {
                        setReleasedEmployeeData({
                          ...releasedEmployeeData,
                          releaseDateNotes: e.target.value,
                        });
                        setReleaseIsDataChanged(true);
                      }}
                      fullWidth
                      margin="normal"
                    />
                  </Grid>
                  <Grid item md={4}>
                    <TextField
                      label="Unique Number"
                      value={releasedEmployeeData?.empPoUniqueNoAndGSCode || ""}
                      onChange={(e) => {
                        setReleasedEmployeeData({
                          ...releasedEmployeeData,
                          empPoUniqueNoAndGSCode: e.target.value,
                        });
                        setReleaseIsDataChanged(true);
                      }}
                      fullWidth
                      margin="normal"
                      disabled
                    />
                  </Grid>
                  <Grid item md={4}>
                    <TextField
                      label="PO Number"
                      value={releasedEmployeeData?.poNumber || ""}
                      onChange={(e) => {
                        setReleasedEmployeeData({
                          ...releasedEmployeeData,
                          poNumber: e.target.value,
                        });
                        setReleaseIsDataChanged(true);
                      }}
                      fullWidth
                      margin="normal"
                      disabled
                    />
                  </Grid>
                  <Grid item md={4}>
                    <TextField
                      label="Release Date"
                      value={releasedEmployeeData?.releaseDate || ""}
                      onChange={(e) => {
                        setReleasedEmployeeData({
                          ...releasedEmployeeData,
                          releaseDate: e.target.value,
                        });
                        setReleaseIsDataChanged(true);
                      }}
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      // disabled
                      fullWidth
                      margin="normal"
                    />
                  </Grid>
                </Grid>
              </Paper>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setReleaseDialogOpen(false)}
                color="primary"
              >
                Cancel
              </Button>
              <Button
                onClick={handleUpdateReleasedEmployee}
                disabled={!isReleaseDataChanged}
                color="primary"
              >
                Release Employee
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={deleteDialogOpen}
            onClose={() => {
              setDeleteDialogOpen(false);
              setPassword(""); // Reset password field
            }}
            maxHeight="md"
            fullWidth
          >
            <DialogTitle>Confirm Deletion</DialogTitle>
            <DialogContent style={{ marginBottom: 20, marginTop: 20 }}>
              <Paper elevation={2} style={{ padding: 20, marginBottom: 20 }}>
                <Grid container spacing={6}>
                  <Grid item md={4}>
                    <TextField
                      label="Enter Password"
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      fullWidth
                      autoFocus
                    />
                  </Grid>
                </Grid>
              </Paper>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setDeleteDialogOpen(false)}
                color="primary"
              >
                Cancel
              </Button>
              <Button
                onClick={handleDeleteConfirm}
                color="primary"
                disabled={isDeleting}
              >
                {isDeleting ? <CircularProgress size={24} /> : "Delete"}
              </Button>
            </DialogActions>
          </Dialog>
        </TableContainer>
      )}
    </div>
  );
};

export default UpdateEmpPO;
