import NavigationBarRSuite from "../Component/NavigationBar/NavigationRSuite";

const Headers = ({ children }) => {
  return (
    <div>
      <NavigationBarRSuite />
      {children}
    </div>
  );
};

export default Headers;
