import React, { useContext, useEffect, useState } from "react";
import { myAxiosCommonHost } from "../../Service/CorsHelperBaseUrl";
import { CardContent } from "@material-ui/core";
import {
  Card,
  Paper,
  Grid,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  CircularProgress,
  Typography,
  TablePagination,
  Button,
} from "@material-ui/core";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import { CompanyContext } from "../StateProvider/CompanyProvider";

const ClientAgreementNotification = () => {
  const [clientAgreementNotification, setClientAgreementNotification] =
    useState([]);
  const [isCANotificationUI, setIsCANotificationUI] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalAgreementCount, setTotalAgreementCount] = useState(0);
  const navigate = useNavigate();
  const { selectedCompany } = useContext(CompanyContext);

  useEffect(() => {
    setIsCANotificationUI(true);
    if (selectedCompany) {
      myAxiosCommonHost
        .get(
          `gst/notification/getClientAgreeMentNotification?selectCompany=${selectedCompany}`
        )
        .then((response) => {
          setClientAgreementNotification(response.data);
          console.log(response.data);
        })
        .catch((error) => {
          console.error("Error fetching project summary:", error);
        })
        .finally(() => {
          setIsCANotificationUI(false);
        });
    }
  }, [selectedCompany]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleViewClick = (uniqueAgreementCode) => {
    navigate(`/updateClientAgreement/${uniqueAgreementCode}`);
  };

  return (
    <div>
      {isCANotificationUI && (
        <div className="blur-effect">
          <CircularProgress color="primary" />
          <div className="loading-message">Getting Notifications...</div>
        </div>
      )}
      <Grid>
        <Grid>
          <Card>
            <CardContent>
              <Paper
                elevation={3}
                style={{ padding: "20px", marginBottom: "20px" }}
              >
                <Paper
                  elevation={3}
                  style={{
                    padding: "10px",
                    marginBottom: "10px",
                    backgroundColor: "#2196f3",
                  }}
                >
                  <Typography
                    variant="h5"
                    gutterBottom
                    style={{
                      marginBottom: "15px",
                      color: "white",
                      textAlign: "center",
                    }}
                  >
                    Agreement Notifications
                  </Typography>
                </Paper>
              </Paper>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {clientAgreementNotification.length > 0 && (
        <Grid>
          <Paper
            elevation={3}
            style={{ padding: "20px", marginBottom: "20px" }}
          >
            <Paper
              elevation={3}
              style={{ padding: "10px", marginBottom: "10px" }}
            >
              <Typography
                variant="h5"
                gutterBottom
                style={{ marginBottom: "15px", color: "#2196f3" }}
              >
                Client Agreement Notifications
              </Typography>
            </Paper>
            <TableContainer component={Paper} style={{ marginTop: "20px" }}>
              <Table>
                <TableHead>
                  <TableRow
                    style={{
                      backgroundColor: "darkcyan",
                      fontWeight: "bold",
                      color: "darkblue",
                    }}
                  >
                    <TableCell>Action</TableCell>
                    <TableCell>SR.No</TableCell>
                    <TableCell>Select Company</TableCell>
                    <TableCell>Financial Year</TableCell>
                    <TableCell>Agreement Code</TableCell>
                    <TableCell>Unique Agreement Code</TableCell>
                    <TableCell>Client Code</TableCell>
                    <TableCell>Client Name</TableCell>
                    <TableCell>Department</TableCell>
                    <TableCell>Aagreement Type</TableCell>
                    <TableCell>Agreement Date</TableCell>
                    <TableCell>Agreement Duration</TableCell>
                    <TableCell>Agreement Start Date</TableCell>
                    <TableCell>Agreement End Date</TableCell>
                    <TableCell>Agreement End In Day</TableCell>
                    <TableCell>Agreement End Hour</TableCell>
                    <TableCell>Agreement End Min</TableCell>
                    <TableCell>Agreement Validation</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {clientAgreementNotification.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={5} align="center">
                        Client Agreement Notifications found
                      </TableCell>
                    </TableRow>
                  ) : (
                    clientAgreementNotification.map((row, index) => (
                      <TableRow key={row.uniqueAgreementCode}>
                        <TableCell>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() =>
                              handleViewClick(row.uniqueAgreementCode)
                            }
                          >
                            View
                          </Button>
                        </TableCell>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{row.selectCompany}</TableCell>
                        <TableCell>{row.financialYear}</TableCell>
                        <TableCell>{row.agreementCode}</TableCell>
                        <TableCell>{row.uniqueAgreementCode}</TableCell>
                        <TableCell>{row.clientCode}</TableCell>
                        <TableCell>{row.clientName}</TableCell>
                        <TableCell>{row.department}</TableCell>
                        <TableCell>{row.agreementType}</TableCell>
                        <TableCell>
                          {row.agreementDate
                            ? row.agreementDate.split("-").reverse().join("-")
                            : "N/A"}
                        </TableCell>
                        <TableCell>{row.duration}</TableCell>
                        <TableCell>
                          {row.startDate
                            ? row.startDate.split("-").reverse().join("-")
                            : "N/A"}
                        </TableCell>
                        <TableCell>
                          {row.endDate
                            ? row.endDate.split("-").reverse().join("-")
                            : "N/A"}
                        </TableCell>
                        <TableCell>{row.cAEndIn}</TableCell>
                        <TableCell>{row.cAEndHour}</TableCell>
                        <TableCell>{row.cAEndMin}</TableCell>
                        <TableCell>{row.cAValidation}</TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 15, 20]}
              component="div"
              count={totalAgreementCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
      )}
    </div>
  );
};

export default ClientAgreementNotification;
