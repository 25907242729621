import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Collapse,
  Grid,
  TablePagination,
  Radio,
  Checkbox,
  CircularProgress,
  Backdrop,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Box, IconButton } from "@mui/material";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import Swal from "sweetalert2";
import GsquareLOGO from "../../Component/Invoice/GsquareLOGO.jpg";
import Bgd_LOGO from "../../Component/Invoice/Bgd_LOGO.jpeg";
import { myAxiosCommonHost } from "../../Service/CorsHelperBaseUrl";

import * as XLSX from "xlsx";

import { Autocomplete } from "@mui/material";
import { CompanyContext } from "../StateProvider/CompanyProvider";

const EmployeeInvoiceList = () => {
  const getCurrentFinancialYear = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const fiscalStartYear = currentYear - (currentDate.getMonth() >= 3 ? 0 : 1);
    const fiscalEndYear = fiscalStartYear + 1;
    return `${(fiscalStartYear % 100).toString().padStart(2, "0")}-${(
      fiscalEndYear % 100
    )
      .toString()
      .padStart(2, "0")}`;
  };
  const defaultFinancialYear = getCurrentFinancialYear();
  // const [selectedFinancialYear, setSelectedFinancialYear] =
  //   useState(defaultFinancialYear);
  const [invoiceHeaderDetails, setInvoiceHeaderDetails] = useState({
    selectCompany: "",
    financialYear: "",
    invoiceNo: "",
    invoiceRefNo: "",
    invoiceDate: "",
    invoiceType: "",
    clientType: "",
    paymentYear: "",
    paymentMonth: "",
    clientNameAndCode: "",
    sacHsn: "",
    uniqueNoPoNo: "",
    buyersPoNumber: "",
    poNumber: "",
    issueDate: "",
    workingDays: "",
    invoiceTitle: "",
    invoiceRemarks: "",
    department: "",
    subDepartment: "",
  });
  const [expandedRow, setExpandedRow] = useState(null);
  const [data, setData] = useState([]);
  const [editedInvoiceData, setEditedInvoiceData] = useState(null);
  const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
  const [fetchError, setFetchError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [hasChanges, setHasChanges] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTermClientName, setSearchTermClientName] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false); // State to control update overlay
  const [invoiceRemarksOptions, setInvoiceRemarksOptions] = useState([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [password, setPassword] = useState("");
  const [selectedInvoiceRefNo, setSelectedInvoiceRefNo] = useState("");
  const [passwordVerified, setPasswordVerified] = useState(false);

  const [totalCount, setTotalCount] = useState(0); // Initialize total count state
  const [searchTotalCount, setSearchTotalCount] = useState(0); // Initialize total count state

  const [financialYear, setFinancialYear] = useState([]);
  const [searchInvoiceRefNo, setSearchInvoiceRefNo] = useState("");
  const [searchClientName, setSearchClientName] = useState("");
  //const [selectedCompany, setSelectedCompany] = useState("");
  const [company, setCompany] = useState([]);
  const [companyProfile, setCompanyProfile] = useState({});
  const [clientDetails, setClientDetails] = useState({});
  const [gstDetails, setGstDetails] = useState({});
  const [selectedOption, setSelectedOption] = useState("1");
  const { selectedCompany } = useContext(CompanyContext);
  const { selectedFinancialYear } = useContext(CompanyContext);
  // useEffect(() => {
  //   async function fetchCompany() {
  //     try {
  //       const response = await myAxiosCommonHost.get(
  //         "gst/profile/getCompanyCodeList"
  //       );
  //       const companies = response.data;
  //       setCompany(companies);
  //       if (companies.length > 0) {
  //         setSelectedCompany(companies[0]); // Set the default company to the first one
  //       }
  //     } catch (error) {
  //       console.error("Error fetching company data:", error);
  //     }
  //   }
  //   fetchCompany();
  // }, []);

  // useEffect(() => {
  //   async function fetchFinancialYear() {
  //     try {
  //       const response = await myAxiosCommonHost.get(
  //         "gst/invoice/getFiscalYear"
  //       );
  //       setFinancialYear(response.data);
  //     } catch (error) {
  //       console.error("Error fetching financial year data:", error);
  //     } finally {
  //     }
  //   }

  //   fetchFinancialYear();
  // }, []);
  // const handleFinancialYearSelect = (financialYear) => {
  //   setSelectedFinancialYear(financialYear);
  //   console.log("Selected Financial Year:", financialYear);
  // };
  const fetchInvoiceCount = () => {
    myAxiosCommonHost
      .get("gst/invoice/getCountAsPerInvoice?filterType=EMPLOYEEPOINVOICE")
      .then((response) => {
        const count = parseInt(response.data); // Extract count from API response
        setTotalCount(count); // Set total count state
      })
      .catch((error) => {
        console.error("Error fetching employee count:", error);
      });
  };
  // const handleCompanySelect = (company) => {
  //   setSelectedCompany(company);
  //   console.log("Selected Company :", company);
  // };
  const fetchInvoiceSearchCount = () => {
    myAxiosCommonHost
      .get(
        `gst/invoice/getSearchCountAsPerInvoice?invoiceRefNo=${searchInvoiceRefNo}&financialYear=${selectedFinancialYear}&clientName=${searchClientName}&filterType=EMPLOYEEPOINVOICE`
      )
      .then((response) => {
        const count = parseInt(response.data); // Extract count from API response
        setSearchTotalCount(count); // Set total count state
      })
      .catch((error) => {
        console.error("Error fetching employee count:", error);
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const limit = rowsPerPage;
        const offset = page * rowsPerPage;
        if (selectedCompany && selectedFinancialYear) {
          const response = await myAxiosCommonHost.get(
            `gst/invoice/getInvoiceList?selectCompany=${selectedCompany}&filterType=EMPLOYEEPOINVOICE&financialYear=${selectedFinancialYear}&limit=${limit}&offset=${offset}`
          );
          const result = response.data;
          setData(result);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(error);
        setLoading(false);
      }
    };

    fetchInvoiceCount();
    fetchData();
  }, [selectedFinancialYear, selectedCompany, rowsPerPage, page]);
  const handleExpandClick = (index) => {
    setExpandedRow(expandedRow === index ? null : index);
  };
  const handleDialogInputChange = (field, value) => {
    if (field === "invoiceTitle") {
      setEditedInvoiceData((prevDetails) => ({
        ...prevDetails,
        invoiceRemarks: "", // Reset invoiceRemarks
      }));
      // Set options for invoiceRemarks based on invoiceTitle value
      if (value === "PROFORMA INVOICE") {
        setInvoiceRemarksOptions([
          "QUOTATION UNDER PROCESS",
          "PR UNDER PROCESS",
          "PO UNDER PROCESS",
        ]);
      } else if (value === "TAX INVOICE") {
        setInvoiceRemarksOptions([
          "Invoice in submission process",
          "Invoice with GSQUARE Representative",
          "Invoice with compliance",
          "Invoice with user",
          "Invoice with account/GRN",
        ]);
      } else {
        // Clear invoiceRemarksOptions if invoiceTitle is neither "PROFORMA INVOICE" nor "TAX INVOICE"
        setInvoiceRemarksOptions([]);
      }
    }
    // Update invoiceHeaderDetails with the selected value
    setEditedInvoiceData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
    setHasChanges(true); // Set the flag when there are changes
  };

  const handlePaymentModeChange = (field, value) => {
    setEditedInvoiceData((prevData) => ({
      ...prevData,
      invoicePaymentModeUI: {
        ...prevData.invoicePaymentModeUI,
        [field]: value,
      },
    }));
    setHasChanges(true); // Set the flag when there are changes
  };
  const handleBankDetailsChange = (field, value) => {
    setEditedInvoiceData((prevData) => ({
      ...prevData,
      bankUI: {
        ...prevData.bankUI,
        [field]: value,
      },
    }));
    setHasChanges(true); // Set the flag when there are changes
  };
  // const handleUpdateDialogSave = () => {
  //   console.log("Updated Invoice Data:", editedInvoiceData);
  //   console.log(JSON.stringify(editedInvoiceData, null, 2));
  //   const response = myAxiosCommonHost.put(
  //     "gst/invoice/updateInvoice?filterType=EMPLOYEEPOINVOICE",
  //     editedInvoiceData
  //   );
  //   setUpdateDialogOpen(false);
  // };
  const handleUpdateInvoiceClick = (invoiceData) => {
    setEditedInvoiceData({ ...invoiceData });
    setUpdateDialogOpen(true);
  };
  const handleUpdateDialogSave = async () => {
    setIsUpdating(true);
    try {
      console.log("Updated Invoice Data:", editedInvoiceData);
      console.log(JSON.stringify(editedInvoiceData, null, 2));
      const response = await myAxiosCommonHost.put(
        "gst/invoice/updateInvoice?filterType=EMPLOYEEPOINVOICE",
        editedInvoiceData
      );
      if (response.status === 200) {
        //alert("Invoice updated successfully!");
        const limit = rowsPerPage;
        const offset = page * rowsPerPage;
        const updatedDataResponse = await myAxiosCommonHost.get(
          // "gst/invoice/getInvoiceList?filterType=EMPLOYEEPOINVOICE"
          `gst/invoice/getInvoiceList?selectCompany=${selectedCompany}&filterType=EMPLOYEEPOINVOICE&financialYear=${selectedFinancialYear}&limit=${limit}&offset=${offset}`
        );
        setData(updatedDataResponse.data);
        setUpdateDialogOpen(false);
        setHasChanges(false); // Reset the flag when changes are saved
      } else {
        alert("Failed to update invoice. Please try again.");
      }
    } catch (error) {
      console.error("Error updating invoice:", error);
      alert("An error occurred while updating the invoice.");
    }
    setIsUpdating(false);
  };

  if (fetchError) {
    return <div>Error fetching data. Please try again later.</div>;
  }

  const handleDeleteClick = async (invoiceRefNo) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this record?"
    );
    if (confirmDelete) {
      setIsDeleting(true);
      await handleDeleteConfirmed(invoiceRefNo);
      setIsDeleting(false);
    }
  };
  const handleDeleteConfirmed = async (invoiceRefNo) => {
    setSelectedInvoiceRefNo(invoiceRefNo);
    setDeleteDialogOpen(true);
  };
  const handleDialogDelete = async () => {
    setIsDeleting(true);
    try {
      const response = await myAxiosCommonHost.get(
        `gst/login/verificationForDeletionRecord?userPassword=${password}`
      );
      if (response.status === 200 && response.data) {
        setPasswordVerified(true); // Set password verification status to true
        const confirmDelete = window.confirm(
          "Password verified. Are you sure you want to delete this record?"
        );
        if (confirmDelete) {
          // Perform deletion logic here
          const deleteResponse = await myAxiosCommonHost.delete(
            `gst/invoice/deleteInvoice/invoiceRefNo?invoiceRefNo=${selectedInvoiceRefNo}`
          );

          if (deleteResponse.status === 200) {
            console.log("Invoice deleted successfully!");
            const limit = rowsPerPage;
            const offset = page * rowsPerPage;
            const updatedDataResponse = await myAxiosCommonHost.get(
              `gst/invoice/getInvoiceList?selectCompany=${selectedCompany}&filterType=EMPLOYEEPOINVOICE&financialYear=${selectedFinancialYear}&limit=${limit}&offset=${offset}`
            );
            if (updatedDataResponse.status === 200) {
              const updatedResult = await updatedDataResponse.data;
              setData(updatedResult);
              //  setEditedData(updatedResult);
            } else {
              console.error("Failed to fetch updated data.");
            }
            setDeleteDialogOpen(false);
            setPassword(""); // Reset password field
          } else {
            console.error("Failed to delete PO.");
            alert("Failed to delete PO. Please try again.");
          }
        }
      } else {
        setPasswordVerified(false); // Set password verification status to false
        console.error("Failed to verify password.");
        alert("Incorrect password. Deletion canceled.");
      }
    } catch (error) {
      console.error("Error deleting Invoice:", error);
      alert("An error occurred while deleting the Invoice.");
    }
    setIsDeleting(false);
  };

  const handleProjectInputChange = (index, field) => (event) => {
    const value = event.target.value;
    setEditedInvoiceData((editedInvoiceData) => {
      const updatedList = [...editedInvoiceData.invoiceEmployeePoDetailsUIList];
      const currentItem = { ...updatedList[index], [field]: value };

      console.log(`Updating index: ${index}, field: ${field}, value: ${value}`);
      console.log(`currentItem before calculation:`, currentItem);

      const presenty = parseFloat(currentItem.presenty);
      const currentPoRate = parseFloat(currentItem.currentPoRate);
      if (!isNaN(presenty) && !isNaN(currentPoRate)) {
        const empRateUnit = editedInvoiceData.empRateUnit;
        if (empRateUnit === "Per Day" || empRateUnit === "Per Hour") {
          currentItem.presentyAmt = (presenty * currentPoRate).toFixed(2);
        } else if (empRateUnit === "Per Month") {
          const workingDays = parseFloat(editedInvoiceData.workingDays || 30);
          currentItem.presentyAmt = (
            (currentPoRate / workingDays) *
            presenty
          ).toFixed(2);
        } else {
          currentItem.presentyAmt = "";
        }
      } else {
        currentItem.presentyAmt = "";
      }

      console.log(`currentItem after calculation:`, currentItem);

      updatedList[index] = currentItem;

      const totalPresentyAmount = updatedList
        .reduce((total, item) => total + (parseFloat(item.presentyAmt) || 0), 0)
        .toFixed(2);

      console.log(`totalPresentyAmount: ${totalPresentyAmount}`);

      return {
        ...editedInvoiceData,
        invoiceEmployeePoDetailsUIList: updatedList,
        invoiceGstDetailsUI: {
          ...editedInvoiceData.invoiceGstDetailsUI,
          totalPresentyAmount: totalPresentyAmount,
        },
      };
    });
  };

  const handleGstDetailsChange = (key, value) => {
    setEditedInvoiceData((prevData) => ({
      ...prevData,
      invoiceGstDetailsUI: {
        ...prevData.invoiceGstDetailsUI,
        [key]: value,
      },
    }));
  };

  const handleCalculate = () => {
    const { totalPresentyAmount, cGST, sGST, iGST } =
      editedInvoiceData.invoiceGstDetailsUI;

    // Debugging logs to check the values
    console.log("totalPresentyAmount:", totalPresentyAmount);
    console.log("cGST:", cGST);
    console.log("sGST:", sGST);
    console.log("iGST:", iGST);

    const totalPresentyAmountParsed = parseFloat(totalPresentyAmount) || 0;
    const cgstParsed = parseFloat(cGST) || 0;
    const sgstParsed = parseFloat(sGST) || 0;
    const igstParsed = parseFloat(iGST) || 0;

    const cgstAmount = (cgstParsed / 100) * totalPresentyAmountParsed;
    const sgstAmount = (sgstParsed / 100) * totalPresentyAmountParsed;
    const igstAmount = (igstParsed / 100) * totalPresentyAmountParsed;
    const totalTaxAmt = cgstAmount + sgstAmount + igstAmount;
    const grandTotalAmount = totalPresentyAmountParsed + totalTaxAmt;

    // Debugging logs to check the calculated values
    console.log("cgstAmount:", cgstAmount);
    console.log("sgstAmount:", sgstAmount);
    console.log("igstAmount:", igstAmount);
    console.log("totalTaxAmt:", totalTaxAmt);
    console.log("grandTotalAmount:", grandTotalAmount);

    setEditedInvoiceData((prevData) => ({
      ...prevData,
      invoiceGstDetailsUI: {
        ...prevData.invoiceGstDetailsUI,
        cgstAmount: cgstAmount.toFixed(2),
        sgstAmount: sgstAmount.toFixed(2),
        igstAmount: igstAmount.toFixed(2),
        totalTaxAmt: totalTaxAmt.toFixed(2),
        grandTotalAmount: grandTotalAmount.toFixed(2),
      },
    }));
  };

  const handlegeneratePDF = async (invoiceRefNo) => {
    setIsDownloading(true);
    try {
      const response = await myAxiosCommonHost.get(
        `gst/invoice/viewInvoice?invoiceRefNo=${invoiceRefNo}`
      );
      const invoiceData = response.data[0];
      const { companyProfileUI, clientUI, gstdetailsUI } = invoiceData;
      setCompanyProfile(companyProfileUI);
      setClientDetails(clientUI);
      setGstDetails(gstdetailsUI);
      console.log("Company Profile:", companyProfileUI);
      console.log("Client Details:", clientUI);
      console.log("GST Details:", gstdetailsUI);
      // Determine empRateUnit from invoiceData
      const empRateUnit = invoiceData.empRateUnit;
      // Based on empRateUnit, generate corresponding PDF
      if (empRateUnit === "Per Day") {
        generatePDFPerDay(
          invoiceData,
          companyProfileUI,
          clientUI,
          gstdetailsUI
        );
      } else if (empRateUnit === "Per Month") {
        generatePDFPerMonth(
          invoiceData,
          companyProfileUI,
          clientUI,
          gstdetailsUI
        );
      } else if (empRateUnit === "Per Hour") {
        generatePDFPerHour(
          invoiceData,
          companyProfileUI,
          clientUI,
          gstdetailsUI
        );
      } else {
        console.log("Invalid empRateUnit value. Unable to generate PDF");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setIsDownloading(false);
  };
  // const handlegeneratePDF = (invoiceRefNo) => {
  //   setIsDownloading(true);
  //   const encodedInvoiceRefNo = encodeURIComponent(invoiceRefNo);
  //   myAxiosCommonHost
  //   .get(
  //     `gst/invoice/viewInvoice?invoiceRefNo=${encodedInvoiceRefNo}`
  //   )
  //     .then((response) => {
  //       setInvoiceData(response.data[0]);
  //       console.log(response.data);
  //       setIsDownloading(false);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching Quotation data:", error);
  //       setIsDownloading(false);
  //     });
  // };

  const generatePDFPerDay = async (
    invoiceData,
    companyProfile,
    clientDetails,
    gstDetails
  ) => {
    const doc = new jsPDF();

    const includePO = await Swal.fire({
      title: "Do you want to include PO number in the PDF?",
      icon: "question",
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        return true;
      } else {
        return false;
      }
    });

    // Define includeEmployeeCategoryWithPO variable
    let includeEmployeeCategoryWithPO;

    if (includePO) {
      includeEmployeeCategoryWithPO = await Swal.fire({
        title:
          "Do you want to include Employee Category with PO Number in the PDF?",
        icon: "question",
        showDenyButton: true,
        confirmButtonText: "Yes",
        denyButtonText: `No`,
      }).then((result) => {
        if (result.isConfirmed) {
          return true;
        } else {
          return false;
        }
      });

      await generatePDF(includePO, includeEmployeeCategoryWithPO);
    } else {
      await generatePDF(includePO, false);
    }
    // Call generatePDF function with correct parameters
    async function generatePDF(includePO, includeEmployeeCategoryWithPO) {
      const encompassingRectX = 3;
      const encompassingRectY = 4;
      const encompassingRectWidth = doc.internal.pageSize.width - 6;
      const encompassingRectHeight = doc.internal.pageSize.height - 8;

      doc.setDrawColor(0); // Set border color to black
      doc.setLineWidth(0.4); // Set border width
      doc.rect(
        encompassingRectX,
        encompassingRectY,
        encompassingRectWidth,
        encompassingRectHeight
      );

      let companyInfoText =
        `${companyProfile.companyName}\n` +
        `${companyProfile.companyAddress}\n` +
        `${companyProfile.website}\n` +
        `${companyProfile.eMail}`;

      // Add the company information to the PDF for the current invoice
      doc.setFontSize(10);
      doc.setTextColor(0, 0, 0); // Black color
      doc.setFont("helvetica", "bold"); // Set font to bold
      doc.text(companyInfoText, 15, 30);
      doc.setFont("helvetica", "normal"); // Reset font to normal

      // Continue with the rest of your PDF generation logic...

      const companyInfoContainerX = 14;
      const companyInfoContainerY = 10;
      const companyInfoContainerWidth =
        doc.internal.pageSize.width * 0.55 - companyInfoContainerX - 10;
      const companyInfoContainerHeight = 40;

      // Draw the container first
      doc.setDrawColor(0);
      doc.setLineWidth(0.4);
      doc.setFillColor(255, 255, 255);
      doc.rect(
        companyInfoContainerX,
        companyInfoContainerY,
        companyInfoContainerWidth,
        companyInfoContainerHeight,
        "FD"
      );
      if (invoiceData.selectCompany == "GST") {
        // Add the GsquareLOGO with additional margin from the left border
        doc.addImage(GsquareLOGO, "JPEG", 15, 11, 28, 28); // Change x coordinate (e.g., 25 or 30)
        doc.setFontSize(10);
        doc.setTextColor(0, 0, 0);
        doc.text(companyInfoText, 25 + 20 + 2, 18, {
          // Change margin after GsquareLOGO width (e.g., 2 or 3)
          maxWidth: doc.internal.pageSize.width * 0.55 - 25 - 20 - 15, // Calculate available width minus adjusted logo position and margins
        });
      }
      if (invoiceData.selectCompany == "BGD") {
        // Add the Bgd_LOGO with additional margin from the left border
        doc.addImage(Bgd_LOGO, "JPEG", 15, 11, 28, 28); // Change x coordinate (e.g., 25 or 30)
        doc.setFontSize(10);
        doc.setTextColor(0, 0, 0);
        doc.text(companyInfoText, 25 + 20 + 2, 18, {
          // Change margin after Bgd_LOGO width (e.g., 2 or 3)
          maxWidth: doc.internal.pageSize.width * 0.55 - 25 - 20 - 15, // Calculate available width minus adjusted logo position and margins
        });
      }

      if (invoiceData.invoiceTitle) {
        const title = invoiceData.invoiceTitle || "";
        const titleHeight = 3;
        const titleY = encompassingRectY + 1;
        doc.setFontSize(10);
        doc.text(title, doc.internal.pageSize.width / 2, titleY + titleHeight, {
          align: "center",
        });
      }

      const CompInvo = [
        ["Invoice No: " + invoiceData.invoiceRefNo],
        ["Company's GST NO.: " + companyProfile.gstin], // Include GSTIN directly
        ["Company's PAN No.: " + companyProfile.pan], // Include PAN directly
        ["Company's CIN: " + companyProfile.cin], // Include CIN directly
        ["Invoice Date: " + invoiceData.invoiceDate],
      ];

      const tableWidth = doc.internal.pageSize.width * 0.45;
      const leftMargin = doc.internal.pageSize.width - tableWidth - 10;

      // Calculate the height of the CompInvo table
      const compInvoTableHeight = doc.autoTable({
        body: CompInvo,
        theme: "grid",
        margin: { top: 10, left: leftMargin, right: 14, bottom: 0 },
        styles: {
          fontSize: 10,
          lineWidth: 0.5,
          lineColor: [0, 0, 0],
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          cellPadding: 2,
          marginBottom: 0,
          marginTop: 0,
          padding: 0,
        },
      }).lastAutoTable.finalY;

      const ClientInvo = [
        ["Service ACC Code: " + gstDetails.hsnsac],
        ["Client's GSTIN: " + gstDetails.gstin],
        ["Payment Term: 15 Days Bank Transfer"],
        ["Onsite Manpower Support for the month"], // No need to concatenate, as it's a single value
        [`${invoiceData.paymentMonth} ${invoiceData.paymentYear}`],
      ];
      // Set the startY parameter for ClientInvo table to start immediately after CompInvo table
      doc.autoTable({
        body: ClientInvo,
        theme: "grid",
        columnStyles: { 0: { cellWidth: "auto" } },
        startY: compInvoTableHeight, // Set startY explicitly
        margin: {
          top: compInvoTableHeight,
          left: leftMargin,
          right: 14,
          bottom: 0,
        },
        styles: {
          fontSize: 10,
          lineWidth: 0.5,
          lineColor: [0, 0, 0],
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          marginBottom: 0,
          tableHeight: 40,
        },
      });
      const billedToContainerHeight = 38.85;
      const clientAddressLines = clientDetails.clientAddress || ""; // Split the address into multiple lines
      // const billedToTextLines = [
      //   `Billed To: ${clientDetails.clientName.trim() || ""} `,
      //   ...clientAddressLines.trim().split("\n"), // Spread the address lines into the array
      //   "",
      //   `Place of supply: ${gstDetails.state || ""} State Code: ${
      //     gstDetails.stateCode || ""
      //   }`,
      //   `Ph: ${clientDetails.clientContactNumber || ""}`,
      //   `Kind Attn: ${invoiceData.invoicePaymentModeUI.kindAttention || ""} `,
      // ];
      const billedToTextLines = [
        `Billed To: ${clientDetails.clientName || ""} `,
        ...clientAddressLines.split("\n"), // Spread the address lines into the array
        "",
        `Place of supply: ${gstDetails.state || ""} State Code: ${
          gstDetails.stateCode || ""
        }`,
        `Ph: ${clientDetails.clientContactNumber || ""}`,
        `Kind Attn: ${invoiceData.invoicePaymentModeUI.kindAttention || ""} `,
      ];

      const billedToContainerX = companyInfoContainerX;
      const topMargin = 0;
      const containerHeight = 40; // Set the common height for both the billedToContainer and the table
      const billedToContainerY =
        companyInfoContainerY + companyInfoContainerHeight + topMargin;
      const billedToContainerWidth = companyInfoContainerWidth;

      doc.setDrawColor(0);
      doc.setLineWidth(0.4);
      doc.setFillColor(255, 255, 255);
      doc.rect(
        billedToContainerX,
        billedToContainerY,
        billedToContainerWidth,
        containerHeight,
        "FD"
      );

      doc.setFontSize(10);
      doc.setTextColor(0, 0, 0);

      const lineHeight = 6; // Adjusted line height
      let yPosition = billedToContainerY + 5;

      billedToTextLines.forEach((line) => {
        doc.text(line.trim(), billedToContainerX + 5, yPosition, {
          maxWidth: billedToContainerWidth - 10,
        });
        yPosition += lineHeight;
      });
      doc.setFillColor(255, 255, 255);

      let tableData;
      let amountColumnIndex;
      if (includePO) {
        // Include PO number column in the PDF
        if (includeEmployeeCategoryWithPO) {
          tableData = invoiceData.invoiceEmployeePoDetailsUIList.map(
            (row, index) => [
              index + 1,
              row.emplyoyeeName,
              invoiceData.poNumber,
              row.employeeCategory,
              row.fromDate,
              row.toDate,

              row.presenty,
              row.currentPoRate,
              row.presentyAmt, // Calculate amount
            ]
          );
          amountColumnIndex = 7;
        } else {
          tableData = invoiceData.invoiceEmployeePoDetailsUIList.map(
            (row, index) => [
              index + 1,
              row.emplyoyeeName,

              row.fromDate,
              row.toDate,

              row.presenty,
              row.currentPoRate,
              row.presentyAmt, // Calculate amount
            ]
          );
          amountColumnIndex = 6;
        }
      } else {
        // Exclude PO number column in the PDF
        tableData = invoiceData.invoiceEmployeePoDetailsUIList.map(
          (row, index) => [
            index + 1,
            row.emplyoyeeName,

            row.fromDate,
            row.toDate,

            row.presenty,
            row.currentPoRate,
            row.presentyAmt, // Calculate amount
          ]
        );
        amountColumnIndex = 5;
      }

      const headerLabels =
        includePO && includeEmployeeCategoryWithPO
          ? [
              "Sr.No",
              "Particulars",
              "PO No.",
              "Employee Category",
              "From Date",
              "To Date",
              "Billable Days",
              "Current PO Rate",
              "Amount",
            ]
          : includePO && !includeEmployeeCategoryWithPO
          ? [
              "Sr.No",
              "Particulars",
              "From Date",
              "To Date",
              "Billable Days",
              "Current PO Rate",
              "Amount",
            ]
          : [
              "Sr.No",
              "Particulars",
              "From Date",
              "To Date",
              "Billable Days",
              "Current PO Rate",
              "Amount",
            ];

      // Generate the PDF table
      doc.autoTable({
        head: [headerLabels],

        body: tableData,
        startY: billedToContainerY + billedToContainerHeight + 0,
        theme: "grid",
        styles: {
          fontSize: 9,
          lineWidth: 0.5,
          lineColor: [0, 0, 0],
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          marginBottom: 0,
        },
        columnStyles: {
          [amountColumnIndex]: { cellWidth: 25, halign: "right" }, // Set width for the "Amount" column
        },
      });

      // Calculate the height of the first table (invoice data table)
      const firstTableHeight = doc.previousAutoTable
        ? doc.previousAutoTable.finalY
        : 0;

      const {
        totalPresentyAmount,
        grandTotalAmount,
        totalTaxAmt,
        totalInWordAmount,
      } = invoiceData.invoiceGstDetailsUI;
      const { cgstAmount, sgstAmount, igstAmount } =
        invoiceData.invoiceGstDetailsUI;

      const InvoiceData = [
        ["A] Total", totalPresentyAmount],
        ["B] CGST (1)", ` ${cgstAmount}`],
        ["C] SGST (2)", ` ${sgstAmount}`],
        ["D] IGST (3)", ` ${igstAmount}`],
        ["E] Total (A+B+C+D)", grandTotalAmount],
        ["F] Deduction ", 0],
        ["G] Grand Total (F-G)", grandTotalAmount],
        [{ content: " Total In Words: " + totalInWordAmount, colSpan: 2 }],
      ];

      // Set startY for the second table immediately after the first table
      doc.autoTable({
        body: InvoiceData,
        startY: firstTableHeight + 0,
        theme: "grid",
        styles: {
          lineWidth: 0.5,
          lineColor: [0, 0, 0],
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          marginBottom: 0,
        },
        columnStyles: {
          0: { cellWidth: 157 },
          1: { cellWidth: 25, halign: "right" },
        },
      });
      // Calculate the height of the second table (invoice data table)
      const secondTableHeight = doc.previousAutoTable
        ? doc.previousAutoTable.finalY
        : 0;

      // Calculate remaining space on the page
      const remainingSpace =
        doc.internal.pageSize.getHeight() - secondTableHeight - 20;

      // Check if there is enough space for the bank table
      if (remainingSpace < 0) {
        doc.addPage();
      }
      let finalY = doc.internal.pageSize.height;

      if (doc.previousAutoTable && doc.previousAutoTable.finalY) {
        finalY = doc.previousAutoTable.finalY + 5; // Add some padding
      }

      const innerRectHeight = 60; // Set a fixed height for the inner rectangle
      const remainingSpace2 = doc.internal.pageSize.height - finalY;

      // Check if the inner rectangle can fit in the remaining space
      if (remainingSpace2 < innerRectHeight) {
        doc.addPage();
        finalY = 15; // Reset Y position for new page
      }

      // Draw the encompassing border at the end
      doc.setDrawColor(0); // Set border color to black
      doc.setLineWidth(0.4); // Set border width
      //draw Big border for the pdf page
      doc.rect(
        3,
        4,
        doc.internal.pageSize.width - 6,
        finalY > doc.internal.pageSize.height - 8
          ? finalY
          : doc.internal.pageSize.height - 8
      );

      // const innerRectX = 20;
      // const innerRectY = finalY + 0; // Start just after the previous table
      // const innerRectWidth = doc.internal.pageSize.width - 30;
      const innerRectX = 14;
      const innerRectY = finalY - 5; // Start just after the previous table
      const innerRectWidth = 182;
      // Draw the inner rectangle
      doc.rect(innerRectX, innerRectY, innerRectWidth, innerRectHeight);

      // Draw the vertical line to split the inner rectangle
      const splitLineX = innerRectX + innerRectWidth / 2;
      doc.line(
        splitLineX,
        innerRectY,
        splitLineX,
        innerRectY + innerRectHeight
      );

      // Add bank details to the left section
      const bankDetailsArray = [
        `Bank Name: ${invoiceData.bankUI.bankName}`,
        `Bank Address: ${invoiceData.bankUI.bankAddress}`,
        `A/C No.: ${invoiceData.bankUI.accountNumber}`,
        `IFC Code: ${invoiceData.bankUI.ifsc}`,
        `Swift Code: ${invoiceData.bankUI.swiftCode}`,
      ];

      const maxTextWidth = innerRectWidth / 2 - 10; // Maximum width for the text in the left section
      let leftTextY = innerRectY + 10; // Initial Y position for the left text

      const lineHeight2 = 7; // Increase line height for more margin

      bankDetailsArray.forEach((line) => {
        const lines = doc.splitTextToSize(line, maxTextWidth);
        lines.forEach((wrappedLine) => {
          doc.text(wrappedLine, innerRectX + 5, leftTextY);
          leftTextY += lineHeight2; // Adjust the line height for more margin
        });
      });

      // Add text to the right section
      const rightText1 = "For Gsquare Techsystem PVT LTD";
      const rightText2 = "Authorized Signatory & Seal";
      const rightText1Width = doc.getTextWidth(rightText1);
      const rightText2Width = doc.getTextWidth(rightText2);

      const rightTextX1 =
        splitLineX + (innerRectWidth / 2 - rightText1Width) / 2;
      const rightTextX2 =
        splitLineX + (innerRectWidth / 2 - rightText2Width) / 2;

      const gapBetweenLines = 30; // Set the gap between the lines here

      doc.text(rightText1, rightTextX1, innerRectY + 10);
      doc.text(rightText2, rightTextX2, innerRectY + 10 + gapBetweenLines); // Adjust the Y position for the second line

      const annexureFlag =
        invoiceData.invoiceEmployeePoDetailsUIList[0].annenuxFlag;
      if (annexureFlag === true) {
        const { clientName, invoiceRefNo, clientUI, invoiceDate, sacHsn } =
          invoiceData;
        doc.addPage();

        // Add page border
        doc.setDrawColor(0);
        doc.setLineWidth(0.3);
        doc.rect(
          5,
          5,
          doc.internal.pageSize.getWidth() - 10,
          doc.internal.pageSize.getHeight() - 10
        );

        // Add title in the center
        const pageTitle = "Annexure";
        doc.setFontSize(18);
        const pageWidth = doc.internal.pageSize.getWidth();
        const titleWidth =
          (doc.getStringUnitWidth(pageTitle) * doc.internal.getFontSize()) /
          doc.internal.scaleFactor;
        const titleX = (pageWidth - titleWidth) / 2;
        // doc.setFont("helvetica", "normal");
        // doc.setTextColor(255, 0, 0); // RGB for black color
        doc.text(pageTitle, titleX, 20);

        // Border around additional fields and table
        const borderX = 10;
        const borderY = 40;
        const borderWidth = doc.internal.pageSize.getWidth() - 20; // Adjusted width to fit within page
        const borderHeight = doc.internal.pageSize.getHeight() - 60; // Adjust height as needed
        doc.rect(borderX, borderY, borderWidth, borderHeight);

        // Additional Fields
        const additionalFieldsTop = borderY + 10;
        const additionalFieldsMargin = 10; // Margin between additional fields
        doc.setFontSize(10);
        doc.text(15, additionalFieldsTop, `Client Name: ${clientName}`);
        doc.text(
          15,
          additionalFieldsTop + additionalFieldsMargin,
          `Invoice No.: ${invoiceRefNo}`
        );
        doc.text(
          15,
          additionalFieldsTop + additionalFieldsMargin * 2,
          `Invoice Date: ${invoiceDate.split("-").reverse().join("-") || "N/A"}`
        );
        // // Bold and colored Invoice Date
        // doc.setFont("helvetica", "bold");
        // doc.setTextColor(255, 0, 0); // RGB for red color
        // doc.text(
        //   15,
        //   additionalFieldsTop + additionalFieldsMargin * 2,
        //   "Invoice Date:"
        // );

        // // Reset font and color for the date value
        // doc.setFont("helvetica", "normal");
        // doc.setTextColor(0, 0, 0); // RGB for black color
        // doc.text(
        //   40, // Adjusted x-coordinate to align after "Invoice Date:"
        //   additionalFieldsTop + additionalFieldsMargin * 2,
        //   `${invoiceDate.split("-").reverse().join("-") || "N/A"}`
        // );
        doc.text(
          15,
          additionalFieldsTop + additionalFieldsMargin * 3,
          `Company's Service Accounting Code (SAC): ${sacHsn}`
        );
        doc.text(
          15,
          additionalFieldsTop + additionalFieldsMargin * 4,
          "Subject: Facilities-Canteen & Transportation Deductions"
        );

        // Table
        const tableTop = additionalFieldsTop + additionalFieldsMargin * 5 + 10; // Adjust spacing between additional fields and table
        const tableHeaders = [
          "Sr.No",
          "Employee Name",
          "Presenty",
          "Canteen Amount",
          "Transportation Amount",
          "Total Amount",
        ];
        const tableData = invoiceData.invoiceEmployeePoDetailsUIList.map(
          (employee, index) => [
            index + 1,
            employee.emplyoyeeName,
            employee.presenty,
            employee.canteenAmt,
            employee.transportationAmt,
            employee.canteenAmt + employee.transportationAmt,
          ]
        );

        // Calculate available height for the table
        const availableHeight = borderHeight - (tableTop - borderY) - 9; // Subtract spacing for padding
        const rowHeight = 8; // Estimate the height of a row
        const maxRows = Math.floor(availableHeight / rowHeight); // Calculate max rows that fit within the available height
        const newPageTableTop = 30; // Adjust the top margin for new pages

        // Split tableData into chunks that fit within the available height
        let currentY = tableTop;
        for (let i = 0; i < tableData.length; i += maxRows) {
          if (i > 0) {
            doc.addPage();
            doc.setDrawColor(0);
            doc.setLineWidth(0.3);
            // doc.rect(borderX, borderY, borderWidth, borderHeight);
            doc.rect(
              5,
              5,
              doc.internal.pageSize.getWidth() - 10,
              doc.internal.pageSize.getHeight() - 10
            );
            // currentY = tableTop; // Reset the currentY for the new page
            currentY = newPageTableTop; // Adjust the currentY for the new page
          }
          const tableChunk = tableData.slice(i, i + maxRows);
          doc.autoTable({
            head: [tableHeaders],
            body: tableChunk,
            startY: currentY,
            theme: "grid",
            styles: {
              fontSize: 9,
              lineWidth: 0.3,
              lineColor: [0, 0, 0],
              fillColor: [255, 255, 255],
              textColor: [0, 0, 0],
              marginBottom: 0,
            },
            margin: { left: borderX + 5, right: borderX + 5 }, // Adjust margins to fit within the border
            tableWidth: borderWidth - 10, // Adjust table width to fit within the border
            columnStyles: {
              0: { cellWidth: 20 }, // Adjust column width if needed
              1: { cellWidth: 40 }, // Example for adjusting column width
              2: { cellWidth: 30 },
              3: { cellWidth: 30 },
              4: { cellWidth: 30 },
              5: { cellWidth: 30 },
            },
          });
          currentY += tableChunk.length * rowHeight - 10; // Adjust currentY for the next chunk
        }
      }

      const pdfData = doc.output("blob"); // Assuming doc.output provides Blob object
      const pdfWindow = window.open("", "_blank");
      const pdfURL = URL.createObjectURL(pdfData);
      pdfWindow.location.href = pdfURL;
    }
  };

  const generatePDFPerMonth = async (
    invoiceData,
    companyProfile,
    clientDetails,
    gstDetails
  ) => {
    const doc = new jsPDF();

    const includePO = await Swal.fire({
      title: "Do you want to include PO number in the PDF?",
      icon: "question",
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        return true;
      } else {
        return false;
      }
    });

    // Define includeEmployeeCategoryWithPO variable
    let includeEmployeeCategoryWithPO;

    if (includePO) {
      includeEmployeeCategoryWithPO = await Swal.fire({
        title:
          "Do you want to include Employee Category with PO Number in the PDF?",
        icon: "question",
        showDenyButton: true,
        confirmButtonText: "Yes",
        denyButtonText: `No`,
      }).then((result) => {
        if (result.isConfirmed) {
          return true;
        } else {
          return false;
        }
      });

      await generatePDF(includePO, includeEmployeeCategoryWithPO);
    } else {
      await generatePDF(includePO, false);
    }
    // Call generatePDF function with correct parameters
    async function generatePDF(includePO, includeEmployeeCategoryWithPO) {
      const encompassingRectX = 3;
      const encompassingRectY = 4;
      const encompassingRectWidth = doc.internal.pageSize.width - 6;
      const encompassingRectHeight = doc.internal.pageSize.height - 8;

      doc.setDrawColor(0); // Set border color to black
      doc.setLineWidth(0.4); // Set border width
      doc.rect(
        encompassingRectX,
        encompassingRectY,
        encompassingRectWidth,
        encompassingRectHeight
      );

      let companyInfoText =
        `${companyProfile.companyName}\n` +
        `${companyProfile.companyAddress}\n` +
        `${companyProfile.website}\n` +
        `${companyProfile.eMail}`;

      // Add the company information to the PDF for the current invoice
      doc.setFontSize(10);
      doc.setTextColor(0, 0, 0); // Black color
      doc.setFont("helvetica", "bold"); // Set font to bold
      doc.text(companyInfoText, 15, 30);
      doc.setFont("helvetica", "normal"); // Reset font to normal

      // Continue with the rest of your PDF generation logic...

      const companyInfoContainerX = 14;
      const companyInfoContainerY = 10;
      const companyInfoContainerWidth =
        doc.internal.pageSize.width * 0.55 - companyInfoContainerX - 10;
      const companyInfoContainerHeight = 40;

      // Draw the container first
      doc.setDrawColor(0);
      doc.setLineWidth(0.4);
      doc.setFillColor(255, 255, 255);
      doc.rect(
        companyInfoContainerX,
        companyInfoContainerY,
        companyInfoContainerWidth,
        companyInfoContainerHeight,
        "FD"
      );

      if (invoiceData.selectCompany == "GST") {
        // Add the GsquareLOGO with additional margin from the left border
        doc.addImage(GsquareLOGO, "JPEG", 15, 11, 28, 28); // Change x coordinate (e.g., 25 or 30)
        doc.setFontSize(10);
        doc.setTextColor(0, 0, 0);
        doc.text(companyInfoText, 25 + 20 + 2, 18, {
          // Change margin after GsquareLOGO width (e.g., 2 or 3)
          maxWidth: doc.internal.pageSize.width * 0.55 - 25 - 20 - 15, // Calculate available width minus adjusted logo position and margins
        });
      }
      if (invoiceData.selectCompany == "BGD") {
        // Add the Bgd_LOGO with additional margin from the left border
        doc.addImage(Bgd_LOGO, "JPEG", 15, 11, 28, 28); // Change x coordinate (e.g., 25 or 30)
        doc.setFontSize(10);
        doc.setTextColor(0, 0, 0);
        doc.text(companyInfoText, 25 + 20 + 2, 18, {
          // Change margin after Bgd_LOGO width (e.g., 2 or 3)
          maxWidth: doc.internal.pageSize.width * 0.55 - 25 - 20 - 15, // Calculate available width minus adjusted logo position and margins
        });
      }

      if (invoiceData.invoiceTitle) {
        const title = invoiceData.invoiceTitle || "";
        const titleHeight = 3;
        const titleY = encompassingRectY + 1;
        doc.setFontSize(10);
        doc.text(title, doc.internal.pageSize.width / 2, titleY + titleHeight, {
          align: "center",
        });
      }
      const CompInvo = [
        ["Invoice No: " + invoiceData.invoiceRefNo],
        ["Company's GST NO.: " + companyProfile.gstin], // Include GSTIN directly
        ["Company's PAN No.: " + companyProfile.pan], // Include PAN directly
        ["Company's CIN: " + companyProfile.cin], // Include CIN directly
        ["Invoice Date: " + invoiceData.invoiceDate],
      ];

      const tableWidth = doc.internal.pageSize.width * 0.45;
      const leftMargin = doc.internal.pageSize.width - tableWidth - 10;

      // Calculate the height of the CompInvo table
      const compInvoTableHeight = doc.autoTable({
        body: CompInvo,
        theme: "grid",
        margin: { top: 10, left: leftMargin, right: 14, bottom: 0 },
        styles: {
          fontSize: 10,
          lineWidth: 0.5,
          lineColor: [0, 0, 0],
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          cellPadding: 2,
          marginBottom: 0,
          marginTop: 0,
          padding: 0,
        },
      }).lastAutoTable.finalY;

      const ClientInvo = [
        ["Service ACC Code: " + gstDetails.hsnsac],
        ["Client's GSTIN: " + gstDetails.gstin],
        ["Payment Term: 15 Days Bank Transfer"],
        ["Onsite Manpower Support for the month"], // No need to concatenate, as it's a single value
        [`${invoiceData.paymentMonth} ${invoiceData.paymentYear}`],
      ];
      // Set the startY parameter for ClientInvo table to start immediately after CompInvo table
      doc.autoTable({
        body: ClientInvo,
        theme: "grid",
        columnStyles: { 0: { cellWidth: "auto" } },
        startY: compInvoTableHeight, // Set startY explicitly
        margin: {
          top: compInvoTableHeight,
          left: leftMargin,
          right: 14,
          bottom: 0,
        },
        styles: {
          fontSize: 10,
          lineWidth: 0.5,
          lineColor: [0, 0, 0],
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          marginBottom: 0,
          tableHeight: 40,
        },
      });

      const billedToContainerHeight = 38.85;
      const clientAddressLines = clientDetails.clientAddress || ""; // Split the address into multiple lines
      const billedToTextLines = [
        `Billed To: ${clientDetails.clientName || ""} `,
        ...clientAddressLines.split("\n"), // Spread the address lines into the array
        "",
        `Place of supply: ${gstDetails.state || ""} State Code: ${
          gstDetails.stateCode || ""
        }`,
        `Ph: ${clientDetails.clientContactNumber || ""}`,
        `Kind Attn: ${invoiceData.invoicePaymentModeUI.kindAttention || ""} `,
      ];
      const billedToContainerX = companyInfoContainerX;
      const topMargin = 0;
      const containerHeight = 40; // Set the common height for both the billedToContainer and the table
      const billedToContainerY =
        companyInfoContainerY + companyInfoContainerHeight + topMargin;
      const billedToContainerWidth = companyInfoContainerWidth;

      doc.setDrawColor(0);
      doc.setLineWidth(0.4);
      doc.setFillColor(255, 255, 255);
      doc.rect(
        billedToContainerX,
        billedToContainerY,
        billedToContainerWidth,
        containerHeight,
        "FD"
      );

      doc.setFontSize(10);
      doc.setTextColor(0, 0, 0);

      const lineHeight = 6; // Adjusted line height
      let yPosition = billedToContainerY + 5;

      billedToTextLines.forEach((line) => {
        doc.text(line.trim(), billedToContainerX + 5, yPosition, {
          maxWidth: billedToContainerWidth - 10,
        });
        yPosition += lineHeight;
      });
      doc.setFillColor(255, 255, 255);

      let tableData;
      let amountColumnIndex;
      if (includePO) {
        // Include PO number column in the PDF
        if (includeEmployeeCategoryWithPO) {
          tableData = invoiceData.invoiceEmployeePoDetailsUIList.map(
            (row, index) => [
              index + 1,
              row.emplyoyeeName,
              invoiceData.poNumber,
              row.employeeCategory,
              row.fromDate,
              row.toDate,
              invoiceData.workingDays,
              row.presenty,
              row.currentPoRate,
              row.presentyAmt, // Calculate amount
            ]
          );
          amountColumnIndex = 8;
        } else {
          tableData = invoiceData.invoiceEmployeePoDetailsUIList.map(
            (row, index) => [
              index + 1,
              row.emplyoyeeName,
              invoiceData.poNumber,
              row.fromDate,
              row.toDate,
              invoiceData.workingDays,
              row.presenty,
              row.currentPoRate,
              row.presentyAmt, // Calculate amount
            ]
          );
          amountColumnIndex = 7;
        }
      } else {
        // Exclude PO number column in the PDF
        tableData = invoiceData.invoiceEmployeePoDetailsUIList.map(
          (row, index) => [
            index + 1,
            row.emplyoyeeName,

            row.fromDate,
            row.toDate,
            invoiceData.workingDays,
            row.presenty,
            row.currentPoRate,
            row.presentyAmt, // Calculate amount
          ]
        );
        amountColumnIndex = 7;
      }

      const headerLabels =
        includePO && includeEmployeeCategoryWithPO
          ? [
              "Sr.No",
              "Particulars",
              "PO No.",
              "Employee Category",
              "From Date",
              "To date",
              "Working Days",
              "Billable Days",
              "Current PO Rate",
              "Amount",
            ]
          : includePO && !includeEmployeeCategoryWithPO
          ? [
              "Sr.No",
              "Particulars",
              "PO NO.",
              "From Date",
              "To date",
              "Working Days",
              "Billable Days",
              "Current PO Rate",
              "Amount",
            ]
          : [
              "Sr.No",
              "Particulars",
              "From Date",
              "To date",
              "Working Days",
              "Billable Days",
              "Current PO Rate",
              "Amount",
            ];

      // Generate the PDF table
      doc.autoTable({
        head: [headerLabels],

        body: tableData,
        startY: billedToContainerY + billedToContainerHeight + 0,
        theme: "grid",
        styles: {
          fontSize: 9,
          lineWidth: 0.5,
          lineColor: [0, 0, 0],
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          marginBottom: 0,
        },
        columnStyles: {
          [amountColumnIndex]: { cellWidth: 25, halign: "right" }, // Set width for the "Amount" column
        },
      });
      // Calculate the height of the first table (invoice data table)
      const firstTableHeight = doc.previousAutoTable
        ? doc.previousAutoTable.finalY
        : 0;

      const {
        totalPresentyAmount,
        grandTotalAmount,
        totalTaxAmt,
        totalInWordAmount,
      } = invoiceData.invoiceGstDetailsUI;
      const { cgstAmount, sgstAmount, igstAmount } =
        invoiceData.invoiceGstDetailsUI;

      const InvoiceData = [
        ["A] Total", totalPresentyAmount],
        ["B] CGST (1)", ` ${cgstAmount}`],
        ["C] SGST (2)", ` ${sgstAmount}`],
        ["D] IGST (3)", ` ${igstAmount}`],
        ["E] Total (A+B+C+D)", grandTotalAmount],
        ["F] Deduction ", 0],
        ["G] Grand Total (F-G)", grandTotalAmount],
        [{ content: " Total In Words: " + totalInWordAmount, colSpan: 2 }],
      ];

      // Set startY for the second table immediately after the first table
      doc.autoTable({
        body: InvoiceData,
        startY: firstTableHeight + 0,
        theme: "grid",
        styles: {
          lineWidth: 0.5,
          lineColor: [0, 0, 0],
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          marginBottom: 0,
        },
        columnStyles: {
          0: { cellWidth: 157 },
          1: { cellWidth: 25, halign: "right" },
        },
      });

      // Calculate the height of the second table (invoice data table)
      const secondTableHeight = doc.previousAutoTable
        ? doc.previousAutoTable.finalY
        : 0;

      // Calculate remaining space on the page
      const remainingSpace =
        doc.internal.pageSize.getHeight() - secondTableHeight - 20;

      // Check if there is enough space for the bank table
      if (remainingSpace < 0) {
        doc.addPage();
      }
      let finalY = doc.internal.pageSize.height;

      if (doc.previousAutoTable && doc.previousAutoTable.finalY) {
        finalY = doc.previousAutoTable.finalY + 5; // Add some padding
      }

      const innerRectHeight = 60; // Set a fixed height for the inner rectangle
      const remainingSpace2 = doc.internal.pageSize.height - finalY;

      // Check if the inner rectangle can fit in the remaining space
      if (remainingSpace2 < innerRectHeight) {
        doc.addPage();
        finalY = 15; // Reset Y position for new page
      }

      // Draw the encompassing border at the end
      doc.setDrawColor(0); // Set border color to black
      doc.setLineWidth(0.4); // Set border width
      //draw Big border for the pdf page
      doc.rect(
        3,
        4,
        doc.internal.pageSize.width - 6,
        finalY > doc.internal.pageSize.height - 8
          ? finalY
          : doc.internal.pageSize.height - 8
      );

      const innerRectX = 14;
      const innerRectY = finalY - 5; // Start just after the previous table
      const innerRectWidth = 182;
      // Draw the inner rectangle
      doc.rect(innerRectX, innerRectY, innerRectWidth, innerRectHeight);

      // Draw the vertical line to split the inner rectangle
      const splitLineX = innerRectX + innerRectWidth / 2;
      doc.line(
        splitLineX,
        innerRectY,
        splitLineX,
        innerRectY + innerRectHeight
      );

      // Add bank details to the left section
      const bankDetailsArray = [
        `Bank Name: ${invoiceData.bankUI.bankName}`,
        `Bank Address: ${invoiceData.bankUI.bankAddress}`,
        `A/C No.: ${invoiceData.bankUI.accountNumber}`,
        `IFC Code: ${invoiceData.bankUI.ifsc}`,
        `Swift Code: ${invoiceData.bankUI.swiftCode}`,
      ];

      const maxTextWidth = innerRectWidth / 2 - 10; // Maximum width for the text in the left section
      let leftTextY = innerRectY + 10; // Initial Y position for the left text

      const lineHeight2 = 7; // Increase line height for more margin

      bankDetailsArray.forEach((line) => {
        const lines = doc.splitTextToSize(line, maxTextWidth);
        lines.forEach((wrappedLine) => {
          doc.text(wrappedLine, innerRectX + 5, leftTextY);
          leftTextY += lineHeight2; // Adjust the line height for more margin
        });
      });

      // Add text to the right section
      const rightText1 = "For Gsquare Techsystem PVT LTD";
      const rightText2 = "Authorized Signatory & Seal";
      const rightText1Width = doc.getTextWidth(rightText1);
      const rightText2Width = doc.getTextWidth(rightText2);

      const rightTextX1 =
        splitLineX + (innerRectWidth / 2 - rightText1Width) / 2;
      const rightTextX2 =
        splitLineX + (innerRectWidth / 2 - rightText2Width) / 2;

      const gapBetweenLines = 30; // Set the gap between the lines here

      doc.text(rightText1, rightTextX1, innerRectY + 10);
      doc.text(rightText2, rightTextX2, innerRectY + 10 + gapBetweenLines); // Adjust the Y position for the second line

      const annexureFlag =
        invoiceData.invoiceEmployeePoDetailsUIList[0].annenuxFlag;
      if (annexureFlag === true) {
        const { clientName, invoiceRefNo, clientUI, invoiceDate, sacHsn } =
          invoiceData;
        doc.addPage();

        // Add page border
        doc.setDrawColor(0);
        doc.setLineWidth(0.3);
        doc.rect(
          5,
          5,
          doc.internal.pageSize.getWidth() - 10,
          doc.internal.pageSize.getHeight() - 10
        );

        // Add title in the center
        const pageTitle = "Annexure";
        doc.setFontSize(18);
        const pageWidth = doc.internal.pageSize.getWidth();
        const titleWidth =
          (doc.getStringUnitWidth(pageTitle) * doc.internal.getFontSize()) /
          doc.internal.scaleFactor;
        const titleX = (pageWidth - titleWidth) / 2;
        // doc.setFont("helvetica", "normal");
        // doc.setTextColor(255, 0, 0); // RGB for black color
        doc.text(pageTitle, titleX, 20);

        // Border around additional fields and table
        const borderX = 10;
        const borderY = 40;
        const borderWidth = doc.internal.pageSize.getWidth() - 20; // Adjusted width to fit within page
        const borderHeight = doc.internal.pageSize.getHeight() - 60; // Adjust height as needed
        doc.rect(borderX, borderY, borderWidth, borderHeight);

        // Additional Fields
        const additionalFieldsTop = borderY + 10;
        const additionalFieldsMargin = 10; // Margin between additional fields
        doc.setFontSize(10);
        doc.text(15, additionalFieldsTop, `Client Name: ${clientName}`);
        doc.text(
          15,
          additionalFieldsTop + additionalFieldsMargin,
          `Invoice No.: ${invoiceRefNo}`
        );
        doc.text(
          15,
          additionalFieldsTop + additionalFieldsMargin * 2,
          `Invoice Date: ${invoiceDate.split("-").reverse().join("-") || "N/A"}`
        );
        doc.text(
          15,
          additionalFieldsTop + additionalFieldsMargin * 3,
          `Company's Service Accounting Code (SAC): ${sacHsn}`
        );
        doc.text(
          15,
          additionalFieldsTop + additionalFieldsMargin * 4,
          "Subject: Facilities-Canteen & Transportation Deductions"
        );

        // Table
        const tableTop = additionalFieldsTop + additionalFieldsMargin * 5 + 10; // Adjust spacing between additional fields and table
        const tableHeaders = [
          "Sr.No",
          "Employee Name",
          "Presenty",
          "Canteen Amount",
          "Transportation Amount",
          "Total Amount",
        ];
        const tableData = invoiceData.invoiceEmployeePoDetailsUIList.map(
          (employee, index) => [
            index + 1,
            employee.emplyoyeeName,
            employee.presenty,
            employee.canteenAmt,
            employee.transportationAmt,
            employee.canteenAmt + employee.transportationAmt,
          ]
        );

        // Calculate available height for the table
        const availableHeight = borderHeight - (tableTop - borderY) - 9; // Subtract spacing for padding
        const rowHeight = 8; // Estimate the height of a row
        const maxRows = Math.floor(availableHeight / rowHeight); // Calculate max rows that fit within the available height
        const newPageTableTop = 30; // Adjust the top margin for new pages

        // Split tableData into chunks that fit within the available height
        let currentY = tableTop;
        for (let i = 0; i < tableData.length; i += maxRows) {
          if (i > 0) {
            doc.addPage();
            doc.setDrawColor(0);
            doc.setLineWidth(0.3);
            // doc.rect(borderX, borderY, borderWidth, borderHeight);
            doc.rect(
              5,
              5,
              doc.internal.pageSize.getWidth() - 10,
              doc.internal.pageSize.getHeight() - 10
            );
            // currentY = tableTop; // Reset the currentY for the new page
            currentY = newPageTableTop; // Adjust the currentY for the new page
          }
          const tableChunk = tableData.slice(i, i + maxRows);
          doc.autoTable({
            head: [tableHeaders],
            body: tableChunk,
            startY: currentY,
            theme: "grid",
            styles: {
              fontSize: 9,
              lineWidth: 0.3,
              lineColor: [0, 0, 0],
              fillColor: [255, 255, 255],
              textColor: [0, 0, 0],
              marginBottom: 0,
            },
            margin: { left: borderX + 5, right: borderX + 5 }, // Adjust margins to fit within the border
            tableWidth: borderWidth - 10, // Adjust table width to fit within the border
            columnStyles: {
              0: { cellWidth: 20 }, // Adjust column width if needed
              1: { cellWidth: 40 }, // Example for adjusting column width
              2: { cellWidth: 30 },
              3: { cellWidth: 30 },
              4: { cellWidth: 30 },
              5: { cellWidth: 30 },
            },
          });
          currentY += tableChunk.length * rowHeight - 10; // Adjust currentY for the next chunk
        }
      }

      const pdfData = doc.output("blob"); // Assuming doc.output provides Blob object
      const pdfWindow = window.open("", "_blank");
      const pdfURL = URL.createObjectURL(pdfData);
      pdfWindow.location.href = pdfURL;
    }
  };

  const generatePDFPerHour = async (
    invoiceData,
    companyProfile,
    clientDetails,
    gstDetails
  ) => {
    const doc = new jsPDF();

    const includePO = await Swal.fire({
      title: "Do you want to include PO number in the PDF?",
      icon: "question",
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        return true;
      } else {
        return false;
      }
    });

    // Define includeEmployeeCategoryWithPO variable
    let includeEmployeeCategoryWithPO;

    if (includePO) {
      includeEmployeeCategoryWithPO = await Swal.fire({
        title:
          "Do you want to include Employee Category with PO Number in the PDF?",
        icon: "question",
        showDenyButton: true,
        confirmButtonText: "Yes",
        denyButtonText: `No`,
      }).then((result) => {
        if (result.isConfirmed) {
          return true;
        } else {
          return false;
        }
      });

      await generatePDF(includePO, includeEmployeeCategoryWithPO);
    } else {
      await generatePDF(includePO, false);
    }
    // Call generatePDF function with correct parameters
    async function generatePDF(includePO, includeEmployeeCategoryWithPO) {
      const encompassingRectX = 3;
      const encompassingRectY = 4;
      const encompassingRectWidth = doc.internal.pageSize.width - 6;
      const encompassingRectHeight = doc.internal.pageSize.height - 8;

      doc.setDrawColor(0); // Set border color to black
      doc.setLineWidth(0.4); // Set border width
      doc.rect(
        encompassingRectX,
        encompassingRectY,
        encompassingRectWidth,
        encompassingRectHeight
      );

      let companyInfoText =
        `${companyProfile.companyName}\n` +
        `${companyProfile.companyAddress}\n` +
        `${companyProfile.website}\n` +
        `${companyProfile.eMail}`;

      // Add the company information to the PDF for the current invoice
      doc.setFontSize(10);
      doc.setTextColor(0, 0, 0); // Black color
      doc.setFont("helvetica", "bold"); // Set font to bold
      doc.text(companyInfoText, 15, 30);
      doc.setFont("helvetica", "normal"); // Reset font to normal

      // Continue with the rest of your PDF generation logic...

      const companyInfoContainerX = 14;
      const companyInfoContainerY = 10;
      const companyInfoContainerWidth =
        doc.internal.pageSize.width * 0.55 - companyInfoContainerX - 10;
      const companyInfoContainerHeight = 40;

      // Draw the container first
      doc.setDrawColor(0);
      doc.setLineWidth(0.4);
      doc.setFillColor(255, 255, 255);
      doc.rect(
        companyInfoContainerX,
        companyInfoContainerY,
        companyInfoContainerWidth,
        companyInfoContainerHeight,
        "FD"
      );

      if (invoiceData.selectCompany == "GST") {
        // Add the GsquareLOGO with additional margin from the left border
        doc.addImage(GsquareLOGO, "JPEG", 15, 11, 28, 28); // Change x coordinate (e.g., 25 or 30)
        doc.setFontSize(10);
        doc.setTextColor(0, 0, 0);
        doc.text(companyInfoText, 25 + 20 + 2, 18, {
          // Change margin after GsquareLOGO width (e.g., 2 or 3)
          maxWidth: doc.internal.pageSize.width * 0.55 - 25 - 20 - 15, // Calculate available width minus adjusted logo position and margins
        });
      }
      if (invoiceData.selectCompany == "BGD") {
        // Add the Bgd_LOGO with additional margin from the left border
        doc.addImage(Bgd_LOGO, "JPEG", 15, 11, 28, 28); // Change x coordinate (e.g., 25 or 30)
        doc.setFontSize(10);
        doc.setTextColor(0, 0, 0);
        doc.text(companyInfoText, 25 + 20 + 2, 18, {
          // Change margin after Bgd_LOGO width (e.g., 2 or 3)
          maxWidth: doc.internal.pageSize.width * 0.55 - 25 - 20 - 15, // Calculate available width minus adjusted logo position and margins
        });
      }

      if (invoiceData.invoiceTitle) {
        const title = invoiceData.invoiceTitle || "";
        const titleHeight = 3;
        const titleY = encompassingRectY + 1;
        doc.setFontSize(10);
        doc.text(title, doc.internal.pageSize.width / 2, titleY + titleHeight, {
          align: "center",
        });
      }

      // Use the fetched invoice data in your PDF
      const CompInvo = [
        ["Invoice No: " + invoiceData.invoiceRefNo],
        ["Company's GST NO.: " + companyProfile.gstin], // Include GSTIN directly
        ["Company's PAN No.: " + companyProfile.pan], // Include PAN directly
        ["Company's CIN: " + companyProfile.cin], // Include CIN directly
        ["Invoice Date: " + invoiceData.invoiceDate],
      ];

      const tableWidth = doc.internal.pageSize.width * 0.45;
      const leftMargin = doc.internal.pageSize.width - tableWidth - 10;

      // Calculate the height of the CompInvo table
      const compInvoTableHeight = doc.autoTable({
        body: CompInvo,
        theme: "grid",
        margin: { top: 10, left: leftMargin, right: 14, bottom: 0 },
        styles: {
          fontSize: 10,
          lineWidth: 0.5,
          lineColor: [0, 0, 0],
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          cellPadding: 2,
          marginBottom: 0,
          marginTop: 0,
          padding: 0,
        },
      }).lastAutoTable.finalY;

      const ClientInvo = [
        ["Service ACC Code: " + gstDetails.hsnsac],
        ["Client's GSTIN: " + gstDetails.gstin],
        ["Payment Term: 15 Days Bank Transfer"],
        ["Onsite Manpower Support for the month"],
      ];

      // Set the startY parameter for ClientInvo table to start immediately after CompInvo table
      doc.autoTable({
        body: ClientInvo,
        theme: "grid",
        columnStyles: { 0: { cellWidth: "auto" } },
        startY: compInvoTableHeight, // Set startY explicitly
        margin: {
          top: compInvoTableHeight,
          left: leftMargin,
          right: 14,
          bottom: 0,
        },
        styles: {
          fontSize: 10,
          lineWidth: 0.5,
          lineColor: [0, 0, 0],
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          marginBottom: 0,
          tableHeight: 40,
        },
      });

      const billedToContainerHeight = 38.85;
      const clientAddressLines = clientDetails.clientAddress || ""; // Split the address into multiple lines
      // const billedToTextLines = [
      //   `Billed To: ${clientDetails.clientName.trim() || ""} `,
      //   ...clientAddressLines.trim().split("\n"), // Spread the address lines into the array
      //   "",
      //   `Place of supply: ${gstDetails.state || ""} State Code: ${
      //     gstDetails.stateCode || ""
      //   }`,
      //   `Ph: ${clientDetails.clientContactNumber || ""}`,
      //   `Kind Attn: ${invoiceData.invoicePaymentModeUI.kindAttention || ""} `,
      // ];
      const billedToTextLines = [
        `Billed To: ${clientDetails.clientName || ""} `,
        ...clientAddressLines.split("\n"), // Spread the address lines into the array
        "",
        `Place of supply: ${gstDetails.state || ""} State Code: ${
          gstDetails.stateCode || ""
        }`,
        `Ph: ${clientDetails.clientContactNumber || ""}`,
        `Kind Attn: ${invoiceData.invoicePaymentModeUI.kindAttention || ""} `,
      ];
      const billedToContainerX = companyInfoContainerX;
      const topMargin = 0;
      const containerHeight = 40; // Set the common height for both the billedToContainer and the table
      const billedToContainerY =
        companyInfoContainerY + companyInfoContainerHeight + topMargin;
      const billedToContainerWidth = companyInfoContainerWidth;

      doc.setDrawColor(0);
      doc.setLineWidth(0.4);
      doc.setFillColor(255, 255, 255);
      doc.rect(
        billedToContainerX,
        billedToContainerY,
        billedToContainerWidth,
        containerHeight,
        "FD"
      );

      doc.setFontSize(10);
      doc.setTextColor(0, 0, 0);

      const lineHeight = 6; // Adjusted line height
      let yPosition = billedToContainerY + 5;

      billedToTextLines.forEach((line) => {
        doc.text(line.trim(), billedToContainerX + 5, yPosition, {
          maxWidth: billedToContainerWidth - 10,
        });
        yPosition += lineHeight;
      });
      doc.setFillColor(255, 255, 255);

      let tableData;
      let amountColumnIndex;
      if (includePO) {
        // Include PO number column in the PDF
        if (includeEmployeeCategoryWithPO) {
          tableData = invoiceData.invoiceEmployeePoDetailsUIList.map(
            (row, index) => [
              index + 1,
              row.emplyoyeeName,
              invoiceData.poNumber,
              row.employeeCategory,
              row.fromDate,
              row.toDate,
              row.presenty,
              row.currentPoRate,
              row.presentyAmt, // Calculate amount
            ]
          );
          amountColumnIndex = 7;
        } else {
          tableData = invoiceData.invoiceEmployeePoDetailsUIList.map(
            (row, index) => [
              index + 1,
              row.emplyoyeeName,
              row.fromDate,
              row.toDate,
              row.presenty,
              row.currentPoRate,
              row.presentyAmt, // Calculate amount
            ]
          );
          amountColumnIndex = 6;
        }
      } else {
        // Exclude PO number column in the PDF
        tableData = invoiceData.invoiceEmployeePoDetailsUIList.map(
          (row, index) => [
            index + 1,
            row.emplyoyeeName,
            row.fromDate,
            row.toDate,
            row.presenty,
            row.currentPoRate,
            row.presentyAmt, // Calculate amount
          ]
        );
        amountColumnIndex = 5;
      }

      const headerLabels =
        includePO && includeEmployeeCategoryWithPO
          ? [
              "Sr.No",
              "Particulars",
              "PO NO.",
              "Employee Category",
              "From Date",
              "To Date",
              "Billable Hours",
              "Current PO Rate",
              "Amount",
            ]
          : includePO && !includeEmployeeCategoryWithPO
          ? [
              "Sr.No",
              "Particulars",
              "From Date",
              "To Date",
              "Billable Hours",
              "Current PO Rate",
              "Amount",
            ]
          : [
              "Sr.No",
              "Particulars",
              "From Date",
              "To Date",
              "Billable Hours",
              "Current PO Rate",
              "Amount",
            ];

      // Generate the PDF table
      doc.autoTable({
        head: [headerLabels],
        body: tableData,
        startY: billedToContainerY + billedToContainerHeight + 0,
        theme: "grid",
        styles: {
          fontSize: 9,
          lineWidth: 0.5,
          lineColor: [0, 0, 0],
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          marginBottom: 0,
        },
        columnStyles: {
          [amountColumnIndex]: { cellWidth: 25, halign: "right" }, // Set width for the "Amount" column
        },
      });

      // Calculate the height of the first table (invoice data table)
      const firstTableHeight = doc.previousAutoTable
        ? doc.previousAutoTable.finalY
        : 0;

      const {
        totalPresentyAmount,
        grandTotalAmount,
        totalTaxAmt,
        totalInWordAmount,
      } = invoiceData.invoiceGstDetailsUI;
      const { cgstAmount, sgstAmount, igstAmount } =
        invoiceData.invoiceGstDetailsUI;

      const InvoiceData = [
        ["A] Total", totalPresentyAmount],
        ["B] CGST (1)", ` ${cgstAmount}`],
        ["C] SGST (2)", ` ${sgstAmount}`],
        ["D] IGST (3)", ` ${igstAmount}`],
        ["E] Total (A+B+C+D)", grandTotalAmount],
        ["F] Deduction ", 0],
        ["G] Grand Total (F-G)", grandTotalAmount],
        [{ content: " Total In Words: " + totalInWordAmount, colSpan: 2 }],
      ];

      // Set startY for the second table immediately after the first table
      doc.autoTable({
        body: InvoiceData,
        startY: firstTableHeight + 0,
        theme: "grid",
        styles: {
          lineWidth: 0.5,
          lineColor: [0, 0, 0],
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          marginBottom: 0,
        },
        columnStyles: {
          0: { cellWidth: 157 },
          1: { cellWidth: 25, halign: "right" },
        },
      });

      const secondTableHeight = doc.previousAutoTable
        ? doc.previousAutoTable.finalY
        : 0;

      // Calculate remaining space on the page
      const remainingSpace =
        doc.internal.pageSize.getHeight() - secondTableHeight - 20;

      // Check if there is enough space for the bank table
      if (remainingSpace < 0) {
        doc.addPage();
      }
      let finalY = doc.internal.pageSize.height;

      if (doc.previousAutoTable && doc.previousAutoTable.finalY) {
        finalY = doc.previousAutoTable.finalY + 5; // Add some padding
      }

      const innerRectHeight = 60; // Set a fixed height for the inner rectangle
      const remainingSpace2 = doc.internal.pageSize.height - finalY;

      // Check if the inner rectangle can fit in the remaining space
      if (remainingSpace2 < innerRectHeight) {
        doc.addPage();
        finalY = 15; // Reset Y position for new page
      }

      // Draw the encompassing border at the end
      doc.setDrawColor(0); // Set border color to black
      doc.setLineWidth(0.4); // Set border width
      //draw Big border for the pdf page
      doc.rect(
        3,
        4,
        doc.internal.pageSize.width - 6,
        finalY > doc.internal.pageSize.height - 8
          ? finalY
          : doc.internal.pageSize.height - 8
      );

      // const innerRectX = 20;
      // const innerRectY = finalY + 0; // Start just after the previous table
      // const innerRectWidth = doc.internal.pageSize.width - 30;
      const innerRectX = 14;
      const innerRectY = finalY - 5; // Start just after the previous table
      const innerRectWidth = 182;
      // Draw the inner rectangle
      doc.rect(innerRectX, innerRectY, innerRectWidth, innerRectHeight);

      // Draw the vertical line to split the inner rectangle
      const splitLineX = innerRectX + innerRectWidth / 2;
      doc.line(
        splitLineX,
        innerRectY,
        splitLineX,
        innerRectY + innerRectHeight
      );

      // Add bank details to the left section
      const bankDetailsArray = [
        `Bank Name: ${invoiceData.bankUI.bankName}`,
        `Bank Address: ${invoiceData.bankUI.bankAddress}`,
        `A/C No.: ${invoiceData.bankUI.accountNumber}`,
        `IFC Code: ${invoiceData.bankUI.ifsc}`,
        `Swift Code: ${invoiceData.bankUI.swiftCode}`,
      ];

      const maxTextWidth = innerRectWidth / 2 - 10; // Maximum width for the text in the left section
      let leftTextY = innerRectY + 10; // Initial Y position for the left text

      const lineHeight2 = 7; // Increase line height for more margin

      bankDetailsArray.forEach((line) => {
        const lines = doc.splitTextToSize(line, maxTextWidth);
        lines.forEach((wrappedLine) => {
          doc.text(wrappedLine, innerRectX + 5, leftTextY);
          leftTextY += lineHeight2; // Adjust the line height for more margin
        });
      });

      // Add text to the right section
      const rightText1 = "For Gsquare Techsystem PVT LTD";
      const rightText2 = "Authorized Signatory & Seal";
      const rightText1Width = doc.getTextWidth(rightText1);
      const rightText2Width = doc.getTextWidth(rightText2);

      const rightTextX1 =
        splitLineX + (innerRectWidth / 2 - rightText1Width) / 2;
      const rightTextX2 =
        splitLineX + (innerRectWidth / 2 - rightText2Width) / 2;

      const gapBetweenLines = 30; // Set the gap between the lines here

      doc.text(rightText1, rightTextX1, innerRectY + 10);
      doc.text(rightText2, rightTextX2, innerRectY + 10 + gapBetweenLines); // Adjust the Y position for the second line

      const annexureFlag =
        invoiceData.invoiceEmployeePoDetailsUIList[0].annenuxFlag;
      if (annexureFlag === true) {
        const { clientName, invoiceRefNo, clientUI, invoiceDate, sacHsn } =
          invoiceData;
        doc.addPage();

        // Add page border
        doc.setDrawColor(0);
        doc.setLineWidth(0.3);
        doc.rect(
          5,
          5,
          doc.internal.pageSize.getWidth() - 10,
          doc.internal.pageSize.getHeight() - 10
        );

        // Add title in the center
        const pageTitle = "Annexure";
        doc.setFontSize(18);
        const pageWidth = doc.internal.pageSize.getWidth();
        const titleWidth =
          (doc.getStringUnitWidth(pageTitle) * doc.internal.getFontSize()) /
          doc.internal.scaleFactor;
        const titleX = (pageWidth - titleWidth) / 2;
        doc.text(pageTitle, titleX, 20);

        // Border around additional fields and table
        const borderX = 10;
        const borderY = 40;
        const borderWidth = doc.internal.pageSize.getWidth() - 20; // Adjusted width to fit within page
        const borderHeight = doc.internal.pageSize.getHeight() - 60; // Adjust height as needed
        doc.rect(borderX, borderY, borderWidth, borderHeight);

        // Additional Fields
        const additionalFieldsTop = borderY + 10;
        const additionalFieldsMargin = 10; // Margin between additional fields
        doc.setFontSize(10);
        doc.text(15, additionalFieldsTop, `Client Name: ${clientName}`);
        doc.text(
          15,
          additionalFieldsTop + additionalFieldsMargin,
          `Invoice No.: ${invoiceRefNo}`
        );
        doc.text(
          15,
          additionalFieldsTop + additionalFieldsMargin * 2,
          `Invoice Date: ${invoiceDate.split("-").reverse().join("-") || "N/A"}`
        );
        doc.text(
          15,
          additionalFieldsTop + additionalFieldsMargin * 3,
          `Company's Service Accounting Code (SAC): ${sacHsn}`
        );
        doc.text(
          15,
          additionalFieldsTop + additionalFieldsMargin * 4,
          "Subject: Facilities-Canteen & Transportation Deductions"
        );

        // Table
        const tableTop = additionalFieldsTop + additionalFieldsMargin * 5 + 10; // Adjust spacing between additional fields and table
        const tableHeaders = [
          "Sr.No",
          "Employee Name",
          "Presenty",
          "Canteen Amount",
          "Transportation Amount",
          "Total Amount",
        ];
        const tableData = invoiceData.invoiceEmployeePoDetailsUIList.map(
          (employee, index) => [
            index + 1,
            employee.emplyoyeeName,
            employee.presenty,
            employee.canteenAmt,
            employee.transportationAmt,
            employee.canteenAmt + employee.transportationAmt,
          ]
        );

        // Calculate available height for the table
        const availableHeight = borderHeight - (tableTop - borderY) - 9; // Subtract spacing for padding
        const rowHeight = 8; // Estimate the height of a row
        const maxRows = Math.floor(availableHeight / rowHeight); // Calculate max rows that fit within the available height
        const newPageTableTop = 30; // Adjust the top margin for new pages

        // Split tableData into chunks that fit within the available height
        let currentY = tableTop;
        for (let i = 0; i < tableData.length; i += maxRows) {
          if (i > 0) {
            doc.addPage();
            doc.setDrawColor(0);
            doc.setLineWidth(0.3);
            // doc.rect(borderX, borderY, borderWidth, borderHeight);
            doc.rect(
              5,
              5,
              doc.internal.pageSize.getWidth() - 10,
              doc.internal.pageSize.getHeight() - 10
            );
            // currentY = tableTop; // Reset the currentY for the new page
            currentY = newPageTableTop; // Adjust the currentY for the new page
          }
          const tableChunk = tableData.slice(i, i + maxRows);
          doc.autoTable({
            head: [tableHeaders],
            body: tableChunk,
            startY: currentY,
            theme: "grid",
            styles: {
              fontSize: 9,
              lineWidth: 0.3,
              lineColor: [0, 0, 0],
              fillColor: [255, 255, 255],
              textColor: [0, 0, 0],
              marginBottom: 0,
            },
            margin: { left: borderX + 5, right: borderX + 5 }, // Adjust margins to fit within the border
            tableWidth: borderWidth - 10, // Adjust table width to fit within the border
            columnStyles: {
              0: { cellWidth: 20 }, // Adjust column width if needed
              1: { cellWidth: 40 }, // Example for adjusting column width
              2: { cellWidth: 30 },
              3: { cellWidth: 30 },
              4: { cellWidth: 30 },
              5: { cellWidth: 30 },
            },
          });
          currentY += tableChunk.length * rowHeight - 10; // Adjust currentY for the next chunk
        }
      }

      const pdfData = doc.output("blob"); // Assuming doc.output provides Blob object
      const pdfWindow = window.open("", "_blank");
      const pdfURL = URL.createObjectURL(pdfData);
      pdfWindow.location.href = pdfURL;
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const downloadExcelFile = async () => {
    try {
      let apiUrl = `/gst/invoice/getAllInvoiceListForExcel`;
      if (selectedOption === "1") {
        apiUrl += `?excelFilterType=all&filterType&financialYear&selectCompany=${selectedCompany}`;
      } else if (selectedOption === "2") {
        apiUrl += `?excelFilterType=all&filterType=``&financialYear=${selectedFinancialYear}&selectCompany=${selectedCompany}`;
      } else if (selectedOption === "3") {
        apiUrl += `?excelFilterType&filterType=EMPLOYEEPOINVOICE&financialYear&selectCompany=${selectedCompany}`;
      } else if (selectedOption === "4") {
        apiUrl += `?excelFilterType&filterType=EMPLOYEEPOINVOICE&financialYear=${selectedFinancialYear}&selectCompany=${selectedCompany}`;
      }

      const response = await myAxiosCommonHost.get(apiUrl);

      const data = response.data;

      if (!data) {
        console.error("Data is not available or empty.");
        return;
      }

      const workbook = XLSX.utils.book_new();

      const invoiceHeaderDetailExcelList =
        data.invoiceHeaderDetailExcelList || [];
      const invoiceEmployeePoExcelList = data.invoiceEmployeePoExcelList || [];
      const invoiceProjectPoExcelList = data.invoiceProjectPoExcelList || [];
      const invoiceOtherChargesExcelList =
        data.invoiceOtherChargesExcelList || [];

      if (invoiceHeaderDetailExcelList.length > 0) {
        const worksheet1 = XLSX.utils.json_to_sheet(
          invoiceHeaderDetailExcelList
        );
        XLSX.utils.book_append_sheet(
          workbook,
          worksheet1,
          "Invoice Header Details"
        );
      }

      if (invoiceEmployeePoExcelList.length > 0) {
        const worksheet2 = XLSX.utils.json_to_sheet(invoiceEmployeePoExcelList);
        XLSX.utils.book_append_sheet(
          workbook,
          worksheet2,
          "Invoice Employee PO"
        );
      }

      if (invoiceProjectPoExcelList.length > 0) {
        const worksheet3 = XLSX.utils.json_to_sheet(invoiceProjectPoExcelList);
        XLSX.utils.book_append_sheet(
          workbook,
          worksheet3,
          "Invoice Project PO"
        );
      }

      if (invoiceOtherChargesExcelList.length > 0) {
        const worksheet4 = XLSX.utils.json_to_sheet(
          invoiceOtherChargesExcelList
        );
        XLSX.utils.book_append_sheet(
          workbook,
          worksheet4,
          "Invoice Other Charges"
        );
      }

      const excelBuffer = XLSX.write(workbook, {
        type: "array",
        bookType: "xlsx",
      });

      const blob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const url = URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.target = "_blank";
      anchor.download = "invoice_data.xlsx";
      anchor.click();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const filteredData =
    data && Array.isArray(data)
      ? data.filter(
          (po) =>
            po.clientNameAndCode
              .toLowerCase()
              .includes(searchTermClientName.toLowerCase()) &&
            po.invoiceNo.toString().includes(searchTerm.toLowerCase())
        )
      : [];
  const handleSearch = async () => {
    try {
      setLoading(true);
      const limit = rowsPerPage;
      const offset = page * rowsPerPage;
      const response = await myAxiosCommonHost.get(
        `gst/invoice/searchInvoice?invoiceRefNo=${searchInvoiceRefNo}&financialYear=${selectedFinancialYear}&clientName=${searchClientName}&filterType=EMPLOYEEPOINVOICE&limit=${limit}&offset=${offset}`
      );
      setData(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error searching invoices:", error);
      setError(error);
      setLoading(false);
    }
    fetchInvoiceSearchCount();
  };
  return (
    <>
      {isDeleting && (
        <div className="blur-effect">
          <CircularProgress color="primary" />
          <div className="loading-message">Invoice is Deleting...</div>
        </div>
      )}
      {isUpdating && (
        <div className="blur-effect">
          <CircularProgress color="primary" />
          <div className="loading-message">Invoice is Updating...</div>
        </div>
      )}
      {isDownloading && (
        <div className="blur-effect">
          <CircularProgress color="primary" />
          <div className="loading-message"> Viewing Invoice...</div>
        </div>
      )}
      <Paper
        elevation={3}
        style={{ padding: 20, marginBottom: 20, marginTop: 20 }}
      >
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <Link to="/generateEmployeeInvoice" style={{ marginRight: "10px" }}>
              <Button
                variant="contained"
                color="secondary"
                style={{ marginTop: 20, marginRight: 10, marginBottom: "20px" }}
              >
                Generate Invoice
              </Button>
            </Link>

            <Button
              variant="contained"
              color="secondary"
              style={{ margin: "10px" }}
            >
              Invoice Count {totalCount}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                label="Invoice Ref No"
                value={searchInvoiceRefNo}
                onChange={(e) => setSearchInvoiceRefNo(e.target.value)}
                variant="standard"
                style={{ marginRight: "10px" }}
              />
              {/* <TextField
                label="Financial Year"
                value={selectedFinancialYear}
                onChange={(e) => setSelectedFinancialYear(e.target.value)}
                variant="standard"
                style={{ marginRight: "10px" }}
              /> */}
              {/* <Autocomplete
                id="financial-year"
                sx={{ width: 200 }}
                options={financialYear}
                loading={loading}
                value={selectedFinancialYear}
                onChange={(event, newValue) =>
                  setSelectedFinancialYear(newValue)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Financial Year"
                    variant="standard"
                    style={{ backgroundColor: "white",marginRight: "10px" }}
                    style={{ marginRight: "10px" }}
                  />
                )}
              /> */}
              <TextField
                label="Client Name"
                value={searchClientName}
                onChange={(e) => setSearchClientName(e.target.value)}
                variant="standard"
                style={{ marginRight: "10px" }}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleSearch}
              >
                Search
              </Button>
            </Box>
            <Button
              variant="contained"
              color="secondary"
              style={{ margin: "10px" }}
            >
              Search Data Count {searchTotalCount}
            </Button>
          </Grid>
        </Grid>
      </Paper>
      <Paper
        elevation={3}
        style={{ padding: 20, marginBottom: 20, marginTop: 20 }}
      >
        <Grid container spacing={2}>
          <Grid item xs={2} md={4}>
            {/* <Autocomplete
              id="financial-year"
              sx={{ width: 200 }}
              options={financialYear}
              loading={loading}
              value={selectedFinancialYear}
              onChange={(event, newValue) =>
                handleFinancialYearSelect(newValue)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Financial Year"
                  variant="standard"
                  style={{ backgroundColor: "white", marginTop: "20px" }}
                />
              )}
            /> */}
            <TextField
              label="Financial Year"
              value={selectedFinancialYear}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              disabled
              variant="standard"
              style={{ backgroundColor: "white", marginTop: "20px" }}
            />
          </Grid>

          <Grid item xs={2} md={4}>
            {/* <Autocomplete
              id="company"
              sx={{ width: 200 }}
              options={company}
              loading={loading}
              value={selectedCompany}
              onChange={(event, newValue) => handleCompanySelect(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Company"
                  variant="standard"
                  style={{ backgroundColor: "white", marginTop: "20px" }}
                />
              )}
            /> */}
            <TextField
              label="Select Company"
              value={selectedCompany}
              InputLabelProps={{
                shrink: true,
              }}
              disabled
              variant="standard"
              style={{ backgroundColor: "white", marginTop: "20px" }}
            />
          </Grid>

          <Box my={4} display="flex" alignItems="center">
            <TextField
              select
              label="Download Options"
              value={selectedOption}
              onChange={handleOptionChange}
              variant="standard"
              style={{ minWidth: 200, marginRight: 16 }}
            >
              <MenuItem value="1">Download All Invoice</MenuItem>
              <MenuItem value="2">
                Download all invoice financial year wise
              </MenuItem>
              <MenuItem value="3">Download Employee PO invoice</MenuItem>
              <MenuItem value="4">
                Download financial year wise invoice
              </MenuItem>
            </TextField>

            <Button
              variant="contained"
              style={{ backgroundColor: "green", color: "white" }}
              onClick={downloadExcelFile}
            >
              DOWNLOAD EXCEL
            </Button>
          </Box>
        </Grid>
      </Paper>
      <Paper
        elevation={3}
        style={{
          padding: 20,
          marginBottom: 20,
          backgroundColor: "#2196f3",
          color: "white",
          padding: "10px",
          textAlign: "center",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography
            variant="h5"
            gutterBottom
            style={{ marginBottom: "15px", color: "darkblue" }}
          >
            Employee Invoice List
          </Typography>
        </div>
      </Paper>

      {error ? (
        <p>No Invoice Data Found, Generete A New Invoice.</p>
      ) : (
        <TableContainer
          component={Paper}
          style={{ height: "20%", marginTop: "20px" }}
        >
          {loading ? (
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              style={{ marginLeft: "10px", marginTop: "50px" }}
              open={loading}
            >
              <CircularProgress color="inherit" />
              <Typography
                variant="h6"
                color="inherit"
                style={{ marginLeft: "10px", marginTop: "20px" }}
              >
                Getting Data...
              </Typography>
            </Backdrop>
          ) : (
            <>
              <Grid item xs={12}>
                <Paper>
                  <Table>
                    <TableHead>
                      <TableRow
                        style={{
                          backgroundColor: "darkcyan",
                          fontWeight: "bold",
                          color: "darkblue",
                        }}
                      >
                        <TableCell />
                        <TableCell>Invoice Ref</TableCell>
                        <TableCell>Client Name</TableCell>
                        <TableCell>Po Number</TableCell>
                        <TableCell>Service Of Month </TableCell>
                        <TableCell> Service Of Year</TableCell>
                        <TableCell>Invoice No</TableCell>
                        <TableCell></TableCell>
                        <TableCell>Action</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.length === 0 ? (
                        <TableRow>
                          <TableCell colSpan={9} align="center">
                            No Employee Invoice found
                          </TableCell>
                        </TableRow>
                      ) : (
                        data.map((invoice, index) => (
                          <React.Fragment key={index}>
                            <TableRow>
                              <TableCell>
                                <IconButton
                                  onClick={() => handleExpandClick(index)}
                                  aria-expanded={expandedRow === index}
                                  aria-label="show more"
                                >
                                  <ExpandMoreIcon />
                                </IconButton>
                              </TableCell>
                              <TableCell>{invoice.invoiceRefNo}</TableCell>
                              <TableCell>{invoice.clientName}</TableCell>
                              <TableCell>{invoice.poNumber}</TableCell>
                              <TableCell>{invoice.paymentMonth}</TableCell>
                              <TableCell>{invoice.paymentYear}</TableCell>
                              <TableCell>{invoice.invoiceNo}</TableCell>
                              <TableCell>
                                <Button
                                  variant="contained"
                                  onClick={() =>
                                    handlegeneratePDF(invoice.invoiceRefNo)
                                  } // Pass a function reference here
                                  color="secondary"
                                >
                                  VIEW
                                </Button>
                              </TableCell>
                              <TableCell>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={() =>
                                    handleUpdateInvoiceClick(invoice)
                                  }
                                >
                                  Update Invoice
                                </Button>
                              </TableCell>
                              <TableCell>
                                <Button
                                  variant="contained"
                                  color="secondary"
                                  onClick={() =>
                                    handleDeleteClick(invoice.invoiceRefNo)
                                  }
                                >
                                  Delete Invoice
                                </Button>
                              </TableCell>
                            </TableRow>
                            {invoice.invoiceEmployeePoDetailsUIList ? (
                              <TableRow>
                                <TableCell colSpan={5}>
                                  <Collapse
                                    in={expandedRow === index}
                                    timeout="auto"
                                    unmountOnExit
                                  >
                                    {invoice.invoiceEmployeePoDetailsUIList.map(
                                      (proInvoice, empIndex) => (
                                        <Accordion key={empIndex}>
                                          <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls={`panel${index}-content`}
                                            id={`panel${index}-header`}
                                            IconButtonProps={{ edge: "start" }}
                                          >
                                            <Typography>
                                              {"Employee Name -:"}
                                              {
                                                invoice
                                                  .invoiceEmployeePoDetailsUIList[
                                                  empIndex
                                                ].emplyoyeeName
                                              }
                                            </Typography>
                                          </AccordionSummary>
                                          <AccordionDetails>
                                            <Table style={{ width: "100%" }}>
                                              <TableBody>
                                                <TableRow
                                                  style={{
                                                    backgroundColor: "darkcyan",
                                                    fontWeight: "bold",
                                                    color: "darkblue",
                                                  }}
                                                >
                                                  <TableCell>
                                                    Emplyoyee Name
                                                  </TableCell>
                                                  <TableCell
                                                    style={{ width: "150px" }}
                                                  >
                                                    From Date
                                                  </TableCell>
                                                  <TableCell
                                                    style={{ width: "150px" }}
                                                  >
                                                    To Date
                                                  </TableCell>
                                                  <TableCell>
                                                    Curreent Po Rate
                                                  </TableCell>
                                                  {/* <TableCell>Action</TableCell> */}
                                                  <TableCell></TableCell>
                                                </TableRow>
                                                {proInvoice ? (
                                                  <TableRow>
                                                    <TableCell>
                                                      {proInvoice.emplyoyeeName}
                                                    </TableCell>
                                                    <TableCell>
                                                      {proInvoice.fromDate
                                                        ? proInvoice.fromDate
                                                            .split("-")
                                                            .reverse()
                                                            .join("-")
                                                        : "N/A"}
                                                    </TableCell>
                                                    <TableCell>
                                                      {proInvoice.toDate
                                                        ? proInvoice.toDate
                                                            .split("-")
                                                            .reverse()
                                                            .join("-")
                                                        : "N/A"}
                                                    </TableCell>
                                                    <TableCell>
                                                      {proInvoice.currentPoRate}
                                                    </TableCell>
                                                  </TableRow>
                                                ) : (
                                                  <TableRow>
                                                    <TableCell
                                                      colSpan={4}
                                                      align="center"
                                                    >
                                                      No Record found
                                                    </TableCell>
                                                  </TableRow>
                                                )}
                                                <TableRow>
                                                  <TableCell colSpan={6}>
                                                    <Collapse
                                                      in={expandedRow === index}
                                                      timeout="auto"
                                                      unmountOnExit
                                                    >
                                                      <Box
                                                        style={{ padding: 20 }}
                                                      >
                                                        <Typography variant="subtitle1">
                                                          Employee Details
                                                        </Typography>
                                                        <TableRow
                                                          style={{
                                                            backgroundColor:
                                                              "darkcyan",
                                                            fontWeight: "bold",
                                                            color: "darkblue",
                                                          }}
                                                        >
                                                          <TableCell>
                                                            Presenty
                                                          </TableCell>
                                                          <TableCell>
                                                            Presenty Amount
                                                          </TableCell>
                                                          <TableCell>
                                                            Canteen Amount
                                                          </TableCell>
                                                          <TableCell>
                                                            Transportation
                                                            Amount
                                                          </TableCell>
                                                          <TableCell
                                                            style={{
                                                              width: "150px",
                                                            }}
                                                          >
                                                            From Date
                                                          </TableCell>
                                                          <TableCell
                                                            style={{
                                                              width: "150px",
                                                            }}
                                                          >
                                                            To Date
                                                          </TableCell>
                                                          <TableCell>
                                                            Emplyoyee Code
                                                          </TableCell>
                                                          <TableCell>
                                                            Emplyoyee Name
                                                          </TableCell>
                                                          <TableCell>
                                                            Curreent Po Rate
                                                          </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                          <TableCell>
                                                            {
                                                              proInvoice.presenty
                                                            }
                                                          </TableCell>
                                                          <TableCell>
                                                            {
                                                              proInvoice.presentyAmt
                                                            }
                                                          </TableCell>
                                                          <TableCell>
                                                            {
                                                              proInvoice.canteenAmt
                                                            }
                                                          </TableCell>
                                                          <TableCell>
                                                            {
                                                              proInvoice.transportationAmt
                                                            }
                                                          </TableCell>
                                                          <TableCell>
                                                            {proInvoice.fromDate
                                                              ? proInvoice.fromDate
                                                                  .split("-")
                                                                  .reverse()
                                                                  .join("-")
                                                              : "N/A"}
                                                          </TableCell>
                                                          <TableCell>
                                                            {proInvoice.fromDate
                                                              ? proInvoice.toDate
                                                                  .split("-")
                                                                  .reverse()
                                                                  .join("-")
                                                              : "N/A"}
                                                          </TableCell>
                                                          <TableCell>
                                                            {
                                                              proInvoice.employeeCode
                                                            }
                                                          </TableCell>
                                                          <TableCell>
                                                            {
                                                              proInvoice.emplyoyeeName
                                                            }
                                                          </TableCell>
                                                          <TableCell>
                                                            {
                                                              proInvoice.currentPoRate
                                                            }
                                                          </TableCell>
                                                        </TableRow>
                                                        <Typography variant="subtitle1">
                                                          Employee Details
                                                        </Typography>
                                                        <TableRow
                                                          style={{
                                                            backgroundColor:
                                                              "darkcyan",
                                                            fontWeight: "bold",
                                                            color: "darkblue",
                                                          }}
                                                        >
                                                          <TableCell>
                                                            Fix monthly Canteen
                                                          </TableCell>
                                                          <TableCell>
                                                            Is CanteenAssign
                                                          </TableCell>
                                                          <TableCell>
                                                            Is
                                                            TransportationAssign
                                                          </TableCell>
                                                          <TableCell>
                                                            Active Status
                                                          </TableCell>
                                                          <TableCell>
                                                            Release Date
                                                          </TableCell>
                                                          <TableCell>
                                                            Current Emplyoyee
                                                          </TableCell>
                                                          <TableCell>
                                                            Current Po Date
                                                          </TableCell>
                                                          {/* <TableCell>
                                                            Annenux Flag
                                                          </TableCell> */}
                                                        </TableRow>
                                                        {proInvoice ? (
                                                          <TableRow>
                                                            <TableCell>
                                                              {
                                                                proInvoice.fixmonthlyCanteen
                                                              }
                                                            </TableCell>
                                                            <TableCell>
                                                              {
                                                                proInvoice.isCanteenAssign
                                                              }
                                                            </TableCell>
                                                            <TableCell>
                                                              {
                                                                proInvoice.isTransportationAssign
                                                              }
                                                            </TableCell>
                                                            <TableCell>
                                                              {
                                                                proInvoice.activeStatus
                                                              }
                                                            </TableCell>
                                                            <TableCell>
                                                              {
                                                                proInvoice.releaseDate
                                                              }
                                                            </TableCell>
                                                            <TableCell>
                                                              {
                                                                proInvoice.currentEmplyoyee
                                                              }
                                                            </TableCell>
                                                            <TableCell>
                                                              {
                                                                proInvoice.currentPoDate
                                                              }
                                                            </TableCell>
                                                            {/* <TableCell>
                                                              {
                                                                proInvoice.annenuxFlag
                                                              }
                                                            </TableCell> */}
                                                          </TableRow>
                                                        ) : (
                                                          <TableRow>
                                                            <TableCell
                                                              colSpan={4}
                                                              align="center"
                                                            >
                                                              No Details found
                                                            </TableCell>
                                                          </TableRow>
                                                        )}
                                                        <Typography variant="subtitle1">
                                                          Invoice Gst Details
                                                        </Typography>
                                                        <TableRow
                                                          style={{
                                                            backgroundColor:
                                                              "darkcyan",
                                                            fontWeight: "bold",
                                                            color: "darkblue",
                                                          }}
                                                        >
                                                          <TableCell>
                                                            Total Presenty
                                                            Amount
                                                          </TableCell>
                                                          <TableCell>
                                                            Total Tax Amt
                                                          </TableCell>
                                                          <TableCell>
                                                            Grand Total
                                                          </TableCell>
                                                          <TableCell>
                                                            Deduction Amount
                                                          </TableCell>
                                                          <TableCell>
                                                            Deduction Particular
                                                          </TableCell>
                                                          <TableCell>
                                                            CGST
                                                          </TableCell>
                                                          <TableCell>
                                                            SGST
                                                          </TableCell>
                                                          <TableCell>
                                                            IGST
                                                          </TableCell>
                                                          <TableCell>
                                                            Grand Total Amount
                                                          </TableCell>
                                                          <TableCell>
                                                            Total In Word Amount
                                                          </TableCell>
                                                        </TableRow>
                                                        {invoice.invoiceGstDetailsUI ? (
                                                          <TableRow>
                                                            <TableCell>
                                                              {
                                                                invoice
                                                                  .invoiceGstDetailsUI
                                                                  .totalPresentyAmount
                                                              }
                                                            </TableCell>
                                                            <TableCell>
                                                              {
                                                                invoice
                                                                  .invoiceGstDetailsUI
                                                                  .totalTaxAmt
                                                              }
                                                            </TableCell>
                                                            <TableCell>
                                                              {
                                                                invoice
                                                                  .invoiceGstDetailsUI
                                                                  .grandTotalAmount
                                                              }
                                                            </TableCell>
                                                            <TableCell>
                                                              {
                                                                invoice
                                                                  .invoiceGstDetailsUI
                                                                  .deductionAmount
                                                              }
                                                            </TableCell>
                                                            <TableCell>
                                                              {
                                                                invoice
                                                                  .invoiceGstDetailsUI
                                                                  .deductionParticular
                                                              }
                                                            </TableCell>
                                                            <TableCell>
                                                              {
                                                                invoice
                                                                  .invoiceGstDetailsUI
                                                                  .cGST
                                                              }
                                                            </TableCell>
                                                            <TableCell>
                                                              {
                                                                invoice
                                                                  .invoiceGstDetailsUI
                                                                  .sGST
                                                              }
                                                            </TableCell>
                                                            <TableCell>
                                                              {
                                                                invoice
                                                                  .invoiceGstDetailsUI
                                                                  .iGST
                                                              }
                                                            </TableCell>
                                                            <TableCell>
                                                              {
                                                                invoice
                                                                  .invoiceGstDetailsUI
                                                                  .grandTotalAmount
                                                              }
                                                            </TableCell>
                                                            <TableCell>
                                                              {
                                                                invoice
                                                                  .invoiceGstDetailsUI
                                                                  .totalInWordAmount
                                                              }
                                                            </TableCell>
                                                          </TableRow>
                                                        ) : (
                                                          <TableRow>
                                                            <TableCell
                                                              colSpan={4}
                                                              align="center"
                                                            >
                                                              No GST Details
                                                              found
                                                            </TableCell>
                                                          </TableRow>
                                                        )}

                                                        <Typography variant="subtitle1">
                                                          Invoice Payment Mode
                                                        </Typography>
                                                        <TableRow
                                                          style={{
                                                            backgroundColor:
                                                              "darkcyan",
                                                            fontWeight: "bold",
                                                            color: "darkblue",
                                                          }}
                                                        >
                                                          <TableCell>
                                                            Mode Terms Of
                                                            Payment
                                                          </TableCell>
                                                          <TableCell>
                                                            Kind Attention
                                                          </TableCell>
                                                          <TableCell>
                                                            Select Bank AccNo
                                                          </TableCell>
                                                        </TableRow>
                                                        {invoice.invoicePaymentModeUI ? (
                                                          <TableRow>
                                                            <TableCell>
                                                              {
                                                                invoice
                                                                  .invoicePaymentModeUI
                                                                  .modeTermsOfPayment
                                                              }
                                                            </TableCell>
                                                            <TableCell>
                                                              {
                                                                invoice
                                                                  .invoicePaymentModeUI
                                                                  .kindAttention
                                                              }
                                                            </TableCell>
                                                            <TableCell>
                                                              {
                                                                invoice
                                                                  .invoicePaymentModeUI
                                                                  .selectBankAccNo
                                                              }
                                                            </TableCell>
                                                          </TableRow>
                                                        ) : (
                                                          <TableRow>
                                                            <TableCell
                                                              colSpan={4}
                                                              align="center"
                                                            >
                                                              No Payment Mode
                                                              Details found
                                                            </TableCell>
                                                          </TableRow>
                                                        )}

                                                        <Typography variant="subtitle1">
                                                          Bank Details
                                                        </Typography>
                                                        <TableRow
                                                          style={{
                                                            backgroundColor:
                                                              "darkcyan",
                                                            fontWeight: "bold",
                                                            color: "darkblue",
                                                          }}
                                                        >
                                                          <TableCell>
                                                            Account Number
                                                          </TableCell>
                                                          <TableCell>
                                                            Bank Name
                                                          </TableCell>
                                                          <TableCell>
                                                            Account Type
                                                          </TableCell>
                                                          <TableCell>
                                                            Branch Name
                                                          </TableCell>
                                                          <TableCell>
                                                            Account Number
                                                          </TableCell>
                                                          <TableCell>
                                                            IFSC
                                                          </TableCell>
                                                          <TableCell>
                                                            MICR
                                                          </TableCell>
                                                          <TableCell>
                                                            Bank Address
                                                          </TableCell>
                                                          <TableCell>
                                                            Swift Code
                                                          </TableCell>
                                                        </TableRow>
                                                        {invoice.bankUI ? (
                                                          <TableRow>
                                                            <TableCell>
                                                              {
                                                                invoice.bankUI
                                                                  .accountNumber
                                                              }
                                                            </TableCell>
                                                            <TableCell>
                                                              {
                                                                invoice.bankUI
                                                                  .bankName
                                                              }
                                                            </TableCell>
                                                            <TableCell>
                                                              {
                                                                invoice.bankUI
                                                                  .accountType
                                                              }
                                                            </TableCell>
                                                            <TableCell>
                                                              {
                                                                invoice.bankUI
                                                                  .branchName
                                                              }
                                                            </TableCell>
                                                            <TableCell>
                                                              {
                                                                invoice.bankUI
                                                                  .ifsc
                                                              }
                                                            </TableCell>
                                                            <TableCell>
                                                              {
                                                                invoice.bankUI
                                                                  .micr
                                                              }
                                                            </TableCell>
                                                            <TableCell>
                                                              {
                                                                invoice.bankUI
                                                                  .bankAddress
                                                              }
                                                            </TableCell>
                                                            <TableCell>
                                                              {
                                                                invoice.bankUI
                                                                  .swiftCode
                                                              }
                                                            </TableCell>
                                                          </TableRow>
                                                        ) : (
                                                          <TableRow>
                                                            <TableCell
                                                              colSpan={4}
                                                              align="center"
                                                            >
                                                              No Bank Details
                                                              found
                                                            </TableCell>
                                                          </TableRow>
                                                        )}
                                                      </Box>
                                                    </Collapse>
                                                  </TableCell>
                                                </TableRow>
                                              </TableBody>
                                            </Table>
                                          </AccordionDetails>
                                        </Accordion>
                                      )
                                    )}
                                  </Collapse>
                                </TableCell>
                              </TableRow>
                            ) : (
                              <TableRow>
                                <TableCell colSpan={4} align="center">
                                  No Record found
                                </TableCell>
                              </TableRow>
                            )}
                          </React.Fragment>
                        ))
                      )}
                    </TableBody>
                  </Table>
                  <TablePagination
                    rowsPerPageOptions={[10, 15, 20]}
                    component="div"
                    count={totalCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </Paper>
              </Grid>
            </>
          )}
        </TableContainer>
      )}
      <Dialog
        open={updateDialogOpen}
        onClose={() => setUpdateDialogOpen(false)}
        maxWidth="lg"
        maxHeight="lg"
        fullWidth
      >
        <DialogTitle>Update Invoice Data</DialogTitle>
        <DialogContent>
          <Paper
            elevation={4}
            style={{ padding: 20, marginBottom: 20, marginTop: 20 }}
          >
            <Grid container spacing={6} style={{ marginTop: 10 }}>
              <Grid item xs={3}>
                <TextField
                  label="Select Company"
                  value={editedInvoiceData?.selectCompany || ""}
                  onChange={(e) =>
                    handleDialogInputChange("selectCompany", e.target.value)
                  }
                  InputProps={{ readOnly: true }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Financial Year"
                  value={editedInvoiceData?.financialYear || ""}
                  onChange={(e) =>
                    handleDialogInputChange("financialYear", e.target.value)
                  }
                  InputProps={{ readOnly: true }}
                  required
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Invoice No"
                  value={editedInvoiceData?.invoiceNo || ""}
                  onChange={(e) =>
                    handleDialogInputChange("invoiceNo", e.target.value)
                  }
                  InputProps={{ readOnly: true }}
                  required
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Invoice Ref No"
                  value={editedInvoiceData?.invoiceRefNo || ""}
                  onChange={(e) =>
                    handleDialogInputChange("invoiceRefNo", e.target.value)
                  }
                  InputProps={{ readOnly: true }}
                  required
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Invoice Date"
                  type="date"
                  value={editedInvoiceData?.invoiceDate || ""}
                  onChange={(e) =>
                    handleDialogInputChange("invoiceDate", e.target.value)
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              {/* <Grid item xs={3}>
                <TextField
                  label="Invoice Type"
                  value={editedInvoiceData?.invoiceType || ""}
                  onChange={(e) =>
                    handleDialogInputChange("invoiceType", e.target.value)
                  }
                  InputProps={{ readOnly: true }}
                />
              </Grid> */}
              <Grid item xs={3}>
                <TextField
                  label="Client Type"
                  value={editedInvoiceData?.clientType || ""}
                  onChange={(e) =>
                    handleDialogInputChange("clientType", e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Client Name And Code"
                  value={editedInvoiceData?.clientNameAndCode || ""}
                  onChange={(e) =>
                    handleDialogInputChange("clientNameAndCode", e.target.value)
                  }
                  InputProps={{ readOnly: true }}
                  required
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Issue Date"
                  type="date"
                  value={editedInvoiceData?.issueDate || ""}
                  onChange={(e) =>
                    handleDialogInputChange("issueDate", e.target.value)
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="SacHsn"
                  value={editedInvoiceData?.sacHsn || ""}
                  onChange={(e) =>
                    handleDialogInputChange("sacHsn", e.target.value)
                  }
                  InputProps={{ readOnly: true }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="PO Number"
                  value={editedInvoiceData?.poNumber || ""}
                  onChange={(e) =>
                    handleDialogInputChange("poNumber", e.target.value)
                  }
                  required
                />
              </Grid>
              {/* <Grid item xs={3}>
                <TextField
                  label="Buyers Po Number"
                  value={editedInvoiceData?.buyersPoNumber || ""}
                  onChange={(e) =>
                    handleDialogInputChange("buyersPoNumber", e.target.value)
                  }
                />
              </Grid> */}
              <Grid item xs={3}>
                <TextField
                  label="Service of Year"
                  value={editedInvoiceData?.paymentYear || ""}
                  onChange={(e) =>
                    handleDialogInputChange("paymentYear", e.target.value)
                  }
                  InputProps={{ readOnly: true }}
                  required
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Service of Month"
                  value={editedInvoiceData?.paymentMonth || ""}
                  onChange={(e) =>
                    handleDialogInputChange("paymentMonth", e.target.value)
                  }
                  InputProps={{ readOnly: true }}
                  required
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Unique Po No"
                  value={editedInvoiceData?.uniqueNoPoNo || ""}
                  onChange={(e) =>
                    handleDialogInputChange("uniqueNoPoNo", e.target.value)
                  }
                  InputProps={{ readOnly: true }}
                  required
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Working Days"
                  value={editedInvoiceData?.workingDays || ""}
                  onChange={(e) =>
                    handleDialogInputChange("workingDays", e.target.value)
                  }
                  required
                />
              </Grid>
              <Grid item xs={3}>
                <Autocomplete
                  id="invoiceType"
                  options={["Debit Note", "Credit Note", "Tax Invoice"]}
                  value={editedInvoiceData?.invoiceType || ""}
                  onChange={(event, newValue) =>
                    handleDialogInputChange("invoiceType", newValue)
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="Invoice Type" required />
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <Autocomplete
                  id="invoiceTitle"
                  options={["TAX INVOICE", "PROFORMA INVOICE"]}
                  value={editedInvoiceData?.invoiceTitle || ""}
                  onChange={(event, newValue) =>
                    handleDialogInputChange("invoiceTitle", newValue)
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="Invoice Title" required />
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <Autocomplete
                  id="invoiceRemarks"
                  options={invoiceRemarksOptions}
                  value={editedInvoiceData?.invoiceRemarks || ""}
                  onChange={(event, newValue) =>
                    handleDialogInputChange("invoiceRemarks", newValue)
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="Invoice Remarks" required />
                  )}
                />
              </Grid>

              {/* <Grid item xs={3}>
                <FormControl fullWidth>
                  <InputLabel id="invoiceType-label">Invoice Type</InputLabel>
                  <Select
                    labelId="invoiceType-label"
                    id="invoiceType"
                    value={editedInvoiceData?.invoiceType || ""}
                    onChange={(e) =>
                      handleDialogInputChange("invoiceType", e.target.value)
                    }
                    required
                  >
                    <MenuItem value={editedInvoiceData?.invoiceType || ""}>
                      {editedInvoiceData?.invoiceType || "Select Invoice Type"}
                    </MenuItem>
                    <MenuItem value="Debit Note">Debit Note</MenuItem>
                    <MenuItem value="Credit Note">Credit Note</MenuItem>
                    <MenuItem value="Tax Invoice">Tax Invoice</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={3}>
                <FormControl fullWidth>
                  <InputLabel id="invoiceTitle-label">Invoice Title</InputLabel>
                  <Select
                    labelId="invoiceTitle-label"
                    id="invoiceTitle"
                    value={editedInvoiceData?.invoiceTitle || ""}
                    onChange={(e) =>
                      handleDialogInputChange("invoiceTitle", e.target.value)
                    }
                    required
                  >
                    <MenuItem value={editedInvoiceData?.invoiceTitle || ""}>
                      {editedInvoiceData?.invoiceTitle ||
                        "Select Invoice Title"}
                    </MenuItem>
                    <MenuItem value="TAX INVOICE">TAX INVOICE</MenuItem>
                    <MenuItem value="PROFORMA INVOICE ">
                      PROFORMA INVOICE{" "}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid> */}
              <Grid item xs={3}>
                <TextField
                  label="Emp Rate Unit"
                  value={editedInvoiceData?.empRateUnit || ""}
                  onChange={(e) =>
                    handleDialogInputChange("empRateUnit", e.target.value)
                  }
                  InputProps={{ readOnly: true }}
                  required
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Department"
                  value={editedInvoiceData?.department || ""}
                  onChange={(e) =>
                    handleDialogInputChange("department", e.target.value)
                  }
                  InputProps={{ readOnly: true }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="SubDepartment"
                  value={editedInvoiceData?.subDepartment || ""}
                  onChange={(e) =>
                    handleDialogInputChange("subDepartment", e.target.value)
                  }
                />
              </Grid>
            </Grid>
          </Paper>
          <Paper
            elevation={3}
            style={{ padding: 20, marginBottom: 20, marginTop: 20 }}
          >
            <TableContainer component={Paper} style={{ padding: "20px" }}>
              <Table>
                <TableHead>
                  <TableRow
                    style={{
                      backgroundColor: "darkcyan",
                      fontWeight: "bold",
                      color: "darkblue",
                    }}
                  >
                    <TableCell style={{ padding: "30px" }}>
                      Delete Employee
                    </TableCell>
                    <TableCell style={{ padding: "30px" }}>Presenty</TableCell>
                    <TableCell style={{ padding: "30px" }}>
                      Presenty Amount
                    </TableCell>
                    <TableCell style={{ padding: "30px" }}>
                      Canteen Amount
                    </TableCell>
                    <TableCell style={{ padding: "30px" }}>
                      Transportation Amount
                    </TableCell>
                    <TableCell style={{ padding: "30px" }}>From Date</TableCell>
                    <TableCell style={{ padding: "30px" }}>To Date</TableCell>
                    <TableCell style={{ padding: "30px" }}>
                      Employee Code
                    </TableCell>
                    <TableCell style={{ padding: "30px" }}>
                      Employee Name
                    </TableCell>
                    <TableCell style={{ padding: "30px" }}>
                      Individual Amount
                    </TableCell>
                    <TableCell style={{ padding: "30px" }}>
                      Current Po Rate
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {editedInvoiceData?.invoiceEmployeePoDetailsUIList?.map(
                    (project, projectIndex) => (
                      <TableRow key={projectIndex}>
                        <TableCell>
                          <Checkbox
                            checked={project.employeeDeleteFlag === true}
                            onChange={(e) =>
                              handleProjectInputChange(
                                projectIndex,
                                "employeeDeleteFlag"
                              )(e)
                            }
                            style={{ color: "red" }} // Set the color to red
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            value={project.presenty || ""}
                            onChange={handleProjectInputChange(
                              projectIndex,
                              "presenty"
                            )}
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            value={project.presentyAmt || ""}
                            onChange={handleProjectInputChange(
                              projectIndex,
                              "presentyAmt"
                            )}
                            disabled
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            value={project.canteenAmt || ""}
                            onChange={handleProjectInputChange(
                              projectIndex,
                              "canteenAmt"
                            )}
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            value={project.transportationAmt || ""}
                            onChange={handleProjectInputChange(
                              projectIndex,
                              "transportationAmt"
                            )}
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            value={project.fromDate || ""}
                            onChange={handleProjectInputChange(
                              projectIndex,
                              "fromDate"
                            )}
                            type="date"
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            value={project.toDate || ""}
                            onChange={handleProjectInputChange(
                              projectIndex,
                              "toDate"
                            )}
                            type="date"
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            value={project.employeeCode || ""}
                            onChange={handleProjectInputChange(
                              projectIndex,
                              "employeeCode"
                            )}
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            value={project.employeeName || ""}
                            onChange={handleProjectInputChange(
                              projectIndex,
                              "employeeName"
                            )}
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            value={project.individualAmount || ""}
                            onChange={handleProjectInputChange(
                              projectIndex,
                              "individualAmount"
                            )}
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            value={project.currentPoRate || ""}
                            onChange={handleProjectInputChange(
                              projectIndex,
                              "currentPoRate"
                            )}
                          />
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <Paper
              elevation={3}
              style={{ padding: 20, marginBottom: 20, marginTop: 20 }}
            >
              <Grid container spacing={6} style={{ marginTop: 10 }}>
                <Grid item xs={4}>
                  <Paper
                    elevation={3}
                    style={{ padding: "20px", marginBottom: "20px" }}
                  >
                    <TextField
                      label="Total Presenty Amount"
                      sx={{ marginBottom: 2, marginTop: 2 }}
                      variant="standard"
                      size="small"
                      fullWidth
                      value={
                        editedInvoiceData &&
                        editedInvoiceData.invoiceGstDetailsUI
                          ? editedInvoiceData.invoiceGstDetailsUI
                              .totalPresentyAmount || ""
                          : ""
                      }
                      onChange={(e) =>
                        handleGstDetailsChange(
                          "totalPresentyAmount",
                          e.target.value
                        )
                      }
                      disabled={
                        !editedInvoiceData ||
                        !editedInvoiceData.invoiceGstDetailsUI
                      }
                    />

                    <TextField
                      label="Total Tax Amt"
                      sx={{ marginBottom: 2, marginTop: 2 }}
                      variant="standard"
                      size="small"
                      fullWidth
                      value={
                        editedInvoiceData &&
                        editedInvoiceData.invoiceGstDetailsUI
                          ? editedInvoiceData.invoiceGstDetailsUI.totalTaxAmt ||
                            ""
                          : ""
                      }
                      onChange={(e) =>
                        handleGstDetailsChange("totalTaxAmt", e.target.value)
                      }
                      disabled={
                        !editedInvoiceData ||
                        !editedInvoiceData.invoiceGstDetailsUI
                      }
                    />

                    <TextField
                      label="Grand Total Amount"
                      sx={{ marginBottom: 2, marginTop: 2 }}
                      variant="standard"
                      size="small"
                      fullWidth
                      value={
                        editedInvoiceData &&
                        editedInvoiceData.invoiceGstDetailsUI
                          ? editedInvoiceData.invoiceGstDetailsUI
                              .grandTotalAmount || ""
                          : ""
                      }
                      onChange={(e) =>
                        handleGstDetailsChange(
                          "grandTotalAmount",
                          e.target.value
                        )
                      }
                      disabled={
                        !editedInvoiceData ||
                        !editedInvoiceData.invoiceGstDetailsUI
                      }
                    />

                    <TextField
                      label="CGST (%)"
                      sx={{ marginBottom: 2, marginTop: 2 }}
                      variant="standard"
                      size="small"
                      fullWidth
                      value={
                        editedInvoiceData &&
                        editedInvoiceData.invoiceGstDetailsUI
                          ? editedInvoiceData.invoiceGstDetailsUI.cGST || ""
                          : ""
                      }
                      onChange={(e) =>
                        handleGstDetailsChange("cGST", e.target.value)
                      }
                      disabled={
                        !editedInvoiceData ||
                        !editedInvoiceData.invoiceGstDetailsUI
                      }
                    />

                    <TextField
                      label="SGST (%)"
                      sx={{ marginBottom: 2, marginTop: 2 }}
                      variant="standard"
                      size="small"
                      fullWidth
                      value={
                        editedInvoiceData &&
                        editedInvoiceData.invoiceGstDetailsUI
                          ? editedInvoiceData.invoiceGstDetailsUI.sGST || ""
                          : ""
                      }
                      onChange={(e) =>
                        handleGstDetailsChange("sGST", e.target.value)
                      }
                      disabled={
                        !editedInvoiceData ||
                        !editedInvoiceData.invoiceGstDetailsUI
                      }
                    />

                    <TextField
                      label="IGST (%)"
                      sx={{ marginBottom: 2, marginTop: 2 }}
                      variant="standard"
                      size="small"
                      fullWidth
                      value={
                        editedInvoiceData &&
                        editedInvoiceData.invoiceGstDetailsUI
                          ? editedInvoiceData.invoiceGstDetailsUI.iGST || ""
                          : ""
                      }
                      onChange={(e) =>
                        handleGstDetailsChange("iGST", e.target.value)
                      }
                      disabled={
                        !editedInvoiceData ||
                        !editedInvoiceData.invoiceGstDetailsUI
                      }
                    />

                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleCalculate}
                      fullWidth
                    >
                      Calculate
                    </Button>
                  </Paper>
                </Grid>
              </Grid>
            </Paper>
          </Paper>

          <Paper
            elevation={3}
            style={{ padding: 20, marginBottom: 20, marginTop: 20 }}
          >
            <Grid container spacing={6} style={{ marginTop: 10 }}>
              <Grid item xs={4}>
                <TextField
                  label="Mode Terms Of Payment"
                  value={
                    editedInvoiceData?.invoicePaymentModeUI
                      .modeTermsOfPayment || ""
                  }
                  onChange={(e) =>
                    handlePaymentModeChange(
                      "modeTermsOfPayment",
                      e.target.value
                    )
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Kind Attention"
                  value={
                    editedInvoiceData?.invoicePaymentModeUI.kindAttention || ""
                  }
                  onChange={(e) =>
                    handlePaymentModeChange("kindAttention", e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Select Bank Acc No"
                  value={
                    editedInvoiceData?.invoicePaymentModeUI.selectBankAccNo ||
                    ""
                  }
                  onChange={(e) =>
                    handlePaymentModeChange("selectBankAccNo", e.target.value)
                  }
                  InputProps={{ readOnly: true }}
                />
              </Grid>
            </Grid>
          </Paper>

          <Paper
            elevation={3}
            style={{ padding: 20, marginBottom: 20, marginTop: 20 }}
          >
            <Grid container spacing={6} style={{ marginTop: 10 }}>
              <Grid item xs={4}>
                <TextField
                  label="Account Number"
                  value={editedInvoiceData?.bankUI.accountNumber || ""}
                  onChange={(e) =>
                    handleBankDetailsChange("accountNumber", e.target.value)
                  }
                  InputProps={{ readOnly: true }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Bank Name"
                  value={editedInvoiceData?.bankUI.bankName || ""}
                  onChange={(e) =>
                    handleBankDetailsChange("bankName", e.target.value)
                  }
                  InputProps={{ readOnly: true }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Account Type"
                  value={editedInvoiceData?.bankUI.accountType || ""}
                  onChange={(e) =>
                    handleBankDetailsChange("accountType", e.target.value)
                  }
                  InputProps={{ readOnly: true }}
                />
              </Grid>

              <Grid item xs={4}>
                <TextField
                  label="Branch Name"
                  value={editedInvoiceData?.bankUI.branchName || ""}
                  onChange={(e) =>
                    handleBankDetailsChange("branchName", e.target.value)
                  }
                  InputProps={{ readOnly: true }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="IFSC"
                  value={editedInvoiceData?.bankUI.ifsc || ""}
                  onChange={(e) =>
                    handleBankDetailsChange("ifsc", e.target.value)
                  }
                  InputProps={{ readOnly: true }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="MICR"
                  value={editedInvoiceData?.bankUI.micr || ""}
                  onChange={(e) =>
                    handleBankDetailsChange("micr", e.target.value)
                  }
                  InputProps={{ readOnly: true }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Bank Address"
                  value={editedInvoiceData?.bankUI.bankAddress || ""}
                  onChange={(e) =>
                    handleBankDetailsChange("bankAddress", e.target.value)
                  }
                  InputProps={{ readOnly: true }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Swift Code"
                  value={editedInvoiceData?.bankUI.swiftCode || ""}
                  onChange={(e) =>
                    handleBankDetailsChange("swiftCode", e.target.value)
                  }
                  InputProps={{ readOnly: true }}
                />
              </Grid>
            </Grid>
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setUpdateDialogOpen(false)}
            color="primary"
            variant="contained"
            style={{ marginLeft: "20px", marginBottom: "20px" }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleUpdateDialogSave}
            style={{ marginLeft: "20px", marginBottom: "20px" }}
            disabled={!hasChanges} // Disable the button if there are no changes
          >
            Update
          </Button>
          <Button
            onClick={() => handlegeneratePDF(editedInvoiceData.invoiceRefNo)}
            color="secondary"
            variant="contained"
            style={{ marginLeft: "20px", marginBottom: "20px" }}
          >
            PDF
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={deleteDialogOpen}
        onClose={() => {
          setDeleteDialogOpen(false);
          setPassword(""); // Reset password field
        }}
        maxHeight="md"
        fullWidth
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent style={{ marginBottom: 20, marginTop: 20 }}>
          <Paper elevation={2} style={{ padding: 20, marginBottom: 20 }}>
            <Grid container spacing={6}>
              <Grid item md={4}>
                <TextField
                  label="Enter Password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  fullWidth
                  autoFocus
                />
              </Grid>
            </Grid>
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleDialogDelete}
            color="primary"
            disabled={isDeleting}
          >
            {isDeleting ? <CircularProgress size={24} /> : "Delete"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default EmployeeInvoiceList;
