import React from 'react';
import { Page, Text, View, Document, StyleSheet, PDFDownloadLink } from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
  page: {
    padding: 20,
  },
  header: {
    fontSize: 10,
    textAlign: 'center',
    marginBottom: 10,
  },
  section: {
    marginBottom: 5,
    fontSize: 9,
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginBottom: 5,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableColHeader: {
    width: "14.28%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: '#f2f2f2',
  },
  tableCol: {
    width: "14.28%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCellHeader: {
    margin: 2,
    fontSize: 8,
    fontWeight: 'bold',
  },
  tableCell: {
    margin: 2,
    fontSize: 8,
  },
  footer: {
    marginTop: 10,
    textAlign: 'right',
    fontSize: 9,
  }
});

// Create Document Component
const MyDocument = () => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Text style={styles.header}>Office No. 404, Pentagon tower-2, Magarpatta city, Pune, Maharashtra 411013</Text>
        <Text style={styles.header}>India, North America & Europe | www.gsquaretech.com | hr@gsquaretech.com</Text>
      </View>

      <View style={styles.section}>
        <Text>Quote No: GST/24-25/LD-2001</Text>
        <Text>Date: 17th Apr 2024</Text>
      </View>

      <View style={styles.section}>
        <Text>TO,</Text>
        <Text>Mahindra and Mahindra Limited,</Text>
        <Text>Mahindra Research Valley, Mahindra World City, Plot No 41/1, ANJUR POST</Text>
        <Text>Chengalpattu, Kanchipuram Dist., Tamil Nadu- 603004</Text>
        <Text>Place of supply: TAMIL NADU State Code: 33</Text>
        <Text>Kind Attn: -SARAVANAKUMAR P</Text>
      </View>

      <View style={styles.section}>
        <Text>Subject: - “Quotation Regarding CAD Engineers”.(Lighting Design)</Text>
        <Text>With reference to your mail and further to our discussion about cost onsite Quotation Regarding CAD Engineers, we are happy to submit the following quote.</Text>
      </View>

      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={styles.tableColHeader}>
            <Text style={styles.tableCellHeader}>Sr No</Text>
          </View>
          <View style={styles.tableColHeader}>
            <Text style={styles.tableCellHeader}>Resource ID</Text>
          </View>
          <View style={styles.tableColHeader}>
            <Text style={styles.tableCellHeader}>Resource Name</Text>
          </View>
          <View style={styles.tableColHeader}>
            <Text style={styles.tableCellHeader}>Service Accounting Code (SAC)</Text>
          </View>
          <View style={styles.tableColHeader}>
            <Text style={styles.tableCellHeader}>Rate Per Month</Text>
          </View>
          <View style={styles.tableColHeader}>
            <Text style={styles.tableCellHeader}>Number of Month</Text>
          </View>
          <View style={styles.tableColHeader}>
            <Text style={styles.tableCellHeader}>Total Cost</Text>
          </View>
        </View>
        {[
          { id: 1, resourceId: '50004056', name: 'Jagadeesh Kiran', sac: '998513', rate: '135000', months: 12, cost: '16,20,000' },
          { id: 2, resourceId: '50007495', name: 'Kumar Simeon', sac: '998513', rate: '135000', months: 12, cost: '16,20,000' },
          { id: 3, resourceId: '50005346', name: 'Rahul Bhosale', sac: '998513', rate: '135000', months: 12, cost: '16,20,000' },
        ].map(item => (
          <View style={styles.tableRow} key={item.id}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{item.id}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{item.resourceId}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{item.name}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{item.sac}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{item.rate}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{item.months}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{item.cost}</Text>
            </View>
          </View>
        ))}
      </View>

      <View style={styles.section}>
        <Text>Total Amount: - Forty-Eight Lakh Sixty Thousand Rupees Only 48,60,000</Text>
        <Text>Terms and Conditions:</Text>
        <Text>1. GST @18%</Text>
        <Text>2. TDS as applicable</Text>
        <Text>3. Payment terms 15 days from the date of invoice</Text>
      </View>

      <View style={styles.footer}>
        <Text>Thanking you,</Text>
        <Text>Mr. M Krishnan</Text>
        <Text>Director</Text>
      </View>
    </Page>
  </Document>
);

// Main component
const DownloadPDFNew = () => (
  <div>
    <PDFDownloadLink document={<MyDocument />} fileName="quotation.pdf">
      {({ loading }) => (loading ? 'Loading document...' : 'Download PDF')}
    </PDFDownloadLink>
  </div>
);

export default DownloadPDFNew;
