import Headers from "../../../Pages/Header";
import UpdateExpenseHeader from "../UpdateExpenseHeader";

const UpdateExpenseHeaderPage = () => {
  return (
    <Headers>
      <UpdateExpenseHeader />
    </Headers>
  );
};
export default UpdateExpenseHeaderPage;
