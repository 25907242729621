import Headers from "../../../Pages/Header";
import UpdateCompanyProfile from "../UpdateCompanyProfile";

const UpdateCompanyProfilePage = () => {
  return (
    <Headers>
      <UpdateCompanyProfile />
    </Headers>
  );
};
export default UpdateCompanyProfilePage;
