import React, { useState, useEffect } from "react";
import axios from "axios";
import PizZip from "pizzip";
import Docxtemplater from "docxtemplater";
import { saveAs } from "file-saver";
import JSZipUtils from "jszip-utils";
import { myAxiosCommonHost } from "../../Service/CorsHelperBaseUrl";
import { PDFDocument } from "pdf-lib";

const QuotationGenerator = () => {
  const [data, setData] = useState(null);
  const [file, setFile] = useState(null);
  const [docBlob, setDocBlob] = useState(null);

  useEffect(() => {
    myAxiosCommonHost
      .get("/gst/quotation/getQuotationList")
      .then((response) => {
        setData(response.data[0]);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);
  const formatFileName = (subject, date) => {
    const formattedSubject = subject
      .replace(/[^a-zA-Z0-9]/g, " ")
      .trim()
      .replace(/\s+/g, "_");
    const formattedDate = date.toISOString().split("T")[0]; // Format date as YYYY-MM-DD
    return `GSQUARE_${formattedSubject}(${formattedDate}).docx`;
  };
  // const getOrdinalSuffix = (day) => {
  //     if (day > 3 && day < 21) return 'th';
  //     switch (day % 10) {
  //       case 1: return 'st';
  //       case 2: return 'nd';
  //       case 3: return 'rd';
  //       default: return 'th';
  //     }
  //   };
  //   const formatDateString = (date) => {
  //     const day = date.getDate();
  //     const month = date.toLocaleString('default', { month: 'long' });
  //     const year = date.getFullYear();
  //     const suffix = getOrdinalSuffix(day);
  //     return `${day}${suffix} ${month} ${year}`;
  //   };

  //   const formatFileName = (subject, date) => {
  //     const formattedSubject = subject.replace(/[^a-zA-Z0-9]/g, ' ').trim().replace(/\s+/g, '_');
  //     const formattedDate = formatDateString(date);
  //     return `GSQUARE_${formattedSubject}(${formattedDate}).docx`;
  //   };
  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const generateDocument = () => {
    if (!file) {
      alert("Please upload a DOCX template file first.");
      return;
    }

    const reader = new FileReader();
    reader.onload = async (event) => {
      const content = event.target.result;

      let zip;
      try {
        zip = new PizZip(content);
      } catch (e) {
        console.error("Error loading zip:", e);
        return;
      }

      let doc;
      try {
        doc = new Docxtemplater(zip, {
          paragraphLoop: true,
          linebreaks: true,
        });
      } catch (e) {
        console.error("Error creating Docxtemplater instance:", e);
        return;
      }
      doc.setData({
        quotationRefNo: data.quotationRefNo,
        quotationContent:data.quotationContent,
        quotationDate: new Date(data.quotationDate).toLocaleDateString(),
        clientName: data.clientUI.clientName,
        clientAddress: data.clientUI.clientAddress,
        kindAttention: data.kindAttention,
        quotationSubject: data.quotationSubject,
        totalAmount: data.totalAmount,
        totalInWord: data.totalInWord,
        state: data.gstDetailsUI.state,
        stateCode: data.gstDetailsUI.stateCode,
        serviceAccountingCode: data.serviceAccountingCode,
        accomodationUIList: data.accomodationUIList.map((item, index) => ({
          index: index + 1,
          description: item.description,
          costPerResources: item.costPerResources,
          noOfResources: item.noOfResources,
          noOfMonth: item.noOfMonth,
          totalCost: item.totalCost,
        })),
        quotationTermAndConditionsUI: data.quotationTermAndConditionsUI.map(
          (item, index) => ({
            termSequenceId: item.termSequenceId,
            termCondition: item.termCondition,
          })
        ),
      });
      try {
        doc.render();
      } catch (error) {
        console.error("Error rendering document:", error);
        return;
      }

      const out = doc.getZip().generate({
        type: "blob",
        mimeType:
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      });
      const fileName = formatFileName(data.quotationSubject, new Date());

      saveAs(out, fileName);
      setDocBlob(out);
    };

    reader.readAsBinaryString(file);
  };
  const generatePDF = async () => {
    if (!docBlob) {
      alert("Please generate the DOCX file first.");
      return;
    }

    const pdfDoc = await PDFDocument.create();
    const templateBytes = new Uint8Array(await docBlob.arrayBuffer());
    const templateDoc = await PDFDocument.load(templateBytes);

    const [templatePage] = await pdfDoc.copyPages(templateDoc, [0]);
    pdfDoc.addPage(templatePage);

    const pdfBytes = await pdfDoc.save();
    const pdfBlob = new Blob([pdfBytes], { type: "application/pdf" });

    const fileName = formatFileName(data.quotationSubject, new Date());

    // Save as PDF
    saveAs(pdfBlob, `${fileName}.pdf`);
  };

  return (
    <div>
      <h1>Quotation Generator</h1>
      <input type="file" accept=".docx" onChange={handleFileChange} />
      <button onClick={generateDocument}>Generate Quotation</button>
      <button onClick={generatePDF}>Download PDF</button>
    </div>
  );
};

export default QuotationGenerator;
