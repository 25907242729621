import Headers from "../../../Pages/Header";
import RegisterVendorInformation from "../RegisterVendorInformation";

const RegisterVendorInformationPage = () => {
  return (
    <Headers>
      <RegisterVendorInformation />
    </Headers>
  );
};
export default RegisterVendorInformationPage;
