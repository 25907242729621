import React, { useState, useEffect } from "react";
import axios from "axios";
import PizZip from "pizzip";
import Docxtemplater from "docxtemplater";
import { saveAs } from "file-saver";
import mammoth from "mammoth";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { myAxiosCommonHost } from "../../Service/CorsHelperBaseUrl";

const QuotationGenerator2 = () => {
  const [data, setData] = useState(null);
  const [docxBlob, setDocBlob] = useState(null);
  useEffect(() => {
    myAxiosCommonHost
      .get("/gst/quotation/getQuotationList")
      .then((response) => {
        setData(response.data[0]);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const getOrdinalSuffix = (day) => {
    if (day > 3 && day < 21) return "th";
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  const formatDateString = (date) => {
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();
    const suffix = getOrdinalSuffix(day);
    return `${day}${suffix} ${month} ${year}`;
  };

  const formatFileName = (subject, date) => {
    const formattedSubject = subject
      .replace(/[^a-zA-Z0-9]/g, " ")
      .trim()
      .replace(/\s+/g, "_");
    const formattedDate = formatDateString(date);
    return `GSQUARE_${formattedSubject}(${formattedDate})`;
  };

  const generateDocument = async () => {
    const filePath = "NewQuotationWord2.docx"; // Path to your DOCX file

    // Fetch the DOCX file from the server
    const response = await fetch(filePath);
    if (!response.ok) {
      alert("Failed to load the DOCX template file.");
      return;
    }

    const arrayBuffer = await response.arrayBuffer();
    const binaryString = arrayBufferToBinaryString(arrayBuffer);

    let zip;
    try {
      zip = new PizZip(binaryString);
    } catch (e) {
      console.error("Error loading zip:", e);
      return;
    }

    let doc;
    try {
      doc = new Docxtemplater(zip, {
        paragraphLoop: true,
        linebreaks: true,
      });
    } catch (e) {
      console.error("Error creating Docxtemplater instance:", e);
      return;
    }

    doc.setData({
      quotationRefNo: data.quotationRefNo,
      quotationDate: new Date(data.quotationDate).toLocaleDateString(),
      clientName: data.clientUI.clientName,
      clientAddress: data.clientUI.clientAddress,
      kindAttention: data.kindAttention,
      quotationSubject: data.quotationSubject,
      totalAmount: data.totalAmount,
      totalInWord: data.totalInWord,
      state: data.gstDetailsUI.state,
      stateCode: data.gstDetailsUI.stateCode,
      serviceAccountingCode: data.serviceAccountingCode,
      accomodationUIList: data.accomodationUIList.map((item, index) => ({
        index: index + 1,
        description: item.description,
        costPerResources: item.costPerResources,
        noOfResources: item.noOfResources,
        noOfMonth: item.noOfMonth,
        totalCost: item.totalCost,
      })),
    });

    try {
      doc.render();
    } catch (error) {
      console.error("Error rendering document:", error);
      return;
    }

    const out = doc.getZip().generate({
      type: "blob",
      mimeType:
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    });
    const fileName = formatFileName(
        data.quotationSubject,
      new Date()
    );

    saveAs(out, fileName);
    setDocBlob(out);
  };

  // Helper function to convert ArrayBuffer to binary string
  function arrayBufferToBinaryString(buffer) {
    let binary = "";
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return binary;
  }

  const generatePDF = () => {
    if (!docxBlob) {
      alert("Please generate the DOCX file first.");
      return;
    }

    mammoth
      .convertToHtml({ arrayBuffer: docxBlob.arrayBuffer() })
      .then((htmlResult) => {
        const element = document.createElement("div");
        element.innerHTML = htmlResult.value;

        document.body.appendChild(element); // Append to body to ensure rendering
        html2canvas(element, { useCORS: true })
          .then((canvas) => {
            const imgData = canvas.toDataURL("image/png");
            const pdf = new jsPDF();
            const imgWidth = 210; // A4 size width in mm
            const pageHeight = 295; // A4 size height in mm
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            let heightLeft = imgHeight;
            let position = 0;

            pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;

            while (heightLeft >= 0) {
              position = heightLeft - imgHeight;
              pdf.addPage();
              pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
              heightLeft -= pageHeight;
            }

            const fileName = formatFileName(data.quotationSubject, new Date());
            pdf.save(`${fileName}.pdf`);

            document.body.removeChild(element); // Clean up the element
          })
          .catch((error) => {
            console.error("Error generating PDF:", error);
          });
      })
      .catch((error) => {
        console.error("Error converting DOCX to HTML:", error);
      });
  };

  return (
    <div>
      <h1>Quotation Generator</h1>
      <button onClick={generateDocument}>Generate DOCX</button>
      <button onClick={generatePDF}>Download PDF</button>
    </div>
  );
};

export default QuotationGenerator2;
