import React, { useState } from "react";
import axios from "axios";
import { myAxiosCommonHost } from "../Service/CorsHelperBaseUrl";

const FileUpload = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadSuccess, setUploadSuccess] = useState(false);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    setUploadSuccess(false);
    setUploadProgress(0);
  };

  const handleFileUpload = async () => {
    if (!selectedFile) {
      alert("Please select a file to upload.");
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);

    try {
      const response = await myAxiosCommonHost.post(
        "gst/clientFile/upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            const percentCompleted = Math.round((loaded * 100) / total);
            setUploadProgress(percentCompleted);
          },
        }
      );

      if (response.status === 200) {
        setUploadSuccess(true);
      } else {
        alert("File upload failed, please try again.");
      }
    } catch (error) {
      console.error("There was an error uploading the file!", error);
      alert("File upload failed, please try again.");
    }
  };

  return (
    <div>
      <h1>Upload a File</h1>
      <input type="file" onChange={handleFileChange} />
      <button onClick={handleFileUpload}>Upload</button>
      {uploadProgress > 0 && (
        <progress value={uploadProgress} max="100">
          {uploadProgress}%
        </progress>
      )}
      {uploadSuccess && <p>File uploaded successfully!</p>}
    </div>
  );
};

export default FileUpload;
