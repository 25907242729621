import Headers from "../../../Pages/Header";
import OutStandingUnPaidHistory from "../UnpaidInvoiceHistory";

const OutStandingUnPaidHistoryPage= () => {
  return (
    <Headers>
      <OutStandingUnPaidHistory/>
    </Headers>
  );
};
export default OutStandingUnPaidHistoryPage;