import React, { useState } from 'react';
import axios from 'axios';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { myAxiosCommonHost } from '../Service/CorsHelperBaseUrl';

const MyComponent = () => {
  const [loading, setLoading] = useState(false);

  const downloadPDF = async () => {
    setLoading(true);
    try {
      const response = await myAxiosCommonHost.get('gst/quotation/getQuotationList');
      const data = response.data;

      data.forEach(item => {
        const { quotationRefNo, selectCompany, quotationNo, quotationDate, clientNameAndCode, kindAttention, quotationSubject, quotationContent, totalAmount, totalInWord, financialYear, financialMonth, accomodationUIList } = item;

        // Create a new PDF document
        const doc = new jsPDF();

        // Add company details and header
        doc.setFontSize(10);
        doc.text('Office No. 404, Pentagon tower-2, Magarpatta city, Pune Maharashtra 411013', 20, 10);
        doc.text('India, North America & Europe', 20, 15);
        doc.text('www.gsquaretech.com', 20, 20);
        doc.text('hr@gsquaretech.com', 20, 25);
        
        doc.setFontSize(14);
        doc.text('Quote No: ' + quotationRefNo, 150, 10);
        doc.text('Date: ' + quotationDate, 150, 15);

        // Add client details
        doc.setFontSize(12);
        doc.text('TO,', 20, 35);
        doc.text(clientNameAndCode, 20, 40);
        doc.text('Place of supply: TAMIL NADU State Code: 33', 20, 45);
        doc.text('Kind Attn: ' + kindAttention, 20, 50);
        
        // Add subject and introductory text
        doc.setFontSize(12);
        doc.text('Subject: - ' + quotationSubject, 20, 60);
        doc.text('With reference to your mail and further to our discussion about cost onsite Quotation Regarding CAD Engineers, we are happy to submit the following quote.', 20, 70);

        // Add Quotation Details table
        doc.autoTable({
          startY: 80,
          head: [['Field', 'Value']],
          body: [
            ['Select Company', selectCompany],
            ['Quotation Ref No', quotationRefNo],
            ['Quotation No', quotationNo],
            ['Quotation Date', quotationDate],
            ['Client Name And Code', clientNameAndCode],
            ['Kind Attention', kindAttention],
            ['Quotation Subject', quotationSubject],
            ['Quotation Content', quotationContent],
            ['Total Amount', totalAmount],
            ['Total In Word', totalInWord],
            ['Financial Year', financialYear],
            ['Financial Month', financialMonth],
          ],
        });

        // Add Accommodation Details table
        doc.text('Accommodation Details', 20, doc.autoTable.previous.finalY + 10);

        doc.autoTable({
          startY: doc.autoTable.previous.finalY + 20,
          head: [['Description', 'Cost Per Resource', 'No Of Resources', 'No Of Months', 'Total Cost']],
          body: accomodationUIList.map(acc => [acc.description, acc.costPerResources, acc.noOfResources, acc.noOfMonth, acc.totalCost]),
        });

        // Add Terms and Conditions
        doc.text('Terms and Conditions:', 20, doc.autoTable.previous.finalY + 10);
        doc.text('1. GST @18%', 20, doc.autoTable.previous.finalY + 20);
        doc.text('2. TDS as applicable', 20, doc.autoTable.previous.finalY + 30);
        doc.text('3. Payment terms 15 days from the date of invoice', 20, doc.autoTable.previous.finalY + 40);

        // Add footer with signature
        doc.text('Thanking you,', 20, doc.autoTable.previous.finalY + 60);
        doc.text('Mr. M Krishnan', 20, doc.autoTable.previous.finalY + 70);
        doc.text('Director', 20, doc.autoTable.previous.finalY + 80);

        // Save the PDF with a unique filename
        doc.save(`quotation_list(${quotationRefNo}).pdf`);
      });

    } catch (error) {
      console.error("Error downloading the PDF file:", error);
      alert('Error downloading the PDF file. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {loading ? (
        <div className="loading-animation">
          {/* Replace with your actual loading animation component */}
          Loading...
        </div>
      ) : (
        <button onClick={downloadPDF} disabled={loading}>
          {loading ? 'Downloading...' : 'Download PDF'}
        </button>
      )}
    </div>
  );
};

export default MyComponent;
