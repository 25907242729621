import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Collapse,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  TablePagination,
  Grid,
  Tab,
  Tabs,
  Box,
  CircularProgress,
  Backdrop,
} from "@material-ui/core";
import { Autocomplete } from "@mui/material";
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  Edit as EditIcon,
  Search,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import * as XLSX from "xlsx";
import { myAxiosCommonHost } from "../../Service/CorsHelperBaseUrl";
import { saveAs } from "file-saver";
import PizZip from "pizzip";
import Docxtemplater from "docxtemplater";
import JSZipUtils from "jszip-utils";
import file from "./NewQuotationWord2.docx";
import { PDFDocument } from "pdf-lib";
import NewQuotationWord2 from "./NewQuotationWord2.docx";
import { CompanyContext } from "../StateProvider/CompanyProvider";

const Row = ({ row, onDelete, onUpdate, onDownloadPdf }) => {
  const [open, setOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [editedQuotation, setEditedQuotation] = useState({ ...row });
  const [editedAccomodation, setEditedAccomodation] = useState(
    Array.isArray(row.accomodationUIList) ? [...row.accomodationUIList] : []
  );
  const [editedQuotationTerm, setEditedQuotationTerm] = useState(
    Array.isArray(row.quotationTermAndConditionsUI)
      ? [...row.quotationTermAndConditionsUI]
      : []
  );
  const [tabValue, setTabValue] = useState(0);
  const [isDataChangedEdit, setIsDataChangedEdit] = useState(false);

  const handleEdit = () => {
    setDialogOpen(true);
  };

  const handleDownloadPDF = () => {
    onDownloadPdf(row.quotationNo);
  };
  const handleDelete = () => {
    onDelete(row.quotationNo);
  };

  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    setEditedQuotation((prevQuotation) => ({
      ...prevQuotation,
      [name]: value,
    }));
    setIsDataChangedEdit(true);
  };

  const handleAccomodationChange = (e, index) => {
    const { name, value } = e.target;
    const updatedAccomodation = [...editedAccomodation];
    updatedAccomodation[index] = {
      ...updatedAccomodation[index],
      [name]: value,
    };
    setEditedAccomodation(updatedAccomodation);
    setIsDataChangedEdit(true);
  };
  const handleQuotationTermChange = (e, index) => {
    const { name, value } = e.target;
    const updatedQuotationTerm = [...editedQuotationTerm];
    updatedQuotationTerm[index] = {
      ...updatedQuotationTerm[index],
      [name]: value,
    };
    setEditedQuotationTerm(updatedQuotationTerm);
    setIsDataChangedEdit(true);
  };
  const handleAddAccomodation = () => {
    setEditedAccomodation([
      ...editedAccomodation,
      {
        description: "",
        costPerResources: "",
        noOfResources: "",
        noOfMonth: "",
        totalCost: "",
      },
    ]);
  };
  const handleAddQuotationTerm = () => {
    setEditedQuotationTerm([
      ...editedQuotationTerm,
      {
        termSequenceId: "",
        termCondition: "",
      },
    ]);
  };
  const handleDeleteAccomodation = (index) => {
    const updatedAccomodation = [...editedAccomodation];
    updatedAccomodation.splice(index, 1);
    setEditedAccomodation(updatedAccomodation);
    setIsDataChangedEdit(true);
  };
  const handleDeleteQuotationTerm = (index) => {
    const updatedQuotationTerm = [...editedQuotationTerm];
    updatedQuotationTerm.splice(index, 1);
    setEditedQuotationTerm(updatedQuotationTerm);
    setIsDataChangedEdit(true);
  };

  const handleSave = () => {
    const updatedQuotation = {
      ...editedQuotation,
      accomodationUIList: editedAccomodation,
      quotationTermAndConditionsUI: editedQuotationTerm,
    };
    onUpdate(updatedQuotation);
    setDialogOpen(false);
  };
  if (!editedQuotation) {
    return <div>Loading...</div>;
  }
  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell>{row.selectCompany}</TableCell>
        <TableCell>{row.quotationRefNo}</TableCell>
        <TableCell>{row.quotationNo}</TableCell>
        <TableCell>{row.clientNameAndCode}</TableCell>
        <TableCell>
          <Button onClick={handleEdit} variant="contained" color="primary">
            Update
          </Button>
          <Button
            onClick={handleDelete}
            color="error"
            variant="contained"
            style={{
              marginLeft: "8px",
              backgroundColor: "red",
              color: "white",
            }}
          >
            Delete
          </Button>
          <Button
            onClick={handleDownloadPDF}
            color="error"
            variant="contained"
            style={{
              marginLeft: "8px",
              backgroundColor: "red",
              color: "white",
            }}
          >
            Download
          </Button>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 10, paddingTop: 10 }} colSpan={5}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <div>
              <Box style={{ padding: 20 }}>
                <Typography variant="h6">Quotation Information</Typography>
                <TableRow
                  style={{
                    backgroundColor: "darkcyan",
                    fontWeight: "bold",
                    color: "darkblue",
                  }}
                >
                  <TableCell>Select Company:</TableCell>
                  <TableCell>Financial Year:</TableCell>
                  <TableCell>Financial Month:</TableCell>
                  <TableCell>Quotation Ref Number:</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{row.selectCompany}</TableCell>
                  <TableCell> {row.financialYear || "N/A"}</TableCell>
                  <TableCell>{row.financialMonth || "N/A"}</TableCell>
                  <TableCell>{row.quotationRefNo}</TableCell>
                </TableRow>
                <TableRow
                  style={{
                    backgroundColor: "darkcyan",
                    fontWeight: "bold",
                    color: "darkblue",
                  }}
                >
                  <TableCell>Quotation No:</TableCell>
                  <TableCell>Quotation Date:</TableCell>
                  <TableCell>Client Name And Code:</TableCell>
                  <TableCell>Kind Attention:</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{row.quotationNo || "N/A"}</TableCell>
                  <TableCell>
                    {row.quotationDate
                      ? row.quotationDate.split("-").reverse().join("-")
                      : "N/A"}
                  </TableCell>
                  <TableCell>{row.clientNameAndCode || "N/A"}</TableCell>
                  <TableCell>{row.kindAttention || "N/A"}</TableCell>
                </TableRow>
                <TableRow
                  style={{
                    backgroundColor: "darkcyan",
                    fontWeight: "bold",
                    color: "darkblue",
                  }}
                >
                  <TableCell>Quotation Subject:</TableCell>
                  <TableCell>Quotation Content:</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{row.quotationSubject || "N/A"}</TableCell>
                  <TableCell>{row.quotationContent || "N/A"}</TableCell>
                </TableRow>
                <TableRow
                  style={{
                    backgroundColor: "darkcyan",
                    fontWeight: "bold",
                    color: "darkblue",
                  }}
                >
                  <TableCell>Total Amount:</TableCell>
                  <TableCell>Total In Word:</TableCell>
                  <TableCell>Service Accounting Code</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell> {row.totalAmount || "N/A"}</TableCell>
                  <TableCell>{row.totalInWord || "N/A"}</TableCell>
                  <TableCell>{row.serviceAccountingCode || "N/A"}</TableCell>
                </TableRow>
                <Typography variant="h6">Accomodation Details</Typography>
                {row.accomodationUIList ? (
                  row.accomodationUIList.map((accomodation, index) => (
                    <div key={index}>
                      <TableRow
                        style={{
                          backgroundColor: "darkcyan",
                          fontWeight: "bold",
                          color: "darkblue",
                        }}
                      >
                        <TableCell>Sr No :</TableCell>
                        <TableCell>Description:</TableCell>
                        <TableCell>Cost Per Resources: </TableCell>
                        <TableCell>Number Of Resources : </TableCell>
                        <TableCell>Number Of Month:</TableCell>
                        <TableCell>Total Cost</TableCell>
                      </TableRow>
                      <TableRow>
                        {/* <TableCell>{index + 1}</TableCell> */}
                        <TableCell>
                          {accomodation?.accomodationSrNo || "N/A"}
                        </TableCell>
                        <TableCell>
                          {accomodation?.description || "N/A"}
                        </TableCell>
                        <TableCell>
                          {accomodation?.costPerResources || "N/A"}
                        </TableCell>
                        <TableCell>
                          {accomodation?.noOfResources || "N/A"}
                        </TableCell>
                        <TableCell>
                          {accomodation?.noOfMonth || "N/A"}
                        </TableCell>
                        <TableCell>
                          {accomodation?.totalCost || "N/A"}
                        </TableCell>
                      </TableRow>
                    </div>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      No Accomodation found
                    </TableCell>
                  </TableRow>
                )}
                <Typography variant="h6"> Terms And Conditions</Typography>
                {row.quotationTermAndConditionsUI ? (
                  row.quotationTermAndConditionsUI.map(
                    (termAndConditions, index) => (
                      <div key={index}>
                        <TableRow
                          style={{
                            backgroundColor: "darkcyan",
                            fontWeight: "bold",
                            color: "darkblue",
                          }}
                        >
                          <TableCell>Sr.No:</TableCell>
                          <TableCell>Term And Conditions</TableCell>
                        </TableRow>
                        <TableRow>
                          {/* <TableCell>{index + 1}</TableCell> */}
                          <TableCell>
                            {termAndConditions?.termSequenceId || "N/A"}
                          </TableCell>
                          <TableCell>
                            {termAndConditions?.termCondition || "N/A"}
                          </TableCell>
                        </TableRow>
                      </div>
                    )
                  )
                ) : (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      No Term And Conditions found
                    </TableCell>
                  </TableRow>
                )}
              </Box>
            </div>
          </Collapse>
        </TableCell>
      </TableRow>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        maxWidth="lg"
        maxHeight="lg"
        fullWidth
      >
        <DialogTitle>Edit Accomodation Information</DialogTitle>
        <DialogContent>
          <Tabs
            value={tabValue}
            onChange={(event, newValue) => setTabValue(newValue)}
            variant="fullWidth"
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="Quotation Information" />
            <Tab label="Accomodation Information" />
            <Tab label="Term And Conditions" />
          </Tabs>
          {tabValue === 0 && (
            <>
              <Paper
                elevation={3}
                style={{ padding: 10, marginBottom: 10, marginTop: 10 }}
              >
                <Grid container spacing={6} style={{ marginTop: 10 }}>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="Select Company"
                      name="selectCompany"
                      value={editedQuotation.selectCompany || ""}
                      onChange={handleFieldChange}
                      fullWidth
                      disabled
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="Service Accounting Code(SAC)"
                      name="serviceAccountingCode"
                      value={editedQuotation.serviceAccountingCode || ""}
                      onChange={handleFieldChange}
                      fullWidth
                      disabled
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="Quotation Ref Number"
                      name="quotationRefNo"
                      value={editedQuotation.quotationRefNo || ""}
                      onChange={handleFieldChange}
                      fullWidth
                      disabled
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="Quotation No"
                      name="quotationNo"
                      value={editedQuotation.quotationNo || ""}
                      onChange={handleFieldChange}
                      fullWidth
                      disabled
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="Quotation Date"
                      name="quotationDate"
                      type="date"
                      value={editedQuotation.quotationDate || ""}
                      onChange={handleFieldChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="Client Name And Code"
                      name="clientNameAndCode"
                      value={editedQuotation.clientNameAndCode || ""}
                      onChange={handleFieldChange}
                      fullWidth
                      variant="standard"
                      disabled
                    />
                  </Grid>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="Kind Attention"
                      name="kindAttention"
                      value={editedQuotation.kindAttention || ""}
                      onChange={handleFieldChange}
                      fullWidth
                      variant="standard"
                    />
                  </Grid>
                  {/* <Grid item xs={2} md={4}>
                    <TextField
                      label="Quotation Subject"
                      name="quotationSubject"
                      value={editedQuotation.quotationSubject || ""}
                      onChange={handleFieldChange}
                      fullWidth
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="Quotation Content"
                      name="quotationContent"
                      value={editedQuotation.quotationContent || ""}
                      onChange={handleFieldChange}
                      fullWidth
                      variant="standard"
                    />
                  </Grid> */}
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Quotation Subject"
                      name="quotationSubject"
                      value={editedQuotation.quotationSubject || ""}
                      onChange={handleFieldChange}
                      fullWidth
                      variant="outlined" // Change the variant to outlined for multiline TextField
                      multiline // Add the multiline prop for multiline functionality
                      rows={6} // Adjust the rows prop as needed
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Quotation Content"
                      name="quotationContent"
                      value={editedQuotation.quotationContent || ""}
                      onChange={handleFieldChange}
                      fullWidth
                      variant="outlined"
                      multiline
                      rows={6}
                    />
                  </Grid>

                  <Grid item xs={2} md={4}>
                    <TextField
                      label="Total Amount"
                      name="totalAmount"
                      value={editedQuotation.totalAmount || ""}
                      onChange={handleFieldChange}
                      fullWidth
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="Total In Word"
                      name="Total In Word"
                      value={editedQuotation.totalInWord || ""}
                      onChange={handleFieldChange}
                      fullWidth
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="Financial Year"
                      name="financialYear"
                      value={editedQuotation.financialYear || ""}
                      onChange={handleFieldChange}
                      fullWidth
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="Financial Month"
                      name="financialMonth"
                      value={editedQuotation.financialMonth || ""}
                      onChange={handleFieldChange}
                      fullWidth
                      variant="standard"
                    />
                  </Grid>
                </Grid>
              </Paper>
            </>
          )}

          {tabValue === 1 && (
            <>
              <Typography variant="h6">Accomodation</Typography>
              {editedAccomodation.map((accomodation, index) => (
                <Paper
                  key={index}
                  elevation={3}
                  style={{ padding: 10, marginBottom: 10, marginTop: 10 }}
                >
                  <Grid container spacing={4} style={{ marginTop: 10 }}>
                    <Grid item xs={2} md={4}>
                      <TextField
                        label={`Description`}
                        name="description"
                        value={accomodation.description}
                        onChange={(e) => handleAccomodationChange(e, index)}
                        fullWidth
                        variant="standard"
                      />
                    </Grid>

                    <Grid item xs={2} md={4}>
                      <TextField
                        label={`Cost Per Resources`}
                        name="costPerResources"
                        value={accomodation.costPerResources}
                        onChange={(e) => handleAccomodationChange(e, index)}
                        fullWidth
                        variant="standard"
                      />
                    </Grid>
                    <Grid item xs={2} md={4}>
                      <TextField
                        label={`Number Of Resources`}
                        name={"noOfResources"}
                        value={accomodation.noOfResources}
                        onChange={(e) => handleAccomodationChange(e, index)}
                        fullWidth
                        variant="standard"
                      />
                    </Grid>
                    <Grid item xs={2} md={4}>
                      <TextField
                        label={`Number Of Month`}
                        name={"noOfMonth"}
                        value={accomodation.noOfMonth}
                        onChange={(e) => handleAccomodationChange(e, index)}
                        fullWidth
                        variant="standard"
                      />
                    </Grid>
                    <Grid item xs={2} md={4}>
                      <TextField
                        label={`Total Cost`}
                        name={"totalCost"}
                        value={accomodation.totalCost}
                        onChange={(e) => handleAccomodationChange(e, index)}
                        fullWidth
                        variant="standard"
                      />
                    </Grid>
                    <Paper
                      elevation={1}
                      style={{ padding: 5, marginBottom: 5, marginTop: 5 }}
                    >
                      <Button
                        variant="contained"
                        onClick={() => handleDeleteAccomodation(index)}
                        style={{ backgroundColor: "red", color: "white" }}
                      >
                        Delete
                      </Button>
                    </Paper>
                  </Grid>
                </Paper>
              ))}
              <Paper
                elevation={2}
                style={{ padding: 5, marginBottom: 5, marginTop: 5 }}
              >
                <Button
                  variant="contained"
                  onClick={handleAddAccomodation}
                  style={{ backgroundColor: "blue", color: "white" }}
                >
                  Add Accomodation
                </Button>
              </Paper>
            </>
          )}
          {tabValue === 2 && (
            <>
              <Typography variant="h6">Term And Conditions</Typography>
              {editedQuotationTerm.map((quotationTerm, index) => (
                <Paper
                  key={index}
                  elevation={3}
                  style={{ padding: 10, marginBottom: 10, marginTop: 10 }}
                >
                  <Grid container spacing={4} style={{ marginTop: 10 }}>
                    <Grid item xs={2} md={4}>
                      <TextField
                        label={`SR.No`}
                        name="termSequenceId"
                        value={quotationTerm.termSequenceId}
                        onChange={(e) => handleQuotationTermChange(e, index)}
                        fullWidth
                        variant="standard"
                      />
                    </Grid>

                    <Grid item xs={2} md={4}>
                      <TextField
                        label={`Term And Condition`}
                        name="termCondition"
                        value={quotationTerm.termCondition}
                        onChange={(e) => handleQuotationTermChange(e, index)}
                        fullWidth
                        variant="standard"
                      />
                    </Grid>
                    <Paper
                      elevation={1}
                      style={{ padding: 5, marginBottom: 5, marginTop: 5 }}
                    >
                      <Button
                        variant="contained"
                        onClick={() => handleDeleteQuotationTerm(index)}
                        style={{ backgroundColor: "red", color: "white" }}
                      >
                        Delete
                      </Button>
                    </Paper>
                  </Grid>
                </Paper>
              ))}
              <Paper
                elevation={2}
                style={{ padding: 5, marginBottom: 5, marginTop: 5 }}
              >
                <Button
                  variant="contained"
                  onClick={handleAddQuotationTerm}
                  style={{ backgroundColor: "blue", color: "white" }}
                >
                  Add Terms And Conditions
                </Button>
              </Paper>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleSave} disabled={!isDataChangedEdit}>
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`}>
      {value === index && <div>{children}</div>}
    </div>
  );
}

const UpdateQuotation = () => {
  const getCurrentFinancialYear = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const fiscalStartYear = currentYear - (currentDate.getMonth() >= 3 ? 0 : 1);
    const fiscalEndYear = fiscalStartYear + 1;
    return `${(fiscalStartYear % 100).toString().padStart(2, "0")}-${(
      fiscalEndYear % 100
    )
      .toString()
      .padStart(2, "0")}`;
  };
  const defaultFinancialYear = getCurrentFinancialYear();
  // const [selectedFinancialYear, setSelectedFinancialYear] =
  //   useState(defaultFinancialYear);
  // const [selectedCompany, setSelectedCompany] = useState("");
  const [financialYear, setFinancialYear] = useState([]);
  const [company, setCompany] = useState([]);
  const [quotationData, setQuotationData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false); // State to control deletion overlay
  const [isUpdating, setIsUpdating] = useState(false); // State to control update overlay
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [excelloading, setExcelLoading] = useState(false);
  const [quotationPDFData, setQuotationPDFData] = useState(null);
  const [pDFloading, setPDFLoading] = useState(true);
  const [docBlob, setDocBlob] = useState(null);
  const [totalCount, setTotalCount] = useState(0); // Initialize total count state
  const [searchQuotationNo, setSearchQuotationNo] = useState("");
  const [searchQuotationRefNo, setSearchQuotationRefNo] = useState("");
  const [searchClientName, setSearchClientName] = useState("");
  const { selectedCompany } = useContext(CompanyContext);
  const { selectedFinancialYear } = useContext(CompanyContext);
  const fetchQuotationCount = () => {
    myAxiosCommonHost
      .get(
        `gst/quotation/getQutationCount?selectCompany=${selectedCompany}&financialYear=${selectedFinancialYear}`
      )
      .then((response) => {
        const count = parseInt(response.data);
        setTotalCount(count);
      })
      .catch((error) => {
        console.error("Error fetching employee count:", error);
      });
  };
  // useEffect(() => {
  //   async function fetchFinancialYear() {
  //     try {
  //       const response = await myAxiosCommonHost.get(
  //         "gst/invoice/getFiscalYear"
  //       );
  //       setFinancialYear(response.data);
  //     } catch (error) {
  //       console.error("Error fetching financial year data:", error);
  //     }
  //   }
  //   fetchFinancialYear();
  // }, []);
  // useEffect(() => {
  //   async function fetchCompany() {
  //     try {
  //       const response = await myAxiosCommonHost.get(
  //         "gst/profile/getCompanyCodeList"
  //       );
  //       setCompany(response.data);
  //     } catch (error) {
  //       console.error("Error fetching financial year data:", error);
  //     }
  //   }
  //   fetchCompany();
  // }, []);

  // useEffect(() => {
  //   async function fetchCompany() {
  //     try {
  //       const response = await myAxiosCommonHost.get(
  //         "gst/profile/getCompanyCodeList"
  //       );
  //       const companies = response.data;
  //       setCompany(companies);
  //       if (companies.length > 0) {
  //         setSelectedCompany(companies[0]); // Set the default company to the first one
  //       }
  //     } catch (error) {
  //       console.error("Error fetching company data:", error);
  //     }
  //   }
  //   fetchCompany();
  // }, []);
  useEffect(() => {
    const fetchQuotationData = () => {
      setLoading(true);
      const limit = rowsPerPage;
      const offset = page * rowsPerPage;
      if (selectedCompany && selectedFinancialYear) {
        myAxiosCommonHost
          .get(
            `gst/quotation/getQuotationListFinancialYearWise?selectCompany=${selectedCompany}&financialYear=${selectedFinancialYear}&limit=${limit}&offset=${offset}`
          )
          .then((response) => {
            setQuotationData(response.data);
            setLoading(false);
          })
          .catch((error) => {
            console.error("Error fetching client data:", error);
            setError(error);
            setLoading(false);
          });
      }
    };
    fetchQuotationCount();
    fetchQuotationData();
  }, [selectedCompany, selectedFinancialYear, rowsPerPage, page]);
  // useEffect(() => {
  //   fetchQuotationCount();
  //   fetchQuotationData();
  // }, [rowsPerPage, page]);
  const downloadExcel = async () => {
    setExcelLoading(true);
    const quotationExcelData = quotationData.map((item) => ({
      "Select Company": item.selectCompany,
      "Service Accounting Code(SAC)": item.serviceAccountingCode,
      "Quotation Ref No": item.quotationRefNo,
      "Quotation No": item.quotationNo,
      "Quotation Date": item.quotationDate,
      "Client Name And Code": item.clientNameAndCode,
      "Kind Attention": item.kindAttention,
      "Quotation Subject": item.quotationSubject,
      "Quotation Content": item.quotationContent,
      "Total Amount": item.totalAmount,
      "Total In Word": item.totalInWord,
      "Financial Year": item.financialYear,
      "Financial Month": item.financialMonth,
    }));
    // Prepare the accommodation data
    const accommodationData = quotationData.flatMap((item, index) =>
      item.accomodationUIList.map((acc) => ({
        "Quotation Ref No": item.quotationRefNo,
        Description: acc.description,
        "Cost Per Resource": acc.costPerResources,
        "No Of Resources": acc.noOfResources,
        "No Of Months": acc.noOfMonth,
        "Total Cost": acc.totalCost,
      }))
    );

    // Create a new workbook
    const workbook = XLSX.utils.book_new();

    // Add the main quotation data worksheet
    const quotationWorksheet = XLSX.utils.json_to_sheet(quotationExcelData);
    XLSX.utils.book_append_sheet(workbook, quotationWorksheet, "Quotations");

    // Add the accommodation data worksheet
    const accommodationWorksheet = XLSX.utils.json_to_sheet(accommodationData);
    XLSX.utils.book_append_sheet(
      workbook,
      accommodationWorksheet,
      "Accommodations"
    );

    // Generate a Blob from the workbook
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Create a download link and click it programmatically
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `quotation_list.xlsx`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
    setExcelLoading(false);
  };

  const handleUpdateQuotation = (updatedQuotation) => {
    setIsUpdating(true); // Show update overlay
    myAxiosCommonHost
      .put("gst/quotation/updateQuotation", updatedQuotation)
      .then((response) => {
        console.log("Quotation Data Updated Successfully.");
        fetchQuotationData();
        setIsUpdating(false); // Hide update overlay after update
      })
      .catch((error) => {
        console.error("Error updating Quotation data: ", error);
        setIsUpdating(false); // Hide update overlay on error
      });
  };
  const handleDeleteQuotation = (quotationNo) => {
    // Delete the Quotation with the given QuotationCode
    if (window.confirm("Are You Sure You Want To Delete This Quotation?")) {
      setIsDeleting(true); // Show deletion overlay
      const encodedQuotationNo = encodeURIComponent(quotationNo);
      myAxiosCommonHost
        .delete(
          `gst/quotation/deleteQuotation?quotationNo=${encodedQuotationNo}`
        )
        .then((response) => {
          console.log("Quotation deleted successfully.");
          fetchQuotationData();
          setIsDeleting(false); // Hide deletion overlay after deletion
        })
        .catch((error) => {
          console.error("Error Deleting Quotation: ", error);
          setIsDeleting(false); // Hide deletion overlay on error
        });
    }
  };
  const formatFileName = (subject, date) => {
    const formattedSubject = subject
      .replace(/[^a-zA-Z0-9]/g, " ")
      .trim()
      .replace(/\s+/g, "_");
    const formattedDate = date.toISOString().split("T")[0];
    return `GSQUARE_${formattedSubject}(${formattedDate}).docx`;
  };
  const handleDownloadPDF = (quotationNo) => {
    setPDFLoading(true);
    const encodedQuotationNo = encodeURIComponent(quotationNo);
    myAxiosCommonHost
      .get(`gst/quotation/viewQuotationPdf?quotationNo=${encodedQuotationNo}`)
      .then((response) => {
        setQuotationPDFData(response.data[0]);
        console.log(response.data);
        setPDFLoading(false);
        generateDocument(quotationPDFData);
      })
      .catch((error) => {
        console.error("Error fetching Quotation data:", error);
        setPDFLoading(false);
      });
  };

  const generateDocument = async (quotationPDFData) => {
    // if (!quotationPDFData) {
    //   alert("Data is not loaded yet.");
    //   return;
    // }

    let content;
    try {
      content = await fetch(NewQuotationWord2).then((res) => res.arrayBuffer());
    } catch (error) {
      console.error("Error fetching template:", error);
      return;
    }

    let zip;
    try {
      zip = new PizZip(content);
    } catch (e) {
      console.error("Error loading zip:", e);
      return;
    }

    let doc;
    try {
      doc = new Docxtemplater(zip, {
        paragraphLoop: true,
        linebreaks: true,
      });
    } catch (e) {
      console.error("Error creating Docxtemplater instance:", e);
      return;
    }

    doc.setData({
      quotationRefNo: quotationPDFData.quotationRefNo,
      quotationContent: quotationPDFData.quotationContent,
      quotationDate: new Date(
        quotationPDFData.quotationDate
      ).toLocaleDateString(),
      clientName: quotationPDFData.clientUI.clientName,
      clientAddress: quotationPDFData.clientUI.clientAddress,
      kindAttention: quotationPDFData.kindAttention,
      quotationSubject: quotationPDFData.quotationSubject,
      totalAmount: quotationPDFData.totalAmount,
      totalInWord: quotationPDFData.totalInWord,
      state: quotationPDFData.gstDetailsUI.state,
      stateCode: quotationPDFData.gstDetailsUI.stateCode,
      serviceAccountingCode: quotationPDFData.serviceAccountingCode,
      accomodationUIList: quotationPDFData.accomodationUIList.map(
        (item, index) => ({
          index: index + 1,
          description: item.description,
          costPerResources: item.costPerResources,
          noOfResources: item.noOfResources,
          noOfMonth: item.noOfMonth,
          totalCost: item.totalCost,
        })
      ),
      quotationTermAndConditionsUI:
        quotationPDFData.quotationTermAndConditionsUI.map((item, index) => ({
          termSequenceId: item.termSequenceId,
          termCondition: item.termCondition,
        })),
    });

    try {
      doc.render();
    } catch (error) {
      console.error("Error rendering document:", error);
      return;
    }

    const out = doc.getZip().generate({
      type: "blob",
      mimeType:
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    });
    const fileName = formatFileName(
      quotationPDFData.quotationSubject,
      new Date()
    );

    saveAs(out, fileName);
    setDocBlob(out);
  };
  const fetchQuotationData = () => {
    setLoading(true);
    const limit = rowsPerPage;
    const offset = page * rowsPerPage;
    myAxiosCommonHost
      .get(
        `gst/quotation/getQuotationListFinancialYearWise?selectCompany=${selectedCompany}&financialYear=${selectedFinancialYear}&limit=${limit}&offset=${offset}`
      )
      .then((response) => {
        setQuotationData(response.data);
        fetchQuotationCount();
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching Quotation data:", error);
        setLoading(false);
      });
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  // const handleFinancialYearSelect = (financialYear) => {
  //   setSelectedFinancialYear(financialYear);
  //   console.log("Selected Financial Year:", financialYear);
  // };
  // const handleCompanySelect = (company) => {
  //   setSelectedCompany(company);
  //   console.log("Selected Company:", company);
  // };
  const handleSearch = async () => {
    try {
      setLoading(true);
      const limit = rowsPerPage;
      const offset = page * rowsPerPage;
      const encodedQuotationRefNo = encodeURIComponent(searchQuotationRefNo);
      const response = await myAxiosCommonHost.get(
        `gst/quotation/searchQuotation?clientName=${searchClientName}&quotationNo=${searchQuotationNo}&quotationRefNo=${encodedQuotationRefNo}&financialYear=${selectedFinancialYear}&limit=${limit}&offset=${offset}`
      );
      setQuotationData(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error searching employeePo:", error);
      setError(error);
      setLoading(false);
    }
  };
  return (
    <div>
      {isDeleting && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 9999,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ textAlign: "center", color: "white" }}>
            <CircularProgress style={{ marginBottom: "10px" }} />
            <Typography variant="h6">Quotation is Deleting...</Typography>
          </div>
        </div>
      )}
      {isUpdating && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 9999,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ textAlign: "center", color: "white" }}>
            <CircularProgress style={{ marginBottom: "10px" }} />
            <Typography variant="h6">Quotation is Updating...</Typography>
          </div>
        </div>
      )}
      {/* {pDFloading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 9999,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ textAlign: "center", color: "white" }}>
            <CircularProgress style={{ marginBottom: "10px" }} />
            <Typography variant="h6">Quotation is Downloading...</Typography>
          </div>
        </div>
      )} */}
      <Paper
        elevation={3}
        style={{ padding: 20, marginBottom: 20, marginTop: 20 }}
      >
        <Link to="/generateQuotationPage">
          <Button
            variant="contained"
            color="primary"
            style={{ margin: "10px" }}
          >
            Generate Quotation
          </Button>
        </Link>
      </Paper>
      <Button
        variant="contained"
        // color="secondary"
        style={{ backgroundColor: "green", margin: "10px" }}
        onClick={downloadExcel}
        disabled={loading}
      >
        Quotation Excel
      </Button>
      <Button
        variant="contained"
        //color="secondary"
        style={{ backgroundColor: "gray", margin: "10px" }}
      >
        Quotation Count {totalCount}
      </Button>
      <Paper
        elevation={3}
        style={{ padding: 20, marginBottom: 20, marginTop: 20 }}
      >
        <Grid item xs={8}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <TextField
              label="Client Name"
              value={searchClientName}
              onChange={(e) => setSearchClientName(e.target.value)}
              variant="standard"
              style={{ marginRight: "10px" }}
            />
            <TextField
              label="Quotation No"
              value={searchQuotationNo}
              onChange={(e) => setSearchQuotationNo(e.target.value)}
              variant="standard"
              style={{ marginRight: "10px" }}
            />
            <TextField
              label="Quotation Ref No"
              value={searchQuotationRefNo}
              onChange={(e) => setSearchQuotationRefNo(e.target.value)}
              variant="standard"
              style={{ marginRight: "10px" }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleSearch}
              style={{
                marginRight: "10px",
                marginLeft: "10px",
                marginTop: "10px",
              }}
            >
              Search
            </Button>
          </Box>
        </Grid>
      </Paper>
      <Paper
        elevation={3}
        style={{ padding: 20, marginBottom: 20, marginTop: 20 }}
      >
        <Grid container spacing={2}>
          <Grid item xs={2} md={4}>
            {/* <Autocomplete
              id="financial-year"
              sx={{ width: 200 }}
              options={financialYear}
              loading={loading}
              value={selectedFinancialYear}
              onChange={(event, newValue) =>
                handleFinancialYearSelect(newValue)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Financial Year"
                  variant="standard"
                  style={{ backgroundColor: "white", marginTop: "20px" }}
                />
              )}
            /> */}
            <TextField
              label="Financial Year"
              value={selectedFinancialYear}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              disabled
              variant="standard"
              style={{ backgroundColor: "white", marginTop: "20px" }}
            />
          </Grid>
          <Grid item xs={2} md={4}>
            <TextField
              label="Select Company"
              value={selectedCompany}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              disabled
              variant="standard"
              style={{ backgroundColor: "white", marginTop: "20px" }}
            />
            {/* <Autocomplete
              id="company"
              sx={{ width: 200 }}
              options={company}
              loading={loading}
              value={selectedCompany}
              onChange={(event, newValue) => handleCompanySelect(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Company"
                  variant="standard"
                  style={{ backgroundColor: "white", marginTop: "20px" }}
                />
              )}
            /> */}
          </Grid>
        </Grid>
      </Paper>
      <Paper
        elevation={3}
        style={{
          padding: 20,
          marginBottom: 20,
          marginTop: 20,
          backgroundColor: "#2196f3",
          color: "white",
          padding: "10px",
          textAlign: "center",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography
            variant="h5"
            gutterBottom
            style={{ marginBottom: "15px", color: "darkblue" }}
          >
            Quotation List
          </Typography>
        </div>
      </Paper>

      {error ? (
        <p>Error fetching data. Please try again.</p>
      ) : (
        <TableContainer component={Paper} style={{ height: "20%" }}>
          {loading ? (
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={loading}
            >
              <CircularProgress color="inherit" />
              <Typography
                variant="h6"
                color="inherit"
                style={{ marginLeft: "10px" }}
              >
                Getting Data...
              </Typography>
            </Backdrop>
          ) : (
            <>
              <Table>
                <TableHead>
                  <TableRow
                    style={{
                      backgroundColor: "darkcyan",
                      fontWeight: "bold",
                      color: "darkblue",
                    }}
                  >
                    <TableCell />
                    <TableCell>Select Company</TableCell>
                    <TableCell>Quotation Ref Number</TableCell>
                    <TableCell>Quotation Number</TableCell>
                    <TableCell>Client Name And Code</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {quotationData.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={5} align="center">
                        No Quotation found
                      </TableCell>
                    </TableRow>
                  ) : (
                    quotationData.map((quotation) => (
                      <Row
                        key={quotation.quotationNo}
                        row={quotation}
                        onDelete={handleDeleteQuotation}
                        onUpdate={handleUpdateQuotation}
                        onDownloadPdf={handleDownloadPDF}
                      />
                    ))
                  )}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[5, 10, 15]}
                component="div"
                count={totalCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage} // Make sure this line is correct
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </>
          )}
        </TableContainer>
      )}
    </div>
  );
};

export default UpdateQuotation;
