import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Collapse,
  Grid,
  TablePagination,
  CircularProgress,
  Backdrop,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Box, IconButton } from "@mui/material";
import { myAxiosCommonHost } from "../../Service/CorsHelperBaseUrl";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import "./InvoiceList.css";
import * as XLSX from "xlsx";
import { Autocomplete } from "@mui/material";
import GsquareLOGO from "../../Component/Invoice/GsquareLOGO.jpg";
import Bgd_LOGO from "../../Component/Invoice/Bgd_LOGO.jpeg";
import { CompanyContext } from "../StateProvider/CompanyProvider";

const OtherChargesInvoiceList = () => {
  const getCurrentFinancialYear = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const fiscalStartYear = currentYear - (currentDate.getMonth() >= 3 ? 0 : 1);
    const fiscalEndYear = fiscalStartYear + 1;
    return `${(fiscalStartYear % 100).toString().padStart(2, "0")}-${(
      fiscalEndYear % 100
    )
      .toString()
      .padStart(2, "0")}`;
  };
  const defaultFinancialYear = getCurrentFinancialYear();
  // const [selectedFinancialYear, setSelectedFinancialYear] =
  //   useState(defaultFinancialYear); // Set the default selected financial year
  const [invoiceHeaderDetails, setInvoiceHeaderDetails] = useState({
    selectCompany: "",
    financialYear: "",
    invoiceNo: "",
    invoiceRefNo: "",
    invoiceDate: "",
    invoiceType: "",
    clientType: "",
    paymentYear: "",
    paymentMonth: "",
    clientNameAndCode: "",
    sacHsn: "",
    uniqueNoPoNo: "",
    buyersPoNumber: "",
    poNumber: "",
    issueDate: "",
    natureofSupply: "",
    invoiceRemarks: "",
    invoiceTitle: "",
    periodofSupply: "",
  });

  const [invoiceCalculation, setInvoiceCalculation] = useState({
    totalPresentyAmount: 0,
    cgst: 0,
    igst: 0,
    sgst: 0,
    grandTotal: 0,
    totalTaxAmt: 0,
    deductionAmount: 0,
    deductionParticular: "",
    totalInWord: "",
  });
  const [expandedRow, setExpandedRow] = useState(null);
  const [data, setData] = useState([]);
  const [editedInvoiceData, setEditedInvoiceData] = useState(null);
  const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
  const [fetchError, setFetchError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [hasChanges, setHasChanges] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTermClientName, setSearchTermClientName] = useState("");
  const [page, setPage] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isPdfDownloading, setIsPdfDownloading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false); // State to control update overlay
  const [invoiceRemarksOptions, setInvoiceRemarksOptions] = useState([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [password, setPassword] = useState("");
  const [selectedInvoiceRefNo, setSelectedInvoiceRefNo] = useState("");
  const [passwordVerified, setPasswordVerified] = useState(false);
  const [totalCount, setTotalCount] = useState(0); // Initialize total count state
  const [financialYear, setFinancialYear] = useState([]);
  const [searchInvoiceRefNo, setSearchInvoiceRefNo] = useState("");
  const [searchFinancialYear, setSearchFinancialYear] = useState("");
  const [searchClientName, setSearchClientName] = useState("");
  const [searchTotalCount, setSearchTotalCount] = useState(0); // Initialize total count state
  //const [selectedCompany, setSelectedCompany] = useState("");
  const { selectedCompany } = useContext(CompanyContext);
  const { selectedFinancialYear } = useContext(CompanyContext);
  const [company, setCompany] = useState([]);
  const [companyProfile, setCompanyProfile] = useState({});
  const [clientDetails, setClientDetails] = useState({});
  const [gstDetails, setGstDetails] = useState({});
  const [selectedOption, setSelectedOption] = useState("1");
  // useEffect(() => {
  //   async function fetchCompany() {
  //     try {
  //       const response = await myAxiosCommonHost.get(
  //         "gst/profile/getCompanyCodeList"
  //       );
  //       const companies = response.data;
  //       setCompany(companies);
  //       if (companies.length > 0) {
  //         setSelectedCompany(companies[0]); // Set the default company to the first one
  //       }
  //     } catch (error) {
  //       console.error("Error fetching company data:", error);
  //     }
  //   }
  //   fetchCompany();
  // }, []);
  // useEffect(() => {
  //   async function fetchFinancialYear() {
  //     try {
  //       const response = await myAxiosCommonHost.get(
  //         "gst/invoice/getFiscalYear"
  //       );
  //       setFinancialYear(response.data);
  //     } catch (error) {
  //       console.error("Error fetching financial year data:", error);
  //     }
  //   }

  //   fetchFinancialYear();
  // }, []);
  // const handleFinancialYearSelect = (financialYear) => {
  //   setSelectedFinancialYear(financialYear);
  //   console.log("Selected Financial Year:", financialYear);
  // };
  // const handleCompanySelect = (company) => {
  //   setSelectedCompany(company);
  //   console.log("Selected Company:", company);
  // };
  const fetchInvoiceCount = () => {
    myAxiosCommonHost
      .get("gst/invoice/getCountAsPerInvoice?filterType=OTHERCHARGESINVOICE")
      .then((response) => {
        const count = parseInt(response.data); // Extract count from API response
        setTotalCount(count); // Set total count state
      })
      .catch((error) => {
        console.error("Error fetching employee count:", error);
      });
  };
  const fetchInvoiceSearchCount = () => {
    myAxiosCommonHost
      .get(
        `gst/invoice/getSearchCountAsPerInvoice?invoiceRefNo=${searchInvoiceRefNo}&financialYear=${selectedFinancialYear}&clientName=${searchClientName}&filterType=OTHERCHARGESINVOICE`
      )
      .then((response) => {
        const count = parseInt(response.data); // Extract count from API response
        setSearchTotalCount(count); // Set total count state
      })
      .catch((error) => {
        console.error("Error fetching employee count:", error);
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const limit = rowsPerPage;
        const offset = page * rowsPerPage;
        if (selectedCompany && selectedFinancialYear) {
          const response = await myAxiosCommonHost.get(
            `gst/invoice/getInvoiceList?selectCompany=${selectedCompany}&filterType=OTHERCHARGESINVOICE&financialYear=${selectedFinancialYear}&limit=${limit}&offset=${offset}`
          );

          const result = response.data;
          setData(result);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(error);
        setLoading(false);
      }
    };
    fetchInvoiceCount();
    fetchData();
  }, [selectedFinancialYear, selectedCompany, rowsPerPage, page]);
  const handleUpdateInvoiceClick = (invoiceData) => {
    setEditedInvoiceData({ ...invoiceData });
    setUpdateDialogOpen(true);
  };
  const handleDialogInputChange = (field, value) => {
    if (field === "invoiceTitle") {
      setEditedInvoiceData((prevDetails) => ({
        ...prevDetails,
        invoiceRemarks: "", // Reset invoiceRemarks
      }));
      // Set options for invoiceRemarks based on invoiceTitle value
      if (value === "PROFORMA INVOICE") {
        setInvoiceRemarksOptions([
          "QUOTATION UNDER PROCESS",
          "PR UNDER PROCESS",
          "PO UNDER PROCESS",
        ]);
      } else if (value === "TAX INVOICE") {
        setInvoiceRemarksOptions([
          "Invoice in submission process",
          "Invoice with GSQUARE Representative",
          "Invoice with compliance",
          "Invoice with user",
          "Invoice with account/GRN",
        ]);
      } else {
        // Clear invoiceRemarksOptions if invoiceTitle is neither "PROFORMA INVOICE" nor "TAX INVOICE"
        setInvoiceRemarksOptions([]);
      }
    }
    // Update invoiceHeaderDetails with the selected value
    setEditedInvoiceData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
    setHasChanges(true); // Set the flag when there are changes
  };

  const handleUpdateDialogSave = async () => {
    setIsUpdating(true);
    try {
      console.log("Updated Invoice Data:", editedInvoiceData);
      console.log(JSON.stringify(editedInvoiceData, null, 2));
      const response = await myAxiosCommonHost.put(
        "gst/invoice/updateInvoice?filterType=OTHERCHARGESINVOICE",
        editedInvoiceData
      );
      if (response.status === 200) {
        const limit = rowsPerPage;
        const offset = page * rowsPerPage;
        const updatedDataResponse = await myAxiosCommonHost.get(
          `gst/invoice/getInvoiceList?selectCompany=${selectedCompany}&filterType=OTHERCHARGESINVOICE&financialYear=${selectedFinancialYear}&limit=${limit}&offset=${offset}`
        );
        setData(updatedDataResponse.data);
        setUpdateDialogOpen(false);
      } else {
        alert("Failed to update invoice. Please try again.");
      }
    } catch (error) {
      console.error("Error updating invoice:", error);
      alert("An error occurred while updating the invoice.");
    }
    setIsUpdating(false);
  };

  const handleExpandClick = (index) => {
    setExpandedRow(expandedRow === index ? null : index);
  };

  const handlePaymentModeChange = (field, value) => {
    setEditedInvoiceData((prevData) => ({
      ...prevData,
      invoicePaymentModeUI: {
        ...prevData.invoicePaymentModeUI,
        [field]: value,
      },
    }));
    setHasChanges(true);
  };
  const handleBankDetailsChange = (field, value) => {
    setEditedInvoiceData((prevData) => ({
      ...prevData,
      bankUI: {
        ...prevData.bankUI,
        [field]: value,
      },
    }));
    setHasChanges(true);
  };
  const handleSearch = async () => {
    try {
      setLoading(true);
      const limit = rowsPerPage;
      const offset = page * rowsPerPage;
      const response = await myAxiosCommonHost.get(
        `gst/invoice/searchInvoice?invoiceRefNo=${searchInvoiceRefNo}&financialYear=${searchFinancialYear}&clientName=${searchClientName}&filterType=OTHERCHARGESINVOICE&limit=${limit}&offset=${offset}`
      );
      setData(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error searching invoices:", error);
      setError(error);
      setLoading(false);
    }
    fetchInvoiceSearchCount();
  };

  if (fetchError) {
    return <div>Error fetching data. Please try again later.</div>;
  }

  const handleProjectInputChange = (projectIndex, field, value) => {
    setEditedInvoiceData((prevData) => {
      const updatedProjects = prevData.invoiceOtherChargesDetailsUIList.map(
        (project, index) => {
          if (index === projectIndex) {
            let updatedProject = { ...project, [field]: value };

            if (field === "ctcAmount" || field === "percentageAmount") {
              const { ctcAmount, percentageAmount } = updatedProject;
              if (ctcAmount && percentageAmount) {
                const calculatedChargePerAmount =
                  (parseFloat(ctcAmount) * parseFloat(percentageAmount)) / 100;
                updatedProject.chargePerAmount =
                  calculatedChargePerAmount.toFixed(2);
                updatedProject.description = `${updatedProject.percentageAmount}% of CTC ${updatedProject.ctcAmount}`;
              }
            }

            return updatedProject;
          }
          return project;
        }
      );

      // Assuming chargePerAmount is from the first item in the list
      const chargePerAmount = updatedProjects[projectIndex].chargePerAmount;

      // Update the state with updated projects and totalPresentyAmount
      return {
        ...prevData,
        invoiceOtherChargesDetailsUIList: updatedProjects,
        invoiceGstDetailsUI: {
          ...prevData.invoiceGstDetailsUI,
          totalPresentyAmount: chargePerAmount,
        },
      };
    });
  };

  const handleGstDetailsChange = (key, value) => {
    setEditedInvoiceData((prevData) => ({
      ...prevData,
      invoiceGstDetailsUI: {
        ...prevData.invoiceGstDetailsUI,
        [key]: value,
      },
    }));
  };

  const handleCalculate = () => {
    const { totalPresentyAmount, cGST, sGST, iGST } =
      editedInvoiceData.invoiceGstDetailsUI;

    const totalPresentyAmountParsed = parseFloat(totalPresentyAmount) || 0;
    const cgstParsed = parseFloat(cGST) || 0;
    const sgstParsed = parseFloat(sGST) || 0;
    const igstParsed = parseFloat(iGST) || 0;

    const cgstAmount = (cgstParsed / 100) * totalPresentyAmountParsed;
    const sgstAmount = (sgstParsed / 100) * totalPresentyAmountParsed;
    const igstAmount = (igstParsed / 100) * totalPresentyAmountParsed;
    const totalTaxAmt = cgstAmount + sgstAmount + igstAmount;
    const grandTotalAmount = totalPresentyAmountParsed + totalTaxAmt;

    setEditedInvoiceData((prevData) => ({
      ...prevData,
      invoiceGstDetailsUI: {
        ...prevData.invoiceGstDetailsUI,
        cgstAmount: cgstAmount.toFixed(2),
        sgstAmount: sgstAmount.toFixed(2),
        igstAmount: igstAmount.toFixed(2),
        totalTaxAmt: totalTaxAmt.toFixed(2),
        grandTotalAmount: grandTotalAmount.toFixed(2),
      },
    }));
  };

  const generatePDF = async (
    invoiceData,
    companyProfile,
    clientDetails,
    gstDetails
  ) => {
    const doc = new jsPDF();
    const encompassingRectX = 3;
    const encompassingRectY = 4;
    const encompassingRectWidth = doc.internal.pageSize.width - 6;
    const encompassingRectHeight = doc.internal.pageSize.height - 8;

    doc.setDrawColor(0); // Set border color to black
    doc.setLineWidth(0.4); // Set border width
    doc.rect(
      encompassingRectX,
      encompassingRectY,
      encompassingRectWidth,
      encompassingRectHeight
    );
    let companyInfoText =
      `${companyProfile.companyName}\n` +
      `${companyProfile.companyAddress}\n` +
      `${companyProfile.website}\n` +
      `${companyProfile.eMail}`;

    // Add the company information to the PDF for the current invoice
    doc.setFontSize(10);
    doc.setTextColor(0, 0, 0); // Black color
    doc.setFont("helvetica", "bold"); // Set font to bold
    doc.text(companyInfoText, 15, 30);
    doc.setFont("helvetica", "normal"); // Reset font to normal

    // Continue with the rest of your PDF generation logic...

    const companyInfoContainerX = 14;
    const companyInfoContainerY = 10;
    const companyInfoContainerWidth =
      doc.internal.pageSize.width * 0.55 - companyInfoContainerX - 10;
    const companyInfoContainerHeight = 40;

    // Draw the container first
    doc.setDrawColor(0);
    doc.setLineWidth(0.4);
    doc.setFillColor(255, 255, 255);
    doc.rect(
      companyInfoContainerX,
      companyInfoContainerY,
      companyInfoContainerWidth,
      companyInfoContainerHeight,
      "FD"
    );
    if (invoiceData.selectCompany == "GST") {
      // Add the GsquareLOGO with additional margin from the left border
      doc.addImage(GsquareLOGO, "JPEG", 15, 11, 28, 28); // Change x coordinate (e.g., 25 or 30)
      doc.setFontSize(10);
      doc.setTextColor(0, 0, 0);
      doc.text(companyInfoText, 25 + 20 + 2, 18, {
        // Change margin after GsquareLOGO width (e.g., 2 or 3)
        maxWidth: doc.internal.pageSize.width * 0.55 - 25 - 20 - 15, // Calculate available width minus adjusted logo position and margins
      });
    }
    if (invoiceData.selectCompany == "BGD") {
      // Add the Bgd_LOGO with additional margin from the left border
      doc.addImage(Bgd_LOGO, "JPEG", 15, 11, 28, 28); // Change x coordinate (e.g., 25 or 30)
      doc.setFontSize(10);
      doc.setTextColor(0, 0, 0);
      doc.text(companyInfoText, 25 + 20 + 2, 18, {
        // Change margin after Bgd_LOGO width (e.g., 2 or 3)
        maxWidth: doc.internal.pageSize.width * 0.55 - 25 - 20 - 15, // Calculate available width minus adjusted logo position and margins
      });
    }

    const title = invoiceData.invoiceType;
    const titleHeight = 3;
    const titleY = encompassingRectY + 1;
    doc.setFontSize(10);
    doc.text(title, doc.internal.pageSize.width / 2, titleY + titleHeight, {
      align: "center",
    });
    const CompInvo = [
      [
        invoiceData.invoiceType === "Credit Note"
          ? "Credit No: " + invoiceData.invoiceRefNo
          : "Invoice No: " + invoiceData.invoiceRefNo,
      ],
      ["Company's GST NO.: " + companyProfile.gstin], // Include GSTIN directly
      ["Company's PAN No.: " + companyProfile.pan], // Include PAN directly
      ["Company's CIN: " + companyProfile.cin], // Include CIN directly
      [
        invoiceData.invoiceType === "Credit Note"
          ? "Credit Note Date: " +
              invoiceData.invoiceDate.split("-").reverse().join("-") || "N/A"
          : "Invoice Date: " +
              invoiceData.invoiceDate.split("-").reverse().join("-") || "N/A",
      ],
    ];

    const tableWidth = doc.internal.pageSize.width * 0.45;
    const leftMargin = doc.internal.pageSize.width - tableWidth - 10;

    // Calculate the height of the CompInvo table
    const compInvoTableHeight = doc.autoTable({
      body: CompInvo,
      theme: "grid",
      margin: { top: 10, left: leftMargin, right: 14, bottom: 0 },
      styles: {
        fontSize: 10,
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        cellPadding: 2,
        marginBottom: 0,
        marginTop: 0,
        padding: 0,
      },
    }).lastAutoTable.finalY;

    const ClientInvo = [
      ["Service ACC Code: " + gstDetails.sacHsn],
      ["Client's GSTIN: " + gstDetails.gstin],
      ["Payment Term: 15 Days Bank Transfer"], // No need to concatenate, as it's a single value
      ["Nature Of Supply: " + invoiceData.natureofSupply],
      [" Period of Supply: " + invoiceData.periodofSupply], // No need to concatenate, as it's a single value
    ];
    // Set the startY parameter for ClientInvo table to start immediately after CompInvo table
    doc.autoTable({
      body: ClientInvo,
      theme: "grid",
      columnStyles: { 0: { cellWidth: "auto" } },
      startY: compInvoTableHeight, // Set startY explicitly
      margin: {
        top: compInvoTableHeight,
        left: leftMargin,
        right: 14,
        bottom: 0,
      },
      styles: {
        fontSize: 10,
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        marginBottom: 0,
        tableHeight: 40,
      },
    });

    const billedToContainerHeight = 38.85;
    const clientAddressLines = clientDetails.clientAddress || ""; // Split the address into multiple lines
    const billedToTextLines = [
      `Billed To: ${clientDetails.clientName || ""} `,
      ...clientAddressLines.split("\n"), // Spread the address lines into the array
      "",
      `Place of supply: ${gstDetails.state || ""} State Code: ${
        gstDetails.stateCode || ""
      }`,
      `Ph: ${clientDetails.clientContactNumber || ""}`,
      `Kind Attn: ${invoiceData.invoicePaymentModeUI.kindAttention || ""} `,
    ];

    const billedToContainerX = companyInfoContainerX;
    const topMargin = 0;
    const containerHeight = 40; // Set the common height for both the billedToContainer and the table
    const billedToContainerY =
      companyInfoContainerY + companyInfoContainerHeight + topMargin;
    const billedToContainerWidth = companyInfoContainerWidth;

    doc.setDrawColor(0);
    doc.setLineWidth(0.4);
    doc.setFillColor(255, 255, 255);
    doc.rect(
      billedToContainerX,
      billedToContainerY,
      billedToContainerWidth,
      containerHeight,
      "FD"
    );

    doc.setFontSize(10);
    doc.setTextColor(0, 0, 0);

    const lineHeight = 6; // Adjusted line height
    let yPosition = billedToContainerY + 5;

    billedToTextLines.forEach((line) => {
      doc.text(line.trim(), billedToContainerX + 5, yPosition, {
        maxWidth: billedToContainerWidth - 10,
      });
      yPosition += lineHeight;
    });
    doc.setFillColor(255, 255, 255);

    const tableData = invoiceData.invoiceOtherChargesDetailsUIList.map(
      (row) => [
        row.employeeNameAndCode, // replace with the actual property names from otherChargesList
        row.employeeRole,
        row.description,
        row.chargePerAmount,
      ]
    );

    doc.autoTable({
      head: [["Employee Name", "Employee Role", "Description", "Amount"]],
      body: tableData,
      startY: billedToContainerY + billedToContainerHeight + 0,
      theme: "grid",
      styles: {
        fontSize: 9,
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        marginBottom: 0,
      },
      columnStyles: {
        3: { columnWidth: 35, halign: "right" },
      },
    });
    // Calculate the height of the first table (invoice data table)
    const firstTableHeight = doc.previousAutoTable
      ? doc.previousAutoTable.finalY
      : 0;

    const { totalPresentyAmount, grandTotalAmount, totalTaxAmt, totalInWord } =
      invoiceData.invoiceGstDetailsUI;
    const { cgst, sgst, igst } = invoiceData.invoiceGstDetailsUI;

    const InvoiceData = [
      ["A] Total", totalPresentyAmount],
      ["B] CGST (1)", ` ${(cgst / 100) * totalPresentyAmount}`],
      ["C] SGST (2)", ` ${(sgst / 100) * totalPresentyAmount}`],
      ["D] IGST (3)", ` ${(igst / 100) * totalPresentyAmount}`],
      ["E] Total (A+B+C+D)", grandTotalAmount],
      ["F] Deduction ", 0],
      ["G] Grand Total (F-G)", grandTotalAmount],
      [{ content: " Total In Words: " + totalInWord, colSpan: 2 }],
    ];

    // Set startY for the second table immediately after the first table
    doc.autoTable({
      body: InvoiceData,
      startY: firstTableHeight + 0,
      theme: "grid",
      styles: {
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        marginBottom: 0,
      },
      columnStyles: {
        0: { columnWidth: 147 },
        1: { columnWidth: 35, halign: "right" },
      },
    });

    // Calculate the height of the second table (invoice data table)
    const secondTableHeight = doc.previousAutoTable
      ? doc.previousAutoTable.finalY
      : 0;

    const remainingSpace =
      doc.internal.pageSize.getHeight() - secondTableHeight - 20;

    // Check if there is enough space for the bank table
    if (remainingSpace < 0) {
      doc.addPage();
    }

    const bankDataTable = [
      [`Bank Name: ${invoiceData.bankUI.bankName}`],
      [`Bank Address: ${invoiceData.bankUI.bankAddress}`],
      [`A/C No.: ${invoiceData.bankUI.accountNumber}`],
      [`IFC Code: ${invoiceData.bankUI.ifsc}`],
      [`Swift Code: ${invoiceData.bankUI.swiftCode}`],
    ];

    const tableWidth2 = doc.internal.pageSize.width * 0.4;

    // Set startY for the bank table immediately after the first table
    doc.autoTable({
      body: bankDataTable,
      startY: secondTableHeight + 0,
      theme: "grid",
      styles: {
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        marginBottom: 0,
      },
      columnStyles: { 0: { cellWidth: tableWidth2, cellHeight: 10 } },
    });

    const rectWidth = 97;
    const rectHeight = 42.5;

    // Set rectX1 to start from the right edge of the bank table
    const rectX1 = doc.internal.pageSize.width * 0.47;

    const rectY1 = secondTableHeight + 0; // Adjust the spacing as needed

    doc.setDrawColor(0); // Set border color to black
    doc.rect(rectX1, rectY1, rectWidth, rectHeight).setLineWidth(0.5);

    const line1Text = "For Gsquare Techsystem PVT LTD";
    const line2Text = "Authorized Signatory & Seal";
    const textX = rectX1 + 25;
    const textY1 = rectY1 + 8;
    const textY2 = rectY1 + 30;
    doc.text(line1Text, textX - 5, textY1);
    doc.text(line2Text, textX, textY2);

    const filename = `Invoice_${invoiceHeaderDetails.invoiceRefNo}.pdf`;
    doc.save(filename);

    const pdfData = doc.output("blob"); // Assuming doc.output provides Blob object
    const pdfWindow = window.open("", "_blank");
    const pdfURL = URL.createObjectURL(pdfData);
    pdfWindow.location.href = pdfURL;
  };

  const handlegeneratePDF = async (invoiceRefNo) => {
    setIsPdfDownloading(true);
    try {
      const response = await myAxiosCommonHost.get(
        `gst/invoice/viewInvoice?invoiceRefNo=${invoiceRefNo}`
      );
      const invoiceData = response.data[0];
      const { companyProfileUI, clientUI, gstdetailsUI } = invoiceData;
      setCompanyProfile(companyProfileUI);
      setClientDetails(clientUI);
      setGstDetails(gstdetailsUI);
      console.log("Company Profile:", companyProfileUI);
      console.log("Client Details:", clientUI);
      console.log("GST Details:", gstdetailsUI);

      generatePDF(invoiceData, companyProfileUI, clientUI, gstdetailsUI);
      setIsPdfDownloading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsPdfDownloading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleDeleteClick = async (invoiceRefNo) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this record?"
    );
    if (confirmDelete) {
      setIsDeleting(true);
      await handleDeleteConfirmed(invoiceRefNo);
      setIsDeleting(false);
    }
  };

  const handleDeleteConfirmed = async (invoiceRefNo) => {
    setSelectedInvoiceRefNo(invoiceRefNo);
    setDeleteDialogOpen(true);
  };
  // const handleDeleteConfirm = async () => {
  //   const handleDialogDelete = async () => {
  //     setIsDeleting(true);
  //     try {
  //       const response = await myAxiosCommonHost.get(
  //         `gst/login/verificationForDeletionRecord?userPassword=${password}`
  //       );
  //       if (response.status === 200 && response.data) {
  //         setPasswordVerified(true); // Set password verification status to true
  //         const confirmDelete = window.confirm(
  //           "Password verified. Are You Want to Delete This Record..."
  //         );
  //         // console.log("Password verified. Deleting record...");
  //         if (confirmDelete) {
  //           // Perform deletion logic here
  //           const deleteResponse = await myAxiosCommonHost.delete(
  //             // const encodedinvoiceRefNo = encodeURIComponent(invoiceRefNo);
  //             `gst/invoice/deleteInvoice/invoiceRefNo?invoiceRefNo=${selectedInvoiceRefNo}`
  //           );

  //           if (deleteResponse.status === 200) {
  //             //  console.log("PO deleted successfully!");
  //             console.log("Invoice deleted successfully!");
  //             const updatedDataResponse = await myAxiosCommonHost.get(
  //               // "gst/projectpo/getProjectPoList"
  //               "gst/invoice/getInvoiceList?filterType=OTHERCHARGESINVOICE"
  //             );
  //             if (updatedDataResponse.status === 200) {
  //               const updatedResult = await updatedDataResponse.data;
  //               setData(updatedResult);
  //               // setEditedInvoiceData(updatedResult);
  //             } else {
  //               console.error("Failed to fetch updated data.");
  //             }
  //             setDeleteDialogOpen(false);
  //             setPassword("");
  //           } else {
  //             console.error("Failed to delete PO.");
  //             alert("Failed to delete PO. Please try again.");
  //           }
  //         } else {
  //           setPasswordVerified(false); // Set password verification status to false
  //           console.error("Failed to verify password.");
  //           alert("Incorrect password. Deletion canceled.");
  //         }
  //       }
  //     } catch (error) {
  //       console.error("Error deleting PO:", error);
  //       alert("An error occurred while deleting the PO.");
  //     }
  //     setIsDeleting(false);
  //     // setDeleteDialogOpen(false);
  //   };
  //   const handleDialogDelete = async () => {
  //     setIsDeleting(true);
  //     try {
  //       const response = await myAxiosCommonHost.get(
  //         `gst/login/verificationForDeletionRecord?userPassword=${password}`
  //       );
  //       if (response.status === 200 && response.data) {
  //         setPasswordVerified(true); // Set password verification status to true
  //         const confirmDelete = window.confirm(
  //           "Password verified. Are You Want to Delete This Record..."
  //         );
  //         if (confirmDelete) {
  //           const deleteResponse = await myAxiosCommonHost.delete(
  //             `gst/invoice/deleteInvoice/invoiceRefNo?invoiceRefNo=${selectedInvoiceRefNo}`
  //           );

  //           if (deleteResponse.status === 200) {
  //             console.log("Invoice deleted successfully!");
  //             const updatedDataResponse = await myAxiosCommonHost.get(
  //               `gst/invoice/getInvoiceList?filterType=OTHERCHARGESINVOICE`
  //             );
  //             if (updatedDataResponse.status === 200) {
  //               const updatedResult = await updatedDataResponse.data;
  //               setData(updatedResult);
  //             } else {
  //               console.error("Failed to fetch updated data.");
  //             }
  //             setDeleteDialogOpen(false);
  //             setPassword("");
  //           } else {
  //             console.error("Failed to delete invoice.");
  //             alert("Failed to delete invoice. Please try again.");
  //           }
  //         } else {
  //           setPasswordVerified(false); // Set password verification status to false
  //           console.error("Failed to verify password.");
  //           alert("Incorrect password. Deletion canceled.");
  //         }
  //       }
  //     } catch (error) {
  //       console.error("Error deleting invoice:", error);
  //       alert("An error occurred while deleting the invoice.");
  //     }
  //     setIsDeleting(false);
  //   };
  const handleDialogDelete = async () => {
    setIsDeleting(true);
    try {
      const response = await myAxiosCommonHost.get(
        `gst/login/verificationForDeletionRecord?userPassword=${password}`
      );
      if (response.status === 200 && response.data) {
        setPasswordVerified(true); // Set password verification status to true
        const confirmDelete = window.confirm(
          "Password verified. Are you sure you want to delete this record?"
        );
        if (confirmDelete) {
          // Perform deletion logic here
          const deleteResponse = await myAxiosCommonHost.delete(
            `gst/invoice/deleteInvoice/invoiceRefNo?invoiceRefNo=${selectedInvoiceRefNo}`
          );

          if (deleteResponse.status === 200) {
            console.log("Invoice deleted successfully!");
            const limit = rowsPerPage;
            const offset = page * rowsPerPage;
            const updatedDataResponse = await myAxiosCommonHost.get(
              `gst/invoice/getInvoiceList?selectCompany=${selectedCompany}&filterType=OTHERCHARGESINVOICE&financialYear=${selectedFinancialYear}&limit=${limit}&offset=${offset}`
              // `gst/invoice/getInvoiceList?filterType=OTHERCHARGESINVOICE`
            );
            if (updatedDataResponse.status === 200) {
              const updatedResult = await updatedDataResponse.data;
              setData(updatedResult);
              //  setEditedData(updatedResult);
            } else {
              console.error("Failed to fetch updated data.");
            }
            setDeleteDialogOpen(false);
            setPassword(""); // Reset password field
          } else {
            console.error("Failed to delete PO.");
            alert("Failed to delete PO. Please try again.");
          }
        }
      } else {
        setPasswordVerified(false); // Set password verification status to false
        console.error("Failed to verify password.");
        alert("Incorrect password. Deletion canceled.");
      }
    } catch (error) {
      console.error("Error deleting Invoice:", error);
      alert("An error occurred while deleting the Invoice.");
    }
    setIsDeleting(false);
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const downloadExcelFile = async () => {
    try {
      let apiUrl = `/gst/invoice/getAllInvoiceListForExcel`;
      if (selectedOption === "1") {
        apiUrl += `?excelFilterType=all&filterType&financialYear&selectCompany=${selectedCompany}`;
      } else if (selectedOption === "2") {
        apiUrl += `?excelFilterType=all&filterType=``&financialYear=${selectedFinancialYear}&selectCompany=${selectedCompany}`;
      } else if (selectedOption === "3") {
        apiUrl += `?excelFilterType&filterType=OTHERCHARGESINVOICE&financialYear&selectCompany=${selectedCompany}`;
      } else if (selectedOption === "4") {
        apiUrl += `?excelFilterType&filterType=OTHERCHARGESINVOICE&financialYear=${selectedFinancialYear}&selectCompany=${selectedCompany}`;
      }

      const response = await myAxiosCommonHost.get(apiUrl);

      const data = response.data;

      if (!data) {
        console.error("Data is not available or empty.");
        return;
      }

      const workbook = XLSX.utils.book_new();

      const invoiceHeaderDetailExcelList =
        data.invoiceHeaderDetailExcelList || [];
      const invoiceEmployeePoExcelList = data.invoiceEmployeePoExcelList || [];
      const invoiceProjectPoExcelList = data.invoiceProjectPoExcelList || [];
      const invoiceOtherChargesExcelList =
        data.invoiceOtherChargesExcelList || [];

      if (invoiceHeaderDetailExcelList.length > 0) {
        const worksheet1 = XLSX.utils.json_to_sheet(
          invoiceHeaderDetailExcelList
        );
        XLSX.utils.book_append_sheet(
          workbook,
          worksheet1,
          "Invoice Header Details"
        );
      }

      if (invoiceEmployeePoExcelList.length > 0) {
        const worksheet2 = XLSX.utils.json_to_sheet(invoiceEmployeePoExcelList);
        XLSX.utils.book_append_sheet(
          workbook,
          worksheet2,
          "Invoice Employee PO"
        );
      }

      if (invoiceProjectPoExcelList.length > 0) {
        const worksheet3 = XLSX.utils.json_to_sheet(invoiceProjectPoExcelList);
        XLSX.utils.book_append_sheet(
          workbook,
          worksheet3,
          "Invoice Project PO"
        );
      }

      if (invoiceOtherChargesExcelList.length > 0) {
        const worksheet4 = XLSX.utils.json_to_sheet(
          invoiceOtherChargesExcelList
        );
        XLSX.utils.book_append_sheet(
          workbook,
          worksheet4,
          "Invoice Other Charges"
        );
      }

      const excelBuffer = XLSX.write(workbook, {
        type: "array",
        bookType: "xlsx",
      });

      const blob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const url = URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.target = "_blank";
      anchor.download = "invoice_data.xlsx";
      anchor.click();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const filteredData =
    data && Array.isArray(data)
      ? data.filter(
          (po) =>
            po.clientNameAndCode
              .toLowerCase()
              .includes(searchTermClientName.toLowerCase()) &&
            po.invoiceNo.toString().includes(searchTerm.toLowerCase())
        )
      : [];
  return (
    <>
      {isDeleting && (
        <div className="blur-effect">
          <CircularProgress color="primary" />
          <div className="loading-message">Invoice is Deleting...</div>
        </div>
      )}
      {isPdfDownloading && (
        <div className="blur-effect">
          <CircularProgress color="primary" />
          <div className="loading-message">Viewing Invoice...</div>
        </div>
      )}
      {isUpdating && (
        <div className="blur-effect">
          <CircularProgress color="primary" />
          <div className="loading-message">Invoice is Updating...</div>
        </div>
      )}
      <Paper
        elevation={3}
        style={{ padding: 20, marginBottom: 20, marginTop: 20 }}
      >
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <Link to="/generateOtherChargesInvoice">
              <Button
                variant="contained"
                color="secondary"
                style={{ marginTop: 20, marginRight: 10, marginBottom: "20px" }}
              >
                Generate Invoice
              </Button>
            </Link>

            <Button
              variant="contained"
              color="secondary"
              style={{ margin: "10px" }}
            >
              Invoice Count {totalCount}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                label="Invoice Ref No"
                value={searchInvoiceRefNo}
                onChange={(e) => setSearchInvoiceRefNo(e.target.value)}
                variant="standard"
                style={{ marginRight: "10px" }}
              />
              <TextField
                label="Client Name"
                value={searchClientName}
                onChange={(e) => setSearchClientName(e.target.value)}
                variant="standard"
                style={{ marginRight: "10px" }}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleSearch}
              >
                Search
              </Button>
            </Box>
            <Button
              variant="contained"
              color="secondary"
              style={{ margin: "10px" }}
            >
              Search Data Count {searchTotalCount}
            </Button>
          </Grid>
        </Grid>
      </Paper>
      <Paper
        elevation={3}
        style={{ padding: 20, marginBottom: 20, marginTop: 20 }}
      >
        <Grid container spacing={2}>
          <Grid item xs={2} md={4}>
            {/* <Autocomplete
              id="financial-year"
              sx={{ width: 200 }}
              options={financialYear}
              loading={loading}
              value={selectedFinancialYear}
              onChange={(event, newValue) =>
                handleFinancialYearSelect(newValue)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Financial Year"
                  variant="standard"
                  style={{ backgroundColor: "white", marginTop: "20px" }}
                />
              )}
            /> */}
            <TextField
              label="Financial Year"
              value={selectedFinancialYear}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              disabled
              variant="standard"
              style={{ backgroundColor: "white", marginTop: "20px" }}
            />
          </Grid>
          <Grid item xs={2} md={4}>
            {/* <Autocomplete
              id="company"
              sx={{ width: 200 }}
              options={company}
              loading={loading}
              value={selectedCompany}
              onChange={(event, newValue) => handleCompanySelect(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Company"
                  variant="standard"
                  style={{ backgroundColor: "white", marginTop: "20px" }}
                />
              )}
            /> */}
            <TextField
              label="Select Company"
              value={selectedCompany}
              InputLabelProps={{
                shrink: true,
              }}
              disabled
              variant="standard"
              style={{ backgroundColor: "white", marginTop: "20px" }}
            />
          </Grid>
          <Box my={4} display="flex" alignItems="center">
            <TextField
              select
              label="Download Options"
              value={selectedOption}
              onChange={handleOptionChange}
              variant="standard"
              style={{ minWidth: 200, marginRight: 16 }}
            >
              <MenuItem value="1">Download All Invoice</MenuItem>
              <MenuItem value="2">
                Download all invoice financial year wise
              </MenuItem>
              <MenuItem value="3">Download Other Charges invoice</MenuItem>
              <MenuItem value="4">
                Download financial year wise invoice
              </MenuItem>
            </TextField>

            <Button
              variant="contained"
              style={{ backgroundColor: "green", color: "white" }}
              onClick={downloadExcelFile}
            >
              DOWNLOAD EXCEL
            </Button>
          </Box>
        </Grid>
      </Paper>
      <Paper
        elevation={3}
        style={{
          padding: 20,
          marginBottom: 20,
          backgroundColor: "#2196f3",
          color: "white",
          padding: "10px",
          textAlign: "center",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography
            variant="h5"
            gutterBottom
            style={{ marginBottom: "15px", color: "darkblue" }}
          >
            Other Charges Invoice
          </Typography>
        </div>
      </Paper>
      {error ? (
        <p>No Invoice Reocrd Found ,Generete A New Invoice.</p>
      ) : (
        <TableContainer
          component={Paper}
          style={{ height: "20%", marginTop: "20px" }}
        >
          {/* {loading ? (
            <p>Loading...</p>
          ) : ( */}
          {loading ? (
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              style={{ marginLeft: "10px", marginTop: "50px" }}
              open={loading}
            >
              <CircularProgress color="inherit" />
              <Typography
                variant="h6"
                color="inherit"
                style={{ marginLeft: "10px", marginTop: "20px" }}
              >
                Getting Data...
              </Typography>
            </Backdrop>
          ) : (
            <>
              <Grid item xs={12}>
                <Paper>
                  <Table>
                    <TableHead>
                      <TableRow
                        style={{
                          backgroundColor: "darkcyan",
                          fontWeight: "bold",
                          color: "darkblue",
                        }}
                      >
                        <TableCell />
                        <TableCell>Invoice Ref</TableCell>
                        <TableCell>Client Name</TableCell>
                        {/* <TableCell>Po Number</TableCell> */}
                        <TableCell>Invoice No</TableCell>
                        <TableCell></TableCell>
                        <TableCell>Action</TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.length === 0 ? (
                        <TableRow>
                          <TableCell colSpan={8} align="center">
                            No Other Charges Invoice found
                          </TableCell>
                        </TableRow>
                      ) : (
                        data.map((invoice, index) => (
                          <React.Fragment key={index}>
                            <TableRow>
                              <TableCell>
                                <IconButton
                                  onClick={() => handleExpandClick(index)}
                                  aria-expanded={expandedRow === index}
                                  aria-label="show more"
                                >
                                  <ExpandMoreIcon />
                                </IconButton>
                              </TableCell>
                              <TableCell>{invoice.invoiceRefNo}</TableCell>
                              <TableCell>{invoice.clientNameAndCode}</TableCell>
                              {/* <TableCell>{invoice.poNumber}</TableCell> */}
                              <TableCell>{invoice.invoiceNo}</TableCell>
                              <TableCell>
                                <Button
                                  variant="contained"
                                  onClick={() =>
                                    handlegeneratePDF(invoice.invoiceRefNo)
                                  } // Pass a function reference here
                                  color="secondary"
                                >
                                  VIEW
                                </Button>
                              </TableCell>
                              <TableCell>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={() =>
                                    handleUpdateInvoiceClick(invoice)
                                  }
                                >
                                  Update Invoice
                                </Button>
                              </TableCell>
                              <TableCell>
                                <Button
                                  variant="contained"
                                  color="secondary"
                                  onClick={() =>
                                    handleDeleteClick(invoice.invoiceRefNo)
                                  }
                                >
                                  Delete Invoice
                                </Button>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell colSpan={5}>
                                <Collapse
                                  in={expandedRow === index}
                                  timeout="auto"
                                  unmountOnExit
                                >
                                  {(
                                    invoice.invoiceOtherChargesDetailsUIList ||
                                    []
                                  ).map((proInvoice, empIndex) => (
                                    <Accordion key={empIndex}>
                                      <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls={`panel${index}-content`}
                                        id={`panel${index}-header`}
                                        IconButtonProps={{ edge: "start" }}
                                      >
                                        <Typography>
                                          {"Employee Name -:"}
                                          {
                                            invoice
                                              .invoiceOtherChargesDetailsUIList[
                                              empIndex
                                            ].employeeName
                                          }
                                        </Typography>
                                      </AccordionSummary>
                                      <AccordionDetails>
                                        <Table style={{ width: "100%" }}>
                                          <TableBody>
                                            <TableRow
                                              style={{
                                                backgroundColor: "goldenrod",
                                                fontWeight: "bold",
                                                color: "darkblue",
                                              }}
                                            >
                                              <TableCell>
                                                Emplyoyee Name
                                              </TableCell>
                                              <TableCell>
                                                Emplyoyee Code
                                              </TableCell>
                                              <TableCell>
                                                Employee Role
                                              </TableCell>
                                              <TableCell>Select Item</TableCell>
                                              <TableCell></TableCell>
                                            </TableRow>
                                            {proInvoice ? (
                                              <TableRow>
                                                <TableCell>
                                                  {proInvoice.employeeName}
                                                </TableCell>
                                                <TableCell>
                                                  {proInvoice.employeeCode}
                                                </TableCell>
                                                <TableCell>
                                                  {proInvoice.employeeRole}
                                                </TableCell>
                                                <TableCell>
                                                  {proInvoice.selectItem}
                                                </TableCell>
                                              </TableRow>
                                            ) : (
                                              <TableRow>
                                                <TableCell colSpan={3}>
                                                  No Invoice Details Available
                                                </TableCell>
                                              </TableRow>
                                            )}
                                            <TableRow>
                                              <TableCell colSpan={6}>
                                                <Collapse
                                                  in={expandedRow === index}
                                                  timeout="auto"
                                                  unmountOnExit
                                                >
                                                  <Box style={{ padding: 20 }}>
                                                    <Typography variant="subtitle1">
                                                      Employee Details
                                                    </Typography>
                                                    <TableRow
                                                      style={{
                                                        backgroundColor:
                                                          "darkcyan",
                                                        fontWeight: "bold",
                                                        color: "darkblue",
                                                      }}
                                                    >
                                                      <TableCell>
                                                        Emplyoyee Name
                                                      </TableCell>
                                                      <TableCell>
                                                        Emplyoyee Code
                                                      </TableCell>
                                                      <TableCell>
                                                        Employee Role
                                                      </TableCell>
                                                      <TableCell>
                                                        Select Item
                                                      </TableCell>
                                                      <TableCell>
                                                        Description
                                                      </TableCell>
                                                      <TableCell>
                                                        Charge Per Amount
                                                      </TableCell>
                                                      <TableCell>
                                                        CTC Amount
                                                      </TableCell>
                                                      <TableCell>
                                                        Percentage Amount
                                                      </TableCell>
                                                    </TableRow>
                                                    {proInvoice ? (
                                                      <TableRow>
                                                        <TableCell>
                                                          {
                                                            proInvoice.employeeName
                                                          }
                                                        </TableCell>
                                                        <TableCell>
                                                          {
                                                            proInvoice.employeeCode
                                                          }
                                                        </TableCell>
                                                        <TableCell>
                                                          {
                                                            proInvoice.employeeRole
                                                          }
                                                        </TableCell>
                                                        <TableCell>
                                                          {
                                                            proInvoice.selectItem
                                                          }
                                                        </TableCell>
                                                        <TableCell>
                                                          {
                                                            proInvoice.description
                                                          }
                                                        </TableCell>
                                                        <TableCell>
                                                          {
                                                            proInvoice.chargePerAmount
                                                          }
                                                        </TableCell>
                                                        <TableCell>
                                                          {proInvoice.ctcAmount}
                                                        </TableCell>
                                                        <TableCell>
                                                          {
                                                            proInvoice.percentageAmount
                                                          }
                                                        </TableCell>
                                                      </TableRow>
                                                    ) : (
                                                      <TableRow>
                                                        <TableCell colSpan={3}>
                                                          No InvoiceDetails
                                                          Available
                                                        </TableCell>
                                                      </TableRow>
                                                    )}

                                                    <Typography variant="subtitle1">
                                                      Invoice Gst Details
                                                    </Typography>
                                                    <TableRow
                                                      style={{
                                                        backgroundColor:
                                                          "darkcyan",
                                                        fontWeight: "bold",
                                                        color: "darkblue",
                                                      }}
                                                    >
                                                      <TableCell>
                                                        Total Presenty Amount
                                                      </TableCell>
                                                      <TableCell>
                                                        Total Tax Amt
                                                      </TableCell>
                                                      <TableCell>
                                                        Grand Total Amount
                                                      </TableCell>
                                                      <TableCell>
                                                        Grand Total
                                                      </TableCell>
                                                      <TableCell>
                                                        Deduction Amount
                                                      </TableCell>
                                                      <TableCell>
                                                        Deduction Particular
                                                      </TableCell>
                                                      <TableCell>
                                                        CGST
                                                      </TableCell>
                                                      <TableCell>
                                                        SGST
                                                      </TableCell>
                                                      <TableCell>
                                                        IGST
                                                      </TableCell>

                                                      <TableCell>
                                                        Total In Word Amount
                                                      </TableCell>
                                                    </TableRow>
                                                    {invoice.invoiceGstDetailsUI ? (
                                                      <TableRow>
                                                        <TableCell>
                                                          {
                                                            invoice
                                                              .invoiceGstDetailsUI
                                                              .totalPresentyAmount
                                                          }
                                                        </TableCell>
                                                        <TableCell>
                                                          {
                                                            invoice
                                                              .invoiceGstDetailsUI
                                                              .totalTaxAmt
                                                          }
                                                        </TableCell>
                                                        <TableCell>
                                                          {
                                                            invoice
                                                              .invoiceGstDetailsUI
                                                              .grandTotalAmount
                                                          }
                                                        </TableCell>
                                                        <TableCell>
                                                          {
                                                            invoice
                                                              .invoiceGstDetailsUI
                                                              .grandTotal
                                                          }
                                                        </TableCell>
                                                        <TableCell>
                                                          {
                                                            invoice
                                                              .invoiceGstDetailsUI
                                                              .deductionAmount
                                                          }
                                                        </TableCell>
                                                        <TableCell>
                                                          {
                                                            invoice
                                                              .invoiceGstDetailsUI
                                                              .deductionParticular
                                                          }
                                                        </TableCell>
                                                        <TableCell>
                                                          {
                                                            invoice
                                                              .invoiceGstDetailsUI
                                                              .cGST
                                                          }
                                                        </TableCell>
                                                        <TableCell>
                                                          {
                                                            invoice
                                                              .invoiceGstDetailsUI
                                                              .sGST
                                                          }
                                                        </TableCell>
                                                        <TableCell>
                                                          {
                                                            invoice
                                                              .invoiceGstDetailsUI
                                                              .iGST
                                                          }
                                                        </TableCell>

                                                        <TableCell>
                                                          {
                                                            invoice
                                                              .invoiceGstDetailsUI
                                                              .totalInWordAmount
                                                          }
                                                        </TableCell>
                                                      </TableRow>
                                                    ) : (
                                                      <TableRow>
                                                        <TableCell colSpan={3}>
                                                          No GST Available
                                                        </TableCell>
                                                      </TableRow>
                                                    )}

                                                    <Typography variant="subtitle1">
                                                      Invoice Payment Mode
                                                    </Typography>
                                                    <TableRow
                                                      style={{
                                                        backgroundColor:
                                                          "darkcyan",
                                                        fontWeight: "bold",
                                                        color: "darkblue",
                                                      }}
                                                    >
                                                      <TableCell>
                                                        Mode Terms Of Payment
                                                      </TableCell>
                                                      <TableCell>
                                                        Kind Attention
                                                      </TableCell>
                                                      <TableCell>
                                                        Select Bank AccNo
                                                      </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                      <TableCell>
                                                        {
                                                          invoice
                                                            .invoicePaymentModeUI
                                                            .modeTermsOfPayment
                                                        }
                                                      </TableCell>
                                                      <TableCell>
                                                        {
                                                          invoice
                                                            .invoicePaymentModeUI
                                                            .kindAttention
                                                        }
                                                      </TableCell>
                                                      <TableCell>
                                                        {
                                                          invoice
                                                            .invoicePaymentModeUI
                                                            .selectBankAccNo
                                                        }
                                                      </TableCell>
                                                    </TableRow>

                                                    <Typography variant="subtitle1">
                                                      Bank Details
                                                    </Typography>
                                                    <TableRow
                                                      style={{
                                                        backgroundColor:
                                                          "darkcyan",
                                                        fontWeight: "bold",
                                                        color: "darkblue",
                                                      }}
                                                    >
                                                      <TableCell>
                                                        Account Number
                                                      </TableCell>
                                                      <TableCell>
                                                        Bank Name
                                                      </TableCell>
                                                      <TableCell>
                                                        Account Type
                                                      </TableCell>
                                                      <TableCell>
                                                        Branch Name
                                                      </TableCell>
                                                      <TableCell>
                                                        Account Number
                                                      </TableCell>
                                                      <TableCell>
                                                        IFSC
                                                      </TableCell>
                                                      <TableCell>
                                                        MICR
                                                      </TableCell>
                                                      <TableCell>
                                                        Bank Address
                                                      </TableCell>
                                                      <TableCell>
                                                        Swift Code
                                                      </TableCell>
                                                    </TableRow>
                                                    {invoice.bankUI ? (
                                                      <TableRow>
                                                        <TableCell>
                                                          {
                                                            invoice.bankUI
                                                              .accountNumber
                                                          }
                                                        </TableCell>
                                                        <TableCell>
                                                          {
                                                            invoice.bankUI
                                                              .bankName
                                                          }
                                                        </TableCell>
                                                        <TableCell>
                                                          {
                                                            invoice.bankUI
                                                              .accountType
                                                          }
                                                        </TableCell>
                                                        <TableCell>
                                                          {
                                                            invoice.bankUI
                                                              .branchName
                                                          }
                                                        </TableCell>
                                                        <TableCell>
                                                          {invoice.bankUI.ifsc}
                                                        </TableCell>
                                                        <TableCell>
                                                          {invoice.bankUI.micr}
                                                        </TableCell>
                                                        <TableCell>
                                                          {
                                                            invoice.bankUI
                                                              .bankAddress
                                                          }
                                                        </TableCell>
                                                        <TableCell>
                                                          {
                                                            invoice.bankUI
                                                              .swiftCode
                                                          }
                                                        </TableCell>
                                                      </TableRow>
                                                    ) : (
                                                      <TableRow>
                                                        <TableCell
                                                          colSpan={4}
                                                          align="center"
                                                        >
                                                          No Record Found
                                                        </TableCell>
                                                      </TableRow>
                                                    )}
                                                  </Box>
                                                </Collapse>
                                              </TableCell>
                                            </TableRow>
                                          </TableBody>
                                        </Table>
                                      </AccordionDetails>
                                    </Accordion>
                                  ))}
                                </Collapse>
                              </TableCell>
                            </TableRow>
                          </React.Fragment>
                        ))
                      )}
                    </TableBody>
                  </Table>
                  <TablePagination
                    rowsPerPageOptions={[10, 15, 20]}
                    component="div"
                    count={totalCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </Paper>
              </Grid>
            </>
          )}
        </TableContainer>
      )}
      <Dialog
        open={updateDialogOpen}
        onClose={() => setUpdateDialogOpen(false)}
        maxWidth="lg"
        maxHeight="lg"
        fullWidth
      >
        <DialogTitle>Update Invoice Data</DialogTitle>
        <DialogContent>
          <Paper
            elevation={4}
            style={{ padding: 20, marginBottom: 20, marginTop: 20 }}
          >
            <Grid container spacing={6} style={{ marginTop: 10 }}>
              <Grid item xs={3}>
                <TextField
                  label="Select Company"
                  value={editedInvoiceData?.selectCompany || ""}
                  onChange={(e) =>
                    handleDialogInputChange("selectCompany", e.target.value)
                  }
                  InputProps={{ readOnly: true }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Financial Year"
                  value={editedInvoiceData?.financialYear || ""}
                  onChange={(e) =>
                    handleDialogInputChange("financialYear", e.target.value)
                  }
                  InputProps={{ readOnly: true }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Invoice No"
                  value={editedInvoiceData?.invoiceNo || ""}
                  onChange={(e) =>
                    handleDialogInputChange("invoiceNo", e.target.value)
                  }
                  InputProps={{ readOnly: true }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Invoice Ref No"
                  value={editedInvoiceData?.invoiceRefNo || ""}
                  onChange={(e) =>
                    handleDialogInputChange("invoiceRefNo", e.target.value)
                  }
                  InputProps={{ readOnly: true }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Invoice Date"
                  type="date"
                  value={editedInvoiceData?.invoiceDate || ""}
                  onChange={(e) =>
                    handleDialogInputChange("invoiceDate", e.target.value)
                  }
                  fullWidth
                  margin="dense"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Invoice Type"
                  value={editedInvoiceData?.invoiceType || ""}
                  onChange={(e) =>
                    handleDialogInputChange("invoiceType", e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Client Type"
                  value={editedInvoiceData?.clientType || ""}
                  onChange={(e) =>
                    handleDialogInputChange("clientType", e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Client Name And Code"
                  value={editedInvoiceData?.clientNameAndCode || ""}
                  onChange={(e) =>
                    handleDialogInputChange("clientNameAndCode", e.target.value)
                  }
                  required
                  InputProps={{ readOnly: true }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Issue Date"
                  type="date"
                  value={editedInvoiceData?.issueDate || ""}
                  onChange={(e) =>
                    handleDialogInputChange("issueDate", e.target.value)
                  }
                  fullWidth
                  margin="dense"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="SacHsn"
                  value={editedInvoiceData?.sacHsn || ""}
                  onChange={(e) =>
                    handleDialogInputChange("sacHsn", e.target.value)
                  }
                  InputProps={{ readOnly: true }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="PO Number"
                  value={editedInvoiceData?.poNumber || ""}
                  onChange={(e) =>
                    handleDialogInputChange("poNumber", e.target.value)
                  }
                />
              </Grid>
              {/* <Grid item xs={3}>
                <TextField
                  label="Buyers Po Number"
                  value={editedInvoiceData?.buyersPoNumber || ""}
                  onChange={(e) =>
                    handleDialogInputChange("buyersPoNumber", e.target.value)
                  }
                />
              </Grid> */}
              <Grid item xs={3}>
                <TextField
                  label="Service of Year"
                  value={editedInvoiceData?.paymentYear || ""}
                  onChange={(e) =>
                    handleDialogInputChange("paymentYear", e.target.value)
                  }
                  required
                  InputProps={{ readOnly: true }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Service of Month"
                  value={editedInvoiceData?.paymentMonth || ""}
                  onChange={(e) =>
                    handleDialogInputChange("paymentMonth", e.target.value)
                  }
                  required
                  InputProps={{ readOnly: true }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Unique Po No"
                  value={editedInvoiceData?.uniqueNoPoNo || ""}
                  onChange={(e) =>
                    handleDialogInputChange("uniqueNoPoNo", e.target.value)
                  }
                  required
                  InputProps={{ readOnly: true }}
                />
              </Grid>
              {/* <Grid item xs={3}>
                <FormControl fullWidth>
                  <InputLabel id="invoiceTitle-label">Invoice Title</InputLabel>
                  <Select
                    labelId="invoiceTitle-label"
                    id="invoiceTitle"
                    value={editedInvoiceData?.invoiceTitle || ""}
                    onChange={(e) =>
                      handleDialogInputChange("invoiceTitle", e.target.value)
                    }
                    required
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value="TAX INVOICE">TAX INVOICE</MenuItem>
                    <MenuItem value="PROFORMA INVOICE ">
                      PROFORMA INVOICE{" "}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <InputLabel id="invoiceType-label">Invoice Type</InputLabel>
                  <Select
                    labelId="invoiceType-label"
                    id="invoiceType"
                    value={editedInvoiceData?.invoiceType || ""}
                    onChange={(e) =>
                      handleDialogInputChange("invoiceType", e.target.value)
                    }
                    required
                  >
                    <MenuItem value={editedInvoiceData?.invoiceType || ""}>
                      {editedInvoiceData?.invoiceType || "Select Invoice Type"}
                    </MenuItem>
                    <MenuItem value="Debit Note">Debit Note</MenuItem>
                    <MenuItem value="Credit Note">Credit Note</MenuItem>
                    <MenuItem value="Tax Invoice">Tax Invoice</MenuItem>
                  </Select>
                </FormControl>
              </Grid> */}
              <Grid item xs={3}>
                <Autocomplete
                  id="invoiceType"
                  options={["Debit Note", "Credit Note", "Tax Invoice"]}
                  value={editedInvoiceData?.invoiceType || ""}
                  onChange={(event, newValue) =>
                    handleDialogInputChange("invoiceType", newValue)
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="Invoice Type" required />
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <Autocomplete
                  id="invoiceTitle"
                  options={["TAX INVOICE", "PROFORMA INVOICE"]}
                  value={editedInvoiceData?.invoiceTitle || ""}
                  onChange={(event, newValue) =>
                    handleDialogInputChange("invoiceTitle", newValue)
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="Invoice Title" required />
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <Autocomplete
                  id="invoiceRemarks"
                  options={invoiceRemarksOptions}
                  value={editedInvoiceData?.invoiceRemarks || ""}
                  onChange={(event, newValue) =>
                    handleDialogInputChange("invoiceRemarks", newValue)
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="Invoice Remarks" required />
                  )}
                />
              </Grid>
            </Grid>
          </Paper>
          <Paper
            elevation={3}
            style={{ padding: 20, marginBottom: 20, marginTop: 20 }}
          >
            <TableContainer component={Paper} style={{ padding: "20px" }}>
              <Table>
                <TableHead>
                  <TableRow
                    style={{
                      backgroundColor: "darkcyan",
                      fontWeight: "bold",
                      color: "darkblue",
                    }}
                  >
                    <TableCell>Employee Name</TableCell>
                    <TableCell>Employee Code</TableCell>
                    <TableCell>Employee Role</TableCell>
                    <TableCell>Select Item</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Charge Per Amount</TableCell>
                    <TableCell>CTC Amount</TableCell>
                    <TableCell>Percentage Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {editedInvoiceData?.invoiceOtherChargesDetailsUIList?.map(
                    (project, projectIndex) => (
                      <TableRow key={projectIndex}>
                        <TableCell>
                          <TextField
                            value={project.employeeName || ""}
                            onChange={(e) =>
                              handleProjectInputChange(
                                projectIndex,
                                "employeeName",
                                e.target.value
                              )
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            value={project.employeeCode || ""}
                            onChange={(e) =>
                              handleProjectInputChange(
                                projectIndex,
                                "employeeCode",
                                e.target.value
                              )
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            value={project.employeeRole || ""}
                            onChange={(e) =>
                              handleProjectInputChange(
                                projectIndex,
                                "employeeRole",
                                e.target.value
                              )
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            value={project.selectItem || ""}
                            onChange={(e) =>
                              handleProjectInputChange(
                                projectIndex,
                                "selectItem",
                                e.target.value
                              )
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            value={project.description || ""}
                            onChange={(e) =>
                              handleProjectInputChange(
                                projectIndex,
                                "description",
                                e.target.value
                              )
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            value={project.chargePerAmount || ""}
                            onChange={(e) =>
                              handleProjectInputChange(
                                projectIndex,
                                "chargePerAmount",
                                e.target.value
                              )
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            value={project.ctcAmount || ""}
                            onChange={(e) =>
                              handleProjectInputChange(
                                projectIndex,
                                "ctcAmount",
                                e.target.value
                              )
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            value={project.percentageAmount || ""}
                            onChange={(e) =>
                              handleProjectInputChange(
                                projectIndex,
                                "percentageAmount",
                                e.target.value
                              )
                            }
                          />
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>

          <Paper
            elevation={3}
            style={{ padding: 20, marginBottom: 20, marginTop: 20 }}
          >
            <Grid container spacing={6} style={{ marginTop: 10 }}>
              <Grid item xs={4}>
                <Paper
                  elevation={3}
                  style={{ padding: "20px", marginBottom: "20px" }}
                >
                  <TextField
                    label="Total Presenty Amount"
                    sx={{ marginBottom: 2, marginTop: 2 }}
                    variant="standard"
                    size="small"
                    fullWidth
                    value={
                      editedInvoiceData?.invoiceGstDetailsUI
                        .totalPresentyAmount || ""
                    }
                    onChange={(e) =>
                      handleGstDetailsChange(
                        "totalPresentyAmount",
                        e.target.value
                      )
                    }
                  />

                  <TextField
                    label="Total Tax Amt"
                    sx={{ marginBottom: 2, marginTop: 2 }}
                    variant="standard"
                    size="small"
                    fullWidth
                    value={
                      editedInvoiceData?.invoiceGstDetailsUI.totalTaxAmt || ""
                    }
                    onChange={(e) =>
                      handleGstDetailsChange("totalTaxAmt", e.target.value)
                    }
                  />
                  <TextField
                    label="Grand Total Amount"
                    sx={{ marginBottom: 2, marginTop: 2 }}
                    variant="standard"
                    size="small"
                    fullWidth
                    value={
                      editedInvoiceData?.invoiceGstDetailsUI.grandTotalAmount ||
                      ""
                    }
                    onChange={(e) =>
                      handleGstDetailsChange("grandTotalAmount", e.target.value)
                    }
                  />

                  <TextField
                    label="Grand Total Amount"
                    sx={{ marginBottom: 2, marginTop: 2 }}
                    variant="standard"
                    size="small"
                    fullWidth
                    value={
                      editedInvoiceData?.invoiceGstDetailsUI.grandTotalAmount ||
                      ""
                    }
                    onChange={(e) =>
                      handleGstDetailsChange("grandTotalAmount", e.target.value)
                    }
                  />

                  <TextField
                    label="Deduction Amount"
                    sx={{ marginBottom: 2, marginTop: 2 }}
                    variant="standard"
                    size="small"
                    fullWidth
                    value={
                      editedInvoiceData?.invoiceGstDetailsUI.deductionAmount ||
                      ""
                    }
                    onChange={(e) =>
                      handleGstDetailsChange("deductionAmount", e.target.value)
                    }
                  />
                </Paper>
              </Grid>
              <Grid item xs={3}>
                <Paper
                  elevation={3}
                  style={{ padding: "20px", marginBottom: "20px" }}
                >
                  <TextField
                    label="Deduction Particular"
                    sx={{ marginBottom: 2, marginTop: 2 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="standard"
                    size="small"
                    multiline
                    rows={4}
                    value={
                      editedInvoiceData?.invoiceGstDetailsUI
                        .deductionParticular || ""
                    }
                    onChange={(e) =>
                      handleGstDetailsChange(
                        "deductionParticular",
                        e.target.value
                      )
                    }
                  />
                </Paper>
              </Grid>
              <Grid item xs={3}>
                <Paper
                  elevation={3}
                  style={{ padding: "20px", marginBottom: "20px" }}
                >
                  <TextField
                    label="CGST"
                    variant="standard"
                    size="small"
                    value={editedInvoiceData?.invoiceGstDetailsUI.cGST || ""}
                    onChange={(e) =>
                      handleGstDetailsChange("cGST", e.target.value)
                    }
                    sx={{ marginBottom: 2, marginTop: 2 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />

                  <TextField
                    label="IGST"
                    variant="standard"
                    size="small"
                    value={editedInvoiceData?.invoiceGstDetailsUI.iGST || ""}
                    onChange={(e) =>
                      handleGstDetailsChange("iGST", e.target.value)
                    }
                    sx={{ marginBottom: 2, marginTop: 2 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />

                  <TextField
                    label="SGST"
                    variant="standard"
                    size="small"
                    value={editedInvoiceData?.invoiceGstDetailsUI.sGST || ""}
                    onChange={(e) =>
                      handleGstDetailsChange("sGST", e.target.value)
                    }
                    sx={{ marginBottom: 2, marginTop: 2 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <TextField
                    label="SGST Amount"
                    variant="standard"
                    size="small"
                    value={
                      editedInvoiceData?.invoiceGstDetailsUI.sgstAmount || ""
                    }
                    onChange={(e) =>
                      handleGstDetailsChange("sgstAmount", e.target.value)
                    }
                    sx={{ marginBottom: 2, marginTop: 2 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <TextField
                    label="CGST Amount"
                    variant="standard"
                    size="small"
                    value={
                      editedInvoiceData?.invoiceGstDetailsUI.cgstAmount || ""
                    }
                    onChange={(e) =>
                      handleGstDetailsChange("cgstAmount", e.target.value)
                    }
                    sx={{ marginBottom: 2, marginTop: 2 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <TextField
                    label="IGST Amount"
                    variant="standard"
                    size="small"
                    value={
                      editedInvoiceData?.invoiceGstDetailsUI.igstAmount || ""
                    }
                    onChange={(e) =>
                      handleGstDetailsChange("igstAmount", e.target.value)
                    }
                    sx={{ marginBottom: 2, marginTop: 2 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <TextField
                    label="Total In Word Amount"
                    variant="standard"
                    size="small"
                    value={
                      editedInvoiceData?.invoiceGstDetailsUI
                        .totalInWordAmount || ""
                    }
                    onChange={(e) =>
                      handleGstDetailsChange(
                        "totalInWordAmount",
                        e.target.value
                      )
                    }
                    sx={{ marginBottom: 2, marginTop: 2 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Paper>
              </Grid>
              <Grid item xs={3}>
                <Paper
                  elevation={3}
                  style={{ padding: "20px", marginBottom: "20px" }}
                >
                  <Button variant="contained" onClick={handleCalculate}>
                    Calculate
                  </Button>
                </Paper>
              </Grid>
            </Grid>
          </Paper>

          <Paper
            elevation={3}
            style={{ padding: 20, marginBottom: 20, marginTop: 20 }}
          >
            <Grid container spacing={6} style={{ marginTop: 10 }}>
              <Grid item xs={4}>
                <TextField
                  label="Mode Terms Of Payment"
                  value={
                    editedInvoiceData?.invoicePaymentModeUI
                      .modeTermsOfPayment || ""
                  }
                  onChange={(e) =>
                    handlePaymentModeChange(
                      "modeTermsOfPayment",
                      e.target.value
                    )
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Kind Attention"
                  value={
                    editedInvoiceData?.invoicePaymentModeUI.kindAttention || ""
                  }
                  onChange={(e) =>
                    handlePaymentModeChange("kindAttention", e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Select Bank Acc No"
                  value={
                    editedInvoiceData?.invoicePaymentModeUI.selectBankAccNo ||
                    ""
                  }
                  onChange={(e) =>
                    handlePaymentModeChange("selectBankAccNo", e.target.value)
                  }
                  InputProps={{ readOnly: true }}
                />
              </Grid>
            </Grid>
          </Paper>
          <Paper
            elevation={3}
            style={{ padding: 20, marginBottom: 20, marginTop: 20 }}
          >
            <Grid container spacing={6} style={{ marginTop: 10 }}>
              <Grid item xs={4}>
                <TextField
                  label="Account Number"
                  value={editedInvoiceData?.bankUI.accountNumber || ""}
                  onChange={(e) =>
                    handleBankDetailsChange("accountNumber", e.target.value)
                  }
                  InputProps={{ readOnly: true }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Bank Name"
                  value={editedInvoiceData?.bankUI.bankName || ""}
                  onChange={(e) =>
                    handleBankDetailsChange("bankName", e.target.value)
                  }
                  InputProps={{ readOnly: true }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Account Type"
                  value={editedInvoiceData?.bankUI.accountType || ""}
                  onChange={(e) =>
                    handleBankDetailsChange("accountType", e.target.value)
                  }
                  InputProps={{ readOnly: true }}
                />
              </Grid>

              <Grid item xs={4}>
                <TextField
                  label="Branch Name"
                  value={editedInvoiceData?.bankUI.branchName || ""}
                  onChange={(e) =>
                    handleBankDetailsChange("branchName", e.target.value)
                  }
                  InputProps={{ readOnly: true }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="IFSC"
                  value={editedInvoiceData?.bankUI.ifsc || ""}
                  onChange={(e) =>
                    handleBankDetailsChange("ifsc", e.target.value)
                  }
                  InputProps={{ readOnly: true }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="MICR"
                  value={editedInvoiceData?.bankUI.micr || ""}
                  onChange={(e) =>
                    handleBankDetailsChange("micr", e.target.value)
                  }
                  InputProps={{ readOnly: true }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Bank Address"
                  value={editedInvoiceData?.bankUI.bankAddress || ""}
                  onChange={(e) =>
                    handleBankDetailsChange("bankAddress", e.target.value)
                  }
                  InputProps={{ readOnly: true }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Swift Code"
                  value={editedInvoiceData?.bankUI.swiftCode || ""}
                  onChange={(e) =>
                    handleBankDetailsChange("swiftCode", e.target.value)
                  }
                  InputProps={{ readOnly: true }}
                />
              </Grid>
            </Grid>
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setUpdateDialogOpen(false)}
            color="primary"
            style={{ marginRight: "20px" }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleUpdateDialogSave}
            color="primary"
            style={{ marginLeft: "20px" }}
            disabled={!hasChanges}
          >
            Update
          </Button>
          <Button
            onClick={() => handlegeneratePDF(editedInvoiceData.invoiceRefNo)}
            color="secondary"
            style={{ marginLeft: "20px" }}
          >
            PDF
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={deleteDialogOpen}
        onClose={() => {
          setDeleteDialogOpen(false);
          setPassword(""); // Reset password field
        }}
        maxHeight="md"
        fullWidth
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent style={{ marginBottom: 20, marginTop: 20 }}>
          {/* {passwordVerified === false && (
            <Typography variant="body2" color="error">
              Incorrect password. Please try again.
            </Typography>
          )} */}
          <Paper elevation={2} style={{ padding: 20, marginBottom: 20 }}>
            <Grid container spacing={6}>
              <Grid item md={4}>
                <TextField
                  label="Enter Password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  fullWidth
                  autoFocus
                />
              </Grid>
            </Grid>
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleDialogDelete}
            color="primary"
            disabled={isDeleting}
          >
            {isDeleting ? <CircularProgress size={24} /> : "Delete"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default OtherChargesInvoiceList;
