import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Collapse,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  TablePagination,
  Grid,
  Box,
  CircularProgress,
  Backdrop,
  MenuItem,
} from "@material-ui/core";
import { Autocomplete } from "@mui/material";
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  Edit as EditIcon,
  Search,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import { myAxiosCommonHost } from "../../Service/CorsHelperBaseUrl";
import * as XLSX from "xlsx";
import { CompanyContext } from "../StateProvider/CompanyProvider";

const Row = ({ row, onDelete, onUpdate }) => {
  const [open, setOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [editedVendor, setEditedVendor] = useState({ ...row });
  const [isDataChangedEdit, setIsDataChangedEdit] = useState(false);

  const handleEdit = () => {
    setDialogOpen(true);
  };

  const handleDelete = () => {
    onDelete(row.vendorCode);
  };

  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    setEditedVendor((prevVendor) => ({ ...prevVendor, [name]: value }));
    setIsDataChangedEdit(true);
  };

  const handleSave = () => {
    onUpdate(editedVendor);
    setDialogOpen(false);
  };

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell>{row.vendorUniqueNoCompanyWise}</TableCell>
        <TableCell>{row.vendorName}</TableCell>
        <TableCell>{row.vendorCode}</TableCell>
        <TableCell>{row.email}</TableCell>
        <TableCell>
          <Button onClick={handleEdit} color="primary" variant="contained">
            Update
          </Button>
          <Button
            onClick={handleDelete}
            color="error"
            variant="contained"
            style={{
              marginLeft: "8px",
              backgroundColor: "red",
              color: "white",
            }}
          >
            Delete
          </Button>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <div>
              <Box style={{ padding: 20 }}>
                <Typography variant="h6">Vendor Details</Typography>
                {row ? (
                  <div>
                    <TableRow
                      style={{
                        backgroundColor: "darkcyan",
                        fontWeight: "bold",
                        color: "darkblue",
                      }}
                    >
                      <TableCell>Vendor Code</TableCell>
                      <TableCell>Vendor Name</TableCell>
                      <TableCell>Contact Number</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Vendor Address</TableCell>
                      <TableCell>Bank Name</TableCell>
                      <TableCell>Branch Name</TableCell>
                      <TableCell>Account Number</TableCell>
                      <TableCell>IFSC</TableCell>
                      <TableCell>Account Type</TableCell>
                      <TableCell>State</TableCell>
                      <TableCell>State Code</TableCell>
                      <TableCell>GSTIN</TableCell>
                      <TableCell>PAN Number</TableCell>
                      <TableCell>Unique Vendor No Company Wise</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{row.vendorCode}</TableCell>
                      <TableCell>{row.vendorName}</TableCell>
                      <TableCell>{row.contactNumber}</TableCell>
                      <TableCell>{row.email}</TableCell>
                      <TableCell>{row.vendorAddress}</TableCell>
                      <TableCell>{row.bankName}</TableCell>
                      <TableCell>{row.branchName}</TableCell>
                      <TableCell>{row.accountNumber}</TableCell>
                      <TableCell>{row.ifsc}</TableCell>
                      <TableCell>{row.accountType}</TableCell>
                      <TableCell>{row.state}</TableCell>
                      <TableCell>{row.stateCode}</TableCell>
                      <TableCell>{row.gstin}</TableCell>
                      <TableCell>{row.panNumber}</TableCell>
                      <TableCell>{row.vendorUniqueNoCompanyWise}</TableCell>
                    </TableRow>
                  </div>
                ) : (
                  <TableRow>
                    <TableCell colSpan={3} align="center">
                      No Vendor Details found
                    </TableCell>
                  </TableRow>
                )}
              </Box>
            </div>
          </Collapse>
        </TableCell>
      </TableRow>

      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        maxWidth="md"
        maxHeight="md"
        fullWidth
      >
        <DialogTitle>Edit Vendor Information</DialogTitle>
        <DialogContent>
          <Typography variant="h6">Vendor Information</Typography>
          <Paper
            elevation={3}
            style={{ padding: 10, marginBottom: 10, marginTop: 10 }}
          >
            <Grid container spacing={3} style={{ marginTop: 10 }}>
              <Grid item xs={12} md={4}>
                <TextField
                  label="Company No Unique"
                  name="vendorUniqueNoCompanyWise"
                  value={editedVendor.vendorUniqueNoCompanyWise}
                  onChange={handleFieldChange}
                  variant="standard"
                  fullWidth
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  label="Select Company"
                  name="selectCompany"
                  value={editedVendor.selectCompany}
                  onChange={handleFieldChange}
                  variant="standard"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  label="Financial Year"
                  name="financialYear"
                  value={editedVendor.financialYear}
                  onChange={handleFieldChange}
                  variant="standard"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <TextField
                  label="Registration Date"
                  name="regDate"
                  value={editedVendor.regDate}
                  onChange={handleFieldChange}
                  variant="standard"
                  type="date"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  label="Vendor Code"
                  name="vendorCode"
                  value={editedVendor.vendorCode}
                  onChange={handleFieldChange}
                  variant="standard"
                  fullWidth
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  label="Vendor Name"
                  name="vendorName"
                  value={editedVendor.vendorName}
                  onChange={handleFieldChange}
                  variant="standard"
                  fullWidth
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  label="Contact Number"
                  name="contactNumber"
                  value={editedVendor.contactNumber}
                  onChange={handleFieldChange}
                  variant="standard"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  label="Email"
                  name="email"
                  value={editedVendor.email}
                  onChange={handleFieldChange}
                  variant="standard"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  label="Vendor Address"
                  name="vendorAddress"
                  value={editedVendor.vendorAddress}
                  onChange={handleFieldChange}
                  variant="standard"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  label="Bank Name"
                  name="bankName"
                  value={editedVendor.bankName}
                  onChange={handleFieldChange}
                  variant="standard"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  label="Branch Name"
                  name="branchName"
                  value={editedVendor.branchName}
                  onChange={handleFieldChange}
                  variant="standard"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  label="Account Number"
                  name="accountNumber"
                  value={editedVendor.accountNumber}
                  onChange={handleFieldChange}
                  variant="standard"
                  fullWidth
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  label="IFSC"
                  name="ifsc"
                  value={editedVendor.ifsc}
                  onChange={handleFieldChange}
                  variant="standard"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  label="Account Type"
                  name="accountType"
                  value={editedVendor.accountType}
                  onChange={handleFieldChange}
                  variant="standard"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  label="State"
                  name="state"
                  value={editedVendor.state}
                  onChange={handleFieldChange}
                  variant="standard"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  label="State Code"
                  name="stateCode"
                  value={editedVendor.stateCode}
                  onChange={handleFieldChange}
                  variant="standard"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  label="GSTIN"
                  name="gstin"
                  value={editedVendor.gstin}
                  onChange={handleFieldChange}
                  variant="standard"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  label="PAN Number"
                  name="panNumber"
                  value={editedVendor.panNumber}
                  onChange={handleFieldChange}
                  variant="standard"
                  fullWidth
                />
              </Grid>
            </Grid>
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            color="primary"
            disabled={!isDataChangedEdit}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const UpdateVendorInformation = () => {
  const [vendorData, setVendorData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  //const [selectedCompany, setSelectedCompany] = useState("");
  const [financialYear, setFinancialYear] = useState([]);
  const [data, setData] = useState(null);
  const [searchVendorName, setSearchVendorName] = useState("");
  const [searchVendorCode, setSearchVendorCode] = useState("");
  const [selectedOption, setSelectedOption] = useState("1");
  const [error, setError] = useState(null);
  const { selectedCompany } = useContext(CompanyContext);
  const { selectedFinancialYear } = useContext(CompanyContext);
  const [totalCount, setTotalCount] = useState(0); // Initialize total count state

  const getCurrentFinancialYear = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const fiscalStartYear = currentYear - (currentDate.getMonth() >= 3 ? 0 : 1);
    const fiscalEndYear = fiscalStartYear + 1;
    return `${(fiscalStartYear % 100).toString().padStart(2, "0")}-${(
      fiscalEndYear % 100
    )
      .toString()
      .padStart(2, "0")}`;
  };
  const defaultFinancialYear = getCurrentFinancialYear();
  //const [selectedFinancialYear, setSelectedFinancialYear] =
  useState(defaultFinancialYear);

  async function fetchFinancialYear() {
    try {
      const response = await myAxiosCommonHost.get("gst/invoice/getFiscalYear");
      setFinancialYear(response.data);
    } catch (error) {
      console.error("Error fetching financial year data:", error);
    }
  }
  useEffect(() => {
    fetchFinancialYear();
  }, []);

  // const handleFinancialYearSelect = (financialYear) => {
  //   setSelectedFinancialYear(financialYear);
  //   console.log("Selected Financial Year:", financialYear);
  // };

  // useEffect(() => {
  //   async function fetchCompany() {
  //     try {
  //       const response = await myAxiosCommonHost.get(
  //         "gst/profile/getCompanyCodeList"
  //       );
  //       const companies = response.data;
  //       setCompany(companies);
  //       if (companies.length > 0) {
  //         setSelectedCompany(companies[0]);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching company data:", error);
  //     }
  //   }
  //   fetchCompany();
  // }, []);
  const fetchVendorInfoCount = () => {
    myAxiosCommonHost
      .get(
        `gst/expense/getVendorInfoCount?selectCompany=${selectedCompany}&financialYear=${selectedFinancialYear}`
      )
      .then((response) => {
        const count = parseInt(response.data); // Extract count from API response
        setTotalCount(count); // Set total count state
      })
      .catch((error) => {
        console.error("Error fetching Agreement count:", error);
      });
  };
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const limit = rowsPerPage;
        const offset = page * rowsPerPage;
        const response = await myAxiosCommonHost.get(
          `gst/expense/getVendorInformationList?selectCompany=${selectedCompany}&limit=${limit}&offset=${offset}`
        );
        setData(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);

        setLoading(false);
      }
    };
    if (selectedCompany) {
      fetchVendorInfoCount();
      fetchData();
    }
  }, [selectedCompany, rowsPerPage, page]);

  const handleDelete = async (vendorCode) => {
    try {
      await myAxiosCommonHost.delete(
        `gst/expense/deleteVendorInfo/vendorCode?vendorCode=${vendorCode}`
      );
      setVendorData(
        vendorData.filter((vendor) => vendor.vendorCode !== vendorCode)
      );
    } catch (error) {
      console.error("Error deleting vendor data:", error);
    }
  };

  const handleUpdate = async (updatedVendor) => {
    try {
      const response = await myAxiosCommonHost.put(
        `/gst/expense/updateVendorInformation`,
        updatedVendor
      );
      setVendorData((prevVendors) =>
        prevVendors.map((vendor) =>
          vendor.vendorCode === updatedVendor.vendorCode
            ? response.data
            : vendor
        )
      );
    } catch (error) {
      console.error("Error updating vendor data:", error);
    }
  };

  const handleSearch = async () => {
    try {
      setLoading(true);
      const limit = rowsPerPage;
      const offset = page * rowsPerPage;
      const response = await myAxiosCommonHost.get(
        `gst/expense/searchVendorInfo?vendorCode=${searchVendorCode}&vendorName=${searchVendorName}&selectCompany=${selectedCompany}&limit=${limit}&offset=${offset}`
      );
      setData(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error searching employeePo:", error);
      setError(error);
      setLoading(false);
    }
  };

  // const handleCompanySelect = (company) => {
  //   setSelectedCompany(company);
  //   console.log("Selected Company Year:", company);
  // };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const downloadExcelFile = async () => {
    try {
      let apiUrl = `/gst/expense/getVendorInformationListForExcel`;
      if (selectedOption === "1") {
        apiUrl += `?selectCompany=${selectedCompany}&financialYear=${selectedFinancialYear}`;
      }

      console.log("API URL:", apiUrl); // Add this to log the constructed URL

      const response = await myAxiosCommonHost.get(apiUrl);

      const data = response.data;

      if (!data) {
        console.error("Data is not available or empty.");
        return;
      }

      const workbook = XLSX.utils.book_new();

      const vendorInformationExcelUIList =
        data.vendorInformationExcelUIList || [];

      if (vendorInformationExcelUIList.length > 0) {
        const worksheet = XLSX.utils.json_to_sheet(
          vendorInformationExcelUIList
        );
        XLSX.utils.book_append_sheet(
          workbook,
          worksheet,
          "Vendor Information Details"
        );
      }

      const excelBuffer = XLSX.write(workbook, {
        type: "array",
        bookType: "xlsx",
      });

      const blob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const url = URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.target = "_blank";
      anchor.download = `${selectedCompany}_Vendor_Information_Details.xlsx`;
      anchor.click();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <div>
      <Paper
        elevation={3}
        style={{ padding: 20, marginBottom: 20, marginTop: 20 }}
      >
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <Link to="/registerVendorInformation">
              <Button
                variant="contained"
                color="primary"
                style={{ margin: "10px" }}
              >
                Register Vendor
              </Button>
            </Link>
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Vendor Code"
              value={searchVendorCode}
              onChange={(e) => setSearchVendorCode(e.target.value)}
              variant="standard"
              style={{ marginRight: "10px" }}
            />
            <TextField
              label="Vendor Name"
              value={searchVendorName}
              onChange={(e) => setSearchVendorName(e.target.value)}
              variant="standard"
              style={{ marginRight: "10px" }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleSearch}
              style={{ marginLeft: "10px", marginTop: 20 }}
            >
              Search
            </Button>
          </Grid>
        </Grid>
      </Paper>
      <Paper elevation={3} style={{ padding: "10px", marginBottom: "10px" }}>
        <Grid container spacing={2} style={{ padding: "20px" }}>
          <Grid item xs={6} md={3}>
            {/* <Autocomplete
              id="financial-year"
              options={financialYear}
              loading={loading}
              value={selectedFinancialYear}
              onChange={(event, newValue) =>
                handleFinancialYearSelect(newValue)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Financial Year"
                  variant="standard"
                  style={{
                    backgroundColor: "white",
                  }}
                />
              )}
            /> */}
            <TextField
              label="Financial Year"
              value={selectedFinancialYear}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              disabled
              variant="standard"
              style={{ backgroundColor: "white", marginTop: "20px" }}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            {/* <Autocomplete
              id="company"
              options={company}
              loading={loading}
              value={selectedCompany}
              onChange={(event, newValue) => handleCompanySelect(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Company"
                  variant="standard"
                  style={{ backgroundColor: "white" }}
                />
              )}
            /> */}
            <TextField
              label="Select Company"
              value={selectedCompany}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              disabled
              variant="standard"
              style={{ backgroundColor: "white", marginTop: "20px" }}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <TextField
              select
              label="Download Options"
              value={selectedOption}
              onChange={handleOptionChange}
              variant="standard"
              style={{ minWidth: 200, marginRight: 16, marginTop: 20 }}
            >
              <MenuItem value="1">Download All Vendor Information</MenuItem>
            </TextField>

            <Button
              variant="contained"
              style={{
                backgroundColor: "green",
                color: "white",
                marginTop: 25,
              }}
              onClick={downloadExcelFile}
            >
              Download Excel
            </Button>
          </Grid>
        </Grid>
      </Paper>
      <Paper
        elevation={3}
        style={{
          padding: 20,
          marginBottom: 20,
          marginTop: 20,
          backgroundColor: "#2196f3",
          color: "white",
          padding: "10px",
          textAlign: "center",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography
            variant="h5"
            gutterBottom
            style={{ marginBottom: "15px", color: "darkblue" }}
          >
            Vendor Information List
          </Typography>
        </div>
      </Paper>

      {error ? (
        <p>Error fetching data. Please try again.</p>
      ) : (
        <TableContainer component={Paper} style={{ height: "20%" }}>
          {loading ? (
            <Backdrop
              sx={{
                color: "#fff",
                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
              open={loading}
            >
              <CircularProgress color="inherit" />
              <Typography
                variant="h6"
                color="inherit"
                style={{ marginLeft: "10px" }}
              >
                Getting Data...
              </Typography>
            </Backdrop>
          ) : (
            <>
              <Table>
                <TableHead>
                  <TableRow
                    style={{
                      backgroundColor: "darkcyan",
                      fontWeight: "bold",
                      color: "darkblue",
                    }}
                  >
                    <TableCell />
                    <TableCell>Vendor Unique No </TableCell>
                    <TableCell>Vendor Name</TableCell>
                    <TableCell>Vendor Code</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data && data.length > 0 ? (
                    data.map((vendor) => (
                      <Row
                        key={vendor.vendorUniqueNoCompanyWise}
                        row={vendor}
                        onDelete={handleDelete}
                        onUpdate={handleUpdate}
                      />
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={5} align="center">
                        No vendor data available
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={totalCount}
                rowsPerPage={rowsPerPage}
                page={currentPage}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </>
          )}
        </TableContainer>
      )}
    </div>
  );
};

export default UpdateVendorInformation;
