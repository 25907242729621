import Headers from "../../../Pages/Header";
import UpdateClient from "../UpdateClient";

const UpdateClientPage = () => {
  return (
    <Headers>
      <UpdateClient />
    </Headers>
  );
};
export default UpdateClientPage;
