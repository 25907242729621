import Headers from "../../../Pages/Header";
import UpdateVendor from "../UpdateVendor";

const UpdateVendorPage = () => {
  return (
    <Headers>
      <UpdateVendor />
    </Headers>
  );
};
export default UpdateVendorPage;
