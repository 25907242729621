import Headers from "../../../Pages/Header";
import NotificationBGDUI from "../Notifications(BGD)";

const NotificationBGDPage = () => {
  return (
    <Headers >
      <NotificationBGDUI />
    </Headers>
  );
};
export default NotificationBGDPage;