import Headers from "../../../Pages/Header";
import GenerateEmployeeInvoice from "../GenerateEmployeeInvoice";

const GenerateEmployeeInvoicePage = () => {
  return (
    <Headers>
      <GenerateEmployeeInvoice />
    </Headers>
  );
};
export default GenerateEmployeeInvoicePage;
