import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { myAxiosCommonHost } from "../Service/CorsHelperBaseUrl";
import {
  TextField,
  Button,
  Typography,
  Container,
  Box,
  Alert,
  Snackbar,
  Paper,
} from "@mui/material";
import gsquareLogo from "./gsquareLogo.ico";
import backgroundImage from "./BackGround.jpg";
const DemoLogin = ({ onLogin }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async () => {
    console.log("Username: ", username);
    console.log("Password: ", password);
    const encodedUsername = encodeURIComponent(username);
    try {
      const response = await myAxiosCommonHost.get("/gst/login/loginUser", {
        params: {
          userName: encodedUsername,
          passWord: password,
        },
      });
      if (response.data === true) {
        localStorage.setItem("isAuthenticated", "true"); // Save authentication status
        setSuccess(true);
        onLogin();
        setTimeout(() => navigate("/navigationBarRSuite"), 1500); // Redirect after 1.5 seconds
      } else {
        setError("Invalid username or password");
      }
    } catch (error) {
      console.error("Login error:", error);
      setError("An error occurred during login");
    }
  };

  return (
    <Box
      sx={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Container maxWidth="sm">
        <Paper
          elevation={3}
          style={{
            padding: 20,
            maxWidth: 400,
            margin: "auto",
            marginTop: 100,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "lightgray",
          }}
        >
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              src={gsquareLogo}
              alt="gsquareLogo"
              style={{ width: "150px", marginBottom: "20px" }}
            />
            <Typography
              component="h1"
              variant="h5"
              sx={{
                fontFamily: "Roboto Condensed, sans-serif",
                fontWeight: "bold",
              }}
            >
              Login
            </Typography>
            <Box
              component="form"
              onSubmit={(e) => e.preventDefault()}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="username"
                label="Username"
                name="username"
                autoComplete="username"
                autoFocus
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              {error && <Alert severity="error">{error}</Alert>}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={handleLogin}
              >
                Login
              </Button>
            </Box>
          </Box>
        </Paper>
        <Snackbar
          open={success}
          autoHideDuration={2000}
          onClose={() => setSuccess(false)}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={() => setSuccess(false)}
            severity="success"
            sx={{ width: "100%" }}
          >
            Login successful! Redirecting...
          </Alert>
        </Snackbar>
      </Container>
    </Box>
  );
};

export default DemoLogin;
