import Headers from "../../../Pages/Header";
import RegisterClientAgreement from "../RegisterClientAgreement";

const RegisterClientAgreementPage = () => {
  return (
    <Headers>
      <RegisterClientAgreement />
    </Headers>
  );
};
export default RegisterClientAgreementPage;
