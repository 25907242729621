import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Tab,
  Tabs,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Collapse,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import DeleteIcon from "@material-ui/icons/Delete";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Box, IconButton } from "@mui/material";

const ProjectPOListVal = () => {
  const [data, setData] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [editedData, setEditedData] = useState(null);
  const [clientDialogOpen, setClientDialogOpen] = useState(false);
  const [editedClientData, setEditedClientData] = useState(null);
  const [currentTab, setCurrentTab] = useState(0);
  const [releaseDialogOpen, setReleaseDialogOpen] = useState(false);
  const [expandedRow, setExpandedRow] = useState(null);
  const [addClientDialogOpen, setAddClientDialogOpen] = useState(false);
  const [fetchError, setFetchError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [newClientData, setNewClientData] = useState({
    projectPoNo: "",
    gsProjectUniqueCode: "",
    clientName: "",
    projectName: "",
    projectPoDetailsUIList: [
      {
        projectPoNo: "",
        poDate: "",
        poStartDate: "",
        poEndDate: "",
        poAmount: "",
        description: "",
        ratePerDay: "",
        ratePerhour: "",
        isActive: "",
      },
    ],
  });
  const [clientOptions, setClientOptions] = useState([]);
  const [addedClient, setAddedClient] = useState([]);
  useEffect(() => {
    axios
      .get("http://localhost:8082/gst/client/getClientNameList")
      .then((response) => {
        setClientOptions(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.error("Error fetching client data:", error);
      });
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "http://localhost:8082/gst/projectpo/getProjectPoList"
        );
        const result = await response.json();
        setData(result);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  const handleAddClientClick = (po) => {
    setNewClientData({
      ...newClientData,
      projectPoNo: po.projectPoNo,
      gsProjectUniqueCode: po.gsProjectUniqueCode,
      clientName: po.clientName,
      projectName: po.projectName,
    });
    setAddClientDialogOpen(true);
  };
  const handleAddClientDialogClose = () => {
    setAddClientDialogOpen(false);
  };
  const handleReleaseButtonClick = (clientData) => {
    setEditedClientData(clientData);
    setReleaseDialogOpen(true);
  };
  const handleReleaseDialogClose = () => {
    setReleaseDialogOpen(false);
  };

  const handleEditClick = (editedData) => {
    setEditedData(editedData);
    setOpenDialog(true);
  };

  const handleClientEditClick = (clientData) => {
    setEditedClientData(clientData);
    setClientDialogOpen(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleAddToTable = () => {
    if (
      //   newClientData.projectPoDetailsUIList[0].projectPoNo.trim() === "" ||
      newClientData.projectPoDetailsUIList[0].poDate.trim() === "" ||
      newClientData.projectPoDetailsUIList[0].poStartDate.trim() === "" ||
      newClientData.projectPoDetailsUIList[0].poEndDate.trim() === "" ||
      newClientData.projectPoDetailsUIList[0].poAmount.trim() === "" ||
      newClientData.projectPoDetailsUIList[0].description.trim() === ""
      // newClientData.projectPoDetailsUIList[0].isActive
    ) {
      alert("Cannot add null or empty data to the table");
      return;
    }
    setAddedClient([...addedClient, newClientData]);
    setNewClientData({
      ...addedClient,
      projectPoDetailsUIList: [
        {
          projectPoNo: "",
          poDate: "",
          poStartDate: "",
          poEndDate: "",
          poAmount: "",
          description: "",
          ratePerDay: "",
          ratePerhour: "",
          isActive: "",
        },
      ],
    });
  };

  const handleSendDataToBackend = async () => {
    if (addedClient.length === 0) {
      alert("Cannot submit without adding an client to the table");
      return;
    }
    const requestData = {
      projectPoNo: addedClient[0].projectPoNo,
      gsProjectUniqueCode: addedClient[0].gsProjectUniqueCode,
      clientName: addedClient[0].clientName,
      projectName: addedClient[0].projectName,

      projectPoDetailsUIList: addedClient.flatMap((client) =>
        client.projectPoDetailsUIList.map((projectPo) => ({
          projectPoNo: projectPo.projectPoNo,
          poDate: projectPo.poDate,
          poStartDate: projectPo.poStartDate,
          poEndDate: projectPo.poEndDate,
          poAmount: projectPo.poAmount,
          ratePerDay: projectPo.ratePerDay,
          ratePerhour: projectPo.ratePerhour,
          description: projectPo.description,
          isActive: projectPo.isActive,
        }))
      ),
    };

    console.log("Request Data:", JSON.stringify(requestData, null, 2));
    try {
      const updateResponse = await axios.put(
        "http://localhost:8082/gst/projectpo/updateProjectPO",
        requestData
      );
      console.log("Update Response:", updateResponse.data);

      const getResponse = await axios.get(
        "http://localhost:8082/gst/projectpo/getProjectPoList"
      );
      console.log("Get Response:", getResponse.data);
    } catch (error) {
      console.error("Error sending or fetching data from the backend:", error);
    }
  };

  const handleClientDialogClose = () => {
    setClientDialogOpen(false);
  };
  const handleUpdateData = async () => {
    try {
      const updatedData = {
        ...editedData,
        projectPoDetailsUIList: [editedClientData],
      };

      const updateResponse = await fetch(
        "http://localhost:8082/gst/projectpo/updateProjectPO",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedData),
        }
      );

      if (updateResponse.ok) {
        console.log("Successfully updated data:", updatedData);

        const fetchDataResponse = await fetch(
          "http://localhost:8082/gst/projectpo/getProjectPoList"
        );

        if (fetchDataResponse.ok) {
          const updatedResult = await fetchDataResponse.json();
          setData(updatedResult);
        } else {
          console.error("Failed to fetch updated data.");
        }

        setOpenDialog(false);
        setClientDialogOpen(false);
      } else {
        console.error("Failed to update data.");
      }
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };
  const handleExpandClick = (index) => {
    setExpandedRow(expandedRow === index ? null : index);
  };
  const handleDeleteRow = (index) => {
    const updatedClient = [...addedClient];
    updatedClient.splice(index, 1);
    setAddedClient(updatedClient);
  };
  if (fetchError) {
    return <div>Error fetching data. Please try again later.</div>;
  }
  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Link to="/addProjectDetails" style={{ textDecoration: "none" }}>
        <Button
          variant="contained"
          color="secondary"
          style={{ marginTop: 20, marginRight: 10, marginBottom: "20px" }}
        >
          Generate Project PO
        </Button>
      </Link>
      <Paper
        elevation={3}
        style={{
          padding: 20,
          marginBottom: 20,
          backgroundColor: "#2196f3",
          color: "white",
          padding: "10px",
          textAlign: "center",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography
            variant="h5"
            gutterBottom
            style={{ marginBottom: "15px", color: "darkblue" }}
          >
            Project PO List
          </Typography>
        </div>
      </Paper>
      {/* <TextField
        label="Search by Po Number"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        style={{ float: "right", margin: "10px" }}
      /> */}
      {error ? (
        <p>No data Found Add Employee PO.</p>
      ) : (
        <TableContainer component={Paper} style={{ height: "20%" }}>
          {loading ? (
            <p>Loading...</p>
          ) : (
            <>
              {data.length === 0 ? (
                <p>No data found.</p>
              ) : (
                <Table>
                  <TableHead>
                    <TableRow
                      style={{
                        backgroundColor: "darkcyan",
                        fontWeight: "bold",
                        color: "darkblue",
                      }}
                    >
                      <TableCell />
                      <TableCell>Project Po No</TableCell>
                      <TableCell>Client Name</TableCell>
                      <TableCell>Project Name</TableCell>
                      <TableCell>Project Unique Code</TableCell>
                      <TableCell>Action</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.map((po, index) => (
                      <React.Fragment key={index}>
                        <TableRow>
                          <TableCell>
                            <IconButton
                              onClick={() => handleExpandClick(index)}
                              aria-expanded={expandedRow === index}
                              aria-label="show more"
                            >
                              <ExpandMoreIcon />
                            </IconButton>
                          </TableCell>
                          <TableCell>{po.projectPoNo}</TableCell>
                          <TableCell>{po.clientName}</TableCell>
                          <TableCell>{po.projectName}</TableCell>
                          <TableCell>{po.gsProjectUniqueCode}</TableCell>
                          <TableCell>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => handleEditClick(po)}
                            >
                              Update PO
                            </Button>
                          </TableCell>
                          <TableCell>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => handleAddClientClick(po)}
                            >
                              Add Project
                            </Button>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={5}>
                            <Collapse
                              in={expandedRow === index}
                              timeout="auto"
                              unmountOnExit
                            >
                              {(po.projectPoDetailsUIList || []).map(
                                (client, clientIndex) => (
                                  <Accordion key={clientIndex}>
                                    <AccordionSummary
                                      expandIcon={<ExpandMoreIcon />}
                                      aria-controls={`panel${index}-content`}
                                      id={`panel${index}-header`}
                                      IconButtonProps={{ edge: "start" }}
                                    >
                                      <Typography>
                                        {"Description -:"}
                                        {
                                          po.projectPoDetailsUIList[clientIndex]
                                            .description
                                        }
                                      </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                      <Table style={{ width: "100%" }}>
                                        <TableBody>
                                          <TableRow
                                            style={{
                                              backgroundColor: "goldenrod",
                                              fontWeight: "bold",
                                              color: "darkblue",
                                            }}
                                          >
                                            {/* <TableCell>Project PoNo</TableCell> */}
                                            <TableCell>Description</TableCell>
                                            <TableCell>Po Amount</TableCell>
                                            <TableCell>Rate Per Day</TableCell>
                                            <TableCell>Rate Per Hour</TableCell>
                                            <TableCell>Po Date</TableCell>
                                            <TableCell>Po StartDate</TableCell>
                                            <TableCell>Po EndDate</TableCell>

                                            {/* <TableCell>Is Active</TableCell> */}
                                            <TableCell>Action</TableCell>
                                          </TableRow>
                                          <TableRow>
                                            {/* <TableCell>{client.projectPoNo}</TableCell> */}
                                            <TableCell>
                                              {client?.description || "N/A"}
                                            </TableCell>
                                            <TableCell>
                                              {client?.poAmount || "N/A"}
                                            </TableCell>
                                            <TableCell>
                                              {client?.ratePerDay || "N/A"}
                                            </TableCell>
                                            <TableCell>
                                              {client?.ratePerhour || "N/A"}
                                            </TableCell>
                                            <TableCell>
                                              {client?.poDate || "N/A"}
                                            </TableCell>
                                            <TableCell>
                                              {client?.poStartDate || "N/A"}
                                            </TableCell>
                                            <TableCell>
                                              {client?.poEndDate || "N/A"}
                                            </TableCell>

                                            {/* <TableCell>{client.isActive}</TableCell> */}
                                            <TableCell>
                                              <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() =>
                                                  handleClientEditClick(client)
                                                }
                                              >
                                                Update
                                              </Button>
                                            </TableCell>
                                            <TableCell>
                                              <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() =>
                                                  handleReleaseButtonClick(
                                                    editedClientData
                                                  )
                                                }
                                              >
                                                Release
                                              </Button>
                                            </TableCell>
                                          </TableRow>
                                          <TableRow></TableRow>
                                        </TableBody>
                                      </Table>
                                    </AccordionDetails>
                                  </Accordion>
                                )
                              )}
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    ))}
                  </TableBody>
                </Table>
              )}
            </>
          )}
        </TableContainer>
      )}
      <Dialog
        open={addClientDialogOpen}
        onClose={handleAddClientDialogClose}
        maxWidth="md"
        maxHeight="md"
        fullWidth
      >
        <DialogTitle>Add Project</DialogTitle>
        <DialogContent style={{ marginBottom: 20, marginTop: 20 }}>
          <Paper elevation={3} style={{ padding: 20, marginBottom: 20 }}>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <TextField
                  label="Project Po No"
                  value={newClientData.projectPoNo || "N/A"}
                  InputProps={{ readOnly: true }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Client Name"
                  value={newClientData.clientName || "N/A"}
                  InputProps={{ readOnly: true }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Project Unique Code"
                  value={newClientData.gsProjectUniqueCode || "N/A"}
                  InputProps={{ readOnly: true }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Project Name"
                  value={newClientData.projectName || "N/A"}
                  InputProps={{ readOnly: true }}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Paper>

          <Paper elevation={3} style={{ padding: 20, marginBottom: 20 }}>
            <Typography variant="subtitle1">Client Details</Typography>
            <Grid container spacing={3}>
              {/* <Grid item xs={6}>
                <TextField
                  label="Project Po No"
                  value={newClientData.projectPoDetailsUIList[0].projectPoNo}
                  onChange={(e) =>
                    setNewClientData({
                      ...newClientData,
                      projectPoDetailsUIList: [
                        {
                          ...newClientData.projectPoDetailsUIList[0],
                          projectPoNo: e.target.value,
                        },
                      ],
                    })
                  }
                  fullWidth
                />
              </Grid> */}
              <Grid item xs={6}>
                <TextField
                  label="Description"
                  value={
                    newClientData.projectPoDetailsUIList[0].description || "N/A"
                  }
                  onChange={(e) =>
                    setNewClientData({
                      ...newClientData,
                      projectPoDetailsUIList: [
                        {
                          ...newClientData.projectPoDetailsUIList[0],
                          description: e.target.value,
                        },
                      ],
                    })
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="PO Amount"
                  value={
                    newClientData.projectPoDetailsUIList[0].poAmount || "N/A"
                  }
                  onChange={(e) =>
                    setNewClientData({
                      ...newClientData,
                      projectPoDetailsUIList: [
                        {
                          ...newClientData.projectPoDetailsUIList[0],
                          poAmount: e.target.value,
                        },
                      ],
                    })
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Rate Per Day"
                  value={
                    newClientData.projectPoDetailsUIList[0].ratePerDay || "N/A"
                  }
                  onChange={(e) =>
                    setNewClientData({
                      ...newClientData,
                      projectPoDetailsUIList: [
                        {
                          ...newClientData.projectPoDetailsUIList[0],
                          ratePerDay: e.target.value,
                        },
                      ],
                    })
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Rate Per Hour"
                  value={
                    newClientData.projectPoDetailsUIList[0].ratePerhour || "N/A"
                  }
                  onChange={(e) =>
                    setNewClientData({
                      ...newClientData,
                      projectPoDetailsUIList: [
                        {
                          ...newClientData.projectPoDetailsUIList[0],
                          ratePerhour: e.target.value,
                        },
                      ],
                    })
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Po Date"
                  type="date"
                  value={
                    newClientData.projectPoDetailsUIList[0].poDate || "N/A"
                  }
                  onChange={(e) =>
                    setNewClientData({
                      ...newClientData,
                      projectPoDetailsUIList: [
                        {
                          ...newClientData.projectPoDetailsUIList[0],
                          poDate: e.target.value,
                        },
                      ],
                    })
                  }
                  margin="dense"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="PO Start Date"
                  type="date"
                  value={
                    newClientData.projectPoDetailsUIList[0].poStartDate || "N/A"
                  }
                  onChange={(e) =>
                    setNewClientData({
                      ...newClientData,
                      projectPoDetailsUIList: [
                        {
                          ...newClientData.projectPoDetailsUIList[0],
                          poStartDate: e.target.value,
                        },
                      ],
                    })
                  }
                  margin="dense"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="PO End Date"
                  type="date"
                  value={
                    newClientData.projectPoDetailsUIList[0].poEndDate || "N/A"
                  }
                  onChange={(e) =>
                    setNewClientData({
                      ...newClientData,
                      projectPoDetailsUIList: [
                        {
                          ...newClientData.projectPoDetailsUIList[0],
                          poEndDate: e.target.value,
                        },
                      ],
                    })
                  }
                  margin="dense"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                />
              </Grid>

              {/* <Grid item xs={6}>
                <InputLabel htmlFor="isActive">IsActive</InputLabel>
                <Select
                  label="Is Active"
                  name="isActive"
                  value={newClientData.projectPoDetailsUIList[0].isActive}
                  onChange={(e) =>
                    setNewClientData({
                      ...newClientData,
                      projectPoDetailsUIList: [
                        {
                          ...newClientData.projectPoDetailsUIList[0],
                          isActive: e.target.value,
                        },
                      ],
                    })
                  }
                  fullWidth
                  margin="dense"
                  variant="standard"
                >
                  <MenuItem value="disabled">Select</MenuItem>
                  <MenuItem value="true">Yes</MenuItem>
                  <MenuItem value="false">No</MenuItem>
                </Select>
              </Grid> */}
            </Grid>
          </Paper>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  {/* <TableCell>Project PoNo</TableCell> */}
                  <TableCell>Description</TableCell>
                  <TableCell>Po Amount</TableCell>
                  <TableCell>Rate Per Day</TableCell>
                  <TableCell>Rate Per Hour</TableCell>
                  <TableCell>Po Date</TableCell>
                  <TableCell>Po StartDate</TableCell>
                  <TableCell>Po EndDate</TableCell>

                  {/* <TableCell>Is Active</TableCell> */}
                  <TableCell>Action</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {addedClient.map((client, index) => (
                  <TableRow key={index}>
                    {/* <TableCell>
                      {client.projectPoDetailsUIList[0].projectPoNo}
                    </TableCell> */}
                    <TableCell>
                      {client?.projectPoDetailsUIList[0].description || "N/A"}
                    </TableCell>
                    <TableCell>
                      {client?.projectPoDetailsUIList[0].poAmount || "N/A"}
                    </TableCell>
                    <TableCell>
                      {client?.projectPoDetailsUIList[0].ratePerDay || "N/A"}
                    </TableCell>
                    <TableCell>
                      {client?.projectPoDetailsUIList[0].ratePerhour || "N/A"}
                    </TableCell>
                    <TableCell>
                      {client.projectPoDetailsUIList[0].poDate || "N/A"}
                    </TableCell>
                    <TableCell>
                      {client?.projectPoDetailsUIList[0].poStartDate || "N/A"}
                    </TableCell>
                    <TableCell>
                      {client?.projectPoDetailsUIList[0].poEndDate || "N/A"}
                    </TableCell>

                    {/* <TableCell>
                      {client.projectPoDetailsUIList[0].isActive}
                    </TableCell> */}
                    <TableCell>
                      <IconButton onClick={() => handleDeleteRow(index)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Button onClick={handleAddToTable} color="primary">
            Add to Table
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddClientDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSendDataToBackend} color="primary">
            Add Client
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        maxHeight="md"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Edit PO Data</DialogTitle>
        <DialogContent style={{ marginBottom: 20, marginTop: 20 }}>
          <Paper elevation={3} style={{ padding: 20, marginBottom: 20 }}>
            <Grid container spacing={6}>
              <Grid item xs={4}>
                <TextField
                  label="Project Po No"
                  value={editedData ? editedData.projectPoNo : ""}
                  onChange={(e) =>
                    setEditedData({
                      ...editedData,
                      projectPoNo: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Client Name"
                  value={editedData ? editedData.clientName : ""}
                  onChange={(e) =>
                    setEditedData({ ...editedData, clientName: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Project Name"
                  value={editedData ? editedData.projectName : ""}
                  onChange={(e) =>
                    setEditedData({
                      ...editedData,
                      projectName: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Project Unique Code"
                  value={editedData ? editedData.gsProjectUniqueCode : ""}
                  onChange={(e) =>
                    setEditedData({
                      ...editedData,
                      gsProjectUniqueCode: e.target.value,
                    })
                  }
                />
              </Grid>
            </Grid>
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleUpdateData} color="primary">
            Update PO
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={clientDialogOpen}
        onClose={handleClientDialogClose}
        maxWidth="md"
        maxHeight="md"
        fullWidth
      >
        <DialogTitle>Edit Project Data</DialogTitle>
        <DialogContent>
          <Tabs
            value={currentTab}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="Client Details" />
          </Tabs>
          {currentTab === 0 && (
            <div>
              <Paper
                elevation={3}
                style={{ padding: 20, marginBottom: 20, marginTop: 20 }}
              >
                <Grid container spacing={6} style={{ marginTop: 10 }}>
                  <Grid item xs={4}>
                    <TextField
                      label="Description"
                      value={
                        editedClientData ? editedClientData.description : ""
                      }
                      onChange={(e) =>
                        setEditedClientData({
                          ...editedClientData,
                          description: e.target.value,
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="PO Amount"
                      value={editedClientData ? editedClientData.poAmount : ""}
                      onChange={(e) =>
                        setEditedClientData({
                          ...editedClientData,
                          poAmount: e.target.value,
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="Rate Per Day"
                      value={
                        editedClientData ? editedClientData.ratePerDay : ""
                      }
                      onChange={(e) =>
                        setEditedClientData({
                          ...editedClientData,
                          ratePerDay: e.target.value,
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="Rate Per Hour"
                      value={
                        editedClientData ? editedClientData.ratePerhour : ""
                      }
                      onChange={(e) =>
                        setEditedClientData({
                          ...editedClientData,
                          ratePerhour: e.target.value,
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="PO Date"
                      value={editedClientData ? editedClientData.poDate : ""}
                      onChange={(e) =>
                        setEditedClientData({
                          ...editedClientData,
                          poDate: e.target.value,
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="Po Start Date"
                      value={
                        editedClientData ? editedClientData.poStartDate : ""
                      }
                      onChange={(e) =>
                        setEditedClientData({
                          ...editedClientData,
                          poStartDate: e.target.value,
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="Po End Date"
                      value={editedClientData ? editedClientData.poEndDate : ""}
                      onChange={(e) =>
                        setEditedClientData({
                          ...editedClientData,
                          poEndDate: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  {/* <Grid item xs={4}>
                    <TextField
                      label="Is Active"
                      value={editedClientData ? editedClientData.isActive : ""}
                      onChange={(e) =>
                        setEditedClientData({
                          ...editedClientData,
                          isActive: e.target.value,
                        })
                      }
                    />
                  </Grid> */}
                </Grid>
              </Paper>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClientDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleUpdateData} color="primary">
            Update Client
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={releaseDialogOpen}
        onClose={handleReleaseDialogClose}
        maxWidth="md"
        maxHeight="md"
        fullWidth
      >
        <DialogTitle>Project Release</DialogTitle>
        <DialogContent style={{ marginBottom: 20, marginTop: 20 }}>
          <Paper elevation={2} style={{ padding: 20, marginBottom: 20 }}>
            <Grid container spacing={6}>
              <Grid item md={4}>
                <TextField
                  label="Project Po No"
                  value={editedClientData ? editedClientData.projectPoNo : ""}
                  onChange={(e) =>
                    setEditedClientData({
                      ...editedClientData,
                      projectPoNo: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item md={4}>
                <TextField
                  label="Client Name"
                  value={editedData ? editedData.clientName : ""}
                  onChange={(e) =>
                    setEditedData({ ...editedData, clientName: e.target.value })
                  }
                />
              </Grid>
              <Grid item md={4}>
                <TextField
                  label="Description"
                  value={editedClientData ? editedClientData.description : ""}
                  onChange={(e) =>
                    setEditedClientData({
                      ...editedClientData,
                      description: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item md={4}>
                <TextField
                  label="Project Unique Code"
                  value={editedData ? editedData.gsProjectUniqueCode : ""}
                  onChange={(e) =>
                    setEditedData({
                      ...editedData,
                      gsProjectUniqueCode: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item md={4}>
                <TextField
                  label="Project Name"
                  value={editedData ? editedData.projectName : ""}
                  onChange={(e) =>
                    setEditedData({
                      ...editedData,
                      projectName: e.target.value,
                    })
                  }
                />
              </Grid>
            </Grid>
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleReleaseDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleUpdateData} color="primary">
            Update Employee Release
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ProjectPOListVal;
