// TableDataComponent.js
import React from "react";
import {
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
} from "@material-ui/core";

const TableDataComponent = ({ projectSummary, page, rowsPerPage }) => {
  // Check if projectSummary is not an array or is empty
  if (!Array.isArray(projectSummary) || projectSummary.length === 0) {
    return (
      <Paper elevation={3} style={{ padding: "20px", marginBottom: "20px" }}>
        <Typography variant="body1">No data available.</Typography>
      </Paper>
    );
  }

  return (
    <Paper elevation={3} style={{ padding: "20px", marginBottom: "20px" }}>
      <TableContainer component={Paper} style={{ marginTop: "20px" }}>
        <Table>
          <TableHead>
            <TableRow
              style={{
                backgroundColor: "darkcyan",
                fontWeight: "bold",
                color: "darkblue",
              }}
            >
              <TableCell>Project Name</TableCell>
              <TableCell>Project Description</TableCell>
              <TableCell>Is Active</TableCell>
              <TableCell>Flag Is Active Value</TableCell>
              <TableCell>Project Po No</TableCell>
              <TableCell>Project Po Amount</TableCell>
              <TableCell>Project Po Date</TableCell>
              <TableCell>Project Po StartDate</TableCell>
              <TableCell>Project Po EndDate</TableCell>
              <TableCell>ClientCode</TableCell>
              <TableCell>ClientName</TableCell>
              <TableCell>Project Unique Code</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {projectSummary
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <TableRow key={row.id}>
                  <TableCell>{row.projectName}</TableCell>
                  <TableCell>{row.projectDescription}</TableCell>
                  <TableCell>{row.isActive}</TableCell>
                  <TableCell>{row.flagIsActiveValue}</TableCell>
                  <TableCell>{row.projectPoNo}</TableCell>
                  <TableCell>{row.projectPoAmount}</TableCell>
                  <TableCell>
                    {row.projectPoDate
                      ? row.projectPoDate.split("-").reverse().join("-")
                      : "N/A"}
                  </TableCell>
                  <TableCell>
                    {row.projectPoStartDate
                      ? row.projectPoStartDate.split("-").reverse().join("-")
                      : "N/A"}
                  </TableCell>
                  <TableCell>
                    {row.projectPoEndDate
                      ? row.projectPoEndDate.split("-").reverse().join("-")
                      : "N/A"}
                  </TableCell>
                  <TableCell>{row.clientCode}</TableCell>
                  <TableCell>{row.clientName}</TableCell>
                  <TableCell>{row.gsProjectUniqueCode}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default TableDataComponent;
